import React from 'react'
import { FieldRenderProps } from 'react-final-form'

import FroalaEditor from '@src/components/common/FroalaEditor'

export type IProps = FieldRenderProps<string> & {
    config?: object
}

export default class FroalaEditorInput extends React.PureComponent<IProps> {
    public render() {
        const { config, input } = this.props

        return (
            <FroalaEditor
                tag="textarea"
                config={config}
                model={input.value}
                onModelChange={input.onChange}
            />
        )
    }
}
