import { Operation } from "fast-json-patch";

import { Features } from "@src/logic/features/features";
import * as Query from "@src/logic/http/CommonQueryParamters";
import {
  RequestBuilder,
  authenticatedRequest,
} from "@src/logic/http/RequestBuilder";
import { qualifyUrl } from "@src/logic/http/URLUtils";
import { AccessControlList } from "@src/types/access";
import { Api } from "@src/types/api";
import {
  Communication,
  CommunicationBrief,
  Template,
  TemplateBrief,
} from "@src/types/communication";
import {
  BudgetOverview,
  Commitment,
  CommitmentDefinition,
  CommitmentItemWithParentRef,
  CostCode,
  CostCodeBrief,
  CostReportSnapshot,
  CostReportSnapshotBrief,
  CostsOverview,
  CostsSettings,
  PaymentClaim,
  Phase,
} from "@src/types/costs";
import { DocumentSearchMode } from "@src/types/document";
import { Email, EmailBrief } from "@src/types/email";
import {
  IMetadataDefinition,
  INewMetadataDefinition,
  INewRelatedMetadataDefinition,
  IUpdateMetadataDefinition,
} from "@src/types/metadata";
import {
  Company,
  CompanyGroup,
  CompanyPublic,
  CompanySettings,
  Group,
  UserBasic,
  UserInternal,
} from "@src/types/principal";
import {
  CollaboratorSettings,
  Project,
  ProjectStats,
} from "@src/types/project";
import { NewRegister, Register, RegisterOverview } from "@src/types/register";
import { Sandbox } from "@src/types/sandbox";
import { Session } from "@src/types/session";
import {
  Transmittal,
  TransmittalBrief,
  TransmittalNew,
  TransmittalUpdate as TransmittalUpdateModel,
} from "@src/types/transmittal";

interface IBaseApiRequestOptions {
  abortSignal?: AbortSignal;
}

export interface IApiRequestOptions extends IBaseApiRequestOptions {
  accessToken?: string;
}

// Admin requests
export const CreateNewCopany = (
  body: Api.Request.NewCompany,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/admin/new-company"),
    body,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const GetAdminCompany = (requestOptions?: IApiRequestOptions) => {
  return new RequestBuilder("GET", qualifyUrl("/admin/get-admin-company"))
    .authenticated(requestOptions?.accessToken)
    .abort(requestOptions?.abortSignal)
    .build<Company>();
};

export const GetCompanyGroups = (
  companyId: string,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/admin/get-company-groups"))
    .authenticated(requestOptions?.accessToken)
    .query("companyId", companyId)
    .abort(requestOptions?.abortSignal)
    .build<CompanyGroup[]>();
};

export const UpdateCompany = (
  body: Api.Request.UpdateCompany,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl("/admin/update-company"),
    body,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const MoveContactToCompany = (
  body: Api.Request.MoveContactToCompany,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/admin/move-contact"),
    body,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const RemoveUserFromCompany = (
  body: { userId: string; companyId: string },
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/admin/remove-user-from-company`),
    body,
    requestOptions?.accessToken
  );

export const GetCompanies = (
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 10000,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/admin/get-companies"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<CompanyPublic[]>();
};

export const GetAllUsers = (
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 500,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/admin/get-all-users"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<UserInternal[]>();
};

export const GetCompanyUsers = (
  companyId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 500,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/admin/get-company-users"))
    .authenticated(requestOptions?.accessToken)
    .query("companyId", companyId)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<UserInternal[]>();
};

export const SendUserInvite = (
  inviteUserRequest: Api.Request.SendInviteUser,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/admin/send-user-invite"),
    inviteUserRequest,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ApproveUser = (
  userId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/admin/approve-user/${userId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const UpdateUser = (
  userId: string,
  companyUser: Api.Request.UpdateCompanyUser,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/admin/update-user/${userId}`),
    companyUser,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const AddRemoveUserFromGroups = (
  userId: string,
  groupIds: Api.Request.AddRemoveUserFromGroups,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/admin/add-remove-users-from-group/${userId}`),
    groupIds,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const AllowedSuperAdminConsole = (
  userId: string,
  requestOptions?: IBaseApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl("/allowed-console"))
    .query("userId", userId)
    .abort(requestOptions?.abortSignal)
    .build<boolean>();
///Admin requests

/**
 * RegisterUser
 */

export const RegisterUser = (
  body: Api.Request.RegisterNewUser,
  requestOptions?: IBaseApiRequestOptions
) =>
  new RequestBuilder("POST", qualifyUrl("/register"))
    .json(body)
    .abort(requestOptions?.abortSignal)
    .build();
/**
 * Feature
 */
export const FeaturesGet = (requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Features>(
    "GET",
    qualifyUrl("/features"),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Account
 */
export const ActivateAccountNew = (
  body: Api.Request.ActivateNew,
  accessToken: string,
  requestOptions?: IBaseApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/account/activate/new"),
    body,
    accessToken,
    requestOptions?.abortSignal
  );

export const ActivateAccountExisting = (
  body: Api.Request.ActivateExisting,
  accessToken: string,
  requestOptions?: IBaseApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/account/activate/existing"),
    body,
    accessToken,
    requestOptions?.abortSignal
  );

export const ActivateAccountSendActivateEmail = (
  userId: string,
  requestOptions?: IBaseApiRequestOptions
) =>
  new RequestBuilder("POST", qualifyUrl("/account/signup/resend_email"))
    .json({ userId })
    .abort(requestOptions?.abortSignal)
    .build();
export const AccountPasswordReset = (
  email: string,
  requestOptions?: IBaseApiRequestOptions
) =>
  new RequestBuilder("POST", qualifyUrl("/account/request_password_reset"))
    .json({ email })
    .abort(requestOptions?.abortSignal)
    .build();
export const AccountPasswordUpdate = (
  oldPassword: string,
  newPassword: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/account/resetpassword"),
    { oldPassword, newPassword },
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Company
 */
export const CompaniesList = (
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/company"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<CompanyPublic[]>();
};

export const CompanyMeGet = (requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Company>(
    "GET",
    qualifyUrl("/company/me"),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyMeUpdate = (
  updatedCompany: Api.Request.CompanyUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl("/company/me"),
    updatedCompany,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyMeSettingsGet = (requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<CompanySettings>(
    "GET",
    qualifyUrl("/company/me/settings"),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyMeSettingsUpdate = (
  updatedCompanySettings: Api.Request.CompanySettingsUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl("/company/me/settings"),
    updatedCompanySettings,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyAccessGet = (
  extended: boolean = false,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/company/me/access"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.AclExtended, extended.toString())
    .abort(requestOptions?.abortSignal)
    .build<AccessControlList>();
};
export const CompanyAccessUpdate = (
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/company/me/access"),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyUserInvite = (
  inviteUserRequest: Api.Request.InviteUser,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/company/me/users"),
    inviteUserRequest,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyUserGetById = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<UserInternal>(
    "GET",
    qualifyUrl(`/company/me/users/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyUsersList = (
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 20,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/company/me/users"))
    .authenticated(requestOptions?.accessToken)
    .withPagination(page, perPage)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .abort(requestOptions?.abortSignal)
    .build<UserInternal[]>();
};
export const CompanyUserEnable = (
  userId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/company/me/users/${userId}/enable`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyUserDisable = (
  userId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/company/me/users/${userId}/disable`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CompanyUserRemove = (
  userId: string,
  body: { reason?: string },
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/company/me/users/${userId}/remove`),
    body,
    requestOptions?.accessToken
  );

export const CompanyLogoUpload = (
  blob: Blob,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("POST", qualifyUrl("/company/me/logo"))
    .authenticated(requestOptions?.accessToken)
    .blob(blob)
    .abort(requestOptions?.abortSignal)
    .build();

/**
 * Document
 */
export const DocumentAccessGet = (
  documentId: string,
  extended: boolean = false,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<AccessControlList>(
    "GET",
    qualifyUrl(`/documents/${documentId}/access`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const DocumentAccessUpdate = (
  documentId: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/documents/${documentId}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const DocumentsList = (
  projectId: string,
  mode: DocumentSearchMode = DocumentSearchMode.LatestNonArchived,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/documents`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.DocumentSearchMode, mode)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.DocumentsList>();
};
export const DocumentGet = (
  documentId: string,
  allRevisions: boolean = false,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/documents/${documentId}`))
    .authenticated(requestOptions?.accessToken)
    .query("allRevisions", allRevisions.toString())
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.Document>();

export const DocumentCreate = (
  projectId: string,
  newDocumentRequest: Api.Request.NewDocument,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Api.Response.Document>(
    "POST",
    qualifyUrl(`/projects/${projectId}/documents`),
    newDocumentRequest,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const DocumentRevise = (
  documentId: string,
  reviseDocumentRequest: Api.Request.ReviseDocument,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/documents/${documentId}/revisions`),
    reviseDocumentRequest,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const DocumentDelete = (
  documentId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/documents/${documentId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const DocumentCheckout = (
  documentId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/documents/${documentId}/checkout`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const DocumentCheckin = (
  documentId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/documents/${documentId}/checkout`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Email
 */
export const EmailsList = (
  projectId: string,
  filter?: string,
  cursor?: string,
  limit: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/projects/${projectId}/emails/`))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Cursor, cursor)
    .query(Query.Limit, limit.toString())
    .abort(requestOptions?.abortSignal)
    .build<EmailBrief[]>();

export const EmailGetById = (id: string, requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Email>(
    "GET",
    qualifyUrl(`/emails/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const EmailAccessGet = (
  id: string,
  includeExtended: boolean = false,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/emails/${id}/access`))
    .authenticated(requestOptions?.accessToken)
    .query(Query.AclExtended, includeExtended.toString())
    .abort(requestOptions?.abortSignal)
    .build<AccessControlList>();

export const EmailAccessUpdate = (
  id: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    `/emails/${id}/access`,
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const EmailDownloadAsPdf = (id: string) =>
  new RequestBuilder("GET", qualifyUrl(`/emails/${id}/download/pdf`))
    .authenticated()
    .responseType("arraybuffer")
    .build<ArrayBuffer>();

export const EmailDownloadLink = (accessToken: string, id: string) =>
  qualifyUrl(
    `/emails/${id}/download?access_token=${encodeURIComponent(accessToken)}`
  );

export const EmailDownloadAttachmentsLink = (
  accessToken: string,
  id: string,
  attachmentIndex?: number
) =>
  qualifyUrl(
    `/emails/${id}/download/attachments${
      attachmentIndex != null ? "/" + encodeURIComponent(attachmentIndex) : ""
    }?access_token=${encodeURIComponent(accessToken)}`
  );

export const EmailDownloadAsPdfLink = (accessToken: string, id: string) =>
  qualifyUrl(
    `/emails/${id}/download/pdf?access_token=${encodeURIComponent(accessToken)}`
  );

/**
 * Group
 */
export const GroupsList = (
  filter?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl("/groups"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Group[]>();

export const GroupCreate = (
  newGroup: Api.Request.GroupNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl("/groups"),
    newGroup,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const GroupUpdate = (
  id: string,
  updatedGroup: Api.Request.GroupUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/groups/${id}`),
    updatedGroup,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const GroupDelete = (id: string, requestOptions?: IApiRequestOptions) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/groups/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Project
 */
export const ProjectAccessGet = (
  id: string,
  includeExtended: boolean = false,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/projects/${id}/access`))
    .authenticated(requestOptions?.accessToken)
    .query(Query.AclExtended, includeExtended.toString())
    .abort(requestOptions?.abortSignal)
    .build<AccessControlList>();

export const ProjectAccessUpdate = (
  id: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/projects/${id}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectCreate = (
  newProject: Api.Request.ProjectNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Project>(
    "POST",
    qualifyUrl("/projects"),
    newProject,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectUpdate = (
  id: string,
  updatedProject: Api.Request.ProjectUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Project>(
    "PUT",
    qualifyUrl(`/projects/${id}`),
    updatedProject,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectMetadataAdd = (
  projectId: string,
  newDefinition: INewMetadataDefinition,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<IMetadataDefinition>(
    "POST",
    qualifyUrl(`/projects/${projectId}/documents/metadefinitions`),
    newDefinition,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectMetadataUpdate = (
  projectId: string,
  metadataKey: string,
  update: IUpdateMetadataDefinition,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(
      `/projects/${projectId}/documents/metadefinitions/${metadataKey}`
    ),
    update,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectMetadataRetire = (
  projectId: string,
  metadataKey: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(
      `/projects/${projectId}/documents/metadefinitions/${metadataKey}/retire`
    ),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectsGet = (id: string, requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Project>(
    "GET",
    qualifyUrl(`/projects/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectsList = (
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/projects"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Project[]>();
};
export const ProjectsListFavourites = (requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Project[]>(
    "GET",
    qualifyUrl("/projects/favourites"),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectSettingsUpdate = (
  id: string,
  updateSettings: Api.Request.CollaboratorSettingsUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${id}/settings`),
    updateSettings,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectSettingsDefaultAccessGet = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CollaboratorSettings>(
    "GET",
    qualifyUrl(`/projects/${id}/default_access`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export enum ProjectAccessEntityType {
  Document = "document",
  Revision = "revision",
  Communication = "communication",
  Transmittal = "transmittal",
  InboundEmail = "inbound_email",
  Register = "register",
}

export const ProjectSettingsDefaultAccessUpdate = (
  id: string,
  entityType: ProjectAccessEntityType,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/projects/${id}/default_access/${entityType}`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectSettingsDefaultAccessApply = (
  id: string,
  entityType: ProjectAccessEntityType,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/projects/${id}/default_access/${entityType}/apply`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectUsersList = (
  id: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/projects/${id}/users`))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<UserBasic[]>();

export const ProjectInvite = (
  id: string,
  email: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/projects/${id}/users?sendInvitation=true`),
    { email },
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectStatsGet = (
  ids: string[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<ProjectStats[]>(
    "GET",
    qualifyUrl(`/projects/${ids.join(",")}/stats`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectMySettingsUpdate = (
  id: string,
  updatedSettings: Api.Request.UserProjectSettingsUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${id}/my_settings`),
    updatedSettings,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const ProjectMySettingsPatch = (
  id: string,
  patch: Operation[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl(`/projects/${id}/my_settings`),
    patch,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Register
 */
export const RegistersList = (
  projectId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/registers`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<RegisterOverview[]>();
};
export const RegisterCreate = (
  projectId: string,
  body: NewRegister,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<RegisterOverview>(
    "POST",
    qualifyUrl(`/projects/${projectId}/registers`),
    body,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterGetById = (
  registerId: string,
  rowFilter?: string,
  rowSort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl(`/registers/${registerId}`))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, rowFilter)
    .query(Query.Sort, rowSort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Register>();
};
export const RegisterOverviewGetById = (
  registerId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<RegisterOverview>(
    "GET",
    qualifyUrl(`/registers/${registerId}/overview`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterAccessGet = (
  registerId: string,
  extended: boolean = false,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(`/registers/${registerId}/access`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.AclExtended, extended.toString())
    .abort(requestOptions?.abortSignal)
    .build<AccessControlList>();
};
export const RegisterAccessUpdate = (
  registerId: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/registers/${registerId}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterDelete = (
  registerId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/registers/${registerId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterColumnAdd = (
  registerId: string,
  column: Api.Request.RegisterColumnNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<IMetadataDefinition>(
    "POST",
    qualifyUrl(`/registers/${registerId}/columns`),
    column,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterColumnDelete = (registerId: string, columnKey: string) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/registers/${registerId}/columns/${columnKey}`)
  );
export const RegisterColumnUpdate = (
  registerId: string,
  columnKey: string,
  updatedColumn: Api.Request.RegisterColumnUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/registers/${registerId}/columns/${columnKey}`),
    updatedColumn,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterColumnMove = (
  registerId: string,
  columnKey: string,
  position: "first" | "last" | string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/registers/${registerId}/columns/${columnKey}/move`),
    { position },
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterRowsAdd = (
  registerId: string,
  rows: { cells: { [key: string]: any } }[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/registers/${registerId}/rows`),
    rows,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RegisterRowUpdate = (
  registerId: string,
  rowId: string,
  updatedRow: Api.Request.UpdateRegisterRow,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/registers/${registerId}/rows/${rowId}`),
    updatedRow
  );
export const RegisterRowPatch = (
  registerId: string,
  rowId: string,
  updatedRow: Api.Request.UpdateRegisterRow,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl(`/registers/${registerId}/rows/${rowId}`),
    updatedRow
  );
export const RegisterRowsDelete = (
  registerId: string,
  rowIds: string[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/registers/${registerId}/rows/${rowIds.join(",")}`),
    undefined,
    undefined,
    requestOptions?.abortSignal
  );
export const RegisterDownloadAsPdf = (registerId: string) =>
  new RequestBuilder("GET", qualifyUrl(`/registers/${registerId}/download/pdf`))
    .authenticated()
    .buildBlob();

/**
 * Revision
 */
export const RevisionAccessGet = (
  revisionId: string,
  extended: boolean = false,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(`/revisions/${revisionId}/access`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.AclExtended, extended.toString())
    .abort(requestOptions?.abortSignal)
    .build<AccessControlList>();
};
export const RevisionAccessUpdate = (
  revisionId: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/revisions/${revisionId}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RevisionAccessBulkUpdate = (
  revisionIds: string[],
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/revisions/${revisionIds.join(",")}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const RevisionsList = (
  projectId?: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(projectId ? `/projects/${projectId}/revisions` : "/revisions")
  )
    .authenticated(requestOptions?.accessToken)
    .query("filter", filter)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.RevisionListSingleProject>();
};
export const RevisionsListAllProjects = (
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/revisions"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.RevisionListMultiProject>();
};
export const RevisionsListRecentlyViewed = (
  filter?: string,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder("GET", qualifyUrl("/revisions/recent"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.RevisionListMultiProject>();
};
export const RevisionGetById = (
  revisionId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Api.Response.RevisionSingle>(
    "GET",
    qualifyUrl(`/revisions/${revisionId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RevisionUpdate = (
  revisionId: string,
  revisionUpdate: Api.Request.RevisionUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/revisions/${revisionId}`),
    revisionUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RevisionPatch = (
  revisionId: string,
  revisionPatch: Operation[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl(`/revisions/${revisionId}`),
    revisionPatch,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RevisionsDownload = (
  projectId: string,
  revisionIds: string[],
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(
      `/projects/${projectId}/revisions/${revisionIds.join(",")}/download`
    )
  )
    .authenticated(requestOptions?.accessToken)
    .responseType("blob")
    .abort(requestOptions?.abortSignal)
    .build<Blob>();
};
export const RevisionApprove = (
  revisionId: string,
  comments: string = "",
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/revisions/${revisionId}/approve`),
    { comments },
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RevisionReject = (
  revisionId: string,
  comments: string = "",
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/revisions/${revisionId}/reject`),
    { comments },
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RevisionDelete = (
  revisionId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/revisions/${revisionId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const RevisionsDownloadLink = (
  accessToken: string,
  projectId: string,
  ...revisionIds: string[]
) =>
  qualifyUrl(
    `/projects/${projectId}/revisions/${revisionIds.join(
      ","
    )}/download?access_token=${accessToken}`
  );

/**
 * Sandbox
 */
export const SandboxListFiles = (requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Sandbox.File[]>(
    "GET",
    qualifyUrl("/sandbox"),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const SandboxSignUpload = (
  body: Api.Request.SignUpload,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Api.Response.SignUpload>(
    "POST",
    qualifyUrl("/sandbox"),
    body,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const SandboxDeleteFile = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/sandbox/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const TransmittalsList = (
  projectId?: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(
      projectId ? `/projects/${projectId}/transmittals` : "/transmittals"
    )
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<TransmittalBrief[]>();

/**
 * Transmittal
 */
export const TransmittalCreate = (
  projectId: string,
  newTransmittal: TransmittalNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Transmittal>(
    "POST",
    qualifyUrl(`/projects/${projectId}/transmittals`),
    newTransmittal,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TransmittalDelete = (
  transmittalId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/transmittals/${transmittalId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TransmittalGetById = (
  transmittalId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Transmittal>(
    "GET",
    qualifyUrl(`/transmittals/${transmittalId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TransmittalUpdate = (
  transmittalId: string,
  updatedTransmittal: TransmittalUpdateModel,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/transmittals/${transmittalId}`),
    updatedTransmittal,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TransmittalAccessGet = (
  transmittalId: string,
  extended: boolean = false,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(`/transmittals/${transmittalId}/access`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.AclExtended, extended.toString())
    .abort(requestOptions?.abortSignal)
    .build<AccessControlList>();
};
export const TransmittalAccessUpdate = (
  transmittalId: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/transmittals/${transmittalId}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TransmittalPublish = (
  transmittalId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/transmittals/${transmittalId}/publish`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TransmittalApprove = (
  transmittalId: string,
  approval: { comments: string },
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/transmittals/${transmittalId}/approve`),
    approval,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TransmittalAttachmentsDownloadLink = (
  accessToken: string,
  transmittalId: string
) =>
  qualifyUrl(
    `/transmittals/${transmittalId}/download?access_token=${encodeURIComponent(
      accessToken
    )}`
  );
export const TransmittalRevisionsList = (
  transmittalId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) => {
  return new RequestBuilder(
    "GET",
    qualifyUrl(`/transmittals/${transmittalId}/revisions`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.RevisionListSingleProject>();
};

/**
 * User
 */
export const UserProfileGet = (requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Session.User>(
    "GET",
    qualifyUrl("/profile"),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const UserProfilePatch = (
  patch: Operation[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl("/profile"),
    patch,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const UserProfilePictureUpload = (
  blob: Blob,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("POST", qualifyUrl("/profile/picture"))
    .authenticated(requestOptions?.accessToken)
    .blob(blob)
    .abort(requestOptions?.abortSignal)
    .build();
export const UserProfileSignatureUpload = (
  blob: Blob,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("POST", qualifyUrl("/profile/signature"))
    .authenticated(requestOptions?.accessToken)
    .blob(blob)
    .abort(requestOptions?.abortSignal)
    .build();

/**
 * Cost
 */
export const CostsGet = (
  projectId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostsOverview>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostsCreateEmpty = (
  projectId: string,
  defaults: boolean = true,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("POST", qualifyUrl(`/projects/${projectId}/costs`))
    .authenticated(requestOptions?.accessToken)
    .query("defaults", defaults.toString())
    .abort(requestOptions?.abortSignal)
    .build<CostsOverview>();
export const CostsCreateFromProject = (
  projectId: string,
  sourceProjectId: string,
  copyOptions: Api.Request.CopyCostsOptions,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostsOverview>(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/copy/${sourceProjectId}`),
    copyOptions,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostsAccessGet = (
  projectId: string,
  extended: boolean = false,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/projects/${projectId}/costs/access`))
    .authenticated(requestOptions?.accessToken)
    .query(Query.AclExtended, extended.toString())
    .abort(requestOptions?.abortSignal)
    .build<AccessControlList>();
export const CostsAccessUpdate = (
  projectId: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostsSettingsGet = (
  projectId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostsSettings>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/settings`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostsSettingsUpdate = (
  projectId: string,
  costSettingsUpdate: Api.Request.CostsSettingsUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${projectId}/costs/settings`),
    costSettingsUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Commitment
 */
export const CommitmentDefinitionCreate = (
  projectId: string,
  newCommitmentDefinition: Api.Request.CommitmentDefinitionNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CommitmentDefinition>(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/commitment_definitions`),
    newCommitmentDefinition,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentDefinitionGet = (
  projectId: string,
  key: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CommitmentDefinition>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/commitment_definitions/${key}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentDefinitionsList = (
  projectId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CommitmentDefinition[]>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/commitment_definitions`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentDefinitionUpdate = (
  projectId: string,
  key: string,
  commitmentDefinitionUpdate: Api.Request.CommitmentDefinitionUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${projectId}/costs/commitment_definitions/${key}`),
    commitmentDefinitionUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentDefinitionPatch = (
  projectId: string,
  key: string,
  commitmentDefinitionUpdate: Operation[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl(`/projects/${projectId}/costs/commitment_definitions/${key}`),
    commitmentDefinitionUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentDefinitionDelete = (
  projectId: string,
  key: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/projects/${projectId}/costs/commitment_definitions/${key}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Cost Code
 */
export const CostCodeCreate = (
  projectId: string,
  newCostCode: Api.Request.CostCodeNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostCode>(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/codes`),
    newCostCode,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostCodesList = (
  projectId: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/projects/${projectId}/costs/codes`))
    .authenticated(requestOptions?.accessToken)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<CostCodeBrief[]>();
export const CostCodeGet = (
  projectId: string,
  code: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostCode>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/codes/${code}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostCodeUpdate = (
  projectId: string,
  code: string,
  costCodeUpdate: Api.Request.CostCodeUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${projectId}/costs/codes/${code}`),
    costCodeUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostCodeDelete = (
  projectId: string,
  code: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/projects/${projectId}/costs/codes/${code}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Phase
 */
export const PhaseCreate = (
  projectId: string,
  newPhase: Phase,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Phase>(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/phases`),
    newPhase,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PhaseGet = (
  projectId: string,
  phase: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Phase>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/phases/${phase}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PhasesList = (
  projectId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Phase[]>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/phases`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PhaseUpdate = (
  projectId: string,
  phase: string,
  phaseUpdate: Api.Request.PhaseUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${projectId}/costs/phases/${phase}`),
    phaseUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PhaseDelete = (
  projectId: string,
  phase: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/projects/${projectId}/costs/phases/${phase}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Budget
 */
export const BudgetUpdate = (
  projectId: string,
  budget: BudgetOverview,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${projectId}/costs/budget`),
    budget,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const BudgetPatch = (
  projectId: string,
  budgetPatch: Operation[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl(`/projects/${projectId}/costs/budget`),
    budgetPatch,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const BudgetItemsList = (
  projectId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/budget/items`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.BudgetItemList>();
export const BudgetItemCreate = (
  projectId: string,
  newBudgetItem: Api.Request.BudgetItemNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/budget/items`),
    newBudgetItem,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const BudgetItemUpdate = (
  projectId: string,
  itemId: string,
  updatedBudgetItem: Api.Request.BudgetItemUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${projectId}/costs/budget/items/${itemId}`),
    updatedBudgetItem,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const BudgetItemDelete = (
  projectId: string,
  budgetItemId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/projects/${projectId}/costs/budget/items/${budgetItemId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CommitmentsList = (
  projectId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/commitments`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.CommitmentList>();
export const CommitmentsListByType = (
  projectId: string,
  type: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/commitments/types/${type}`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.CommitmentList>();
export const CommitmentGet = (
  projectId: string,
  commitmentId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Commitment>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/commitments/${commitmentId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentCreate = (
  projectId: string,
  newCommitment: Api.Request.CommitmentNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Commitment>(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/commitments`),
    newCommitment,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentUpdate = (
  projectId: string,
  commitmentId: string,
  commitmentUpdate: Api.Request.CommitmentUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/projects/${projectId}/costs/commitments/${commitmentId}`),
    commitmentUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentItemsUpdate = (
  projectId: string,
  commitmentId: string,
  commitmentItemsUpdate: Api.Request.CommitmentItemsUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/items`
    ),
    commitmentItemsUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentDelete = (
  projectId: string,
  commitmentId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/projects/${projectId}/costs/commitments/${commitmentId}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CommitmentItemsList = (
  projectId: string,
  commitmentId: string,
  subItems: boolean = false,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/commitments/${commitmentId}/items`)
  )
    .authenticated(requestOptions?.accessToken)
    .query("subItems", subItems.toString())
    .abort(requestOptions?.abortSignal)
    .build<CommitmentItemWithParentRef[]>();
export const CommitmentItemCreate = (
  projectId: string,
  commitmentId: string,
  newCommitmentItem: Api.Request.CommitmentItemNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/items`
    ),
    newCommitmentItem,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentItemUpdate = (
  projectId: string,
  commitmentId: string,
  commitmentItemId: string,
  updateCommitmentItem: Api.Request.CommitmentItemUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/items/${commitmentItemId}`
    ),
    updateCommitmentItem,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentItemDelete = (
  projectId: string,
  commitmentId: string,
  itemId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/items/${itemId}`
    ),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentInsuranceCreate = (
  projectId: string,
  commitmentId: string,
  newInsurance: Api.Request.CommitmentInsuranceNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/insurances`
    ),
    newInsurance,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentInsuranceDelete = (
  projectId: string,
  commitmentId: string,
  insuranceId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/insurances/${insuranceId}`
    ),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommitmentInsuranceDownloadLink = (
  projectId: string,
  commitmentId: string,
  insuranceId: string,
  accessToken: string
) =>
  qualifyUrl(
    `/projects/${projectId}/costs/commitments/${commitmentId}/insurances/${insuranceId}/download?access_token=${encodeURIComponent(
      accessToken
    )}`
  );

export enum AggregateBy {
  CostCodeGroup = "costCodeGroup",
  Phase = "phase",
}

export const CostReportExcelLink = (accessToken: string, projectId: string) =>
  qualifyUrl(
    `/projects/${projectId}/costs/reporting/download?access_token=${encodeURIComponent(
      accessToken
    )}`
  );
export const CostReportPdf = (
  projectId: string,
  type: AggregateBy,
  phases?: string[],
  includeUnassigned?: boolean,
  includeUnusedCostCodes?: boolean
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/reporting/download/pdf`)
  )
    .authenticated()
    .query("type", type?.toString())
    .query("phases", phases?.join(","))
    .query("includeUnassigned", includeUnassigned?.toString())
    .query("includeUnusedCostCodes", includeUnusedCostCodes?.toString())
    .buildBlob();

export const CostDashboardExcelLink = (
  accessToken: string,
  projectId: string
) =>
  qualifyUrl(
    `/projects/${projectId}/costs/snapshots/current/download?access_token=${encodeURIComponent(
      accessToken
    )}`
  );

export const PaymentClaimsList = (
  projectId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/payment_claims`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<Api.Response.PaymentClaimList>();
export const PaymentClaimGet = (
  projectId: string,
  commitmentId: string,
  paymentClaimId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<PaymentClaim>(
    "GET",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/payment_claims/${paymentClaimId}`
    )
  );
export const PaymentClaimCreate = (
  projectId: string,
  commitmentId: string,
  newPaymentClaim: Api.Request.PaymentClaimNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/payment_claims`
    ),
    newPaymentClaim,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PaymentClaimUpdate = (
  projectId: string,
  commitmentId: string,
  paymentClaimId: string,
  paymentClaimUpdate: Api.Request.PaymentClaimUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/payment_claims/${paymentClaimId}`
    ),
    paymentClaimUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PaymentClaimPatch = (
  projectId: string,
  commitmentId: string,
  paymentClaimId: string,
  paymentClaimPatch: Operation[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/payment_claims/${paymentClaimId}`
    ),
    paymentClaimPatch,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PaymentClaimLock = (
  projectId: string,
  commitmentId: string,
  paymentClaimId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/payment_claims/${paymentClaimId}/lock`
    ),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PaymentClaimUnlock = (
  projectId: string,
  commitmentId: string,
  paymentClaimId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/payment_claims/${paymentClaimId}/unlock`
    ),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const PaymentClaimDelete = (
  projectId: string,
  commitmentId: string,
  paymentClaimId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(
      `/projects/${projectId}/costs/commitments/${commitmentId}/payment_claims/${paymentClaimId}`
    ),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const CostSnapshotCurrentGet = (
  projectId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostReportSnapshot>(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/reporting/snapshots/current`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostSnapshotGet = (
  projectId: string,
  snapshotId: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostReportSnapshot>(
    "GET",
    qualifyUrl(
      `/projects/${projectId}/costs/reporting/snapshots/${snapshotId}`
    ),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostSnapshotsList = (
  projectId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder(
    "GET",
    qualifyUrl(`/projects/${projectId}/costs/reporting/snapshots`)
  )
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<CostReportSnapshotBrief[]>();
export const CostSnapshotCreate = (
  projectId: string,
  newSnapshot: Api.Request.CostReportSnapshotNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<CostReportSnapshot>(
    "POST",
    qualifyUrl(`/projects/${projectId}/costs/reporting/snapshots`),
    newSnapshot,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CostSnapshotDownloadLink = (
  projectId: string,
  snapshotIds: string[],
  accessToken: string
) =>
  qualifyUrl(
    `/projects/${projectId}/costs/reporting/snapshots/${snapshotIds.join(
      ","
    )}/download?access_token=${encodeURIComponent(accessToken)}`
  );

export const CommunicationsList = (
  projectId: string,
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl(`/projects/${projectId}/communications`))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<CommunicationBrief[]>();
export const CommunicationCreate = (
  projectId: string,
  newCommunication: Api.Request.CommunicationNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Communication>(
    "POST",
    qualifyUrl(`/projects/${projectId}/communications`),
    newCommunication,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommunicationGet = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Communication>(
    "GET",
    qualifyUrl(`/communications/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommunicationAccessGet = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<AccessControlList>(
    "GET",
    qualifyUrl(`/communications/${id}/access`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommunicationAccessUpdate = (
  id: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/communications/${id}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommunicationPreview = (
  id: string,
  previewOptions: Api.Request.CommunicationPreviewOverride,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<string>(
    "POST",
    qualifyUrl(`/communications/${id}/preview`),
    previewOptions,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommunicationPreviewPdfLink = (accessToken: string, id: string) =>
  qualifyUrl(
    `/communications/${id}/preview?access_token=${encodeURIComponent(
      accessToken
    )}`
  );
export const CommunicationPreviewPdfBlob = (id: string) =>
  new RequestBuilder("GET", qualifyUrl(`/communications/${id}/preview`))
    .authenticated()
    .buildBlob();
export const CommunicationUpdate = (
  id: string,
  communicationUpdate: Api.Request.CommunicationUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PUT",
    qualifyUrl(`/communications/${id}`),
    communicationUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const CommunicationDelete = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/communications/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const TemplatesList = (
  filter?: string,
  sort?: string,
  page: number = 1,
  perPage: number = 50,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl("/communications/templates"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .withPagination(page, perPage)
    .abort(requestOptions?.abortSignal)
    .build<TemplateBrief[]>();
export const TemplateGet = (id: string, requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<Template>(
    "GET",
    qualifyUrl(`/communications/templates/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TemplateCreate = (
  newTemplate: Api.Request.TemplateNew,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<Template>(
    "POST",
    qualifyUrl("/communications/templates"),
    newTemplate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TemplatePatch = (
  id: string,
  templatePatch: Operation[],
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "PATCH",
    qualifyUrl(`/communications/templates/${id}`),
    templatePatch,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TemplateAccessGet = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest<AccessControlList>(
    "GET",
    qualifyUrl(`/communications/templates/${id}/access`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TemplateAccessUpdate = (
  id: string,
  accessUpdate: Api.Request.AccessControlListUpdate,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "POST",
    qualifyUrl(`/communications/templates/${id}/access`),
    accessUpdate,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );
export const TemplateDelete = (
  id: string,
  requestOptions?: IApiRequestOptions
) =>
  authenticatedRequest(
    "DELETE",
    qualifyUrl(`/communications/templates/${id}`),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

export const NotificationsList = (requestOptions?: IApiRequestOptions) =>
  authenticatedRequest<{
    notificaitons: { id: string; title: string; description: string }[];
    unreadCount: number;
  }>(
    "GET",
    qualifyUrl("/notifications"),
    undefined,
    requestOptions?.accessToken,
    requestOptions?.abortSignal
  );

/**
 * Global Search
 */
export const SearchUsers = (
  filter?: string,
  sort?: string,
  limit?: number,
  cursor?: string,
  requestOptions?: IApiRequestOptions
) =>
  new RequestBuilder("GET", qualifyUrl("/users"))
    .authenticated(requestOptions?.accessToken)
    .query(Query.Filter, filter)
    .query(Query.Sort, sort)
    .query(Query.Limit, limit?.toString())
    .query(Query.Cursor, cursor)
    .abort(requestOptions?.abortSignal)
    .build<UserBasic[]>();
