import React, { useState } from 'react'
import { Field, useField } from 'react-final-form'
import { OnBlur, OnChange } from 'react-final-form-listeners'
import { Button, Card, CardBody, CardHeader, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import BigNumber from 'bignumber.js'

import Action from '@src/components/common/Action'
import ClampLines from '@src/components/common/ClampLines'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedTextArea from '@src/components/common/ValidatedTextArea'
import CostValue from '@src/components/costs/common/CostValue'
import { getFormItemId, getFormTypeId } from '@src/components/costs/payments/PaymentClaimItemsTableForm'
import { FieldPrefix, FieldPrefixContext, PrefixedField } from '@src/components/forms/FieldPrefix'
import GenericTable, { ITableHeader } from '@src/components/table/GenericTable'
import useModal from '@src/hooks/useModal'
import { normalizePercent } from '@src/logic/forms/normalization'
import * as Routes from '@src/logic/routing/routes'
import { toPercent } from '@src/logic/utils/Strings'
import { mutedValue, valueOrMutedFallback } from '@src/logic/utils/ValueHelper'
import { CostsOverview, PaymentClaimItem, PaymentClaimStatus } from '@src/types/costs'

interface ItemsTableProps {
    commitmentType: string
    costsOverview: CostsOverview
    items: PaymentClaimItem[]
    paymentClaimStatus: PaymentClaimStatus
    projectId: string
    tableName: string
}

export interface IPaymentClaimItemFormData {
    commitmentItemId: string
    determined: number
    determinedPct: number
    previouslyCertified: number
    value: number
    claimed: number
    certified: number
    paid: number
    gst: number
    reason: string
}

export default function PaymentItemsTable({ commitmentType, costsOverview, items, paymentClaimStatus, projectId, tableName }: ItemsTableProps) {
    const [editReasonField, setEditReasonField] = useState<string>()
    const reasonModal = useModal(false, { onClose: () => setEditReasonField(null), destroyOnClose: true })

    function editReason(name: string) {
        setEditReasonField(name)
        reasonModal.actions.show()
    }

    function calculateCertifiedThisPayment(determined: number, previouslyCertified: number) {
        return new BigNumber(determined ?? 0).minus(previouslyCertified).toNumber()
    }

    const headers = React.useMemo<ITableHeader<PaymentClaimItem>[]>(
        () => [
            {
                // Name of the commitment item
                name: 'Name',
                overrideRenderer: (item) => (
                    <Link to={Routes.projectCostsCommitmentTypeDetail(projectId, commitmentType, item.commitmentId)}>
                        <ClampLines lines={1} text={item.commitmentItem.name} />
                    </Link>
                ),
                footer: () => <strong>SUB-TOTAL</strong>
            },
            {
                // other party ref on commitment item
                name: 'Other Party Ref',
                overrideRenderer: item => valueOrMutedFallback(item.commitmentItem.otherPartyRef, '-')
            },
            {
                name: 'Status',
                overrideRenderer: item => item.commitmentItem.status
            },
            {
                name: 'Value',
                overrideRenderer: item => <CostValue value={item.value} />,
                footer: items => <CostValue value={items.reduce((agg, p) => agg + p.value, 0)} />
            },
            {
                name: 'Determined %',
                overrideRenderer: item => (
                    <FieldPrefix prefix={getFormItemId(item.commitmentItem.id)}>
                        <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="percentage" /></InputGroupText></InputGroupAddon>
                            <PrefixedField disabled={paymentClaimStatus !== PaymentClaimStatus.Entered} name="determinedPct" component={ValidatedInput} format={normalizePercent} formatOnBlur type="number" step="any" subscription={{ value: true }} />
                            <PrefixedField<number> name="determined" subscription={{ value: true, active: true }}>
                                {({ input, meta: { active } }) => (
                                    <>
                                        <OnChange name={`${input.name.substring(0, input.name.lastIndexOf('.'))}.determinedPct`}>
                                            {(value) => {
                                                if (!active) {
                                                    input.onChange(new BigNumber(item.value).multipliedBy(value || 0).dividedBy(100).decimalPlaces(2).toNumber() || 0)
                                                }
                                            }}
                                        </OnChange>
                                        <PrefixedField<number> name="determinedPct" subscription={{}}>
                                            {determinedPctField =>
                                                <OnBlur name={`${input.name.substring(0, input.name.lastIndexOf('.'))}.determinedPct`}>
                                                    {() => determinedPctField.input.onChange(new BigNumber(input.value).div(item.value).times(100).decimalPlaces(2).toNumber() || 0)}
                                                </OnBlur>
                                            }
                                        </PrefixedField>
                                    </>
                                )}
                            </PrefixedField>
                        </InputGroup>
                    </FieldPrefix>
                ),
                footer: () => (
                    <Field name={`totals.${getFormTypeId(commitmentType)}.determinedPct`} format={normalizePercent} subscription={{ value: true }}>
                        {({ input: { value } }) => (
                            <div className="text-center">
                                {value}%
                            </div>
                        )}
                    </Field>
                )
            },
            {
                name: 'Determined Value',
                overrideRenderer: item => (
                    <FieldPrefix prefix={getFormItemId(item.commitmentItem.id)}>
                        <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="dollar-sign" /></InputGroupText></InputGroupAddon>
                            <PrefixedField disabled={paymentClaimStatus !== PaymentClaimStatus.Entered} name="determined" component={ValidatedCurrencyInput} />
                            <PrefixedField name="determinedPct" subscription={{}}>
                                {({ input }) => (
                                    <>
                                        <OnChange name={`${input.name.substring(0, input.name.lastIndexOf('.'))}.determined`}>
                                            {(value, prev) => {
                                                if (value !== prev) {
                                                    input.onChange(new BigNumber(value).dividedBy(item.value).multipliedBy(100).decimalPlaces(2).toNumber() || 0)
                                                }
                                            }}
                                        </OnChange>
                                    </>
                                )}
                            </PrefixedField>
                        </InputGroup>
                    </FieldPrefix>
                ),
                footer: _ => (
                    <Field<number> name={`totals.${getFormTypeId(commitmentType)}.determined`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue value={value} />}
                    </Field>
                )
            },
            {
                name: 'Previously Certified',
                overrideRenderer: item => <CostValue value={new BigNumber(item.previouslyCertified).toNumber()} />,
                footer: () => (
                    <Field<number> name={`totals.${getFormTypeId(commitmentType)}.previouslyCertified`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue value={value} />}
                    </Field>
                )
            },
            {
                name: 'Certified',
                overrideRenderer: item => (
                    <FieldPrefix prefix={getFormItemId(item.commitmentItem.id)}>
                        <PrefixedField<number> name="certified" subscription={{ value: true }}>
                            {({ input }) => (
                                <>
                                    <CostValue value={input.value} />
                                    <OnChange name={`${input.name.substring(0, input.name.lastIndexOf('.'))}.determined`}>
                                        {determinedValue => input.onChange(calculateCertifiedThisPayment(determinedValue, item.previouslyCertified))}
                                    </OnChange>
                                </>
                            )}
                        </PrefixedField>
                    </FieldPrefix>
                ),
                footer: () => (
                    <Field<number> name={`totals.${getFormTypeId(commitmentType)}.certified`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue value={value} />}
                    </Field>
                )
            },
            {
                name: 'Claimed',
                overrideRenderer: item => (
                    <PrefixedField disabled={paymentClaimStatus !== PaymentClaimStatus.Entered} name={`${getFormItemId(item.commitmentItem.id)}.claimed`} component={ValidatedCurrencyInput} bsSize="sm" />
                ),
                footer: () => (
                    <Field<number> name={`totals.${getFormTypeId(commitmentType)}.claimed`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue value={value} />}
                    </Field>
                )
            },
            {
                name: 'Variance',
                overrideRenderer: item => (
                    <PrefixedField<number> name={`${getFormItemId(item.commitmentItem.id)}.variance`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue value={value} errorNonZero />}
                    </PrefixedField>
                ),
                footer: () => (
                    <Field<number> name={`totals.${getFormTypeId(commitmentType)}.variance`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue value={value} errorNonZero />}
                    </Field>
                )
            },
            {
                name: 'Paid',
                overrideRenderer: item => <PrefixedField name={`${getFormItemId(item.commitmentItem.id)}.paid`} component={ValidatedCurrencyInput} bsSize="sm" />,
                footer: _ => (
                    <Field<number> name={`totals.${getFormTypeId(commitmentType)}.paid`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue className="mx-1" value={value} />}
                    </Field>
                )
            },
            {
                name: 'GST',
                overrideRenderer: item =>
                    <div className="d-flex justify-content-center">
                        <PrefixedField<boolean> name={`${getFormItemId(item.commitmentItem.id)}.gst`} allowNull subscription={{ value: true, dirty: true }}>
                            {field => {
                                const determinedFieldName = `${field.input.name.substring(0, field.input.name.lastIndexOf('.'))}.determined`
                                const determinedField = useField(determinedFieldName, { subscription: { value: true } })
                                function calculateGst(rate: number, determined: number, previouslyCertified: number) {
                                    return new BigNumber(rate).multipliedBy(determined - previouslyCertified).toNumber()
                                }

                                function handleGstChange(value: boolean) {
                                    field.input.onChange(value ? calculateGst(costsOverview.settings.gstRate, determinedField.input.value, item.previouslyCertified) : null)
                                }

                                return (
                                    <>
                                        <ValidatedCheckboxRadio meta={field.meta} input={{ ...field.input, value: field.input.value != null, onChange: handleGstChange }} disabled={paymentClaimStatus !== PaymentClaimStatus.Entered} />
                                        <OnChange name={determinedFieldName}>
                                            {(value) => {
                                                if (field.input.value != null) field.input.onChange(calculateGst(field.meta.dirty || item.gst == null ? costsOverview.settings.gstRate : item.gst, value, item.previouslyCertified))
                                            }}
                                        </OnChange>
                                    </>
                                )
                            }}
                        </PrefixedField>
                        {item.gst != null && item.gst !== costsOverview.settings.gstRate ? mutedValue(toPercent(item.gst)) : null}
                    </div>,
                footer: () => (
                    <Field<number> name={`totals.${getFormTypeId(commitmentType)}.gst`} subscription={{ value: true }}>
                        {({ input: { value } }) => <CostValue value={value} />}
                    </Field>
                )
            },
            {
                name: 'Reason',
                overrideRenderer: item => (
                    <InputGroup size="sm">
                        <PrefixedField name={`${getFormItemId(item.commitmentItem.id)}.reason`} component={ValidatedInput} subscription={{ value: true }} />
                        <InputGroupAddon addonType="append">
                            <FieldPrefixContext.Consumer>
                                {prefix => <Action tag={Button} outline color="primary" data={`${prefix}.${getFormItemId(item.commitmentItem.id)}.reason`} onClick={editReason}><FA icon="external-link-alt" /></Action>}
                            </FieldPrefixContext.Consumer>
                        </InputGroupAddon>
                    </InputGroup>
                )
            }
        ],
        [items])

    return (
        <Card key={`table-form-${tableName}`} className="mb-3">
            <CardHeader>{tableName}</CardHeader>
            <CardBody>
                <GenericTable<PaymentClaimItem, never>
                    className="table-sm small"
                    data={items}
                    headers={headers}
                />
                <Modal {...reasonModal.modalProps}>
                    <ModalHeader toggle={reasonModal.actions.toggle}>{items.find(x => getFormItemId(x.commitmentItem.id) === editReasonField?.split('.')[2])?.commitmentItem.name}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="edit-reason-popout">Reason</Label>
                            <Field id="edit-reason-popout" name={editReasonField} component={ValidatedTextArea} tag="textarea" rows={4} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={reasonModal.actions.toggle} color="primary">Close</Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
        </Card>
    )
}
