import React from 'react'
import { Button } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import Tags from '@src/components/common/Tags'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import NewRegisterModal from '@src/components/register/NewRegisterModal'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import useModal from '@src/hooks/useModal'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations, RegisterOperations } from '@src/logic/auth/operations'
import { RegisterDelete, RegistersList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { Project } from '@src/types/project'
import { RegisterOverview } from '@src/types/register'

interface IProps {
    project: Project
}

const noRegistersFoundMessage = (
    <>
        <div className="my-3"><FA size="3x" icon="file-times" /></div>
        <p className="lead">No registers found with the current search criteria...</p>
        <p>{"Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters."}</p>
    </>
)

export default function RegistersSection({ project }: IProps) {
    const searchSectionRef = React.createRef<SearchSectionType<RegisterOverview, never>>()
    const newRegisterModal = useModal(false, { onClose: clearRegisterToDelete })
    const [registerToDelete, setRegisterToDelete] = React.useState<RegisterOverview>()

    async function onSearch(filter: string, sort: string, page: number, perPage: number) {
        const response = await RegistersList(project.id, filter, sort, page, perPage)
        return ({
            items: response.data,
            totalItems: +response.headers[Headers.PaginationTotalCount]
        })
    }

    async function deleteRegister() {
        try {
            await RegisterDelete(registerToDelete.id)
            NotificationService.info('Deleted register')
        } catch (e) {
            if (isAxiosError(e)) {
                if (e.response) {
                    switch (e.response.status) {
                        case 403:
                            NotificationService.error('You do not have permission to delete the register.')
                            break
                        case 404:
                            NotificationService.error('Could not find the register to delete.')
                            break
                        default:
                            NotificationService.error('An error occurred while deleting the register.')
                            break
                    }
                } else {
                    NotificationService.error('Encountered network connection issues while deleting the register.')
                }
            } else {
                NotificationService.error('An error occurred while deleting the register.')
            }
        }

        setRegisterToDelete(undefined)
        searchSectionRef.current?.doSearch()
    }

    function clearRegisterToDelete() {
        setRegisterToDelete(undefined)
    }

    return (
        <SearchSection<RegisterOverview, never>
            ref={searchSectionRef}
            headers={[
                {
                    name: 'Name',
                    sortKey: 'name',
                    sortable: true,
                    overrideRenderer: register => <Link to={Routes.projectRegister(project.id, register.id)}>{register.name}</Link>
                },
                {
                    name: 'Description',
                    accessor: 'description',
                    sortKey: 'description',
                    sortable: true
                },
                {
                    name: 'Tags',
                    overrideRenderer: register => <Tags tags={register.tags} />
                },
                {
                    name: 'Created',
                    sortKey: 'created',
                    sortable: true,
                    overrideRenderer: register => localShortDate(register.createdDate)
                },
                {
                    name: 'Actions',
                    noSmallHeader: true,
                    headerWrapperClass: 'text-right',
                    overrideRenderer: r => (
                        <div className="justify-content-end text-right selectable-content__actions">
                            <TooltipLinkAction id={'delete-' + r.id} tooltip="Delete" disabled={!isAuthorised(r.myAccess, RegisterOperations.Delete)} data={r} onClick={setRegisterToDelete}><FA icon="trash" /></TooltipLinkAction>
                        </div>
                    )
                }
            ]}
            extraSearchBarElements={[
                {
                    element: _ => (
                        <Button disabled={!isAuthorised(project.myAccess, ProjectOperations.CreateRegister)} onClick={newRegisterModal.actions.show}>
                            <FA icon="plus" className="mr-1" />New Register
                        </Button>
                    ),
                    position: 'before'
                }
            ]}
            onSearch={onSearch}
            noItemsFoundMessage={noRegistersFoundMessage}
            searchAssistantProperties={[
                {
                    name: 'Name',
                    searchKey: 'name',
                    type: PropertyType.Text
                },
                {
                    name: 'Description',
                    searchKey: 'description',
                    type: PropertyType.Text
                },
                {
                    name: 'Tags',
                    searchKey: 'tags',
                    type: PropertyType.Text
                },
                {
                    name: 'Created',
                    searchKey: 'created',
                    type: PropertyType.Date
                }
            ]}
        >
            <ConfirmationModal
                danger
                isOpen={registerToDelete != null}
                header="Delete register"
                message={<span>Are you sure you want to delete <strong>{registerToDelete?.name}</strong>?</span>}
                confirmAction="Delete"
                onConfirm={deleteRegister}
                toggle={clearRegisterToDelete}
                onReject={clearRegisterToDelete}
            />
            <NewRegisterModal projectId={project.id} {...newRegisterModal.modalProps} />
        </SearchSection>
    )
}
