import React from 'react'

import { useSpreadsheetStoreApi } from '@src/components/spreadsheet/SpreadsheetContext'

export function useColumnWidthListener<T extends HTMLElement = HTMLElement>(columnId: string, ref: React.RefObject<T>) {
    const spreadsheetStore = useSpreadsheetStoreApi()

    const setWidth = React.useCallback((width: number) => {
        ref.current.style.width = `${width}px`
        ref.current.style.minWidth = `${width}px`
    }, [ref.current])

    React.useEffect(() => spreadsheetStore.subscribe(s => s.columns.widths[columnId], setWidth), [setWidth, columnId])
    React.useEffect(() => setWidth(spreadsheetStore.getState().columns.widths[columnId] ?? 150), [])
}
