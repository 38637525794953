import React from 'react'

import Link from '@src/components/common/Link'
import DocumentStatusIndicator from '@src/components/document/DocumentStatusIndicator'
import * as Routes from '@src/logic/routing/routes'
import { Revision } from '@src/types/document'

interface IProps {
    revision: Revision
}

export default function RevisionName({ revision }: IProps) {
    return (
        <>
            <DocumentStatusIndicator revision={revision} />
            <div className="selectable-content__title">
                <Link to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)}>{revision.name}</Link>
            </div>
        </>
    )
}
