import React from 'react'
import { useHistory } from 'react-router'

import { Location, UnregisterCallback } from 'history'

interface RenderProps {
    onConfirm: () => void
    onCancel: () => void
}

interface IProps {
    when: boolean
    children: React.ElementType<RenderProps>
}

export default function NavigationPrompt({ when, children }: IProps) {
    const history = useHistory()
    const unblockRef = React.useRef<UnregisterCallback>()
    const [nextLocation, setNextLocation] = React.useState<Location>()

    const onCancel = React.useCallback(() => {
        setNextLocation(null)
    }, [])

    const onConfirm = React.useCallback(() => {
        unblockRef.current?.()
        history.push(nextLocation.pathname)
    }, [nextLocation])

    const renderProps = React.useMemo(() => ({
        onConfirm,
        onCancel
    }), [onConfirm, onCancel])

    React.useEffect(() => {
        if (when) {
            unblockRef.current = history.block(nextLocation => {
                setNextLocation(nextLocation)
            })
        } else if (unblockRef.current) {
            unblockRef.current()
        }

        return () => unblockRef.current?.()
    }, [when])

    return nextLocation ? React.createElement(children, renderProps) : null
}
