import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { OnBlur } from 'react-final-form-listeners'
import { Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Table, UncontrolledTooltip } from 'reactstrap'

import { v4 } from 'uuid'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { commitmentStatusLabel, commitmentStatusValue, costCodeLabel, costCodeValue, isPhaseLocked, phaseLabel, phaseValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CostCodesList } from '@src/logic/http/Api'
import { groupBy } from '@src/logic/utils/Collection'
import { CommitmentDefinition, CommitmentReportColumn, CommitmentReportColumnName, CommitmentStatusDefinition, CostCodeBrief, CostValue, CostsOverview, Phase } from '@src/types/costs'

interface IProps {
    projectId: string
    costsOverview: CostsOverview
    commitmentDefinition: CommitmentDefinition
}

export interface ICommitmentItemFormData {
    name: string
    description: string
    otherPartyRef: string
    costCode: CostCodeBrief
    status: CommitmentStatusDefinition
    phase: Phase
    statusColumnValues: {
        status: string
        columns: {
            [Column in CommitmentReportColumn]?: CostValue
        }
    }[]
}

export default function CommitmentItemFormBody({ projectId, commitmentDefinition, costsOverview }: IProps) {
    const costCodesAsync = useAsyncAbortable(async (abortSignal, query) => {
        const response = await CostCodesList(projectId, 1, 200, { abortSignal })
        const grouped = groupBy(response.data, 'group')
        return Object.keys(grouped).reduce((prev, curr) => [...prev, { label: curr, options: grouped[curr] }], [])
    }, [''], { initialState: () => ({ error: undefined, loading: false, result: [], status: 'not-requested' }), setLoading: s => ({ ...s, loading: true }) })

    function isStatusDisabled(option: CommitmentStatusDefinition) {
        return !costsOverview.budget.locked ? !option.allowInUnlockedBudget : false
    }

    function formatStatusLabel(option: CommitmentStatusDefinition) {
        const id = v4()
        if (isStatusDisabled(option)) {
            return (
                <>
                    <UncontrolledTooltip target={`commitment-item-select-status-${id}`}>Requires locked budget</UncontrolledTooltip>
                    <div>
                        <FA id={`commitment-item-select-status-${id}`} className="text-warning" icon="exclamation-triangle" />&nbsp;
                        {commitmentStatusLabel(option)}
                    </div>
                </>
            )
        }

        return commitmentStatusLabel(option)
    }

    const statuses = commitmentDefinition.statusDefinitions

    return (
        <>
            <FormGroup row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="commitmentItem-name">Name</Label>
                        <Field id="commitmentItem-name" name="name" component={ValidatedInput} placeholder="Name" validate={required} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="commitmentItem-status">Current Status</Label>
                        <Field
                            id="commitmentItem-status"
                            name="status"
                            component={ValidatedSelect}
                            options={commitmentDefinition.statusDefinitions}
                            validate={required}
                            getOptionLabel={commitmentStatusLabel}
                            formatOptionLabel={formatStatusLabel}
                            getOptionValue={commitmentStatusValue}
                            isOptionDisabled={isStatusDisabled}
                            isClearable
                        />
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="commitmentItem-costCode">Cost Code</Label>
                        <Field
                            id="commitmentItem-costCode"
                            name="costCode"
                            component={ValidatedSelect}
                            validate={required}
                            options={costCodesAsync.result}
                            getOptionValue={costCodeValue}
                            getOptionLabel={costCodeLabel}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="commitmentItem-phase">Phase</Label>
                        <Field
                            id="commitmentItem-phase"
                            name="phase"
                            component={ValidatedSelect}
                            options={costsOverview.phases}
                            getOptionLabel={phaseLabel}
                            getOptionValue={phaseValue}
                            isClearable
                            isOptionDisabled={isPhaseLocked}
                        />
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <Label for="commitmentItem-otherPartyRef">Supplier Reference</Label>
                    <Field id="commitmentItem-otherPartyRef" name="otherPartyRef" component={ValidatedInput} />
                </Col>
            </FormGroup>
            <FormGroup>
                <Label for="commitmentItem-description">Description</Label>
                <Field id="commitmentItem-description" name="description" component={ValidatedInput} type="textarea" />
            </FormGroup>
            <Table responsive>
                <thead>
                    <tr>
                        <th style={{ minWidth: 120 }}>Status</th>
                        <th style={{ minWidth: 120 }}>Column</th>
                        <th style={{ minWidth: 120 }}>Unit</th>
                        <th style={{ minWidth: 120 }}>Qty</th>
                        <th style={{ minWidth: 120 }}>Rate</th>
                        <th style={{ minWidth: 120 }}>Value</th>
                        <th style={{ minWidth: 120 }}>Notes</th>
                    </tr>
                </thead>
                <Field<CommitmentStatusDefinition> name="status" subscription={{ value: true }}>
                    {({ input: { value: selectedStatus } }) =>
                        <FieldArray<any> name="statusColumnValues">
                            {({ fields }) =>
                                <tbody>
                                    {fields.map((f, idx) =>
                                        statuses[idx].columns.map((col, cidx) => (
                                            <StatusColumnValueRow
                                                key={`${idx}-${cidx}`}
                                                status={statuses[idx]}
                                                columnIndex={cidx}
                                                field={f}
                                                active={selectedStatus && statuses[idx].code === selectedStatus.code}
                                            />)
                                        )
                                    )}
                                </tbody>
                            }
                        </FieldArray>
                    }
                </Field>
            </Table>
        </>
    )
}

interface IStatusColumnValueRowProps {
    status: CommitmentStatusDefinition
    columnIndex: number
    field: string
    active: boolean
}

function StatusColumnValueRow({ active, field, status, columnIndex }: IStatusColumnValueRowProps) {
    const fieldPrefix = `${field}.columns.${status.columns[columnIndex]}`
    const form = useForm()
    const { input: { value: quantityValue } } = useField<number>(`${fieldPrefix}.quantity`, { subscription: { value: true } })
    const { input: { value: rateValue } } = useField<number>(`${fieldPrefix}.rate`, { subscription: { value: true } })

    function updateValue() {
        form.change(`${fieldPrefix}.value`, quantityValue * rateValue)
    }

    return (
        <tr style={{ background: active ? '#ecf4f5' : 'initial' }}>
            <td>{columnIndex === 0 ? status.code : ''}</td>
            <td>{CommitmentReportColumnName[status.columns[columnIndex]]}</td>
            <td>
                <Field name={`${fieldPrefix}.unit`} component={ValidatedInput} bsSize="sm" />
            </td>
            <td>
                <Field name={`${fieldPrefix}.quantity`} component={ValidatedInput} bsSize="sm" type="number" />
                <OnBlur name={`${fieldPrefix}.quantity`}>
                    {updateValue}
                </OnBlur>
            </td>
            <td>
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" size="sm"><InputGroupText className="bg-white">$</InputGroupText></InputGroupAddon>
                    <Field name={`${fieldPrefix}.rate`} component={ValidatedCurrencyInput} bsSize="sm" />
                    <OnBlur name={`${fieldPrefix}.rate`}>
                        {updateValue}
                    </OnBlur>
                </InputGroup>
            </td>
            <td>
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" size="sm"><InputGroupText className="bg-white">$</InputGroupText></InputGroupAddon>
                    <Field name={`${fieldPrefix}.value`} component={ValidatedCurrencyInput} bsSize="sm" />
                </InputGroup>
            </td>
            <td>
                <Field name={`${fieldPrefix}.notes`} component={ValidatedInput} bsSize="sm" />
            </td>
        </tr>
    )
}
