import React from 'react'

import { FeatureFlag, Features, hasFeature } from '@src/logic/features/features'

interface Props<TFlag extends FeatureFlag> {
    features: Features
    flag: TFlag
    value: Features[TFlag] | Features[TFlag][]
    children: () => React.ReactElement
}

const FeatureGate = <TFlag extends FeatureFlag>({ features, flag, value, children }: Props<TFlag>) => {
    const renderChildren = React.useMemo(() => hasFeature(features, flag, value), [features, flag, value])
    if (!hasFeature(features, flag, value)) {
        return null
    }

    return renderChildren ? children() : null
}

export default FeatureGate
