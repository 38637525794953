import React, { useEffect } from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import Pagination from '@src/components/common/Pagination'
import { IOption } from '@src/components/common/Select'
import RevisionTable from '@src/components/document/RevisionTable'
import WidgetRevisionsInput from '@src/components/widget/inputs/WidgetRevisionsInput'
import { IModalProps } from '@src/hooks/useModal'
import { usePagination } from '@src/hooks/usePagination'
import { RevisionsList } from '@src/logic/http/Api'
import { FilterBuilder } from '@src/logic/iql/FilterBuilder'
import { Api } from '@src/types/api'
import { DocumentLink, Revision } from '@src/types/document'

interface IProps extends IModalProps {
    projectId: string
    links: DocumentLink[]
    header: string
    onLinkAdd?: (revision: Revision) => void
    onLinkRemove?: (revision: Revision) => void
}

const DocumentLinksModal: React.FC<IProps> = ({ children, header, links, projectId, onLinkAdd, onLinkRemove, ...modalProps }) => {
    const revisionIds = links?.map(x => x.revisionId) ?? []
    const revisionIdsHash = revisionIds.join('-')
    const paging = usePagination({ initialPage: 1, initialPerPage: 20, initialTotalItems: revisionIds.length })

    useEffect(() => paging.setTotalItems(revisionIds.length), [revisionIdsHash])

    const revisionsAsync = useAsyncAbortable(
        (abortSignal, links) => revisionIds.length === 0 ? Promise.resolve<Api.Response.RevisionListSingleProject>({ metadataDefinitions: [], revisions: [] }) : RevisionsList(projectId, FilterBuilder.for<Revision>().eq('id', revisionIds).build(), undefined, 1, 10, { abortSignal }).then(x => x.data),
        [revisionIdsHash],
        {
            setLoading: s => ({ ...s, loading: true })
        }
    )

    function filterWidgetRevisions(option: IOption<string>) {
        return !revisionIds.includes(option.value)
    }

    return (
        <Modal size="xl" {...modalProps}>
            <ModalHeader toggle={modalProps.toggle}>{header}</ModalHeader>
            <ModalBody>
                {revisionsAsync.result && (
                    <div>
                        <Row className="mb-3">
                            <Col lg={4}>
                                <WidgetRevisionsInput
                                    isMulti={false}
                                    onChange={onLinkAdd}
                                    placeholder="Add document from widget..."
                                    filterOption={filterWidgetRevisions}
                                />
                            </Col>
                            <Col className="ml-auto flex-grow-0 flex-shrink-1">
                                <Pagination
                                    currentPage={paging.page}
                                    onSelectPage={paging.setPage}
                                    perPage={paging.perPage}
                                    totalCount={revisionIds.length}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RevisionTable
                                    revisions={revisionsAsync.result.revisions}
                                    metadataDefinitions={revisionsAsync.result.metadataDefinitions}
                                    deleteAction={{
                                        tooltip: 'Remove link',
                                        icon: 'times',
                                        visible: true,
                                        disabled: false,
                                        overrideAction: onLinkRemove
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={modalProps.toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DocumentLinksModal
