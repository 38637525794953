import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'

import { FORM_ERROR } from 'final-form'

import FA from '@src/components/common/FontAwesomeIcon'
import PictureUploadManager from '@src/components/common/PictureUploadManager'
import { IOption } from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import ProfilePicture from '@src/components/usersettings/ProfilePicture'
import { required } from '@src/logic/forms/validation'
import { CompanyLogoUpload, CompanyMeUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Company } from '@src/types/principal'

interface IProps {
    company: Company
    reloadCompany: () => void
}

interface IGeneralSettingsFormData {
    name: string
    abbreviatedName: string
    description: string
    abn: string
    acn: string
    phone: string
    website: string
    industry: string
    disciplines: IOption<string>[]
}

const GeneralSettingsSection: React.FC<IProps> = ({ company, reloadCompany }) => {
    const companyLogoUploadRef = React.useRef<PictureUploadManager>()
    const [companyLogoError, setCompanyLogoError] = React.useState<string>()

    async function handleUpdateCompany(values: IGeneralSettingsFormData) {
        try {
            await CompanyMeUpdate({
                name: values.name,
                abbreviatedName: values.abbreviatedName.trim().toUpperCase(),
                abn: values.abn,
                acn: values.acn,
                addresses: company.addresses,
                description: values.description,
                disciplines: values.disciplines.map(x => x.value),
                industry: values.industry,
                phone: values.phone,
                website: values.website
            })
            NotificationService.info('Company updated')
        } catch {
            NotificationService.error('Could not save changes. Please try again.')
            return { [FORM_ERROR]: 'Submission error' }
        }

        reloadCompany()
    }

    function validateShortName(value: string) {
        if (value == null || value.trim().length === 0) return 'Required'

        if (value.trim().length > 6) return 'Maximum of 6 characters is allowed'

        if (!/^[a-zA-Z1-9]*$/.test(value)) return 'Can only contain alphanumeric characters'
    }

    function formatShortName(value: string, name: string) {
        return value == null ? undefined : value.replace(/\s/g, '').toUpperCase()
    }

    async function uploadCompanyLogo(blob: Blob) {
        const pictureUpload = CompanyLogoUpload(blob)
        const uploadingNotification = NotificationService.info(<div><span>Uploading profile picture...  </span><FA icon="spinner-third" spin /></div>, { autoClose: false })

        try {
            await pictureUpload
            setCompanyLogoError(undefined)
            NotificationService.updateThenCloseToast(uploadingNotification, 'Updated profile picture')
        } catch {
            NotificationService.updateThenCloseToast(uploadingNotification, 'Failed to upload picture', { type: 'error' })
            return
        }

        reloadCompany()
    }

    function openProfilePictureUpload() {
        companyLogoUploadRef.current?.open()
    }

    return (
        <Card className="mb-3">
            <Form<IGeneralSettingsFormData>
                onSubmit={handleUpdateCompany}
                initialValues={{
                    name: company.name,
                    abbreviatedName: company.abbreviatedName,
                    abn: company.abn,
                    acn: company.acn,
                    description: company.description,
                    phone: company.phone,
                    website: company.website,
                    industry: company.industry,
                    disciplines: company.disciplines.map(d => ({ label: d, value: d }))
                }}
            >
                {({ handleSubmit, submitting, pristine }) => (
                    <>
                        <CardHeader className="d-flex align-items-center">
                            <span><FA icon="cogs" /> Company Settings</span>
                            <Button color="primary" className="ml-auto" onClick={handleSubmit} >
                                <FA icon={submitting ? 'spinner-third' : !pristine ? 'save' : 'check-circle'} spin={submitting} />
                                <span className="d-none d-md-inline-block">&nbsp;{submitting ? 'Saving...' : !pristine ? 'Save' : 'Saved'}</span>
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <h5>Business Details</h5>
                            <hr />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Field name="name" component={ValidatedInput} validate={required} />
                                        <FormText>{`Your company's name.`}</FormText>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Abbreviated Name</Label>
                                        <Field name="abbreviatedName" component={ValidatedInput} validate={validateShortName} format={formatShortName} formatOnBlur />
                                        <FormText>Abbreviation of company name. Should contain only alphanumeric characters.</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>ABN</Label>
                                        <Field name="abn" disabled component={ValidatedInput} />
                                        <FormText>{`Your Company's ABN (Australian Business Number)`}</FormText>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>ACN</Label>
                                        <Field name="acn" disabled component={ValidatedInput} />
                                        <FormText>{`Your Company's ACN (Australian Company Number)`}</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Industry</Label>
                                        <Field name="industry" component={ValidatedInput} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Disciplines</Label>
                                        <Field name="disciplines" component={ValidatedSelect} selectType="creatable" isMulti isClearable />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Company Logo</Label>
                                        <PictureUploadManager
                                            ref={companyLogoUploadRef}
                                            onUploadPicture={uploadCompanyLogo}
                                            onError={setCompanyLogoError}
                                        >
                                            <ProfilePicture url={company.logoLink} fallbackIcon="building" />
                                        </PictureUploadManager>
                                        <Button color="default mt-3" onClick={openProfilePictureUpload}>Upload new picture</Button>
                                        <FormText color="danger">{companyLogoError}</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <h5>Contact Details</h5>
                            <hr />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Phone</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="phone" /></InputGroupText></InputGroupAddon>
                                            <Field name="phone" component={ValidatedInput} />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Website</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="globe" /></InputGroupText></InputGroupAddon>
                                            <Field name="website" component={ValidatedInput} />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </>
                )}
            </Form>
        </Card>
    )
}

export default GeneralSettingsSection
