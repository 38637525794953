import React from 'react'

import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import { RevisionRow } from '@src/components/document/section/DocumentsSection'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'

interface IProps {
    userId: string
    revision: RevisionRow
    onDownload: (revision: RevisionRow) => void
    onExpand?: (revision: RevisionRow) => void
    onRevise?: (revision: RevisionRow) => void
    onCheckout?: (revision: RevisionRow) => void
}

function checkoutTooltip(userId: string, revision: RevisionRow) {
    if (revision.checkedOutBy == null) {
        return 'Check Out'
    }

    if (revision.checkedOutBy.id === userId) {
        return 'Check In'
    }

    if (revision.documentAccess.isAdministrator) {
        return `Remove check out by ${revision.checkedOutBy.name}`
    }

    return `Checked out by ${revision.checkedOutBy.name}`
}

function isCheckoutDisabled(userId: string, revision: RevisionRow) {
    if (!isAuthorised(revision.documentAccess, Operations.Update)) {
        return true
    }

    if (revision.checkedOutBy?.id !== userId && !revision.documentAccess.isAdministrator) {
        return true
    }

    return false
}

export default function RevisionActions({ revision, userId, onCheckout, onExpand, onDownload, onRevise }: IProps) {
    return (
        <>
            {revision.expandable && <TooltipLinkAction id={`expand-revisions-${revision.id}`} tooltip="Earlier revisions" className="order-lg-0" onClick={onExpand} data={revision} data-tour={TourTags.DocumentTableRowExpand}>
                    <FA icon={revision.isExpanded ? 'minus-square' : 'plus-square'} aria-label="Show more revisions" />
                </TooltipLinkAction>}
            <TooltipLink id={`view-${revision.id}`} tooltip="View" to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)} className="selectable-content__icon order-lg-1" aria-label="View revision" data-tour={TourTags.DocumentTableRowView}><FA icon="eye" /></TooltipLink>
            <TooltipLink id={`edit-${revision.id}`} tooltip="Edit" to={Routes.projectDocumentEdit(revision.projectId, revision.documentId, revision.id)} className="selectable-content__icon order-lg-1" data-tour={TourTags.DocumentTableRowEdit} aria-label="Edit revision"><FA icon="pencil" /></TooltipLink>
            {onRevise && <TooltipLinkAction id={`revise-${revision.id}`} tooltip="Revise" data={revision} className="order-lg-1" onClick={onRevise} disabled={!isAuthorised(revision.documentAccess, Operations.Revise) || revision.checkedOutBy != null} data-tour={TourTags.DocumentTableRowRevise} aria-label="Revise document"><FA icon="edit" /></TooltipLinkAction>}
            {revision.canCheckout && <TooltipLinkAction id={`checkout-${revision.id}`} tooltip={checkoutTooltip(userId, revision)} data={revision} onClick={onCheckout} disabled={isCheckoutDisabled(userId, revision)} data-tour={TourTags.DocumentTableRowCheckout}><FA icon={revision.checkedOutBy != null ? 'lock' : 'lock-open'} aria-label="Checkout document" /></TooltipLinkAction>}
            <TooltipLinkAction id={`download-${revision.id}`} tooltip="Download" data={revision} onClick={onDownload} data-tour={TourTags.DocumentTableRowDownload} aria-label="Download revision"><FA icon="download" /></TooltipLinkAction>
        </>
    )
}
