import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import FA from "@src/components/common/FontAwesomeIcon";
import { auth } from "@src/logic/auth/AuthService";

interface IProps {
  onForgotPassword: () => void;
}

interface IState {
  email: string;
  password: string;
  loginError: string;
}

export default class extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loginError: null,
    };
  }

  private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.currentTarget.value });
  };

  private handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  };

  private handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await auth.login(this.state.email, this.state.password);
    } catch (e) {
      let error = "An error occurred while logging in. Please try again.";
      if (auth.isAuth0Error(e)) {
        switch (e.code) {
          case "access_denied":
            error = "Wrong email or password.";
            break;
          case "request_error":
            error =
              "Unable to connect to login.\nPlease check your network and try again.";
            break;
        }
      }

      this.setState({ loginError: error });
    }
  };

  private handleForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.onForgotPassword();
  };

  public render() {
    const { email, password, loginError } = this.state;
    console.log("overrides__customInput");
    return (
      <Form onSubmit={this.handleLogin}>
        <FormGroup className="mt-4 mb-0">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="no-border-bottom">
                <FA icon="at" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="login-email"
              type="email"
              className="no-border-bottom no-border-left"
              onChange={this.handleEmailChange}
              value={email}
              placeholder="Email Address"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FA icon="lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="login-password"
              type="password"
              className="no-border-left"
              onChange={this.handlePasswordChange}
              value={password}
              placeholder="Password"
            />
          </InputGroup>
        </FormGroup>
        {loginError && (
          <div
            className="mb-2 text-center text-danger"
            style={{ whiteSpace: "pre-line" }}
          >
            {loginError}
          </div>
        )}
        <div role="button" className="pointer mb-3 text-muted">
          <a
            href="#"
            color="quinary"
            className="text-reset"
            onClick={this.handleForgotPassword}
          >
            Forgot Password
          </a>
        </div>
        <Button
          block
          size="sm"
          color="primary"
          className="text-uppercase p-3 font-weight-bold"
          type="submit"
          style={{ fontSize: "90%", letterSpacing: ".08rem" }}
        >
          Continue
        </Button>
        <div className="text-muted text-center mb-1 mt-5">
          Don't have an account? <a href="./register">Sign Up</a>
        </div>{" "}
      </Form>
    );
  }
}
