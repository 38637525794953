import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { shallowEqual, useSelector } from 'react-redux'
import { Badge, Card, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import { RevisionsList, TransmittalsList } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { fromNow } from '@src/logic/utils/Date'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Session } from '@src/types/session'
import { TransmittalBrief } from '@src/types/transmittal'

function getSessionUser(state: RootState) {
    return state.session.user
}

const ActionNeededCard: React.FC = () => {
    const currentUser = useSelector<RootState, Session.User>(getSessionUser, shallowEqual)

    const revisionsAsync = useAsyncAbortable(
        (abortSignal, uid) => RevisionsList(undefined, `requested_approver:'${uid}' & approval_status:'pending'`, '-created', 1, 20, { abortSignal }).then(r => r.data),
        [currentUser.id],
        { executeOnUpdate: false }
    )

    const transmittalsAsync = useAsyncAbortable(
        (abortSignal, uid) => TransmittalsList(undefined, `requested_approver:'${uid}' & approval_status:'pending'`, '-created', 1, 20, { abortSignal }).then(r => r.data),
        [currentUser.id],
        { executeOnUpdate: false }
    )

    function isRevision(entity: Revision | TransmittalBrief): entity is Revision {
        return (entity as Revision).documentId !== undefined
    }

    function renderRevisionOrTransmittalLink(entity: Revision | TransmittalBrief) {
        return isRevision(entity)
        ? (
            <Link to={Routes.projectDocument(entity.projectId, entity.documentId, entity.id)}>
                <FA icon="file-alt" className="mr-2" />
                {entity.name}
            </Link>
        )
        : (
            <Link to={Routes.projectTransmittal(entity.projectId, entity.id)}>
                <FA icon="envelope" className="mr-2" />
                {entity.subject}
            </Link>
        )
    }

    function renderActions() {
        if (revisionsAsync.loading || transmittalsAsync.loading) {
            return <ListGroupItem>Loading items to action <FA icon="spinner-third" spin /></ListGroupItem>
        }
        if (revisionsAsync.result.revisions.length === 0 && transmittalsAsync.result.length === 0) {
            return <ListGroupItem className="text-center">Nothing to action.</ListGroupItem>
        }

        return [...revisionsAsync.result.revisions, ...transmittalsAsync.result].sort((a, b) => (+new Date(b.updatedDate)) - (+new Date(a.updatedDate))).map(r => (
            <ListGroupItem key={r.id}>
                <Row>
                    <Col className="text-truncate">
                        {renderRevisionOrTransmittalLink(r)}
                    </Col>
                    <Col sm={5} className="text-sm-right">
                        <Badge pill tag="span" color="warning" className="card-list__badge align-self-start">Approval Requested</Badge>
                        <span className="card-list__link text-muted d-sm-inline-block">{fromNow(r.updatedDate)}</span>
                    </Col>
                </Row>
            </ListGroupItem>
        ))
    }

    return (
        <Card>
            <CardHeader className="d-sm-flex align-items-center">
                <FA icon="users" />
                &nbsp;Action needed
                    {revisionsAsync.result && transmittalsAsync.result && <div className="ml-auto card-header__links"/>}
            </CardHeader>
            <ListGroup flush>
                {renderActions()}
            </ListGroup>
        </Card>
    )
}

export default ActionNeededCard
