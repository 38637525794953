import { useSelector } from 'react-redux'

import { RootState } from '@src/types/models'

function defaultAppStateSelector(appState: RootState) {
    return appState
}

function useAppState(selector?: undefined): RootState
function useAppState<T>(selector: (appState: RootState) => T): T
function useAppState<T>(selector?: (appState: RootState) => T) {
    return selector == null
        ? useSelector<RootState, RootState>(defaultAppStateSelector)
        : useSelector<RootState, T>(selector)
}

export default useAppState
