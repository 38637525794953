import React from 'react'

import CheckboxRadio from '@src/components/common/CheckboxRadio'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import { localShortDate } from '@src/logic/utils/Date'
import { DocumentEntityProps, DocumentPropertyDefinition } from '@src/types/metadata'
import { PrincipalBrief } from '@src/types/principal'
import { IRegisterColumn } from '@src/types/register'

export default function DocumentPropertyField({ row, col }: IRegisterTableField<DocumentPropertyDefinition & IRegisterColumn>) {
    const rawValue = row.cells[col.id] as string | Date | number | boolean | PrincipalBrief | null
    const formattedValue = React.useMemo(() => {
        switch (col.data.metadataDefinition.options.property) {
            case DocumentEntityProps.Author:
            case DocumentEntityProps.Description:
            case DocumentEntityProps.Name:
            case DocumentEntityProps.RevisionNo:
                return <span className="register__inactive-text-num-span">{rawValue}</span>
            case DocumentEntityProps.CreatedDate:
            case DocumentEntityProps.RevisionDate:
                return <span className="register__inactive-date-span">{localShortDate(new Date(rawValue as string))}</span>
            case DocumentEntityProps.Published:
                return (
                    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
                        <CheckboxRadio labelClass="m-0" checked={rawValue as boolean} />
                    </div>
                )
            case DocumentEntityProps.CreatedBy:
                return <span className="register__inactive-text-num-span">{(rawValue as PrincipalBrief)?.name}</span>
            default:
                return <span />
        }
    },
    [rawValue])
    return formattedValue
}

export function DocumentPropertyFieldFooter() {
    return <span />
}
