import { useDispatch } from 'react-redux'

import { reloadActiveProject } from '@src/actions/project'
import useAppState from '@src/hooks/useAppState'
import { Project } from '@src/types/project'

export default function useProject(): [Project, () => void] {
    const dispatch = useDispatch()
    const project = useAppState(s => s.projects.active)

    function reload() {
        dispatch(reloadActiveProject())
    }

    return [
        project,
        reload
    ]
}
