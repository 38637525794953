import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from "reactstrap";

import ValidatedInput from "@src/components/common/ValidatedInput";
import useModal, { IModalProps } from "@src/hooks/useModal";
import {
  composeValidators,
  isNumber,
  isValidABN,
  required,
} from "@src/logic/forms/validation";
import NotificationService from "@src/logic/notification/NotificationService";
import {
  AddRemoveUserFromGroups,
  UpdateCompany,
  UpdateUser,
} from "@src/logic/http/Api";
import {
  Address,
  CompanyGroup,
  CompanyPublic,
  UserInternal,
} from "@src/types/principal";
import FA from "@src/components/common/FontAwesomeIcon";
import AddContactForm from "../Contacts/AddContactForm";
import {
  useCompanyGroupsStore,
  useCompanyStore,
  useCompanyUsersStore,
} from "@src/store/admin";
import shallow from "zustand/shallow";
import { IOption } from "@src/components/common/Select";
import GroupSelect from "../GroupSelect";
import MoveContactForm from "../Contacts/MoveContactForm";

interface IUpdateCompanyValues {
  companyName: string;
  abn: string;
  address: Address;
  website: string;
  phone: string;
  id: string;
}

export default function UpdateCompanyForm({
  modalProps,
  company,
}: {
  modalProps: IModalProps;
  company: CompanyPublic;
}) {
  const [companyUsers, refreshCompanyUsers, incrementPage] =
    useCompanyUsersStore(
      (s) => [s.companyUsers, s.refresh, s.incrementPage],
      shallow
    );
  const [groups, loadGroups] = useCompanyGroupsStore(
    (s) => [s.groups, s.loadGroups, s.refreshGroups],
    shallow
  );
  const [adminCompany, refreshCompanies] = useCompanyStore(
    (s) => [s.adminCompany, s.refreshCompanies],
    shallow
  );
  const isAdminCompany = company?.id == adminCompany?.id;
  const addContactModal = useModal(false);
  const moveContactModal = useModal(false);

  const [selectedUser, setSelectedUser] = useState({} as UserInternal);

  async function handleSubmit(values: IUpdateCompanyValues): Promise<any> {
    try {
      // submit company details
      await UpdateCompany({
        id: values.id,
        name: values.companyName,
        abn: values.abn,
        addresses: values.address
          ? [
              {
                description: values.address.description,
                lines: [],
                city: "",
                state: "",
                postCode: "",
              },
            ]
          : [],
        website: values.website,
        phone: values.phone,
      }).then(async () => {
        await refreshCompanies("");
      });

      NotificationService.success("Company details were updated successfully.");
    } catch {
      NotificationService.error(
        "An error occured while updating the company details. Please refresh and try again."
      );
    } finally {
      modalProps.toggle();
    }
  }

  useEffect(() => {
    loadGroups(company?.id);
  }, [company]);

  return (
    <>
      {company && (
        <AddContactForm
          modalProps={addContactModal.modalProps}
          companyId={company.id}
          companyName={company.name}
        />
      )}
      {company && (
        <MoveContactForm
          modalProps={moveContactModal.modalProps}
          refreshCompanyUsers={refreshCompanyUsers}
          oldCompanyId={company.id}
          user={selectedUser}
        />
      )}
      <Modal {...modalProps} size="xl">
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            companyName: company?.name,
            abn: company?.abn,
            address: company?.addresses[0],
            phone: company?.phone,
            website: company?.website,
            id: company?.id,
          }}
        >
          {(formProps) => (
            <>
              <ModalHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5 style={{ marginBottom: 0, flexGrow: 1 }}>
                    {company.name}
                  </h5>
                  <Button
                    color="primary"
                    outline
                    style={{ marginLeft: 5, borderColor: "lightgray" }}
                    disabled={formProps.submitting}
                    onClick={formProps.handleSubmit}
                    id="save-form-btn"
                  >
                    Save
                  </Button>
                </div>
              </ModalHeader>
              <ModalBody>
                <Row className="mb-3 ml-3 mr-3">
                  <Col xs={12} className="mb-3">
                    <h5>Company Details</h5>
                    <hr />
                  </Col>
                  <Col xs={4} className="mb-2">
                    <Label for="company">Company *</Label>
                    <Field
                      id="companyName"
                      name="companyName"
                      component={ValidatedInput}
                      validate={required}
                    />
                  </Col>
                  <Col xs={4} className="mb-2">
                    <Label for="abn">Company ABN *</Label>
                    <Field
                      id="abn"
                      name="abn"
                      component={ValidatedInput}
                      validate={composeValidators(required, isValidABN)}
                    />
                  </Col>
                  <Col xs={4} className="mb-2">
                    <Label for="address">Company Address</Label>
                    <Field
                      id="address.description"
                      name="address.description"
                      component={ValidatedInput}
                    />
                  </Col>
                  <Col xs={4} className="mb-2">
                    <Label for="phone">Company Phone</Label>
                    <Field
                      id="phone"
                      name="phone"
                      component={ValidatedInput}
                      validate={composeValidators(isNumber)}
                    />
                  </Col>
                  <Col xs={4} className="mb-2">
                    <Label for="phone">Website</Label>
                    <Field
                      id="website"
                      name="website"
                      component={ValidatedInput}
                    />
                  </Col>
                </Row>
                <Row className="ml-3 mr-3 pt-5">
                  <Col xs={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h5 style={{ marginBottom: 0, flexGrow: 1 }}>
                        Related Contacts
                      </h5>
                      <Button
                        disabled={formProps.submitting}
                        color="primary"
                        outline
                        style={{ borderColor: "lightgray" }}
                        onClick={addContactModal.actions.show}
                      >
                        Invite New Contact
                      </Button>
                    </div>
                    <hr />
                  </Col>
                </Row>
                <Row className="ml-3 mr-3">
                  <Col>
                    <Table>
                      <thead className="thead-light">
                        <tr>
                          <th style={{ textAlign: "left" }}>Name</th>
                          <th style={{ textAlign: "left" }}>Group</th>
                          <th style={{ textAlign: "left" }}>Phone</th>
                          <th style={{ textAlign: "left" }}>Email Address</th>
                          {isAdminCompany && <th style={{ width: 50 }}>MFA</th>}
                          <th style={{ width: 50 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyUsers &&
                          companyUsers.map((companyUser, index) => {
                            var values = groups?.filter(
                              (item) =>
                                companyUser?.groups?.find(
                                  (g) => g.id == item.groupId
                                ) != null
                            );

                            function filterWidgetRevisions(
                              option: IOption<string>
                            ) {
                              return true;
                            }

                            return (
                              <tr key={index}>
                                <th scope="row">
                                  {companyUser.firstName} {companyUser.lastName}
                                </th>
                                <td>
                                  <GroupSelect
                                    isMulti={true}
                                    onChange={async (value: CompanyGroup[]) => {
                                      await AddRemoveUserFromGroups(
                                        companyUser.id,
                                        {
                                          groupIds: value.map((g) => g.groupId),
                                        }
                                      ).then(async () => {
                                        await refreshCompanyUsers(company?.id);
                                      });
                                    }}
                                    placeholder="Group"
                                    filterOption={filterWidgetRevisions}
                                    value={values}
                                  />
                                </td>
                                <td>{companyUser.phone ?? "-"}</td>
                                <td>{companyUser.email ?? "-"}</td>
                                {isAdminCompany && (
                                  <td>
                                    <h5
                                      style={{
                                        cursor: "pointer",
                                        marginBottom: 0,
                                        color: companyUser?.mfaEnabled
                                          ? "green"
                                          : "",
                                      }}
                                      onClick={async () =>
                                        await UpdateUser(companyUser?.id, {
                                          email: companyUser?.email,
                                          firstName: companyUser?.firstName,
                                          lastName: companyUser?.lastName,
                                          phone: companyUser?.phone,
                                          role: companyUser?.role,
                                          mFAEnabled: !companyUser?.mfaEnabled,
                                          groupIds: companyUser?.groups.map(
                                            (g) => g.id
                                          ),
                                        }).then(async () => {
                                          NotificationService.success(
                                            `Update MFA Status for ${companyUser?.email}.`
                                          );
                                          company &&
                                            (await refreshCompanyUsers(
                                              company.id
                                            ));
                                        })
                                      }
                                    >
                                      {companyUser?.mfaEnabled
                                        ? "Enabled"
                                        : "Disabled"}
                                    </h5>
                                  </td>
                                )}
                                <td>
                                  <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                      className="mr-0"
                                      color="link"
                                    >
                                      <FA icon="ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu right className="shadow">
                                      <DropdownItem
                                        onClick={async () => {
                                          setSelectedUser(companyUser);
                                          moveContactModal.actions.show();
                                        }}
                                      >
                                        Move Contact
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledButtonDropdown>
                                </td>
                              </tr>
                            );
                          })}
                        {!companyUsers?.length && (
                          <tr>
                            <td rowSpan={6}>No users found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {!formProps.submitting &&
                  companyUsers?.length % 100 == 0 &&
                  companyUsers?.length !== 0 && (
                    <Row className="ml-3 mr-3">
                      <Col style={{ textAlign: "right" }}>
                        <Button
                          color="primary"
                          outline
                          style={{ borderColor: "lightgray" }}
                          onClick={async () => {
                            if (company) {
                              incrementPage();
                              company &&
                                (await refreshCompanyUsers(company.id));
                            }
                          }}
                        >
                          Load More
                        </Button>
                      </Col>
                    </Row>
                  )}
              </ModalBody>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}
