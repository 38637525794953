import React from 'react'

import CostReportModal from '@src/components/costs/common/CostReportModal'
import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarButton from '@src/components/toolbar/ToolbarButton'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { auth } from '@src/logic/auth/AuthService'
import { CostReportExcelLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { CostsOverview } from '@src/types/costs'

import { Project } from '@src/types/project'

interface IProps {
    project: Project
    currentRoutePath: string
    costsOverview: CostsOverview
}

interface IState {
    isCreating: boolean
}

export default class CostsToolbar extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            isCreating: false
        }
    }

    private readonly downloadExcelCostReport = () => {
        downloadURL(CostReportExcelLink(auth.getSessionToken(), this.props.project.id))
    }

    private readonly clearCostReportModal = () => {
        this.setState({ isCreating: false })
    }

    private readonly markCreating = () => {
        this.setState({ isCreating: true })
    }

    public render() {
        const { project, costsOverview } = this.props
        const { isCreating } = this.state
        return (
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_DASHBOARD)} to={Routes.projectCostsDashboard(project.id)} name="Dashboard" nameBrief="Dash" iconBrief="chart-line" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_BUDGET, true)} to={Routes.projectCostsBudget(project.id)} name="Budget" nameBrief="Budget" iconBrief="box-usd" />
                    {costsOverview.commitmentDefinitions.map((cd) => {
                        const route = Routes.projectCostsCommitmentType(project.id, cd.code)
                        return <ToolbarLink key={cd.code} active={pathMatchesCurrentRoute(route)} to={route} name={cd.name} nameBrief={cd.name} />
                    })}
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_PAYMENTS)} to={Routes.projectCostsPayments(project.id)} name="Payment" nameBrief="Payment" />
                    <div className="d-none d-md-flex ml-auto">
                        <ToolbarButton onClick={this.downloadExcelCostReport} name="Export" icon="share-square" iconBrief="share-square" tool />
                        <ToolbarButton onClick={this.markCreating} name="Report (Beta)" icon="file-chart-line" iconBrief="file-chart-line" tool />
                        <ToolbarLink to={Routes.projectCostsSettings(project.id)} tool name="Settings" icon="cog" iconBrief="cog" />
                    </div>
                </Toolbar>
                <CostReportModal
                    project={project}
                    costsOverview={costsOverview}
                    isOpen={isCreating}
                    toggle={this.clearCostReportModal}
                />
            </section>
        )
    }
}
