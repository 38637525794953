import React from 'react'
import { FormFeedback } from 'reactstrap'

import cx from 'classnames'

import DatePicker, { IDatePickerProps } from '@src/components/common/DatePicker'
import { FieldRenderProps } from '@src/types/react-final-form'

export default class ValidatedDatePicker extends React.PureComponent<Omit<IDatePickerProps, 'onChange' | 'selected' > & FieldRenderProps<Date>> {
    public render() {
        const { input, meta: { touched, error }, ...dateProps } = this.props

        const showError = touched && !!error

        return (
            <>
                <DatePicker {...dateProps} onChange={input.onChange} selected={input.value || undefined} onBlur={input.onBlur} invalid={showError} disabled={this.props.disabled} />
                <FormFeedback className={cx({ 'd-block': showError })}>{showError && error}</FormFeedback>
            </>
        )
    }
}
