import React from 'react'
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps {
    className?: string
    value: string
    onChange: (input: string) => void
    onSearch: () => void 
}

const SimpleSearch: React.FC<IProps> = ({ className, value, onChange, onSearch }) => {

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        onChange(e.currentTarget.value)
    }

    function handleMaybeSubmit(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') onSearch()
    }

    return (
        <InputGroup className={cx(className, 'search')}>
            <Input type="text" className="search__control" placeholder="Search" aria-label="Search" value={value} onChange={handleChange} onKeyPress={handleMaybeSubmit} />
            <InputGroupAddon addonType="append">
                <Button className="search__btn" color="link" onE><FA icon="search" onClick={onSearch} /></Button>
            </InputGroupAddon>
        </InputGroup>
    )
}

export default SimpleSearch
