import axios from 'axios'
import log from 'loglevel'

import GoogleCloudUpload from '@src/logic/storage/gcs/GoogleCloudUpload'

class GCSUploadManager {
    private uploads: GoogleCloudUpload[]

    constructor() {
        this.uploads = []
    }

    public newSignedUpload = async (id: string, signedUrl: string, file: File, onProgress?: (id: string, bytesCompleted: number) => void, onComplete?: (id: string) => void): Promise<GoogleCloudUpload> => {
        const contentType = file.type || 'text/plain; charset=us-ascii'
        try {
            const response = await axios.post(signedUrl, null, {
                headers: {
                    'x-goog-meta-filename': encodeURI(file.name),
                    'x-goog-resumable': 'start',
                    'Content-Type': contentType
                }
            })
            const newUpload = new GoogleCloudUpload(id, response.headers.location, file)
            newUpload.onProgress = onProgress
            newUpload.onComplete = (uId) => {
                onComplete?.(uId)
                this.uploads = this.uploads.filter(u => u.id !== uId)
            }
            this.uploads.push(newUpload)
            return newUpload
        } catch (error) {
            log.error('Unable to initialize upload', error)
        }

        return null
    }

    public startUpload = (uploadId: string) => {
        this.uploads.find(u => u.id === uploadId).start()
    }

    public pauseUpload = (uploadId: string, onPause: (uploadId: string) => void, force: boolean = false): number => {
        const upload = this.uploads.find(u => u.id === uploadId)

        if (upload) {
            return upload.pause(onPause, force)
        }

        log.warn(`Couldn't pause upload ${uploadId} - upload not found`)
        return -1
    }

    public resumeUpload = (uploadId): boolean => {
        const upload = this.uploads.find(u => u.id === uploadId)
        if (upload) {
            upload.unpause()
            return true
        }

        log.warn(`Couldn't resume upload ${uploadId} - upload not found`)
        return false
    }

    public cancelUpload = (uploadId: string): boolean => {
        const upload = this.uploads.find(u => u.id === uploadId)
        if (upload) {
            upload.pause(undefined, true)
            this.uploads.splice(this.uploads.indexOf(upload), 1)
            return true
        }

        return false
    }
}

export const Manager = new GCSUploadManager()
