import React from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { clamp } from 'lodash'
import shallow from 'zustand/shallow'

import { ISpreadsheetColumn } from '@src/components/spreadsheet/Spreadsheet'
import { useSpreadsheetStore } from '@src/components/spreadsheet/SpreadsheetContext'

interface IColumnResizerProps<TCol, TRow> {
    column: ISpreadsheetColumn<TCol, TRow>
    currentWidth: number
    onResize?: (col: ISpreadsheetColumn<TCol, TRow>, width: number) => void
}

function ColumnResizer<TCol, TRow>({ column, currentWidth, onResize }: IColumnResizerProps<TCol, TRow>) {
    const [getColumnWidth, setColumnWidth, setResizing] = useSpreadsheetStore(x => [x.getColumnWidth, x.setColumnWidth, x.setResizing], shallow)

    const handleColumnResize = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const prevX = e.clientX
        setResizing(column.id)

        // when the mouse moves, detect how far it has moved in the x-axis and add it to the tracked width of the column
        const handleMouseMove = (event: MouseEvent) => {
            event.preventDefault()
            if (prevX !== 0) {
                const distanceX = event.clientX - prevX
                const newWidth = clamp(currentWidth + distanceX, 50, 1000)

                setColumnWidth(column, newWidth)
            }
        }

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
            setResizing(null)
            onResize?.(column, getColumnWidth(column))
        }
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }, [column])

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        // eslint-disable-next-line jsx-a11y/role-supports-aria-props
        <div className="spreadsheet__column-resizer" onMouseDown={handleColumnResize} role="separator" aria-valuenow={currentWidth} aria-valuemin={50} aria-valuemax={1000} />
    )
}

function SortableHeader<TCol, TRow>({ HeaderComponent, onColumnResize, style, width, ...col }: ISpreadsheetHeaderProps<TCol, TRow> & { style: React.CSSProperties, width: number }) {
    const { attributes, listeners, setNodeRef } = useSortable({ id: col.id })

    return (
        <div className="spreadsheet__header-cell" role="cell" style={style} ref={col.canReorder ? setNodeRef : null}>
            <div className="d-flex flex-grow-1 overflow-hidden" {...(col.canReorder ? ({ ...attributes, ...listeners }) : {})}>
                {HeaderComponent ? <HeaderComponent {...col} /> : col.name}
            </div>
            <ColumnResizer column={col} onResize={onColumnResize} currentWidth={width} />
        </div>
    )
}

function FixedHeader<TCol, TRow>({ HeaderComponent, onColumnResize, style, width, ...col }: ISpreadsheetHeaderProps<TCol, TRow> & { style: React.CSSProperties, width: number }) {
    return (
        <div className="spreadsheet__header-cell" role="cell" style={style}>
            <div className="d-flex flex-grow-1">
                {HeaderComponent ? <HeaderComponent {...col} /> : col.name}
            </div>
            <ColumnResizer column={col} onResize={onColumnResize} currentWidth={width} />
        </div>
    )
}

type ISpreadsheetHeaderProps<TCol, TRow> = ISpreadsheetColumn<TCol, TRow> & { onColumnResize?: IColumnResizerProps<TCol, TRow>['onResize'] }

export default function SpreadsheetHeader<TCol, TRow>(col: ISpreadsheetHeaderProps<TCol, TRow>) {
    const width = useSpreadsheetStore(x => x.getColumnWidth(col))
    const style = React.useMemo<React.CSSProperties>(() => ({ width, minWidth: width }), [width])
    const Header = col.canReorder ? SortableHeader : FixedHeader

    return <Header {...col} style={style} width={width} />
}
