import React from 'react'

import { useForwardedRef } from '@src/hooks/useForwardedRef'

interface IProps extends Omit<React.HTMLProps<HTMLIFrameElement>, 'src' | 'srcDoc'> {
    content: string
}

const IFrame: React.ForwardRefRenderFunction<HTMLIFrameElement, IProps> = ({ content, className, ...props }, ref) => {
    const innerRef = useForwardedRef(ref)
    React.useEffect(() => { writeHTML() }, [content])

    function writeHTML() {
        const frame = innerRef.current
        if (!frame) return
        const doc = frame.contentDocument
        doc.open()
        doc.write(content)
        doc.close()
    }

    return <iframe {...props} title={props.title} className="border-0" ref={innerRef} />
}

export default React.forwardRef(IFrame)
