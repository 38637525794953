import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { IOption } from '@src/components/common/Select'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalProps } from '@src/hooks/useModal'
import { isFileNameForm } from '@src/logic/forms/validation'
import { AggregateBy, CostReportPdf } from '@src/logic/http/Api'
import { downloadBlob } from '@src/logic/http/Download'
import NotificationService from '@src/logic/notification/NotificationService'
import { CostsOverview } from '@src/types/costs'
import { Project } from '@src/types/project'

interface IProps extends IModalProps {
    project: Project
    costsOverview: CostsOverview
}

export interface ICostReportData {
    name: string
    type: AggregateBy
    phases?: IOption<string>[]
    includeUnusedCostCodes: boolean
}

export default function CostReportModal({ project, costsOverview, ...modalProps }: IProps) {
    // Set phases and unassigned options including headers
    const phaseOptions = React.useMemo(() => {
        return [
            { label: 'Unassigned', options: [{ label: 'Unassigned', value: '__UNASSIGNED__' }] },
            { label: 'Phases', options: [...costsOverview.phases.map<IOption<string>>(p => ({ label: p.name, value: p.code }))] }
        ]
    }, [costsOverview])

    async function handleSubmit(values: ICostReportData) {
        const notificationId = NotificationService.pendingActivityToast('Generating cost report')
        try {
            modalProps.toggle()

            // Check file name and add local date
            const fileNameData = values.name ?? `${project.code} ${project.name} Cost Report`
            const fileName = `${fileNameData} - ${new Date().toLocaleDateString().replace(/\//g, '-')}.pdf`

            // Create a blob and download it
            let includeUnassigned = false
            let phases = []
            // If no phases (including unassigned) are selected, set to all phases
            if (values.phases == null) {
                phases = costsOverview.phases.map(p => p.code)
                includeUnassigned = true
            } else {
                phases = values.phases.every(p => p.value === '__UNASSIGNED__') ? [] : values.phases.map(p => p.value).filter(p => p !== '__UNASSIGNED__')
                includeUnassigned = values.phases.map(p => p.value).includes('__UNASSIGNED__')
            }
            const blob = (await CostReportPdf(project.id, values.type, phases, includeUnassigned, values.includeUnusedCostCodes)).data
            downloadBlob(blob, fileName, 'application/pdf')
            NotificationService.updateThenCloseToast(notificationId, 'Cost report generated')
        } catch {
            NotificationService.updateThenCloseToast(notificationId, 'Failed to generate cost report', { type: 'error' })
        }
    }

    return (
        <Modal {...modalProps}>
            <Form<ICostReportData> onSubmit={handleSubmit} initialValues={{ type: AggregateBy.CostCodeGroup }}>
            {formProps =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>Generate Cost Report</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="costReport-name">File Name</Label>
                                        <Field id="costReport-name" name="name" component={ValidatedInput} placeholder={`${project.code} ${project.name} Cost Report`} validate={isFileNameForm}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup tag="fieldset">
                                        <Label for="costReport-type">Group By</Label>
                                        <FormGroup check>
                                        <Label check>
                                            <Field className="form-check-input mr-1" value={AggregateBy.CostCodeGroup} component={ValidatedInput} type="radio" name="type"/>
                                            Cost code group
                                        </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                        <Label check>
                                            <Field className="form-check-input mr-1" value={AggregateBy.Phase} component={ValidatedInput} type="radio" name="type"/>
                                            Phase
                                        </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="costReport-type">Phases</Label>
                                        <Field id="costReport-type" name="phases" component={ValidatedSelect} isMulti closeMenuOnSelect={false} options={phaseOptions}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                    <Field name="includeUnusedCostCodes" component={ValidatedCheckboxRadio} label="Include unused cost codes"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={formProps.handleSubmit}>Create</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
