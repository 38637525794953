import React from 'react'

import { useSelector } from 'react-redux'

import CommitmentLinks from '@src/components/document/section/CommitmentLinks'
import TransmittalLinks from '@src/components/document/section/TransmittalLinks'
import FeatureGate from '@src/containers/FeatureGate'
import { CostFlagValue, FeatureFlag, Features } from '@src/logic/features/features'
import { CostsOverview } from '@src/types/costs'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'

interface IProps {
    revision: Revision
}

export default function RevisionLinks({ revision }: IProps) {
    const sessionFeatures = useSelector<RootState, Features>(x => x.session.features)
    const costsOverview = useSelector<RootState, CostsOverview>(x => x.projects.activeCostsOverview)
    return (
        <>
            <TransmittalLinks revision={revision} />
            <FeatureGate features={sessionFeatures} flag={FeatureFlag.Costs} value={CostFlagValue.Default}>
                {() => <CommitmentLinks revision={revision} costsOverview={costsOverview} />}
            </FeatureGate>
        </>
    )
}
