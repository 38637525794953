import React from "react";
import { useAsyncAbortable } from "react-async-hook";
import { Field, Form } from "react-final-form";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";

import infopointLogo from "public/img/infopoint_colour.svg";

import FA from "@src/components/common/FontAwesomeIcon";
import ValidatedCheckboxRadio from "@src/components/common/ValidatedCheckboxRadio";
import ValidatedInput, {
  IProps as InputProps,
} from "@src/components/common/ValidatedInput";
import useBoolean from "@src/hooks/useBoolean";
import { auth } from "@src/logic/auth/AuthService";
import { isTokenExpired } from "@src/logic/auth/jwtHelper";
import { SharedDomains } from "@src/logic/company/domains";
import { buildFormErrorsFromModelState } from "@src/logic/forms/errors";
import {
  composeValidators,
  matchField,
  required,
  validPassword,
} from "@src/logic/forms/validation";
import {
  ActivateAccountExisting,
  ActivateAccountNew,
  ActivateAccountSendActivateEmail,
  CompanyMeGet,
  UserProfileGet,
} from "@src/logic/http/Api";
import SignupLeftImg from "public/img/signup-left.png";
import { isAxiosError } from "@src/logic/http/helpers";
import { stopPropagation } from "@src/logic/utils/Events";

const validate = (values: ISignupFormData) => {
  const errors = {} as any;

  if (!values.password) {
    errors.password = "Required";
  } else {
    const upper = +/[A-Z]/.test(values.password);
    const lower = +/[a-z]/.test(values.password);
    const number = +/\d/.test(values.password);
    const character = +/\W/.test(values.password);

    if (upper + lower + number + character < 3) {
      errors.password = [
        "Requires 3 of uppercase, lowercase, number, and special character",
      ];
    } else if (values.password.length < 8) {
      errors.password = "Needs to be at least 8 characters";
    }
  }

  if (!values.confirmPassword || values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};

interface ISignupFormProps {
  token: string;
}

interface CompanyReference {
  id: string;
  name: string;
  abn: string;
}

interface ISignupFormData {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  company: CompanyReference;
  autoApproveDomain: boolean;
  terms: boolean;
  isNewCompany: boolean;
  email: string;
  companyName: string;
}

const SignupForm: React.FC<ISignupFormProps> = ({ token }) => {
  const [decodedToken] = React.useState(auth.getUserProfile(token));
  const [tokenExpired] = React.useState(isTokenExpired(token));
  const newPasswordValidator = composeValidators(required, validPassword);
  const confirmPasswordValidator = matchField<ISignupFormData>(
    "password",
    "Passwords do not match"
  );

  const activationEmailSent = useBoolean(false);
  const [requireApprovalCompany, setRequireApprovalCompany] =
    React.useState<ISignupFormData["company"]>();

  const company = useAsyncAbortable(
    async (abortSignal) =>
      decodedToken.companyId
        ? (await CompanyMeGet({ abortSignal, accessToken: token })).data
        : undefined,
    [],
    { executeOnUpdate: false }
  );
  const user = useAsyncAbortable(
    async (abortSignal) =>
      (await UserProfileGet({ abortSignal, accessToken: token })).data,
    [],
    { executeOnUpdate: false }
  );

  async function activateAccount(values: ISignupFormData) {
    if (values.isNewCompany) {
      try {
        await ActivateAccountNew(
          {
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            abn: values.company.abn,
            companyName: values.company.name,
            autoApproveDomains: values.autoApproveDomain
              ? [user.result.email.split("@")[1]]
              : [],
          },
          token
        );
      } catch (err) {
        if (isAxiosError(err) && err.response && err.response.status === 400) {
          const submitErrors = buildFormErrorsFromModelState(
            values,
            err.response.data
          );

          if (err.response.data.abn) {
            const error =
              err.response.data.abn instanceof Array
                ? err.response.data.abn[0]
                : err.response.data.abn;
            if (submitErrors.company) submitErrors.company.abn = error;
            else
              submitErrors.company = {
                id: undefined,
                name: undefined,
                abn: error,
              };
          }

          return submitErrors;
        }
      }
    } else {
      await ActivateAccountExisting(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          companyId: company.result ? company.result.id : values.company ? values.company.id : undefined,
          companyName: !company.result && !values.company ? values.companyName : undefined,
        },
        token
      );

      // if (!company.result) setRequireApprovalCompany(values.company);
    }

    if (!company.result)
      //requires approval from company. Navigate to login
      window.location.href = "/";
    else await auth.login(values.email, values.password); // otherwise, try login
  }

  async function resendActivationEmail() {
    await ActivateAccountSendActivateEmail(decodedToken.userId);
    activationEmailSent.setTrue();
  }

  function validateTerms(value: boolean) {
    return value == null || !value
      ? "You must accept the terms to activate your account."
      : undefined;
  }

  return (
    <>
      {!tokenExpired &&
        (user.loading || company.loading ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div>
              <FA
                icon="circle-notch"
                size="3x"
                className="text-center my-3"
                spin
              />
            </div>
            <p>Loading...</p>
          </div>
        ) : (
          <Container fluid>
            <Row>
              <Col
                md={4}
                className="d-none d-md-block"
                style={{
                  backgroundImage: `url(${SignupLeftImg})`,
                  backgroundSize: "cover",
                  minHeight: "100vh",
                }}
              />
              <Col className="d-none d-md-block" xs={1} />
              <Col md={6}>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      className="justify-content-center align-items-center text-center"
                    >
                      <div>
                        <img
                          className="mt-2 mb-2 mx-auto"
                          width="200"
                          src={infopointLogo}
                          alt="InfoPoint Logo"
                        />
                        <h1 className="mt-3 pb-4 mx-auto font-weight-bold">
                          Sign up to InfoPoint
                        </h1>
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div>
                        <Form<ISignupFormData>
                          onSubmit={activateAccount}
                          validate={validate}
                          initialValues={{
                            firstName: user.result.firstName,
                            lastName: user.result.lastName,
                            email: user.result.email,
                            company: company.result
                              ? {
                                  id: company.result.id,
                                  abn: company.result.abn,
                                  name: company.result.name,
                                }
                              : undefined,
                            autoApproveDomain: !SharedDomains.includes(
                              user.result.email.split("@")[1]
                            ),
                            password: "",
                            confirmPassword: "",
                            terms: false,
                            isNewCompany: false,
                            companyName: undefined,
                          }}
                        >
                          {({ handleSubmit }) => (
                            <>
                              <div>
                                <FormGroup>
                                  <Label for="firstName">First Name *</Label>
                                  <Field
                                    id="firstName"
                                    name="firstName"
                                    component={ValidatedInput}
                                    validate={required}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="lastName">Last Name *</Label>
                                  <Field
                                    id="lastName"
                                    name="lastName"
                                    component={ValidatedInput}
                                    validate={required}
                                  />
                                </FormGroup>
                                {company.result && (
                                  <FormGroup>
                                    <Label for="company.name">
                                      Company Name *
                                    </Label>
                                    <Field
                                      id="company.name"
                                      name="company.name"
                                      component={ValidatedInput}
                                      validate={required}
                                      disabled
                                    />
                                </FormGroup>
                                )}
                                {!company.result && (
                                  <FormGroup>
                                    <Label for="companyName">Company Name *</Label>
                                    <Field
                                      id="companyName"
                                      name="companyName"
                                      component={ValidatedInput}
                                      validate={required}
                                    />
                                  </FormGroup>
                                )}
                                <FormGroup>
                                  <Label for="Email">Email *</Label>
                                  <Field
                                    id="email"
                                    name="email"
                                    component={ValidatedInput}
                                    validate={required}
                                    disabled
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="password">Password *</Label>
                                  <Field<string, InputProps<string>>
                                    id="password"
                                    name="password"
                                    component={ValidatedInput}
                                    type="password"
                                  />
                                </FormGroup>
                                <FormGroup className="mb-4">
                                  <Label for="confirmPassword">
                                    Confirm Password *
                                  </Label>
                                  <Field
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    component={ValidatedInput}
                                    type="password"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Field name="terms" validate={validateTerms}>
                                    {(termProps) => {
                                      const showError =
                                        termProps.meta.touched &&
                                        !!termProps.meta.error;
                                      return (
                                        <>
                                          <ValidatedCheckboxRadio
                                            id="terms-and-conditions"
                                            {...termProps}
                                            label={
                                              <span>
                                                I have read and agree to the{" "}
                                                <a
                                                  href="http://infopoint.com.au/terms-and-conditions/"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  onClick={stopPropagation}
                                                >
                                                  Terms of Service
                                                </a>
                                              </span>
                                            }
                                          />
                                          {showError && (
                                            <span className="form-text text-danger">
                                              {termProps.meta.error}
                                            </span>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Field>
                                </FormGroup>
                              </div>
                              <Button
                                block
                                size="lg"
                                color="primary"
                                className="text-uppercase p-3 font-weight-bold"
                                type="submit"
                                style={{
                                  fontSize: "90%",
                                  letterSpacing: ".08rem",
                                }}
                                onClick={handleSubmit}
                              >
                                Sign Up and Login
                              </Button>
                            </>
                          )}
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        ))}
      {tokenExpired && !activationEmailSent.value && (
        <div>
          <p>
            The account activation link you have used has expired. Use the
            button below to send a new activation link to your email address.
          </p>
          <p className="text-center">
            <Button color="primary" onClick={resendActivationEmail}>
              Resend Activation Link
            </Button>
          </p>
        </div>
      )}
      {activationEmailSent.value && (
        <div>
          <p>
            A new activation link has been sent to your email. Use that link to
            continue activating your account.
          </p>
        </div>
      )}
    </>
  );
};

export default SignupForm;
