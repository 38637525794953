import React from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { compare } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalProps } from '@src/hooks/useModal'
import { CommitmentDefinitionPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { CommitmentDefinition, CommitmentReportColumn, CommitmentReportColumnName, CommitmentStatusDefinition } from '@src/types/costs'

interface IProps extends IModalProps {
    projectId: string
    commitmentDefinition: CommitmentDefinition
    statusToEdit?: CommitmentStatusDefinition
    existingCommitmentDefinitions: CommitmentDefinition[]
}

export interface ICommitmentStatusFormData {
    code: string
    columns: { label: string, value: CommitmentReportColumn }[]
    allowClaim: boolean
    unlockedBudget: boolean
    requireOtherParty: boolean
}

const reportColumnOptions = Object.keys(CommitmentReportColumnName).map<{ label: string, value: string }>(k => ({ label: CommitmentReportColumnName[k], value: k }))

export default function CommitmentStatusModal({ commitmentDefinition, existingCommitmentDefinitions, projectId, statusToEdit, ...modalProps }: IProps) {
    const dispatch = useDispatch()
    const initialValues = React.useMemo(() => statusToEdit == null
    ? {
        columns: []
    }
    : {
        code: statusToEdit.code,
        columns: statusToEdit.columns.map(c => ({ label: CommitmentReportColumnName[c], value: c })),
        allowClaim: statusToEdit.allowClaim,
        unlockedBudget: statusToEdit.allowInUnlockedBudget,
        requireOtherParty: statusToEdit.requireOtherParty
    }, [statusToEdit])

    async function handleSubmit(values: ICommitmentStatusFormData) {
        if (statusToEdit) {
            const index = commitmentDefinition.statusDefinitions.findIndex(s => s.code === statusToEdit.code)
            if (index < 0) throw Error('Status to edit not found in commitment')
            const sd = statusToEdit
            const original: CommitmentStatusDefinition = {
                code: sd.code,
                columns: sd.columns,
                allowClaim: sd.allowClaim,
                allowInUnlockedBudget: sd.allowInUnlockedBudget,
                requireOtherParty: sd.requireOtherParty,
                mapping: sd.mapping
            }
            const update: CommitmentStatusDefinition = {
                code: statusToEdit.code,
                columns: values.columns.map(c => c.value),
                allowClaim: values.allowClaim,
                allowInUnlockedBudget: values.unlockedBudget,
                requireOtherParty: values.requireOtherParty,
                mapping: sd.mapping
            }

            const patch = compare(original, update).map(op => ({ ...op, path: `/statusDefinitions/${index}${op.path}` }))
            await CommitmentDefinitionPatch(projectId, commitmentDefinition.code, patch)
            NotificationService.info('Updated status')
        } else {
            const newStatusDefinition: CommitmentStatusDefinition = {
                code: values.code,
                columns: values.columns.map(c => c.value),
                allowClaim: values.allowClaim,
                allowInUnlockedBudget: values.unlockedBudget,
                requireOtherParty: values.requireOtherParty,
                mapping: {}
            }
            await CommitmentDefinitionPatch(projectId, commitmentDefinition.code, [
                { op: 'add', path: '/statusDefinitions/-', value: newStatusDefinition }
            ])
            NotificationService.info('Created status')
        }

        dispatch(loadProjectCostsOverview())
        modalProps.toggle()
    }

        return (
            <Form onSubmit={handleSubmit} initialValues={initialValues}>
                {({ handleSubmit }) =>
                    <Modal {...modalProps}>
                        <ModalHeader toggle={modalProps.toggle}>{statusToEdit ? `Edit - ${statusToEdit.code}` : 'New Status'}</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>Code</Label>
                                <Field name="code" component={ValidatedInput} disabled={statusToEdit != null} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Columns</Label>
                                <Field name="columns" component={ValidatedSelect} isMulti options={reportColumnOptions} />
                            </FormGroup>
                            <FormGroup>
                                <Field name="allowClaim" component={ValidatedCheckboxRadio} label="Allow Claim" />
                            </FormGroup>
                            <FormGroup>
                                <Field name="unlockedBudget" component={ValidatedCheckboxRadio} label="Unlocked Budget" />
                            </FormGroup>
                            <FormGroup>
                                <Field name="requireOtherParty" component={ValidatedCheckboxRadio} label="Require Other Party" />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit}>{statusToEdit ? 'Save' : 'Create'}</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Form>
        )
}
