import React from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, UncontrolledTooltip } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import TransmittalApprovalModal, { IApprovalFormData } from '@src/components/transmittal/TransmittalApprovalModal'
import TransmittalInnerForm, { ITransmittalFormData } from '@src/components/transmittal/TransmittalInnerForm'
import TransmittalToolbar from '@src/components/transmittal/TransmittalToolbar'
import useBoolean from '@src/hooks/useBoolean'
import useModal from '@src/hooks/useModal'
import { TransmittalApprove, TransmittalCreate, TransmittalPublish, TransmittalUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { projectTransmittalEdit, projectTransmittalView } from '@src/logic/routing/routes'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { ApprovalStatus, DocumentLink } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Session } from '@src/types/session'
import { Transmittal, TransmittalAddress, TransmittalStatus } from '@src/types/transmittal'
import { IProjectWidgetState } from '@src/types/widget'

interface IProps {
    transmittal?: Transmittal
    reloadTransmittal: (transmittal?: Transmittal) => void
}

export interface ITransmittalEditPageActions {
    submit: () => void
}

const TransmittalEditPage: React.FC<IProps> = ({ transmittal, reloadTransmittal }) => {
    const history = useHistory()
    if (transmittal && (transmittal.status === TransmittalStatus.Rejected || transmittal.status === TransmittalStatus.Sent)) {
        history.replace(projectTransmittalView(transmittal.projectId, transmittal.id))
        return null
    }

    const sendingTransmittal = useBoolean(false)
    const showSelectApprover = useModal(false)

    const user = useSelector<RootState, Session.User>(s => s.session.user)
    const projectId = useSelector<RootState, string>(s => s.projects.active.id)
    const widget = useSelector<RootState, IProjectWidgetState>(s => getActiveProjectWidgetState(s))

    const initialValues: ITransmittalFormData = React.useMemo(
        () => ({
            subject: transmittal?.subject,
            reason: transmittal?.reason ? { label: transmittal.reason, value: transmittal?.reason } : undefined,
            responseDate: transmittal ? new Date(transmittal.responseDate) : new Date(),
            responseType: transmittal?.reason ? { label: transmittal.responseType, value: transmittal?.responseType } : undefined,
            to: transmittal?.to ?? widget.users.map<TransmittalAddress>(u => ({ name: `${u.firstName} ${u.lastName}`, email: u.email, id: u.id })),
            cc: transmittal?.cc ?? [],
            bcc: transmittal?.bcc ?? [],
            revisions: transmittal?.revisions ?? widget.revisions.map<DocumentLink>(r => ({ revisionId: r.id, documentId: r.documentId, name: r.name, id: r.id })),
            documentGroups: transmittal?.documentGroups ?? [],
            body: transmittal?.body,
            attachToEmail: transmittal?.settings.attachDocumentsToEmail ?? false
        }),
        [transmittal]
    )

    async function saveTransmittal(values: ITransmittalFormData): Promise<Transmittal> {
        if (transmittal == null) {
            try {
                const result = await TransmittalCreate(projectId, {
                    subject: values.subject,
                    tags: [],
                    reason: values.reason?.value,
                    responseDate: values.responseDate,
                    responseType: values.responseType?.value,
                    requestedApprover: null,
                    approvalRequestComments: null,
                    to: (values.to || []).map(o => o.id),
                    cc: (values.cc || []).map(o => o.id),
                    bcc: (values.bcc || []).map(o => o.id),
                    body: values.body,
                    linkedRevisions: (values.revisions || []).map(o => o.revisionId),
                    linkedDocumentGroups: [],
                    settings: {
                        attachDocumentsToEmail: values.attachToEmail
                    }
                })
                history.replace(projectTransmittalEdit(result.data.projectId, result.data.id))
                return
            } catch (err) {
                NotificationService.error('There was an error while saving the draft.')
                return err
            }
        }

        const updatedTransmittal: Transmittal = {
            ...transmittal,
            updatedBy: {
                id: user.id,
                name: user.fullName
            },
            updatedDate: new Date(),
            subject: values.subject,
            tags: [],
            reason: values.reason?.value,
            responseDate: values.responseDate,
            responseType: values.responseType?.value,
            to: [...values.to],
            cc: [...values.cc],
            bcc: [...values.bcc],
            body: values.body,
            revisions: [...values.revisions],
            settings: { attachDocumentsToEmail: values.attachToEmail }
        }
        try {
            await TransmittalUpdate(transmittal.id, {
                subject: updatedTransmittal.subject,
                tags: [],
                reason: updatedTransmittal.reason,
                responseDate: updatedTransmittal.responseDate,
                responseType: updatedTransmittal.responseType,
                requestedApprover: transmittal.approval?.requestedApprover?.id,
                approvalRequestComments: null,
                to: updatedTransmittal.to.map(o => o.id),
                cc: updatedTransmittal.cc.map(o => o.id),
                bcc: updatedTransmittal.bcc.map(o => o.id),
                body: updatedTransmittal.body,
                linkedRevisions: updatedTransmittal.revisions.map(o => o.revisionId),
                linkedDocumentGroups: updatedTransmittal.documentGroups.map(o => o.id),
                settings: updatedTransmittal.settings
            })
        } catch (err) {
            NotificationService.error('There was an error while saving the draft.')
            return err
        }
        reloadTransmittal(updatedTransmittal)
    }

    async function approveTransmittal() {
        if (sendingTransmittal.value) return
        sendingTransmittal.setTrue()
        const sendingNotification = NotificationService.pendingActivityToast('Sending transmittal...')
        try {
            await TransmittalApprove(transmittal.id, { comments: '' })
            NotificationService.updateThenCloseToast(sendingNotification, 'Transmittal sent')
        } catch {
            NotificationService.updateThenCloseToast(sendingNotification, 'There was an issue while sending the transmittal', { type: 'error' })
        } finally {
            sendingTransmittal.setFalse()
        }
        reloadTransmittal()
    }

    async function submitApproval(values: IApprovalFormData) {
        await TransmittalUpdate(transmittal.id, {
            subject: transmittal.subject,
            tags: transmittal.tags,
            reason: transmittal.reason,
            responseDate: transmittal.responseDate,
            responseType: transmittal.responseType,
            requestedApprover: values.approver.id,
            approvalRequestComments: values.comments,
            to: transmittal.to.map(o => o.id),
            cc: transmittal.cc.map(o => o.id),
            bcc: transmittal.bcc.map(o => o.id),
            body: transmittal.body,
            linkedRevisions: transmittal.revisions.map(o => o.revisionId),
            linkedDocumentGroups: transmittal.documentGroups.map(o => o.id),
            settings: {
                attachDocumentsToEmail: transmittal.settings.attachDocumentsToEmail
            }
        })

        showSelectApprover.actions.hide()

        await TransmittalPublish(transmittal.id)

        if (values.approver.id === user.id) {
            await approveTransmittal()
        } else {
            reloadTransmittal()
            NotificationService.info('Transmittal published for approval')
        }
    }

    return (
        <Form
            onSubmit={saveTransmittal}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            subscription={{ submitting: true, dirty: true }}
        >
            {form =>
                <>
                    <TransmittalToolbar transmittal={transmittal}>
                            <div className="mr-2">
                                <Button color="primary" onClick={form.handleSubmit}>
                                    <FA icon={form.submitting ? 'spinner-third' : transmittal == null || form.dirty ? 'save' : 'check-circle'} spin={form.submitting} />
                                    <span className="d-none d-md-inline-block ml-2">{form.submitting ? 'Saving...' : transmittal == null || form.dirty ? 'Save' : 'Saved'}</span>
                                </Button>
                            </div>
                        <div>
                            {(transmittal?.to == null || transmittal?.to.length === 0) &&
                                <UncontrolledTooltip target="request-approval-wrapper">
                                    Transmittal requires at least one recipient
                                </UncontrolledTooltip>
                            }
                            <div id="request-approval-wrapper" className="mr-2">
                                <Button
                                    color="primary"
                                    disabled={transmittal?.to == null || transmittal?.to.length === 0}
                                    onClick={showSelectApprover.actions.show}
                                >
                                    <FA icon="file-signature" />
                                    <span className="d-none d-md-inline-block ml-2">{transmittal?.status === TransmittalStatus.Draft ? 'Request Approval' : 'Change Approver'}</span>
                                </Button>
                            </div>
                        </div>
                        {transmittal?.status === TransmittalStatus.Published && transmittal?.approval.status !== ApprovalStatus.Denied && !sendingTransmittal.value &&
                            <div>
                                <Button color="success" onClick={approveTransmittal}>
                                    <FA icon="file-check" />
                                    <span className="d-none d-md-inline-block ml-2">Approve &amp; Send</span>
                                </Button>
                            </div>
                        }
                    </TransmittalToolbar>
                    <div className="mb-3" />
                    <TransmittalInnerForm
                        projectId={projectId}
                        transmittalId={transmittal?.id}
                    />
                    {transmittal && <TransmittalApprovalModal
                        {...showSelectApprover.modalProps}
                        projectId={projectId}
                        onSubmit={submitApproval}
                    />}
                </>
            }
        </Form>
    )
}

export default React.memo(TransmittalEditPage)
