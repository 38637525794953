import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { Button, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { throttle } from 'lodash'

import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import useConstant from '@src/hooks/useConstant'
import { IModalProps } from '@src/hooks/useModal'
import { templateLabel, templateValue } from '@src/logic/forms/SelectHelpers'; import { required } from '@src/logic/forms/validation'
import { CommunicationCreate, TemplatesList } from '@src/logic/http/Api'
import { Communication, TemplateBrief } from '@src/types/communication'

interface IProps extends IModalProps {
    projectId: string
    onCommunicationCreated?: (communication: Communication) => void
}

interface INewCommunicationFormData {
    name: string
    template: TemplateBrief
}

async function loadTemplates(abortSignal: AbortSignal, projectId: string, query: string): Promise<TemplateBrief[]> {
    const templatesResponse = await TemplatesList(`project: ${projectId},null AND name: "${query.replace('"', '\\"')}"`, undefined, 1, 100, { abortSignal })
    return templatesResponse.data
}

export default function NewCommunicationModal({ projectId, onCommunicationCreated, ...modalProps }: IProps) {
    const throttledLoadTemplates = useConstant(() => throttle(loadTemplates, 300))
    const templatesAsync = useAsyncAbortable((ct, query) => throttledLoadTemplates(ct, projectId, query), [''], { executeOnMount: false })

    async function doSubmit(values: INewCommunicationFormData) {
        const communicationResponse = await CommunicationCreate(projectId, {
            name: values.name,
            description: '',
            templateId: values.template.id,
            to: [],
            cc: [],
            metadata: {},
            metadataDefinitions: null,
            tags: []
        })

        onCommunicationCreated?.(communicationResponse.data)
        modalProps.toggle()
    }

    return (
        <Modal {...modalProps}>
            <Form onSubmit={doSubmit}>
                {({ handleSubmit, submitting }) => (
                    <>
                        <ModalHeader toggle={modalProps.toggle}>New Communication</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Field id="name" name="name" component={ValidatedInput} validate={required} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="template">Template</Label>
                                <Field
                                    id="template"
                                    name="template"
                                    validate={required}
                                    component={ValidatedSelect}
                                    selectType="async"
                                    defaultOptions
                                    loadOptions={templatesAsync.execute}
                                    getOptionLabel={templateLabel}
                                    getOptionValue={templateValue}
                                />
                                <Field<TemplateBrief> name="template" render={({ input: { value } }) => value && <FormText className="user-text">{value.description}</FormText>} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit} disabled={submitting}>Create</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}
