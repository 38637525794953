import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Col, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

import { IOption, buildOptions } from '@src/components/common/Select'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { FieldPrefix, PrefixedField } from '@src/components/forms/FieldPrefix'
import { IMetadataDefinitionForm } from '@src/components/register/EditColumnDropdown'
import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import useBoolean from '@src/hooks/useBoolean'
import { IModalProps } from '@src/hooks/useModal'
import { composeValidators, isMetadataKeyForm, required } from '@src/logic/forms/validation'
import { AutoNumberDefinition, BoolDefinition, CommitmentEntityProps, CommitmentLinksDefinition, CommitmentPropertyDefinition, CompanyEntityProps, CompanyLinksDefinition, CompanyPropertyDefinition, DateDefinition, DocumentEntityProps, DocumentLinksDefinition, DocumentPropertyDefinition, EmailEntityProps, EmailLinksDefinition, EmailPropertyDefinition, IMetadataDefinition, MetadataTypes, NumericDefinition, PaymentClaimLinksDefinition, PaymentEntityProps, PaymentPropertyDefinition, SelectDefinition, TextDefinition, TransmittalEntityProps, TransmittalLinksDefinition, TransmittalPropertyDefinition, UserEntityProps, UserLinksDefinition, UserPropertyDefinition } from '@src/types/metadata'
import { RegisterColumn, RegisterColumnDefinition } from '@src/types/register'

export interface IProps extends IModalProps {
    column: RegisterColumn
    onSubmit: (values) => Promise<unknown>
}

function GeneralTab({ columnDefinition }: { columnDefinition: RegisterColumnDefinition }) {
    return (
        <div className="GeneralTab row">
            <Col className="mb-3">
                <MetadataDefinitionSpreadsheet
                    definition={columnDefinition}
                />
            </Col>
        </div>
    )
}

function StyleTab({ columnDefinition }: { columnDefinition: RegisterColumnDefinition }) {
    const usingColourOption = useBoolean(false)
    const [currentColour, setCurrentColour] = useState('white')
    return (
        <Row>
            <Col className="mb-3">
                <Label>Colours</Label>
                <div style={{ border: '1px solid #DEE7EF', borderRadius: 2, padding: 20, paddingLeft: 10, paddingRight: 10, position: 'relative' }}>
                    <FormGroup>
                        <button className="column-modal__colour-picker-button" onBlur={usingColourOption.setFalse} onClick={usingColourOption.setTrue}
                            style={{ backgroundColor: currentColour }}>{currentColour === 'white' ? '(not set)' : ''}</button>
                        <div className="column-modal__dropup-content" style={{ display: usingColourOption.value ? 'block' : 'none' }}>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('#E7EBEE')} style={{ backgroundColor: '#E7EBEE' }}></button>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('#C2E1FD')} style={{ backgroundColor: '#C2E1FD' }}></button>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('#C1E9B7')} style={{ backgroundColor: '#C1E9B7' }}></button>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('#FAEDAC')} style={{ backgroundColor: '#FAEDAC' }}></button>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('#FBCE99')} style={{ backgroundColor: '#FBCE99' }}></button>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('#F1A1A3')} style={{ backgroundColor: '#F1A1A3' }}></button>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('#E8C3FF')} style={{ backgroundColor: '#E8C3FF' }}></button>
                            <button className="column-modal__colour-button" onClick={() => setCurrentColour('white')} style={{ backgroundColor: 'white' }}></button>
                        </div>
                        <div style={{ minWidth: '75%', paddingLeft: 10 }}>
                            <Field name={`colour.${columnDefinition.key}`} component={ValidatedSelect} />
                        </div>
                    </FormGroup>
                    <Button color="info"><FA icon="plus" size="sm" /><span className="pl-1">Add colour</span></Button>
                </div>
                <FormText>Set colours to specific values for this column</FormText>
            </Col>
        </Row>
    )
}

export default function EditColumnModal({ onSubmit, column, ...modalProps }: IProps) {
    const [activeTab, setActiveTab] = useState('generalTab')

    const initialValues = React.useMemo<IMetadataDefinitionForm<unknown>>(() => ({
        key: column.metadataDefinition.key,
        description: column.metadataDefinition.description,
        isRequired: column.metadataDefinition.isRequired,
        name: column.metadataDefinition.name,
        options: column.metadataDefinition.options
    }), [column.metadataDefinition])

    function tabIsActive(tabName) {
        return activeTab === tabName
    }

    return (
        <Modal {...modalProps} trapFocus>
            <Form onSubmit={onSubmit} initialValues={initialValues} subscription={{}}>
                {({ handleSubmit }) =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>Edit - {column.metadataDefinition.name}</ModalHeader>
                        <ModalBody className="pt-0">
                            <Toolbar>
                                <ToolbarLink name="General" active={tabIsActive('generalTab')} onClick={() => setActiveTab('generalTab')} />
                                <ToolbarLink name="Style" disabled={true} />
                            </Toolbar>
                            <div className="mt-3">
                                {activeTab === 'generalTab' ? <GeneralTab columnDefinition={column.metadataDefinition} /> : <StyleTab columnDefinition={column.metadataDefinition} />}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={modalProps.toggle}>Cancel</Button>
                            <Button className="primary" onClick={handleSubmit}>Save</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}

const columnKeyValidator = composeValidators(required, isMetadataKeyForm)

export function MetadataDefinitionSpreadsheet<T extends IMetadataDefinition<OptionsOf<T>>>({ definition }: ICommonProps<T>) {
    const DefinitionFormBody: MetadataDefinitionOptionsComponent<T, unknown> = React.useMemo(() => {
        switch (definition.type) {
            case MetadataTypes.AutoNumber:
                return AutoNumberMetadataDefinition
            case MetadataTypes.Bool:
                return BoolMetadataDefinition
            case MetadataTypes.Numeric:
                return NumericMetadataDefinition
            case MetadataTypes.Text:
                return TextMetadataDefinition
            case MetadataTypes.Date:
                return DateMetadataDefinition
            case MetadataTypes.Select:
                return SelectMetadataDefinition
            case MetadataTypes.DocumentLinks:
                return DocumentLinksMetadataDefinition
            case MetadataTypes.DocumentProperty:
                return DocumentPropertyMetadataDefinition
            case MetadataTypes.TransmittalLinks:
                return TransmittalLinksMetadataDefinition
            case MetadataTypes.TransmittalProperty:
                return TransmittalPropertyMetadataDefinition
            case MetadataTypes.CompanyLinks:
                return CompanyLinksMetadataDefinition
            case MetadataTypes.CompanyProperty:
                return CompanyPropertyMetadataDefinition
            case MetadataTypes.UserLinks:
                return UserLinksMetadataDefinition
            case MetadataTypes.UserProperty:
                return UserPropertyMetadataDefinition
            case MetadataTypes.EmailLinks:
                return EmailLinksMetadataDefinition
            case MetadataTypes.EmailProperty:
                return EmailPropertyMetadataDefinition
            case MetadataTypes.CommitmentLinks:
                return CommitmentLinksMetadataDefinition
            case MetadataTypes.CommitmentProperty:
                return CommitmentPropertyMetadataDefinition
            case MetadataTypes.PaymentClaimLinks:
                return PaymentClaimLinksMetadataDefinition
            case MetadataTypes.PaymentProperty:
                return PaymentPropertyMetadataDefinition
            default:
                return null
        }
    }, [definition.type]) as MetadataDefinitionOptionsComponent<T, unknown>

    return (
        <>
            <FormGroup>
                <Label htmlFor="key">Search Key</Label>
                <Field id="key" name="key" component={ValidatedInput} validate={columnKeyValidator} />
                <FormText>The key to search against this column.</FormText>
            </FormGroup>
            <FormGroup>
                <Label for="description">Description</Label>
                <Field id="description" name="description" component={ValidatedInput} type="textarea" />
                <FormText>A short description which indicates how the field should be used.</FormText>
            </FormGroup>
            <legend className="col-form-label" style={{ color: '#818EA2', fontWeight: 'bold', paddingTop: 0 }}>Options</legend>
            <FormGroup>
                <Field id="isRequired" name="isRequired" component={ValidatedCheckboxRadio} label="Required" />
            </FormGroup>
            <DefinitionFormBody definition={definition} />
        </>
    )
}

type OptionsOf<T> = T extends IMetadataDefinition<infer TOpts> ? TOpts : never

interface IOptionsProps<T extends IMetadataDefinition<OptionsOf<T>>> {
    definition: T
}

interface ICommonProps<T extends IMetadataDefinition<unknown>> {
    definition: T
}

// An interface for components to handle formatting options from form values to submit values
interface IOptionsFormatter<TDefinition extends IMetadataDefinition<OptionsOf<TDefinition>>, TFormOpts> {
    formatInitializeOptions: (options: OptionsOf<TDefinition>) => TFormOpts
    formatSubmitOptions: (definition: TDefinition, options: TFormOpts) => OptionsOf<TDefinition>
}

type MetadataDefinitionOptionsComponent<T extends IMetadataDefinition<OptionsOf<T>>, TOpts> = React.ComponentType<IOptionsProps<T>> & IOptionsFormatter<T, TOpts>

export const BoolMetadataDefinition: MetadataDefinitionOptionsComponent<BoolDefinition, {}> = () => {
    return null
}
BoolMetadataDefinition.formatInitializeOptions = (options) => ({})
BoolMetadataDefinition.formatSubmitOptions = (options) => ({})

interface INumericOpts {
    enforceRange: boolean
    minValue?: number
    maxValue?: number
    format?: string
}

export const NumericMetadataDefinition: MetadataDefinitionOptionsComponent<NumericDefinition, INumericOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <PrefixedField id="enforceRange" name="enforceRange" component={ValidatedCheckboxRadio} label="Enforce Range" />
            </FormGroup>
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minValue">Minimum Value</Label>
                        <PrefixedField id="minValue" name="minValue" component={ValidatedInput} type="number" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxValue">Maximum Value</Label>
                        <PrefixedField id="maxValue" name="maxValue" component={ValidatedInput} type="number" />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for="format">Format</Label>
                <PrefixedField id="format" name="format" component={ValidatedInput} />
            </FormGroup>
        </FieldPrefix>
    )
}
NumericMetadataDefinition.formatInitializeOptions = (options) => options
NumericMetadataDefinition.formatSubmitOptions = (_, options) => options

interface ITextOpts {
    minLength?: number
    maxLength?: number
}

export const TextMetadataDefinition: MetadataDefinitionOptionsComponent<TextDefinition, ITextOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minLength">Minimum Length</Label>
                        <PrefixedField id="minLength" name="minLength" component={ValidatedInput} type="number" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxLength">Maximum Length</Label>
                        <PrefixedField id="maxLength" name="maxLength" component={ValidatedInput} type="number" />
                    </FormGroup>
                </Col>
            </Row>
        </FieldPrefix>
    )
}
TextMetadataDefinition.formatInitializeOptions = (options) => options
TextMetadataDefinition.formatSubmitOptions = (_, options) => options

const commonDateOptions: IOption<string>[] = [
    {
        label: 'DD/MM/YYYY',
        value: 'DD/MM/YYYY'
    },
    {
        label: 'YYYY-MM-DDTHH:mm:ssZ',
        value: 'YYYY-MM-DDTHH:mm:ssZ'
    }
]

interface IDateOpts {
    enforceRange: boolean
    minValue?: Date
    maxValue?: Date
    format: IOption<string>
}

export const DateMetadataDefinition: MetadataDefinitionOptionsComponent<DateDefinition, IDateOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <PrefixedField id="enforceRange" name="enforceRange" component={ValidatedCheckboxRadio} label="Enforce Range" />
            </FormGroup>
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minValue">Minimum Date</Label>
                        <PrefixedField id="minValue" name="minValue" component={ValidatedDatePicker} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxValue">Maximum Date</Label>
                        <PrefixedField id="maxValue" name="maxValue" component={ValidatedDatePicker} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for="format">Format</Label>
                <PrefixedField id="format" name="format" component={ValidatedSelect} selectType="creatable" options={commonDateOptions} />
            </FormGroup>
        </FieldPrefix>
    )
}

DateMetadataDefinition.formatInitializeOptions = (options) => ({
    enforceRange: options.enforceRange,
    minValue: options.minValue,
    maxValue: options.maxValue,
    format: { label: options.format, value: options.format }
})
DateMetadataDefinition.formatSubmitOptions = (_, options) => ({
    enforceRange: options.enforceRange,
    minValue: options.minValue,
    maxValue: options.maxValue,
    format: options.format.value
})

interface ISelectOpts {
    enforceValues: boolean
    isMultiselect: boolean
    values: IOption<string>[]
}

function parseSelectValues(values: IOption<string>[] | null) {
    return values?.map(x => x.value) ?? []
}

function formatSelectValues(values: string[] | null): IOption<string>[] {
    return values ? buildOptions(values) : []
}

export const SelectMetadataDefinition: MetadataDefinitionOptionsComponent<SelectDefinition, ISelectOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <PrefixedField id="enforceValues" name="enforceValues" component={ValidatedCheckboxRadio} label="Enforce Options" />
            </FormGroup>
            <FormGroup>
                <PrefixedField id="isMultiSelect" name="isMultiselect" component={ValidatedCheckboxRadio} label="Multi Select" />
            </FormGroup>
            <FormGroup style={{ paddingTop: 10 }}>
                <Label for="values">Select Options</Label>
                <PrefixedField
                    id="values"
                    name="values"
                    component={ValidatedSelect}
                    isMulti
                    selectType="creatable"
                    parse={parseSelectValues}
                    format={formatSelectValues}
                />
            </FormGroup>
        </FieldPrefix>
    )
}
SelectMetadataDefinition.formatInitializeOptions = (options) => ({
    enforceValues: options.enforceValues,
    isMultiselect: options.isMultiselect,
    values: options.values.map(v => ({ label: v, value: v }))
})
SelectMetadataDefinition.formatSubmitOptions = (_, options) => ({
    enforceValues: options.enforceValues,
    isMultiselect: options.isMultiselect,
    values: options.values.map(x => x.value)
})

interface IDocumentLinkOpts {
    limit?: number
}

export const DocumentLinksMetadataDefinition: MetadataDefinitionOptionsComponent<DocumentLinksDefinition, IDocumentLinkOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} />
            </FormGroup>
        </FieldPrefix>
    )
}
DocumentLinksMetadataDefinition.formatInitializeOptions = (options) => options
DocumentLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const DocumentPropertySelectOptions: IOption<DocumentEntityProps>[] = [
    {
        label: 'Author',
        value: DocumentEntityProps.Author
    },
    {
        label: 'Created By',
        value: DocumentEntityProps.CreatedBy
    },
    {
        label: 'Created Date',
        value: DocumentEntityProps.CreatedDate
    },
    {
        label: 'Description',
        value: DocumentEntityProps.Description
    },
    {
        label: 'Metadata',
        value: DocumentEntityProps.MetaData
    },
    {
        label: 'Name',
        value: DocumentEntityProps.Name
    },
    {
        label: 'Published',
        value: DocumentEntityProps.Published
    },
    {
        label: 'Revision Date',
        value: DocumentEntityProps.RevisionDate
    },
    {
        label: 'Revision Number',
        value: DocumentEntityProps.RevisionNo
    }
]

interface IDocumentPropertyOpts {
    property: typeof DocumentPropertySelectOptions[0]
}

function buildFormatProperty<T>(options: IOption<T>[]) {
    return (value: T) => options.find(x => x?.value === value)
}

function parseProperty<T>(option: IOption<T>) {
    return option?.value
}

const formatDocumentProperty = buildFormatProperty(DocumentPropertySelectOptions)

export const DocumentPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<DocumentPropertyDefinition, IDocumentPropertyOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={DocumentPropertySelectOptions} format={formatDocumentProperty} parse={parseProperty} />
            </FormGroup>
        </FieldPrefix>
    )
}
DocumentPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: DocumentPropertySelectOptions.find(x => x.value === options.property)
})
DocumentPropertyMetadataDefinition.formatSubmitOptions = (definition, options) => ({
    property: options.property.value,
    parentMetadataKey: definition.options.parentMetadataKey
})

interface ITransmittalLinkOpts {
    limit?: number
}

export const TransmittalLinksMetadataDefinition: MetadataDefinitionOptionsComponent<TransmittalLinksDefinition, ITransmittalLinkOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} />
            </FormGroup>
        </FieldPrefix>
    )
}
TransmittalLinksMetadataDefinition.formatInitializeOptions = (options) => options
TransmittalLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const TransmittalPropertySelectOptions: IOption<TransmittalEntityProps>[] = [
    {
        label: 'Created Date',
        value: TransmittalEntityProps.CreatedDate
    },
    {
        label: 'Reference Number',
        value: TransmittalEntityProps.ReferenceNo
    },
    {
        label: 'Response Date',
        value: TransmittalEntityProps.ResponseDate
    },
    {
        label: 'Response Type',
        value: TransmittalEntityProps.ResponseType
    },
    {
        label: 'Status',
        value: TransmittalEntityProps.Status
    },
    {
        label: 'Subject',
        value: TransmittalEntityProps.Subject
    }
]

interface ITransmittalPropertyOpts {
    property: typeof TransmittalPropertySelectOptions[0]
}

const transmittalFormatProperty = buildFormatProperty(TransmittalPropertySelectOptions)

export const TransmittalPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<TransmittalPropertyDefinition, ITransmittalPropertyOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={TransmittalPropertySelectOptions} format={transmittalFormatProperty} parse={parseProperty} />
            </FormGroup>
        </FieldPrefix>
    )
}
TransmittalPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: TransmittalPropertySelectOptions.find(x => x.value === options.property)
})
TransmittalPropertyMetadataDefinition.formatSubmitOptions = (definition, options) => ({
    property: options.property.value
})

interface ICompanyLinkOpts {
    limit?: number
}

export const CompanyLinksMetadataDefinition: MetadataDefinitionOptionsComponent<CompanyLinksDefinition, ICompanyLinkOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} />
            </FormGroup>
        </FieldPrefix>
    )
}
CompanyLinksMetadataDefinition.formatInitializeOptions = (options) => options
CompanyLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const CompanyPropertySelectOptions: IOption<CompanyEntityProps>[] = [
    {
        label: 'Abbreviated Name',
        value: CompanyEntityProps.AbbreviatedName
    },
    {
        label: 'Name',
        value: CompanyEntityProps.Name
    },
    {
        label: 'Disciplines',
        value: CompanyEntityProps.Disciplines
    }
]

interface ICompanyPropertyOpts {
    property: typeof CompanyPropertySelectOptions[0]
}

const companyFormatProperty = buildFormatProperty(CompanyPropertySelectOptions)

export const CompanyPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<CompanyPropertyDefinition, ICompanyPropertyOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label name="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={CompanyPropertySelectOptions} format={companyFormatProperty} parse={parseProperty} />
            </FormGroup>
        </FieldPrefix>
    )
}
CompanyPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: CompanyPropertySelectOptions.find(x => x.value === options.property)
})
CompanyPropertyMetadataDefinition.formatSubmitOptions = (_, options) => ({
    property: options.property.value
})

interface IUserLinkOpts {
    limit?: number
}

export const UserLinksMetadataDefinition: MetadataDefinitionOptionsComponent<UserLinksDefinition, IUserLinkOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} />
            </FormGroup>
        </FieldPrefix>
    )
}
UserLinksMetadataDefinition.formatInitializeOptions = (options) => options
UserLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const UserPropertySelectOptions: IOption<UserEntityProps>[] = [
    {
        label: 'Email',
        value: UserEntityProps.Email
    },
    {
        label: 'Mobile',
        value: UserEntityProps.Mobile
    },
    {
        label: 'Name',
        value: UserEntityProps.Name
    },
    {
        label: 'Role',
        value: UserEntityProps.Role
    }
]

interface IUserPropertyOpts {
    property: typeof UserPropertySelectOptions[0]
}

const userFormatProperty = buildFormatProperty(UserPropertySelectOptions)

export const UserPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<UserPropertyDefinition, IUserPropertyOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={UserPropertySelectOptions} parse={parseProperty} format={userFormatProperty} />
            </FormGroup>
        </FieldPrefix>
    )
}
UserPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: UserPropertySelectOptions.find(x => x.value === options.property)
})
UserPropertyMetadataDefinition.formatSubmitOptions = (definition, options) => ({
    property: options.property.value
})

interface IEmailLinkOpts {
    limit?: number
}

export const EmailLinksMetadataDefinition: MetadataDefinitionOptionsComponent<EmailLinksDefinition, IEmailLinkOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} />
            </FormGroup>
        </FieldPrefix>
    )
}
EmailLinksMetadataDefinition.formatInitializeOptions = (options) => options
EmailLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const EmailPropertySelectOptions: IOption<EmailEntityProps>[] = [
    {
        label: 'Bcc',
        value: EmailEntityProps.Bcc
    },
    {
        label: 'Cc',
        value: EmailEntityProps.Cc
    },
    {
        label: 'Date',
        value: EmailEntityProps.Date
    },
    {
        label: 'From',
        value: EmailEntityProps.From
    },
    {
        label: 'Subject',
        value: EmailEntityProps.Subject
    },
    {
        label: 'To',
        value: EmailEntityProps.To
    }
]

interface IEmailPropertyOpts {
    property: typeof EmailPropertySelectOptions[0]
}

const emailFormatProperty = buildFormatProperty(EmailPropertySelectOptions)

export const EmailPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<EmailPropertyDefinition, IEmailPropertyOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={EmailPropertySelectOptions} format={emailFormatProperty} parse={parseProperty} />
            </FormGroup>
        </FieldPrefix>
    )
}
EmailPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: EmailPropertySelectOptions.find(x => x.value === options.property)
})
EmailPropertyMetadataDefinition.formatSubmitOptions = (_, options) => ({
    property: options.property.value
})

interface IAutoNumberOpts {
    displayPrefix: boolean
    minValue: number
    maxValue: number
}

export const AutoNumberMetadataDefinition: MetadataDefinitionOptionsComponent<AutoNumberDefinition, IAutoNumberOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Field name="displayPrefix" component={ValidatedCheckboxRadio} label="Display prefix" />
            </FormGroup>
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minValue">Minimum Value</Label>
                        <PrefixedField id="minValue" name="minValue" component={ValidatedInput} type="number" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxValue">Maximum Value</Label>
                        <PrefixedField id="maxValue" name="maxValue" component={ValidatedInput} type="number" />
                    </FormGroup>
                </Col>
            </Row>
        </FieldPrefix>
    )
}
AutoNumberMetadataDefinition.formatInitializeOptions = (options) => options
AutoNumberMetadataDefinition.formatSubmitOptions = (_, options) => options

interface ICommitmentLinkOpts {
    limit?: number
}

export const CommitmentLinksMetadataDefinition: MetadataDefinitionOptionsComponent<CommitmentLinksDefinition, ICommitmentLinkOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} />
            </FormGroup>
        </FieldPrefix>
    )
}
CommitmentLinksMetadataDefinition.formatInitializeOptions = (options) => options
CommitmentLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const CommitmentPropertySelectOptions: IOption<CommitmentEntityProps>[] = [
    {
        label: 'Commitment Number',
        value: CommitmentEntityProps.CommitmentNo
    },
    {
        label: 'Claimed',
        value: CommitmentEntityProps.Claimed
    },
    {
        label: 'Certified',
        value: CommitmentEntityProps.Certified
    },
    {
        label: 'Date',
        value: CommitmentEntityProps.Date
    },
    {
        label: 'Description',
        value: CommitmentEntityProps.Description
    },
    {
        label: 'Name',
        value: CommitmentEntityProps.Name
    },
    {
        label: 'Notes',
        value: CommitmentEntityProps.Notes
    },
    {
        label: 'Other Party',
        value: CommitmentEntityProps.OtherParty
    },
    {
        label: 'Other Party Reference',
        value: CommitmentEntityProps.OtherPartyReference
    },
    {
        label: 'Paid',
        value: CommitmentEntityProps.Paid
    },
    {
        label: 'Type',
        value: CommitmentEntityProps.Type
    },
    {
        label: 'Value',
        value: CommitmentEntityProps.Value
    }
]

interface ICommitmentPropertyOpts {
    property: typeof CommitmentPropertySelectOptions[0]
}

const commitmentFormatProperty = buildFormatProperty(CommitmentPropertySelectOptions)

export const CommitmentPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<CommitmentPropertyDefinition, ICommitmentPropertyOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={CommitmentPropertySelectOptions} format={commitmentFormatProperty} parse={parseProperty} />
            </FormGroup>
        </FieldPrefix>
    )
}
CommitmentPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: CommitmentPropertySelectOptions.find(x => x.value === options.property)
})
CommitmentPropertyMetadataDefinition.formatSubmitOptions = (_, options) => ({
    property: options.property.value
})

interface IPaymentClaimLinkOpts {
    limit?: number
}

export const PaymentClaimLinksMetadataDefinition: MetadataDefinitionOptionsComponent<PaymentClaimLinksDefinition, IPaymentClaimLinkOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} />
            </FormGroup>
        </FieldPrefix>
    )
}
PaymentClaimLinksMetadataDefinition.formatInitializeOptions = (options) => options
PaymentClaimLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const PaymentPropertySelectOptions: IOption<PaymentEntityProps>[] = [
    {
        label: 'Certificate Number',
        value: PaymentEntityProps.CertificateNumber
    },
    {
        label: 'Claim Date',
        value: PaymentEntityProps.ClaimDate
    },
    {
        label: 'Claim Reference',
        value: PaymentEntityProps.ClaimReference
    },
    {
        label: 'Invoice Date',
        value: PaymentEntityProps.InvoiceDate
    },
    {
        label: 'Invoice Reference',
        value: PaymentEntityProps.InvoiceReference
    },
    {
        label: 'Notes',
        value: PaymentEntityProps.Notes
    },
    {
        label: 'Status',
        value: PaymentEntityProps.Status
    },
    {
        label: 'Total Certified',
        value: PaymentEntityProps.TotalCertified
    },
    {
        label: 'Total Certified (inc. GST)',
        value: PaymentEntityProps.TotalCertifiedIncGst
    },
    {
        label: 'Total Claimed',
        value: PaymentEntityProps.TotalClaimed
    },
    {
        label: 'Total Paid',
        value: PaymentEntityProps.TotalPaid
    }
]

interface IPaymentPropertyOpts {
    property: typeof PaymentPropertySelectOptions[0]
}

const paymentFormatProperty = buildFormatProperty(PaymentPropertySelectOptions)

export const PaymentPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<PaymentPropertyDefinition, IPaymentPropertyOpts> = () => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={PaymentPropertySelectOptions} format={paymentFormatProperty} parse={parseProperty} />
            </FormGroup>
        </FieldPrefix>
    )
}
PaymentPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: PaymentPropertySelectOptions.find(x => x.value === options.property)
})
PaymentPropertyMetadataDefinition.formatSubmitOptions = (_, options) => ({
    property: options.property.value
})
