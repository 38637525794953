import React from 'react'
import { useForm, useFormState } from 'react-final-form'

import { debounce } from 'lodash'

import useConstant from '@src/hooks/useConstant'
import useUpdateEffect from '@src/hooks/useUpdateEffect'

interface AutoSaveProps {
    wait: number
    shouldSave?: () => boolean
}

export default function AutoSave({ wait, shouldSave }: AutoSaveProps): React.ReactElement {
    const form = useForm()
    const debouncedSave = useConstant(() => debounce(() => {
        const { submitting, dirty } = form.getState()
        if (!submitting && dirty && (shouldSave?.() ?? true)) {
            form.submit()
        }
    }, wait))
    const { values } = useFormState({ subscription: { values: true } })

    useUpdateEffect(debouncedSave, [values])

    return null
}
