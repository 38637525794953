import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Button, Col, FormText, Row, UncontrolledTooltip } from 'reactstrap'

import Action from '@src/components/common/Action'
import FA from '@src/components/common/FontAwesomeIcon'
import { MetadataTypeDisplayMap, MetadataTypes } from '@src/types/metadata'

interface IProps {
    availableTypes: readonly MetadataTypes[]
}

export default class MetadataTileInput extends React.PureComponent<IProps & FieldRenderProps<MetadataTypes>> {
    private readonly handleSelectMetadataType = (type: MetadataTypes) => {
        this.props.input.onChange(type)
    }

    public render() {
        return (
            <>
                {this.props.meta.error && this.props.meta.touched && <FormText color="danger" className="mb-2">{this.props.meta.error}</FormText>}
                <Row className="px-2 mx-2 mt-2">
                    {this.props.availableTypes.map((x, idx) => (
                        <Col className="mb-3 px-2" xs={3} key={x}>
                            <Action id={`metadata-tile-${idx}`} data={x} tag={Button} active={this.props.input.value === x} block outline color="primary" className="h-100 d-flex flex-column align-items-center" style={{ height: '65px' }} onClick={this.handleSelectMetadataType}>
                                <div><FA icon={MetadataTypeDisplayMap[x].icon} /></div>
                                <small className="d-block" style={{ lineHeight: 1.2, whiteSpace: 'pre-line' }}>{MetadataTypeDisplayMap[x].label}</small>
                            </Action>
                            <UncontrolledTooltip target={`metadata-tile-${idx}`}>{MetadataTypeDisplayMap[x].tooltip}</UncontrolledTooltip>
                        </Col>
                    ))}
                </Row>
            </>
        )
    }
}
