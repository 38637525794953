import React from 'react'

import cx from 'classnames'

import Checkbox from '@src/components/common/CheckboxRadio'
import TriToggle from '@src/components/common/TriToggleButton'
import { AclEntry } from '@src/types/access'

interface IProps {
    principal: AclEntry
    validOperations: string[]
    onDeny: (principalId: string, ...operations: string[]) => void
    onRevokeOrUndeny: (principalId: string, ...operations: string[]) => void
    onGrant: (principalId: string, ...operations: string[]) => void
    onToggleAdmin?: (principalId: string) => void
    disabled?: boolean
}

function getOperationState(principal: AclEntry, operation: string): boolean {
    if (principal.denials.includes(operation)) {
        return false
    }

    if (principal.grants.includes(operation)) {
        return true
    }

    return null
}

export default function AccessControlTableRow({ principal, validOperations, onDeny, onGrant, onRevokeOrUndeny, onToggleAdmin, disabled }: IProps) {
    const handleGrantAll = React.useCallback(
        () => onGrant(principal.id, ...validOperations),
        [onGrant, principal.id, validOperations]
    )
    const handleDenyAll = React.useCallback(
        () => onDeny(principal.id, ...validOperations),
        [onDeny, principal.id, validOperations]
    )
    const handleRevokeOrUndenyAll = React.useCallback(
        () => onRevokeOrUndeny(principal.id, ...validOperations),
        [onRevokeOrUndeny, principal.id, validOperations]
    )
    const handleToggleAdmin = React.useCallback(
        () => onToggleAdmin(principal.id),
        [onToggleAdmin, principal.id]
    )
    const handleToggleDeny = React.useCallback(
        (operation: string) => onDeny(principal.id, operation),
        [onDeny, principal.id]
    )
    const handleToggleNeutral = React.useCallback(
        (operation: string) => onRevokeOrUndeny(principal.id, operation),
        [onRevokeOrUndeny, principal.id]
    )
    const handleToggleGrant = React.useCallback(
        (operation: string) => onGrant(principal.id, operation),
        [onGrant, principal.id]
    )

    const applyAllState = principal.grants.length === validOperations.length
        ? true
        : principal.denials.length === validOperations.length
            ? false
            : null

    const principalName = principal.type === 'user'
        ? <>{principal.name} <small className="text-muted">({principal.email || 'no email'})</small></>
        : principal.name

    const adminCheckbox = principal.type === 'company'
        ? null
        : (
            <div>
                <Checkbox labelClass={disabled && 'text-dark'} onClick={handleToggleAdmin} checked={principal.isAdministrator} label="Admin" disabled={disabled} />
            </div>
        )

    return (
        <tr className={cx({ 'table-secondary': principal.isAdministrator })}>
            <th scope="row">{principalName}{adminCheckbox}</th>
            <td className="permission-table__apply-all">
                <TriToggle idPrefix="apply-all" aria-label="Apply all" disabled={principal.isAdministrator || disabled} state={applyAllState} onToggleOff={handleDenyAll} onToggleNeutral={handleRevokeOrUndenyAll} onToggleOn={handleGrantAll} />
            </td>
            {validOperations.map((op) => {
                return (
                    <td key={op}>
                        <TriToggle idPrefix={op} aria-label={`Toggle ${op}`} name={op} disabled={principal.isAdministrator || disabled} state={getOperationState(principal, op)} onToggleOff={handleToggleDeny} onToggleOn={handleToggleGrant} onToggleNeutral={handleToggleNeutral} />
                    </td>
                )
            })}
        </tr>
    )
}
