import React from 'react'

import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import { localShortDate } from '@src/logic/utils/Date'
import { TransmittalEntityProps, TransmittalPropertyDefinition } from '@src/types/metadata'
import { IRegisterColumn } from '@src/types/register'

export default function TransmittalPropertyField({ row, col }: IRegisterTableField<TransmittalPropertyDefinition & IRegisterColumn>) {
    const rawValue = row.cells[col.id] as string | null
    const formattedValue = React.useMemo(() => {
        switch (col.data.metadataDefinition.options.property) {
            case TransmittalEntityProps.ReferenceNo:
            case TransmittalEntityProps.ResponseType:
            case TransmittalEntityProps.Status:
            case TransmittalEntityProps.Subject:
                return <span className="register__inactive-text-num-span">{rawValue}</span>
            case TransmittalEntityProps.CreatedDate:
            case TransmittalEntityProps.ResponseDate:
                return <span className="register__inactive-date-span">{localShortDate(new Date(rawValue))}</span>
            default:
                return <span></span>
        }
    },
    [rawValue])
    return formattedValue
}

export function TransmittalPropertyFieldFooter() {
    return <span />
}
