import React from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedTextArea from '@src/components/common/ValidatedTextArea'
import { IModalProps } from '@src/hooks/useModal'
import { required } from '@src/logic/forms/validation'
import { RegisterCreate } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'

interface IProps {
    projectId: string
}

interface INewRegisterData {
    name: string
    description: string
}

export default function NewRegisterModal({ projectId, ...modalProps }: IProps & IModalProps) {
    const history = useHistory()

    async function handleCreateRegister(values: INewRegisterData) {
        const register = (await RegisterCreate(projectId, { name: values.name, description: values.description, tags: [] })).data
        history.push(Routes.projectRegister(projectId, register.id))
    }

    return (
        <Modal {...modalProps}>
            <Form<INewRegisterData> onSubmit={handleCreateRegister}>
                {({ handleSubmit }) =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>Create Register</ModalHeader>
                        <ModalBody>
                                <FormGroup>
                                    <Label for="newregister-name">Name</Label>
                                    <Field id="newregister-name" name="name" component={ValidatedInput} validate={required} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="newregister-description">Description</Label>
                                    <Field id="newregister-description" name="description" component={ValidatedTextArea} />
                                </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit}>Create</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
