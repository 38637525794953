import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { IModalProps } from '@src/hooks/useModal'

interface IProps extends IModalProps {
    message?: React.ReactChild
    header?: React.ReactChild
    confirmAction?: string
    rejectAction?: string
    onConfirm: () => void
    onReject?: () => void
    danger?: boolean
}

export default function ConfirmationModal({ children, message, header, confirmAction, danger, rejectAction, onConfirm, onReject, ...modalProps }: React.PropsWithChildren<IProps>) {
    return (
        <Modal {...modalProps}>
            {header && <ModalHeader toggle={modalProps.toggle}>{header}</ModalHeader>}
            <ModalBody>
                {message}
                {children}
            </ModalBody>
            <ModalFooter>
                {onReject && <Button color="default" onClick={onReject}>{rejectAction || 'Cancel'}</Button>}
                <Button color={danger && 'danger'} onClick={onConfirm}>{confirmAction || 'Confirm'}</Button>
            </ModalFooter>
        </Modal>
    )
}
