import { Action, handleActions } from 'redux-actions'

import * as Actions from '@src/actions/Actions'
import { CostsOverview } from '@src/types/costs'
import { UserBasic } from '@src/types/principal'
import { Project, ProjectsState } from '@src/types/project'

const initialState: ProjectsState = {
    active: null,
    activeContact: null,
    activeCostsOverview: null,
    favourites: null
}

export default handleActions<ProjectsState, any>(
    {
        [Actions.PROJECT_SET_ACTIVE]: (state, action: Action<Project>) => {
            return { ...(state.active?.id !== action.payload?.id ? initialState : state), favourites: state.favourites, active: action.payload }
        },
        [Actions.PROJECT_SET_ACTIVE_CONTACT]: (state, action: Action<UserBasic>) => {
            return { ...state, activeContact: action.payload }
        },
        [Actions.PROJECT_SET_ACTIVE_COSTSOVERVIEW]: (state, action: Action<CostsOverview>) => {
            return { ...state, activeCostsOverview: action.payload }
        },
        [Actions.PROJECT_SET_FAVOURITES]: (state, action: Action<Project[]>) => {
            return { ...state, favourites: action.payload }
        }
    },
    initialState
)
