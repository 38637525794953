import dayjs from 'dayjs'

export function yyyyMMdd(value: Date) {
    return dayjs(value).format('YYYY-MM-DD')
}

/**
 * Localized short date format - 08/16/2018
 */
export function localShortDate(value: Date) {
    return dayjs(value).format('L')
}

export function localLongDate(value: Date) {
    return dayjs(value).format('LL')
}

export function localDateTime(value: Date) {
    return dayjs(value).format('lll')
}

export function fromNow(value: Date) {
    return dayjs(value).fromNow()
}
