import React from 'react'

export default function Toolbar({ children }: React.PropsWithChildren<{}>) {
    return (
        <div className="toolbar" role="menubar">
            <div className="toolbar__nav d-flex flex-row">
                {children}
            </div>
        </div>
    )
}
