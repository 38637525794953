import { IconName, library } from '@fortawesome/fontawesome-svg-core'
import {
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faArrowLeft,
    faArrowsAlt,
    faArrowsAltV,
    faArrowsV,
    faBars,
    faBold,
    faCamera,
    faCode,
    faCommentAlt,
    faCompress,
    faEdit,
    faEraser,
    faExchange,
    faExchangeAlt,
    faExpand,
    faExternalLink,
    faExternalLinkAlt,
    faFile,
    faFilePdf,
    faFolder,
    faFont,
    faFontAwesomeLogoFull,
    faHeading,
    faImage,
    faIndent,
    faInfo,
    faItalic,
    faKeyboard,
    faLink,
    faListOl,
    faListUl,
    faMagic,
    faMinus,
    faMousePointer,
    faOutdent,
    faPaintBrush,
    faParagraph,
    faPlus,
    faPrint,
    faQuestion,
    faQuoteLeft,
    faRedo,
    faSave,
    faSearch,
    faShareAlt,
    faSlidersH,
    faSmile,
    faSquare,
    faStar,
    faStrikethrough,
    faSubscript,
    faSuperscript,
    faTable,
    faTag,
    faTextHeight,
    faTint,
    faTrash,
    faUnderline,
    faUndo,
    faUnlink,
    faUpload
} from '@systems-software/fontawesome-pro-light-svg-icons'

type FroalaEditorIcons = Extends<IconName,
    'align-center' |
    'align-justify' |
    'align-left' |
    'align-right' |
    'arrow-left' |
    'arrows-alt' |
    'arrows-alt-v' |
    'arrows-v' |
    'bars' |
    'bold' |
    'camera' |
    'code' |
    'comment-alt' |
    'compress' |
    'edit' |
    'eraser' |
    'exchange' |
    'exchange-alt' |
    'expand' |
    'external-link' |
    'external-link-alt' |
    'file' |
    'file-pdf' |
    'folder' |
    'font' |
    'font-awesome' |
    'font-awesome-flag' |
    'heading' |
    'image' |
    'indent' |
    'info' |
    'italic' |
    'keyboard' |
    'link' |
    'list-ol' |
    'list-ul' |
    'magic' |
    'minus' |
    'mouse-pointer' |
    'outdent' |
    'paint-brush' |
    'paragraph' |
    'plus' |
    'print' |
    'question' |
    'quote-left' |
    'redo' |
    'save' |
    'search' |
    'share-alt' |
    'sliders-h' |
    'smile' |
    'square' |
    'star' |
    'strikethrough' |
    'subscript' |
    'superscript' |
    'table' |
    'tag' |
    'text-height' |
    'tint' |
    'trash' |
    'underline' |
    'undo' |
    'unlink' |
    'upload'
>

export function buildFroalaIconLibrary() {
    library.add(faAlignCenter,
                faAlignJustify,
                faAlignLeft,
                faAlignRight,
                faArrowLeft,
                faArrowsAlt,
                faArrowsAltV,
                faArrowsV,
                faBars,
                faBold,
                faCamera,
                faCode,
                faCommentAlt,
                faCompress,
                faEdit,
                faEraser,
                faExchange,
                faExchangeAlt,
                faExpand,
                faExternalLink,
                faExternalLinkAlt,
                faFile,
                faFilePdf,
                faFolder,
                faFont,
                faFontAwesomeLogoFull,
                faHeading,
                faImage,
                faIndent,
                faInfo,
                faItalic,
                faKeyboard,
                faLink,
                faListOl,
                faListUl,
                faMagic,
                faMinus,
                faMousePointer,
                faOutdent,
                faPaintBrush,
                faParagraph,
                faPlus,
                faPrint,
                faQuestion,
                faQuoteLeft,
                faRedo,
                faSave,
                faSearch,
                faShareAlt,
                faSlidersH,
                faSmile,
                faSquare,
                faStar,
                faStrikethrough,
                faSubscript,
                faSuperscript,
                faTable,
                faTag,
                faTextHeight,
                faTint,
                faTrash,
                faUnderline,
                faUndo,
                faUnlink,
                faUpload)
}
