import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import TemplateToolbar from '@src/components/communication/TemplateToolbar'
import CompanyBanner from '@src/components/company/CompanyBanner'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { TemplateOperations } from '@src/logic/auth/operations'
import { TemplateAccessGet, TemplateAccessUpdate, TemplateGet } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import * as Routes from '@src/logic/routing/routes'
import AccessPage from '@src/pages/common/AccessPage'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import TemplateEditPage from '@src/pages/communication/TemplateEditPage'
import { Api } from '@src/types/api'
import { Template } from '@src/types/communication'
import { ClientErrorCode } from '@src/types/http'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IProps {
    project?: Project
}

interface IConnectedState {
    company: Company
}

interface IState {
    template: Template
    templateLoadError: ClientErrorCode
}

class TemplatePages extends React.PureComponent<IProps & IConnectedState & RouteComponentProps<Partial<Routes.ICompanyTemplateParams>>, IState> {
    constructor(props) {
        super(props)

        this.state = {
            template: null,
            templateLoadError: null
        }
    }

    public componentDidMount() {
        this.loadTemplate()
    }

    private readonly loadTemplate = async (template?: Template) => {
        if (template) {
            this.setState({ template })
            return
        }

        try {
            const response = await TemplateGet(this.props.match.params.templateId)
            this.setState({ template: response.data, templateLoadError: null })
        } catch (err) {
            if (isAxiosError(err)) {
                if (!err.response) {
                    throw err
                }

                switch (err.response.status) {
                    case 403:
                        this.setState({ templateLoadError: ClientErrorCode.Forbidden })
                    case 404:
                        this.setState({ templateLoadError: ClientErrorCode.NotFound })
                    default:
                        throw err
                }
            }
        }
    }

    private readonly getBannerTitle = () => {
        if (this.state.templateLoadError) {
            switch (this.state.templateLoadError) {
                case ClientErrorCode.Forbidden:
                    return 'Permission Issue'
                case ClientErrorCode.NotFound:
                    return 'Template Not Found'
                default:
                    return ''
            }
        }

        return this.state.template ? this.state.template.name : <>Loading Template <FA icon="spinner-third" spin /></>
    }

    private readonly getTemplateAcl = async (template: Template) => {
        return (await TemplateAccessGet(template.id)).data
    }

    private readonly saveTemplateAccessUpdates = async (template: Template, accessUpdate: Api.Request.AccessControlListUpdate) => {
        try {
            await TemplateAccessUpdate(template.id, accessUpdate)
            await this.loadTemplate()
        } catch {

        }
    }

    public render() {
        const { company, project, ...routeProps } = this.props
        const { template, templateLoadError } = this.state
        return (
            <CrumbRoute
                path=""
                linkPath={template ? Routes.companyTemplate(template.id) : routeProps.match.url}
                title={template?.name ?? ''}
            >
                <div>
                    {project
? (
                        <ProjectBanner
                            project={project}
                            overrideTitle={this.getBannerTitle()}
                        />
                    )
: (
                        <CompanyBanner
                            company={company}
                            overrideTitle={this.getBannerTitle()}
                        />
                    )}
                    {template && <TemplateToolbar template={template} />}
                    <div className="mb-3" />
                    <ClientErrorPage clientErrorCode={templateLoadError} resourceName="communication template" requiredOperation={TemplateOperations.Read}>
                        {template &&
                        <Switch>
                            <Route path={project ? Routes.PROJECT_TEMPLATE : Routes.COMPANY_TEMPLATE} exact render={routeProps => <TemplateEditPage {...routeProps} template={template} reloadTemplate={this.loadTemplate} />} />
                            <CrumbRoute
                                path={project ? Routes.PROJECT_TEMPLATE_ACCESS : Routes.COMPANY_TEMPLATE_ACCESS}
                                title="Access"
                                render={() =>
                                    <AccessPage
                                        name="Communication Template"
                                        entity={template}
                                        company={company}
                                        getEntityAcl={this.getTemplateAcl}
                                        saveAccessUpdate={this.saveTemplateAccessUpdates}
                                    />
                                }
                            />
                            <Redirect to={project ? Routes.projectTemplate(project.id, template.id) : Routes.companyTemplate(template.id)} />
                        </Switch>}
                    </ClientErrorPage>
                </div>
            </CrumbRoute>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        company: state.session.company
    }
}

export default connect(mapStateToProps)(TemplatePages)
