import React from 'react'
import ReactDOM from 'react-dom'
import { useResizeDetector } from 'react-resize-detector'

import cx from 'classnames'
import { DropkiqUI } from 'dropkiq-ui'

import styled from 'styled-components'

import FroalaEditor from '@src/components/common/FroalaEditor'
import { DropkiqContext } from '@src/logic/autotexts/DropkiqContext'
import { CommunicationSchema } from '@src/logic/autotexts/DropkiqSchema'

// import '@src/logic/froala/google-fonts/plugin'

interface IOverlayProps {
    show: boolean
    editor?: any
    error?: boolean
}

const OverlayDiv = styled.div<{ error: boolean }>(props => ({
    position: 'absolute',
    padding: '20px 75px 20px 75px',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: props.error ? 'rgba(255,0,0,0.5)' : 'rgba(0,0,0,0.5)',
    zIndex: 2,
    color: '#fff'
}))

const OverlayMessage: React.FC<IOverlayProps> = ({ editor, error, show, children }) => {
    return editor
        ? ReactDOM.createPortal(
            <OverlayDiv
                error={error}
                className={cx('mx-0', { 'd-none': !editor.edit.isDisabled() || !show })}
            >
                {children}
            </OverlayDiv>,
            editor.$box[0]
        )
        : null
}

export interface IOverlayMessageRenderProps {
    navigateToLineCol: ([number, col]: [number, number?]) => void
}

interface IProps {
    value: string
    preview: string
    isEditing: boolean
    context?: DropkiqContext
    onChange: (value: string) => void
    onRefreshPreview: () => void
    onToggleEditing: (editing: boolean) => void
    renderOverlayMessage: (props: IOverlayMessageRenderProps) => React.ReactNode
}

const staticConfig = {
    htmlUntouched: true,
    entities: '&lsquo;&rsquo;',
    htmlRemoveTags: ['script'],
    htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', 'span', 'p', 'path', 'line'],
    htmlAllowedTags: ['.*'],
    htmlAllowedAttrs: ['.*'],
    iframe: true,
    iframeStyle: '@media only screen and (min-width: 722px) { html{text-align: left;padding: 30px;background:#EFEFEF;margin: auto;}body{text-align: left;background: rgb(255, 255, 255) none repeat scroll 0% 0% !important;margin: 0 auto !important;min-height: 26cm !important;padding: 2cm 2cm;box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.16) 0px 1px 1px 1px;overflow: visible;z-index: auto !important;max-width:21cm;} }',
    toolbarSticky: false,
    editorClass: 'fr-forceselectable',
    charCounterCount: false,
    placeholderText: ''
    // googleFontsEnabled: true,
    // fontFamily: localFonts
}

interface IState {
    restoreCodeView: boolean
    codeViewSelection?: CodeMirror.Position
}

type Actions = {
    type: 'saveCodeView'
    payload: CodeMirror.Position
} | {
    type: 'clearCodeView'
} | {
    type: 'updateScope'
    payload: object
} | {
    type: 'setLoadingPreview'
    payload: boolean
} | {
    type: 'previewLoaded'
    payload: string
} | {
    type: 'previewLoadedWithError'
    payload: React.ReactNode
}

const reducer: React.Reducer<IState, Actions> = (state, action) => {
    switch (action.type) {
        case 'saveCodeView':
            return { ...state, codeViewSelection: action.payload, restoreCodeView: true }
        case 'clearCodeView':
            return { ...state, codeViewSelection: undefined, restoreCodeView: false }
        case 'updateScope':
            return { ...state, scope: action.payload }
        case 'setLoadingPreview':
            return { ...state, loadingPreview: action.payload }
        case 'previewLoaded':
            return { ...state, preview: action.payload, loadingPreview: false, renderErrorMessage: undefined }
        case 'previewLoadedWithError':
            return { ...state, loadingPreview: false, renderErrorMessage: action.payload }
        default:
            throw new Error()
    }
}

const initialState: IState = {
    restoreCodeView: false,
    codeViewSelection: undefined
}

const DocumentEditor: React.FC<IProps> = ({ context, isEditing, onChange, onRefreshPreview, onToggleEditing, preview, renderOverlayMessage, value }) => {
    const { width, ref } = useResizeDetector({ handleHeight: false })
    const editorRef = React.useRef<any>()
    const codeMirrorRef = React.useRef<CodeMirror.Editor>()
    const dropkiqRef = React.useRef<DropkiqUI>()
    const codeMirrorDropkiqRef = React.useRef<DropkiqUI>()
    const previewAbortController = React.useRef(new AbortController())
    const [state, dispatch] = React.useReducer(reducer, initialState)

    const config = React.useMemo(
        () => ({
            ...staticConfig,
            events: {
                'froalaEditor.initialized': (e, editor) => {
                    editorRef.current = editor
                    initializeEditorDropkiq()

                    if (!isEditing) editOff()

                    editor.events.on('keydown', keyEvent => {
                        if (keyEvent.which === 13 && dropkiqRef.current?.menuIsOpen()) {
                            return false
                        }
                    }, true)
                },
                'froalaEditor.commands.after': (e, editor, cmd) => {
                    dropkiqRef.current?.closeMenu()
                    codeMirrorDropkiqRef.current?.closeMenu()

                    if (cmd === 'html') {
                        if (!codeMirrorDropkiqRef.current) {
                            initializeCodeMirrorDropkiq()
                        }
                    }
                }
            }
        }),
        [editorRef, dropkiqRef]
    )

    // On mount/unmount
    React.useEffect(
        () => {
            updatePreview()
            return unmount
        },
        []
    )

    function unmount() {
        previewAbortController.current.abort()
    }

    // Update dropkiq scope whenever our scope changes
    React.useEffect(
        () => {
            initializeEditorDropkiq()
        },
        []
    )

    // Reload Google Fonts whenever preview changes
    // React.useEffect(
    //     () => {
    //         editorRef.current?.googleFonts.loadUsedGoogleFonts()
    //     },
    //     [preview]
    // )

    React.useEffect(
        () => {
            isEditing ? editOn() : editOff()
            updatePreview()
        },
        [isEditing]
    )

    function getCodeMirror() {
        if (codeMirrorRef.current) return codeMirrorRef.current
        codeMirrorRef.current = editorRef.current.$box.find('.CodeMirror')[0].CodeMirror
        return codeMirrorRef.current
    }

    function navigateToLineCol([line, col]: [number, number?]) {
        dispatch({ type: 'saveCodeView', payload: { line: line - 1, ch: col ?? 0 } })
        onToggleEditing(true)
    }

    function updatePreview(): void {
        if (isEditing) return
        onRefreshPreview()
    }

    function initializeEditorDropkiq() {
        if (editorRef.current == null) return
        const iframe = editorRef.current.$box.find('.fr-iframe')[0]
        dropkiqRef.current = new DropkiqUI(editorRef.current.el, CommunicationSchema, context, {}, process.env.DROPKIQ_LICENSE, { iframe })
        dropkiqRef.current.showPreviews = () => false
    }

    function initializeCodeMirrorDropkiq() {
        const codemirror = getCodeMirror()
        codeMirrorDropkiqRef.current = new DropkiqUI(codemirror, CommunicationSchema, context, {}, process.env.DROPKIQ_LICENSE)
    }

    function editOn() {
        if (editorRef.current == null) return
        const editor = editorRef.current
        editor.edit.on()
        editor.toolbar.show()

        if (state.restoreCodeView) {
            if (!editor.codeView.isActive()) {
                editor.codeView.toggle()
            }

            getCodeMirror().setCursor(state.codeViewSelection)
            dispatch({ type: 'clearCodeView' })
        }
    }

    function editOff() {
        if (editorRef.current == null) return
        const editor = editorRef.current
        editor.selection.save()
        if (editor.codeView.isActive()) {
            codeMirrorDropkiqRef.current?.closeMenu()
            dispatch({
                type: 'saveCodeView',
                payload: getCodeMirror().getCursor()
            })
            editor.codeView.toggle()
        }
        dropkiqRef.current?.closeMenu()
        editor.edit.off()
        editor.toolbar.hide()
    }

    function handleModelChange(value: string) {
        if (isEditing) onChange(value)
    }

    const overlayMessage = renderOverlayMessage({ navigateToLineCol: navigateToLineCol })

    return (
        <div ref={ref} className={cx({ 'fr-x-doc': width >= 722, 'show-overlay': overlayMessage != null, 'show-overlay__error': true })}>
            <FroalaEditor
                model={isEditing ? value : (preview ?? '')}
                onModelChange={handleModelChange}
                config={config}
            />
            <OverlayMessage show={overlayMessage != null} editor={editorRef.current}>
                {overlayMessage ?? 'Loading preview...'}
            </OverlayMessage>
        </div>
    )
}

export default DocumentEditor
