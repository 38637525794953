// tslint:disable:max-classes-per-file
import React from 'react'
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { Table } from 'reactstrap'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'
import Sort from '@src/components/common/Sort'

import variables from '@public/styles/custom/global/_variables.scss'

// Untyped due to current typings not having onBeforeDragStart
const UntypedDragDropContext: any = DragDropContext

interface IBaseTableHeader<T> {
    name: string
    sortable?: boolean
    sortKey?: string
    headerWrapperClass?: string
    footer?: (data: T[]) => React.ReactChild
}

interface IBasePropertyHeader<T> extends IBaseTableHeader<T> {
    accessor: keyof T
    defaultFallback?: React.ReactChild
}

interface ICustomPropertyHeader<T> extends IBaseTableHeader<T> {
    overrideRenderer: (rowData: T, rowIndex: number) => React.ReactChild
}

export type ITableHeader<T> = IBasePropertyHeader<T> | ICustomPropertyHeader<T>

function isCustomPropertyHeader<T>(header: ITableHeader<T>): header is ICustomPropertyHeader<T> {
    return (header as ICustomPropertyHeader<T>).overrideRenderer != null
}


interface IProps<T, K extends keyof T> {
    headers: ITableHeader<T>[]
    data: T[]
    currentSort?: string
    onSort?: (key: string) => void
    onSelect?: (...rows: T[]) => void
    disableSelectAll?: boolean
    selectBy?: {
        key: K
        selected: T[K][]
    }
    onReorder?: (row: T, result: DropResult) => Promise<void>
    getRowId?: (row: T) => string
    className?: string
}

interface IState {
    isDragging: boolean
    draggingDisabled: boolean
}

interface IDataCellProps<T> {
    header: ITableHeader<T>
    data: T
    rowIndex: number
    isDragging?: boolean
}

interface IHeaderCellProps<T> extends Omit<React.HTMLProps<HTMLTableDataCellElement>, keyof { data, onClick }> {
    className?: string
    data: T
    tag?: React.ReactType
    sortField?: string
    onClick?: (e, data: T) => void
    onSort?: (sortField: string) => void
    currentSort?: string
}

interface ICellState {
    revealSort: boolean
}

type Snapshot = { width: number, height: number } | null

class DataCell<T> extends React.PureComponent<IDataCellProps<T>> {
    private readonly ref?: React.RefObject<HTMLTableCellElement>
    constructor(props) {
        super(props)
        this.ref = React.createRef<HTMLTableCellElement>()
    }

    public getSnapshotBeforeUpdate(prevProps: IDataCellProps<T>): Snapshot {
        if (!this.ref) {
            return null
        }

        const isDragStarting: boolean = this.props.isDragging && !prevProps.isDragging

        if (!isDragStarting) {
            return null
        }

        const { width, height } = this.ref.current.getBoundingClientRect()

        const snapshot = {
            width,
            height
        }

        return snapshot
    }

    public componentDidUpdate(prevProps: IDataCellProps<T>, prevState: {}, snapshot: Snapshot) {
        const ref = this.ref
        if (!ref) {
            return
        }

        if (snapshot) {
            const width = ref.current.style.width != null ? parseInt(ref.current.style.width) : NaN
            if (width === snapshot.width) {
                return
            }
            ref.current.style.width = `${snapshot.width}px`
            ref.current.style.height = `${snapshot.height}px`
            return
        }

        if (this.props.isDragging) {
            return
        }

        // inline styles not applied
        if (ref.current.style.width == null) {
            return
        }

        // no snapshot and drag is finished - clear the inline styles
        ref.current.style.removeProperty('height')
        ref.current.style.removeProperty('width')
    }

    private readonly renderValue = () => {
        const { header, data, rowIndex } = this.props
        if (isCustomPropertyHeader(header)) {
            return header.overrideRenderer(data, rowIndex)
        }

        return data[header.accessor] == null ? header.defaultFallback : data[header.accessor]
    }

    public render() {
        const { children, data, header, isDragging, rowIndex, ...rest } = this.props
        return (
            <td {...rest} ref={this.ref}>{this.renderValue()}</td>
        )
    }
}

class HeaderCell<T> extends React.PureComponent<IHeaderCellProps<T>, ICellState> {
    constructor(props) {
        super(props)

        this.state = {
            revealSort: false
        }
    }

    private readonly handleClick = (e) => {
        const { onClick, onSort, sortField } = this.props
        if (onSort) {
            onSort(sortField)
        }
        if (onClick) {
            onClick(e, this.props.data)
        }
    }

    private readonly handleMouseEnter = (e) => {
        const { onMouseEnter, onSort } = this.props
        if (onSort) {
            this.setState({ revealSort: true })
        }
        if (onMouseEnter) {
            onMouseEnter(e)
        }
    }

    private readonly handleMouseLeave = (e) => {
        const { onMouseLeave, onSort } = this.props
        if (onSort) {
            this.setState({ revealSort: false })
        }
        if (onMouseLeave) {
            onMouseLeave(e)
        }
    }

    public render() {
        const { className, currentSort, onClick, onSort, sortField, tag, ...rest } = this.props

        const Tag = tag || 'th'

        return (
            <Tag {...rest} className={cx(className, { pointer: onSort })} onClick={this.handleClick} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                {this.props.children}&nbsp;
                <Sort reveal={this.state.revealSort} field={sortField} currentSort={currentSort} />
            </Tag>
        )
    }
}

class SelectableCell<T> extends React.PureComponent<IHeaderCellProps<T> & { selected: boolean }> {
    private readonly handleClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e, this.props.data)
        }
    }

    public render() {
        const { onClick, tag, selected, ...rest } = this.props

        const Tag = tag || 'td'

        return (
            <Tag {...rest} onClick={this.handleClick}>
                <div className="selectable-content__checkbox h-auto" onClick={this.handleClick} onKeyPress={this.handleClick} role={onClick ? 'button' : undefined}>
                    <input checked={selected} type="checkbox" />
                    <label />
                </div>
            </Tag>
        )
    }
}

export default class GenericTable<T, K extends keyof T> extends React.PureComponent<IProps<T, K>, IState> {
    constructor(props) {
        super(props)

        this.state = {
            isDragging: false,
            draggingDisabled: false
        }
    }

    private readonly allSelected = () => {
        return this.props.selectBy ? this.props.data.every(r => this.props.selectBy.selected.includes(r[this.props.selectBy.key])) : false
    }

    private readonly isSelected = (row: T) => {
        return this.props.selectBy ? this.props.selectBy.selected.includes(row[this.props.selectBy.key]) : false
    }

    private readonly handleSelectAll = () => {
        return this.props.onSelect(...this.props.data)
    }

    private readonly onBeforeDragStart = () => {
        this.setState({ isDragging: true })
    }

    private readonly onDragEnd = (result: DropResult) => {
        this.setState({ isDragging: false })
        this.setState({ draggingDisabled: true })

        this.props.onReorder(this.props.data[result.source.index], result)
            .then(() => this.setState({ draggingDisabled: false }))
            .catch(() => this.setState({ draggingDisabled: false }))
    }

    private readonly renderHeader = (canReorder: boolean) => {
        const selectAll = this.props.onSelect != null ? (
            !this.props.disableSelectAll ? <SelectableCell tag="th" selected={this.allSelected()} onClick={this.props.onSelect} data={null} /> : <th />
        ) : null

        return (
            <tr>
                {canReorder && <HeaderCell data={null} style={{ width: '20px' }} />}
                {selectAll}
                {this.props.headers.map((header, idx) => (
                    <HeaderCell
                        key={idx}
                        className={header.headerWrapperClass}
                        data={header.sortKey}
                        onSort={header.sortable && this.props.onSort}
                        sortField={header.sortKey}
                        currentSort={this.props.currentSort}
                    >
                        {header.name}
                    </HeaderCell>
                ))}
            </tr>
        )
    }

    private readonly renderRows = (canReorder: boolean) => {
        const { onSelect, headers, data } = this.props
        if (canReorder) {
            return this.props.data.map((rowData, idx) => (
                <Draggable
                    draggableId={this.props.getRowId(rowData)}
                    isDragDisabled={this.state.draggingDisabled}
                    index={idx}
                    key={idx}
                >
                    {(
                        provided: DraggableProvided,
                        snapshot: DraggableStateSnapshot
                    ) => (
                        <tr
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={{
                                ...(snapshot.isDragging ? { backgroundColor: variables.tableHoverBg } : {}), ...provided.draggableProps.style
                            }}
                        >
                            <DataCell {...provided.dragHandleProps} header={{ name: '', overrideRenderer: () => <FA icon="ellipsis-v" /> }} data={null} rowIndex={idx} isDragging={this.state.isDragging}  />
                            {onSelect && <SelectableCell selected={this.isSelected(rowData)} onClick={onSelect} data={rowData} />}
                            {headers.map((header, cellIdx) => (
                                <DataCell
                                    key={cellIdx}
                                    header={header}
                                    rowIndex={idx}
                                    data={rowData}
                                    isDragging={this.state.isDragging}
                                />
                            ))}
                        </tr>
                    )}
                </Draggable>
            ))
        }
        return data.map((rowData, idx) => (
            <tr key={idx}>
                {onSelect && <SelectableCell selected={this.isSelected(rowData)} onClick={onSelect} data={rowData} />}
                {headers.map((header, headerIdx) => (
                    <DataCell key={headerIdx} header={header} data={rowData} rowIndex={idx} />
                ))}
            </tr>
        ))
    }

    private readonly renderFooter = (canReorder: boolean) => {
        if (!this.props.headers.some(x => x.footer != null)) return null

        const selectCell = this.props.onSelect != null ? <td/> : null

        return (
            <tfoot>
                <tr>
                    {canReorder && <HeaderCell data={null} style={{ width: '20px' }} />}
                    {selectCell}
                    {this.props.headers.map((h, idx) => h.footer ? <td key={`footer-${idx}`}>{h.footer(this.props.data)}</td> : <td key={`footer-${idx}`}/>)}
                </tr>
            </tfoot>
        )
    }

    private wrapDragDropContext(body) {
        return (
            <UntypedDragDropContext onBeforeDragStart={this.onBeforeDragStart} onDragEnd={this.onDragEnd}>
                {body}
            </UntypedDragDropContext>
        )
    }

    public render() {
        const canReorder = this.props.onReorder != null
        const header = this.renderHeader(canReorder)
        const rows = this.renderRows(canReorder)
        const footer = this.renderFooter(canReorder)
        const body = canReorder ? (
            <Droppable droppableId="table">
                {(droppableProvided: DroppableProvided) => (
                  <tbody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {rows}
                    {droppableProvided.placeholder}
                  </tbody>
                )}
            </Droppable>
        ) : <tbody>{rows}</tbody>
        const table = (
            <Table responsive hover className={this.props.className}>
                <thead>
                    {header}
                </thead>
                {body}
                {footer}
            </Table>
        )

        if (canReorder) return this.wrapDragDropContext(table)

        return table
    }
}
