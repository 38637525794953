import React from 'react'
import { Button } from 'reactstrap'

import { auth } from '@src/logic/auth/AuthService'

import InfopointLogo from 'public/img/infopoint_colour.svg'

export default class  extends React.PureComponent {

    private handleLogout = () => {
        auth.logout()
    }

    public render() {
        return (
            <section className="h-100 d-flex flex-column align-items-center justify-content-center">
                <div className="mb-3">
                    <img width="200" src={InfopointLogo} />
                </div>
                <div className="mb-2">
                    <h1>Yikes... Something doesn't look right</h1>
                </div>
                <div className="mb-2">
                    <span className="lead">There was an issue while loading your workspace. Try and log out and log back in. If you see this screen again, please contact support.</span>
                </div>
                <div>
                    <Button color="primary" onClick={this.handleLogout}>Logout</Button>
                </div>
            </section>
        )
    }
}
