import React from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { compare } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalProps } from '@src/hooks/useModal'
import { required } from '@src/logic/forms/validation'
import { BudgetPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { BudgetOverview, BudgetReportColumn, BudgetReportColumnName, BudgetStatusDefinition } from '@src/types/costs'

interface IProps extends IModalProps {
    projectId: string
    statusToEdit?: BudgetStatusDefinition
    statusType: 'item' | 'adjustment'
    budget: BudgetOverview
}

export interface IBudgetStatusFormData {
    code: string
    columns: { label: string, value: BudgetReportColumn }[]
}

const reportColumnOptions = Object.keys(BudgetReportColumnName).map<{ label: string, value: string }>(k => ({ label: BudgetReportColumnName[k], value: k }))

export default function BudgetStatusModal({ projectId, statusToEdit, statusType, budget, ...modalProps }: IProps) {
    const dispatch = useDispatch()

    async function createOrUpdateBudgetStatus(values: IBudgetStatusFormData) {
        const patchRootPath = statusType === 'item' ? '/itemStatusDefinitions' : '/adjustmentStatusDefinitions'
        if (statusToEdit) {
            const index = statusType === 'item'
                ? budget.itemStatusDefinitions.findIndex(s => s.code === statusToEdit.code)
                : budget.adjustmentStatusDefinitions.findIndex(s => s.code === statusToEdit.code)
            if (index < 0) throw Error('Status to edit not found in budget')
            const original: BudgetStatusDefinition = {
                code: statusToEdit.code,
                columns: statusToEdit.columns
            }
            const update: BudgetStatusDefinition = {
                code: statusToEdit.code,
                columns: values.columns.map(c => c.value)
            }
            const patch = compare(original, update).map(op => ({ ...op, path: `${patchRootPath}/${index}${op.path}` }))
            await BudgetPatch(projectId, [
                { op: 'test', path: `${patchRootPath}/${index}/code`, value: statusToEdit.code },
                ...patch
            ])

            NotificationService.info('Updated status')
        } else {
            const newStatusDefinition: BudgetStatusDefinition = {
                code: values.code,
                columns: values.columns.map(c => c.value)
            }
            await BudgetPatch(projectId, [
                { op: 'add', path: patchRootPath + '/-', value: newStatusDefinition }
            ])

            NotificationService.info('Created status')
        }

        dispatch(loadProjectCostsOverview())
        modalProps.toggle()
    }

    return (
        <Form onSubmit={createOrUpdateBudgetStatus}>
            {({ handleSubmit }) =>
                <Modal {...modalProps}>
                    <ModalHeader toggle={modalProps.toggle}>{statusToEdit ? `Edit - ${statusToEdit.code}` : 'New Status'}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="budgetStatus-code">Code</Label>
                            <Field id="budgetStatus-code" name="code" component={ValidatedInput} validate={required} disabled={statusToEdit != null} initialValue={statusToEdit?.code} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="budgetStatus-columns">Columns</Label>
                            <Field id="budgetStatus-columns" name="columns" component={ValidatedSelect} isMulti options={reportColumnOptions} initialValue={statusToEdit?.columns} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleSubmit}>{statusToEdit ? 'Save' : 'Create'}</Button>
                    </ModalFooter>
                </Modal>
            }
        </Form>
    )
}
