import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@src/types/models";
import { Company, UserInternal, UserStatus } from "@src/types/principal";
import {
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Tooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import Banner from "@src/components/banner/Banner";
import BannerPanel from "@src/components/banner/BannerPanel";
import FA from "@src/components/common/FontAwesomeIcon";
import { useNewRegistrationsStore } from "@src/store/admin";
import shallow from "zustand/shallow";
import { debounce } from "lodash";
import UserStatusIndicator from "@src/components/company/UserStatusIndicator";
import { safeHtmlId } from "@src/logic/utils/Strings";
import useModal from "@src/hooks/useModal";
import UpdateNewRegistrationForm from "./UpdateNewRegistrationForm";
import ConfirmContactForm from "./ConfirmContactForm";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";

const NewRegistrationsPage: React.FC = () => {
  const gridRef: any = useRef();
  const updateNewRegistrationModal = useModal(false);
  const confirmContactModal = useModal(false);
  const company = useSelector<RootState, Company>((s) => s.session.company);

  const [selectedNewRegistration, setSelectedNewRegistration] =
    useState<UserInternal>();

  const [newRegistrations, loadNewRegistrations, refreshNewRegistrations] =
    useNewRegistrationsStore(
      (s) => [s.newRegistrations, s.load, s.refresh],
      shallow
    );

  const triggerSearch = debounce((query: string) => {
    refreshNewRegistrations(query);
  }, 999);

  const searchNewRegistrations = (e: React.ChangeEvent<HTMLInputElement>) => {
    triggerSearch(e.currentTarget.value);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    loadNewRegistrations("");
  }, []);

  const doubleClick = (event) => {
    setSelectedNewRegistration(event.data);
    updateNewRegistrationModal.actions.show();
  };

  const DropdownCellRenderer = ({ value, data }) => {
    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle className="mr-0" color="link">
          <FA icon="ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu right className="shadow">
          <DropdownItem
            onClick={() => {
              setSelectedNewRegistration(data);
              confirmContactModal.actions.show();
            }}
          >
            Confirm Contact
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSelectedNewRegistration(data);
              updateNewRegistrationModal.actions.show();
            }}
          >
            Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const ConfirmClientButton = ({ value, data }) => {
    return (
      <Button
        color="primary"
        outline
        style={{ marginLeft: 5, borderColor: "lightgray" }}
        onClick={() => {
          setSelectedNewRegistration(data);
          confirmContactModal.actions.show();
        }}
      >
        Confirm Contact
      </Button>
    );
  };

  const objectIdToTimestamp = (objectId: string) => {
    objectId = objectId.substring(objectId.indexOf("|") + 1);
    const timestampHex = objectId.substring(0, 8);
    const timestamp = parseInt(timestampHex, 16);

    return dayjs(new Date(timestamp * 1000)).format("DD/MM/YYYY - hh:mm a");
  };

  const [colDefs, setColDefs] = useState([
    {
      cellRendererFramework: ({ value, data }) => (
        <>{objectIdToTimestamp(data.id)}</>
      ),
      headerName: "Date Created",
      field: "id",
      filter: false,
      flex: 1,
      sortable: true,
      sort: "desc" as any,
    },
    {
      cellRendererFramework: ({ value, data }) => (
        <>
          {data.firstName} {data.lastName}
        </>
      ),
      field: "name",
      filter: true,
      flex: 1,
      sortable: true,
    },
    {
      cellRendererFramework: ({ value, data }) => <>{data.company?.name}</>,
      field: "company.name",
      filter: true,
      flex: 1,
      sortable: true,
    },
    {
      cellRendererFramework: ({ value, data }) => <>{data.email}</>,
      field: "email",
      filter: true,
      flex: 1,
      sortable: true,
    },
    {
      cellRendererFramework: ({ value, data }) => <>{data.phone}</>,
      field: "phone",
      filter: true,
      flex: 1,
      sortable: true,
    },
    {
      cellRendererFramework: ({ value, data }) => {
        return (
          <Badge pill style={{ borderRadius: 20 }} color="light">
            <UserStatusIndicator
              id={safeHtmlId(data.id)}
              status={data.status}
            />
            <span
              style={{
                lineHeight: 1.5,
                verticalAlign: "middle",
              }}
            >
              {(data.status == UserStatus.Activated
                ? "Pending confirmation"
                : data.status
              ).toUpperCase()}
            </span>
          </Badge>
        );
      },
      field: "status",
      filter: true,
      flex: 1,
      sortable: true,
    },
    {
      field: "",
      filter: false,
      flex: 0,
      cellRendererFramework: ConfirmClientButton,
      cellStyle: {
        overflow: "visible",
      },
    },
    {
      headerName: "Action",
      field: "",
      filter: false,
      flex: 0,
      cellRendererFramework: DropdownCellRenderer,
      cellStyle: {
        overflow: "visible",
      },
      width: 100,
    },
  ]);

  const clearFilters = useCallback(() => {
    //@ts-ignore
    gridRef.current.api.setFilterModel(null);
  }, []);

  return (
    <Container fluid>
      <UpdateNewRegistrationForm
        modalProps={updateNewRegistrationModal.modalProps}
        user={selectedNewRegistration}
      />
      <ConfirmContactForm
        modalProps={confirmContactModal.modalProps}
        user={selectedNewRegistration}
      />
      <Row>
        <Col xs="12">
          <Banner title="Legacy Registrations" noBreadcrumbs>
            <BannerPanel column>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FA icon="search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="login-email"
                  type="email"
                  className="no-border-left"
                  placeholder="Search"
                  onChange={searchNewRegistrations}
                />
              </InputGroup>
            </BannerPanel>
            <BannerPanel>
              <Button
                id="clear-filter-btn"
                color="primary"
                outline
                style={{ marginLeft: 5, borderColor: "lightgray" }}
                onClick={clearFilters}
              >
                <FA icon="filter" />
              </Button>
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target={"clear-filter-btn"}
                toggle={toggle}
              >
                Clear Filters
              </Tooltip>
            </BannerPanel>
          </Banner>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              suppressRowTransform={true}
              suppressCellFocus={true}
              rowData={newRegistrations}
              columnDefs={colDefs}
              pagination={true}
              paginationPageSize={15}
              domLayout="autoHeight"
              onRowDoubleClicked={doubleClick}
              rowHeight={50}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewRegistrationsPage;
