import React from 'react'
import { Field } from 'react-final-form'

import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import GenericSelectDropdown from '@src/components/register/tablefields/GenericSelectDropdown'
import useTouched from '@src/hooks/useTouched'
import { CompanyLinksDefinition } from '@src/types/metadata'
import { CompanyLink } from '@src/types/principal'

function getCompanyLabel(companyLink: CompanyLink) {
    return companyLink.name
}

function getCompanyValue(companyLink: CompanyLink) {
    return companyLink.id
}

export default function CompanyLinkField(props: IRegisterTableField<CompanyLinksDefinition>) {
    const value = props.row[props.col.id] as CompanyLink[]
    const isTouched = useTouched(props.isFocused, [value])
    return isTouched
        ? (
            <Field name={`cells.r-${props.row.id}.c-${props.col.id}`} validateFields={[]}>
                {({ input }) =>
                    <GenericSelectDropdown
                        {...props}
                        onChange={input.onChange}
                        getOptionLabel={getCompanyLabel}
                        getOptionValue={getCompanyValue}
                        options={[]} />
                    }
            </Field>
        )
        : (
            <GenericSelectDropdown {...props} value={value} getOptionLabel={getCompanyLabel} getOptionValue={getCompanyValue} options={[]} />
        )
}

export function CompanyLinkFieldFooter(props: IRegisterTableField<CompanyLinksDefinition>) {
    return (
        <Field name={`cells.c-${props.col.id}`}>
            {({ input }) =>
                <GenericSelectDropdown
                    {...props}
                    onChange={input.onChange}
                    getOptionLabel={getCompanyLabel}
                    getOptionValue={getCompanyValue}
                    options={[]}
                />
            }
        </Field>
    )
}
