import React from 'react'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'

export interface IProps {
    id?: string
    label?: React.ReactChild
    labelClass?: string
    checked: boolean
    onClick?: () => void
    disabled?: boolean
}

export default class CheckboxRadio extends React.PureComponent<IProps> {
    private readonly handleClick = () => {
        if (!this.props.disabled && this.props.onClick) {
            this.props.onClick()
        }
    }

    public render() {
        return (
            <label className={cx('checkbox-radio', this.props.labelClass)} htmlFor={this.props.id}>
                <input id={this.props.id} className="checkbox-radio__control" type="checkbox" checked={!!this.props.checked} disabled={this.props.disabled} onClick={this.handleClick} readOnly />
                <span className="checkbox-radio__box-wrapper">
                    <span className="checkbox-radio__box" />
                    <span className="checkbox-radio__check">
                        <FA size="lg" icon="check" />
                    </span>
                </span>
                {this.props.label && <span className="checkbox-radio__label">{this.props.label}</span>}
            </label>
        )
    }
}
