import React from 'react'
import { Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'
import SearchAssistantModal, { ISearchProperty } from '@src/components/search/SearchAssistant'
import TypeaheadInput from '@src/components/search/TypeaheadInput'
import useModal from '@src/hooks/useModal'

interface IProps {
    value: string
    onChange: (newValue: string, triggerSearch?: boolean) => void
    onSubmit: () => void
    submitting?: boolean
    className?: string
    placeholder?: string
    searchAssistant?: boolean
    availableProperties?: ISearchProperty[]
}

const SearchBar: React.FC<IProps> = ({ className, onChange, searchAssistant, availableProperties, placeholder, onSubmit, submitting, value }) => {
    const searchAssistantRef = React.useRef<SearchAssistantModal>()
    const searchAssistantModal = useModal(false)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        onChange(e.target.value)
        if (searchAssistantRef.current) searchAssistantRef.current.reset()
    }

    function changeAndSearch(newValue: string) {
        onChange(newValue, true)
    }

    function checkEnterPressed(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            onSubmit()
        }
    }

    return (
        <InputGroup className={cx('action-bar__input', className)}>
            <InputGroupAddon addonType="prepend">
                {!searchAssistant && <InputGroupText><FA icon="search" /></InputGroupText>}
                {searchAssistant &&
                    <InputGroupText className="pointer" onClick={searchAssistantModal.actions.show}>
                        <SearchAssistantModal
                            {...searchAssistantModal.modalProps}
                            ref={searchAssistantRef}
                            onSearch={changeAndSearch}
                            availableProps={availableProperties}
                            unmountOnClose={false}
                        />
                        <FA icon="search" aria-label="Search Assistant Button"/>
                    </InputGroupText>}
            </InputGroupAddon>
            <TypeaheadInput onKeyPress={checkEnterPressed} onChange={handleChange} value={value} placeholder={placeholder} fields={availableProperties ? availableProperties.map(p => p.searchKey) : []} />
            <InputGroupAddon addonType="append">
                <Button color="primary" type="submit" onClick={onSubmit}>Search {submitting && <FA icon="spinner-third" spin />}</Button>
            </InputGroupAddon>
        </InputGroup>
    )
}

export default SearchBar
