// tslint:disable:jsx-no-lambda
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { Container } from 'reactstrap'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import DocumentToolbar from '@src/components/document/DocumentToolbar'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { RevisionOperations } from '@src/logic/auth/operations'
import { DocumentGet, RevisionGetById } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import * as Routes from '@src/logic/routing/routes'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import DownloadRevisionPage from '@src/pages/document/DownloadRevisionPage'
import DocumentAccessPage from '@src/pages/project/DocumentAccessPage'
import DocumentEditPage from '@src/pages/project/DocumentEditPage'
import DocumentPreviewPage from '@src/pages/project/DocumentPreviewPage'
import { IPDocument, Revision } from '@src/types/document'
import { ClientErrorCode } from '@src/types/http'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IConnectedState {
    project: Project
}

interface IState {
    projectId: string
    revision: Revision
    document: IPDocument
    metadataDefinitions: IMetadataDefinition[]
    documentLoadError: ClientErrorCode
}

class DocumentPages extends React.Component<RouteComponentProps<any> & IConnectedState, IState> {
    constructor(props) {
        super(props)

        this.state = {
            projectId: this.props.match.params.id,
            revision: undefined,
            document: undefined,
            metadataDefinitions: [],
            documentLoadError: null
        }
    }

    public componentDidMount() {
        this.loadDocumentOrRevision()
    }

    public componentDidUpdate(prevProps: RouteComponentProps<any> & IConnectedState) {
        const currentRevId = new URLSearchParams(this.props.location.search).get('rid')
        const previousRevId = new URLSearchParams(prevProps.location.search).get('rid')
        if (prevProps.match.params.docId !== this.props.match.params.docId || currentRevId !== previousRevId) {
            this.loadDocumentOrRevision()
        }
    }

    public loadDocumentOrRevision = async () => {
        const revId = new URLSearchParams(this.props.location.search).get('rid')
        const docId = this.props.match.params.docId
        if (revId) {
            try {
                const revisionResponse = await RevisionGetById(revId)
                const documentResponse = await DocumentGet(docId)
                this.setState({ revision: revisionResponse.data.revision, document: documentResponse.data.document, metadataDefinitions: revisionResponse.data.metadataDefinitions, documentLoadError: null })
            } catch (err) {
                if (isAxiosError(err)) {
                    if (!err.response) {
                        throw err
                    }
                    switch (err.response.status) {
                        case 403:
                            this.setState({ documentLoadError: ClientErrorCode.Forbidden })
                            break
                        case 404:
                            this.setState({ documentLoadError: ClientErrorCode.NotFound })
                            break
                        default:
                            throw err
                    }
                }
            }
        } else {
            const response = await DocumentGet(docId)
            this.setState({ document: response.data.document, revision: response.data.document.revisions[0], metadataDefinitions: response.data.metadataDefinitions, documentLoadError: null })
        }
    }

    private getBannerTitle = () => {
        const { documentLoadError, revision } = this.state
        if (documentLoadError) {
            switch (documentLoadError) {
                case ClientErrorCode.Forbidden:
                    return 'Permission Issue'
                case ClientErrorCode.NotFound:
                    return 'Document Not Found'
                default:
                    return ''
            }
        }

        return revision ? revision.name : <>Loading document <FA icon="spinner-third" spin /></>
    }

    public render() {
        const { project, ...routeProps } = this.props
        const { document, revision, metadataDefinitions, documentLoadError } = this.state

        return (
            <CrumbRoute path="" linkPath={revision ? Routes.projectDocument(revision.projectId, revision.documentId, revision.id) : this.props.match.url} title={revision?.name ?? ''}>
                <div>
                    <ProjectBanner project={project} overrideTitle={this.getBannerTitle()} />
                    <ClientErrorPage clientErrorCode={documentLoadError} resourceName="document" requiredOperation={RevisionOperations.Read} >
                    {revision &&
                        <>
                            <DocumentToolbar document={document} revision={revision} reloadDocument={this.loadDocumentOrRevision} {...routeProps} />
                            <Container className="my-3" fluid>
                                <Switch>
                                    <CrumbRoute path={Routes.PROJECT_DOCUMENT_ACCESS} title="Access" render={() => <DocumentAccessPage revision={revision} />}/>
                                    <CrumbRoute path={Routes.PROJECT_DOCUMENT_EDIT} title="Edit" render={() => <DocumentEditPage revision={revision} metadataDefinitions={metadataDefinitions} reloadDocument={this.loadDocumentOrRevision} />} />
                                    <Route path={Routes.PROJECT_DOCUMENT_DOWNLOAD} render={() => <DownloadRevisionPage revision={revision} />} />
                                    <Route render={() => <DocumentPreviewPage revision={revision} metadataDefinitions={metadataDefinitions} reloadDocument={this.loadDocumentOrRevision} />} />
                                </Switch>
                            </Container>
                        </>
                    }
                    </ClientErrorPage>
                </div>
            </CrumbRoute>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<any>): RouteComponentProps<any> & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active
    }
}

export default connect(mapStateToProps)(DocumentPages)
