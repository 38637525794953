import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import { AxiosResponse } from 'axios'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { TransmittalOperations } from '@src/logic/auth/operations'
import { TransmittalGetById } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import * as Routes from '@src/logic/routing/routes'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import TransmittalAccessPage from '@src/pages/transmittal/TransmittalAccessPage'
import TransmittalDownloadPage from '@src/pages/transmittal/TransmittalDownloadPage'
import TransmittalEditPage from '@src/pages/transmittal/TransmittalEditPage'
import TransmittalViewPage from '@src/pages/transmittal/TransmittalViewPage'
import { ClientErrorCode } from '@src/types/http'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Transmittal, TransmittalStatus } from '@src/types/transmittal'

function TransmittalPages() {
    const match = useRouteMatch<{ transmittalId?: string }>()
    const project = useSelector<RootState, Project>(s => s.projects.active)

    const transmittalAsync = useAsyncAbortable(
        (abortSignal, transmittalId) => isNewTransmittal(transmittalId) ? null : TransmittalGetById(transmittalId, { abortSignal }),
        [match.params.transmittalId]
    )

    function isNewTransmittal(transmittalId: string) {
        return isNullOrEmpty(transmittalId) || transmittalId === 'new'
    }

    function transmittalTitleOrFallback(t: Transmittal, fallback: any) {
        return t?.subject ?? fallback
    }

    const bannerTitle = React.useMemo(
        () => {
            if (isNewTransmittal(match.params.transmittalId)) return 'New Transmittal'

            if (transmittalAsync.error) {
                if (!isAxiosError(transmittalAsync.error)) return ''
                switch (transmittalAsync.error.response.status) {
                    case ClientErrorCode.Forbidden:
                        return 'Permission Issue'
                    case ClientErrorCode.NotFound:
                        return 'Transmittal Not Found'
                    default:
                        return ''
                }
            }

            return transmittalAsync.result ? transmittalTitleOrFallback(transmittalAsync.result.data, '(No Subject)') : <span>Loading Transmittal<FA icon="spinner-third" spin className="ml-1" /></span>
        },
        [match.params.transmittalId, transmittalAsync.error, transmittalAsync.result]
    )

    const newTransmittal = isNewTransmittal(match.params.transmittalId)
    const transmittal: Transmittal | undefined = transmittalAsync.result && transmittalAsync.result.data.id === match.params.transmittalId ? transmittalAsync.result.data : undefined
    function reloadTransmittal(updatedTransmittal?: Transmittal) {
        if (!updatedTransmittal) return transmittalAsync.execute(transmittal.id)

        const updatedResult: AxiosResponse<Transmittal> = {
            data: updatedTransmittal,
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {}
        }

        transmittalAsync.set({ error: null, loading: false, result: updatedResult, status: 'not-requested' })
    }

    const clientErrorCode: ClientErrorCode = transmittalAsync.error && isAxiosError(transmittalAsync.error) ? transmittalAsync.error.response.status : undefined

    return (
        <CrumbRoute
            path=""
            linkPath={transmittal ? Routes.projectTransmittal(project.id, transmittal.id) : match.url}
            title={newTransmittal ? 'New Transmittal' : transmittal ? transmittalTitleOrFallback(transmittal, '(No Subject)') : ''}
        >
            <div>
                <ProjectBanner
                    project={project}
                    overrideTitle={bannerTitle}
                />
                <ClientErrorPage clientErrorCode={clientErrorCode} resourceName="transmittal" requiredOperation={TransmittalOperations.Read}>
                    <Switch>
                        <Route path={[Routes.PROJECT_TRANSMITTAL_NEW, Routes.PROJECT_TRANSMITTAL_EDIT]} exact>
                            <TransmittalEditPage transmittal={transmittal} reloadTransmittal={reloadTransmittal} />
                        </Route>
                        {transmittal &&
                            <Route>
                                <Switch>
                                    <CrumbRoute path={Routes.PROJECT_TRANSMITTAL_ACCESS} title="Access">
                                        <TransmittalAccessPage transmittal={transmittal} reloadTransmittal={reloadTransmittal} />
                                    </CrumbRoute>
                                    <Route path={Routes.PROJECT_TRANSMITTAL_VIEW}>
                                        <TransmittalViewPage transmittal={transmittal} reloadTransmittal={reloadTransmittal} />
                                    </Route>
                                    <Route path={Routes.PROJECT_TRANSMITTAL_DOWNLOAD}>
                                        <TransmittalDownloadPage transmittal={transmittal} />
                                    </Route>
                                    <Redirect
                                        to={transmittal.status !== TransmittalStatus.Draft
                                            ? Routes.projectTransmittalView(transmittal.projectId, transmittal.id)
                                            : Routes.projectTransmittalEdit(transmittal.projectId, transmittal.id)}
                                    />
                                </Switch>
                            </Route>
                        }
                    </Switch>
                </ClientErrorPage>
            </div>
        </CrumbRoute>
    )
}

export default React.memo(TransmittalPages)
