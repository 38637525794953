import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { Card, Container } from 'reactstrap'

import cx from 'classnames'
import { History } from 'history'

import LoadingCard from '@src/components/common/LoadingCard'
import GenericSearchBarWithPaging, { IExtraElement } from '@src/components/search/GenericSearchBarWithPaging'
import { ISearchProperty } from '@src/components/search/SearchAssistant'
import GenericContentTable, { ITableHeader as IContentTableHeader } from '@src/components/table/GenericContentTable'
import GenericTable, { ITableHeader } from '@src/components/table/GenericTable'
import { ISearchParamMap, getSearchParamsFromQuery, pushURLSearchFilter } from '@src/logic/search/SearchStateHelpers'
import { enforceNumber } from '@src/logic/utils/Math'
import { invertOrChange } from '@src/logic/utils/SortHelper'

export interface ISearchResult<T> {
    items: T[]
    totalItems: number
}

interface ISearchParams {
    filter: string
    sort: string
    page: number
    perPage: number
}

interface IBaseSearchSectionProps<T, K extends keyof T> {
    search: string
    history: History
    regularTable?: boolean
    onSearch: (filter: string, sort: string, page: number, perPage: number, abortSignal?: AbortSignal) => Promise<ISearchResult<T>>
    onSearchError?: (searchParams: ISearchParams, prevResult?: ISearchResult<T>) => ISearchResult<T>
    extraSearchBarElements?: IExtraElement[]
    defaultPerPage?: number
    noItemsFoundMessage?: string | JSX.Element
    itemIdKey?: K
    selectedItems?: T[K][]
    searchAssistantProperties?: ISearchProperty[]
    onItemsSelected?: (...items: T[]) => void
    headers: any[]
    searchParamMap?: ISearchParamMap
    getRowClass?: (row: T) => string
    noContainerForTable?: boolean
}

interface IContentTableSearchSectionProps<T, K extends keyof T> extends IBaseSearchSectionProps<T, K> {
    regularTable?: false | null | undefined
    headers: IContentTableHeader<T>[]
}

interface ITableSearchSectionProps<T, K extends keyof T> extends IBaseSearchSectionProps<T, K> {
    regularTable?: true
    headers: ITableHeader<T>[]
}

export type ISearchSectionProps<T, K extends keyof T> = IContentTableSearchSectionProps<T, K> | ITableSearchSectionProps<T, K>

export interface ISearchSectionState<T> {
    page: number
    perPage: number
    searchFilter: string
    sort: string
    totalItems: number
    items: T[]
    searching: boolean
}

const defaultSearchResult: ISearchResult<any> = {
    items: [],
    totalItems: 0
}

class SearchSection<T, K extends keyof T> extends React.PureComponent<ISearchSectionProps<T, K>, ISearchSectionState<T>> {
    private abortController: AbortController

    constructor(props: ISearchSectionProps<T, K>) {
        super(props)

        this.state = {
            ...getSearchParamsFromQuery(props.search, undefined, props.defaultPerPage, props.searchParamMap),
            totalItems: null,
            items: null,
            searching: false
        }
    }

    public componentDidMount() {
        this.doSearch()
    }

    public componentDidUpdate(prevProps: Readonly<ISearchSectionProps<T, K>>, prevState: Readonly<ISearchSectionState<T>>) {
        if (this.props.search !== prevProps.search) {
            this.setState(
                {
                    ...getSearchParamsFromQuery(this.props.search, 1, this.props.defaultPerPage, this.props.searchParamMap)
                },
                () => { this.doSearch() }
            )
        }
    }

    public componentWillUnmount() {
        this.abortController.abort()
    }

    private readonly handleFilterChange = (value: string, triggerSearch?: boolean) => {
        this.setState({ searchFilter: value }, triggerSearch ? this.onSearch : undefined)
    }

    private readonly handleSelectPage = (value: number) => {
        this.setState({ page: value }, this.onSearch)
    }

    private readonly handleSelectPerPage = (value: number) => {
        this.setState({ perPage: value }, this.onSearch)
    }

    private readonly handleSort = (value: string) => {
        this.setState({ sort: invertOrChange(this.state.sort, value) }, this.onSearch)
    }

    private readonly handleSelectItems = (...items: T[]) => {
        if (this.props.onItemsSelected) {
            this.props.onItemsSelected(...items)
        }
    }

    public doSearch = async () => {
        const { searchFilter, sort, page, perPage } = this.state

        this.abortController?.abort()
        const abort = this.abortController = new AbortController()

        this.setState({ searching: true })
        try {
            const response = await this.props.onSearch(searchFilter, sort, page, perPage, abort.signal)
            // In case the search function didn't accept abort signals, throw here if abort was requested
            if (abort.signal.aborted) {
                if (abort === this.abortController) {
                    const result = this.props.onSearchError?.({
                        filter: searchFilter,
                        sort,
                        page,
                        perPage
                    }, {
                        items: this.state.items,
                        totalItems: this.state.totalItems
                    }) ?? defaultSearchResult
                    this.setState({
                        items: result.items,
                        totalItems: enforceNumber(result.totalItems),
                        searching: false
                    })
                }
            } else {
                this.setState({
                    items: response.items,
                    totalItems: enforceNumber(response.totalItems),
                    searching: false
                })
            }
        } catch (err) {
            if (!abort.signal.aborted) this.setState({ searching: false })
        }
    }

    public updateData = (updater: ((currentData: T[]) => T[])) => {
        this.setState(s => ({ ...s, items: [...updater(s.items)] }))
    }

    private readonly onSearch = (): void => {
        const { defaultPerPage, search, searchParamMap } = this.props
        const { searchFilter, sort, page, perPage } = this.state

        const querySearchParams = getSearchParamsFromQuery(search, undefined, defaultPerPage, searchParamMap)
        const resetPage = querySearchParams.searchFilter !== searchFilter || querySearchParams.sort !== sort

        // If the search doesn't change the URL, then trigger search immediately (refresh results)
        // Otherwise, a search will be triggered when the component is updated by the location search in props
        if (!pushURLSearchFilter(
                this.props.history,
                search,
                searchFilter,
                sort,
                resetPage ? 1 : page,
                perPage,
                defaultPerPage || 50, searchParamMap
            )) {
            this.doSearch()
        }
    }

    public render() {
        const { headers, noItemsFoundMessage, noContainerForTable, onItemsSelected, itemIdKey, regularTable, selectedItems, searchAssistantProperties, getRowClass } = this.props
        const { items, page, perPage, searchFilter, sort, totalItems } = this.state

        const TableTag: React.ElementType = regularTable ? GenericTable : GenericContentTable

        const table = (
            <>
            {items && items.length > 0 && (
                <TableTag
                    headers={headers}
                    data={items}
                    className={cx({ 'rounded-0': noContainerForTable })}
                    onSelect={onItemsSelected && this.handleSelectItems}
                    selectBy={itemIdKey && selectedItems && {
                        key: itemIdKey,
                        selected: selectedItems
                    }}
                    onSort={this.handleSort}
                    currentSort={sort}
                    getRowClass={getRowClass}
                />
            )}
            {items == null && <LoadingCard />}
            {items != null && items.length === 0 &&
                <Card className="text-center" body>
                    {noItemsFoundMessage || <span className="lead text-center">No results</span>}
                </Card>
            }
            </>
        )

        return (
            <>
                <GenericSearchBarWithPaging
                    filter={searchFilter}
                    onFilterChange={this.handleFilterChange}
                    onSearch={this.onSearch}
                    onSelectPage={this.handleSelectPage}
                    onSelectPerPage={this.handleSelectPerPage}
                    currentPage={page}
                    perPage={perPage}
                    totalItems={totalItems}
                    searchAssistantProperties={searchAssistantProperties}
                    searching={this.state.searching}
                    extraElements={this.props.extraSearchBarElements}
                />
                {this.props.children}
                {noContainerForTable ? table : <Container fluid className="mt-3">{table}</Container>}
            </>
        )
    }
}

function RoutedSearchSection<T, K extends keyof T>(props: React.PropsWithChildren<Omit<ISearchSectionProps<T, K> & React.RefAttributes<SearchSection<T, K>>, 'search' | 'history'>>, ref) {
    const location = useLocation()
    const history = useHistory()

    return (
        <SearchSection
            {...props}
            history={history}
            search={location.search}
            ref={ref}
        />
    )
}

export type SearchSectionType<T, K extends keyof T> = SearchSection<T, K>

export default React.forwardRef(RoutedSearchSection) as <T, K extends keyof T>(p: React.PropsWithChildren<Omit<ISearchSectionProps<T, K> & React.RefAttributes<SearchSection<T, K>>, 'search' | 'history'>>) => React.ReactElement
