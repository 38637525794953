// Generated from src/components/search/IQL/IQLFilter.g4 by ANTLR 4.9.0-SNAPSHOT


// @ts-nocheck
/* eslint-disable */


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { IQLFilterListener } from "./IQLFilterListener";
import { IQLFilterVisitor } from "./IQLFilterVisitor";


export class IQLFilterParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly AND = 9;
	public static readonly OR = 10;
	public static readonly NOT = 11;
	public static readonly BOOLEAN = 12;
	public static readonly ISODATE = 13;
	public static readonly NULL = 14;
	public static readonly NUMBER = 15;
	public static readonly WORD = 16;
	public static readonly STOPWORD = 17;
	public static readonly QUOTE = 18;
	public static readonly WS = 19;
	public static readonly UNKNOWN = 20;
	public static readonly RULE_filter = 0;
	public static readonly RULE_expr = 1;
	public static readonly RULE_text = 2;
	public static readonly RULE_property_path = 3;
	public static readonly RULE_keyOpValue = 4;
	public static readonly RULE_property = 5;
	public static readonly RULE_reserved_word = 6;
	public static readonly RULE_value = 7;
	public static readonly RULE_ignored = 8;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"filter", "expr", "text", "property_path", "keyOpValue", "property", "reserved_word", 
		"value", "ignored",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'('", "')'", "'.'", "':'", "'>'", "'<'", "','", "'_'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, "AND", "OR", "NOT", "BOOLEAN", "ISODATE", "NULL", 
		"NUMBER", "WORD", "STOPWORD", "QUOTE", "WS", "UNKNOWN",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(IQLFilterParser._LITERAL_NAMES, IQLFilterParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return IQLFilterParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "IQLFilter.g4"; }

	// @Override
	public get ruleNames(): string[] { return IQLFilterParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return IQLFilterParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(IQLFilterParser._ATN, this);
	}
	// @RuleVersion(0)
	public filter(): FilterContext {
		let _localctx: FilterContext = new FilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, IQLFilterParser.RULE_filter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 18;
			this.expr(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expr(): ExprContext;
	public expr(_p: number): ExprContext;
	// @RuleVersion(0)
	public expr(_p?: number): ExprContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExprContext = new ExprContext(this._ctx, _parentState);
		let _prevctx: ExprContext = _localctx;
		let _startState: number = 2;
		this.enterRecursionRule(_localctx, 2, IQLFilterParser.RULE_expr, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 31;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case IQLFilterParser.NOT:
				{
				_localctx = new NOTContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 21;
				this.match(IQLFilterParser.NOT);
				this.state = 22;
				this.expr(5);
				}
				break;
			case IQLFilterParser.BOOLEAN:
			case IQLFilterParser.NULL:
			case IQLFilterParser.WORD:
			case IQLFilterParser.STOPWORD:
			case IQLFilterParser.QUOTE:
				{
				_localctx = new TextOrFilterContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 25;
				this._errHandler.sync(this);
				switch ( this.interpreter.adaptivePredict(this._input, 0, this._ctx) ) {
				case 1:
					{
					this.state = 23;
					this.text();
					}
					break;

				case 2:
					{
					this.state = 24;
					this.keyOpValue();
					}
					break;
				}
				}
				break;
			case IQLFilterParser.T__0:
				{
				_localctx = new ParensContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 27;
				this.match(IQLFilterParser.T__0);
				this.state = 28;
				this.expr(0);
				this.state = 29;
				this.match(IQLFilterParser.T__1);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 46;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 44;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 3, this._ctx) ) {
					case 1:
						{
						_localctx = new ExprANDContext(new ExprContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, IQLFilterParser.RULE_expr);
						this.state = 33;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 34;
						this.match(IQLFilterParser.AND);
						this.state = 35;
						this.expr(5);
						}
						break;

					case 2:
						{
						_localctx = new ExprORContext(new ExprContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, IQLFilterParser.RULE_expr);
						this.state = 36;
						if (!(this.precpred(this._ctx, 3))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
						}
						this.state = 40;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
						while (_la === IQLFilterParser.OR) {
							{
							{
							this.state = 37;
							this.match(IQLFilterParser.OR);
							}
							}
							this.state = 42;
							this._errHandler.sync(this);
							_la = this._input.LA(1);
						}
						this.state = 43;
						this.expr(4);
						}
						break;
					}
					}
				}
				this.state = 48;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public text(): TextContext {
		let _localctx: TextContext = new TextContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, IQLFilterParser.RULE_text);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 50;
			this._errHandler.sync(this);
			_alt = 1;
			do {
				switch (_alt) {
				case 1:
					{
					{
					this.state = 49;
					_la = this._input.LA(1);
					if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << IQLFilterParser.WORD) | (1 << IQLFilterParser.STOPWORD) | (1 << IQLFilterParser.QUOTE))) !== 0))) {
					this._errHandler.recoverInline(this);
					} else {
						if (this._input.LA(1) === Token.EOF) {
							this.matchedEOF = true;
						}

						this._errHandler.reportMatch(this);
						this.consume();
					}
					}
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 52;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 5, this._ctx);
			} while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public property_path(): Property_pathContext {
		let _localctx: Property_pathContext = new Property_pathContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, IQLFilterParser.RULE_property_path);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 54;
			this.property();
			this.state = 59;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === IQLFilterParser.T__2) {
				{
				{
				this.state = 55;
				this.match(IQLFilterParser.T__2);
				this.state = 56;
				this.property();
				}
				}
				this.state = 61;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public keyOpValue(): KeyOpValueContext {
		let _localctx: KeyOpValueContext = new KeyOpValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, IQLFilterParser.RULE_keyOpValue);
		try {
			let _alt: number;
			this.state = 85;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 8, this._ctx) ) {
			case 1:
				_localctx = new GtContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 62;
				this.property_path();
				this.state = 63;
				this.match(IQLFilterParser.T__3);
				this.state = 64;
				this.match(IQLFilterParser.T__4);
				this.state = 65;
				this.value();
				}
				break;

			case 2:
				_localctx = new LtContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 67;
				this.property_path();
				this.state = 68;
				this.match(IQLFilterParser.T__3);
				this.state = 69;
				this.match(IQLFilterParser.T__5);
				this.state = 70;
				this.value();
				}
				break;

			case 3:
				_localctx = new EqContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 72;
				this.property_path();
				this.state = 73;
				this.match(IQLFilterParser.T__3);
				this.state = 74;
				this.value();
				}
				break;

			case 4:
				_localctx = new AnyEqContext(_localctx);
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 76;
				this.property_path();
				this.state = 77;
				this.match(IQLFilterParser.T__3);
				this.state = 78;
				this.value();
				this.state = 81;
				this._errHandler.sync(this);
				_alt = 1;
				do {
					switch (_alt) {
					case 1:
						{
						{
						this.state = 79;
						this.match(IQLFilterParser.T__6);
						this.state = 80;
						this.value();
						}
						}
						break;
					default:
						throw new NoViableAltException(this);
					}
					this.state = 83;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 7, this._ctx);
				} while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public property(): PropertyContext {
		let _localctx: PropertyContext = new PropertyContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, IQLFilterParser.RULE_property);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 96;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case IQLFilterParser.WORD:
				{
				this.state = 87;
				this.match(IQLFilterParser.WORD);
				this.state = 92;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === IQLFilterParser.T__7) {
					{
					{
					this.state = 88;
					this.match(IQLFilterParser.T__7);
					this.state = 89;
					this.match(IQLFilterParser.WORD);
					}
					}
					this.state = 94;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				}
				break;
			case IQLFilterParser.BOOLEAN:
			case IQLFilterParser.NULL:
				{
				this.state = 95;
				this.reserved_word();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public reserved_word(): Reserved_wordContext {
		let _localctx: Reserved_wordContext = new Reserved_wordContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, IQLFilterParser.RULE_reserved_word);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 98;
			_la = this._input.LA(1);
			if (!(_la === IQLFilterParser.BOOLEAN || _la === IQLFilterParser.NULL)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public value(): ValueContext {
		let _localctx: ValueContext = new ValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, IQLFilterParser.RULE_value);
		let _la: number;
		try {
			this.state = 105;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case IQLFilterParser.NULL:
				_localctx = new NullContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 100;
				this.match(IQLFilterParser.NULL);
				}
				break;
			case IQLFilterParser.BOOLEAN:
				_localctx = new BoolContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 101;
				this.match(IQLFilterParser.BOOLEAN);
				}
				break;
			case IQLFilterParser.NUMBER:
				_localctx = new NumberContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 102;
				this.match(IQLFilterParser.NUMBER);
				}
				break;
			case IQLFilterParser.ISODATE:
				_localctx = new DateContext(_localctx);
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 103;
				this.match(IQLFilterParser.ISODATE);
				}
				break;
			case IQLFilterParser.WORD:
			case IQLFilterParser.QUOTE:
				_localctx = new StringContext(_localctx);
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 104;
				_la = this._input.LA(1);
				if (!(_la === IQLFilterParser.WORD || _la === IQLFilterParser.QUOTE)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public ignored(): IgnoredContext {
		let _localctx: IgnoredContext = new IgnoredContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, IQLFilterParser.RULE_ignored);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 107;
			this.matchWildcard();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 1:
			return this.expr_sempred(_localctx as ExprContext, predIndex);
		}
		return true;
	}
	private expr_sempred(_localctx: ExprContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 4);

		case 1:
			return this.precpred(this._ctx, 3);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\x16p\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x03\x02\x03\x02\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x05\x03\x1C\n\x03\x03\x03\x03\x03\x03\x03\x03\x03" +
		"\x05\x03\"\n\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x07\x03)\n\x03" +
		"\f\x03\x0E\x03,\v\x03\x03\x03\x07\x03/\n\x03\f\x03\x0E\x032\v\x03\x03" +
		"\x04\x06\x045\n\x04\r\x04\x0E\x046\x03\x05\x03\x05\x03\x05\x07\x05<\n" +
		"\x05\f\x05\x0E\x05?\v\x05\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03" +
		"\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03" +
		"\x06\x03\x06\x03\x06\x03\x06\x03\x06\x06\x06T\n\x06\r\x06\x0E\x06U\x05" +
		"\x06X\n\x06\x03\x07\x03\x07\x03\x07\x07\x07]\n\x07\f\x07\x0E\x07`\v\x07" +
		"\x03\x07\x05\x07c\n\x07\x03\b\x03\b\x03\t\x03\t\x03\t\x03\t\x03\t\x05" +
		"\tl\n\t\x03\n\x03\n\x03\n\x02\x02\x03\x04\v\x02\x02\x04\x02\x06\x02\b" +
		"\x02\n\x02\f\x02\x0E\x02\x10\x02\x12\x02\x02\x05\x03\x02\x12\x14\x04\x02" +
		"\x0E\x0E\x10\x10\x04\x02\x12\x12\x14\x14\x02x\x02\x14\x03\x02\x02\x02" +
		"\x04!\x03\x02\x02\x02\x064\x03\x02\x02\x02\b8\x03\x02\x02\x02\nW\x03\x02" +
		"\x02\x02\fb\x03\x02\x02\x02\x0Ed\x03\x02\x02\x02\x10k\x03\x02\x02\x02" +
		"\x12m\x03\x02\x02\x02\x14\x15\x05\x04\x03\x02\x15\x03\x03\x02\x02\x02" +
		"\x16\x17\b\x03\x01\x02\x17\x18\x07\r\x02\x02\x18\"\x05\x04\x03\x07\x19" +
		"\x1C\x05\x06\x04\x02\x1A\x1C\x05\n\x06\x02\x1B\x19\x03\x02\x02\x02\x1B" +
		"\x1A\x03\x02\x02\x02\x1C\"\x03\x02\x02\x02\x1D\x1E\x07\x03\x02\x02\x1E" +
		"\x1F\x05\x04\x03\x02\x1F \x07\x04\x02\x02 \"\x03\x02\x02\x02!\x16\x03" +
		"\x02\x02\x02!\x1B\x03\x02\x02\x02!\x1D\x03\x02\x02\x02\"0\x03\x02\x02" +
		"\x02#$\f\x06\x02\x02$%\x07\v\x02\x02%/\x05\x04\x03\x07&*\f\x05\x02\x02" +
		"\')\x07\f\x02\x02(\'\x03\x02\x02\x02),\x03\x02\x02\x02*(\x03\x02\x02\x02" +
		"*+\x03\x02\x02\x02+-\x03\x02\x02\x02,*\x03\x02\x02\x02-/\x05\x04\x03\x06" +
		".#\x03\x02\x02\x02.&\x03\x02\x02\x02/2\x03\x02\x02\x020.\x03\x02\x02\x02" +
		"01\x03\x02\x02\x021\x05\x03\x02\x02\x0220\x03\x02\x02\x0235\t\x02\x02" +
		"\x0243\x03\x02\x02\x0256\x03\x02\x02\x0264\x03\x02\x02\x0267\x03\x02\x02" +
		"\x027\x07\x03\x02\x02\x028=\x05\f\x07\x029:\x07\x05\x02\x02:<\x05\f\x07" +
		"\x02;9\x03\x02\x02\x02<?\x03\x02\x02\x02=;\x03\x02\x02\x02=>\x03\x02\x02" +
		"\x02>\t\x03\x02\x02\x02?=\x03\x02\x02\x02@A\x05\b\x05\x02AB\x07\x06\x02" +
		"\x02BC\x07\x07\x02\x02CD\x05\x10\t\x02DX\x03\x02\x02\x02EF\x05\b\x05\x02" +
		"FG\x07\x06\x02\x02GH\x07\b\x02\x02HI\x05\x10\t\x02IX\x03\x02\x02\x02J" +
		"K\x05\b\x05\x02KL\x07\x06\x02\x02LM\x05\x10\t\x02MX\x03\x02\x02\x02NO" +
		"\x05\b\x05\x02OP\x07\x06\x02\x02PS\x05\x10\t\x02QR\x07\t\x02\x02RT\x05" +
		"\x10\t\x02SQ\x03\x02\x02\x02TU\x03\x02\x02\x02US\x03\x02\x02\x02UV\x03" +
		"\x02\x02\x02VX\x03\x02\x02\x02W@\x03\x02\x02\x02WE\x03\x02\x02\x02WJ\x03" +
		"\x02\x02\x02WN\x03\x02\x02\x02X\v\x03\x02\x02\x02Y^\x07\x12\x02\x02Z[" +
		"\x07\n\x02\x02[]\x07\x12\x02\x02\\Z\x03\x02\x02\x02]`\x03\x02\x02\x02" +
		"^\\\x03\x02\x02\x02^_\x03\x02\x02\x02_c\x03\x02\x02\x02`^\x03\x02\x02" +
		"\x02ac\x05\x0E\b\x02bY\x03\x02\x02\x02ba\x03\x02\x02\x02c\r\x03\x02\x02" +
		"\x02de\t\x03\x02\x02e\x0F\x03\x02\x02\x02fl\x07\x10\x02\x02gl\x07\x0E" +
		"\x02\x02hl\x07\x11\x02\x02il\x07\x0F\x02\x02jl\t\x04\x02\x02kf\x03\x02" +
		"\x02\x02kg\x03\x02\x02\x02kh\x03\x02\x02\x02ki\x03\x02\x02\x02kj\x03\x02" +
		"\x02\x02l\x11\x03\x02\x02\x02mn\v\x02\x02\x02n\x13\x03\x02\x02\x02\x0E" +
		"\x1B!*.06=UW^bk";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!IQLFilterParser.__ATN) {
			IQLFilterParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(IQLFilterParser._serializedATN));
		}

		return IQLFilterParser.__ATN;
	}

}

export class FilterContext extends ParserRuleContext {
	public expr(): ExprContext {
		return this.getRuleContext(0, ExprContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_filter; }
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterFilter) {
			listener.enterFilter(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitFilter) {
			listener.exitFilter(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitFilter) {
			return visitor.visitFilter(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExprContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_expr; }
	public copyFrom(ctx: ExprContext): void {
		super.copyFrom(ctx);
	}
}
export class NOTContext extends ExprContext {
	public NOT(): TerminalNode { return this.getToken(IQLFilterParser.NOT, 0); }
	public expr(): ExprContext {
		return this.getRuleContext(0, ExprContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterNOT) {
			listener.enterNOT(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitNOT) {
			listener.exitNOT(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitNOT) {
			return visitor.visitNOT(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class ExprANDContext extends ExprContext {
	public expr(): ExprContext[];
	public expr(i: number): ExprContext;
	public expr(i?: number): ExprContext | ExprContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExprContext);
		} else {
			return this.getRuleContext(i, ExprContext);
		}
	}
	public AND(): TerminalNode { return this.getToken(IQLFilterParser.AND, 0); }
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterExprAND) {
			listener.enterExprAND(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitExprAND) {
			listener.exitExprAND(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitExprAND) {
			return visitor.visitExprAND(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class ExprORContext extends ExprContext {
	public expr(): ExprContext[];
	public expr(i: number): ExprContext;
	public expr(i?: number): ExprContext | ExprContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExprContext);
		} else {
			return this.getRuleContext(i, ExprContext);
		}
	}
	public OR(): TerminalNode[];
	public OR(i: number): TerminalNode;
	public OR(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(IQLFilterParser.OR);
		} else {
			return this.getToken(IQLFilterParser.OR, i);
		}
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterExprOR) {
			listener.enterExprOR(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitExprOR) {
			listener.exitExprOR(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitExprOR) {
			return visitor.visitExprOR(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class TextOrFilterContext extends ExprContext {
	public textContext(): TextContext | undefined {
		return this.tryGetRuleContext(0, TextContext);
	}
	public keyOpValue(): KeyOpValueContext | undefined {
		return this.tryGetRuleContext(0, KeyOpValueContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterTextOrFilter) {
			listener.enterTextOrFilter(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitTextOrFilter) {
			listener.exitTextOrFilter(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitTextOrFilter) {
			return visitor.visitTextOrFilter(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class ParensContext extends ExprContext {
	public expr(): ExprContext {
		return this.getRuleContext(0, ExprContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterParens) {
			listener.enterParens(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitParens) {
			listener.exitParens(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitParens) {
			return visitor.visitParens(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class TextContext extends ParserRuleContext {
	public QUOTE(): TerminalNode[];
	public QUOTE(i: number): TerminalNode;
	public QUOTE(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(IQLFilterParser.QUOTE);
		} else {
			return this.getToken(IQLFilterParser.QUOTE, i);
		}
	}
	public STOPWORD(): TerminalNode[];
	public STOPWORD(i: number): TerminalNode;
	public STOPWORD(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(IQLFilterParser.STOPWORD);
		} else {
			return this.getToken(IQLFilterParser.STOPWORD, i);
		}
	}
	public WORD(): TerminalNode[];
	public WORD(i: number): TerminalNode;
	public WORD(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(IQLFilterParser.WORD);
		} else {
			return this.getToken(IQLFilterParser.WORD, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_text; }
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterText) {
			listener.enterText(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitText) {
			listener.exitText(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitText) {
			return visitor.visitText(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Property_pathContext extends ParserRuleContext {
	public property(): PropertyContext[];
	public property(i: number): PropertyContext;
	public property(i?: number): PropertyContext | PropertyContext[] {
		if (i === undefined) {
			return this.getRuleContexts(PropertyContext);
		} else {
			return this.getRuleContext(i, PropertyContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_property_path; }
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterProperty_path) {
			listener.enterProperty_path(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitProperty_path) {
			listener.exitProperty_path(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitProperty_path) {
			return visitor.visitProperty_path(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class KeyOpValueContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_keyOpValue; }
	public copyFrom(ctx: KeyOpValueContext): void {
		super.copyFrom(ctx);
	}
}
export class GtContext extends KeyOpValueContext {
	public property_path(): Property_pathContext {
		return this.getRuleContext(0, Property_pathContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	constructor(ctx: KeyOpValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterGt) {
			listener.enterGt(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitGt) {
			listener.exitGt(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitGt) {
			return visitor.visitGt(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class LtContext extends KeyOpValueContext {
	public property_path(): Property_pathContext {
		return this.getRuleContext(0, Property_pathContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	constructor(ctx: KeyOpValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterLt) {
			listener.enterLt(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitLt) {
			listener.exitLt(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitLt) {
			return visitor.visitLt(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class EqContext extends KeyOpValueContext {
	public property_path(): Property_pathContext {
		return this.getRuleContext(0, Property_pathContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	constructor(ctx: KeyOpValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterEq) {
			listener.enterEq(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitEq) {
			listener.exitEq(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitEq) {
			return visitor.visitEq(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class AnyEqContext extends KeyOpValueContext {
	public property_path(): Property_pathContext {
		return this.getRuleContext(0, Property_pathContext);
	}
	public value(): ValueContext[];
	public value(i: number): ValueContext;
	public value(i?: number): ValueContext | ValueContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueContext);
		} else {
			return this.getRuleContext(i, ValueContext);
		}
	}
	constructor(ctx: KeyOpValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterAnyEq) {
			listener.enterAnyEq(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitAnyEq) {
			listener.exitAnyEq(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitAnyEq) {
			return visitor.visitAnyEq(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PropertyContext extends ParserRuleContext {
	public WORD(): TerminalNode[];
	public WORD(i: number): TerminalNode;
	public WORD(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(IQLFilterParser.WORD);
		} else {
			return this.getToken(IQLFilterParser.WORD, i);
		}
	}
	public reserved_word(): Reserved_wordContext | undefined {
		return this.tryGetRuleContext(0, Reserved_wordContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_property; }
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterProperty) {
			listener.enterProperty(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitProperty) {
			listener.exitProperty(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitProperty) {
			return visitor.visitProperty(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Reserved_wordContext extends ParserRuleContext {
	public NULL(): TerminalNode | undefined { return this.tryGetToken(IQLFilterParser.NULL, 0); }
	public BOOLEAN(): TerminalNode | undefined { return this.tryGetToken(IQLFilterParser.BOOLEAN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_reserved_word; }
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterReserved_word) {
			listener.enterReserved_word(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitReserved_word) {
			listener.exitReserved_word(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitReserved_word) {
			return visitor.visitReserved_word(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_value; }
	public copyFrom(ctx: ValueContext): void {
		super.copyFrom(ctx);
	}
}
export class NullContext extends ValueContext {
	public NULL(): TerminalNode { return this.getToken(IQLFilterParser.NULL, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterNull) {
			listener.enterNull(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitNull) {
			listener.exitNull(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitNull) {
			return visitor.visitNull(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class BoolContext extends ValueContext {
	public BOOLEAN(): TerminalNode { return this.getToken(IQLFilterParser.BOOLEAN, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterBool) {
			listener.enterBool(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitBool) {
			listener.exitBool(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitBool) {
			return visitor.visitBool(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class NumberContext extends ValueContext {
	public NUMBER(): TerminalNode { return this.getToken(IQLFilterParser.NUMBER, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterNumber) {
			listener.enterNumber(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitNumber) {
			listener.exitNumber(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitNumber) {
			return visitor.visitNumber(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class DateContext extends ValueContext {
	public ISODATE(): TerminalNode { return this.getToken(IQLFilterParser.ISODATE, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterDate) {
			listener.enterDate(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitDate) {
			listener.exitDate(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitDate) {
			return visitor.visitDate(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class StringContext extends ValueContext {
	public QUOTE(): TerminalNode | undefined { return this.tryGetToken(IQLFilterParser.QUOTE, 0); }
	public WORD(): TerminalNode | undefined { return this.tryGetToken(IQLFilterParser.WORD, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterString) {
			listener.enterString(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitString) {
			listener.exitString(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitString) {
			return visitor.visitString(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class IgnoredContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return IQLFilterParser.RULE_ignored; }
	// @Override
	public enterRule(listener: IQLFilterListener): void {
		if (listener.enterIgnored) {
			listener.enterIgnored(this);
		}
	}
	// @Override
	public exitRule(listener: IQLFilterListener): void {
		if (listener.exitIgnored) {
			listener.exitIgnored(this);
		}
	}
	// @Override
	public accept<Result>(visitor: IQLFilterVisitor<Result>): Result {
		if (visitor.visitIgnored) {
			return visitor.visitIgnored(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


