import React from 'react'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Button, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { IOption } from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import MetadataTileInput from '@src/components/metadata/definitions/MetadataDefinitionTileInput'
import { IModalProps } from '@src/hooks/useModal'
import { composeValidators, isMetadataKeyForm, required } from '@src/logic/forms/validation'
import { slugify } from '@src/logic/utils/Strings'
import { IMetadataDefinition, MetadataPropertyToLinkType, MetadataTypes } from '@src/types/metadata'

export interface INewDefinitionModalFormData {
    name: string
    key: string
    type: MetadataTypes
    parentKey?: IOption<string>
}
interface IProps extends IModalProps {
    existingDefinitions: readonly IMetadataDefinition[]
    validTypes: readonly MetadataTypes[]
    onSubmit: (values: INewDefinitionModalFormData) => Promise<void>
}

const formIdPrefix = 'new_definition-'

export default function NewDefinitionModal({ existingDefinitions, validTypes, ...props }: IProps) { // React.PureComponent<IProps & IInternalProps & IModalProps & InjectedFormProps<INewDefinitionModalFormData, IProps & IInternalProps & IModalProps>> {
    const [propertyTypeSelected, setPropertyTypeSelected] = React.useState(false)
    const [linkOptions, setLinkOptions] = React.useState<IOption<string>[]>([])

    function handleSelectedPropertyTypeChange(type: MetadataTypes) {
        const propertyTypeSelected = Object.keys(MetadataPropertyToLinkType).includes(type)
        setPropertyTypeSelected(propertyTypeSelected)
        setLinkOptions(propertyTypeSelected
            ? existingDefinitions.filter(d => d.type === MetadataPropertyToLinkType[type]).map(d => ({ label: d.name, value: d.key }))
            : []
        )
    }

    async function submitForm(values: INewDefinitionModalFormData) {
        await props.onSubmit(values)
        props.toggle()
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} onClosed={props.onClosed}>
            <Form onSubmit={submitForm}>
                {({ handleSubmit, form }) =>
                    <>
                        <ModalHeader toggle={props.toggle}>Add metadata</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for={`${formIdPrefix}name`}>Name</Label>
                                <Field id={`${formIdPrefix}name`} name="name" component={ValidatedInput} validate={required} />
                                <FormText>Name of the field.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for={`${formIdPrefix}key`}>Search Key</Label>
                                <Field id={`${formIdPrefix}key`} name="key" component={ValidatedInput} validate={composeValidators(required, isMetadataKeyForm)} />
                                <FormText>This will be used to identify this field when searching.</FormText>
                                <OnChange name="name">
                                    {(name) => form.change('key', slugify(name))}
                                </OnChange>
                            </FormGroup>
                            <FormGroup>
                                <Label>Type</Label>
                                <Field name="type" component={MetadataTileInput} availableTypes={validTypes} validate={required} />
                                <OnChange name="type">
                                    {handleSelectedPropertyTypeChange}
                                </OnChange>
                            </FormGroup>
                            {propertyTypeSelected &&
                                <FormGroup>
                                    <Label>Parent</Label>
                                    <Field name="parentKey" component={ValidatedSelect} validate={required} options={linkOptions} />
                                </FormGroup>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit}>Create</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
