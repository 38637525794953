import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { useDispatch } from 'react-redux'
import { Alert, Button, ButtonGroup, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap'

import { newUploads, toggleSandbox } from '@src/actions/sandbox'
import { UserAvatar } from '@src/components/common/Avatar'
import DetailList from '@src/components/common/DetailList'
import FA from '@src/components/common/FontAwesomeIcon'
import IFrame from '@src/components/common/IFrame'
import EmailAttachment from '@src/components/email/EmailAttachment'
import RecipientList from '@src/components/email/RecipientList'
import useNotifications from '@src/hooks/useNotifications'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import { EmailOperations } from '@src/logic/auth/operations'
import { EmailDownloadAsPdf, EmailDownloadAsPdfLink, EmailDownloadLink, ProjectUsersList } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { localDateTime } from '@src/logic/utils/Date'
import { Email } from '@src/types/email'
import { UserBasic } from '@src/types/principal'

interface IProps {
    email: Email
    onExit: () => void
}

async function loadSenderAsync(abortSignal: AbortSignal, email: Email): Promise<UserBasic | null> {
    if (email.from.userId == null) return null
    const response = await ProjectUsersList(email.projectId, `id: "${email.from.userId}"`, undefined, 1, 1, { abortSignal })
    if (response.data.length === 0) return null

    return response.data[0]
}

export default function EmailPreview({ email, onExit }: IProps) {
    const notifications = useNotifications()
    const dispatch = useDispatch()
    const iframeRef = React.useRef<HTMLIFrameElement>()
    const senderAsync = useAsyncAbortable(loadSenderAsync, [email])
    const downloadsDisabled = React.useMemo(() => !isAuthorised(email.myAccess, EmailOperations.Download), [email.myAccess])
    const recipients = React.useMemo(() => {
        const recipients = [{ title: 'To', data: <RecipientList recipients={email.to} /> }]
        if (email.cc.length) recipients.push({ title: 'Cc', data: <RecipientList recipients={email.cc} /> })
        if (email.bcc.length) recipients.push({ title: 'Bcc', data: <RecipientList recipients={email.bcc} /> })
        return recipients
    }, [email])
    const nonContentAttachments = React.useMemo(
        () => email.attachments.filter(a => a.contentId == null || !email.htmlBody.includes(a.contentId)
        ),
    [email])
    const [firstName, lastName] = React.useMemo(
        () => {
            if (!email.from.name) return [undefined, undefined]
            const parts = email.from.name.split(' ')
            return parts.length === 1 ? [parts[0], undefined] : [parts[0], parts[1]]
        },
    [email.from.name])

    function resizeIframe() {
        iframeRef.current.style.height = `${iframeRef.current.contentDocument.documentElement.scrollHeight}px`
    }

    function downloadEmail() {
        downloadURL(EmailDownloadLink(auth.getSessionToken(), email.id))
    }

    function downloadAsPdf() {
        downloadURL(EmailDownloadAsPdfLink(auth.getSessionToken(), email.id))
    }

    async function sendToSandbox() {
        const notificationId = notifications.pending('Sending email to sandbox')
        try {
            const pdfResponse = await EmailDownloadAsPdf(email.id)
            notifications.remove(notificationId)
            dispatch(newUploads([new File([pdfResponse.data], `${email.subject}.pdf`, { type: 'application/pdf' })]))
            dispatch(toggleSandbox(true))
        } catch {
            notifications.updateThenClose(notificationId, 'There was an issue while sending an email to the sandbox', { type: 'error' })
        }
    }

    return (
        <Container fluid className="h-100 d-flex flex-column bg-white overflow-auto">
            {downloadsDisabled && <Row className="mt-3">
                <Col>
                    <Alert color="warning" className="mb-0"><FA icon="exclamation-triangle" className="mr-2" />You do not have permission to download this email or its attachments.</Alert>
                </Col>
            </Row>}
            <Row className="d-md-none mt-3 pb-2 border-bottom">
                <Col>
                    <Button color="link" onClick={onExit}><FA icon="chevron-left" /></Button>
                </Col>
                <Col className="flex-grow-0 flex-shrink-1">
                    <ButtonGroup>
                        <Button disabled={downloadsDisabled} color="primary" outline onClick={sendToSandbox}><FA icon="cloud-upload" fixedWidth /></Button>
                        <Button disabled={downloadsDisabled} color="primary" outline onClick={downloadAsPdf}><FA icon="file-pdf" fixedWidth /></Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="m-2 mt-3">
                <Col><h1>{email.subject}</h1></Col>
            </Row>
            <Row className="m-2">
                <Col md={8} className="d-flex">
                    <div className="mr-2">
                        <UserAvatar size="sm" firstName={senderAsync.result?.lastName || firstName} lastName={senderAsync.result?.lastName || lastName} imageUrl={senderAsync.result?.profilePictureLink} />
                    </div>
                    <div className="flex-grow-1">
                        <p className="mb-1">{email.from.name} &lt;{email.from.email}&gt;</p>
                        <DetailList entries={recipients} />
                    </div>
                </Col>
                <Col md={4} className="flex-column align-items-end d-flex">
                    <UncontrolledButtonDropdown className="d-none d-md-block">
                        {downloadsDisabled && <UncontrolledTooltip target="download-email">You do not have permission</UncontrolledTooltip>}
                        <Button id="download-email" disabled={downloadsDisabled} onClick={downloadEmail}><FA icon="download" className="mr-2"/>Download .eml</Button>
                        <DropdownToggle split disabled={downloadsDisabled} />
                        <DropdownMenu right >
                            <DropdownItem onClick={sendToSandbox}><FA icon="cloud-upload" fixedWidth className="mr-2" />Send to sandbox</DropdownItem>
                            <DropdownItem onClick={downloadAsPdf}><FA icon="file-pdf" fixedWidth className="mr-2" />Download as PDF</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <small className="text-muted">{localDateTime(email.received)}</small>
                </Col>
            </Row>
            <Row className="m-2 pb-2 border-bottom overflow-auto flex-shrink-0" style={{ maxHeight: 150 }}>
                {nonContentAttachments.map((attachment, idx) => (
                    <Col key={idx} md={3}>
                        <EmailAttachment emailId={email.id} attachment={attachment} index={email.attachments.indexOf(attachment)} hideDownload={downloadsDisabled} />
                    </Col>
                ))}
            </Row>
            <Row className="flex-fill">
                <Col>
                    <IFrame ref={iframeRef} title="Email body" scrolling="no" content={email.htmlBody || email.textBody} width="100%" sandbox="allow-same-origin" className="border-0" onLoad={resizeIframe} />
                </Col>
            </Row>
        </Container>
    )
}
