import { History, Location } from 'history'

import * as Query from '@src/logic/http/CommonQueryParamters'

export function pushURLSearchFilter(history: History, urlSearch: string, searchFilter?: string, sort?: string, page?: number, perPage?: number, defaultPerPage?: number, paramMap?: ISearchParamMap): boolean {
    const queryStringBuilder = new URLSearchParams(urlSearch)
    const map = paramMap ?? defaultSearchParamMap

    if (searchFilter) {
        queryStringBuilder.set(map.filter, searchFilter)
    } else {
        queryStringBuilder.delete(map.filter)
    }

    if (sort) {
        queryStringBuilder.set(map.sort, sort)
    } else {
        queryStringBuilder.delete(map.sort)
    }

    if (page && page > 1) {
        queryStringBuilder.set(map.page, page.toString())
    } else {
        queryStringBuilder.delete(map.page)
    }

    if (perPage && perPage !== defaultPerPage) {
        queryStringBuilder.set(map.perPage, perPage.toString())
    } else {
        queryStringBuilder.delete(map.perPage)
    }

    const builtQuery = queryStringBuilder.toString()

    if (builtQuery !== history.location.search.substring(1)) {
        history.push({
            pathname: history.location.pathname,
            search: builtQuery
        })

        return true
    }

    return false
}

export interface ISearchParamMap {
    page: string
    perPage: string
    filter: string
    sort: string
}

const defaultSearchParamMap = {
    page: Query.Page,
    perPage: Query.PerPage,
    filter: Query.Filter,
    sort: Query.Sort
}

export function getSearchParamsFromQuery(search: string, defaultPage?: number, defaultPerPage?: number, paramMap?: ISearchParamMap) {
    const urlQuery = new URLSearchParams(search)
    const page = defaultPage || 1
    const perPage = defaultPerPage || 50
    const map = paramMap || defaultSearchParamMap
    return {
        page: +urlQuery.get(map.page) || page,
        perPage: +urlQuery.get(map.perPage) || perPage,
        searchFilter: urlQuery.get(map.filter) || '',
        sort: urlQuery.get(map.sort) || ''
    }
}

export function pushURLWithParamUpdates<T = unknown>(history: History, location: Location<T>, updates: { [param: string]: string }, remove: string[]) {
    const queryStringBuilder = new URLSearchParams(location.search)

    for (const paramKey in updates) {
        queryStringBuilder.set(paramKey, updates[paramKey])
    }

    for (const removeKey of remove) {
        queryStringBuilder.delete(removeKey)
    }

    history.push({
        pathname: location.pathname,
        search: queryStringBuilder.toString()
    })
}
