import React from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router'

import CrumbRoute, { CrumbRouteProps } from '@src/components/navigation/CrumbRoute'
import { FeatureFlag, Features, hasFeature } from '@src/logic/features/features'
import { RootState } from '@src/types/models'

interface FeatureCrumbRouteProps<T, TFlag extends FeatureFlag> extends CrumbRouteProps<T> {
    flag: TFlag
    value: Features[TFlag]
}

interface FeatureRouteProps<TFlag extends FeatureFlag> extends RouteProps {
    flag: TFlag
    value: Features[TFlag]
}

export const FeatureCrumbRoute = <T, TFlag extends FeatureFlag>({ flag, value, ...routeProps }: FeatureCrumbRouteProps<T, TFlag>) => {
    const features = useSelector<RootState, Features>(x => x.session.features)
    return hasFeature(features, flag, value) && <CrumbRoute {...routeProps} />
}

export const FeatureRoute = <TFlag extends FeatureFlag>({ flag, value, ...routeProps }: FeatureRouteProps<TFlag>) => {
    const features = useSelector<RootState, Features>(x => x.session.features)
    return hasFeature(features, flag, value) && <Route {...routeProps} />
}
