import React from "react";
import { connect } from "react-redux";
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { UserAvatar } from "@src/components/common/Avatar";
import Link from "@src/components/common/Link";
import { auth } from "@src/logic/auth/AuthService";
import * as Routes from "@src/logic/routing/routes";
import { RootState } from "@src/types/models";
import { Session } from "@src/types/session";

interface IConnectedState {
  user: Session.User;
  account: Session.AuthAccount;
}

interface IState {
  open: boolean;
}

class UserMenu extends React.PureComponent<IConnectedState, IState> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  private readonly toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  private readonly gotoAdmin = () => {
    window.location = Routes.ADMIN_CONSOLE_DASHBOARD as any;
  };

  public render() {
    const { user } = this.props;

    return (
      <Dropdown
        className="header__dropdown h-100 d-flex"
        isOpen={this.state.open}
        toggle={this.toggle}
        inNavbar
      >
        <DropdownToggle
          className="px-1 py-0 my-auto"
          caret
          color="link"
          role="button"
          aria-label="User menu"
        >
          <span className="header__notifications d-inline-block mr-0">
            <UserAvatar size="xs" imageUrl={user.profilePictureLink} />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu--alt" right>
          <DropdownItem header>
            {user.firstName + " " + user.lastName}
          </DropdownItem>
          <DropdownItem tag={Link} to={Routes.SETTINGS_PROFILE}>
            Settings
          </DropdownItem>
          <DropdownItem onClick={auth.logout}>Logout</DropdownItem>
          <DropdownItem header>Help</DropdownItem>
          <DropdownItem
            tag="a"
            href="https://docs.infopoint.com.au"
            target="_blank"
          >
            Docs
          </DropdownItem>
          <DropdownItem
            tag="a"
            href="https://infopoint.com.au/terms-and-conditions"
          >
            Terms &amp; Conditions
          </DropdownItem>
          {user.allowedConsole && (
            <DropdownItem tag={Link} onClick={this.gotoAdmin}>
              Admin Console
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
  return {
    ...ownProps,
    user: state.session.user,
    account: state.session.account,
  };
}

export default connect(mapStateToProps)(UserMenu);
