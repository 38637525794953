import React from 'react'
import Dropzone from 'react-dropzone'
import { Button, FormFeedback } from 'reactstrap'

import cx from 'classnames'
import { DebouncedFunc, debounce } from 'lodash'

import FA from '@src/components/common/FontAwesomeIcon'
import SmartUpload from '@src/components/common/SmartUpload'
import { SandboxDeleteFile, SandboxSignUpload } from '@src/logic/http/Api'
import { Manager } from '@src/logic/storage/gcs/GCSUploadManager'
import { FieldRenderProps } from '@src/types/react-final-form'
import { Sandbox } from '@src/types/sandbox'

type IProps = FieldRenderProps<Sandbox.Upload> & {
}

export default class ValidatedDropzoneSandboxInput extends React.PureComponent<IProps> {
    private readonly debouncedHandleDownloadProgress: DebouncedFunc<((id: string, bytesCompleted: number) => void)>

    constructor(props) {
        super(props)
        this.debouncedHandleDownloadProgress = debounce(this.handleUploadUpdate, 100)
    }

    public componentWillUnmount() {
        this.debouncedHandleDownloadProgress.cancel()
        if (this.props.input.value) {
            Manager.cancelUpload(this.props.input.value.id)
        }
    }

    private readonly onDrop = async (files: File[]) => {
        const sandboxSignReq = {
            fileName: encodeURI(files[0].name),
            contentType: files[0].type || 'text/plain; charset=us-ascii',
            length: files[0].size
        }
        const signedUrlResponse = (await SandboxSignUpload(sandboxSignReq)).data

        const googleUpload = await Manager.newSignedUpload(signedUrlResponse.name, signedUrlResponse.url, files[0], this.handleUploadUpdate, this.handleUploadCompleted)

        const upload: Sandbox.Upload = {
            id: googleUpload.id,
            bytesTotal: googleUpload.file.size,
            bytesUploaded: 0,
            contentType: googleUpload.file.type,
            filename: googleUpload.file.name,
            pausingAtByte: 0,
            state: 'running'
        }

        this.props.input.onChange(upload)
        Manager.startUpload(googleUpload.id)
    }

    private readonly handleUploadUpdate = (id: string, bytesCompleted: number) => {
        this.props.input.onChange({ ...this.props.input.value, bytesUploaded: bytesCompleted })
    }

    private readonly handleUploadCompleted = (id: string) => {
        this.debouncedHandleDownloadProgress.cancel()
        this.props.input.onChange({ ...this.props.input.value, state: 'completed' })
    }

    private readonly handleRemove = () => {
        Manager.cancelUpload(this.props.input.value.id)
        this.props.input.onChange(undefined)
        SandboxDeleteFile(this.props.input.value.id)
    }

    public render() {
        const { value: upload } = this.props.input
        const showError = this.props.meta.touched && !!this.props.meta.error
        return (
            <Dropzone onDrop={this.onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) =>
                    <>
                        <div role="presentation" className={cx('document-sandbox__filebox', { 'border-danger': showError })} onClick={null} {...getRootProps()}>
                            {upload
                                ? <SmartUpload upload={upload} onCancel={this.handleRemove} />
                                : (
                                    <div className="text-center">
                                        <FA icon="cloud-upload" size="3x" />
                                        <h2 className="my-3">Drag and Drop</h2>
                                        <p>or</p>
                                        <Button block color="success" onClick={null}>Select File</Button>
                                    </div>
                                )}
                            <input {...getInputProps()} />
                        </div>
                        {showError && <FormFeedback className="text-danger d-block">{this.props.meta.error}</FormFeedback>}
                    </>}
            </Dropzone>
        )
    }
}
