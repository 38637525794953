import React from 'react'

import FroalaEditor from '@src/components/common/FroalaEditor'

interface IProps {
    content: string
    config?: object
}

const FroalaViewer: React.FC<IProps> = ({ content, config }) => {
    function editorOff(e, editor) {
        editor.edit.off()
        editor.toolbar.hide()
    }

    return (
        <FroalaEditor
            tag="textarea"
            model={content}
            config={{
                key: process.env.FROALA_LICENSE_KEY,
                iconsTemplate: 'font_awesome_5l',
                heightMin: 400,
                editorClass: 'fr-forceselectable',
                events: {
                    'froalaEditor.initialized': editorOff
                },
                charCounterCount: false,
                placeholderText: '',
                ...config
            }}
        />
    )
}

export default React.memo(FroalaViewer)
