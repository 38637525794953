import React from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { IModalProps } from '@src/hooks/useModal'
import { RevisionApprove, RevisionReject } from '@src/logic/http/Api'
import { Revision } from '@src/types/document'

interface IProps extends IModalProps {
    revision: Revision
    onApproveOrReject: () => void
}

// TODO: Modal might break on display < 480px due to auth0 styling at breakpoint
export default function RevisionApprovalModal({ revision, onApproveOrReject, ...modalProps }: IProps) {
    const [comments, setComments] = React.useState('')

    function handleCommentsChange(e: React.ChangeEvent<HTMLInputElement>) {
        setComments(e.target.value)
    }

    async function approveRevision() {
        await RevisionApprove(revision.id, comments)
        onApproveOrReject()
        modalProps.toggle()
    }

    async function rejectRevision() {
        await RevisionReject(revision.id, comments)
        onApproveOrReject()
        modalProps.toggle()
    }

    return (
        <Modal wrapClassName="d-block" {...modalProps}>
            <ModalHeader toggle={modalProps.toggle}>Review Revision</ModalHeader>
            <ModalBody>
                <p>You may leave comments along with your decision to approve or reject. If you are not yet ready to commit, close the dialog in the top right corner.</p>
                <p><strong>Request Comments:</strong></p>
                <p>{revision.approval.requestComments}</p>
                <p><strong>Response:</strong></p>
                <Input type="textarea" className="mb-3" value={comments} onChange={handleCommentsChange} />
            </ModalBody>
            <ModalFooter>
                <Button color="success" className="mr-2" onClick={approveRevision}>Approve</Button>
                <Button color="danger" onClick={rejectRevision}>Reject</Button>
            </ModalFooter>
        </Modal>
    )
}
