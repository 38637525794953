import React from "react";
import { Field, Form } from "react-final-form";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import infopointLogo from "public/img/infopoint_colour.svg";
import SignupLeftImg from "public/img/signup-left.png";
import ValidatedCheckboxRadio from "@src/components/common/ValidatedCheckboxRadio";
import ValidatedInput, {
  IProps as InputProps,
} from "@src/components/common/ValidatedInput";
import { auth } from "@src/logic/auth/AuthService";
import { buildFormErrorsFromModelState } from "@src/logic/forms/errors";
import {
  composeValidators,
  matchField,
  required,
  validPassword,
} from "@src/logic/forms/validation";
import { RegisterUser } from "@src/logic/http/Api";
import { isAxiosError } from "@src/logic/http/helpers";
import { stopPropagation } from "@src/logic/utils/Events";

interface IRegisterFormProps {}

interface IRegisterFormData {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  companyName: string;
  email: string;
}

const RegisterForm: React.FC<IRegisterFormProps> = () => {
  const newPasswordValidator = composeValidators(required, validPassword);
  const confirmPasswordValidator = matchField<IRegisterFormData>(
    "password",
    "Passwords do not match"
  );

  async function activateAccount(values: IRegisterFormData) {
    if (values) {
      try {
        await RegisterUser({
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          confirmPassword: values.confirmPassword,
          companyName: values.companyName,
          email: values.email,
        });

        await auth.login(values.email, values.password);

        // dashboard after register and login.
        window.location.href = "/";
      } catch (err) {
        if (isAxiosError(err) && err.response && err.response.status === 400) {
          const submitErrors = buildFormErrorsFromModelState(
            values,
            err.response.data
          );
          return submitErrors;
        }
      }
    }
  }

  function validateTerms(value: boolean) {
    return value == null || !value
      ? "You must accept the terms to activate your account."
      : undefined;
  }

  return (
    <Container fluid>
      <Row>
        <Col
          md={4}
          className="d-none d-md-block"
          style={{
            backgroundImage: `url(${SignupLeftImg})`,
            backgroundSize: "cover",
            minHeight: "100vh",
          }}
        />
        <Col className="d-none d-md-block" xs={1} />
        <Col md={6}>
          <Container>
            <Row>
              <Col
                xs={12}
                className="justify-content-center align-items-center text-center"
              >
                <div>
                  <img
                    className="mt-2 mb-2 mx-auto"
                    width="200"
                    src={infopointLogo}
                    alt="InfoPoint Logo"
                  />
                  <h1 className="mt-3 pb-4 mx-auto font-weight-bold">
                    Sign up to InfoPoint
                  </h1>
                </div>
              </Col>

              <Col xs={12}>
                <div>
                  <Form<IRegisterFormData>
                    onSubmit={activateAccount}
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      companyName: undefined,
                      password: "",
                      confirmPassword: "",
                      email: "",
                    }}
                  >
                    {({ handleSubmit }) => (
                      <>
                        <div>
                          <FormGroup>
                            <Label for="firstName">First Name *</Label>
                            <Field
                              id="firstName"
                              name="firstName"
                              component={ValidatedInput}
                              validate={required}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="lastName">Last Name *</Label>
                            <Field
                              id="lastName"
                              name="lastName"
                              component={ValidatedInput}
                              validate={required}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="companyName">Company Name *</Label>
                            <Field
                              id="companyName"
                              name="companyName"
                              component={ValidatedInput}
                              validate={required}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="Email">Email *</Label>
                            <Field
                              id="email"
                              name="email"
                              component={ValidatedInput}
                              validate={required}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="password">Password *</Label>
                            <Field<string, InputProps<string>>
                              id="password"
                              name="password"
                              component={ValidatedInput}
                              type="password"
                              validate={newPasswordValidator}
                            />
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Label for="confirmPassword">
                              Confirm Password *
                            </Label>
                            <Field
                              id="confirmPassword"
                              name="confirmPassword"
                              component={ValidatedInput}
                              type="password"
                              validate={confirmPasswordValidator}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field name="terms" validate={validateTerms}>
                              {(termProps) => {
                                const showError =
                                  termProps.meta.touched &&
                                  !!termProps.meta.error;
                                return (
                                  <>
                                    <ValidatedCheckboxRadio
                                      id="terms-and-conditions"
                                      {...termProps}
                                      label={
                                        <span>
                                          I have read and agreed to the{" "}
                                          <a
                                            href="http://infopoint.com.au/terms-and-conditions/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={stopPropagation}
                                          >
                                            Terms of Service
                                          </a>
                                        </span>
                                      }
                                    />
                                    {showError && (
                                      <span className="form-text text-danger">
                                        {termProps.meta.error}
                                      </span>
                                    )}
                                  </>
                                );
                              }}
                            </Field>
                          </FormGroup>
                        </div>
                        <Button
                          block
                          size="lg"
                          color="primary"
                          className="text-uppercase p-3 font-weight-bold"
                          type="submit"
                          style={{ fontSize: "90%", letterSpacing: ".08rem" }}
                          onClick={handleSubmit}
                        >
                          Sign Up and Login
                        </Button>
                      </>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterForm;
