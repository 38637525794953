import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import ValidatedInput from "@src/components/common/ValidatedInput";
import ValidatedSelect from "@src/components/common/ValidatedSelect";
import useModal, { IModalProps } from "@src/hooks/useModal";
import { required } from "@src/logic/forms/validation";
import NotificationService from "@src/logic/notification/NotificationService";
import { UserInternal } from "@src/types/principal";
import { UserAvatar } from "@src/components/common/Avatar";
import ConfirmContactForm from "./ConfirmContactForm";

import {
  useCompanyGroupsStore,
  useNewRegistrationsStore,
} from "@src/store/admin";
import shallow from "zustand/shallow";
import { UpdateUser } from "@src/logic/http/Api";

interface IUpdateCompanyValues {
  firstName: string;
  lastName: string;
  role: string;
  phone: string;
  email: string;
  companyName: string;
  groups: any;
}

export default function UpdateNewRegistrationForm({
  modalProps,
  user,
}: {
  modalProps: IModalProps;
  user: UserInternal;
}) {
  const confirmContactModal = useModal(false);

  const [refreshNewRegistrations] = useNewRegistrationsStore(
    (s) => [s.refresh],
    shallow
  );

  const [groups, loadGroups] = useCompanyGroupsStore(
    (s) => [s.groups, s.loadGroups, s.refreshGroups],
    shallow
  );

  async function handleSubmit(values: IUpdateCompanyValues): Promise<any> {
    try {
      await UpdateUser(user.id, {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        role: values.role,
        mFAEnabled: user.mfaEnabled,
        groupIds:
          values.groups && values.groups.length > 0
            ? values.groups.map((g) => g.value)
            : [],
      }).then(async () => {
        await refreshNewRegistrations("");
        NotificationService.success("Contact details updated successfully.");
      });
    } catch {
      NotificationService.error(
        "An error occured while updating a contact. Please refresh and try again."
      );
    } finally {
      modalProps.toggle();
    }
  }

  useEffect(() => {
    if (user && user.company && user.company.id) loadGroups(user.company.id);
  }, [user]);

  return (
    <>
      {user && (
        <>
          <ConfirmContactForm
            modalProps={confirmContactModal.modalProps}
            user={user}
          />
          <Modal {...modalProps} size="xl">
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                role: user.role,
                phone: user.phone,
                email: user.email,
                companyName: user?.company?.name,
                groups: user.groups.map((c) => ({
                  label: c.name,
                  value: c.id,
                })),
              }}
            >
              {(formProps) => (
                <>
                  <ModalHeader>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h5 style={{ marginBottom: 0, flexGrow: 1 }}>
                        {user.firstName} {user.lastName}
                      </h5>
                      <Button
                        color="primary"
                        style={{ marginLeft: 5 }}
                        onClick={confirmContactModal.actions.show}
                      >
                        Confirm Contact
                      </Button>
                      <Button
                        color="primary"
                        outline
                        style={{ marginLeft: 5, borderColor: "lightgray" }}
                        disabled={formProps.submitting}
                        onClick={formProps.handleSubmit}
                        id="save-form-btn"
                      >
                        Save
                      </Button>
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <Row className="mb-5 mt-3 ml-3">
                      <Col xs={12}>
                        <UserAvatar
                          size="sm"
                          firstName={user.firstName}
                          lastName={user.lastName}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3 ml-3 mr-3">
                      <Col xs={12} className="mb-3">
                        <h5>Contact Details</h5>
                        <hr />
                      </Col>
                      <Col xs={4} className="mb-2">
                        <FormGroup>
                          <Label for="firstName">First Name *</Label>
                          <Field
                            id="firstName"
                            name="firstName"
                            component={ValidatedInput}
                            validate={required}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={4} className="mb-2">
                        <FormGroup>
                          <Label for="lastName">Last Name *</Label>
                          <Field
                            id="lastName"
                            name="lastName"
                            component={ValidatedInput}
                            validate={required}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={4} className="mb-2">
                        <FormGroup>
                          <Label for="email">Email *</Label>
                          <Field
                            id="email"
                            name="email"
                            component={ValidatedInput}
                            validate={required}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={4} className="mb-2">
                        <FormGroup>
                          <Label for="role">Company</Label>
                          <Field
                            id="companyName"
                            name="companyName"
                            component={ValidatedInput}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={4} className="mb-2">
                        <FormGroup>
                          <Label for="role">Role</Label>
                          <Field
                            id="role"
                            name="role"
                            component={ValidatedInput}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={4} className="mb-2">
                        <FormGroup>
                          <Label for="phone">Phone</Label>
                          <Field
                            id="phone"
                            name="phone"
                            component={ValidatedInput}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={4} className="mb-3">
                        <FormGroup>
                          <Label for="groups">Group</Label>
                          <Field
                            name="groups"
                            component={ValidatedSelect}
                            isClearable
                            isMulti
                            options={
                              user.company && user.company.id && groups
                                ? groups.map((c) => ({
                                    label: c.groupName,
                                    value: c.groupId,
                                  }))
                                : [{}]
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                </>
              )}
            </Form>
          </Modal>
        </>
      )}
    </>
  );
}
