import { UserAccess } from '@src/types/access'
import { IpEntity } from '@src/types/common'
import { CommitmentLink } from '@src/types/costs'
import { BoolDefinition, DateDefinition, NumericDefinition, SelectDefinition, TextDefinition } from '@src/types/metadata'
import { PrincipalBrief } from '@src/types/principal'
import { TrackingEntry } from '@src/types/tracking'
import { TransmittalLink } from '@src/types/transmittal'

export enum DocumentSearchMode {
    Latest = 'latest',
    LatestNonArchived = 'latestNonArchived',
    LatestPublished = 'latestPublished',
    Published = 'published',
    Archived = 'archived'
}

export enum RevisionField {
    Name = 'name',
    Description = 'description',
    RevisionNumber = 'revisionNumber',
    RevisionDate = 'revisionDate',
    UploadedDate = 'uploadedDate',
    Author = 'author',
    Tags = 'tags',
    FileName = 'fileName'
}

export interface IPDocument {
    id: string
    createdDate: Date
    createdBy: string
    updatedDate: Date
    updatedBy: string
    projectId: string
    revisionCount: number
    revisions: Revision[]
    myAccess: UserAccess
    checkedOutBy: PrincipalBrief
}

export interface Revision extends IpEntity {
    documentId: string
    projectId: string
    author: string
    revNumber: string
    revDate: Date
    contentType: string
    fileName: string
    storageObjectName: string
    fileSize: number
    hash: string
    published: boolean
    archived: boolean
    uploadDate: Date
    uploadedBy: PrincipalBrief
    approval: Approval
    metadata: object
    myAccess: UserAccess
    links: ReferenceLinks
    tracking: TrackingEntry[]
}

export interface ReferenceLinks {
    transmittals: TransmittalLink[]
    commitments: CommitmentLink[]
}

export enum ApprovalStatus {
    Pending = 'pending',
    Denied = 'denied',
    Approved = 'approved'
}

export interface Approval {
    status: ApprovalStatus
    approvedBy?: PrincipalBrief
    approvalDate?: Date
    requestedApprover: PrincipalBrief
    requestComments: string
    comments: string
}

export interface DocumentLink {
    name: string
    documentId: string
    revisionId: string
}

export interface DocumentGroupLink {
    name: string
    id: string
}

export type DocumentColumnDefinition =
    BoolDefinition |
    TextDefinition |
    SelectDefinition |
    DateDefinition |
    NumericDefinition
