import React from "react";
import { RouteComponentProps } from "react-router";
import { Card, Col, Container, Row } from "reactstrap";
import RegisterForm from "./RegisterForm";

interface IState {
  token: string;
}

export default class extends React.Component<RouteComponentProps, IState> {
  public render() {
    return (
      <div
        className="d-flex h-100 content__inner"
        style={{
          background:
            "linear-gradient(135deg, rgba(2,21,79,1) 0%, rgba(37,64,146,1) 100%)",
        }}
      >
        <Container fluid>
          <Row className="h-50 justify-content-center align-items-center">
            <Col className="d-none d-md-block" xl={3} />
            <Col className="h-75" xs={12} xl={6}>
              <Card body className="mb-5">
                <RegisterForm />
              </Card>
            </Col>
            <Col className="d-none d-md-block" xl={3} />
          </Row>
        </Container>
      </div>
    );
  }
}
