import React, { MutableRefObject } from 'react'

export function useForwardedRef<T>(ref: MutableRefObject<T> | ((element: T) => any)) {
    const innerRef = React.useRef<T>(null)
    React.useEffect(() => {
        if (!ref) return
        if (typeof ref === 'function') {
            ref(innerRef.current)
        } else {
            ref.current = innerRef.current
        }
    })

    return innerRef
}
