import React from 'react'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { Field, useForm } from 'react-final-form'
import { FieldArray, FieldArrayRenderProps, useFieldArray } from 'react-final-form-arrays'
import { useSelector } from 'react-redux'
import { Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, Container, FormFeedback, ListGroupItem, Row, UncontrolledTooltip } from 'reactstrap'

import { flatMap } from 'lodash'
import * as uuid from 'uuid'

import FA from '@src/components/common/FontAwesomeIcon'
import { IWizardPageProps } from '@src/components/forms/WizardPage'
import { IDocumentSandboxDocument, IDocumentSandboxWizardForm } from '@src/components/sandbox/DocumentSandbox'
import { contentTypeIcon } from '@src/logic/utils/FileFormats'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Sandbox } from '@src/types/sandbox'

const UploadDraggable: React.FC<{ upload: Sandbox.Upload, index: number, asNewDocument?: () => void, removeDoc?: () => void }> = ({ upload, index, asNewDocument, removeDoc }) => {
    return (
        <Draggable draggableId={upload.id} key={upload.id} index={index}>
            {(provided, snapshot) => (
                <li
                    key={upload.id}
                    ref={provided.innerRef}
                    className="list-group-item border rounded-sm shadow-sm my-1"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className="d-flex align-items-center">
                        <FA icon={contentTypeIcon(upload.contentType)} className="mr-2" />
                        <span className="text-truncate text-nowrap mr-4">{upload.filename}</span>
                        {asNewDocument &&
                            <Button id={`sandbox-create-doc-${upload.id}`} onClick={asNewDocument} type="button" size="sm" color="primary" className="ml-auto" aria-label="Add as new document">
                                <FA icon="plus" />
                                <UncontrolledTooltip target={`sandbox-create-doc-${upload.id}`}>New Document</UncontrolledTooltip>
                            </Button>
                        }
                        {removeDoc &&
                            <Button id={`sandbox-remove-doc-${upload.id}`} onClick={removeDoc} type="button" size="sm" color="primary" className="ml-auto" aria-label="Remove from document">
                                <FA icon="times" />
                                <UncontrolledTooltip target={`sandbox-remove-doc-${upload.id}`}>Remove</UncontrolledTooltip>
                            </Button>
                        }
                    </div>
                </li>
            )}
        </Draggable>
    )
}

const FileSelectStage: React.FC<IWizardPageProps<IDocumentSandboxWizardForm>> = () => {
    const uploads = useSelector<RootState, Sandbox.Upload[]>(state => state.sandbox.uploads)
    const toRevise = useSelector<RootState, Revision[]>(state => state.sandbox.toRevise)
    const sandboxProject = useSelector<RootState, Project>(s => s.sandbox.project)
    const documents = useFieldArray<IDocumentSandboxDocument>('documents', {})
    const form = useForm<IDocumentSandboxWizardForm>()

    React.useEffect(
        () => {
            if (sandboxProject && sandboxProject !== form.getState().values.project) {
                toRevise.forEach(r => documents.fields.push(buildDocument(r)))
                form.change('project', sandboxProject)
            }
        },
        [toRevise, sandboxProject]
    )

    function allocatedUploads(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields']): Sandbox.Upload[] {
        return flatMap(fields.value, d => d.uploads)
    }

    function unallocatedUploads(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields']) {
        return uploads.filter(u => allocatedUploads(fields).find(au => au.id === u.id) == null)
    }

    function handleDragToFiles(result: DropResult) {
        if (result.source.droppableId === 'sandbox-files') {
            return
        }

        const id = result.source.droppableId.split('sandbox-documents-')[1]
        const idx = documents.fields.value.findIndex(x => x.id === id)
        documents.fields.update(idx, { ...documents.fields.value[idx], uploads: documents.fields.value[idx].uploads.filter(x => x.id !== result.draggableId) })
    }

    function handleDragToRevisions(result: DropResult) {
        const documentId = result.destination.droppableId.split('sandbox-documents-')[1]
        const idx = documents.fields.value.findIndex(x => x.id === documentId)

        if (result.destination.droppableId === result.source.droppableId) {
            form.mutators.move(`documents[${idx}].uploads`, result.source.index, result.destination.index)
        } else {
            form.mutators.insert(`documents[${idx}].uploads`, result.destination.index, uploads.find(x => x.id === result.draggableId))

            if (result.source.droppableId.startsWith('sandbox-documents-')) {
                const removeFromId = result.source.droppableId.split('sandbox-documents-')[1]
                const removeFromIdx = documents.fields.value.findIndex(x => x.id === removeFromId)
                form.mutators.remove(`documents[${removeFromIdx}].uploads`, result.source.index)
            }
        }
    }

    function handleDragEnd(result: DropResult) {
        if (result.destination == null) return

        if (result.destination.droppableId === 'sandbox-files') {
            return handleDragToFiles(result)
        }

        if (result.destination.droppableId.startsWith('sandbox-documents')) {
            return handleDragToRevisions(result)
        }
    }

    function buildDocument(revision?: Revision, ...uploadsForDocument: Sandbox.Upload[]): IDocumentSandboxDocument {
        return { id: uuid.v4(), existingRevision: revision, uploads: uploadsForDocument }
    }

    function remainingUploadsToNewDocuments() {
        const uploads = unallocatedUploads(documents.fields)
        for (const u of uploads) {
            documents.fields.push(buildDocument(undefined, u))
        }
    }

    function remainingUploadsAsNewDocument() {
        documents.fields.push(buildDocument(undefined, ...unallocatedUploads(documents.fields)))
    }

    function removeDocument(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields'], document: IDocumentSandboxDocument) {
        return () => fields.remove(fields.value.findIndex(x => x.id === document.id))
    }

    function removeUpload(documentIdx: number, uploadId: string) {
        return () => {
            documents.fields.update(documentIdx, { ...documents.fields.value[documentIdx], uploads: documents.fields.value[documentIdx].uploads.filter(x => x.id !== uploadId) })
        }
    }

    function uploadToNewDocument(upload: Sandbox.Upload) {
        return () => {
            const emptyDocIdx = documents.fields.value ? documents.fields.value.findIndex(v => v.uploads.length === 0) : -1

            if (emptyDocIdx > -1) {
                documents.fields.update(emptyDocIdx, { ...documents.fields.value[emptyDocIdx], uploads: [upload] })
            } else {
                documents.fields.push(buildDocument(undefined, upload))
            }
        }
    }

    return (
        <Container fluid className="h-100 d-flex flex-column">
            <h4 className="mb-3">Organise your files into new documents and revisions</h4>
            <FieldArray<IDocumentSandboxDocument> name="documents">
                {({ fields }) => {
                    const unallocated = unallocatedUploads(fields)
                    return (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <Field name="documents">
                                        {({ meta: { error, dirty, touched } }) => touched && typeof error === 'string' && <FormFeedback className="d-block">{error}</FormFeedback>}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="mb-3 flex-grow-1 min-h-0">
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Col md={6} className="mh-100">
                                        <Card className="mh-100">
                                            <CardHeader className="d-flex align-items-center">
                                                <span>Sandbox Files</span>
                                                <span className="ml-auto">
                                                    <ButtonGroup>
                                                        <Button id="sandbox-uploads-to-document-each" color="primary" disabled={unallocated.length === 0} onClick={remainingUploadsToNewDocuments}>
                                                            <FA icon="file" />
                                                            <UncontrolledTooltip target="sandbox-uploads-to-document-each">Each upload to new document</UncontrolledTooltip>
                                                        </Button>
                                                        <Button id="sandbox-uploads-to-single-document" color="primary" disabled={unallocated.length === 0} onClick={remainingUploadsAsNewDocument}>
                                                            <FA icon="copy" />
                                                            <UncontrolledTooltip target="sandbox-uploads-to-single-document">All uploads as revisions</UncontrolledTooltip>
                                                        </Button>
                                                    </ButtonGroup>
                                                </span>
                                            </CardHeader>
                                            <CardBody className="overflow-auto">
                                                <Droppable droppableId="sandbox-files">
                                                    {(provided, snapshot) =>
                                                        <ul ref={provided.innerRef} {...provided.droppableProps} className="list-group list-group-flush p-1">
                                                            {unallocated.map((upload, idx) => <UploadDraggable key={upload.id} upload={upload} index={idx} asNewDocument={uploadToNewDocument(upload)} />)}
                                                            {provided.placeholder}
                                                        </ul>
                                                    }
                                                </Droppable>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={6} className="mh-100">
                                        {fields.length > 0 && <Card body className="h-100 overflow-auto">
                                            {fields.value?.map((doc, idx) => (
                                                <Card className="mb-2" key={doc.id}>
                                                    <CardBody>
                                                        <CardTitle className="d-flex">
                                                            <span>{doc.existingRevision ? <span>{doc.existingRevision.name} <span className="text-muted">({doc.existingRevision.fileName})</span></span> : 'New Document'}</span>
                                                            <span className="ml-auto"><FA role="button" className="pointer" icon="times" onClick={removeDocument(fields, doc)} /></span>
                                                        </CardTitle>
                                                        <Droppable droppableId={`sandbox-documents-${doc.id}`} >
                                                            {(docProvided, docSnapshot) =>
                                                                <ul ref={docProvided.innerRef} {...docProvided.droppableProps} className="list-group list-group-flush border-0 p-1">
                                                                    {doc.uploads.map((upload, uidx) => <UploadDraggable key={upload.id} upload={upload} index={uidx} removeDoc={removeUpload(idx, upload.id)} />)}
                                                                    {docProvided.placeholder}
                                                                </ul>
                                                            }
                                                        </Droppable>
                                                    </CardBody>
                                                </Card>
                                            ))}
                                        </Card>}
                                    </Col>
                                </DragDropContext>
                            </Row>
                        </>
                    )
                }}
            </FieldArray>
        </Container>
    )
}

export default FileSelectStage
