import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { ButtonGroup } from 'reactstrap'

import { flatten } from 'lodash'

import { setDocumentToRevise, toggleSandbox } from '@src/actions/sandbox'
import ClampLines from '@src/components/common/ClampLines'
import FA from '@src/components/common/FontAwesomeIcon'
import Tags from '@src/components/common/Tags'
import DocumentSearchModeComboBox, { SearchMode } from '@src/components/document/section/DocumentSearchModeComboBox'
import FileTypesDropdown, { FileTypes } from '@src/components/document/section/FileTypesDropdown'
import RevisionActions from '@src/components/document/section/RevisionActions'
import RevisionHeadersDropdown from '@src/components/document/section/RevisionHeadersDropdown'
import RevisionLinks from '@src/components/document/section/RevisionLinks'
import RevisionName from '@src/components/document/section/RevisionName'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { ISearchProperty, PropertyType, metadataSearchProperty } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import type { ITableHeader } from '@src/components/table/GenericContentTable'
import useModal from '@src/hooks/useModal'
import useUpdateEffect from '@src/hooks/useUpdateEffect'
import useProjectWidget from '@src/hooks/useWidget'
import { auth } from '@src/logic/auth/AuthService'
import { DocumentCheckin, DocumentCheckout, DocumentGet, DocumentsList, RevisionsDownloadLink, RevisionsList } from '@src/logic/http/Api'
import * as Query from '@src/logic/http/CommonQueryParamters'
import { downloadURL } from '@src/logic/http/Download'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import { pushURLWithParamUpdates } from '@src/logic/search/SearchStateHelpers'
import { localShortDate } from '@src/logic/utils/Date'
import { fileTypeIcon } from '@src/logic/utils/FileFormats'
import { UserAccess } from '@src/types/access'
import { DocumentSearchMode, Revision, RevisionField } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { PrincipalBrief } from '@src/types/principal'
import { Project } from '@src/types/project'
import { Session } from '@src/types/session'

export interface RevisionRow extends Revision {
    canCheckout: boolean
    expandable: boolean
    isExpanded: boolean
    documentAccess: UserAccess
    checkedOutBy?: PrincipalBrief
}

interface IProps {
    project: Project
}

const NoDocumentsFoundMessage = (
    <>
        <div className="my-3"><FA size="3x" icon="file-times" /></div>
        <p className="lead">No documents found with the current search criteria...</p>
        <p>{"Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters."}</p>
    </>
)

const FixedHeaders: ITableHeader<RevisionRow>[] = [
    {
        name: 'Links',
        noSmallHeader: true,
        overrideRenderer: r => <RevisionLinks revision={r} />,
        cellWrapperClass: 'col-4 col-lg-2 order-11 order-lg-2 text-center align-items-center selectable-content__links'
    },
    {
        name: 'Type',
        cellWrapperClass: 'd-none d-lg-table-cell order-1 text-center',
        overrideRenderer: r => <FA icon={fileTypeIcon(r.fileName || '')} />
    }
]

const RevisionPropertyHeadersMap: Record<RevisionField, ITableHeader<RevisionRow>> = {
    [RevisionField.Name]: {
        name: 'Name',
        sortable: true,
        sortKey: 'name',
        noSmallHeader: true,
        cellWrapperClass: 'col-9 col-lg-2 order-1 order-lg-3',
        overrideRenderer: r => <RevisionName revision={r} />
    },
    [RevisionField.Description]: {
        name: 'Description',
        sortable: true,
        sortKey: 'description',
        cellWrapperClass: 'col-4 order-4',
        overrideRenderer: r => <ClampLines text={r.description} lines={3} />
    },
    [RevisionField.RevisionNumber]: {
        name: 'Rev\xa0#',
        sortable: true,
        sortKey: 'revision_number',
        cellWrapperClass: 'col-4 order-4',
        accessor: 'revNumber'
    },
    [RevisionField.Tags]: {
        name: 'Tags',
        sortable: true,
        sortKey: 'tags',
        cellWrapperClass: 'col-4 order-4',
        overrideRenderer: r => <Tags tags={r.tags} />
    },
    [RevisionField.Author]: {
        name: 'Author',
        sortable: true,
        sortKey: 'author',
        cellWrapperClass: 'col-4 order-4',
        accessor: 'author'
    },
    [RevisionField.RevisionDate]: {
        name: 'Revision Date',
        sortable: true,
        sortKey: 'revision_date',
        cellWrapperClass: 'col-4 order-4',
        overrideRenderer: r => localShortDate(r.revDate)
    },
    [RevisionField.UploadedDate]: {
        name: 'Uploaded',
        sortable: true,
        sortKey: 'uploaded',
        cellWrapperClass: 'col-4 order-4',
        overrideRenderer: r => localShortDate(r.uploadDate)
    },
    [RevisionField.FileName]: {
        name: 'File Name',
        sortable: true,
        sortKey: 'file_name',
        cellWrapperClass: 'col-4 order-4',
        overrideRenderer: r => r.fileName
    }
}

function buildRevisionPropertyHeaders(preferredRevisionProperties: RevisionField[]): ITableHeader<RevisionRow>[] {
    return Object.keys(RevisionPropertyHeadersMap).filter(k => preferredRevisionProperties.includes(k as RevisionField)).map(k => RevisionPropertyHeadersMap[k])
}

const defaultAssistantProperties: ISearchProperty[] = [
    {
        name: 'Name',
        searchKey: 'name',
        type: PropertyType.Text
    },
    {
        name: 'Rev #',
        searchKey: 'revision_number',
        type: PropertyType.Text
    },
    {
        name: 'Tags',
        searchKey: 'tags',
        type: PropertyType.Text
    },
    {
        name: 'Created At',
        searchKey: 'created',
        type: PropertyType.Date
    },
    {
        name: 'Revision Date',
        searchKey: 'revision_date',
        type: PropertyType.Date
    },
    {
        name: 'Author',
        searchKey: 'author',
        type: PropertyType.Text
    },
    {
        name: 'File Name',
        searchKey: 'file_name',
        type: PropertyType.Text
    }
]

function buildMetadataHeaders(definitions: IMetadataDefinition[]): ITableHeader<RevisionRow>[] {
    return definitions.map<ITableHeader<RevisionRow>>(d => ({
        name: d.name,
        sortable: true,
        sortKey: d.key,
        cellWrapperClass: 'col-4 order-4',
        overrideRenderer: r => renderMetadata(r.metadata[d.key], d, r.projectId)
    }))
}

const EmptyColBreakHeader: ITableHeader<RevisionRow> = {
    name: '',
    noSmallHeader: true,
    cellWrapperClass: 'd-lg-none order-10 w-100 col-12',
    headerWrapperClass: 'd-none',
    overrideRenderer: () => null
}

function actionsHeader(user: Session.User, handleDownload: (revision: RevisionRow) => void, handleExpand: (revision: RevisionRow) => Promise<void>, handleCheckout: (revision: RevisionRow) => Promise<void>, handleRevise: (revision: RevisionRow) => void): ITableHeader<RevisionRow> {
    return {
        name: 'Actions',
        noSmallHeader: true,
        overrideRenderer: r => <RevisionActions userId={user.id} revision={r} onExpand={handleExpand} onDownload={handleDownload} onCheckout={handleCheckout} onRevise={handleRevise} />,
        headerWrapperClass: 'text-right',
        cellWrapperClass: 'col-8 col-lg-2 order-12 d-flex d-lg-table-cell justify-content-end align-items-baseline text-right selectable-content__actions'
    }
}

function downloadRevision(revision: Revision) {
    downloadURL(RevisionsDownloadLink(auth.getSessionToken(), revision.projectId, revision.id))
}

function fileTypeFilter(fileTypes: string[]) {
    if (fileTypes.length === 0) return ''

    return `file_extension: ${flatten(fileTypes.map(x => FileTypes[x] ? FileTypes[x].ext : [])).join(',')} `
}

async function getMoreRevisions(revision: RevisionRow): Promise<RevisionRow[]> {
    const response = await DocumentGet(revision.documentId, true)
    return response.data.document.revisions.map(r => ({
        ...r,
        canCheckout: r.id === revision.id,
        expandable: r.id === revision.id,
        isExpanded: true,
        documentAccess: response.data.document.myAccess,
        checkedOutBy: response.data.document.checkedOutBy
    }))
}

function getRowClass(revision: RevisionRow) {
    return revision.isExpanded && !revision.expandable ? 'selectable-content__row--subrow' : undefined
}

export default function DocumentsSection({ project }: IProps) {
    const dispatch = useDispatch()
    const searchSectionRef = React.useRef<SearchSectionType<RevisionRow, 'id'>>()
    const user = useSelector<RootState, Session.User>(x => x.session.user)
    const [documentSearchMode, setDocumentSearchMode] = React.useState<SearchMode>(DocumentSearchMode.LatestNonArchived)
    const [fileTypes, setFileTypes] = React.useState<string[]>([])
    const [metadataDefinitions, setMetadataDefinitions] = React.useState<IMetadataDefinition[]>([])
    const [revisionToDownload, setRevisionToDownload] = React.useState<RevisionRow>()
    const history = useHistory()
    const location = useLocation()
    const confirmDownloadModal = useModal(false, { onClose: () => setRevisionToDownload(undefined) })
    const { actions: widgetActions, revisions: widgetRevisions } = useProjectWidget()

    const selectedRevisionIds = React.useMemo(() => widgetRevisions.map(x => x.id), [widgetRevisions])
    const memoHeaders = React.useMemo(() => {
        const { mySettings: { preferredRevisionProperties, preferredMetadata } } = project
        return [
            ...FixedHeaders,
            ...buildRevisionPropertyHeaders(preferredRevisionProperties), ...buildMetadataHeaders(metadataDefinitions.filter(x => preferredMetadata.includes(x.key))),
            EmptyColBreakHeader,
            actionsHeader(user, handleDownloadRevision, handleExpand, handleCheckout, handleRevise)
        ]
    }, [metadataDefinitions, project])
    const searchAssistantProperties = React.useMemo(() =>
        [...defaultAssistantProperties, ...metadataDefinitions.map(d => metadataSearchProperty(d))]
        , [metadataDefinitions])

    const handleSearch = React.useCallback(async (filter: string, sort: string, page: number, perPage: number, abortSignal: AbortSignal) => {
        const extFilter = fileTypeFilter(fileTypes)
        const combinedFilter = fileTypeFilter ? `${extFilter} AND ${filter}` : filter
        if (documentSearchMode === 'allRevisions') {
            const response = await RevisionsList(project.id, combinedFilter, sort, page, perPage, { abortSignal })
            setMetadataDefinitions(response.data.metadataDefinitions)
            return {
                items: response.data.revisions.map<RevisionRow>(x => ({
                    ...x,
                    canCheckout: false,
                    expandable: false,
                    isExpanded: false,
                    documentAccess: { authorisedOperations: [], isAdministrator: false, validOperations: [] },
                    checkedOutBy: undefined
                })),
                totalItems: +response.headers[Headers.PaginationTotalCount]
            }
        } else {
            const response = await DocumentsList(project.id, documentSearchMode, combinedFilter, sort, page, perPage, { abortSignal })
            setMetadataDefinitions(response.data.metadataDefinitions)
            return {
                items: response.data.documents.map<RevisionRow>(x => ({
                    ...x.revisions[0],
                    canCheckout: true,
                    expandable: x.revisionCount > 1,
                    isExpanded: false,
                    documentAccess: x.myAccess,
                    checkedOutBy: x.checkedOutBy
                })),
                totalItems: +response.headers[Headers.PaginationTotalCount]
            }
        }
    }, [fileTypes, documentSearchMode])

    useUpdateEffect(() => {
        pushURLWithParamUpdates(history, location,
            { [Query.DocumentSearchMode]: documentSearchMode },
            documentSearchMode === DocumentSearchMode.LatestNonArchived ? [Query.DocumentSearchMode] : []
        )
    }, [documentSearchMode])

    useUpdateEffect(() => {
        searchSectionRef.current?.doSearch()
    }, [fileTypes])

    function handleRevisionsSelected(...revisions: RevisionRow[]) {
        widgetActions.toggleRevisions({
            entities: revisions.map<Revision>(r => {
                const { ...revision } = r
                return revision
            }),
            projectId: project.id,
            snapTogether: true
        })
    }

    async function handleCheckout(revision: RevisionRow) {
        if (revision.checkedOutBy == null) {
            await DocumentCheckout(revision.documentId)
            NotificationService.info('Checked out document')
            downloadRevision(revision)
        } else {
            await DocumentCheckin(revision.documentId)
            NotificationService.info('Checked in document')
        }

        await searchSectionRef.current?.doSearch()
    }

    function handleDownloadRevision(revision: RevisionRow) {
        if (revision.checkedOutBy == null) {
            downloadRevision(revision)
        } else {
            setRevisionToDownload(revision)
            confirmDownloadModal.actions.show()
        }
    }

    function handleFileTypeChecked(fileType: string) {
        setFileTypes(fileTypes.includes(fileType)
            ? fileTypes.filter(x => x !== fileType)
            : [...fileTypes, fileType]
        )
    }

    function handleRevise(revision: Revision) {
        dispatch(setDocumentToRevise({ project, revisions: [revision] }))
        dispatch(toggleSandbox(true))
    }

    async function handleExpand(revision: RevisionRow) {
        if (revision.isExpanded) {
            searchSectionRef.current.updateData(data => {
                const filtered = data.filter(x => x.documentId !== revision.documentId || x.id === revision.id)
                filtered.find(x => x.id === revision.id).isExpanded = false
                return filtered
            })
        } else {
            const revisions = await getMoreRevisions(revision)
            searchSectionRef.current.updateData(data => {
                data.splice(data.findIndex(x => x.id === revision.id), 1, ...revisions)
                return data
            })
        }
    }

    return (
        <SearchSection<RevisionRow, 'id'>
            ref={searchSectionRef}
            itemIdKey="id"
            headers={memoHeaders}
            searchAssistantProperties={searchAssistantProperties}
            onSearch={handleSearch}
            onItemsSelected={handleRevisionsSelected}
            selectedItems={selectedRevisionIds}
            noItemsFoundMessage={NoDocumentsFoundMessage}
            getRowClass={getRowClass}
            extraSearchBarElements={[
                {
                    element: _ => (
                        <ButtonGroup size="sm">
                            <FileTypesDropdown selectedFileTypes={fileTypes} onCheck={handleFileTypeChecked} />
                            <RevisionHeadersDropdown metadataDefinitions={metadataDefinitions} />
                        </ButtonGroup>
                    ),
                    position: 'before'
                },
                {
                    element: _ => <DocumentSearchModeComboBox value={documentSearchMode} onChange={setDocumentSearchMode} />,
                    position: 'before'
                }
            ]}
        >
            <ConfirmationModal
                {...confirmDownloadModal.modalProps}
                header="Downloading Checked Out Document"
                message={<span>This document has been checked out by {<strong>{revisionToDownload?.checkedOutBy?.name}</strong>}, and they may be making changes to it. Are you sure you want to download?</span>}
                confirmAction="Download"
                onConfirm={() => downloadRevision(revisionToDownload)}
            />
        </SearchSection>
    )
}
