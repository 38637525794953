import React from 'react'
import { connect } from 'react-redux'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'

import { Breakpoint, IBreakpointContext, withBreakpoints } from '@src/components/breakpoint/Breakpoints'
import FA from '@src/components/common/FontAwesomeIcon'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'

interface IProps {
    addRevisions: (revisions: Revision[]) => void
}

interface IConnectedState {
    revisions: Revision[]
}

class AddRevisionsFromWidget extends React.Component<IProps & IConnectedState & IBreakpointContext> {
    private readonly handleSelectAll = () => {
        this.props.addRevisions(this.props.revisions)
    }

    private readonly handleSelectRevision = (e: React.MouseEvent<HTMLElement>) => {
        this.props.addRevisions(e.currentTarget as any)
    }

    public render() {
        const { breakpoints, currentBreakpoint } = this.props

        return (
            <UncontrolledButtonDropdown>
                <Button color="secondary" onClick={this.handleSelectAll} disabled={this.props.revisions.length === 0}><FA icon="plus-circle" /> {breakpoints[currentBreakpoint] > breakpoints[Breakpoint.sm] ? 'Add widget documents' : 'Add docs'}</Button>
                <DropdownToggle color="secondary" caret disabled={this.props.revisions.length === 0} />
                <DropdownMenu>
                    {this.props.revisions.map(r => (
                        <DropdownItem key={r.id} onClick={this.handleSelectRevision}>{r.name}</DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps) {
    return {
        ...ownProps,
        revisions: getActiveProjectWidgetState(state).revisions
    }
}

export default connect(mapStateToProps)(withBreakpoints(AddRevisionsFromWidget))
