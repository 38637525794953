import React from 'react'

import CostValue from '@src/components/costs/common/CostValue'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import { localShortDate } from '@src/logic/utils/Date'
import { CommitmentEntityProps, CommitmentPropertyDefinition } from '@src/types/metadata'

export default function CommitmentPropertyField({ row, col }: IRegisterTableField<CommitmentPropertyDefinition>) {
    const rawValue = row.cells[col.id] as string | Date | number | null
    const formattedValue = React.useMemo(() => {
        switch (col.data.metadataDefinition.options.property) {
            case CommitmentEntityProps.CommitmentNo:
            case CommitmentEntityProps.Description:
            case CommitmentEntityProps.Name:
            case CommitmentEntityProps.Notes:
            case CommitmentEntityProps.OtherParty:
            case CommitmentEntityProps.OtherPartyReference:
            case CommitmentEntityProps.Type:
                return <span className="register__inactive-text-num-span">{rawValue}</span>
            case CommitmentEntityProps.Date:
                return <span className="register__inactive-date-span">{localShortDate(new Date(rawValue))}</span>
            case CommitmentEntityProps.Certified:
            case CommitmentEntityProps.Claimed:
            case CommitmentEntityProps.Paid:
            case CommitmentEntityProps.Value:
                return rawValue != null && typeof rawValue === 'number' ? <span className="register__inactive-text-num-span"><CostValue value={rawValue} /></span> : ''
            default:
                return ''
        }
    },
    [rawValue])

    return <>{formattedValue}</>
}
