// tslint:disable:max-classes-per-file

import React from 'react'

import FA from '@src/components/common/FontAwesomeIcon'

interface ITriggerProps {
    open: boolean
    onToggle: () => void
}

class Trigger extends React.PureComponent<ITriggerProps> {
    public render() {
        return (
            <a className="action-bar__trigger" onClick={this.props.onToggle} role="button">
                <FA icon={this.props.open ? 'minus-square' : 'plus-square'} />
            </a>
        )
    }
}

class Search extends React.PureComponent {
    public render() {
        const { children } = this.props
        return (
            <div className="action-bar__search">
                {children}
            </div>
        )
    }
}

export default class ActionBar extends React.PureComponent<React.HTMLAttributes<HTMLElement>> {
    public static Search = Search
    public static Trigger = Trigger

    public render() {
        const { children, className = '', ...rest } = this.props
        return (
            <div className={'action-bar ' + className} {...rest}>
                {children}
            </div>
        )
    }
}
