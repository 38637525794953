import React from 'react'
import { Form } from 'react-final-form'
import { RouteComponentProps } from 'react-router'

import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'

import { FORM_ERROR } from 'final-form'

import FA from '@src/components/common/FontAwesomeIcon'
import CommunicationForm, { ICommunicationFormData } from '@src/components/communication/CommunicationForm'
import CommunicationToolbar from '@src/components/communication/CommunicationToolbar'
import { UploadCommunicationModal } from '@src/components/communication/UploadCommunicationModal'
import useModal from '@src/hooks/useModal'
import { auth } from '@src/logic/auth/AuthService'
import { CommunicationPreviewPdfLink, CommunicationUpdate, ProjectUsersList } from '@src/logic/http/Api'
import { downloadInNewTab } from '@src/logic/http/Download'
import NotificationService from '@src/logic/notification/NotificationService'
import { Communication } from '@src/types/communication'
import { Project } from '@src/types/project'

interface IProps {
    communication: Communication
    project: Project
    reloadCommunication: (communication: Communication) => void
}

const CommunicationEditPage: React.FC<IProps & RouteComponentProps> = ({ communication, project, reloadCommunication }) => {
    const formalizeModal = useModal()

    async function onCommunicationSave(values: ICommunicationFormData) {
        const updatedCommunication: Communication = {
            ...communication,
            to: values.communication.to,
            cc: values.communication.cc,
            name: values.communication.name,
            body: values.communication.body,
            description: values.communication.description,
            metadata: communication.metadataDefinitions.reduce((agg, curr) => ({ ...agg, [curr.key]: values.metadata[curr.key] ?? null }), {})
        }

        try {
            await CommunicationUpdate(communication.id, {
                name: updatedCommunication.name,
                description: updatedCommunication.description,
                tags: [],
                to: updatedCommunication.to.map(x => x.id || x.email),
                cc: updatedCommunication.cc.map(x => x.id || x.email),
                body: updatedCommunication.body,
                metadata: updatedCommunication.metadata
            })
        } catch {
            NotificationService.error('There was an error while saving the communication.')
            return { [FORM_ERROR]: 'Unable to save communication' }
        }

        reloadCommunication(updatedCommunication)
    }

    async function getProjectUsers(nameFilter: string, limit: number) {
        return (await ProjectUsersList(project.id, `first_name: "${nameFilter}" last_name: "${nameFilter}"`, undefined, 1, limit)).data
    }

    const initialValues: ICommunicationFormData = {
        editing: false,
        communication: {
            name: communication.name,
            description: communication.description,
            to: communication.to,
            cc: communication.cc,
            body: communication.body
        },
        metadata: communication.metadata
    }

    function handlePreviewClick() {
        downloadInNewTab(CommunicationPreviewPdfLink(auth.getSessionToken(), communication.id))
    }

    return (
        <>
            <Form<ICommunicationFormData>
                initialValues={initialValues}
                destroyOnUnregister={false}
                onSubmit={onCommunicationSave}
                subscription={{
                    dirty: true,
                    values: true,
                    initialValues: true,
                    submitting: true
                }}
            >
                {formProps => (
                    <>
                        <CommunicationToolbar project={project} communication={communication}>
                            <UncontrolledButtonDropdown className="mr-2">
                                <Button onClick={handlePreviewClick}>
                                    <FA icon="file-pdf"/>
                                    <span className="d-none d-md-inline-block">&nbsp;Preview</span>
                                </Button>
                                <DropdownToggle split/>
                                <DropdownMenu>
                                    <DropdownItem onClick={formalizeModal.actions.show} >Formalise</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                            <Button color="primary" onClick={formProps.handleSubmit}>
                                <FA icon={formProps.submitting ? 'spinner-third' : formProps.dirty ? 'save' : 'check-circle'}
                                    spin={formProps.submitting}/>
                                <span
                                    className="d-none d-md-inline-block">&nbsp;{formProps.submitting ? 'Saving...' : formProps.dirty ? 'Save' : 'Saved'}</span>
                            </Button>
                        </CommunicationToolbar>
                        <CommunicationForm
                            {...formProps}
                            projectId={project.id}
                            communication={communication}
                            definitions={communication?.metadataDefinitions}
                            getUsers={getProjectUsers}
                        />
                    </>
                )}
            </Form>
            <UploadCommunicationModal {...formalizeModal.modalProps} communication={communication}/>
        </>
    )
}

export default CommunicationEditPage
