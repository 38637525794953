import React from 'react'

interface IModalHook {
    modalProps: IModalProps
    actions: IModalHookActions
}

export interface IModalProps {
    isOpen: boolean
    toggle: () => void
    onClosed?: () => void
    unmountOnClose?: boolean
}

interface IModalHookActions {
    show: () => void
    hide: () => void
    toggle: () => void
}

interface IModalOptions {
    onClose?: () => void
    destroyOnClose?: boolean
}

export default function useModal(initialValue?: boolean, options?: IModalOptions): IModalHook {
    const [open, setOpen] = React.useState<boolean>(initialValue ?? false)

    const show = React.useCallback(() => {
        setOpen(true)
    }, [setOpen])

    const hide = React.useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const toggle = React.useCallback(() => {
        setOpen(isOpen => !isOpen)
    }, [setOpen])

    const actions = React.useMemo(() => ({
        show,
        hide,
        toggle
    }), [show, hide, toggle])

    return {
        actions,
        modalProps: {
            isOpen: open,
            toggle: toggle,
            onClosed: options?.onClose,
            unmountOnClose: options?.destroyOnClose ?? false
        }
    }
}
