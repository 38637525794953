// tslint:disable:jsx-no-lambda
import React from 'react'
import { connect } from 'react-redux'
import { match as Match, Redirect, Route, Switch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import BudgetSection from '@src/components/costs/budget/BudgetSection'
import CommitmentSection from '@src/components/costs/commitments/CommitmentSection'
import CostsToolbar from '@src/components/costs/common/CostsToolbar'
import DashboardSection from '@src/components/costs/dashboard/DashboardSection'
import PaymentSection from '@src/components/costs/payments/PaymentSection'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import * as Routes from '@src/logic/routing/routes'
import { CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IConnectedState {
    project: Project
    costsOverview: CostsOverview
}

class CostsMainPage extends React.Component<IConnectedState> {

    private getCommitmentTypeTitle = (match: Match<{[key: string]: string}>) => {
        const commitmentType = this.props.costsOverview.commitmentDefinitions.find(x => x.code === match.params.type)
        return commitmentType ? commitmentType.name : ''
    }

    public render() {
        const { project } = this.props
        return (
            <>
                <ProjectBanner project={project} />
                <Switch>
                    <Route path={Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL} component={null} />
                    <Route render={routeProps => <CostsToolbar project={project} costsOverview={this.props.costsOverview} currentRoutePath={location.pathname} />} />
                </Switch>
                <Switch>
                    <CrumbRoute title="Dashboard" path={Routes.PROJECT_COSTS_DASHBOARD} render={routeProps => <DashboardSection {...routeProps} costsOverview={this.props.costsOverview} projectId={project.id} />} />
                    <CrumbRoute title="Budget" path={Routes.PROJECT_COSTS_BUDGET} component={BudgetSection} exact />
                    <CrumbRoute title={this.getCommitmentTypeTitle} path={Routes.PROJECT_COSTS_COMMITMENT_TYPE} exact component={CommitmentSection} />
                    <CrumbRoute title="Payments" path={Routes.PROJECT_COSTS_PAYMENTS} component={PaymentSection} />
                    <Redirect to={Routes.projectCostsDashboard(project.id)} />
                </Switch>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active,
        costsOverview: state.projects.activeCostsOverview
    }
}

export default connect(mapStateToProps)(CostsMainPage)
