import React from 'react'
import { Field, useField } from 'react-final-form'
import { useSelector } from 'react-redux'
import { FormGroup, Label, UncontrolledTooltip } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IWizardPageProps } from '@src/components/forms/WizardPage'
import { IDocumentSandboxWizardForm } from '@src/components/sandbox/DocumentSandbox'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { projectLabel, projectValue } from '@src/logic/forms/SelectHelpers'
import { ProjectsList } from '@src/logic/http/Api'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

const SelectProjectStage: React.FC<IWizardPageProps<IDocumentSandboxWizardForm>> = () => {
    const activeProject = useSelector<RootState, Project>(state => state.projects.active)
    const projectField = useField<Project>('project')

    React.useEffect(
        () => {
            if (projectField && activeProject && isAuthorised(activeProject.myAccess, ProjectOperations.CreateDocument)) {
                projectField.input.onChange(activeProject)
            }
        },
        [activeProject])

    async function filterProjects(input: string): Promise<Project[]> {
        const filter = input ? `name: "${input}" code: "${input}"` : undefined
        const response = await ProjectsList(filter, undefined, 1, 10)
        return response.data
    }

    function formatProjectOptionLabel(project: Project) {
        return !isAuthorised(project.myAccess, ProjectOperations.CreateDocument)
            ? (
                <>
                    <UncontrolledTooltip target={`sandbox-project-select-${project.id}`}>No Permission</UncontrolledTooltip>
                    <div>
                        <FA id={`sandbox-project-select-${project.id}`} className="text-warning" icon="exclamation-triangle" />&nbsp;
                        {projectLabel(project)}
                    </div>
                </>
            )
            : (
                <div>
                    {projectLabel(project)}
                </div>
            )
    }

    function projectOptionDisabled(project: Project) {
        return !isAuthorised(project.myAccess, ProjectOperations.CreateDocument)
    }

    return (
        <>
            <h4 className="mb-3">Set shared document details</h4>
            <p>Enter common metadata details for your selected files</p>
            <FormGroup>
                <Label>Project</Label>
                <Field
                    name="project"
                    selectType="async"
                    cacheOptions
                    defaultOptions
                    component={ValidatedSelect}
                    loadOptions={filterProjects}
                    getOptionLabel={projectLabel}
                    getOptionValue={projectValue}
                    formatOptionLabel={formatProjectOptionLabel}
                    isOptionDisabled={projectOptionDisabled}
                />
            </FormGroup>
        </>
    )
}

export default SelectProjectStage
