import React from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { FixedSizeList, areEqual } from 'react-window'

import { DndContext, DragEndEvent, closestCenter, useDroppable, useSensor, useSensors } from '@dnd-kit/core'
import { PointerSensor } from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable'
import produce from 'immer'
import shallow from 'zustand/shallow'

import { ColumnDropZoneSash } from '@src/components/spreadsheet/ColumnDropSash'
import SpreadsheetCell, { ICellRenderProps } from '@src/components/spreadsheet/SpreadsheetCell'
import { ISpreadsheetContext, Provider, createStore, useSpreadsheetStore, useSpreadsheetStoreApi } from '@src/components/spreadsheet/SpreadsheetContext'
import FooterCell, { IFooterRenderProps } from '@src/components/spreadsheet/SpreadsheetFooter'
import SpreadsheetHeader from '@src/components/spreadsheet/SpreadsheetHeader'
import SpreadsheetIndexDropdown from '@src/components/spreadsheet/SpreadsheetIndexDropdown'
import useConstant from '@src/hooks/useConstant'

export interface IColumnHeaderRenderProps<TCol> {
    id: string
    name: string
    data: TCol
}

export interface ISpreadsheetColumn<TCol, TRow> {
    id: string
    name: string
    data: TCol
    styles: {
        width?: number
        backgroundColor?: (col: TCol, row: TRow) => string
    }
    canReorder?: boolean
    CellComponent: React.ElementType<ICellRenderProps<TCol, TRow>>
    HeaderComponent?: React.ComponentType<IColumnHeaderRenderProps<TCol>>
    FooterComponent?: React.ComponentType<IFooterRenderProps<TCol>>
}

interface IProps<TCol, TRow> {
    columns: ISpreadsheetColumn<TCol, TRow>[]
    rows: TRow[]
    getRowId: (row: TRow) => string
    onDeleteRow?: (row: TRow) => void
    onResizeColumn?: (column: ISpreadsheetColumn<TCol, TRow>, width: number) => void
    onReorderColumn?: (column: ISpreadsheetColumn<TCol, TRow>, toIndex: number) => void
    footerIndexCell?: React.ReactElement
    bodyWrapper?: React.ComponentType<React.PropsWithChildren<{}>>
    footerWrapper?: React.ComponentType<React.PropsWithChildren<{}>>
}

export interface ISpreadsheetRef {
    scrollToBottom: () => void
}

interface IRowProps<TCol, TRow> {
    index: number
    style: React.CSSProperties
    data: {
        rows: TRow[]
        columns: ISpreadsheetColumn<TCol, TRow>[]
        onDeleteRow: IProps<TCol, TRow>['onDeleteRow']
        getRowId: IProps<TCol, TRow>['getRowId']
        tableRef: React.RefObject<HTMLDivElement>
    }
}

function Row<TCol, TRow>({ index, style, data: { columns, rows, onDeleteRow, getRowId, tableRef } }: IRowProps<TCol, TRow>) {
    const row = rows[index]
    return (
        <div className="spreadsheet__row" role="row" style={style}>
            <div className="spreadsheet__index-cell">
                <SpreadsheetIndexDropdown index={index + 1} row={row} onDeleteRow={onDeleteRow} />
            </div>
            {columns.map((column) => <SpreadsheetCell getRowId={getRowId} key={column.id} row={row} col={column} spreadsheetPortalTarget={tableRef.current} />)}
        </div>
    )
}

const MemoRow = React.memo(Row, areEqual)
const modifiers = [restrictToHorizontalAxis]

function Spreadsheet<TCol, TRow>({ columns, rows, getRowId, onDeleteRow, onResizeColumn, onReorderColumn, bodyWrapper, footerWrapper, footerIndexCell }: IProps<TCol, TRow>, ref: React.Ref<ISpreadsheetRef>) {
    const [isResizingColumn, focusCell, clearFocus] = useSpreadsheetStore(x => [x.columns.resize.target != null, x.focusCell, x.clearFocus], shallow)
    const orderableColumnsIds = React.useMemo(() => columns.filter(x => x.canReorder).map(x => x.id), [columns.filter(x => x.canReorder).map(x => x.id).join()])
    const spreadsheetApi = useSpreadsheetStoreApi()
    const { height, ref: tableRef } = useResizeDetector<HTMLDivElement>({ handleWidth: false })
    const { height: innerHeight, ref: innerTableRef } = useResizeDetector<HTMLDivElement>({ handleWidth: false })
    const bodyRef = React.useRef<HTMLDivElement>()
    const { setNodeRef: headerRef } = useDroppable({ id: 'columns' })
    const sensors = useSensors(useSensor(
        PointerSensor,
        {
            activationConstraint: {
                distance: 8
            }
        }
    ))
    React.useImperativeHandle(ref, () => ({ scrollToBottom: () => { tableRef.current.scrollTop = tableRef.current.scrollHeight } }))

    const BodyWrapper: React.ElementType = bodyWrapper ?? 'div'
    const FooterWrapper: React.ElementType = footerWrapper ?? 'div'

    // On mount/unmount effects
    React.useEffect(() => {
        function handleClickOutside(e: MouseEvent) {
            if (tableRef.current && !tableRef.current.contains(e.target as Node)) {
                clearFocus()
            }
        }

        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    const Body = React.useMemo(() => (
        <BodyWrapper>
            <FixedSizeList<IRowProps<TCol, TRow>['data']>
                height={rows.length * 40 > height - 76 ? height - 76 : rows.length * 40}
                width="100%"
                style={{ overflowX: 'hidden' }}
                itemCount={rows.length}
                itemSize={40}
                itemData={{ rows, columns, getRowId, onDeleteRow, tableRef }}
            >
                {MemoRow}
            </FixedSizeList>
        </BodyWrapper>
    ), [bodyWrapper, rows, columns, getRowId, onDeleteRow, tableRef])

    const Footer = React.useMemo(() => (
        <FooterWrapper>
            <div className="spreadsheet__add-row-cell" role="cell">{footerIndexCell}</div>
            {columns.map(column => <FooterCell key={column.id} col={column} />)}
        </FooterWrapper>
    ), [columns, footerIndexCell])

    function handleBodyNavigation(e: React.KeyboardEvent) {
        const focus = (spreadsheetApi.getState() as ISpreadsheetContext<TCol, TRow>).focus
        if (focus == null) return

        const rowIdx = rows.indexOf(focus.row)
        const colIdx = columns.indexOf(focus.column)

        switch (e.key) {
            case 'ArrowLeft':
                if (colIdx > 0) focusCell(columns[colIdx - 1], focus.row)
                break
            case 'ArrowRight':
                if (colIdx < columns.length - 1) focusCell(columns[colIdx + 1], focus.row)
                break
            case 'ArrowUp':
                if (rowIdx > 0) focusCell(focus.column, rows[rowIdx - 1])
                break
            case 'ArrowDown':
                if (rowIdx < rows.length - 1) focusCell(focus.column, rows[rowIdx + 1])
                break
            case 'Tab':
                if (colIdx < columns.length - 1) {
                    e.preventDefault()
                    focusCell(columns[colIdx + 1], focus.row)
                } else if (rowIdx < rows.length - 1) {
                    e.preventDefault()
                    focusCell(columns[0], rows[rowIdx + 1])
                } else {
                    clearFocus()
                }
        }
    }

    function handleDragColumn({ active, over }: DragEndEvent) {
        if (active.id !== over.id) onReorderColumn?.(columns.find(x => x.id === active.id), columns.findIndex(x => x.id === over.id))
    }

    return (
        <div className="spreadsheet__table" role="table" ref={tableRef} style={{ userSelect: isResizingColumn ? 'none' : 'initial', scrollBehavior: 'smooth', scrollSnapType: 'y' }}>
            <div style={{ minWidth: 'max-content' }} ref={innerTableRef}>
                <DndContext collisionDetection={closestCenter} modifiers={modifiers} onDragEnd={handleDragColumn} sensors={sensors}>
                    <SortableContext items={orderableColumnsIds} strategy={horizontalListSortingStrategy}>
                        <div className="spreadsheet__header-row" style={{ minWidth: 'max-content' }} role="row" ref={headerRef}>
                            <div className="spreadsheet__top-left-cell"></div>
                            {columns.map(columnProps => <SpreadsheetHeader key={columnProps.id} {...columnProps} onColumnResize={onResizeColumn} />)}
                        </div>
                    </SortableContext>
                    <ColumnDropZoneSash height={innerHeight} />
                </DndContext>
                <div className="spreadsheet__body" onKeyDown={handleBodyNavigation} ref={bodyRef}>
                    {Body}
                </div>
                <div className="spreadsheet__footer" role="row" style={{ minWidth: 'max-content' }}>
                    {Footer}
                </div>
            </div>
        </div>
    )
}

const SpreadsheetWithRef = React.forwardRef(Spreadsheet)

export default React.memo(React.forwardRef(<TCol, TRow>(props: IProps<TCol, TRow>, ref: React.Ref<ISpreadsheetRef>) => {
    const createInitializedStore = useConstant(() => () => {
        const store = createStore()
        store.setState(produce<ISpreadsheetContext<TCol, TRow>>(s => {
            s.columns.widths = props.columns.reduce((agg, col) => ({ ...agg, [col.id]: col.styles.width }), {})
        }))
        return store
    })
    return (
        <Provider createStore={createInitializedStore}>
            <SpreadsheetWithRef {...props} ref={ref} />
        </Provider>
    )
}))
