import React from 'react'
import { Col, ColProps } from 'reactstrap'

import cx from 'classnames'

import Sort from '@src/components/common/Sort'

interface IProps extends ColProps {
    sortField?: string
    onSort?: (sortField: string) => void
    currentSort?: string
}

interface IState {
    revealSort: boolean
}

export default class HeaderCell extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            revealSort: false
        }
    }

    private readonly handleSort = (e: React.MouseEvent<HTMLDivElement>) => {
        const { onClick, onSort, sortField } = this.props
        onSort?.(sortField)
        onClick?.(e)
    }

    private readonly handleMouseEnter = (e) => {
        const { onMouseEnter, onSort } = this.props
        if (onSort) {
            this.setState({ revealSort: true })
        }
        onMouseEnter?.(e)
    }

    private readonly handleMouseLeave = (e) => {
        const { onMouseLeave, onSort } = this.props
        if (onSort) {
            this.setState({ revealSort: false })
        }
        onMouseLeave?.(e)
    }

    public render() {
        const { className, sortField, onSort, currentSort, onMouseEnter, onMouseLeave, onClick, ref, ...rest } = this.props

        return (
            <Col
                className={cx(className, 'selectable-content__cell', { pointer: onSort != null })}
                onClick={this.handleSort}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                {...rest}
            >
                    {this.props.children}
                    {sortField && <>&nbsp;<Sort onClick={this.handleSort} reveal={this.state.revealSort} field={sortField} currentSort={currentSort} /></>}
            </Col>
        )
    }
}
