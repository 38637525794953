import React from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import ValidatedInput from "@src/components/common/ValidatedInput";
import { IModalProps } from "@src/hooks/useModal";
import {
  composeValidators,
  isValidEmail,
  required,
} from "@src/logic/forms/validation";
import NotificationService from "@src/logic/notification/NotificationService";
import { SendUserInvite } from "@src/logic/http/Api";
import { useCompanyUsersStore } from "@src/store/admin";
import shallow from "zustand/shallow";
import ValidatedSelect from "@src/components/common/ValidatedSelect";

interface ISendUserInviteValues {
  firstName: string;
  lastName: string;
  email: string;
  mFAEnabled: { label: string; value: boolean };
  company: { label: string; value: string };
  groups: [{ label: string; value: string }];
}

export default function AddContactForm({
  companies,
  modalProps,
  companyId,
  companyName,
}: {
  companies?: any;
  modalProps: IModalProps;
  companyId?: string;
  companyName?: string;
}) {
  const [refresh] = useCompanyUsersStore((s) => [s.refresh], shallow);

  async function handleSubmit(values: ISendUserInviteValues): Promise<any> {
    try {
      await SendUserInvite({
        companyId: companyId || values.company.value,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      }).then(async (err) => {
        await refresh(companyId || values.company.value);
      });

      NotificationService.success("Contact created successfully.");
    } catch {
      NotificationService.error(
        "An error occured while creating a contact. Please refresh and try again."
      );
    } finally {
      modalProps.toggle();
    }
  }

  return (
    <Modal {...modalProps}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          email: null,
          firstName: null,
          lastName: null,
        }}
      >
        {(formProps) => (
          <>
            <ModalHeader>Invite New Contact</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6} className="mb-3">
                  <Label for="firstName">First Name *</Label>
                  <Field
                    id="firstName"
                    name="firstName"
                    component={ValidatedInput}
                    validate={required}
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Label for="lastName">Last Name *</Label>
                  <Field
                    id="lastName"
                    name="lastName"
                    component={ValidatedInput}
                    validate={required}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Label for="email">Email *</Label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    component={ValidatedInput}
                    validate={composeValidators(required, isValidEmail)}
                  />
                </Col>
                {companyName && (
                  <Col xs={12} className="mb-3">
                    <hr />
                    <Label>Company Name</Label>
                    <h5>{companyName}</h5>
                  </Col>
                )}
                {!companyName && companies && (
                  <Col xs={12} className="mb-3">
                    <Label>Select Company *</Label>
                    <Field
                      name="company"
                      component={ValidatedSelect}
                      isClearable
                      validate={required}
                      options={companies.map((c) => ({
                        label: c.name,
                        value: c.id,
                      }))}
                    />
                  </Col>
                )}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                style={{ borderColor: "lightgray" }}
                onClick={modalProps.toggle}
                className="w-25"
              >
                Cancel
              </Button>
              <Button
                disabled={formProps.submitting}
                color="primary"
                className="w-25"
                onClick={formProps.handleSubmit}
              >
                Send Invite
              </Button>
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
}
