import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, FormRenderProps } from 'react-final-form'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Collapse, Container, FormGroup, FormText, Input, Label, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import LinkAction from '@src/components/common/LinkAction'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import DocumentEditor, { IOverlayMessageRenderProps } from '@src/components/communication/DocumentEditor'
import { FieldPrefix, PrefixedField } from '@src/components/forms/FieldPrefix'
import { FFMetadataInput } from '@src/components/metadata/MetadataInputs'
import MetadataTooltip from '@src/components/metadata/MetadataTooltip'
import useBoolean from '@src/hooks/useBoolean'
import { BuildCommunicationContext } from '@src/logic/autotexts/DropkiqContext'
import { transmittalAddressLabel, transmittalAddressValue } from '@src/logic/forms/SelectHelpers'
import { CommunicationPreview, TemplateGet } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import { isAbortError } from '@src/logic/utils/Abort'
import { validateEmail } from '@src/logic/utils/Strings'
import { Communication, Template } from '@src/types/communication'
import { IMetadataDefinition, IMetadataForm } from '@src/types/metadata'
import { UserBasic } from '@src/types/principal'
import { TransmittalAddress } from '@src/types/transmittal'

interface IProps extends IMetadataForm {
    communication: Communication
    projectId: string
    getUsers: (name: string, limit: number) => Promise<UserBasic[]>
}

export interface ICommunicationFormData {
    editing: boolean
    communication: {
        name: string
        description: string
        to: TransmittalAddress[]
        cc: TransmittalAddress[]
        body: string
    }
    metadata: {
        [key: string]: any
    }
}

function buildPreviewError(overlayProps: IOverlayMessageRenderProps, error: Error, hasOldPreview: boolean): React.ReactNode {
    if (error == null) return null
    let newPlaceholder: React.ReactNode = `An error occurred while previewing the communication. ${hasOldPreview ? 'Displaying last successful preview.' : ''}`
    if (isAxiosError(error)) {
        const message: string = error.response?.data?.message
        switch (error.response.status) {
            case 400:
                if (error.response.data.message == null) {
                    newPlaceholder = 'Unable to preview communication, check all required fields are complete.'
                    break
                }
                // eslint-disable-next-line no-case-declarations
                const matches: RegExpMatchArray = message.match(/at \(([0-9]+):([0-9]+)\)/)

                if (matches.length === 3) {
                    const data: [number, number] = [parseInt(matches[1]), parseInt(matches[2])]
                    newPlaceholder = (
                        <pre className="text-light" style={{ whiteSpace: 'pre-wrap', overflow: 'visible' }}>
                            <LinkAction data={[data[0], data[1]]} onClick={overlayProps.navigateToLineCol}>{message}</LinkAction>
                        </pre>
                    )
                } else {
                    newPlaceholder = message
                }
                break
        }
        return newPlaceholder
    }
}

const CommunicationForm: React.FC<IProps & FormRenderProps<ICommunicationFormData>> = ({ communication, definitions, projectId, getUsers, ...formProps }) => {
    const basicInfoCollapsed = useBoolean(false)
    const metadataCollapsed = useBoolean(false)
    const templateAsync = useAsyncAbortable(
        async (abortSignal, templateId) => templateId != null ? (await TemplateGet(templateId, { abortSignal })).data : await Promise.resolve<Template>(null),
        [communication.templateReference?.id],
        {
            setLoading: s => ({ ...s, loading: true })
        }
    )

    const previewAsync = useAsyncAbortable(
        async (abortSignal) => {
            return (await CommunicationPreview(
                communication.id,
                {
                    overrideBody: formProps.values.communication?.body ?? null, overrideMetadata: formProps.values.metadata
                },
                { abortSignal }
            )).data
        },
        [],
        {
            setLoading: s => ({ ...s, loading: true }),
            setError: (error, s) => {
                return ({ ...s, error: isAbortError(error) ? s.error : error })
            }
        }
    )

    function renderMetadataDefinitionField(definition: IMetadataDefinition) {
        return (
            <Col md="auto" key={definition.key}>
                <FormGroup>
                    <Label>{definition.name} <MetadataTooltip id={`comms-meta-tooltip-${definition.key}`} definition={definition} placement="right" /></Label>
                    <FFMetadataInput name={`metadata.${definition.key}`} definition={definition} />
                    {definition.description && <FormText>{definition.description}</FormText>}
                </FormGroup>
            </Col>
        )
    }

    async function loadUserOptions(input: string): Promise<TransmittalAddress[]> {
        const users = await getUsers(input, 100)
        return users.map<TransmittalAddress>(u => ({
            email: u.email,
            id: u.id,
            name: `${u.firstName} ${u.lastName}`
        }))
    }

    function isValidEmailOption(option: any, selectValue, options) {
        return validateEmail(option.label)
    }

    return (
        <Container fluid>
            <Row>
                <Col className="mb-3" md={4}>
                    <Card>
                        <CardHeader onClick={basicInfoCollapsed.toggle} className="d-flex align-items-center"><FA icon="info-circle" className="mr-2" />Basic Info<FA icon={basicInfoCollapsed.value ? 'chevron-up' : 'chevron-down'} className="ml-auto pointer" /></CardHeader>
                        <Collapse isOpen={!basicInfoCollapsed.value}>
                            <CardBody>
                                <FieldPrefix prefix="communication">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <PrefixedField name="name" bsSize="sm" component={ValidatedInput} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Communication Number</Label>
                                                <Input bsSize="sm" disabled readOnly value={communication.communicationNo} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Description</Label>
                                                <PrefixedField bsSize="sm" name="description" component={ValidatedInput} type="textarea" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>To</Label>
                                                <PrefixedField size="sm" name="to" component={ValidatedSelect} selectType="async-creatable" defaultOptions cacheOptions loadOptions={loadUserOptions} isMulti isValidNewOption={isValidEmailOption} getOptionLabel={transmittalAddressLabel} getOptionValue={transmittalAddressValue} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Cc</Label>
                                                <PrefixedField size="sm" name="cc" component={ValidatedSelect} selectType="async-creatable" defaultOptions cacheOptions loadOptions={loadUserOptions} isMulti isValidNewOption={isValidEmailOption} getOptionLabel={transmittalAddressLabel} getOptionValue={transmittalAddressValue} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </FieldPrefix>
                            </CardBody>
                        </Collapse>
                    </Card>
                    <Card className="mt-3">
                        <CardHeader onClick={metadataCollapsed.toggle} className="d-flex align-items-center"><FA icon="brackets-curly" className="mr-2" />Communication Data<FA icon={metadataCollapsed.value ? 'chevron-up' : 'chevron-down'} className="ml-auto pointer" /></CardHeader>
                        <Collapse isOpen={!metadataCollapsed.value}>
                            <CardBody>
                                <FieldPrefix prefix="metadata">
                                    {definitions.map(renderMetadataDefinitionField)}
                                </FieldPrefix>
                            </CardBody>
                        </Collapse>
                    </Card>
                </Col>
                <Col style={{ flexBasis: '21cm' }} className="flex-grow-1 flex-shrink-0 mb-3">
                    <Card>
                        <CardHeader className="d-flex align-items-center">
                            <span>Body</span>
                            <div className="ml-auto">
                                <Field<boolean> name="editing" subscription={{ value: true }}>
                                    {(togglePreview) => (
                                        <ButtonGroup className="mr-2">
                                            <Button color="primary" active={!togglePreview.input.value} onClick={() => togglePreview.input.onChange(false)}><FA icon="eye" className="mr-1" />Preview</Button>
                                            <Button color="primary" active={togglePreview.input.value} onClick={() => togglePreview.input.onChange(true)}><FA icon="pencil" className="mr-1" />Edit</Button>
                                        </ButtonGroup>
                                    )}
                                </Field>
                            </div>
                        </CardHeader>
                        <CardBody className="p-0">
                            <Field<boolean> name="editing" subscription={{ value: true }}>
                                {(togglePreview) => (
                                    <Field<string> name="communication.body">
                                        {(props) => <DocumentEditor
                                            value={props.input.value}
                                            preview={previewAsync.result}
                                            context={BuildCommunicationContext(communication.metadataDefinitions)}
                                            isEditing={togglePreview.input.value}
                                            onChange={props.input.onChange}
                                            onRefreshPreview={previewAsync.execute}
                                            onToggleEditing={togglePreview.input.onChange}
                                            renderOverlayMessage={(props) => buildPreviewError(props, previewAsync.error, previewAsync.result != null)}
                                        />}
                                    </Field>
                                )}
                            </Field>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CommunicationForm
