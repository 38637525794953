import React from 'react'

import { ISpreadsheetColumn } from '@src/components/spreadsheet/Spreadsheet'
import { useSpreadsheetStore } from '@src/components/spreadsheet/SpreadsheetContext'
import { useColumnWidthListener } from '@src/components/spreadsheet/useColumnWidthListener'

export interface IFooterRenderProps<TCol> {
    col: ISpreadsheetColumn<TCol, unknown>
}

export default function FooterCell<TCol>({ col }: IFooterRenderProps<TCol>) {
    const Cell = col.FooterComponent
    const clearFocus = useSpreadsheetStore(x => x.clearFocus)
    const ref = React.useRef<HTMLDivElement>()
    useColumnWidthListener(col.id, ref)

    return (
        <div className="spreadsheet__footer-cell" role="cell" onClick={clearFocus} ref={ref}>
            {Cell ? <Cell col={col} /> : <span />}
        </div>
    )
}
