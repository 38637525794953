import { FieldState } from 'final-form'

import { localShortDate } from '@src/logic/utils/Date'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import { AclEntry } from '@src/types/access'
import { Sandbox } from '@src/types/sandbox'

export function required<T>(value: T) {
    if (value == null) { return 'Required' }

    let failed = false

    switch (typeof value) {
        case 'string':
            if (value.trim() === '') {
                failed = true
            }
            break
        case 'number':
            if (isNaN(value)) {
                failed = true
            }
            break
    }

    return failed ? 'Required' : undefined
}

export function isNumber<T>(value: T) {
    if (value == null || typeof value === 'number') return undefined

    const failed = typeof value === 'string' ? isNaN(Number(value)) : true

    return failed ? 'Must be valid number' : undefined
}

export function nonNegative(value: number | null) {
    if (value == null) return undefined

    let failed = false

    if (typeof value === 'number') {
        failed = value < 0
    } else if (typeof value === 'string') {
        const parsed = Number(value)
        if (!isNaN(parsed)) {
            failed = parsed < 0
        }
    }

    return failed ? 'Must be non-negative' : undefined
}

export function validPassword(value: string) {
    if (!value) {
        return 'Required'
    }

    const upper = +(/[A-Z]/.test(value))
    const lower = +(/[a-z]/.test(value))
    const number = +(/\d/.test(value))
    const character = +(/\W/.test(value))

    if (upper + lower + number + character < 3) {
        return 'Requires 3 of uppercase, lowercase, number, and special character'
    }
    if (value.length < 8) {
        return 'Needs to be at least 8 characters'
    }

    return undefined
}

export function matchField<TFormValues>(field: keyof TFormValues, message: string) {
    return <TValue extends TFormValues[typeof field]>(value: TValue, allValues: TFormValues) => value === allValues[field] ? undefined : message
}

export function aclHasAdmin(value: AclEntry[]) {
    if (value == null || value === [] || value.findIndex(e => e.isAdministrator) < 0) {
        return 'Access table requires at least one admin'
    }

    return undefined
}

export function isOnOrAfter(value: Date, comperand: Date) {
    if (value == null || comperand == null) return undefined

    return value >= comperand ? undefined : `Must be on or after ${localShortDate(comperand)}`
}

export function isMetadataKeyForm(value: string) {
    return /^[a-zA-Z1-9_-]*$/.test(value) ? undefined : 'Can only contain alpha-numeric, -, or _ characters.'
}

export function isFileNameForm(value: string) {
    return /^[\w,\s-]*$/.test(value) ? undefined : 'Can only contain alpha-numeric, -, or _ characters.'
}

export function uploadComplete(value: Sandbox.Upload) {
    return value == null || value.state !== 'completed' ? 'Upload not finished' : undefined
}
 
export function composeValidators<TFieldValue = any, TFormValues = object>(...validators: ((value: TFieldValue, allValues: TFormValues, meta?: FieldState<TFieldValue>) => string)[]) {
    return (value: TFieldValue, allValues: TFormValues, meta?: FieldState<TFieldValue>) => validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)
}

export const invalidABNFormatMessage = 'Must be a valid 11 digit ABN.'
const abnWeight: number[] = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]

export function isValidABN(value: string) {
    if (isNullOrEmpty(value) || !(/^\d{11}$/.test(value))) return invalidABNFormatMessage

    let weightedSum = 0

    for (let i = 0; i <= 10; i++) {
        weightedSum += ((parseInt(value[i]) - ((i === 0 ? 1 : 0))) * abnWeight[i])
    }

    if (weightedSum % 89 !== 0) return invalidABNFormatMessage
}

export function isValidEmail(value: string)
{ 
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? undefined : 'Should be a valid email address.';
}
