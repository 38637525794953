import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

import { activeProject } from '@src/actions/project'
import DropdownCheckOptions, { IDropdownCheckOption } from '@src/components/common/DropdownCheckOptions'
import FA from '@src/components/common/FontAwesomeIcon'
import { ProjectMySettingsUpdate } from '@src/logic/http/Api'
import { RevisionField } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

const revisionPropertyHeaderSection = 'Revision Property'
const metadataHeaderSection = 'Metadata'

function headers(visibleRevisionProps: RevisionField[]) {
    return [
        {
            section: revisionPropertyHeaderSection,
            label: 'Name',
            key: RevisionField.Name,
            checked: visibleRevisionProps.includes(RevisionField.Name)
        },
        {
            section: revisionPropertyHeaderSection,
            label: 'Description',
            key: RevisionField.Description,
            checked: visibleRevisionProps.includes(RevisionField.Description)
        },
        {
            section: revisionPropertyHeaderSection,
            label: 'Rev #',
            key: RevisionField.RevisionNumber,
            checked: visibleRevisionProps.includes(RevisionField.RevisionNumber)
        },
        {
            section: revisionPropertyHeaderSection,
            label: 'Author',
            key: RevisionField.Author,
            checked: visibleRevisionProps.includes(RevisionField.Author)
        },
        {
            section: revisionPropertyHeaderSection,
            label: 'Tags',
            key: RevisionField.Tags,
            checked: visibleRevisionProps.includes(RevisionField.Tags)
        },
        {
            section: revisionPropertyHeaderSection,
            label: 'Revision Date',
            key: RevisionField.RevisionDate,
            checked: visibleRevisionProps.includes(RevisionField.RevisionDate)
        },
        {
            section: revisionPropertyHeaderSection,
            label: 'Uploaded',
            key: RevisionField.UploadedDate,
            checked: visibleRevisionProps.includes(RevisionField.UploadedDate)
        },
        {
            section: revisionPropertyHeaderSection,
            label: 'File Name',
            key: RevisionField.FileName,
            checked: visibleRevisionProps.includes(RevisionField.FileName)
        }
    ]
}

interface IProps {
    metadataDefinitions: IMetadataDefinition[]
}

export default function RevisionHeadersDropdown({ metadataDefinitions }: IProps) {
    const dispatch = useDispatch()
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const settings = project.mySettings
    const headerOptions = [
        ...headers(settings.preferredRevisionProperties),
        ...metadataDefinitions.map(md => ({ section: metadataHeaderSection, label: md.name, key: md.key, checked: settings.preferredMetadata.includes(md.key) }))
    ]

    const updateSettingsAsync = useAsyncAbortable(async (abortSignal, metadata: string[], revisionProperties: RevisionField[]) => {
        dispatch(activeProject({ ...project, mySettings: { ...project.mySettings, preferredMetadata: metadata, preferredRevisionProperties: revisionProperties } }))
        await ProjectMySettingsUpdate(
            project.id,
            {
                preferredMetadata: metadata,
                preferredRevisionProperties: revisionProperties
            },
            { abortSignal })
    }, [[], []], { executeOnMount: false, executeOnUpdate: false })

    function handleHeaderChecked(e, option: IDropdownCheckOption) {
        if (option.section === revisionPropertyHeaderSection) {
            const { preferredRevisionProperties } = settings
            updateSettingsAsync.execute(
                settings.preferredMetadata,
                preferredRevisionProperties.includes(option.key as RevisionField)
                    ? preferredRevisionProperties.filter(h => h !== option.key)
                    : [...preferredRevisionProperties, option.key as RevisionField]
            )
        } else if (option.section === metadataHeaderSection) {
            const { preferredMetadata } = settings
            updateSettingsAsync.execute(
                preferredMetadata.includes(option.key)
                    ? preferredMetadata.filter(h => h !== option.key)
                    : [...preferredMetadata, option.key],
                settings.preferredRevisionProperties
            )
        }
    }
    return (
        <>
            <DropdownCheckOptions
                caret
                isSearchable
                title={<FA icon="filter" />}
                sectionHeaders={['Revision Property', 'Metadata']}
                options={headerOptions}
                toggleProps={{ color: 'default', outline: true, id: 'document-search-columns' }}
                onCheck={handleHeaderChecked}
            />
            <UncontrolledTooltip target="document-search-columns" delay={{ show: 1000, hide: 0 }}>Columns</UncontrolledTooltip>
        </>
    )
}
