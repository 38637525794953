import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Form } from 'react-final-form'
import { Card, Container } from 'reactstrap'

import { FormApi } from 'final-form'
import { produce } from 'immer'
import { get, groupBy } from 'lodash'
import { v4 } from 'uuid'
import shallow from 'zustand/shallow'

import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import SearchBar from '@src/components/common/SearchBar'
import AutoSave from '@src/components/forms/AutoSave'
import AddColumnHeader from '@src/components/register/AddColumnHeader'
import EditColumnDropdown from '@src/components/register/EditColumnDropdown'
import { determineRendererForFooterType, determineRendererForType } from '@src/components/register/tablefields/FieldRenderer'
import { metadataSearchProperty } from '@src/components/search/SearchAssistant'
import Spreadsheet, { ISpreadsheetColumn, ISpreadsheetRef } from '@src/components/spreadsheet/Spreadsheet'
import useFormApi from '@src/hooks/useFormApi'
import { RegisterColumnMove, RegisterColumnUpdate, RegisterGetById, RegisterRowPatch, RegisterRowsAdd, RegisterRowsDelete } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import useRegisterStore from '@src/store/register'
import { MetadataTypes } from '@src/types/metadata'
import { RegisterColumn, RegisterColumnDefinition, RegisterRow } from '@src/types/register'

interface IUpdateRegisterRowsForm {
    cells: {
        [rowId: string]: {
            [colId: string]: string
        }
    }
}

interface INewRegisterRowForm {
    cells: {
        [columnId: string]: unknown
    }
}

function getRegisterRowId(row: RegisterRow) {
    return row.id
}

const linkTypes = [MetadataTypes.DocumentLinks, MetadataTypes.CommitmentLinks, MetadataTypes.CompanyLinks, MetadataTypes.EmailLinks, MetadataTypes.TransmittalLinks, MetadataTypes.PaymentClaimLinks, MetadataTypes.UserLinks]
const linkedCellColour = '#B9EDB1'
const notLinkedCellColour = '#CAE6FF'

function getCellBackgroundColour(column: RegisterColumn, row: RegisterRow) {
    let backgroundColor = ''
    if (linkTypes.includes(column.metadataDefinition.type)) {
        // If an item is linked, set the colour to green, if not set it to blue
        row.cells[column.key]
            ? ((row.cells[column.key] as []).length ? backgroundColor = linkedCellColour : backgroundColor = notLinkedCellColour)
            : backgroundColor = ''
    }
    return backgroundColor
}

export default function RegisterDataPage() {
    const [filter, setFilter] = React.useState<string>('')
    const spreadsheetRef = React.useRef<ISpreadsheetRef>()
    const [register, updateRegister] = useRegisterStore(s => [s.register, s.update], shallow)
    const addRowForm = useFormApi({ onSubmit: handleAddNewRow })

    async function handleSearch(abortSignal: AbortSignal, registerId: string) {
        const response = await RegisterGetById(registerId, filter, undefined, 1, 200, { abortSignal })
        return response.data.data.rows
    }

    const rowsAsync = useAsyncAbortable(
        handleSearch,
        [register.id, register.columns],
        {
            initialState: o => ({ result: [], error: undefined, loading: false, status: 'not-requested' }),
            setLoading: s => ({ ...s, loading: true, status: 'loading' }),
            setError: error => ({ error, loading: false, status: 'error', result: [] })
        }
    )

    const columns = React.useMemo(() => register.columns.map(column => {
        const col: ISpreadsheetColumn<RegisterColumn, RegisterRow> = {
            id: column.key,
            name: column.metadataDefinition.name,
            data: column,
            styles: {
                width: column.styles.width,
                backgroundColor: getCellBackgroundColour
            },
            canReorder: true,
            CellComponent: determineRendererForType(column),
            HeaderComponent: EditColumnDropdown,
            FooterComponent: determineRendererForFooterType(column)
        }
        return col
    }).concat([{
        id: '__add-register-column',
        name: '',
        styles: {},
        data: null,
        canReorder: false,
        CellComponent: () => <span></span>,
        HeaderComponent: AddColumnHeader
    }]), [register.columns])

    const searchAssistantProperties = React.useMemo(() => {
        return register.columns.map(c => metadataSearchProperty(c.metadataDefinition))
    }, [register.columns])

    const updateRegisterRow = React.useCallback(async (values: IUpdateRegisterRowsForm, form: FormApi<IUpdateRegisterRowsForm>) => {
        const dirtyFields = form.getState().dirtyFields
        const dirtyRows = groupBy(Object.keys(dirtyFields), x => x.split('.')[1])
        try {
            let newRowsResult = rowsAsync.result
            for (const prefixedRowId in dirtyRows) {
                const rowId = prefixedRowId.substring(2)
                const cells = dirtyRows[prefixedRowId].reduce((agg, dirtyField) => {
                    return ({
                        ...agg,
                        [dirtyField.split('.c-')[1]]: get(values, dirtyField)
                    })
                }, {})
                await RegisterRowPatch(register.id, rowId, { cells })
                newRowsResult = produce(newRowsResult, draft => {
                    const idx = draft.findIndex(x => x.id === rowId)
                    if (idx < 0) return
                    draft[idx].cells = { ...draft[idx].cells, ...cells }
                })
            }
            rowsAsync.merge({ result: newRowsResult })
            NotificationService.info('Saved register rows')
        } catch {
            NotificationService.error('An error occured while updating the row')
        }
    }, [rowsAsync.result, register.id])

    async function handleAddNewRow(values: INewRegisterRowForm): Promise<void> {
        if (values.cells == null) return
        const newRow = {
            cells: Object.keys(values.cells).reduce((agg, c) => ({ ...agg, [c.substring(2)]: values.cells[c] }), {})
        }
        try {
            await RegisterRowsAdd(register.id, [newRow])
            spreadsheetRef.current.scrollToBottom()
        } catch (e) {
            NotificationService.error('An error occured while creating a new register row')
            throw e
        }

        rowsAsync.merge({ result: [...rowsAsync.result, { id: v4(), cells: newRow.cells }] })
        await rowsAsync.execute(register.id)
        addRowForm.reset()
    }

    const handleDeleteRow = React.useCallback(async (row: RegisterRow) => {
        try {
            await RegisterRowsDelete(register.id, [row.id])
            NotificationService.info('Row deleted')
        } catch (error) {
            if (isAxiosError(error)) {
                switch (error.response?.status) {
                    case 403:
                        NotificationService.error('No permission to delete rows')
                        break
                    case 404:
                        break
                    default:
                        // There was reponse, it was an error (i.e. 400+), something bad...
                        NotificationService.error('Unable to delete row')
                }
            }
        }

        const rows = produce(rowsAsync.result, draft => {
            const index = draft.findIndex(r => r.id === row.id)
            if (index !== -1) draft.splice(index, 1)
        })

        rowsAsync.merge({ result: [...rows] })
    }, [rowsAsync.merge, rowsAsync.result, register.id])

    const handleResizeColumn = React.useCallback(async (column: ISpreadsheetColumn<RegisterColumnDefinition, RegisterRow>, width: number) => {
        const columns = produce(register.columns, draft => {
            const colToUpdate = draft.find(x => x.key === column.id)
            if (column == null) return

            colToUpdate.styles.width = width
        })

        const updatedColumn = columns.find(x => x.key === column.id)
        await RegisterColumnUpdate(register.id, column.id, { metadataDefinition: updatedColumn.metadataDefinition, styles: updatedColumn.styles })

        updateRegister({ columns })
    }, [register.id, register.columns])

    const handleReorderColumn = React.useCallback(async (column: ISpreadsheetColumn<RegisterColumnDefinition, RegisterRow>, index: number) => {
        const columns = produce(register.columns, draft => {
            const [col] = draft.splice(draft.findIndex(x => x.key === column.id), 1)
            draft.splice(index, 0, col)
        })

        updateRegister({ columns })

        const position = index === 0 ? 'first' : `after:${columns[index - 1].key}`

        await RegisterColumnMove(register.id, column.id, position)
    }, [register.id, register.columns])

    const BodyWrapper = React.useCallback(({ children }) => (
        <Form onSubmit={updateRegisterRow} subscription={{}}>
            {() => (
                <>
                    {children}
                    <AutoSave wait={3000} />
                </>
            )}
        </Form>
    ), [updateRegisterRow])

    const FooterWrapper = React.useCallback(({ children }) => (
        <Form subscription={{}} form={addRowForm} onSubmit={handleAddNewRow}>
            {() => children}
        </Form>
    ), [handleAddNewRow])

    const FooterIndexCell = React.useMemo(() => <button className="spreadsheet__button mx-auto" onClick={addRowForm.submit}><FA icon="plus" size="xs" /></button>, [])

    if (register.columns.length === 0) {
        return (
            <Container fluid>
                <Card body className="text-center mt-3">
                    <div className="my-3"><FA size="3x" icon="columns" /></div>
                    <p className="lead"><div style={{ display: 'inline-block' }}><AddColumnHeader /></div></p>
                    <p>{'This register does not have any columns configured.'}</p>
                </Card>
            </Container>
        )
    }

    return (
        <>
            <ActionBar className="border-bottom">
                <ActionBar.Search>
                    <SearchBar
                        onChange={setFilter}
                        value={filter}
                        onSubmit={() => rowsAsync.execute(register.id)}
                        placeholder={'Search...'}
                        submitting={rowsAsync.loading}
                        searchAssistant
                        availableProperties={searchAssistantProperties}
                    />
                </ActionBar.Search>
            </ActionBar>
            <Spreadsheet
                ref={spreadsheetRef}
                columns={columns}
                rows={rowsAsync.result}
                getRowId={getRegisterRowId}
                onDeleteRow={handleDeleteRow}
                onResizeColumn={handleResizeColumn}
                onReorderColumn={handleReorderColumn}
                bodyWrapper={BodyWrapper}
                footerWrapper={FooterWrapper}
                footerIndexCell={FooterIndexCell}
            />
        </>
    )
}
