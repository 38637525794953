import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import PaymentClaimItemsTableForm from '@src/components/costs/payments/PaymentClaimItemsTableForm'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { PaymentClaimGet } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { CostsOverview, PaymentClaim } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

function aggregatePaymentData(payment?: PaymentClaim) {
    return payment.items.reduce(
        (agg, item) => {
            agg.totalCompleted += (item.certified + item.previouslyCertified)
            agg.totalCertifiedPrevious += item.previouslyCertified
            agg.totalCertified += item.certified
            agg.gst += (item.gst ? item.gst * item.certified : 0)
            return agg
        },
        {
            totalCompleted: 0,
            totalCertifiedPrevious: 0,
            totalCertified: 0,
            gst: 0
        }
    )
}

const PaymentClaimDetailPage: React.FC = () => {
    const match = useRouteMatch<Routes.IProjectCostsPaymentDetailParams>()

    const project = useSelector<RootState, Project>(s => s.projects.active)
    const costsOverview = useSelector<RootState, CostsOverview>(s => s.projects.activeCostsOverview)

    const paymentClaimAsync = useAsyncAbortable(
        (abortSignal, pid, cid, payId) => PaymentClaimGet(pid, cid, payId, { abortSignal })
            .then(res => ({
                paymentClaim: res.data,
                aggregateItemData: aggregatePaymentData(res.data)
            })
        ),
        [project.id, match.params.commitmentId, match.params.paymentClaimId],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    function handleReloadPaymentClaim(payment?: PaymentClaim) {
        if (payment == null) {
            return paymentClaimAsync.execute(project.id, match.params.commitmentId, match.params.paymentClaimId)
        } else {
            paymentClaimAsync.set({ ...paymentClaimAsync, result: { paymentClaim: { ...payment }, aggregateItemData: aggregatePaymentData(payment) } })
        }
    }

    const paymentTitle = paymentClaimAsync.result ? `${paymentClaimAsync.result.paymentClaim.certificateNumber}` : <>Loading Payment Claim <FA icon="spinner-third" spin /></>

    return (
        <>
            <ProjectBanner project={project} overrideTitle={paymentTitle} />
            <CrumbRoute title={`${paymentClaimAsync.result?.paymentClaim.certificateNumber ?? ''}`}>
                {paymentClaimAsync.result &&
                    <PaymentClaimItemsTableForm
                        projectId={project.id}
                        costsOverview={costsOverview}
                        paymentClaim={paymentClaimAsync.result.paymentClaim}
                        reloadPaymentClaim={handleReloadPaymentClaim}
                    />
                }
            </CrumbRoute>
        </>
    )
}

export default PaymentClaimDetailPage
