import { CharStreams, CommonTokenStream } from 'antlr4ts'
import { sortBy } from 'lodash'

import InMemoryFilterVisitor from '@src/components/search/IQL/InMemoryFilterVisitor'
import { IQLFilterLexer } from '@src/components/search/IQL/IQLFilterLexer'
import { IQLFilterParser } from '@src/components/search/IQL/IQLFilterParser'
import { IMappedSearchProperty } from '@src/components/search/SearchAssistant'
import { pageToSkip } from '@src/logic/utils/Pagination'

export default function localSearch<T extends object>(collection: T[], searchProperties: IMappedSearchProperty<T>[], filter: string | undefined, sort: string | undefined, page: number, perPage: number) {
    const visitor = new InMemoryFilterVisitor(searchProperties)

    let filteredItems = [...collection]

    if (filter?.trim() !== '') {
        const inputStream = CharStreams.fromString(filter)
        const lexer = new IQLFilterLexer(inputStream)
        const tokenStream = new CommonTokenStream(lexer)
        const parser = new IQLFilterParser(tokenStream)
        filteredItems = filteredItems.filter(visitor.visit(parser.filter()))
    }

    if (sort?.length > 0) {
        const desc = sort[0] === '-'
        const sortName = desc ? sort.slice(1) : sort
        const sortProperty = searchProperties.find(x => x.searchKey === sortName)

        if (sortProperty != null) {
            filteredItems = sortBy(filteredItems, sortProperty.path)
            if (desc) filteredItems.reverse()
        }
    }

    if (page != null && perPage != null) {
        return filteredItems.slice(pageToSkip(page, perPage), pageToSkip(page, perPage) + perPage)
    }

    return filteredItems
}
