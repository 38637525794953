import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupAddon, InputGroupText, Row, UncontrolledButtonDropdown } from 'reactstrap'

import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { BudgetItemAddModal } from '@src/components/costs/budget/BudgetItemAddModal'
import { IBudgetItemFormData } from '@src/components/costs/budget/BudgetItemFormBody'
import useModal from '@src/hooks/useModal'
import { budgetStatusLabel, budgetStatusValue, costCodeLabel, costCodeValue, isPhaseLocked, phaseLabel, phaseValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { BudgetItemCreate, CostCodesList } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { groupBy } from '@src/logic/utils/Collection'
import { BudgetReportColumn, CostsOverview, StatusColumnValueMap } from '@src/types/costs'

interface IProps {
    projectId: string
    costsOverview: CostsOverview
    onItemCreated?: () => void
}

export interface IQuickAddBudgetItemFormData extends IBudgetItemFormData {
    value: number
}

export default function BudgetItemToolbarForm({ projectId, costsOverview, onItemCreated }: IProps) {
    const firstFieldRef: React.RefObject<HTMLInputElement> = React.useRef()
    const budgetItemModal = useModal(false, { destroyOnClose: true })

    const initialValues = React.useMemo<Partial<IQuickAddBudgetItemFormData>>(() => ({
        date: new Date(),
        status: (costsOverview.budget.locked ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions)[0],
        statusColumnValues: (costsOverview.budget.locked ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions).map(sd => ({
            status: sd.code,
            unreported: 0,
            original: 0,
            adjustment: 0
        }))
    }), [costsOverview])

    const costCodesAsync = useAsyncAbortable(async (abortSignal, query) => {
        const response = await CostCodesList(projectId, 1, 200, { abortSignal })
        const grouped = groupBy(response.data, 'group')
        return Object.keys(grouped).reduce((prev, curr) => [...prev, { label: curr, options: grouped[curr] }], [])
    }, [''], { executeOnMount: false })

    async function createItemOrAdjustment(values: IQuickAddBudgetItemFormData) {
        const {
            locked: budgetLocked,
            itemStatusDefinitions: itemStatuses,
            adjustmentStatusDefinitions: adjustmentStatuses
        } = costsOverview.budget
        try {
            await BudgetItemCreate(projectId, {
                isAdjustment: budgetLocked,
                name: values.name,
                costCode: values.costCode.code,
                description: '',
                phaseId: values.phase ? values.phase.code : null,
                status: values.status.code,
                date: new Date(),
                tags: [],
                values: (budgetLocked ? adjustmentStatuses : itemStatuses).map(sd => sd.columns.map<StatusColumnValueMap<BudgetReportColumn>>((col, colIdx) => ({
                    column: col,
                    status: sd.code,
                    value: {
                        quantity: 0,
                        unit: '',
                        rate: 0,
                        notes: '',
                        value: colIdx === 0 ? values.value : 0
                    }
                }))).reduce((a, b) => a.concat(b), []),
                clientApprovalNumber: '',
                clientApprovalDocuments: []
            })
        } catch (e) {
            NotificationService.error('Failed to create budget item')
            throw e
        }

        NotificationService.info('Created Budget Item')
        firstFieldRef.current.focus()
        onItemCreated?.()
    }

    return (
        <ActionBar className="pt-0 mb-3 d-none d-lg-block">
            <form>
                <h5>Add Budget {costsOverview.budget.locked ? 'Adjustment' : 'Item'}</h5>
                <Form<IQuickAddBudgetItemFormData> onSubmit={createItemOrAdjustment} initialValues={initialValues} subscription={{}}>
                    {({ handleSubmit }) =>
                        <Row>
                            <Col>
                                <Field name="name" component={ValidatedInput} placeholder="Name" validate={required} innerRef={firstFieldRef} bsSize="sm" />
                            </Col>
                            <Col>
                                <Field name="costCode" component={ValidatedSelect} selectType="async" placeholder="Cost Code" validate={required} defaultOptions loadOptions={costCodesAsync.execute} getOptionValue={costCodeValue} getOptionLabel={costCodeLabel} cacheOptions size="sm" />
                            </Col>
                            <Col>
                                <Field
                                    name="phase"
                                    component={ValidatedSelect}
                                    placeholder="Phase"
                                    options={costsOverview.phases}
                                    getOptionLabel={phaseLabel}
                                    getOptionValue={phaseValue}
                                    isClearable
                                    size="sm"
                                    isOptionDisabled={isPhaseLocked}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="status"
                                    component={ValidatedSelect}
                                    placeholder="Status"
                                    options={!costsOverview.budget.locked ? costsOverview.budget.itemStatusDefinitions : costsOverview.budget.adjustmentStatusDefinitions}
                                    validate={required}
                                    getOptionLabel={budgetStatusLabel}
                                    getOptionValue={budgetStatusValue}
                                    size="sm"
                                />
                            </Col>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="dollar-sign" /></InputGroupText></InputGroupAddon>
                                    <Field aria-label="Value" name="value" component={ValidatedCurrencyInput} validate={required} />
                                </InputGroup>
                            </Col>
                            <Col className="flex-grow-0">
                                <UncontrolledButtonDropdown size="sm">
                                    <Button onClick={handleSubmit}><FA icon="plus" /> Add</Button>
                                    <DropdownToggle caret />
                                    <DropdownMenu>
                                        <DropdownItem className="pointer" onClick={budgetItemModal.actions.show}>Advanced Add</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                        </Row>
                    }
                </Form>
            </form>
            <BudgetItemAddModal {...budgetItemModal.modalProps} costsOverview={costsOverview} projectId={projectId} onItemAdded={onItemCreated} />
        </ActionBar>
    )
}
