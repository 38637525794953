import React from 'react'
import { Button, Card, Container } from 'reactstrap'

import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import NewCommitmentDefinitionModal from '@src/components/costs/settings/NewCommitmentDefinitionModal'
import GenericContentTable from '@src/components/table/GenericContentTable'
import useModal from '@src/hooks/useModal'
import useProject from '@src/hooks/useProject'
import useProjectCosts from '@src/hooks/useProjectCosts'
import { CommitmentDefinitionDelete } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { CommitmentDefinition } from '@src/types/costs'

export default function CommitmentDefinitionSettingsSection() {
    const [project] = useProject()
    const [{ commitmentDefinitions }, reloadCostsOverview] = useProjectCosts()
    const isCreatingModal = useModal()

    async function deleteCommitmentDefinition(commitmentDefinition: CommitmentDefinition) {
        await CommitmentDefinitionDelete(project.id, commitmentDefinition.code)
        reloadCostsOverview()
    }

    const definitionsExistBody = (
        <GenericContentTable
            data={commitmentDefinitions}
            headers={[
                {
                    name: 'Code',
                    accessor: 'code'
                },
                {
                    name: 'Name',
                    accessor: 'name'
                },
                {
                    name: 'Additions',
                    overrideRenderer: cd => ((cd.defaultVariationAdditions || 0) * 100).toFixed(3) + '%'
                },
                {
                    name: 'Deductions',
                    overrideRenderer: cd => ((cd.defaultVariationDeductions || 0) * 100).toFixed(3) + '%'
                },
                {
                    name: 'Current Number',
                    overrideRenderer: cd => cd.commitmentNumberDefinition.currentNumber
                },
                {
                    name: 'Current Certificate',
                    overrideRenderer: cd => 'TODO'
                },
                {
                    name: 'Actions',
                    headerWrapperClass: 'text-right',
                    noSmallHeader: true,
                    overrideRenderer: (commitmentType, rowIdx) => (
                        <div className="justify-content-end text-right selectable-content__actions">
                            <TooltipLink id={`edit-commitdef-${rowIdx}`} tooltip="Edit" to={Routes.projectCostsSettingsCommitmentDefinition(project.id, commitmentType.code)}><FA icon="pencil" /></TooltipLink>
                            <TooltipLinkAction id={`remove-commitdef-${rowIdx}`} tooltip="Remove" data={commitmentType} onClick={deleteCommitmentDefinition}><FA icon="trash" /></TooltipLinkAction>
                        </div>
                    )
                }
            ]}
        />
    )

    const noDefinitionsBody = (
        <Card body className="text-center mt-3">
            <div className="my-3"><FA size="3x" icon={'badge-dollar' as any} /></div>
            <p className="lead"><Button onClick={isCreatingModal.actions.show}>Create Commitment Type</Button></p>
            <p>There are no commitment types for this project.</p>
        </Card>
    )

    return (
        <>
            {commitmentDefinitions.length > 0 &&
                <ActionBar className="mb-3">
                    <Button onClick={isCreatingModal.actions.show}><FA icon="plus-circle" /> Create Type</Button>
                </ActionBar>
            }
            <Container fluid>
                {commitmentDefinitions.length > 0 ? definitionsExistBody : noDefinitionsBody}
                <NewCommitmentDefinitionModal
                    {...isCreatingModal.modalProps}
                    projectId={project.id}
                    onCreated={reloadCostsOverview}
                />
            </Container>
        </>
    )
}
