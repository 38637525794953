import React, { PropsWithChildren } from 'react'
import { Button, Collapse, UncontrolledTooltip } from 'reactstrap'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'
import useBoolean from '@src/hooks/useBoolean'

interface IProps {
    icon: IPFontawesomeIcon
    name: string
    tooltip: string
    metadataKey: string
    onRetire?: (metadataKey: string) => void
    onUpdate?: (e: React.MouseEvent) => void
    disabled?: boolean
}

export default function DefinitionTemplate({ icon, name, tooltip, metadataKey, onRetire, onUpdate, disabled, children }: PropsWithChildren<IProps>) {
    const open = useBoolean(false)

    function handleRetire() {
        onRetire?.(metadataKey)
    }

    function handleUpdate(e: React.MouseEvent) {
        onUpdate?.(e)
    }

    return (
        <div>
            <h4 className="my-2">
                <UncontrolledTooltip target={`metadata-icon-${metadataKey}`}>{tooltip}</UncontrolledTooltip>
                <FA id={`metadata-icon-${metadataKey}`} className="align-baseline mr-2" icon={icon} size="xs" />
                <span className="pointer" onClick={open.toggle} onKeyPress={open.toggle} role="button" tabIndex={0}>{name}</span>
                <span className="float-right">
                    <Button className="mr-2" color="primary" onClick={handleUpdate} disabled={disabled}><FA className="mr-1" icon="check-circle" />Save changes</Button>
                    <Button color="danger" onClick={handleRetire}><FA className="mr-1" icon="times" />Remove</Button>
                </span>
            </h4>
            <Collapse isOpen={!open.value}>
                {children}
            </Collapse>
        </div>
    )
}
