import create from 'zustand'

import { RegisterGetById } from '@src/logic/http/Api'
import { RegisterOverview } from '@src/types/register'

interface IRegisterStore {
    register: RegisterOverview | null
    loadError: Error | null
    load: (id: string, abortSignal?: AbortSignal) => Promise<void>
    refresh: () => void
    update: (update: Partial<RegisterOverview>) => void
}

const useRegisterStore = create<IRegisterStore>((set, get) => ({
    register: null,
    loadError: null,
    load: async (id, abortSignal) => {
        set({ register: null, loadError: null })
        try {
            const response = await RegisterGetById(id, undefined, undefined, undefined, undefined, { abortSignal })
            set({ register: response.data, loadError: null })
        } catch (error) {
            set({ register: null, loadError: error as Error })
        }
    },
    refresh: async () => {
        const registerId = get().register?.id
        if (registerId == null) return
        try {
            const response = await RegisterGetById(registerId)
            set({ register: response.data, loadError: null })
        } catch (error) {
            set({ register: null, loadError: error as Error })
        }
    },
    update: (update: Partial<RegisterOverview>) => {
        const register = get().register
        set({ register: { ...register, ...update } })
    }
}))

export default useRegisterStore
