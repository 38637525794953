import React from 'react'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    currentRoutePath: string
}

export default class ProjectToolbar extends React.PureComponent<IProps> {
    public render() {
        const { project } = this.props
        return (
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_DOCUMENTS)} to={Routes.projectDocuments(project.id)} name="Documents" nameBrief="Docs" iconBrief="copy" data-tour={TourTags.ProjectToolbarDocuments} />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_TRANSMITTALS)} to={Routes.projectTransmittals(project.id)} name="Transmittals" nameBrief="Transmit" iconBrief="envelope" data-tour={TourTags.ProjectToolbarTransmittals} />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_EMAILS)} to={Routes.projectEmails(project.id)} name="Emails" nameBrief="Mail" iconBrief="at" data-tour={TourTags.ProjectToolbarEmails} />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTERS)} to={Routes.projectRegisters(project.id)} name="Registers" nameBrief="Registers" iconBrief="table" data-tour={TourTags.ProjectToolbarRegisters} />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COMMUNICATIONS)} to={Routes.projectCommunications(project.id)} name="Communications" nameBrief="Comms" iconBrief="envelope-open-text" data-tour={TourTags.ProjectToolbarCommunications} />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_TEMPLATES)} to={Routes.projectTemplates(project.id)} name="Templates" nameBrief="Templates" iconBrief="envelope-open-text" data-tour={TourTags.ProjectToolbarTemplates} />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_USERS)} to={Routes.projectUsers(project.id)} name="Users" nameBrief="Users" iconBrief="users" data-tour={TourTags.ProjectToolbarUsers} />
                    <div className="ml-auto d-none d-md-flex">
                        {/* Disabled while TODO
                            <ToolbarLink disabled tool name="Export" icon="download" iconBrief="download" />
                        */}
                        <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_SETTINGS_GENERAL)} to={Routes.projectSettingsGeneral(project.id)} tool name="Settings" icon="cog" iconBrief="cog" data-tour={TourTags.ProjectToolbarSettings} />
                    </div>
                </Toolbar>
            </section>
        )
    }
}
