import React from 'react'
import { connect } from 'react-redux'
import { match as Match, Route, RouteComponentProps, Switch } from 'react-router'
import { Button, Card, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { Dispatch } from 'redux'

import { activeProjectCostsOverview } from '@src/actions/project'
import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { CostsCreateEmpty, CostsGet } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import ForbiddenPage from '@src/pages/common/ForbiddenPage'
import CommitmentDetailPage from '@src/pages/costs/CommitmentDetailPage'
import CostsMainPage from '@src/pages/costs/CostsMainPage'
import CostsSettingsPage from '@src/pages/costs/CostsSettingsPage'
import PaymentClaimDetailPage from '@src/pages/costs/PaymentClaimDetailPage'
import { CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IConnectedState {
    project: Project
    costsOverview: CostsOverview
}

interface IConnectedDispatch {
    setActiveCostsOverview: typeof activeProjectCostsOverview
}

interface IState {
    loaded: boolean
    authorized: boolean
}

class CostsPages extends React.PureComponent<RouteComponentProps & IConnectedState & IConnectedDispatch, IState> {
    private getCostsAbortController: AbortController

    constructor(props) {
        super(props)

        this.state = {
            loaded: false,
            authorized: true
        }
    }

    public componentDidMount() {
        this.loadCostsOverview()
    }

    public componentWillUnmount() {
        this.getCostsAbortController?.abort()
    }

    private readonly loadCostsOverview = async () => {
        this.getCostsAbortController = new AbortController()
        try {
            const response = await CostsGet(this.props.project.id, { abortSignal: this.getCostsAbortController.signal })
            this.props.setActiveCostsOverview(response.data)
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    return this.props.setActiveCostsOverview(null)
                }
                if (error.response.status === 403) {
                    this.setState({ authorized: false })
                    return this.props.setActiveCostsOverview(null)
                }
            }
        } finally {
            this.getCostsAbortController = null
            this.setState({ loaded: true })
        }
    }

    private readonly createTemplateCostsForProject = async () => {
        await CostsCreateEmpty(this.props.project.id)
        this.loadCostsOverview()
    }

    private readonly createEmptyCostsForProject = async () => {
        await CostsCreateEmpty(this.props.project.id, false)
        this.loadCostsOverview()
    }

    private readonly getCommitmentTypeTitle = (match: Match<{[key: string]: string}>) => {
        const commitmentType = this.props.costsOverview.commitmentDefinitions.find(x => x.code === match.params.type)
        return commitmentType ? commitmentType.name : ''
    }

    public render() {
        const { costsOverview } = this.props
        const { loaded, authorized } = this.state
        let body = null

        if (!authorized) {
            body = (
                <>
                    <ProjectBanner project={this.props.project} />
                    <ForbiddenPage resourceName="Cost Management" requiredOperation="read" />
                </>
            )
        } else if (loaded) {
            body = !costsOverview
                ? (
                    <>
                        <ProjectBanner project={this.props.project} />
                        <Container fluid>
                            <Card body className="mt-3 text-center">
                                <div className=" mt-3 mb-3"><FA icon="chart-line" size="4x" /></div>
                                <div className="mb-3"><h4>No Project Costs Management Setup</h4></div>
                                <div className="mb-5">
                                    <UncontrolledButtonDropdown>
                                        <Button onClick={this.createTemplateCostsForProject}>Create With Defaults</Button>
                                        <DropdownToggle caret />
                                        <DropdownMenu>
                                            <DropdownItem onClick={this.createEmptyCostsForProject}>Create Empty</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </Card>
                        </Container>
                    </>
                )
                : (
                    <Switch>
                        <CrumbRoute title="Settings" path={Routes.PROJECT_COSTS_SETTINGS} component={CostsSettingsPage} />
                        <CrumbRoute
                            exact
                            title="Payments"
                            path={Routes.PROJECT_COSTS_PAYMENT_DETAIL}
                            linkPath={Routes.PROJECT_COSTS_PAYMENTS}
                            component={PaymentClaimDetailPage}
                        />
                        <CrumbRoute
                            title={this.getCommitmentTypeTitle}
                            path={Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL}
                            linkPath={Routes.PROJECT_COSTS_COMMITMENT_TYPE}
                            component={CommitmentDetailPage}
                        />
                        <Route component={CostsMainPage} />
                    </Switch>
                )
        }

        return body
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps): RouteComponentProps<any> & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active,
        costsOverview: state.projects.activeCostsOverview
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: RouteComponentProps): IConnectedDispatch {
    return {
        setActiveCostsOverview: (costsOverview: CostsOverview) => dispatch(activeProjectCostsOverview(costsOverview))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostsPages)
