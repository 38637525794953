import React from 'react'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { Communication } from '@src/types/communication'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    communication: Communication
}

export default function CommunicationToolbar({ project, communication, children }: React.PropsWithChildren<IProps>) {
    const onEditRoute = pathMatchesCurrentRoute(Routes.PROJECT_COMMUNICATION_EDIT)

    return (
        <section className="banner mb-3">
            <Toolbar>
                <ToolbarLink active={onEditRoute} to={Routes.projectCommunication(project.id, communication.id)}
                             name="Edit" nameBrief="Edit" iconBrief="pencil"/>
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COMMUNICATION_ACCESS)}
                             to={Routes.projectCommunicationAccess(project.id, communication.id)} name="Access"
                             nameBrief="Access" iconBrief="lock"/>
                <div className="ml-auto d-none d-md-flex align-items-center">
                    {children}
                </div>
            </Toolbar>
        </section>
    )
}
