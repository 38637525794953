import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { v4 } from 'uuid'

import useConstant from '@src/hooks/useConstant'
import { EmailUser } from '@src/types/email'

interface IProps {
    recipients: EmailUser[]
}

const RecipientList: React.FC<IProps> = ({ recipients }) => {
    const uuid = useConstant(() => v4())
    return (
        <div>
            {recipients.map((r, idx, a) => (
                <React.Fragment key={idx}>
                    <span><a href={`mailto:${r.email}`} id={`recipient-${idx}-${uuid}`}>{r.name || r.email}</a>{idx !== a.length - 1 && '; '}</span>
                    {r.name && r.name !== r.email && <UncontrolledTooltip target={`recipient-${idx}-${uuid}`}>{r.email}</UncontrolledTooltip>}
                </React.Fragment>)
            )}
        </div>
    )
}

export default React.memo(RecipientList)
