export function setNativeValue(element: HTMLElement, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value)
    } else {
        valueSetter.call(element, value)
    }

    element.dispatchEvent(new Event('input', { bubbles: true }))
}

export function mergeRefs<T>(refs: (React.MutableRefObject<T> | React.LegacyRef<T>)[]): React.RefCallback<T> {
    return (value: T) => {
        for (const ref of refs) {
            if (typeof ref === 'function') ref(value)
            else if (ref != null) (ref as React.MutableRefObject<T>).current = value
        }
    }
}
