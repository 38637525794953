import React from 'react'
import { AsyncState, useAsyncAbortable } from 'react-async-hook'
import { Route, Switch, useLocation, useParams } from 'react-router'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { Card, CardSubtitle, CardTitle, Col, Container, Nav, NavLink, Row } from 'reactstrap'

import MiniBanner from '@src/components/banner/MiniBanner'
import { UserAvatar } from '@src/components/common/Avatar'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import UserStatusIndicator from '@src/components/company/UserStatusIndicator'
import { SearchUsers } from '@src/logic/http/Api'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { parseLinkHeader } from '@src/logic/utils/Pagination'
import { isNullOrEmpty, safeHtmlId } from '@src/logic/utils/Strings'
import { tryBuildURL } from '@src/logic/utils/URL'
import { mutedValue, valueOrMutedFallback } from '@src/logic/utils/ValueHelper'
import { UserBasic } from '@src/types/principal'

type SearchType = 'user' | 'company'

function UserCardContent(props: ListChildComponentProps) {
    if (props.data == null) return null
    const user = props.data[props.index] as UserBasic

    return (
        <div className="d-flex">
            <div className="mr-2">
                <UserAvatar size="sm" firstName={user.firstName} lastName={user.lastName} imageUrl={user.profilePictureLink} />
            </div>
            <div className="d-flex" style={{ width: 'auto' }}>
                <UserStatusIndicator id={safeHtmlId(user.id)} status={user.status} />
            </div>
            <div>
                <CardTitle className="d-block mb-0" tag="strong">{isNullOrEmpty(user.firstName) ? mutedValue('Unknown') : `${user.firstName} ${user.lastName}`}</CardTitle>
                <CardSubtitle className="d-block">{valueOrMutedFallback(user.company?.name, 'N/A')}</CardSubtitle>
                <div className="text-sm d-flex align-items-center">
                    <FA icon="envelope" fixedWidth className="mr-1" />
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                </div>
            </div>
        </div>
    )
}

function getComponentForType(type: SearchType): React.ComponentType<ListChildComponentProps> & React.ReactNode {
    let Content: React.ComponentType<ListChildComponentProps>
    switch (type) {
        case 'user':
            Content = UserCardContent
    }

    return props => (
        <Card
            body
            style={{
                ...props.style,
                height: (props.style.height as number) - GUTTER_SIZE
            }}
        >
            <Content {...props} />
        </Card>
    )
}

type SearchFunction = (filter: string | undefined, sort: string | undefined, cursor: string | undefined, abortSignal: AbortSignal) => Promise<IGlobalSearchResponse<UserBasic>>

function getSearchForType(type: SearchType): SearchFunction {
    switch (type) {
        case 'user':
            return async (filter, sort, cursor, abortSignal: AbortSignal): Promise<IGlobalSearchResponse<UserBasic>> => {
                const result = await SearchUsers(filter, sort, 20, cursor, { abortSignal })
                const nextRel = tryBuildURL(parseLinkHeader(result.headers.link)?.next?.url ?? '')?.searchParams
                return {
                    filter,
                    items: result.data,
                    hasMore: true,
                    cursor: nextRel?.has('cursor') ? nextRel.get('cursor') : null,
                    type: 'user'
                }
            }
        default:
            throw new Error('Unknown search type')
    }
}

interface IGlobalSearchResponse<T extends { id: string }> {
    filter: string
    items: T[]
    hasMore: boolean
    cursor: string
    type: SearchType
}

function handleAsyncResponse<T extends { id: string }>(result: IGlobalSearchResponse<T>, asyncState: AsyncState<IGlobalSearchResponse<T>>): AsyncState<IGlobalSearchResponse<T>> {
    return {
        error: undefined,
        loading: false,
        result: {
            filter: result.filter,
            items: result.type === asyncState.result?.type && asyncState.result.filter === result.filter ? [...asyncState.result.items, ...result.items.filter(i => !asyncState.result.items.find(x => x.id === i.id))] : result.items,
            hasMore: result.hasMore,
            cursor: result.cursor,
            type: result.type
        },
        status: 'success'
    }
}

function handleLoading<T extends { id: string }>(asyncState: AsyncState<IGlobalSearchResponse<T>>): AsyncState<IGlobalSearchResponse<T>> {
    return {
        ...asyncState,
        loading: true
    }
}

const GUTTER_SIZE = 5

const innerElementType = React.forwardRef<any, any>(({ style, ...rest }, ref) => (
    <div
        ref={ref}
        style={{
            ...style
            // paddingBottom: GUTTER_SIZE
        }}
        {...rest}
    />
))

const GlobalSearchPage: React.FC = () => {
    const location = useLocation()
    const routeParams = useParams<Routes.IGlobalSearchParams>()
    const ItemComponent = React.useMemo(() => getComponentForType(routeParams.searchType as SearchType), [routeParams.searchType])
    const filter = new URLSearchParams(location.search).get('filter')
    const handleSearch = React.useMemo(() => getSearchForType(routeParams.searchType as SearchType), [routeParams.searchType])
    // const [cursor, setCursor] = React.useState<string | undefined>(undefined)
    const asyncItems = useAsyncAbortable<IGlobalSearchResponse<UserBasic>, [SearchFunction, string]>((ct, doSearch, filter) =>
        doSearch(filter, undefined, asyncItems?.filter === filter ? asyncItems.cursor : undefined, ct),
        [handleSearch, filter],
        { setResult: handleAsyncResponse, setLoading: handleLoading }
    )

    function isItemLoaded(index: number) {
        return !asyncItems.result?.hasMore || index < asyncItems.result?.items.length
    }

    const loadMoreItems = asyncItems.loading ? null : () => asyncItems.execute(handleSearch, filter)

    const itemCount = (asyncItems.result?.items.length ?? 0)

    return (
        <>
            <MiniBanner />
            <Container>
                <Row className="mt-3">
                    <Col md={3}>
                        <div className="sticky-top" style={{ top: '1rem' }}>
                            <Card body>
                                <Nav vertical pills>
                                    <NavLink disabled>Search by</NavLink>
                                    <NavLink tag={Link} to={{ pathname: Routes.GLOBAL_SEARCH_USER, search: location.search }} active={pathMatchesCurrentRoute(Routes.GLOBAL_SEARCH_USER, true)}><FA icon="users" className="mr-2" fixedWidth />User</NavLink>
                                </Nav>
                            </Card>
                        </div>
                    </Col>
                    <Col md={9}>
                        <Card body className="mb-1">{asyncItems.loading ? <span>Loading <FA icon="spinner-third" spin /></span> : <span>Showing results for {routeParams.searchType} with &quot;{filter}&quot;</span>}</Card>
                        <Switch>
                            <Route render={() => (
                                <InfiniteLoader
                                    isItemLoaded={isItemLoaded}
                                    itemCount={itemCount}
                                    loadMoreItems={loadMoreItems}
                                >
                                    {({ onItemsRendered, ref }) => (
                                        <FixedSizeList
                                            itemCount={itemCount}
                                            onItemsRendered={onItemsRendered}
                                            itemData={asyncItems.result?.items}
                                            ref={ref}
                                            itemSize={100 + GUTTER_SIZE}
                                            innerElementType={innerElementType}
                                            height={600}
                                            width="100%"
                                        >
                                            {ItemComponent}
                                        </FixedSizeList>
                                    )}
                                </InfiniteLoader>
                            )} />
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default GlobalSearchPage
