import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { IModalProps } from "@src/hooks/useModal";
import NotificationService from "@src/logic/notification/NotificationService";
import { MoveContactToCompany } from "@src/logic/http/Api";
import { UserInternal } from "@src/types/principal";
import ValidatedSelect from "@src/components/common/ValidatedSelect";
import shallow from "zustand/shallow";
import { useCompanyStore, useContactsStore } from "@src/store/admin";

interface IMoveContactValues {
  company: { label: string; value: string };
  userId: string;
}

export default function MoveContactForm({
  modalProps,
  outerModalProps,
  refreshCompanyUsers,
  oldCompanyId,
  user,
}: {
  modalProps: IModalProps;
  outerModalProps?: IModalProps;
  refreshCompanyUsers?: any;
  oldCompanyId?: string;
  user: UserInternal;
}) {
  const [refreshContacts] = useContactsStore(
    (s) => [s.refreshContacts],
    shallow
  );
  const [searchableCompanies, loadSearchableCompanies] = useCompanyStore(
    (s) => [s.searchableCompanies, s.loadSearchableCompanies],
    shallow
  );
  async function handleSubmit(values: IMoveContactValues): Promise<any> {
    try {
      // submit company details
      await MoveContactToCompany({
        companyId: values.company.value,
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      }).then(async () => {
        await refreshContacts("");
      });

      NotificationService.success(
        `Contact was successfully moved to ${values.company.label}.`
      );
    } catch {
      NotificationService.error(
        "An error occured while moving a contact. Please refresh and try again."
      );
    } finally {
      modalProps.toggle();
      if (outerModalProps) outerModalProps.toggle();
      if (refreshCompanyUsers) refreshCompanyUsers(oldCompanyId);
    }
  }

  useEffect(() => {
    loadSearchableCompanies("", 10000);
  }, []);

  return (
    <Modal {...modalProps}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          company: undefined,
          userId: user?.id,
        }}
      >
        {(formProps) => (
          <>
            <ModalHeader>Move Contact</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12} className="mb-3">
                  <FormGroup>
                    <Label>Select Company</Label>
                    <Field
                      name="company"
                      component={ValidatedSelect}
                      isClearable
                      options={searchableCompanies.map((c) => ({
                        label: c.name,
                        value: c.id,
                      }))}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                style={{ borderColor: "lightgray" }}
                onClick={modalProps.toggle}
                className="w-25"
              >
                Cancel
              </Button>
              <Button
                disabled={formProps.submitting}
                color="primary"
                className="w-25"
                onClick={formProps.handleSubmit}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
}
