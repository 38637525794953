import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Col, Row } from 'reactstrap'

import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CostValue from '@src/components/costs/common/CostValue'
import EditPaymentForm from '@src/components/costs/payments/EditPaymentForm'
import NewPaymentForm from '@src/components/costs/payments/NewPaymentForm'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import useModal from '@src/hooks/useModal'
import useProjectWidget from '@src/hooks/useWidget'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import { PaymentClaimDelete, PaymentClaimsList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { mutedNotSet, valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { AggregatePaymentClaimData, CostsOverview, PaymentClaim, PaymentClaimBrief, PaymentClaimStatus } from '@src/types/costs'
import { RootState } from '@src/types/models'

export default function PaymentSection() {
    const projectId = useSelector<RootState, string>(x => x.projects.active.id)
    const costsOverview = useSelector<RootState, CostsOverview>(x => x.projects.activeCostsOverview)
    const [aggregatePaymentData, setAggregatePaymentData] = useState<AggregatePaymentClaimData>(undefined)
    const [paymentClaimToEdit, setPaymentClaimToEdit] = useState<PaymentClaimBrief>()
    const [paymentClaimToDelete, setPaymentClaimToDelete] = useState<PaymentClaimBrief>()
    const searchSectionRef = React.useRef<SearchSectionType<PaymentClaimBrief, 'id'>>()
    const newPaymentModal = useModal()
    const editPaymentModal = useModal(false, { destroyOnClose: true })
    const widget = useProjectWidget()
    const history = useHistory()
    const notAuthorised = React.useMemo(() => !isAuthorised(costsOverview.myAccess, Operations.Delete), [costsOverview])
    const widgetPaymentClaimIds = React.useMemo(() => widget.paymentClaims.map(x => x.id), [widget.paymentClaims])

    const paymentsSearch = React.useCallback(async (filter: string, sort: string, page: number, perPage: number, abortSignal: AbortSignal) => {
            const response = await PaymentClaimsList(projectId, filter, sort, page, perPage, { abortSignal })
            setAggregatePaymentData(response.data.aggregatePaymentData)
            return {
                items: response.data.paymentClaims,
                totalItems: Number(response.headers[Headers.PaginationTotalCount])
            }
        },
        [projectId]
    )

    async function handlePaymentClaimsSelected(...paymentClaims: PaymentClaimBrief[]) {
        const selectedIds = [...widgetPaymentClaimIds]
        widget.actions.addPaymentClaims({ projectId: projectId, entities: paymentClaims.filter(p => !selectedIds.includes(p.id)) })
        widget.actions.removePaymentClaims({ projectId: projectId, entityIds: paymentClaims.filter(c => selectedIds.includes(c.id)).map(c => c.id) })
    }

    function clearPaymentClaimToDelete() {
        setPaymentClaimToDelete(null)
    }

    function editPaymentClaim(paymentClaim: PaymentClaimBrief) {
        setPaymentClaimToEdit(paymentClaim)
        editPaymentModal.actions.show()
    }

    async function deletePaymentClaim() {
        clearPaymentClaimToDelete()
        try {
            await PaymentClaimDelete(projectId, paymentClaimToDelete.commitment.id, paymentClaimToDelete.id)
        } catch {
            NotificationService.error(<span>Failed to remove {paymentClaimToDelete.certificateNumber}</span>)
        }

        await searchSectionRef.current.doSearch()
    }

    function handlePaymentCreated(paymentClaim: PaymentClaim) {
        history.push(Routes.projectCostsPaymentDetail(projectId, paymentClaim.commitment.type, paymentClaim.commitment.id, paymentClaim.id))
    }

    function handlePaymentUpdated() {
        searchSectionRef.current?.doSearch()
    }

    function deleteTooltipText(item: PaymentClaimBrief) {
        if (item.status !== PaymentClaimStatus.Entered) return "Locked payment can't be deleted"
        if (notAuthorised) return 'No permission to delete'
        return 'Remove'
    }

    const noPaymentClaimsFoundMessage = (
        <>
            <div className="my-3"><FA size="3x" icon="envelope-open-dollar" /></div>
            <p className="lead">No payment claims found with the current search criteria...</p>
            <p>{"Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters."}</p>
        </>
    )

    return (
        <>
            <ActionBar className="pb-0 pt-0 d-block">
                <Row>
                    <Col className="pt-3" xs={6} md="auto">
                        <h5>Total Claimed</h5>
                        {aggregatePaymentData && <CostValue value={aggregatePaymentData.totalClaimed} />}
                    </Col>
                    <Col className="pt-3" xs={6} md="auto">
                        <h5>Total Certified</h5>
                        {aggregatePaymentData && <CostValue value={aggregatePaymentData.totalCertified} />}
                    </Col>
                    <Col className="pt-3" xs={6} md="auto">
                        <h5>Total Paid</h5>
                        {aggregatePaymentData && <CostValue value={aggregatePaymentData.totalPaid} />}
                    </Col>
                </Row>
            </ActionBar>
            <SearchSection<PaymentClaimBrief, 'id'>
                ref={searchSectionRef}
                onItemsSelected={handlePaymentClaimsSelected}
                selectedItems={widgetPaymentClaimIds}
                defaultPerPage={50}
                itemIdKey="id"
                searchAssistantProperties={[
                    {
                        name: 'Claim Number',
                        searchKey: 'claim_number',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Claim Date',
                        searchKey: 'claim_date',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Invoice Date',
                        searchKey: 'invoice_date',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Claim Reference',
                        searchKey: 'claim_reference',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Invoice Reference',
                        searchKey: 'invoice_reference',
                        type: PropertyType.Text
                    }
                ]}
                headers={[
                    {
                        name: 'Claim #',
                        sortKey: 'claim_number',
                        sortable: true,
                        overrideRenderer: payment => <Link to={Routes.projectCostsPaymentDetail(projectId, payment.commitment.type, payment.commitment.id, payment.id)}>{payment.certificateNumber}</Link>
                    },
                    {
                        name: 'Supplier',
                        overrideRenderer: payment => valueOrMutedNotSet(payment.commitment.otherParty?.name)
                    },
                    {
                        name: 'Claim Date',
                        sortKey: 'claim_date',
                        sortable: true,
                        overrideRenderer: payment => payment.claimDate ? localShortDate(payment.claimDate) : mutedNotSet
                    },
                    {
                        name: 'Invoice Date',
                        sortKey: 'invoice_date',
                        sortable: true,
                        overrideRenderer: payment => payment.invoiceDate ? localShortDate(payment.invoiceDate) : mutedNotSet
                    },
                    {
                        name: 'Claim Ref.',
                        overrideRenderer: payment => valueOrMutedNotSet(payment.claimReference)
                    },
                    {
                        name: 'Invoice Ref.',
                        overrideRenderer: payment => valueOrMutedNotSet(payment.invoiceReference)
                    },
                    {
                        name: 'Claimed',
                        overrideRenderer: commitment => <CostValue value={commitment.currentClaimed} />
                    },
                    {
                        name: 'Certified',
                        overrideRenderer: commitment => <CostValue value={commitment.currentCertified} />
                    },
                    {
                        name: 'Paid',
                        overrideRenderer: commitment => <CostValue value={commitment.currentPaid} />
                    },
                    {
                        name: 'Actions',
                        headerWrapperClass: 'text-right',
                        overrideRenderer: item =>
                            <div className="text-right">
                                <TooltipLinkAction id={`edit-payment-${item.id}`} tooltip="Edit" data={item} className="order-lg-1" onClick={editPaymentClaim}><FA icon="pencil" /></TooltipLinkAction>
                                <TooltipLinkAction id={`delete-payment-${item.id}`} tooltip={deleteTooltipText(item)} data={item} className="order-lg-1" onClick={setPaymentClaimToDelete} disabled={item.status !== PaymentClaimStatus.Entered || notAuthorised}><FA icon="trash" /></TooltipLinkAction>
                            </div>
                    }
                ]}
                onSearch={paymentsSearch}
                noItemsFoundMessage={noPaymentClaimsFoundMessage}
                extraSearchBarElements={[
                    {
                        position: 'before',
                        element: () =>
                            <Button onClick={newPaymentModal.actions.show}>
                                <FA icon="plus" className="mr-1" />Add Payment
                            </Button>
                    }
                ]}
            >
                {paymentClaimToEdit && <EditPaymentForm
                    {...editPaymentModal.modalProps}
                    projectId={projectId}
                    costsOverview={costsOverview}
                    paymentClaim={paymentClaimToEdit}
                    onUpdated={handlePaymentUpdated}
                />}
                <NewPaymentForm
                    {...newPaymentModal.modalProps}
                    projectId={projectId}
                    costsOverview={costsOverview}
                    onCreated={handlePaymentCreated}
                />
                <ConfirmationModal
                    danger
                    isOpen={paymentClaimToDelete != null}
                    toggle={clearPaymentClaimToDelete}
                    header={'Remove commitment'}
                    message={<span>Are you sure you want to remove <strong>{paymentClaimToDelete?.certificateNumber}</strong>?</span>}
                    confirmAction="Remove"
                    rejectAction="Cancel"
                    onReject={clearPaymentClaimToDelete}
                    onConfirm={deletePaymentClaim}
                />
            </SearchSection>
        </>
    )
}
