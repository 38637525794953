// tslint:disable:max-classes-per-file
import React from 'react'
import { Field, Form } from 'react-final-form'
import { Col, FormGroup, FormText, Label, Row } from 'reactstrap'

import { IOption } from '@src/components/common/Select'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { FieldPrefix, PrefixedField } from '@src/components/forms/FieldPrefix'
import DefinitionTemplate from '@src/components/metadata/definitions/DefinitionTemplate'
import { AutoNumberDefinition, BoolDefinition, CommitmentLinksDefinition, CompanyEntityProps, CompanyLinksDefinition, CompanyPropertyDefinition, DateDefinition, DocumentEntityProps, DocumentLinksDefinition, DocumentPropertyDefinition, EmailEntityProps, EmailLinksDefinition, EmailPropertyDefinition, IMetadataDefinition, IUpdateMetadataDefinition, MetadataTypeDisplayMap, MetadataTypes, NumericDefinition, PaymentClaimLinksDefinition, SelectDefinition, TextDefinition, TransmittalEntityProps, TransmittalLinksDefinition, TransmittalPropertyDefinition, UserEntityProps, UserLinksDefinition, UserPropertyDefinition } from '@src/types/metadata'

interface IMetadataDefinitionForm<T> {
    key: string
    name: string
    description: string
    isRequired: boolean
    options: T
}

export function MetadataDefinition<T extends IMetadataDefinition<OptionsOf<T>>>({ definition, disabled, fieldDescriptions, fieldLabels, onRetire, onUpdate }: ICommonProps<T>) {
    const DefinitionFormBody: MetadataDefinitionOptionsComponent<T, unknown> = React.useMemo(() => {
        switch (definition.type) {
            case MetadataTypes.AutoNumber:
                return AutoNumberMetadataDefinition
            case MetadataTypes.Bool:
                return BoolMetadataDefinition
            case MetadataTypes.Numeric:
                return NumericMetadataDefinition
            case MetadataTypes.Text:
                return TextMetadataDefinition
            case MetadataTypes.Date:
                return DateMetadataDefinition
            case MetadataTypes.Select:
                return SelectMetadataDefinition
            case MetadataTypes.DocumentLinks:
                return DocumentLinksMetadataDefinition
            case MetadataTypes.DocumentProperty:
                return DocumentPropertyMetadataDefinition
            case MetadataTypes.TransmittalLinks:
                return TransmittalLinksMetadataDefinition
            case MetadataTypes.TransmittalProperty:
                return TransmittalPropertyMetadataDefinition
            case MetadataTypes.CompanyLinks:
                return CompanyLinksMetadataDefinition
            case MetadataTypes.CompanyProperty:
                return CompanyPropertyMetadataDefinition
            case MetadataTypes.UserLinks:
                return UserLinksMetadataDefinition
            case MetadataTypes.UserProperty:
                return UserPropertyMetadataDefinition
            case MetadataTypes.EmailLinks:
                return EmailLinksMetadataDefinition
            case MetadataTypes.EmailProperty:
                return EmailPropertyMetadataDefinition
            case MetadataTypes.CommitmentLinks:
                return CommitmentLinksMetadataDefinition
            case MetadataTypes.PaymentClaimLinks:
                return PaymentClaimLinksMetadataDefinition
            default:
                return null
        }
    }, []) as MetadataDefinitionOptionsComponent<T, unknown>

    const initialValues = React.useMemo(() => ({
        key: definition.key,
        name: definition.name,
        description: definition.description,
        isRequired: definition.isRequired,
        options: DefinitionFormBody.formatInitializeOptions(definition.options)
    }), [definition])

    const { icon, tooltip } = MetadataTypeDisplayMap[definition.type]

    function handleSubmit(values: IMetadataDefinitionForm<any>) {
        onUpdate(definition.key, {
            description: values.description,
            isRequired: values.isRequired,
            key: values.key,
            name: values.name,
            options: DefinitionFormBody.formatSubmitOptions(definition, values.options),
            type: definition.type
        })
    }

    return (
        <Form<IMetadataDefinitionForm<any>> onSubmit={handleSubmit} initialValues={initialValues}>
            {(formProps) =>
                <DefinitionTemplate icon={icon} tooltip={tooltip} name={definition.name} metadataKey={definition.key} onRetire={onRetire} onUpdate={formProps.handleSubmit} disabled={disabled}>
                    <Row className="mb-3">
                        <Col xs={12} sm={6}>
                            <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                        </Col>
                        <Col xs={12} sm={6}>
                            <legend className="col-form-label mb-3">Options</legend>
                            <FormGroup check>
                                <Label check for="isRequired">
                                    <Field id="isRequired" name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                                    Required
                                </Label>
                            </FormGroup>
                            <DefinitionFormBody definition={definition} disabled={disabled} />
                        </Col>
                    </Row>
                </DefinitionTemplate>
            }
        </Form>
    )
}

export interface ICommonFieldText {
    name: string
    key: string
    description: string
}

interface ICommonSettingsProps {
    fieldLabels?: ICommonFieldText
    fieldDescriptions?: ICommonFieldText
    disabled?: boolean
}

function CommonMetadataSettings({ fieldLabels: labels, fieldDescriptions: descriptions, disabled }: ICommonSettingsProps) {
    return (
        <>
            <legend className="col-form-label">General</legend>
            <FormGroup>
                <Label for="name">{labels ? labels.name : 'Name'}</Label>
                <Field id="name" name="name" component={ValidatedInput} disabled={disabled} />
                <FormText>{descriptions ? descriptions.name : 'Name of the field.'}</FormText>
            </FormGroup>
            <FormGroup>
                <Label for="key">{labels ? labels.key : 'Search Key'}</Label>
                <Field id="key" name="key" component={ValidatedInput} disabled={disabled} />
                <FormText>{descriptions ? descriptions.key : 'This will be used to identify this field when searching.'}</FormText>
            </FormGroup>
            <FormGroup>
                <Label for="description">{labels ? labels.description : 'Description'}</Label>
                <Field id="description" name="description" component={ValidatedInput} type="textarea" disabled={disabled} />
                <FormText>{descriptions ? descriptions.description : 'A short description which indicates how the field should be used.'}</FormText>
            </FormGroup>
        </>
    )
}

export interface IMetadataDefinitionFormData<TOps = {}> {
    name: string
    key: string
    description: string
    isRequired: boolean
    options?: TOps
}

export type OptionsOf<T> = T extends IMetadataDefinition<infer TOpts> ? TOpts : never

interface IOptionsProps<T extends IMetadataDefinition<OptionsOf<T>>> {
    definition: T
    disabled?: boolean
}

interface ICommonProps<T extends IMetadataDefinition<unknown>> {
    definition: T
    onRetire?: (key: string) => void
    onUpdate?: <TUpdate extends IUpdateMetadataDefinition<OptionsOf<T>>>(key: string, update: TUpdate) => Promise<void>
    fieldLabels?: ICommonFieldText
    fieldDescriptions?: ICommonFieldText
    disabled?: boolean
}

// An interface for components to handle formatting options from form values to submit values
interface IOptionsFormatter<TDefinition extends IMetadataDefinition<OptionsOf<TDefinition>>, TFormOpts> {
    formatInitializeOptions: (options: OptionsOf<TDefinition>) => TFormOpts
    formatSubmitOptions: (definition: TDefinition, options: TFormOpts) => OptionsOf<TDefinition>
}

type MetadataDefinitionOptionsComponent<T extends IMetadataDefinition<OptionsOf<T>>, TOpts> = React.ComponentType<IOptionsProps<T>> & IOptionsFormatter<T, TOpts>

export const BoolMetadataDefinition: MetadataDefinitionOptionsComponent<BoolDefinition, {}> = ({ disabled }) => {
    return null
}
BoolMetadataDefinition.formatInitializeOptions = (options) => ({})
BoolMetadataDefinition.formatSubmitOptions = (options) => ({})

interface INumericOpts {
    enforceRange: boolean
    minValue?: number
    maxValue?: number
    format?: string
}

export const NumericMetadataDefinition: MetadataDefinitionOptionsComponent<NumericDefinition, INumericOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup check>
                <Label check for="enforceRange">
                    <PrefixedField id="enforceRange" name="enforceRange" component={ValidatedInput} type="checkbox" disabled={disabled} />
                    Enforce Range
                </Label>
            </FormGroup>
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minValue">Minimum Value</Label>
                        <PrefixedField id="minValue" name="minValue" component={ValidatedInput} type="number" disabled={disabled} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxValue">Maximum Value</Label>
                        <PrefixedField id="maxValue" name="maxValue" component={ValidatedInput} type="number" disabled={disabled} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for="format">Format</Label>
                <PrefixedField id="format" name="format" component={ValidatedInput} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
NumericMetadataDefinition.formatInitializeOptions = (options) => options
NumericMetadataDefinition.formatSubmitOptions = (_, options) => options

interface ITextOpts {
    minLength?: number
    maxLength?: number
}

export const TextMetadataDefinition: MetadataDefinitionOptionsComponent<TextDefinition, ITextOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minLength">Minimum Length</Label>
                        <PrefixedField id="minLength" name="minLength" component={ValidatedInput} type="number" disabled={disabled} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxLength">Maximum Length</Label>
                        <PrefixedField id="maxLength" name="maxLength" component={ValidatedInput} type="number" disabled={disabled} />
                    </FormGroup>
                </Col>
            </Row>
        </FieldPrefix>
    )
}
TextMetadataDefinition.formatInitializeOptions = (options) => options
TextMetadataDefinition.formatSubmitOptions = (_, options) => options

const commonDateOptions: IOption<string>[] = [
    {
        label: 'DD/MM/YYYY',
        value: 'DD/MM/YYYY'
    },
    {
        label: 'YYYY-MM-DDTHH:mm:ssZ',
        value: 'YYYY-MM-DDTHH:mm:ssZ'
    }
]

interface IDateOpts {
    enforceRange: boolean
    minValue?: Date
    maxValue?: Date
    format: IOption<string>
}

export const DateMetadataDefinition: MetadataDefinitionOptionsComponent<DateDefinition, IDateOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup check>
                <Label check for="enforceRange">
                    <PrefixedField id="enforceRange" name="enforceRange" component={ValidatedInput} type="checkbox" disabled={disabled} />
                    Enforce Range
                </Label>
            </FormGroup>
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minValue">Minimum Date</Label>
                        <PrefixedField id="minValue" name="minValue" component={ValidatedDatePicker} disabled={disabled} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxValue">Maximum Date</Label>
                        <PrefixedField id="maxValue" name="maxValue" component={ValidatedDatePicker} disabled={disabled} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for="format">Format</Label>
                <PrefixedField id="format" name="format" component={ValidatedSelect} selectType="creatable" options={commonDateOptions} isDisabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}

DateMetadataDefinition.formatInitializeOptions = (options) => ({
    enforceRange: options.enforceRange,
    minValue: options.minValue,
    maxValue: options.maxValue,
    format: { label: options.format, value: options.format }
})
DateMetadataDefinition.formatSubmitOptions = (_, options) => ({
    enforceRange: options.enforceRange,
    minValue: options.minValue,
    maxValue: options.maxValue,
    format: options.format.value
})

interface ISelectOpts {
    enforceValues: boolean
    isMultiselect: boolean
    values: IOption<string>[]
}

export const SelectMetadataDefinition: MetadataDefinitionOptionsComponent<SelectDefinition, ISelectOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup check>
                <Label check for="enforceValues">
                    <PrefixedField id="enforceValues" name="enforceValues" component={ValidatedInput} type="checkbox" disabled={disabled} />
                    Enforce Options
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check for="isMultiselect">
                    <PrefixedField id="isMultiselect" name="isMultiselect" component={ValidatedInput} type="checkbox" disabled={disabled} />
                    Multi Select
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for="values">Options</Label>
                <PrefixedField id="values" name="values" component={ValidatedSelect} isMulti selectType="creatable" isDisabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
SelectMetadataDefinition.formatInitializeOptions = (options) => ({
    enforceValues: options.enforceValues,
    isMultiselect: options.isMultiselect,
    values: options.values.map(v => ({ label: v, value: v }))
})
SelectMetadataDefinition.formatSubmitOptions = (_, options) => ({
    enforceValues: options.enforceValues,
    isMultiselect: options.isMultiselect,
    values: options.values.map(x => x.value)
})

interface IDocumentLinkOpts {
    limit?: number
}

export const DocumentLinksMetadataDefinition: MetadataDefinitionOptionsComponent<DocumentLinksDefinition, IDocumentLinkOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
DocumentLinksMetadataDefinition.formatInitializeOptions = (options) => options
DocumentLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const DocumentPropertySelectOptions: IOption<DocumentEntityProps>[] = [
    {
        label: 'Author',
        value: DocumentEntityProps.Author
    },
    {
        label: 'Created By',
        value: DocumentEntityProps.CreatedBy
    },
    {
        label: 'Created Date',
        value: DocumentEntityProps.CreatedDate
    },
    {
        label: 'Description',
        value: DocumentEntityProps.Description
    },
    {
        label: 'Metadata',
        value: DocumentEntityProps.MetaData
    },
    {
        label: 'Name',
        value: DocumentEntityProps.Name
    },
    {
        label: 'Published',
        value: DocumentEntityProps.Published
    },
    {
        label: 'Revision Date',
        value: DocumentEntityProps.RevisionDate
    },
    {
        label: 'Revision Number',
        value: DocumentEntityProps.RevisionNo
    }
]

interface IDocumentPropertyOpts {
    property: typeof DocumentPropertySelectOptions[0]
}

export const DocumentPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<DocumentPropertyDefinition, IDocumentPropertyOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={DocumentPropertySelectOptions} isDisabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
DocumentPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: DocumentPropertySelectOptions.find(x => x.value === options.property)
})
DocumentPropertyMetadataDefinition.formatSubmitOptions = (definition, options) => ({
    property: options.property.value,
    parentMetadataKey: definition.options.parentMetadataKey
})

interface ITransmittalLinkOpts {
    limit?: number
}

export const TransmittalLinksMetadataDefinition: MetadataDefinitionOptionsComponent<TransmittalLinksDefinition, ITransmittalLinkOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
TransmittalLinksMetadataDefinition.formatInitializeOptions = (options) => options
TransmittalLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const TransmittalPropertySelectOptions: IOption<TransmittalEntityProps>[] = [
    {
        label: 'Created Date',
        value: TransmittalEntityProps.CreatedDate
    },
    {
        label: 'Reference Number',
        value: TransmittalEntityProps.ReferenceNo
    },
    {
        label: 'Response Date',
        value: TransmittalEntityProps.ResponseDate
    },
    {
        label: 'Response Type',
        value: TransmittalEntityProps.ResponseType
    },
    {
        label: 'Status',
        value: TransmittalEntityProps.Status
    },
    {
        label: 'Subject',
        value: TransmittalEntityProps.Subject
    }
]

interface ITransmittalPropertyOpts {
    property: typeof TransmittalPropertySelectOptions[0]
}

export const TransmittalPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<TransmittalPropertyDefinition, ITransmittalPropertyOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={TransmittalPropertySelectOptions} isDisabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
TransmittalPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: TransmittalPropertySelectOptions.find(x => x.value === options.property)
})
TransmittalPropertyMetadataDefinition.formatSubmitOptions = (definition, options) => ({
    property: options.property.value
})

interface ICompanyLinkOpts {
    limit?: number
}

export const CompanyLinksMetadataDefinition: MetadataDefinitionOptionsComponent<CompanyLinksDefinition, ICompanyLinkOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
CompanyLinksMetadataDefinition.formatInitializeOptions = (options) => options
CompanyLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const CompanyPropertySelectOptions: IOption<CompanyEntityProps>[] = [
    {
        label: 'Abbreviated Name',
        value: CompanyEntityProps.AbbreviatedName
    },
    {
        label: 'Name',
        value: CompanyEntityProps.Name
    },
    {
        label: 'Disciplines',
        value: CompanyEntityProps.Disciplines
    }
]

interface ICompanyPropertyOpts {
    property: typeof CompanyPropertySelectOptions[0]
}

export const CompanyPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<CompanyPropertyDefinition, ICompanyPropertyOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label name="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={CompanyPropertySelectOptions} isDisabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
CompanyPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: CompanyPropertySelectOptions.find(x => x.value === options.property)
})
CompanyPropertyMetadataDefinition.formatSubmitOptions = (_, options) => ({
    property: options.property.value
})

interface IUserLinkOpts {
    limit?: number
}

export const UserLinksMetadataDefinition: MetadataDefinitionOptionsComponent<UserLinksDefinition, IUserLinkOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
UserLinksMetadataDefinition.formatInitializeOptions = (options) => options
UserLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const UserPropertySelectOptions: IOption<UserEntityProps>[] = [
    {
        label: 'Email',
        value: UserEntityProps.Email
    },
    {
        label: 'Mobile',
        value: UserEntityProps.Mobile
    },
    {
        label: 'Name',
        value: UserEntityProps.Name
    },
    {
        label: 'Role',
        value: UserEntityProps.Role
    }
]

interface IUserPropertyOpts {
    property: typeof UserPropertySelectOptions[0]
}

export const UserPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<UserPropertyDefinition, IUserPropertyOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={UserPropertySelectOptions} isDisabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
UserPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: UserPropertySelectOptions.find(x => x.value === options.property)
})
UserPropertyMetadataDefinition.formatSubmitOptions = (definition, options) => ({
    property: options.property.value
})

interface IEmailLinkOpts {
    limit?: number
}

export const EmailLinksMetadataDefinition: MetadataDefinitionOptionsComponent<EmailLinksDefinition, IEmailLinkOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
EmailLinksMetadataDefinition.formatInitializeOptions = (options) => options
EmailLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

const EmailPropertySelectOptions: IOption<EmailEntityProps>[] = [
    {
        label: 'Bcc',
        value: EmailEntityProps.Bcc
    },
    {
        label: 'Cc',
        value: EmailEntityProps.Cc
    },
    {
        label: 'Date',
        value: EmailEntityProps.Date
    },
    {
        label: 'From',
        value: EmailEntityProps.From
    },
    {
        label: 'Subject',
        value: EmailEntityProps.Subject
    },
    {
        label: 'To',
        value: EmailEntityProps.To
    }
]

interface IEmailPropertyOpts {
    property: typeof EmailPropertySelectOptions[0]
}

export const EmailPropertyMetadataDefinition: MetadataDefinitionOptionsComponent<EmailPropertyDefinition, IEmailPropertyOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Label for="property">Property</Label>
                <PrefixedField id="property" name="property" component={ValidatedSelect} options={EmailPropertySelectOptions} isDisabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
EmailPropertyMetadataDefinition.formatInitializeOptions = (options) => ({
    property: EmailPropertySelectOptions.find(x => x.value === options.property)
})
EmailPropertyMetadataDefinition.formatSubmitOptions = (_, options) => ({
    property: options.property.value
})

interface IAutoNumberOpts {
    displayPrefix: boolean
    minValue: number
    maxValue: number
}

export const AutoNumberMetadataDefinition: MetadataDefinitionOptionsComponent<AutoNumberDefinition, IAutoNumberOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup>
                <Field name="displayPrefix" component={ValidatedCheckboxRadio} label="Display prefix" disabled={disabled} />
            </FormGroup>
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <Label for="minValue">Minimum Value</Label>
                        <PrefixedField id="minValue" name="minValue" component={ValidatedInput} type="number" disabled={disabled} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="maxValue">Maximum Value</Label>
                        <PrefixedField id="maxValue" name="maxValue" component={ValidatedInput} type="number" disabled={disabled} />
                    </FormGroup>
                </Col>
            </Row>
        </FieldPrefix>
    )
}
AutoNumberMetadataDefinition.formatInitializeOptions = (options) => options
AutoNumberMetadataDefinition.formatSubmitOptions = (_, options) => options

interface ICommitmentLinkOpts {
    limit?: number
}

export const CommitmentLinksMetadataDefinition: MetadataDefinitionOptionsComponent<CommitmentLinksDefinition, ICommitmentLinkOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
CommitmentLinksMetadataDefinition.formatInitializeOptions = (options) => options
CommitmentLinksMetadataDefinition.formatSubmitOptions = (_, options) => options

interface IPaymentClaimLinkOpts {
    limit?: number
}

export const PaymentClaimLinksMetadataDefinition: MetadataDefinitionOptionsComponent<PaymentClaimLinksDefinition, IPaymentClaimLinkOpts> = ({ disabled }) => {
    return (
        <FieldPrefix prefix="options">
            <FormGroup className="mt-2">
                <Label for="limit">Limit</Label>
                <PrefixedField id="limit" name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
            </FormGroup>
        </FieldPrefix>
    )
}
PaymentClaimLinksMetadataDefinition.formatInitializeOptions = (options) => options
PaymentClaimLinksMetadataDefinition.formatSubmitOptions = (_, options) => options
