import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import NewTemplateModal from '@src/components/communication/NewTemplateModal'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { ISearchResult, SearchSectionType } from '@src/components/search/SearchSection'
import { TemplateDelete, TemplatesList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { TemplateBrief } from '@src/types/communication'
import { Project } from '@src/types/project'

interface IProps {
    project?: Project
}

interface IState {
    isCreating: boolean
    isDeleting: boolean
    toDelete: TemplateBrief
}

export default class TemplatesSection extends React.PureComponent<IProps & RouteComponentProps, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<TemplateBrief, 'id'>>

    constructor(props) {
        super(props)

        this.searchSectionRef = React.createRef()

        this.state = {
            isCreating: false,
            isDeleting: false,
            toDelete: null
        }
    }

    private readonly onSearch = async (filter: string, sort: string, page: number, perPage: number, abortSignal: AbortSignal): Promise<ISearchResult<TemplateBrief>> => {
        const filterCompanyTemplatesOnly = `project: ${this.props.project?.id ?? 'null'} AND ${filter}`
        const templatesResponse = await TemplatesList(filterCompanyTemplatesOnly, sort, page, perPage, { abortSignal })
        return ({
            items: templatesResponse.data,
            totalItems: +templatesResponse.headers[Headers.PaginationTotalCount]
        })
    }

    private readonly setIsCreating = () => {
        this.setState({ isCreating: true })
    }

    private readonly clearIsCreating = () => {
        this.setState({ isCreating: false })
    }

    private readonly setTemplateToDelete = (template: TemplateBrief) => {
        this.setState({ isDeleting: true, toDelete: template })
    }

    private readonly clearIsDeleting = () => {
        this.setState({ isDeleting: false })
    }

    private readonly clearToDelete = () => {
        this.setState({ toDelete: null })
    }

    private readonly handleDelete = async () => {
        const { toDelete: template } = this.state
        await TemplateDelete(template.id)
        NotificationService.info('Deleted template')
        this.setState({ isDeleting: false })
        this.searchSectionRef.current.doSearch()
    }

    private readonly handleTemplateCreated = () => {
        this.searchSectionRef.current.doSearch()
        this.setState({ isCreating: false })
    }

    public render() {
        const { project } = this.props
        const { isCreating, isDeleting, toDelete } = this.state
        const noTemplatesFoundMessage = (
            <>
                <div className="my-3"><FA size="3x" icon="file-times" /></div>
                <p className="lead">No templates found with the current search criteria...</p>
                <p>{"Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters."}</p>
            </>
        )

        return (
            <SearchSection
                ref={this.searchSectionRef}
                headers={[
                    {
                        name: 'Name',
                        noSmallHeader: true,
                        sortKey: 'name',
                        sortable: true,
                        overrideRenderer: t => <Link to={project ? Routes.projectTemplate(project.id, t.id) : Routes.companyTemplate(t.id)}>{t.name}</Link>
                    },
                    {
                        name: 'Code',
                        sortKey: 'code',
                        sortable: true,
                        overrideRenderer: t => t.key
                    },
                    {
                        name: 'Description',
                        overrideRenderer: t => t.description
                    },
                    {
                        name: 'Last Updated',
                        sortKey: 'updated',
                        sortable: true,
                        overrideRenderer: t => localShortDate(t.updatedDate)
                    },
                    {
                        name: 'Actions',
                        noSmallHeader: true,
                        overrideRenderer: t => (
                            <div className="justify-content-end text-right selectable-content__actions">
                                <TooltipLinkAction id={`delete-${t.id}`} tooltip="Delete" data={t} onClick={this.setTemplateToDelete}><FA icon="trash" /></TooltipLinkAction>
                            </div>
                        )
                    }
                ]}
                onSearch={this.onSearch}
                noItemsFoundMessage={noTemplatesFoundMessage}
                extraSearchBarElements={[
                    {
                        element: _ => <Button onClick={this.setIsCreating}><FA icon="plus" className="mr-1" />New Template</Button>,
                        position: 'before'
                    }
                ]}
                searchAssistantProperties={[
                    {
                        name: 'Name',
                        searchKey: 'name',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Code',
                        searchKey: 'code',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Description',
                        searchKey: 'description',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Last Updated',
                        searchKey: 'updated',
                        type: PropertyType.Text
                    }
                ]}
            >
                <NewTemplateModal
                    isOpen={isCreating}
                    toggle={this.clearIsCreating}
                    projectId={project?.id}
                    onTemplateCreated={this.handleTemplateCreated}
                />
                <ConfirmationModal
                    danger
                    isOpen={isDeleting}
                    header="Delete Group"
                    onClosed={this.clearToDelete}
                    toggle={this.clearIsDeleting}
                    message={toDelete &&
                        <div>
                            <span>Are you sure you want to delete <strong>{toDelete.name}</strong>?</span>
                        </div>
                    }
                    onConfirm={this.handleDelete}
                    onReject={this.clearIsDeleting}
                    confirmAction="Yes, delete"
                />
            </SearchSection>
        )
    }
}
