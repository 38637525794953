import React from 'react'
import { Button } from 'reactstrap'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'

interface IProps {
    icon: IPFontawesomeIcon
    buttonText: string
    onClick: () => void
    messageText: string
}

export default function EmptyResourceArea({ icon, buttonText, onClick, messageText }: IProps) {
    return (
        <div className="text-center">
            <div className="my-3"><FA size="3x" icon={icon} /></div>
            <p className="lead"><Button onClick={onClick}>{buttonText}</Button></p>
            <p>{messageText}</p>
        </div>
    )
}
