import React from 'react'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps {
    message?: string
}

export default function LoadingMessage({ message = 'Loading...' }: IProps) {
    return <div className="text-center"><span className="lead">{message}</span><FA icon="spinner-third" spin /></div>
}
