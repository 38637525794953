import React from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import cx from 'classnames'
import type { FormApi } from 'final-form'
import shallow from 'zustand/shallow'

import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import AdvancedAddColumnModal from '@src/components/register/AdvancedAddColumn'
import useBoolean from '@src/hooks/useBoolean'

import { RegisterColumnAdd } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { slugify } from '@src/logic/utils/Strings'
import useRegisterStore from '@src/store/register'
import { BaseMetadataTypes, MetadataTypes } from '@src/types/metadata'

export interface INewColumnFormData<TOptions> {
    name: string
    key: string
    type: MetadataTypes
    description: string
    tags: string[]
    isRequired: boolean
    options: TOptions
    parentKey?: IOption<string>
}

export function formatOptions(type: MetadataTypes, options: INewColumnFormData<any>['options']) {
    switch (type) {
        case MetadataTypes.AutoNumber:
        case MetadataTypes.Text:
        case MetadataTypes.Numeric:
        case MetadataTypes.Select:
        case MetadataTypes.Bool:
        case MetadataTypes.DocumentLinks:
        case MetadataTypes.TransmittalLinks:
        case MetadataTypes.CompanyLinks:
        case MetadataTypes.UserLinks:
        case MetadataTypes.EmailLinks:
        case MetadataTypes.CommitmentLinks:
        case MetadataTypes.PaymentClaimLinks:
            return options
        case MetadataTypes.Date:
            return options?.format
                ? {
                    enforceRange: options.enforceRange,
                    minValue: options.minValue,
                    maxValue: options.maxValue,
                    format: options.format.value
                }
                : options
        case MetadataTypes.DocumentProperty:
        case MetadataTypes.TransmittalProperty:
        case MetadataTypes.CompanyProperty:
        case MetadataTypes.UserProperty:
        case MetadataTypes.EmailProperty:
        case MetadataTypes.CommitmentProperty:
        case MetadataTypes.PaymentProperty:
            return {
                property: options.property
            }
        default:
            return null
    }
}

export default function AddColumnHeader() {
    const [register, reloadRegister] = useRegisterStore(s => [s.register, s.refresh], shallow)
    const registerId = register.id
    const usingAdvancedColumnModal = useBoolean(false)

    async function addMetadataDefinition(values: INewColumnFormData<unknown>, form: FormApi): Promise<void> {
        try {
            await RegisterColumnAdd(registerId, {
                metadataDefinition: {
                    name: values.name,
                    key: values.key,
                    type: values.type as unknown as BaseMetadataTypes,
                    description: values.description,
                    tags: values.tags,
                    isRequired: values.isRequired,
                    options: formatOptions(values.type, values.options),
                    ...(values.parentKey ? { parentKey: values.parentKey.value } : {})
                },
                styles: {
                    width: null
                }
            })
            NotificationService.info('Column added')
            reloadRegister()
            form.reset()
        } catch (error) {
            NotificationService.error('Unable to add column')
        }
    }

    return (
        <Form onSubmit={addMetadataDefinition}>
            {({ handleSubmit, form }) => (
                <div className="column-dropdown__content" style={{ paddingLeft: register.columns.length > 0 ? '1rem' : 'none' }}>
                        <button onClick={usingAdvancedColumnModal.setTrue} className={cx({ 'btn btn-secondary': register.columns.length === 0, 'column-dropdown__column-button column-dropdown__column-button--add': register.columns.length > 0, 'column-dropdown__add-first-column-button': register.columns.length === 0 })}>
                        {register.columns.length > 0 ? <FA icon="plus" className="align-top" size="xs" color="black"/> : 'Add register columns'}
                        </button>
                    <AdvancedAddColumnModal isOpen={usingAdvancedColumnModal.value} toggle={usingAdvancedColumnModal.toggle} existingColumns={register.columns}/>
                    <OnChange name="name">
                        {(name) => form.change('key', slugify(name))}
                    </OnChange>
                </div>
            )}
        </Form>
    )
}
