import React from 'react'

import shallow from 'zustand/shallow'

import { ISpreadsheetColumn } from '@src/components/spreadsheet/Spreadsheet'
import { useSpreadsheetStore } from '@src/components/spreadsheet/SpreadsheetContext'
import { useColumnWidthListener } from '@src/components/spreadsheet/useColumnWidthListener'

interface ICellProps<TCol, TRow> {
    col: ISpreadsheetColumn<TCol, TRow>
    row: TRow
    getRowId: (row: TRow) => string
    spreadsheetPortalTarget: HTMLElement
}

export interface ICellRenderProps<TCol, TRow> extends ICellProps<TCol, TRow> {
    isFocused: boolean
}

export default function SpreadsheetCell<TCol, TRow>({ col, row, getRowId, spreadsheetPortalTarget }: ICellProps<TCol, TRow>) {
    const divRef = React.useRef<HTMLDivElement>()
    const [isFocused, focusCell] = useSpreadsheetStore(React.useCallback(x => [x.isCellFocused(col, row, getRowId), x.focusCell], [col, row]), shallow)
    useColumnWidthListener(col.id, divRef)
    const Cell = col.CellComponent

    function setFocus() {
        focusCell(col, row)
    }

    React.useEffect(() => {
        if (isFocused) divRef.current.focus()
    }, [isFocused])

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <div className="spreadsheet__row-cell" onClick={setFocus} tabIndex={-1} role="cell" ref={divRef}
            style={{
                boxShadow: isFocused ? '0px 0px 0px 1px var(--tertiary) inset' : null,
                margin: isFocused ? '-1px 1px 0px -1px' : null,
                border: isFocused ? '1px solid var(--tertiary)' : null,
                transition: 'box-shadow 0.1s',
                backgroundColor: col.styles.backgroundColor?.(col.data, row)
            }}
        >
            <Cell row={row} col={col} isFocused={isFocused} getRowId={getRowId} spreadsheetPortalTarget={spreadsheetPortalTarget} />
        </div>
    )
}
