import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Col, Label, Row } from 'reactstrap'

import { IOption, buildOptions } from '@src/components/common/Select'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { FFMetadataInput } from '@src/components/metadata/MetadataInputs'
import MetadataTooltip from '@src/components/metadata/MetadataTooltip'
import { required } from '@src/logic/forms/validation'
import { Revision } from '@src/types/document'
import { IMetadataForm } from '@src/types/metadata'

interface IProps extends IMetadataForm {
    revision: Revision
    onSaveChanges: (values: IRevisionEditFormData) => Promise<void>
}

export interface IRevisionEditFormData {
    name: string
    revNumber: string
    revDate: Date
    author: string
    tags: IOption<string>[]
    description: string
    metadata: {
        [key: string]: any
    }
}

const formIdPrefix = 'edit_revision-'

export default function RevisionEditForm({ definitions, revision, onSaveChanges }: IProps) {
    const initialValues = React.useMemo(() => ({
        name: revision.name,
        revNumber: revision.revNumber,
        revDate: revision.revDate,
        author: revision.author,
        tags: buildOptions(revision.tags),
        description: revision.description,
        metadata: definitions.map(d => d.key).reduce((prev, definitionKey) => ({ ...prev, [definitionKey]: revision.metadata[definitionKey] }), {})
    }), [definitions, revision])

    return (
        <Form
            onSubmit={onSaveChanges}
            initialValues={initialValues}
        >
            {({ submitting, pristine, form, handleSubmit }) => (
                <>
                    <h5>Common Info</h5>
                    <Row>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <Label for={`${formIdPrefix}name`}>Revision Name</Label>
                            <Field id={`${formIdPrefix}name`} name="name" component={ValidatedInput} validate={required} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <Label for={`${formIdPrefix}revNumber`}>Revision #</Label>
                            <Field id={`${formIdPrefix}revNumber`} name="revNumber" component={ValidatedInput} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <Label for={`${formIdPrefix}revDate`}>Revision Date</Label>
                            <Field id={`${formIdPrefix}revDate`} name="revDate" component={ValidatedDatePicker} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <Label for={`${formIdPrefix}author`}>Author</Label>
                            <Field id={`${formIdPrefix}author`} name="author" component={ValidatedInput} />
                        </Col>
                        <Col xs={12} md={6} lg={9} className="mb-3">
                            <Label for={`${formIdPrefix}description`}>Description</Label>
                            <Field id={`${formIdPrefix}description`} name="description" component={ValidatedInput} type="textarea" />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <Label for={`${formIdPrefix}tags`}>Tags</Label>
                            <Field id={`${formIdPrefix}tags`} name="tags" isMulti selectType="creatable" component={ValidatedSelect} />
                        </Col>
                    </Row>
                    {definitions.length > 0 &&
                        <Row>
                            <Col xs={12}>
                                <h5>Metadata</h5>
                            </Col>
                            {definitions.map(d => (
                                <Col key={d.key} xs={12} md={6} lg={3} className="mb-3">
                                    <Label for={`${formIdPrefix}metadata.${d.key}`}>{d.name} <MetadataTooltip id={'meta-tooltip-' + d.key} definition={d} placement="right" /></Label>
                                    <FFMetadataInput id={`${formIdPrefix}metadata.${d.key}`} name={`metadata.${d.key}`} definition={d} />
                                </Col>
                            ))}
                        </Row>
                    }
                    <Button disabled={submitting || pristine} onClick={handleSubmit} className="mr-2">Save Changes</Button>
                    <Button color="warning" disabled={pristine || submitting} onClick={() => form.reset()}>Reset</Button>
                </>
            )}
        </Form>
    )
}
