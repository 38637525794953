import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form, FormProps } from 'react-final-form'
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { IOption, buildOptions } from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalProps } from '@src/hooks/useModal'
import { composeValidators, required } from '@src/logic/forms/validation'
import { CostCodesList } from '@src/logic/http/Api'
import { CostCodeBrief } from '@src/types/costs'

export interface ICostCodeFormData {
    group: IOption<string>
    code: string
    name: string
    clientGroup: string
    clientCode: string
    clientName: string
}

interface IProps extends IModalProps {
    projectId: string
    existingCode?: CostCodeBrief
    onSubmit: FormProps['onSubmit']
}

export default function CostCodeForm({ projectId, existingCode, isOpen, toggle, onClosed, onSubmit }: IProps & FormProps<ICostCodeFormData>) {
    const costCodes = useAsyncAbortable(async (abortSignal, pid) => (await CostCodesList(pid, 1, 200, { abortSignal })).data, [projectId])

    function normalizeCode(code: IOption<string>) { return code ? { ...code, value: code.value.toUpperCase() } : code }

    function uniqueCodeValidate(value: string, allValues: ICostCodeFormData) {
        if (costCodes.result == null) return

        if (existingCode && existingCode.code === value) return undefined

        const code = costCodes.result.find(c => c.code === value && c.group === allValues.group.value)

        return code == null ? undefined : `Code needs to be unique - already used by '${code.code} ${code.name}'`
    }

    return (
        <Form
            initialValues={existingCode
                ? {
                    group: { label: existingCode.group, value: existingCode.group },
                    code: existingCode.code,
                    name: existingCode.name,
                    clientGroup: existingCode.clientGroup,
                    clientCode: existingCode.clientCode,
                    clientName: existingCode.clientName
                }
                : undefined}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) =>
                <Modal size="lg" isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
                    <ModalHeader toggle={toggle}>{existingCode ? 'Edit Cost Code' : 'New Cost Code'}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="costCode-group">Group</Label>
                                    <Field
                                        id="costCode-group"
                                        name="group"
                                        component={ValidatedSelect}
                                        selectType="creatable"
                                        validate={required}
                                        format={normalizeCode}
                                        isLoading={costCodes.loading}
                                        options={costCodes.result && buildOptions([...new Set(costCodes.result.map(c => c.group))])}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="costCode-clientGroup">Client Group</Label>
                                    <Field id="costCode-clientGroup" name="clientGroup" component={ValidatedInput} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="costCode-code">Code</Label>
                                    <Field id="costCode-code" name="code" component={ValidatedInput} disabled={existingCode != null} validate={composeValidators(required, uniqueCodeValidate)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="costCode-clientCode">Client Code</Label>
                                    <Field id="costCode-clientCode" name="clientCode" component={ValidatedInput} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="costCode-name">Name</Label>
                                    <Field id="costCode-name" name="name" component={ValidatedInput} validate={required} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="costCode-clientName">Client Name</Label>
                                    <Field id="costCode-clientName" name="clientName" component={ValidatedInput} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleSubmit}>{existingCode ? 'Save' : 'Create'}</Button>
                    </ModalFooter>
                </Modal>
            }
        </Form>
    )
}
