import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field } from 'react-final-form'
import { Button } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import DocumentLinkModal from '@src/components/register/tablefields/DocumentLinkModal'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import GenericSelectDropdown from '@src/components/register/tablefields/GenericSelectDropdown'
import useModal from '@src/hooks/useModal'
import useProject from '@src/hooks/useProject'
import useTouched from '@src/hooks/useTouched'
import useProjectWidget from '@src/hooks/useWidget'
import { documentLinkLabel, documentLinkValue, revisionLabel, revisionValue } from '@src/logic/forms/SelectHelpers'
import { RevisionsList } from '@src/logic/http/Api'
import { DocumentLink } from '@src/types/document'
import { DocumentLinksDefinition } from '@src/types/metadata'

const NO_OPTIONS_MESSAGE = () => 'Add documents to your widget'

export default function DocumentLinkField(props: IRegisterTableField<DocumentLinksDefinition>) {
    const value = props.row.cells[props.col.id] as DocumentLink[] | null
    const [project] = useProject()
    const widget = useProjectWidget()
    const options = React.useMemo(() => (value ?? []).concat(widget.revisions.map<DocumentLink>(x => ({ documentId: x.documentId, revisionId: x.id, name: x.name }))), [widget.revisions, value])
    const displayDocumentLinkModal = useModal(false, { destroyOnClose: true })
    const isTouched = useTouched(props.isFocused, [value])

    const revisionsAsync = useAsyncAbortable(async (abortSignal) => {
        const response = await RevisionsList(project.id, `id: ${value.map(x => x.revisionId).join(',')}`, undefined, undefined, undefined, { abortSignal })
        return response.data.revisions
    }, [], { executeOnMount: false, executeOnUpdate: false })

    React.useEffect(() => {
        if (displayDocumentLinkModal.modalProps.isOpen) revisionsAsync.execute()
    }, [displayDocumentLinkModal.modalProps.isOpen])

    function handleOpenTable(e: React.MouseEvent) {
        e.stopPropagation()
        displayDocumentLinkModal.actions.show()
    }

    return (
        <>
            {isTouched
                ? (
                    <Field name={`cells.r-${props.row.id}.c-${props.col.id}`} initialValue={value} validateFields={[]}>
                        {({ input }) =>
                            <>
                                {props.isFocused
                                    ? <>
                                        <GenericSelectDropdown
                                            {...props}
                                            value={input.value}
                                            onChange={input.onChange}
                                            getOptionLabel={documentLinkLabel}
                                            getOptionValue={documentLinkValue}
                                            options={options}
                                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                                        />
                                    </>
                                    : (
                                        <span className="register__select-span d-inline-flex overflow-hidden align-items-center">
                                            {input.value?.length
                                                ? <>
                                                    <span className="text-truncate flex-grow-1">{input.value[0].name}</span>
                                                    <span className="register__link-button-span">
                                                        <Button color="link" className="register__external-link-button" onClick={handleOpenTable}>
                                                            <FA icon="external-link-alt" />
                                                        </Button>
                                                    </span>
                                                </>
                                                : <span className="register__placeholder" />
                                            }
                                        </span>
                                    )}
                            </>
                        }
                    </Field>
                )
                : (
                    <span className="register__select-span d-inline-flex overflow-hidden align-items-center">
                        {value?.length
                            ? <>
                                <span className="text-truncate flex-grow-1">{value[0].name}</span>
                                <span className="register__link-button-span">
                                    <Button color="link" className="register__external-link-button" onClick={handleOpenTable}>
                                        <FA icon="external-link-alt" />
                                    </Button>
                                </span>
                            </>
                            : <span className="register__placeholder" />
                        }
                    </span>
                )
            }
            <DocumentLinkModal {...displayDocumentLinkModal.modalProps} revisions={revisionsAsync.result ?? []} colName={props.col.name} />
        </>
    )
}

export function DocumentLinkFieldFooter(props: IRegisterTableField<DocumentLinksDefinition>) {
    const widget = useProjectWidget()
    const options = React.useMemo(() => widget.revisions.map<DocumentLink>(x => ({ documentId: x.documentId, revisionId: x.id, name: x.name })), [widget.revisions])

    return (
        <Field name={`cells.c-${props.col.id}`}>
            {({ input }) =>
                <GenericSelectDropdown
                    {...props}
                    isFocused={true}
                    onChange={input.onChange}
                    value={input.value}
                    getOptionLabel={revisionLabel}
                    getOptionValue={revisionValue}
                    options={options}
                />
            }
        </Field>
    )
}
