import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { Form as BootstrapForm, Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import BigNumber from 'bignumber.js'

import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { entries, mapValues, sumBy, values } from 'lodash'
import { filter, flow, keys, map, uniqBy } from 'lodash/fp'

import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import PaymentInfoCard from '@src/components/costs/payments/PaymentInfoCard'
import PaymentItemsTable, { IPaymentClaimItemFormData } from '@src/components/costs/payments/PaymentItemsTable'
import { FieldPrefix } from '@src/components/forms/FieldPrefix'
import NavigationPrompt from '@src/components/navigation/NavigationPrompt'
import { normalizePercent } from '@src/logic/forms/normalization'
import { CommitmentGet, CommitmentItemsList, PaymentClaimLock, PaymentClaimUnlock, PaymentClaimUpdate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { formatCurrency } from '@src/logic/utils/Currency'
import { Api } from '@src/types/api'
import { CostsOverview, PaymentClaim, PaymentClaimBrief, PaymentClaimItem, PaymentClaimStatus } from '@src/types/costs'

export interface IPaymentClaimItemsTableFormData {
    items: {
        [type: string]: {
            [commitmentItemId: string]: IPaymentClaimItemFormData
        }
    }
    totals: {
        [type: string]: {
            determined: number
            determinedPct: number
            previouslyCertified: number
            certified: number
            claimed: number
            variance: number
            value: number
            gst: number
            paid: number
        }
    }
    grandTotal: {
        determined: number
        previouslyCertified: number
        certified: number
        gst: number
        totalIncGst: number
    }
}

interface IPaymentClaimItemsTableFormProps {
    costsOverview: CostsOverview
    projectId: string
    paymentClaim: PaymentClaim
    reloadPaymentClaim: (payment?: PaymentClaim) => void
}

const calculatedDecorator = createDecorator<IPaymentClaimItemsTableFormData>(
    {
        field: /^totals\..+\.determined$/,
        updates: (determined: number, name, allValues) => {
            const formTypeId = name.split('.')[1]
            const determinedPctField = `totals.${formTypeId}.determinedPct`
            const certifiedField = `totals.${formTypeId}.certified`
            const update = {
                // TODO: add total value
                [determinedPctField]: new BigNumber(determined).div(allValues.totals[formTypeId].value).times(100).decimalPlaces(2).toNumber() || 0,
                [certifiedField]: new BigNumber(determined ?? 0).minus(allValues.totals[formTypeId]?.previouslyCertified).decimalPlaces(2).toNumber(),
                'grandTotal.determined': new BigNumber(values(allValues.totals).reduce((agg, i) => agg + i.determined, 0)).decimalPlaces(2).toNumber()
            }
            return update
        }
    },
    {
        field: /^items\..+\..+\.determined$/,
        updates: (_, name, allValues) => {
            const formTypeId = name.split('.')[1]
            const field = `totals.${formTypeId}.determined`
            return {
                // TODO: add total value
                [field]: new BigNumber(values(allValues.items[formTypeId]).reduce((agg, i) => agg + i.determined, 0)).decimalPlaces(2).toNumber()
            }
        }
    },
    {
        field: /^items\..+\..+\.claimed$/,
        updates: (_, name, allValues) => {
            const formTypeId = name.split('.')[1]
            const claimedField = `totals.${formTypeId}.claimed`
            return {
                [claimedField]: new BigNumber(values(allValues.items[formTypeId]).reduce((agg, i) => agg + i.claimed, 0)).decimalPlaces(2).toNumber()
            }
        }
    },
    {
        field: /^items\..+\..+\.(certified|claimed)$/,
        updates: (_, name, allValues) => {
            const parts = name.split('.')
            const formTypeId = parts[1]
            const formItemId = parts[2]
            const itemVarianceField = `items.${formTypeId}.${formItemId}.variance`
            return {
                [itemVarianceField]: new BigNumber(allValues.items[formTypeId][formItemId].certified).minus(allValues.items[formTypeId][formItemId].claimed).decimalPlaces(2).toNumber()
            }
        }
    },
    {
        field: /^totals\..+\.(claimed|certified)$/,
        updates: (_, name, allValues) => {
            const formTypeId = name.split('.')[1]
            const field = `totals.${formTypeId}.variance`
            return {
                [field]: new BigNumber(allValues.totals[formTypeId].certified).minus(allValues.totals[formTypeId].claimed).decimalPlaces(2).toNumber()
            }
        }
    },
    {
        field: /^items\..+\..+\.paid$/,
        updates: (_, name, allValues) => {
            const formTypeId = name.split('.')[1]
            const totalPaidfield = `totals.${formTypeId}.paid`
            return {
                [totalPaidfield]: new BigNumber(sumBy(values(allValues.items[formTypeId] ?? {}), i => i.paid)).decimalPlaces(2).toNumber()
            }
        }
    },
    {
        field: /^items\..+\..+\.gst$/,
        updates: (_, name, allValues) => {
            const formTypeId = name.split('.')[1]
            const totalGstfield = `totals.${formTypeId}.gst`
            return {
                [totalGstfield]: new BigNumber(sumBy(values(allValues.items[formTypeId] ?? {}), i => i.gst || 0)).decimalPlaces(2).toNumber()
            }
        }
    },
    {
        field: /^totals\..+\.gst$/,
        updates: {
            'grandTotal.gst': (_, allValues) => sumBy(values(allValues.totals), i => i.gst || 0)
        }
    },
    {
        field: 'grandTotal.determined',
        updates: {
            'grandTotal.certified': (determined: number, allValues) => new BigNumber(determined - allValues.grandTotal.previouslyCertified).decimalPlaces(2).toNumber()
        }
    },
    {
        field: /^grandTotal\.(certified|gst)$/,
        updates: {
            'grandTotal.totalIncGst': (_, allValues) => new BigNumber(allValues.grandTotal.certified + allValues.grandTotal.gst).decimalPlaces(2).toNumber()
        }
    }
)

function isLockUnlockDisabled(currentClaim: PaymentClaim, ...otherCommitmentClaims: PaymentClaimBrief[]) {
    if (currentClaim == null) return true

    if (currentClaim.status === PaymentClaimStatus.Generated) return true

    if (currentClaim.status === PaymentClaimStatus.Entered) return false

    return otherCommitmentClaims.find(x => x.status === PaymentClaimStatus.Entered) != null
}

const paymentItemsFormDecorators = [calculatedDecorator]

// Final-form cannot have form names that start with numbers.
// Therefore, we prefix dynamic members (types and commitment item id's) so that we never get into a situation that would crash final-form
export const formTypeIdPrefix = 't-'
export const formItemIdPrefix = 'ci-'

export function getFormTypeId(formType: string) {
    return `${formTypeIdPrefix}${formType}`
}

export function getFormItemId(itemId: string) {
    return `${formItemIdPrefix}${itemId}`
}

export default function PaymentClaimItemsTableForm({ costsOverview, paymentClaim, projectId, reloadPaymentClaim }: IPaymentClaimItemsTableFormProps) {
    const commitmentAsync = useAsyncAbortable(
        async (abortSignal, _pid?, _cid?, _payId?) => (await CommitmentGet(projectId, paymentClaim.commitment.id, { abortSignal })).data,
        [projectId, paymentClaim.commitment.id, paymentClaim.id]
    )

    const commitmentItemsAsync = useAsyncAbortable(
        async (abortSignal, _pid: string, _cid: string, _payId: string) => {
            const response = await CommitmentItemsList(projectId, paymentClaim.commitment.id, true, { abortSignal })
            return {
                commitmentItems: response.data,
                commitmentItemType: response.data.reduce((agg, item) => ({ ...agg, [item.id]: item.parentCommitment.type }), {})
            }
        },
        [projectId, paymentClaim.commitment.id, paymentClaim.id]
    )

    async function toggleLockClaim() {
        if (paymentClaim.status === PaymentClaimStatus.Entered) {
            await PaymentClaimLock(projectId, paymentClaim.commitment.id, paymentClaim.id)
        } else if (paymentClaim.status === PaymentClaimStatus.Locked) {
            await PaymentClaimUnlock(projectId, paymentClaim.commitment.id, paymentClaim.id)
        }

        commitmentAsync.execute()
        reloadPaymentClaim()
    }

    const commitmentItems = commitmentItemsAsync.result?.commitmentItems

    const itemsByType = React.useMemo(
        () => {
            if (commitmentItems == null) return {}
            return paymentClaim.items.reduce<{ [type: string]: PaymentClaimItem[] }>(
                (agg, item) => {
                    const itemType = commitmentItems.find(x => x.id === item.commitmentItem.id).parentCommitment.type
                    return ({ ...agg, [itemType]: (agg[itemType] ?? []).concat(item) })
                },
                { [paymentClaim.commitment.type]: [] })
        },
        [paymentClaim, commitmentItems]
    )

    const subCommitmentTypes = React.useMemo(() =>
        commitmentItems
            ? [...new Set(commitmentItems.filter(x => x.parentCommitment.id !== paymentClaim.commitment.id).map(x => x.parentCommitment.type))]
            : []
        , [commitmentItems])

    const initialValues: IPaymentClaimItemsTableFormData = React.useMemo(() => [paymentClaim.commitment.type, ...subCommitmentTypes].reduce<IPaymentClaimItemsTableFormData>(
        (agg, type) => {
            if (commitmentItems == null || itemsByType[type] == null) return agg

            const totalValue = itemsByType[type].reduce((agg, i) => agg + i.value, 0)
            const totalDetermined = itemsByType[type].reduce((agg, i) => agg + i.determinedValue, 0)
            const totalPreviouslyCertified = itemsByType[type].reduce((agg, i) => agg + i.previouslyCertified, 0)
            const totalCertified = itemsByType[type].reduce((agg, i) => agg + i.certified, 0)
            const totalClaimed = itemsByType[type].reduce((agg, i) => agg + i.claimed, 0)

            const totalGst = new BigNumber(sumBy(itemsByType[type], i => (i.determinedValue - i.previouslyCertified) * (i.gst || 0))).decimalPlaces(2).toNumber()
            return {
                items: {
                    ...agg.items,
                    [getFormTypeId(type)]: itemsByType[type].reduce<IPaymentClaimItemsTableFormData['items']['']>((items, i) => ({
                        ...items,
                        [getFormItemId(i.commitmentItem.id)]: {
                            commitmentItemId: i.commitmentItem.id,
                            index: paymentClaim.items.indexOf(i),
                            certified: i.certified,
                            claimed: i.claimed,
                            determined: i.determinedValue != null ? i.determinedValue : i.previouslyCertified,
                            determinedPct: (i.determinedValue == null || isNaN(i.determinedValue)) ? normalizePercent(new BigNumber(i.previouslyCertified).div(i.value).times(100).toNumber() || 0) : normalizePercent(new BigNumber(i.determinedValue).div(i.value).times(100).toNumber() || 0),
                            gst: i.gst ? new BigNumber(i.determinedValue).minus(i.previouslyCertified).times(i.gst).toNumber() : null,
                            paid: i.paid,
                            reason: i.reason ?? undefined,
                            variance: new BigNumber(i.certified).minus(i.claimed).decimalPlaces(2).toNumber(),
                            previouslyCertified: i.previouslyCertified,
                            value: i.commitmentItem.value
                        }
                    }),
                        {})
                },
                totals: {
                    ...agg.totals,
                    [getFormTypeId(type)]: {
                        determined: totalDetermined,
                        determinedPct: new BigNumber(totalDetermined).div(totalValue).times(100).decimalPlaces(2).toNumber() || 0,
                        previouslyCertified: new BigNumber(totalPreviouslyCertified).decimalPlaces(2).toNumber(),
                        certified: new BigNumber(totalCertified).decimalPlaces(2).toNumber(),
                        claimed: new BigNumber(totalClaimed).decimalPlaces(2).toNumber(),
                        variance: new BigNumber(totalCertified).minus(totalClaimed).decimalPlaces(2).toNumber(),
                        value: new BigNumber(totalValue).decimalPlaces(2).toNumber(),
                        paid: itemsByType[type].reduce((agg, i) => agg + i.paid, 0),
                        gst: totalGst
                    }
                },
                grandTotal: {
                    determined: new BigNumber(agg.grandTotal.determined).plus(totalDetermined).decimalPlaces(2).toNumber(),
                    previouslyCertified: new BigNumber(agg.grandTotal.previouslyCertified).plus(totalPreviouslyCertified).decimalPlaces(2).toNumber(),
                    certified: new BigNumber(agg.grandTotal.certified).plus(totalCertified).decimalPlaces(2).toNumber(),
                    gst: new BigNumber(agg.grandTotal.gst || 0).plus(totalGst).decimalPlaces(2).toNumber(),
                    totalIncGst: new BigNumber(agg.grandTotal.totalIncGst).plus(totalCertified).plus(totalGst).decimalPlaces(2).toNumber()
                }
            }
        },
        {
            items: {},
            totals: {},
            grandTotal: {
                determined: 0,
                previouslyCertified: 0,
                certified: 0,
                gst: 0,
                totalIncGst: 0
            }
        })
        , [itemsByType])

    function getCommitmentTypeName(commitmentType: string) {
        return costsOverview.commitmentDefinitions.find(x => x.code === commitmentType).name
    }

    async function handleSave(values: IPaymentClaimItemsTableFormData, form: FormApi<IPaymentClaimItemsTableFormData>) {
        const updatedClaimItems: Api.Request.PaymentClaimItemUpdate[] = []

        const dirtyFields = form.getState().dirtyFields
        const itemsRegex = /items\..*\..*\./

        const dirtyPaymentItems = flow(
            keys,
            filter(k => dirtyFields[k] && itemsRegex.test(k)),
            map(k => {
                const parts = k.split('.')
                return { id: parts[2].split(formItemIdPrefix)[1], type: parts[1].split(formTypeIdPrefix)[1] }
            }),
            uniqBy(x => x.id)
        )(dirtyFields)

        for (const { id, type } of dirtyPaymentItems) {
            const itemIndex = paymentClaim.items.findIndex(x => x.commitmentItem.id === id)
            const formTypeId = getFormTypeId(type)
            const formItemId = getFormItemId(id)
            const update: Api.Request.PaymentClaimItemUpdate = {
                commitmentItemId: values.items[formTypeId][formItemId].commitmentItemId,
                claimed: values.items[formTypeId][formItemId].claimed,
                determinedValue: values.items[formTypeId][formItemId].determined,
                gst: values.items[formTypeId][formItemId].gst != null ? costsOverview.settings.gstRate : null,
                paid: values.items[formTypeId][formItemId].paid,
                period: paymentClaim.items[itemIndex].period,
                reason: values.items[formTypeId][formItemId].reason ?? null
            }
            updatedClaimItems.push(update)
        }

        try {
            await PaymentClaimUpdate(projectId, paymentClaim.commitment.id, paymentClaim.id, {
                claimReference: paymentClaim.claimReference,
                claimDate: paymentClaim.claimDate,
                invoiceReference: paymentClaim.invoiceReference,
                invoiceDate: paymentClaim.invoiceDate,
                value: paymentClaim.value,
                items: updatedClaimItems,
                documentLinks: mapValues(paymentClaim.documentLinks, links => links.map(x => x.revisionId)),
                notes: paymentClaim.notes
            })
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response == null) {
                    NotificationService.error('An error occurred while saving changes.')
                    return
                }

                switch (err.response.status) {
                    case 400:
                        if (err.response.data.message != null && (err.response.data.message as string).includes('locked')) {
                            NotificationService.error('Could not save changes. Claim is locked')
                            reloadPaymentClaim()
                            return
                        }
                        NotificationService.error('An error occurred while saving changes.')
                        break
                    case 403:
                        NotificationService.error('Could not save changes. Require permission.')
                        reloadPaymentClaim()
                        break
                    default:
                        NotificationService.error('An error occurred while saving changes.')
                }
            }
        }

        reloadPaymentClaim()
    }

    const isLocked = isLockUnlockDisabled(paymentClaim, ...(commitmentAsync.result?.paymentClaims ?? []))

    return commitmentItemsAsync.result != null
        ? (
            <Form<IPaymentClaimItemsTableFormData, IPaymentClaimItemsTableFormData>
                initialValues={initialValues}
                onSubmit={handleSave}
                subscription={{ dirty: true, submitting: true }}
                keepDirtyOnReinitialize
                decorators={paymentItemsFormDecorators}
            >
                {({ dirty, submitting, handleSubmit }) =>
                    paymentClaim &&
                    <>
                        <ActionBar className="d-block">
                            <Row>
                                <Col xs={6} md="auto">
                                    <h5>Completed</h5>
                                    <Field<number> name="grandTotal.determined" subscription={{ value: true }}>
                                        {props => <strong>{formatCurrency(props.input.value)}</strong>}
                                    </Field>
                                </Col>
                                <Col xs={6} md="auto">
                                    <h5>Previously Certified</h5>
                                    <Field<number> name="grandTotal.previouslyCertified" subscription={{ value: true }}>
                                        {props => <strong>{formatCurrency(props.input.value)}</strong>}
                                    </Field>
                                </Col>
                                <Col xs={6} md="auto">
                                    <h5>Certified Now</h5>
                                    <Field<number> name="grandTotal.certified" subscription={{ value: true }}>
                                        {props => <strong>{formatCurrency(props.input.value)}</strong>}
                                    </Field>
                                </Col>
                                <Col xs={6} md="auto">
                                    <h5>GST</h5>
                                    <Field<number> name="grandTotal.gst" subscription={{ value: true }}>
                                        {props => <strong>{formatCurrency(props.input.value)}</strong>}
                                    </Field>
                                </Col>
                                <Col xs={6} md="auto">
                                    <h5>Total (Inc. GST)</h5>
                                    <Field<number> name="grandTotal.totalIncGst" subscription={{ value: true }}>
                                        {props => <strong>{formatCurrency(props.input.value)}</strong>}
                                    </Field>
                                </Col>
                                {paymentClaim && <Col className="flex-shrink-1 flex-grow-0 ml-auto align-self-center ml-auto d-flex">
                                    <Button className="mr-2" color="primary" onClick={handleSubmit} disabled={submitting}>
                                        <FA icon={submitting ? 'spinner-third' : dirty ? 'save' : 'check-circle'} spin={submitting} />
                                        <span className="d-none d-md-inline-block ml-2">{submitting ? 'Saving...' : dirty ? 'Save' : 'Saved'}</span>
                                    </Button>
                                    <Button disabled={isLocked || submitting} onClick={toggleLockClaim}>
                                        <FA icon={paymentClaim.status === PaymentClaimStatus.Entered ? 'lock' : 'lock-open'} />
                                        <span className="ml-2">{paymentClaim.status === PaymentClaimStatus.Entered ? 'Lock Claim' : 'Unlock Claim'}</span>
                                    </Button>
                                </Col>}
                            </Row>
                        </ActionBar>
                        <Container fluid className="mt-3 mb-5">
                            <Row>
                                <Col lg={3} md={12} className="mb-3 order-lg-12">
                                    <PaymentInfoCard
                                        costsOverview={costsOverview}
                                        payment={paymentClaim}
                                        projectId={projectId}
                                        reloadPayment={reloadPaymentClaim}
                                    />
                                </Col>
                                <Col md={12} lg={9}>
                                    <BootstrapForm>
                                        <FieldPrefix prefix={`items.${getFormTypeId(paymentClaim.commitment.type)}`}>
                                            <PaymentItemsTable
                                                commitmentType={paymentClaim.commitment.type}
                                                costsOverview={costsOverview}
                                                items={itemsByType[paymentClaim.commitment.type] ?? []}
                                                paymentClaimStatus={paymentClaim.status}
                                                projectId={projectId}
                                                tableName={`${getCommitmentTypeName(paymentClaim.commitment.type)} Items`}
                                            />
                                        </FieldPrefix>
                                        {entries(itemsByType).filter(([type]) => subCommitmentTypes.includes(type)).map(([type, items]) =>
                                            <FieldPrefix key={type} prefix={`items.${getFormTypeId(type)}`}>
                                                <PaymentItemsTable
                                                    key={type}
                                                    commitmentType={type}
                                                    costsOverview={costsOverview}
                                                    items={items}
                                                    paymentClaimStatus={paymentClaim.status}
                                                    projectId={projectId}
                                                    tableName={`${getCommitmentTypeName(type)} Items`}
                                                />
                                            </FieldPrefix>
                                        )}
                                    </BootstrapForm>
                                </Col>
                            </Row>
                        </Container>
                        <NavigationPrompt when={dirty}>
                            {({ onConfirm, onCancel }) => (
                                <Modal isOpen={true} toggle={onCancel}>
                                    <ModalHeader toggle={onCancel}>Unsaved Changes</ModalHeader>
                                    <ModalBody>You have unsaved changes on this payment. Are you sure you want to leave?</ModalBody>
                                    <ModalFooter>
                                        <Button color="link" onClick={onCancel}>Cancel</Button>
                                        <Button color="danger" onClick={onConfirm}>Discard</Button>
                                    </ModalFooter>
                                </Modal>
                            )}
                        </NavigationPrompt>
                    </>
                }
            </Form>
        )
        : null
}
