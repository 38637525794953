import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as WidgetActions from '@src/actions/widget'
import ClampLines from '@src/components/common/ClampLines'
import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import DocumentStatusIndicator from '@src/components/document/DocumentStatusIndicator'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import GenericContentTable, { ITableHeader } from '@src/components/table/GenericContentTable'
import { auth } from '@src/logic/auth/AuthService'
import { RevisionsDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import * as Routes from '@src/logic/routing/routes'
import { localDateTime, localShortDate } from '@src/logic/utils/Date'
import { fileTypeIcon } from '@src/logic/utils/FileFormats'
import { mutedNotSet } from '@src/logic/utils/ValueHelper'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IProps {
    revisions: Revision[]
    metadataDefinitions: IMetadataDefinition[]
    viewAction?: {
        visible: boolean
        tooltip: string
        disabled?: boolean
        overrideAction: (revision: Revision) => void
    }
    downloadAction?: {
        visible: boolean
        tooltip: string
        disabled?: boolean
        overrideAction: (revision: Revision) => void
    }
    deleteAction?: {
        visible: boolean
        tooltip: string
        icon: Extends<IPFontawesomeIcon, 'times' | 'trash'>
        disabled?: boolean
        overrideAction?: (revision: Revision) => void
    }
}

const defaultProps: Partial<IProps> = {
    viewAction: {
        visible: true,
        tooltip: 'View',
        disabled: null,
        overrideAction: null
    },
    downloadAction: {
        visible: true,
        tooltip: 'Download',
        disabled: null,
        overrideAction: null
    },
    deleteAction: {
        visible: false,
        tooltip: 'Delete',
        icon: 'trash',
        disabled: null,
        overrideAction: null
    }
}

function downloadRevision(revision: Revision) {
    downloadURL(RevisionsDownloadLink(auth.getSessionToken(), revision.projectId, revision.id))
}

const RevisionTable: React.FC<IProps> = ({
    revisions,
    metadataDefinitions,
    viewAction = defaultProps.viewAction,
    downloadAction = defaultProps.downloadAction,
    deleteAction = defaultProps.deleteAction
}) => {
    const dispatch = useDispatch()
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const selectedRevisions = useSelector<RootState, string[]>(s => getActiveProjectWidgetState(s).revisions.map(x => x.id))

    const widgetActions = bindActionCreators(WidgetActions, dispatch)

    function areRevisionsSelected(revisions: Revision[]) {
        return revisions.every(r => selectedRevisions.includes(r.id))
    }

    function handleSelect(...rows: Revision[]) {
        if (areRevisionsSelected(rows)) {
            widgetActions.removeRevisions({ projectId: project.id, entityIds: rows.map(r => r.id) })
        } else {
            widgetActions.addRevisions({ projectId: project.id, entities: rows })
        }
    }

    return (
        <GenericContentTable
            data={revisions}
            onSelect={handleSelect}
            selectBy={{
                key: 'id',
                selected: selectedRevisions
            }}
            headers={[
                {
                    name: 'Type',
                    overrideRenderer: r => <FA icon={fileTypeIcon(r.fileName || '')} />
                },
                {
                    name: 'Name',
                    overrideRenderer: r => (
                        <>
                            <DocumentStatusIndicator revision={r} />
                            <div className="selectable-content__title">
                                <Link to={Routes.projectDocument(r.projectId, r.documentId, r.id)}>{r.name}</Link>
                            </div>
                        </>
                    )
                },
                {
                    name: 'Rev #',
                    accessor: 'revNumber'
                },
                {
                    name: 'Tags',
                    accessor: 'tags'
                },
                {
                    name: 'Author',
                    accessor: 'author'
                },
                {
                    name: 'Revision Date',
                    accessor: 'revDate',
                    overrideRenderer: r => r.revDate ? localDateTime(r.revDate) : mutedNotSet
                },
                {
                    name: 'Uploaded',
                    overrideRenderer: r => r.revDate ? localShortDate(r.revDate) : mutedNotSet
                },
                ...(metadataDefinitions.map<ITableHeader<Revision>>(md => ({
                    name: md.name,
                    overrideRenderer: (r) => {
                        const value = renderMetadata(r.metadata[md.key], md, r.projectId)
                        return md.type === 'text' ? <ClampLines lines={2} text={typeof value === 'string' ? value : ''} /> : value
                    }
                }))),
                {
                    name: 'Actions',
                    headerWrapperClass: 'text-right',
                    noSmallHeader: true,
                    overrideRenderer: r => (
                        <div className="text-right">
                            {viewAction.visible && <TooltipLink id={`view-${r.id}`} disabled={viewAction.disabled != null ? viewAction.disabled : false} tooltip={viewAction.tooltip || RevisionTable.defaultProps.viewAction.tooltip} to={Routes.projectDocument(r.projectId, r.documentId, r.id)} className="selectable-content__icon order-lg-1"><FA icon="eye" /></TooltipLink>}
                            {downloadAction.visible && <TooltipLinkAction id={`download-${r.id}`} disabled={downloadAction.disabled != null ? downloadAction.disabled : false} tooltip={downloadAction.tooltip || RevisionTable.defaultProps.downloadAction.tooltip} data={r} onClick={downloadRevision}><FA icon="download" /></TooltipLinkAction>}
                            {deleteAction.visible && <TooltipLinkAction id={`delete-${r.id}`} disabled={deleteAction.disabled != null ? deleteAction.disabled : false} tooltip={deleteAction.tooltip || RevisionTable.defaultProps.deleteAction.tooltip} data={r} onClick={deleteAction.overrideAction}><FA icon={deleteAction.icon} /></TooltipLinkAction>}
                        </div>
                    )
                }
            ]}
        />
    )
}

export default RevisionTable
