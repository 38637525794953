import React from 'react'
import { connect } from 'react-redux'

import Banner from '@src/components/banner/Banner'
import BannerPanel from '@src/components/banner/BannerPanel'
import { UserAvatar } from '@src/components/common/Avatar'
import ButtonLink from '@src/components/common/ButtonLink'
import ClampLines from '@src/components/common/ClampLines'
import DetailList from '@src/components/common/DetailList'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { VCard } from '@src/logic/email/vCard'
import { downloadString } from '@src/logic/http/Download'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { mutedNotSet, valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { RootState } from '@src/types/models'
import { UserInternal } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IConnectedState {
    primaryContact?: UserInternal
}

interface IProps {
    project?: Project
    overrideTitle?: string | JSX.Element
}

class ProjectBanner extends React.Component<IProps & IConnectedState> {
    public downloadProjectVcf = () => {
        const { project } = this.props
        const vcard = new VCard()
        vcard.formattedName = project.name
        vcard.workEmail = project.email

        downloadString(vcard.getFormattedString(), 'text/vcard;charset=utf-8', `project ${project.code}.vcf`)
    }

    public render() {
        const { project, primaryContact, overrideTitle } = this.props

        const projectTitle = overrideTitle !== undefined ? overrideTitle : (project ? <ClampLines lines={2} text={project.name} /> : <>Loading project <FA icon="spinner-third" spin /></>)

        const primaryConactPanelBody = primaryContact
        ? (
            <>
                <div className="mr-2 text-right">
                    <UserAvatar size="sm" imageUrl={primaryContact.profilePictureLink} />
                </div>
                <div>
                    <strong className="text-uppercase">Primary Contact</strong>
                    <p>{`${primaryContact.firstName} ${primaryContact.lastName}`}</p>
                    <p><FA icon="envelope" /> {primaryContact.email}</p>
                    <p className="w-100"><span className="pr-2"><FA icon="phone" /> {valueOrMutedNotSet(primaryContact.phone)} </span><span><FA icon="mobile" /> {valueOrMutedNotSet(primaryContact.mobile)}</span></p>
                </div>
            </>
        )
        : (isAuthorised(project.myAccess, ProjectOperations.Update)
            ? <ButtonLink color="primary" size="sm" to={Routes.projectSettingsGeneral(project.id)}>Add Primary Contact</ButtonLink>
            : null)

        return (
            <Banner title={projectTitle}>
                {project?.settings &&
                    <>
                        {primaryConactPanelBody && <BannerPanel>
                            {primaryConactPanelBody}
                        </BannerPanel>}
                        <BannerPanel visibleSize="md">
                            <DetailList
                                titleClassName="text-right"
                                entries={[
                                    { title: 'Project Code', data: valueOrMutedNotSet(project.settings.code) },
                                    { title: 'Client', data: project.client ? valueOrMutedNotSet(project.client.name) : mutedNotSet },
                                    { title: 'Category', data: valueOrMutedNotSet(project.settings.category) },
                                    {
                                        title: 'Email',
                                        data: (
                                            <span>
                                                <TooltipLinkAction id="download-project-vcard" tooltip="Download vCard" data={null} onClick={this.downloadProjectVcf}><FA icon="id-card" /></TooltipLinkAction>
                                                <a href={`mailto:${project.email}`} className="ml-1">{project.email}</a>
                                            </span>
                                        )
                                    }
                                ]}
                            />
                        </BannerPanel>
                        <BannerPanel visibleSize="md">
                            <DetailList
                                titleClassName="text-right"
                                entries={[
                                    { title: 'Status', data: valueOrMutedNotSet(project.settings.status) },
                                    { title: 'Start', data: valueOrMutedNotSet(project.settings.startDate ? localShortDate(project.settings.startDate) : null) },
                                    { title: 'End', data: valueOrMutedNotSet(project.settings.finishDate ? localShortDate(project.settings.finishDate) : null) }
                                ]}
                            />
                        </BannerPanel>
                    </>}
            </Banner>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps): IProps & IConnectedState {
    return {
        ...ownProps,
        primaryContact: state.projects.activeContact
    }
}

export default connect(mapStateToProps)(ProjectBanner)
