import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { Button, Card, CardHeader } from 'reactstrap'

import { Operation as PatchOperation } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import BudgetStatusModal from '@src/components/costs/settings/BudgetStatusModal'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import GenericTable from '@src/components/table/GenericTable'
import useBoolean from '@src/hooks/useBoolean'
import { BudgetPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { reorder } from '@src/logic/utils/Collection'
import { safeHtmlId } from '@src/logic/utils/Strings'
import { BudgetOverview, BudgetReportColumnName, BudgetStatusDefinition } from '@src/types/costs'

interface IProps {
    type: 'item' | 'adjustment'
    budget: BudgetOverview
    projectId: string
}

function getStatusDefinitionId(status: BudgetStatusDefinition) {
    return status.code
}

export default function BudgetStatusForm({ budget, type, projectId }: IProps) {
    const dispatch = useDispatch()
    const isCreating = useBoolean()
    const [updatedStatuses, setUpdatedStatuses] = React.useState<BudgetStatusDefinition[] | null>(null)
    const [statusToDelete, setStatusToDelete] = React.useState<BudgetStatusDefinition | null>(null)
    const [statusToEdit, setStatusToEdit] = React.useState<BudgetStatusDefinition | null>(null)

    const statusDefinitions = type === 'item' ? budget.itemStatusDefinitions : budget.adjustmentStatusDefinitions

    function clearStatusToDelete() {
        setStatusToDelete(null)
    }

    function clearNewOrEditStatus() {
        setStatusToEdit(null)
        isCreating.setFalse()
    }

    async function handleStatusReorder(status: BudgetStatusDefinition, result: DropResult) {
        if (result.destination == null || result.source.index === result.destination.index) return

        const { itemStatusDefinitions, adjustmentStatusDefinitions } = budget
        const isItemStatus = type === 'item'
        const rootPatchPath = isItemStatus ? '/itemStatusDefinitions' : '/adjustmentStatusDefinitions'
        const patch: PatchOperation = {
            op: 'move',
            from: `${rootPatchPath}/${result.source.index}`,
            path: `${rootPatchPath}/${result.destination.index}`
        }

        setUpdatedStatuses(reorder(isItemStatus ? itemStatusDefinitions : adjustmentStatusDefinitions, result.source.index, result.destination.index))

        try {
            await BudgetPatch(projectId, [patch])
            dispatch(loadProjectCostsOverview())
        } catch (e) {
            NotificationService.error('An error occurred when attempting to reorder the status')
        } finally {
            setUpdatedStatuses(null)
        }
    }

    async function deleteStatus() {
        const { itemStatusDefinitions, adjustmentStatusDefinitions } = budget

        const isItemStatus = type === 'item'
        const index = isItemStatus ? itemStatusDefinitions.findIndex(s => s.code === statusToDelete.code) : adjustmentStatusDefinitions.findIndex(s => s.code === statusToDelete.code)
        const rootPatchPath = isItemStatus ? '/itemStatusDefinitions' : '/adjustmentStatusDefinitions'
        const testOperation: PatchOperation = {
            op: 'test', path: `${rootPatchPath}/${index}/code`, value: statusToDelete.code
        }
        const deleteOperation: PatchOperation = {
            op: 'remove',
            path: `${rootPatchPath}/${index}`
        }

        try {
            await BudgetPatch(projectId, [testOperation, deleteOperation])
            NotificationService.info(`Removed status '${statusToDelete.code}'`)
        } catch {
            NotificationService.error(`There was an error while trying to delete status '${statusToDelete.code}'`)
        } finally {
            dispatch(loadProjectCostsOverview())
            clearStatusToDelete()
        }
    }

    return (
        <Card>
            <CardHeader className="d-flex">
                <div className="flex-grow-1">Budget Adjustment Statuses</div>
                <Button disabled={budget.locked} color="primary" onClick={isCreating.setTrue}><FA icon="plus" /> Add Status</Button>
            </CardHeader>
            <GenericTable<BudgetStatusDefinition, never>
                className="mb-0"
                data={updatedStatuses ?? statusDefinitions}
                getRowId={getStatusDefinitionId}
                onReorder={budget.locked ? null : handleStatusReorder}
                headers={[
                    {
                        accessor: 'code',
                        name: 'Status'
                    },
                    {
                        name: 'Columns',
                        overrideRenderer: defintion => defintion.columns.map(c => BudgetReportColumnName[c]).join(', ')
                    },
                    {
                        name: 'Actions',
                        headerWrapperClass: 'text-right',
                        overrideRenderer: (definition, rowIdx) => (
                            <div className="justify-content-end text-right selectable-content__actions">
                                <TooltipLinkAction id={`edit-${type}-${safeHtmlId(definition.code)}-${rowIdx}`} tooltip="Edit" data={definition} onClick={setStatusToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                <TooltipLinkAction id={`remove-${type}-${safeHtmlId(definition.code)}-${rowIdx}`} tooltip="Remove" data={definition} onClick={setStatusToDelete}><FA icon="trash" /></TooltipLinkAction>
                            </div>
                        )
                    }
                ]}
            />
            <BudgetStatusModal
                isOpen={statusToEdit != null || isCreating.value}
                statusToEdit={statusToEdit}
                statusType={type}
                toggle={clearNewOrEditStatus}
                projectId={projectId}
                budget={budget}
            />
            <ConfirmationModal
                isOpen={statusToDelete != null}
                toggle={clearStatusToDelete}
                message={<span>Are you sure you want to remove the status <strong>{statusToDelete ? statusToDelete.code : ''}</strong>? This action <strong>cannot</strong> be undone.</span>}
                header="Delete Status"
                confirmAction="Yes, Delete Status"
                onConfirm={deleteStatus}
                danger
            />
        </Card>
    )
}
