import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'


import BudgetSettingsForm from '@src/components/costs/settings/BudgetSettingsForm'
import BudgetStatusForm from '@src/components/costs/settings/BudgetStatusForm'
import { BudgetOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'

export default function BudgetSettingsSection() {
    const projectId = useSelector<RootState, string>(x => x.projects.active.id)
    const budget = useSelector<RootState, BudgetOverview>(x => x.projects.activeCostsOverview.budget)

    return (
        <Container fluid>
            <Row className="mt-3">
                <Col>
                    <BudgetSettingsForm budget={budget} projectId={projectId} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="mb-3">
                    <BudgetStatusForm budget={budget} projectId={projectId} type="item" />
                </Col>
                <Col>
                    <BudgetStatusForm budget={budget} projectId={projectId} type="adjustment" />
                </Col>
            </Row>
        </Container>
    )
}
