import React from 'react'
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import Cropper from '@src/components/image/Cropper'
import { dataURItoBlob } from '@src/logic/utils/File'

interface IProps {
    'aria-label'?: string
    onUploadPicture: (picture: Blob) => void
    onError?: (error: string) => void
    aspectRatio?: number
}

interface IState {
    newPictureUrl?: string
    croppedPictureUrl?: string
}

export default class PictureUploadManager extends React.PureComponent<IProps, IState> {
    private static readonly maxImageSize = 1000000
    private static readonly acceptedImageExtensions = ['image/jpeg', 'image/png']
    private readonly dropzoneRef: React.RefObject<DropzoneRef>

    constructor(props) {
        super(props)

        this.dropzoneRef = React.createRef()

        this.state = {
            newPictureUrl: null,
            croppedPictureUrl: null
        }
    }

    private readonly onDrop = (accepted: File[], rejected: FileRejection[]) => {
        if (rejected && rejected.length > 0) {
            let error = 'Cannot accept the selected file'

            if (rejected.filter(r => !PictureUploadManager.acceptedImageExtensions.includes(r.file.type)).length > 0) {
                error = 'File is not a picture. Accepted formats are .jpg and .png.'
            } else if (rejected.filter(r => r.file.size > PictureUploadManager.maxImageSize).length > 0) {
                error = 'Picture is too large. Please upload picture less than 1MB.'
            }

            if (this.props.onError) {
                this.props.onError(error)
            }
        } else {
            this.setState({ newPictureUrl: URL.createObjectURL(accepted[0]) })
        }
    }

    public open = () => {
        this.dropzoneRef.current.open()
    }

    private readonly clearNewProfilePicture = () => {
        this.setState({ newPictureUrl: null })
    }

    private readonly setCroppedImage = (url: string) => {
        this.setState({ croppedPictureUrl: url })
    }

    public uploadProfilePicture = async () => {
        this.props.onUploadPicture(dataURItoBlob(this.state.croppedPictureUrl))
        this.setState({ croppedPictureUrl: null, newPictureUrl: null })
    }

    public render() {
        return (
            <div>
                <Dropzone accept={PictureUploadManager.acceptedImageExtensions.join(', ')} ref={this.dropzoneRef} onDrop={this.onDrop} maxSize={PictureUploadManager.maxImageSize} multiple={false}>
                    {({ getInputProps, getRootProps }) => <>{this.props.children} <div {...getRootProps({ className: 'd-none' })}>
                    <input {...getInputProps({ 'aria-label': this.props['aria-label'] })} data-cy="picture-upload" />
                    </div></>}
                </Dropzone>
                <Modal isOpen={this.state.newPictureUrl != null} toggle={this.clearNewProfilePicture}>
                    <ModalHeader toggle={this.clearNewProfilePicture}>Upload image</ModalHeader>
                    <ModalBody>
                        {this.state.newPictureUrl && (
                            <div className="w-100 d-flex justify-content-center">
                                <Cropper src={this.state.newPictureUrl} aspectRatio={this.props.aspectRatio} onCrop={this.setCroppedImage} />
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.uploadProfilePicture}>Upload</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
