import { Config, createForm } from 'final-form'

import useConstant from '@src/hooks/useConstant'
import useUpdateEffect from '@src/hooks/useUpdateEffect'

export default function useFormApi<FormValues, InitialFormValues>(config: Config<FormValues, InitialFormValues>) {
    const form = useConstant(() => createForm(config))
    useUpdateEffect(() => {
      form.setConfig('debug', config.debug)
    }, [config.debug])

    useUpdateEffect(() => {
      form.destroyOnUnregister = config.destroyOnUnregister
    }, [config.destroyOnUnregister])

    useUpdateEffect(() => {
      form.setConfig('keepDirtyOnReinitialize', config.keepDirtyOnReinitialize)
    }, [config.keepDirtyOnReinitialize])

    // useWhenValueChanges(
    //   initialValues,
    //   () => {
    //     form.setConfig("initialValues", initialValues);
    //   },
    //   initialValuesEqual || shallowEqual,
    // )

    useUpdateEffect(() => {
      form.setConfig('mutators', config.mutators)
    }, [config.mutators])

    useUpdateEffect(() => {
      form.setConfig('onSubmit', config.onSubmit)
    }, [config.onSubmit])

    useUpdateEffect(() => {
      form.setConfig('validate', config.validate)
    }, [config.validate])

    useUpdateEffect(() => {
      form.setConfig('validateOnBlur', config.validateOnBlur)
    }, [config.validateOnBlur])

    return form
}
