import React from 'react'
import { Input } from 'reactstrap'

import { DocumentSearchMode } from '@src/types/document'

export type SearchMode = DocumentSearchMode | 'allRevisions'

interface IProps {
    value?: SearchMode
    onChange: (value: SearchMode) => void
}

export default function DocumentSearchModeComboBox({ value, onChange }: IProps) {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        onChange(e.currentTarget.value as SearchMode)
    }

    return (
        <Input type="select" value={value} onChange={handleChange}>
            <option value={DocumentSearchMode.Latest}>Latest</option>
            <option value={DocumentSearchMode.LatestNonArchived}>Latest (Non Archived)</option>
            <option value={DocumentSearchMode.LatestPublished}>Latest Published</option>
            <option value={DocumentSearchMode.Published}>Published</option>
            <option value={DocumentSearchMode.Archived}>Archived</option>
            <option value={'allRevisions'}>All Revisions</option>
        </Input>
    )
}
