import React from 'react'
import { Slide, ToastContainerProps, ToastProps, useToast, useToastContainer } from 'react-toastify'
import { Toast } from 'reactstrap'

import cx from 'classnames'
import styled, { keyframes } from 'styled-components'

export const NotificationContainer: React.FC<ToastContainerProps> = props => {
    const { getToastToRender, containerRef, isToastActive } = useToastContainer(props)
    const { style, rtl, containerId } = props

    return (
        <div ref={containerRef} id={containerId as string} className="Toastify">
            {getToastToRender((position, toastList) => {
                const swag = {
                    className: cx(
                        'Toastify__toast-container',
                        `Toastify__toast-container--${position}`,
                        { 'Toastify__toast-container--rtl': rtl }
                    ),
                    style:
                        toastList.length === 0
                            ? { color: 'unset', ...style, pointerEvents: 'none' as React.CSSProperties['pointerEvents'] }
                            : { color: 'unset', ...style }
                }

                return (
                    <div {...swag} key={`container-${position}`}>
                        {toastList.map(({ content, props: toastProps }) =>
                            <NotificationToastContainer
                                {...toastProps}
                                isIn={isToastActive(toastProps.toastId)}
                                key={`toast-${toastProps.key}`}
                            >
                                {content}
                            </NotificationToastContainer>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

NotificationContainer.defaultProps = {
    position: 'bottom-right',
    transition: Slide,
    rtl: false,
    autoClose: 5000,
    hideProgressBar: false,
    closeButton: false,
    pauseOnHover: true,
    pauseOnFocusLoss: true,
    closeOnClick: true,
    newestOnTop: false,
    draggable: true,
    draggablePercent: 80,
    role: 'alert'
}

const NotificationToastContainer: React.FC<ToastProps> = props => {
    const { toastId, onClick, style, deleteToast, autoClose, closeToast } = props
    const { eventHandlers, toastRef, isRunning } = useToast(props)

    function handleAutoClose() {
        if (props.isIn) props.closeToast()
    }

    return (
        <Toast
            id={toastId as string}
            fade
            isOpen={props.isIn}
            onClick={onClick}
            {...eventHandlers}
            style={{
                cursor: 'pointer',
                ...style
            }}
            innerRef={toastRef}
            transition={{
                timeout: 150,
                unmountOnExit: true,
                onExit: deleteToast
            }}
        >
            {autoClose && <Timeout
                delay={autoClose}
                isRunning={isRunning}
                closeToast={closeToast}
                onAnimationEnd={handleAutoClose}
            />}
            {props.children}
        </Toast>
    )
}

const progress = keyframes`
    0%{
        transform: scaleX(1);
    }
    100%{
        transform: scaleX(0);
    }
`

interface TimeoutProps {
    delay: number
    isRunning: boolean
    closeToast: () => void
}

const Timeout = styled.div<TimeoutProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0;
    background-color: rgba(255,255,255,.7);
    transform-origin: left;
    animation: ${progress} linear 1 forwards;
    animation-duration: ${props => `${props.delay}ms`};
    animation-play-state: ${props => props.isRunning ? 'running' : 'paused'};
`
