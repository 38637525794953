import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'

import { ICommonFieldText, MetadataDefinition } from '@src/components/metadata/definitions/MetadataDefinitions'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { IMetadataDefinition, IUpdateMetadataDefinition } from '@src/types/metadata'

interface IProps {
    definitions: IMetadataDefinition[]
    onDeleteDefinition: (key: string) => void
    onUpdateDefinition: (key: string, updatedDefinition: IUpdateMetadataDefinition) => void
    fieldLabels?: ICommonFieldText
    fieldDescriptions?: ICommonFieldText
    disabled?: boolean
}

interface IState {
    metadataToDelete: string
}

export default class MetadataDefinitionList extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            metadataToDelete: null
        }
    }

    private readonly deleteMetadataDefinition = () => {
        const toDelete = this.state.metadataToDelete
        this.clearMetadataToDelete()

        this.props.onDeleteDefinition(toDelete)
    }

    private readonly setMetadataToDelete = (metadataKey: string) => {
        this.setState({ metadataToDelete: metadataKey })
    }

    private readonly clearMetadataToDelete = () => {
        this.setState({ metadataToDelete: null })
    }

    private readonly handleUpdateMetadataDefinition = async (key: string, update: IUpdateMetadataDefinition<any>) => {
        this.props.onUpdateDefinition(key, update)
    }

    public render() {
        const { metadataToDelete } = this.state
        const metadataToDeleteName = metadataToDelete && this.props.definitions.find(x => x.key === metadataToDelete).name

        return (
            <ListGroup flush>
                {this.props.definitions?.map(def =>
                    <ListGroupItem key={def.key}>
                        <MetadataDefinition
                            definition={def}
                            onRetire={this.setMetadataToDelete}
                            onUpdate={this.handleUpdateMetadataDefinition}
                            fieldLabels={this.props.fieldLabels}
                            fieldDescriptions={this.props.fieldDescriptions}
                            disabled={this.props.disabled}
                        />
                    </ListGroupItem>
                )}
                <ConfirmationModal
                    isOpen={metadataToDelete != null}
                    toggle={this.clearMetadataToDelete}
                    message={<span>Are you sure you want to delete register column <strong>{metadataToDeleteName}</strong>? This action <strong>cannot</strong> be undone.</span>}
                    header="Delete Column"
                    confirmAction="Yes, Delete Column"
                    onConfirm={this.deleteMetadataDefinition}
                    danger
                />
            </ListGroup>
        )
    }
}
