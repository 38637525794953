import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, ButtonGroup, Col, Row, UncontrolledTooltip } from 'reactstrap'
import { Dispatch, bindActionCreators } from 'redux'

import { loadProjectCostsOverview } from '@src/actions/project'
import * as WidgetActions from '@src/actions/widget'
import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import { EditCommitmentModal, NewCommitmentModal } from '@src/components/costs/commitments/CommitmentFormModal'
import CostValue from '@src/components/costs/common/CostValue'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { ISearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { ISearchResult, SearchSectionType } from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import { CommitmentDelete, CommitmentsListByType } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { localLongDate } from '@src/logic/utils/Date'
import { mutedNotSet, mutedValue } from '@src/logic/utils/ValueHelper'
import { getProjectState } from '@src/reducers/widget'
import { AggregateCommitmentData, Commitment, CommitmentDefinition, CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import LinkAction from '@src/components/common/LinkAction'
import DocumentLinksModal from './DocumentLinksModal'
import DropdownCheckOptions, { IDropdownCheckOption } from '@src/components/common/DropdownCheckOptions'

interface IState {
    isCreating: boolean
    isEditing: boolean
    aggregateCommitmentData: AggregateCommitmentData
    commitmentDefinition: CommitmentDefinition
    commitmentToDelete?: Commitment
    commitmentToEdit?: Commitment
    linksCategory?: string
    filterLinks?: boolean
    selectedCommitment?: Commitment
}

interface IConnectedState {
    project: Project
    costsOverview: CostsOverview
    widgetCommitmentsIds: string[]
}

interface IConnectedDispatch {
    reloadCostsOverview: () => void
    widgetActions: typeof WidgetActions
}

const commitmentSearchProperties: ISearchProperty[] = [
    {
        name: 'Name',
        searchKey: 'name',
        type: PropertyType.Text
    },
    {
        name: 'Commitment Number',
        searchKey: 'commitment_number',
        type: PropertyType.Text
    },
    {
        name: 'Status',
        searchKey: 'status',
        type: PropertyType.Text
    },
    {
        name: 'Other Party',
        searchKey: 'other_party',
        type: PropertyType.Text
    },
    {
        name: 'Description',
        searchKey: 'description',
        type: PropertyType.Text
    }
]

class CommitmentSection extends React.PureComponent<RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState & IConnectedDispatch, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<Commitment, 'id'>>

    constructor(props: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState & IConnectedDispatch) {
        super(props)

        const commitmentDefinition = CommitmentSection.getCommitmentDefinitionOrRedirect(props)

        this.searchSectionRef = React.createRef<SearchSectionType<Commitment, 'id'>>()

        this.state = {
            commitmentDefinition,
            isCreating: false,
            isEditing: false,
            aggregateCommitmentData: null,
            commitmentToDelete: null,
            commitmentToEdit: null,
            filterLinks: true,
            linksCategory: null,
            selectedCommitment: null
        }
    }

    public static getDerivedStateFromProps(props, state) {
        const commitmentDefinition = CommitmentSection.getCommitmentDefinitionOrRedirect(props)

        return {
            ...state,
            commitmentDefinition
        }
    }

    public static getCommitmentDefinitionOrRedirect(props: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState & IConnectedDispatch) {
        const commitmentDefinition = props.costsOverview.commitmentDefinitions.find(x => x.code === props.match.params.type)

        if (commitmentDefinition == null) {
            props.history.push({
                pathname: Routes.projectCostsDashboard(props.project.id)
            })
        }

        return commitmentDefinition
    }

    public componentDidUpdate(prevProps, prevState: IState) {
        if (this.state.commitmentDefinition.code !== prevState.commitmentDefinition.code) {
            this.searchSectionRef.current.doSearch()
        }
    }

    private readonly loadCommitments = async (filter: string, sort: string, page: number, perPage: number, abortSignal: AbortSignal): Promise<ISearchResult<Commitment>> => {
        const response = await CommitmentsListByType(this.props.project.id, this.state.commitmentDefinition.code, filter, sort, page, perPage, { abortSignal })
        this.setState({ aggregateCommitmentData: response.data.aggregateCommitmentData })
        return {
            items: response.data.commitments,
            totalItems: Number(response.headers[Headers.PaginationTotalCount])
        }
    }

    private readonly setCommitmentToDelete = (item: Commitment) => {
        this.setState({ commitmentToDelete: item })
    }

    private readonly clearCommitmentToDelete = () => {
        this.setState({ commitmentToDelete: null })
    }

    private readonly deleteCommitment = async () => {
        const { commitmentToDelete } = this.state
        this.clearCommitmentToDelete()

        try {
            await CommitmentDelete(this.props.project.id, commitmentToDelete.id)
        } catch {
            NotificationService.error(<span>Failed to remove {commitmentToDelete.name}</span>)
        }

        await this.searchSectionRef.current.doSearch()
    }

    private readonly setCreating = () => {
        this.setState({ isCreating: true })
    }

    private readonly clearCreating = () => {
        this.setState({ isCreating: false })
    }

    private readonly handleCommitmentsSelected = (...commitments: Commitment[]) => {
        const selectedIds = [...this.props.widgetCommitmentsIds]
        this.props.widgetActions.addCommitments({ projectId: this.props.project.id, entities: commitments.filter(c => !selectedIds.includes(c.id)) })
        this.props.widgetActions.removeCommitments({ projectId: this.props.project.id, entityIds: commitments.filter(c => selectedIds.includes(c.id)).map(c => c.id) })
    }

    private readonly setCommitmentToEdit = (item: Commitment) => {
        this.setState({ isEditing: true, commitmentToEdit: item })
    }

    private readonly clearEditing = () => {
        this.setState({ isEditing: false })
    }

    private readonly clearCommitmentToEdit = () => {
        this.setState({ commitmentToEdit: null })
    }

    private readonly clearFlags = () => {
        this.setState({ isCreating: false, isEditing: false })
    }

    private readonly navigateToCommitment = (commitment: Commitment) => {
        this.props.history.push(Routes.projectCostsCommitmentTypeDetail(this.props.project.id, commitment.type, commitment.id))
    }

    private readonly deleteToolTipText = (commitment: Commitment) => {
        if (commitment.paymentClaims.length > 0) return 'Linked to a payment claim'
        return 'Remove'
    }

    private readonly setLinksCategory = (category: string) => {
        this.setState({ linksCategory: category })
    }

    private readonly clearLinksCategory = () => {
        this.setState({ linksCategory: null })
    }

    private readonly setFilterLinks = (filterBoolean: boolean) => {
        this.setState({ filterLinks: filterBoolean })
    }

    private readonly setSelectedCommitment = (commitment: Commitment) => {
        this.setState({ selectedCommitment: commitment })
    }

    private readonly showDocumentLinks = (type: string, commitment: Commitment) => {
        this.setLinksCategory(type)
        this.setSelectedCommitment(commitment)
    }

    private readonly handleHeaderChecked = (e, option: IDropdownCheckOption) => {
        if (option.key === 'links') this.setFilterLinks(!this.state.filterLinks ?? false)
    }
    
    public render() {
        const { project, costsOverview, widgetCommitmentsIds } = this.props
        const { commitmentDefinition, commitmentToDelete, isCreating, isEditing, commitmentToEdit, linksCategory, filterLinks } = this.state

        const headerOptions = [
            { section: 'Metadata', label: 'Links', key: 'links', checked: filterLinks }
        ]

        return (
            <>
                <ActionBar className="pb-0 pt-0 d-block">
                    <Row>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Value</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalValue} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Claimed</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalClaimed} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Certified</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalCertified} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Paid</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalPaid} />}
                        </Col>
                    </Row>
                </ActionBar>
                <SearchSection<Commitment, 'id'>
                    ref={this.searchSectionRef}
                    onItemsSelected={this.handleCommitmentsSelected}
                    selectedItems={widgetCommitmentsIds}
                    itemIdKey="id"
                    searchAssistantProperties={commitmentSearchProperties}
                    headers={[
                        {
                            name: 'Commitment',
                            accessor: 'commitmentNo',
                            sortKey: 'commitment_no',
                            sortable: true
                        },
                        {
                            name: 'Name',
                            sortKey: 'name',
                            sortable: true,
                            overrideRenderer: commitment => <Link to={Routes.projectCostsCommitmentTypeDetail(project.id, commitment.type, commitment.id)}>{commitment.name}</Link>
                        },
                        {
                            name: 'Date',
                            sortKey: 'date',
                            sortable: true,
                            overrideRenderer: commitment => commitment.date != null ? localLongDate(commitment.date) : mutedNotSet
                        },
                        {
                            name: 'Other Party',
                            sortKey: 'other_party',
                            sortable: true,
                            overrideRenderer: commitment => commitment.otherParty ? commitment.otherParty.name : mutedValue('(none)')
                        },
                        {
                            name: 'Status',
                            overrideRenderer: commitment => {
                                if (commitment.commitmentItems.length === 0) return mutedValue('N/A')

                                const firstItemStatus = commitment.commitmentItems[0].status
                                return commitment.commitmentItems.every(c => c.status === firstItemStatus)
                                    ? firstItemStatus
                                    : <em>Mixed<FA id={`mixed-status-${commitment.id}`} icon="question-circle" className="ml-2" /><UncontrolledTooltip target={`mixed-status-${commitment.id}`}>Line items have different statuses.</UncontrolledTooltip></em>
                            }
                        },
                        {
                            name: 'Value',
                            overrideRenderer: commitment => <CostValue value={commitment.currentValue} />
                        },
                        {
                            name: 'Claimed',
                            overrideRenderer: commitment => <CostValue value={commitment.currentClaimed} />
                        },
                        {
                            name: 'Certified',
                            overrideRenderer: commitment => <CostValue value={commitment.currentCertified} />
                        },
                        {
                            name: 'Paid',
                            overrideRenderer: commitment => <CostValue value={commitment.currentPaid} />
                        },
                        ...filterLinks ? commitmentDefinition.defaultDocumentLinkCategories.map(d => ({
                            name: d,
                            overrideRenderer: (commitment: Commitment) => <div>
                                {commitment.documentLinks[d]?.length > 0 &&
                                    <LinkAction data={d} onClick={() => this.showDocumentLinks(d, commitment)}>
                                        {commitment.documentLinks[d].length} {commitment.documentLinks[d].length === 1 ? 'document' : 'documents'}
                                    </LinkAction> 
                                }
                            </div>
                        }))
                        : [],
                        {
                            name: 'Actions',
                            headerWrapperClass: 'text-right',
                            overrideRenderer: item => (
                                <div className="text-right">
                                    <TooltipLinkAction id={`edit-commitment-${item.id}`} tooltip="Edit" data={item} className="order-lg-1" onClick={this.setCommitmentToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                    <TooltipLinkAction id={`delete-commitment-${item.id}`} tooltip={this.deleteToolTipText(item)} data={item} className="order-lg-1" onClick={this.setCommitmentToDelete}
                                    disabled={!isAuthorised(costsOverview.myAccess, Operations.Delete) || item.paymentClaims.length > 0 }><FA icon="trash" /></TooltipLinkAction>
                                </div>
                            )
                        }
                    ]}
                    onSearch={this.loadCommitments}
                    noItemsFoundMessage={
                        <>
                        <div className="my-3"><FA size="3x" icon="file-alt" /></div>
                        <p className="lead">No items found with the current search criteria...</p>
                        <p>{"Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters."}</p>
                    </>
                    }
                    extraSearchBarElements={[
                        {
                            position: 'before',
                            element: _ => (
                                <Button onClick={this.setCreating}><FA icon="plus" /> Add</Button>
                            )
                        },
                        {
                            element: _ => (
                                <ButtonGroup size="sm">
                                    <DropdownCheckOptions
                                        caret
                                        isSearchable
                                        title={<FA icon="filter" />}
                                        sectionHeaders={['Revision Property', 'Metadata']}
                                        options={headerOptions}
                                        toggleProps={{ color: 'default', outline: true, id: 'document-search-columns' }}
                                        onCheck={this.handleHeaderChecked}
                                    />
                                </ButtonGroup>
                            ),
                            position: 'before'
                        },
                    ]}
                >
                    <DocumentLinksModal
                        isOpen={linksCategory ? true : false}
                        toggle={this.clearLinksCategory}
                        projectId={project.id}
                        commitment={this.state.selectedCommitment}
                        category={linksCategory} 
                        reloadCommitment={() => null}
                    />
                    <NewCommitmentModal
                        isOpen={isCreating}
                        project={project}
                        costsOverview={costsOverview}
                        commitmentDefinition={commitmentDefinition}
                        toggle={this.clearFlags}
                        onClosed={this.clearCommitmentToEdit}
                        onCommitmentCreated={this.navigateToCommitment}
                    />
                    {commitmentToEdit && <EditCommitmentModal
                        isOpen={isEditing}
                        project={project}
                        costsOverview={costsOverview}
                        commitment={commitmentToEdit}
                        toggle={this.clearFlags}
                        onClosed={this.clearCommitmentToEdit}
                        onCommitmentUpdated={this.searchSectionRef.current?.doSearch}
                    />}
                    <ConfirmationModal
                        danger
                        isOpen={commitmentToDelete != null}
                        toggle={this.clearCommitmentToDelete}
                        header={`Remove ${commitmentDefinition.name}`}
                        message={
                            <span>Are you sure you want to remove <strong>{commitmentToDelete?.name}</strong>?
                                {(commitmentToDelete?.commitmentItems.length > 0 || commitmentToDelete?.subCommitments.length > 0) &&
                                <span> <br/> This {commitmentDefinition.name.toLowerCase()} has items or subcommitments attached to it, <strong>they will also be deleted.</strong> </span>}
                            </span>}
                        confirmAction="Remove"
                        rejectAction="Cancel"
                        onReject={this.clearCommitmentToDelete}
                        onConfirm={this.deleteCommitment}
                    />
                </SearchSection>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams>): RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active,
        costsOverview: state.projects.activeCostsOverview,
        widgetCommitmentsIds: getProjectState(state.widget, state.projects.active.id).commitments.map(c => c.id)
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams>): IConnectedDispatch {
    return {
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview()),
        widgetActions: bindActionCreators(WidgetActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentSection)
