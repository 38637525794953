import React from 'react'
import { Field, Form } from 'react-final-form'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import cx from 'classnames'
import produce from 'immer'
import shallow from 'zustand/shallow'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedInput from '@src/components/common/ValidatedInput'
import AutoSave from '@src/components/forms/AutoSave'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { formatOptions } from '@src/components/register/AddColumnHeader'
import EditColumnModal from '@src/components/register/EditColumnModal'
import { IColumnHeaderRenderProps } from '@src/components/spreadsheet/Spreadsheet'
import useBoolean from '@src/hooks/useBoolean'
import useModal from '@src/hooks/useModal'
import { required } from '@src/logic/forms/validation'
import { RegisterColumnDelete, RegisterColumnUpdate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import useRegisterStore from '@src/store/register'
import { Api } from '@src/types/api'
import { IEntityPropertyDefinition } from '@src/types/metadata'
import { RegisterColumn } from '@src/types/register'

export interface IMetadataDefinitionForm<T> {
    key: string
    name: string
    description: string
    isRequired: boolean
    options: T
}

interface IDropdownColumnForm {
    name: string
}

export default function EditColumnDropdown<T extends RegisterColumn, TOpts extends T['metadataDefinition']['options']>({ data: column }: IColumnHeaderRenderProps<T>) {
    const [register, update] = useRegisterStore(s => [s.register, s.update], shallow)
    const usingDisplayEditColumnMenu = useBoolean(false)
    const editColumnModal = useModal(false, { destroyOnClose: true })
    const confirmDeleteRegisterModal = useModal(false, { destroyOnClose: true })
    const registerId = register.id

    const initialValues: IMetadataDefinitionForm<unknown> = React.useMemo(() => ({
        key: column.key,
        name: column.metadataDefinition.name,
        description: column.metadataDefinition.description,
        isRequired: column.metadataDefinition.isRequired,
        options: column.metadataDefinition.options
    }), [column])

    async function updateColumnName(values) {
        await RegisterColumnUpdate(register.id, column.key, {
            metadataDefinition: {
                ...column.metadataDefinition,
                name: values.name
            },
            styles: column.styles
        })
    }

    async function handleOnSubmit(values: IMetadataDefinitionForm<T>) {
        const isModalEdit = editColumnModal.modalProps.isOpen
        editColumnModal.actions.hide()
        const columnUpdate: Api.Request.RegisterColumnUpdate = {
            metadataDefinition: {
                name: values.name,
                type: column.metadataDefinition.type,
                key: values.key,
                description: values.description,
                isRequired: values.isRequired,
                options: formatOptions(column.metadataDefinition.type, values.options),
                ...((column as unknown as IEntityPropertyDefinition<TOpts, unknown>).parentKey ? { parentKey: (column as any).parentKey } : {})
            },
            styles: column.styles
        }
        try {
            await RegisterColumnUpdate(registerId, initialValues.key, columnUpdate)

            if (isModalEdit) NotificationService.info('Updated column')

            const updatedColumns = produce(register.columns, cols => {
                const index = cols.findIndex(x => x.key === column.key)
                if (index === -1) return
                cols[index].metadataDefinition.name = values.name
                cols[index].metadataDefinition.key = values.key
                cols[index].metadataDefinition.description = values.description
                cols[index].metadataDefinition.isRequired = values.isRequired
                cols[index].metadataDefinition.options = values.options ?? {}
            })
            update({ columns: updatedColumns })
        } catch (error) {
            if (isAxiosError(error)) {
                switch (error.response?.status) {
                    case 403:
                        NotificationService.error('No permission to update columns')
                        break
                    case 404:
                        break
                    default:
                        NotificationService.error('Unable to update column')
                }
            }
        }
    }

    async function handleDeleteColumn() {
        try {
            await RegisterColumnDelete(registerId, column.key)
            const updatedColumns = produce(register.columns, draft => {
                const index = draft.findIndex(x => x.key === column.key)
                if (index !== -1) draft.splice(index, 1)
            })
            update({ columns: updatedColumns })
        } catch (error) {
            if (isAxiosError(error)) {
                switch (error.response?.status) {
                    case 403:
                        NotificationService.error('No permission to delete columns')
                        break
                    case 404:
                        break
                    default:
                        NotificationService.error('Unable to delete column')
                }
            }
        }
    }

    function shouldAutoSave() {
        return !editColumnModal.modalProps.isOpen
    }

    return (
        <div className="column-dropdown__content" style={{ paddingLeft: register.columns.length > 0 ? '1rem' : 'none' }}>
            <Form<IDropdownColumnForm> onSubmit={updateColumnName} keepDirtyOnReinitialize initialValues={initialValues}>
                {() => (
                    <>
                        <AutoSave wait={1000} shouldSave={shouldAutoSave} />
                        <div className="flex-grow-1 min-width-0 text-truncate">
                            <Field name="name" subscription={{ dirty: true, value: true }}>
                                {nameField => nameField.meta.dirty ? nameField.input.value : column.metadataDefinition.name}
                            </Field>
                        </div>
                        <Dropdown className="d-flex" isOpen={usingDisplayEditColumnMenu.value} toggle={usingDisplayEditColumnMenu.toggle} tag="div">
                            <DropdownToggle tag="button" className={cx({ 'opacity-1': usingDisplayEditColumnMenu.value }, 'column-dropdown__column-button')}>
                                <FA size="xs" className="align-top" icon="chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu className="shadow" container="body">
                                <DropdownItem text className="mt-2">
                                    <Field id={`register-edit-name-${registerId}`} name="name" aria-label="Name" initialValue={initialValues.name} component={ValidatedInput} bsSize="sm" validate={required} />
                                </DropdownItem>
                                <DropdownItem header>Actions</DropdownItem>
                                <DropdownItem onClick={editColumnModal.actions.show} size="sm">Edit</DropdownItem>
                                <DropdownItem className="text-danger" onClick={confirmDeleteRegisterModal.actions.show}>Delete</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </>
                )}
            </Form>
            <ConfirmationModal {...confirmDeleteRegisterModal.modalProps} onConfirm={handleDeleteColumn} onReject={confirmDeleteRegisterModal.actions.hide} danger confirmAction="Delete" header="Delete column">
                Are you sure you want to delete column <strong>{column.metadataDefinition.name}</strong>? All data from this column will also be deleted.
            </ConfirmationModal>
            <EditColumnModal {...editColumnModal.modalProps} onSubmit={handleOnSubmit} column={column} />
        </div>
    )
}
