import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { loadFavourites } from '@src/actions/project'
import { reloadUserProfile } from '@src/actions/session'
import ButtonLink from '@src/components/common/ButtonLink'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CopyProjectModal from '@src/components/project/new/CopyProjectModal'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import { CompanyOperations } from '@src/logic/auth/operations'
import { ProjectsList, UserProfilePatch } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { mutedNotSet } from '@src/logic/utils/ValueHelper'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'
import { Session } from '@src/types/session'

interface IProps {
    company: Company
}

interface IConnectedState {
    currentUser: Session.User
}

interface IConnectedDispatch {
    reloadUserAndFavourites: () => void
}

interface IState {
    copyProjectModalOpen: boolean
}

class ProjectsSection extends React.PureComponent<IProps & RouteComponentProps & IConnectedState & IConnectedDispatch, IState> {
    constructor(props) {
        super(props)

        this.state = {
            copyProjectModalOpen: false
        }
    }

    private async onSearch(filter: string, sort: string, page: number, perPage: number, abortSignal: AbortSignal) {
        const response = await ProjectsList(filter, sort, page, perPage, { abortSignal })
        return ({ items: response.data, totalItems: +response.headers[Headers.PaginationTotalCount] })
    }

    private handleSearchError() {
        NotificationService.error('There was an error while loading projects')
        return {
            items: [],
            totalItems: null
        }
    }

    private readonly toggleCopyProjectModal = () => {
        this.setState({ copyProjectModalOpen: !this.state.copyProjectModalOpen })
    }

    private readonly renderCreateButton = () => {
        return (
            <UncontrolledButtonDropdown>
                <ButtonLink
                    to={Routes.PROJECT_CREATE}
                    disabled={!isAuthorised(this.props.company.myAccess, CompanyOperations.CreateProject)}
                    color="secondary"
                    className="float-right"
                >
                    <FA icon="plus" /> New Project
                </ButtonLink>
                <DropdownToggle
                    caret
                />
                <DropdownMenu right>
                    <DropdownItem onClick={this.toggleCopyProjectModal}><FA className="mr-2" icon="clone" />Copy Existing</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        )
    }

    private readonly toggleFavouriteProject = async (project: Project) => {
        const favouriteProjects = this.props.currentUser.favouriteProjects
        const projectIndex = favouriteProjects != null ? favouriteProjects.findIndex(x => x.id === project.id) : -1
        try {
            if (projectIndex < 0) {
                await UserProfilePatch([
                    { op: 'replace', path: '/favouriteProjects', value: [...(favouriteProjects == null ? [] : favouriteProjects.map(x => x.id)), project.id] }
                ])
            } else {
                await UserProfilePatch([
                    { op: 'replace', path: '/favouriteProjects', value: [...(favouriteProjects.filter(x => x.id !== project.id).map(x => x.id))] }
                ])
            }
        } catch {
            //
        } finally {
            this.props.reloadUserAndFavourites()
        }
    }

    public render() {
        return (
            <SearchSection<Project, never>
                onSearch={this.onSearch}
                onSearchError={this.handleSearchError}
                extraSearchBarElements={[
                    {
                        element: this.renderCreateButton,
                        position: 'before'
                    }
                ]}
                headers={[
                    {
                        name: 'Name',
                        sortKey: 'name',
                        sortable: true,
                        overrideRenderer: project => <Link to={Routes.project(project.id)}>{project.name}</Link>
                    },
                    {
                        name: 'Code',
                        sortKey: 'code',
                        sortable: true,
                        overrideRenderer: p => p.settings.code
                    },
                    {
                        name: 'Client',
                        sortKey: 'client',
                        sortable: true,
                        overrideRenderer: p => p.client?.name,
                        defaultFallback: mutedNotSet
                    },
                    {
                        name: 'Description',
                        accessor: 'description',
                        sortKey: 'description',
                        sortable: true,
                        defaultFallback: mutedNotSet
                    },
                    {
                        name: 'Created Date',
                        sortKey: 'created',
                        sortable: true,
                        overrideRenderer: p => localShortDate(new Date(p.createdDate))
                    },
                    {
                        name: 'Email',
                        accessor: 'email',
                        sortKey: 'email',
                        sortable: true
                    },
                    {
                        name: 'Status',
                        sortKey: 'status',
                        sortable: true,
                        overrideRenderer: p => p.settings.status
                    },
                    {
                        name: 'Actions',
                        noSmallHeader: true,
                        headerWrapperClass: 'text-right',
                        overrideRenderer: (p) => {
                            const favourite = this.props.currentUser.favouriteProjects?.find(x => x.id === p.id)
                            return (
                                <div className="justify-content-end text-right selectable-content__actions">
                                    <TooltipLinkAction id={`favourite-${p.id}`} tooltip="Favourite" data={p} onClick={this.toggleFavouriteProject} className={favourite ? 'text-warning' : 'text-muted'}><FA icon={favourite ? 'star' : 'star-half-alt'} /></TooltipLinkAction>
                                </div>
                            )
                        }
                    }
                ]}
                searchAssistantProperties={[
                    {
                        name: 'Name',
                        searchKey: 'name',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Code',
                        searchKey: 'code',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Client',
                        searchKey: 'client',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Description',
                        searchKey: 'description',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Created',
                        searchKey: 'created',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Updated',
                        searchKey: 'updated',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Tags',
                        searchKey: 'tag',
                        type: PropertyType.Text
                    }
                ]}
            >
                <CopyProjectModal isOpen={this.state.copyProjectModalOpen} toggle={this.toggleCopyProjectModal} />
            </SearchSection>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps & RouteComponentProps): IConnectedState {
    return {
        ...ownProps,
        currentUser: state.session.user
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<RootState, never, Action>, ownProps: IProps & RouteComponentProps): IConnectedDispatch {
    return {
        reloadUserAndFavourites: () => {
            dispatch(reloadUserProfile())
            dispatch(loadFavourites())
        }
    }
}

export default connect<IConnectedState, IConnectedDispatch, IProps & RouteComponentProps>(mapStateToProps, mapDispatchToProps)(ProjectsSection)
