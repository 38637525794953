import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import shallow from 'zustand/shallow'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import RegisterToolbar from '@src/components/register/RegisterToolbar'
import useProject from '@src/hooks/useProject'
import { RegisterOperations } from '@src/logic/auth/operations'
import { isAxiosError } from '@src/logic/http/helpers'
import * as Routes from '@src/logic/routing/routes'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import RegisterAccessPage from '@src/pages/register/RegisterAccessPage'
import RegisterDataPage from '@src/pages/register/RegisterDataPage'
import useRegisterStore from '@src/store/register'
import { ClientErrorCode } from '@src/types/http'

export default function RegisterPages() {
    const { params, url } = useRouteMatch<Routes.IProjectRegisterParams>()
    const [project] = useProject()
    const [registerOverview, loadError, loadRegister] = useRegisterStore(s => [s.register, s.loadError, s.load], shallow)

    useAsyncAbortable(abortSignal => loadRegister(params.registerId, abortSignal), [params.registerId])

    const errorCode: ClientErrorCode | null = React.useMemo(() => {
        if (loadError == null) return null
        const error = loadError
        if (isAxiosError(error) && error.response) {
            switch (error.response.status) {
                case 403:
                    return ClientErrorCode.Forbidden
                case 404:
                    return ClientErrorCode.NotFound
                default:
                    return null
            }
        }
        return null
    }, [loadError])

    function getBannerTitle() {
        if (errorCode) {
            switch (errorCode) {
                case ClientErrorCode.Forbidden:
                    return 'Permission Issue'
                case ClientErrorCode.NotFound:
                    return 'Register Not Found'
            }
        }

        return registerOverview?.name ?? <>Loading register <FA icon="spinner-third" spin /></>
    }

    return (
        <CrumbRoute path="" linkPath={url} title={registerOverview?.name ?? ''}>
            <>
                <ProjectBanner project={project} overrideTitle={getBannerTitle()} />
                <ClientErrorPage clientErrorCode={errorCode} resourceName="register" requiredOperation={RegisterOperations.Read}>
                    {registerOverview &&
                        <>
                            <RegisterToolbar currentRoutePath={location.pathname} register={registerOverview} />
                            <Switch>
                                <CrumbRoute path={Routes.PROJECT_REGISTER_ACCESS} title="Access">
                                    <RegisterAccessPage />
                                </CrumbRoute>
                                <Route path={Routes.PROJECT_REGISTER} exact>
                                    <RegisterDataPage />
                                </Route>
                                <Route>
                                    <Redirect to={Routes.projectRegister(project.id, registerOverview.id)} />
                                </Route>
                            </Switch>
                        </>
                    }
                </ClientErrorPage>
            </>
        </CrumbRoute>
    )
}
