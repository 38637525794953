import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFeedback, Input, InputProps } from 'reactstrap'

import { v4 } from 'uuid'

export type IProps = RemoveIndex<InputProps> & React.TextareaHTMLAttributes<HTMLTextAreaElement> & FieldRenderProps<string>

export default class ValidatedTextArea extends React.PureComponent<IProps> {
    private readonly id: string

    constructor(props: IProps) {
        super(props)
        this.id = props.id ?? v4()
    }

    public render() {
        const { input, meta: { touched, error, ...otherMeta }, type, ...htmlInputProps } = this.props
        const submitError = otherMeta.submitError
        const showError = touched && (!!error || (!!submitError && !otherMeta.dirtySinceLastSubmit))

        const ariaErrorMessage = htmlInputProps['aria-errormessage'] ?? `${this.id}-errorMessage`

        return (
            <>
                <Input {...htmlInputProps} aria-errormessage={ariaErrorMessage} {...input} type="textarea" invalid={showError} {...showError ? { 'aria-invalid': true } : {}} />
                <FormFeedback id={ariaErrorMessage}>
                    {showError && (error || submitError)}
                </FormFeedback>
            </>
        )
    }
}
