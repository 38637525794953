import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'

import cx from 'classnames'
import { compare } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { documentLinkLabel, documentLinkValue } from '@src/logic/forms/SelectHelpers'
import { BudgetItemsList, BudgetPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { BudgetOverview } from '@src/types/costs'
import { DocumentLink } from '@src/types/document'
import { RootState } from '@src/types/models'

export interface IBudgetSettingsFormData {
    approvedBudget: number
    budgetApprovalDate?: Date
    locked: boolean
    clientBudgetAdvice: DocumentLink[]
    consultantBudgetAdvice: DocumentLink[]
}

interface IProps {
    budget: BudgetOverview
    projectId: string
}

export default function BudgetSettingsForm({ budget, projectId }: IProps) {
    const dispatch = useDispatch()
    const availableRevisions = useSelector<RootState, DocumentLink[]>(x => getActiveProjectWidgetState(x).revisions.map<DocumentLink>(r => ({
        revisionId: r.id,
        documentId: r.documentId,
        name: r.name
    })))
    const totalBudgetSumAsync = useAsyncAbortable(async (abortSignal, pid) =>
        (await (BudgetItemsList(pid, undefined, undefined, 1, 1, { abortSignal }))).data.aggregate.totalSum,
        [projectId])
    const canLock = totalBudgetSumAsync.result != null ? totalBudgetSumAsync.result === budget.approvedBudget : true

    async function saveBudgetSettings(values: IBudgetSettingsFormData) {
        const original: BudgetOverview = {
            ...budget
        }
        const update: BudgetOverview = {
            ...budget,
            locked: values.locked,
            approvedBudget: values.approvedBudget,
            budgetApprovalDate: values.budgetApprovalDate,
            clientBudgetAdvice: values.clientBudgetAdvice != null ? values.clientBudgetAdvice : [],
            consultantBudgetAdvice: values.consultantBudgetAdvice != null ? values.consultantBudgetAdvice : []
        }

        const patch = compare(original, update)

        try {
            await BudgetPatch(projectId, patch)
        } catch {
            NotificationService.error('An error occurred while saving budget settings')
            return
        }
        NotificationService.info('Budget settings saved')
        dispatch(loadProjectCostsOverview())
    }

    async function toggleBudgetLock() {
        try {
            await BudgetPatch(projectId, [{ op: 'replace', path: '/locked', value: !budget.locked }])
        } catch {
            NotificationService.error(`Unable to ${budget.locked ? 'unlock' : 'lock'} budget`)
            return
        }

        dispatch(loadProjectCostsOverview())
    }

    function noOptionsMessage(data: { inputValue: string }) {
        return data.inputValue === '' ? 'No documents in widget' : 'No document in widget match'
    }

    return (
        <Form onSubmit={saveBudgetSettings}>
            {({ handleSubmit }) =>
                <Card>
                    <CardHeader className="d-flex">
                        <div className="flex-grow-1"><FA icon="cog" /> Budget Settings</div>
                        <Button color="primary" className={cx({ 'd-none': budget.locked })} onClick={handleSubmit}>Save</Button>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Button className="mr-2" onClick={toggleBudgetLock} disabled={!budget.locked && !canLock}>{budget.locked ? 'Unlock' : 'Lock'} Budget</Button>
                                    {!budget.locked && !canLock && <FormText color="warning"><FA icon="exclamation-triangle" /> The budget cannot be locked because the current budget value does not equal the approved budget</FormText>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="budgetSettings-approvedBudget">Approved Budget</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Field id="budgetSettings-approvedBudget" name="approvedBudget" component={ValidatedCurrencyInput} disabled={budget.locked} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for='budgetSettings-approvalDate'>Approval Date</Label>
                                    <Field id='budgetSettings-approvalDate' name="budgetApprovalDate" component={ValidatedDatePicker} disabled={budget.locked} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="budgetSettings-clientBudgetAdvice">Client Budget Advice</Label>
                                    <Field
                                        id="budgetSettings-clientBudgetAdvice"
                                        name="clientBudgetAdvice"
                                        component={ValidatedSelect}
                                        options={availableRevisions}
                                        placeholder="Select documents..."
                                        isMulti
                                        getOptionLabel={documentLinkLabel}
                                        getOptionValue={documentLinkValue}
                                        isDisabled={budget.locked}
                                        noOptionsMessage={noOptionsMessage}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="budgetSettings-consultantBudgetAdvice">Consultant Budget Advice</Label>
                                    <Field
                                        id="budgetSettings-consultantBudgetAdvice"
                                        name="consultantBudgetAdvice"
                                        component={ValidatedSelect}
                                        options={availableRevisions}
                                        placeholder="Select documents..."
                                        isMulti
                                        getOptionLabel={documentLinkLabel}
                                        getOptionValue={documentLinkValue}
                                        isDisabled={budget.locked}
                                        noOptionsMessage={noOptionsMessage}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
        </Form>
    )
}
