import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Card, CardHeader, Col, Container, Row } from 'reactstrap'

import { AddOperation } from 'fast-json-patch'

import FA from '@src/components/common/FontAwesomeIcon'
import TemplateEditForm from '@src/components/communication/TemplateEditForm'
import TemplateLayoutEditForm from '@src/components/communication/TemplateLayoutEditForm'
import MetadataDefinitionList from '@src/components/metadata/definitions/MetadataDefinitionList'
import NewDefinitionModal, { INewDefinitionModalFormData } from '@src/components/metadata/definitions/NewDefinitionModal'
import ModalToggle from '@src/components/modal/ModalToggle'
import { TemplatePatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Template } from '@src/types/communication'
import { BaseMetadataTypes, INewMetadataDefinition, IUpdateMetadataDefinition, MetadataTypes } from '@src/types/metadata'

interface IProps {
    template: Template
    reloadTemplate: () => void
}

const TemplateMetadataTypes = [MetadataTypes.Bool, MetadataTypes.Date, MetadataTypes.DocumentLinks, MetadataTypes.Numeric, MetadataTypes.Select, MetadataTypes.Text, MetadataTypes.TransmittalLinks, MetadataTypes.EmailLinks, MetadataTypes.CompanyLinks, MetadataTypes.UserLinks, MetadataTypes.AutoNumber, MetadataTypes.CommitmentLinks, MetadataTypes.PaymentClaimLinks]

export default class TemplateEditPage extends React.PureComponent<IProps & RouteComponentProps> {
    private readonly addMetadataDefinition = async (values: INewDefinitionModalFormData) => {
        const patch: AddOperation<INewMetadataDefinition> = {
            op: 'add',
            path: '/metadataDefinitions/-',
            value: {
                key: values.key,
                name: values.name,
                description: '',
                tags: [],
                isRequired: false,
                options: {},
                type: values.type as BaseMetadataTypes
            }
        }

        await TemplatePatch(this.props.template.id, [patch])
        this.props.reloadTemplate()
    }

    private readonly onAddMetadataDefinitionSuccess = () => {
        this.props.reloadTemplate()
        NotificationService.info('Metadata added to template')
    }

    private readonly handleDeleteMetadataDefinition = async (key: string) => {
        try {
            const metadataIndex = this.props.template.metadataDefinitions.findIndex(md => md.key === key)
            await TemplatePatch(this.props.template.id, [
                { op: 'test', path: `/metadataDefinitions/${metadataIndex}/key`, value: key },
                { op: 'test', path: `/metadataDefinitions/${metadataIndex}/type`, value: this.props.template.metadataDefinitions[metadataIndex].type },
                { op: 'remove', path: `/metadataDefinitions/${metadataIndex}` }
            ])
            NotificationService.info('Removed autotext from template')
        } catch {
            NotificationService.error('Unable to remove autotext')
        } finally {
            this.props.reloadTemplate()
        }
    }

    private readonly handleUpdateMetadataDefinition = async (key: string, update: IUpdateMetadataDefinition) => {
        try {
            const metadataIndex = this.props.template.metadataDefinitions.findIndex(md => md.key === key)
            await TemplatePatch(this.props.template.id, [
                { op: 'test', path: `/metadataDefinitions/${metadataIndex}/key`, value: key },
                { op: 'test', path: `/metadataDefinitions/${metadataIndex}/type`, value: this.props.template.metadataDefinitions[metadataIndex].type },
                { op: 'replace', path: `/metadataDefinitions/${metadataIndex}`, value: update }
            ])
            NotificationService.info('Updated autotext for template')
        } catch {
            NotificationService.error('Unable to remove autotext')
        } finally {
            this.props.reloadTemplate()
        }
    }

    public render() {
        const { template } = this.props

        return (
            <Container fluid>
                <Row className="mb-5">
                    <Col xs={12} xl={6}>
                        <Row>
                            <Col xs={6} xl={12} className="mb-3">
                                <TemplateEditForm template={template} onUpdated={this.props.reloadTemplate} />
                            </Col>
                            <Col xs={6} xl={12} className="mb-3">
                                <Card>
                                    <CardHeader className="d-flex align-items-center">
                                        <span><FA icon="brackets-curly" /> Autotext fields</span>
                                        <span id="register-create-column" className="ml-auto">
                                            <ModalToggle
                                                modal={NewDefinitionModal}
                                                modalProps={{ existingDefinitions: template.metadataDefinitions, validTypes: TemplateMetadataTypes, onSubmit: this.addMetadataDefinition, onSubmitSuccess: this.onAddMetadataDefinitionSuccess }}
                                            >
                                                <FA icon="plus-circle" /> Add Autotext
                                            </ModalToggle>
                                        </span>
                                    </CardHeader>
                                    <MetadataDefinitionList
                                        definitions={template.metadataDefinitions}
                                        onDeleteDefinition={this.handleDeleteMetadataDefinition}
                                        onUpdateDefinition={this.handleUpdateMetadataDefinition}
                                        fieldLabels={{
                                            name: 'Autotext Name',
                                            key: 'Autotext Template Key',
                                            description: 'Description'
                                        }}
                                        fieldDescriptions={{
                                            name: 'Name of the autotext.',
                                            key: 'The key the autotext will use in the template.',
                                            description: 'A short description indicating how to use the autotext.'
                                        }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} xl={6}>
                        <TemplateLayoutEditForm
                            template={template}
                            onUpdated={this.props.reloadTemplate}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
