// tslint:disable:max-classes-per-file

import React from 'react'
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, Row } from 'reactstrap'

import { isEqual } from 'lodash'

import DatePicker from '@src/components/common/DatePicker'
import FA from '@src/components/common/FontAwesomeIcon'
import Select, { IOption, buildOptions } from '@src/components/common/Select'
import { IModalProps } from '@src/hooks/useModal'
import { localLongDate } from '@src/logic/utils/Date'
import { IMetadataDefinition, MetadataTypes, SelectDefinition } from '@src/types/metadata'

export enum PropertyType {
    Text = 'Text',
    Number = 'Number',
    Date = 'Date',
    Select = 'Select',
    Boolean = 'Boolean'
}

export interface ISearchProperty {
    name: string
    searchKey: string
    type: PropertyType
    selectOptions?: IOption<string>[]
}

export interface IMappedSearchProperty<T> extends ISearchProperty {
    path: (item: T) => string | number | Date
}

export function metadataSearchProperty(metadataDefinition: IMetadataDefinition, keyPrefix: string = ''): ISearchProperty {
    let type: PropertyType
    const options: IOption<string>[] = []

    switch (metadataDefinition.type) {
        case MetadataTypes.Bool:
            type = PropertyType.Select
            options.push(...buildOptions(['True', 'False']))
            break
        case MetadataTypes.Date:
            type = PropertyType.Date
            break
        case MetadataTypes.Numeric:
            type = PropertyType.Number
            break
        case MetadataTypes.Select:
            type = PropertyType.Select
            options.push(...buildOptions((metadataDefinition as SelectDefinition).options.values))
            break
        case MetadataTypes.Text:
        default:
            type = PropertyType.Text
    }

    return ({
        type,
        selectOptions: options,
        name: metadataDefinition.name,
        searchKey: keyPrefix + metadataDefinition.key
    })
}

enum SearchOperation {
    Equal,
    GreaterThan,
    LessThan,
    NotSet
}

interface ISearchCriteria {
    property: ISearchProperty
    operation: SearchOperation
    value: any
}

const emptySearchCriteria: ISearchCriteria = {
    property: null,
    operation: null,
    value: ''
}

interface ISearchCriteriaRowProps {
    criteria: ISearchCriteria
    index: number
    availableProps: ISearchProperty[]
    onRemove: (index: number) => void
    onChangeProperty: (index: number, name: string) => void
    onChangeOperation: (index: number, name: SearchOperation) => void
    onChangeValue: (index: number, value: any) => void
    onSubmit: () => void
}

class SearchCriteriaRow extends React.PureComponent<ISearchCriteriaRowProps> {
    private readonly handleRemove = () => {
        this.props.onRemove(this.props.index)
    }

    private readonly handleChangeProperty = (value: ISearchProperty) => {
        this.props.onChangeProperty(this.props.index, value.name)
    }

    private readonly handleChangeOperation = (option: IOption<SearchOperation>) => {
        this.props.onChangeOperation(this.props.index, option.value)
    }

    private readonly handleChangeValueEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeValue(this.props.index, event.target.value)
    }

    private readonly handleChangeValue = (value: any) => {
        this.props.onChangeValue(this.props.index, value)
    }

    private readonly handleChangeDateValue = (value: Date) => {
        this.props.onChangeValue(this.props.index, value)
    }

    private readonly handleSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            this.props.onSubmit()
        }
    }

    private readonly operationOptions = () => {
        const { criteria } = this.props
        if (this.props.criteria.property == null) return []

        switch (criteria.property.type) {
            case PropertyType.Date:
                return [{ value: SearchOperation.Equal, label: 'During' }, { value: SearchOperation.GreaterThan, label: 'After' }, { value: SearchOperation.LessThan, label: 'Before' }, { value: SearchOperation.NotSet, label: 'Not Set' }]
            case PropertyType.Number:
                return [{ value: SearchOperation.Equal, label: 'Equal To' }, { value: SearchOperation.GreaterThan, label: 'Greater Than' }, { value: SearchOperation.LessThan, label: 'Less Than' }, { value: SearchOperation.NotSet, label: 'Not Set' }]
            case PropertyType.Select:
            case PropertyType.Text:
                return [{ value: SearchOperation.Equal, label: 'Contains' }, { value: SearchOperation.NotSet, label: 'Not Set' }]
            default:
                return []
        }
    }

    private readonly renderValueInput = () => {
        const { criteria } = this.props
        if (criteria.operation === SearchOperation.NotSet) return null
        if (criteria.property == null) return <Input value={criteria.value} onChange={this.handleChangeValueEvent} placeholder="Value" aria-label="search value"/>
        const label = `${criteria.property.name} value`
        switch (criteria.property.type) {
            case PropertyType.Text:
                return <Input value={criteria.value} onChange={this.handleChangeValueEvent} onKeyPress={this.handleSubmit} placeholder="Value" aria-label={label}/>
            case PropertyType.Number:
                return <Input value={criteria.value} onChange={this.handleChangeValueEvent} onKeyPress={this.handleSubmit} type="number" placeholder="Value" aria-label={label}/>
            case PropertyType.Date:
                return <DatePicker selected={criteria.value} onChange={this.handleChangeDateValue} placeholder="Date"/>
            case PropertyType.Select:
                return <Select selectType="creatable" value={criteria.value === '' ? null : criteria.value} options={criteria.property.selectOptions} onChange={this.handleChangeValue} onKeyDown={this.handleSubmit} placeholder="Value" aria-label={label}/>
            default:
                return <Input value={criteria.value} onChange={this.handleChangeValueEvent} onKeyPress={this.handleSubmit} placeholder="Value" aria-label={label}/>
        }
    }

    private getPropertyLabel(property: ISearchProperty) {
        return property.name
    }

    private getPropertyValue(property: ISearchProperty) {
        return property.searchKey
    }

    public render() {
        const { criteria, index } = this.props
        const operationOptions = this.operationOptions()
        return (
            <Row key={index} className="mb-3">
                <Col md={4}>
                    <Select
                        onChange={this.handleChangeProperty}
                        value={criteria.property}
                        options={this.props.availableProps}
                        placeholder="Property"
                        getOptionLabel={this.getPropertyLabel}
                        getOptionValue={this.getPropertyValue}
                    />
                </Col>
                <Col md={3}>
                    <Select
                        onChange={this.handleChangeOperation}
                        value={operationOptions.find(o => o.value === criteria.operation)}
                        options={operationOptions}
                        placeholder="Is..."
                    />
                </Col>
                <Col md={4}>
                    {this.renderValueInput()}
                </Col>
                <Col md={1}>
                    {// tslint:disable-next-line:jsx-no-lambda
                        index === 0 ? null : <FA className="pointer" icon="times" onClick={this.handleRemove} />}
                </Col>
            </Row>
        )
    }
}

interface IProps extends IModalProps {
    onSearch: (searchFilter: string) => void
    availableProps: ISearchProperty[]
}

interface IState {
    touched: boolean
    searchCriteria: ISearchCriteria[]
}

export default class SearchAssistantModal extends React.PureComponent<IProps & ModalProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            touched: false,
            searchCriteria: props.availableProps.map<ISearchCriteria>(searchProp => ({
                operation: null,
                property: searchProp,
                value: ''
            }))
        }
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.availableProps !== this.props.availableProps && !isEqual(prevProps.availableProps, this.props.availableProps)) {
            this.loadSearchCriteriaFromProps()
        }
    }

    public reset = () => this.state.touched && this.loadSearchCriteriaFromProps()

    private readonly loadSearchCriteriaFromProps = () => {
        this.setState({
            touched: false,
            searchCriteria: this.props.availableProps.map<ISearchCriteria>(searchProp => ({
                operation: null,
                property: searchProp,
                value: ''
            }))
        })
    }

    private readonly buildSearch = () => {
        function operator(operation: SearchOperation) {
            switch (operation) {
                case SearchOperation.GreaterThan:
                    return '>'
                case SearchOperation.LessThan:
                    return '<'
                default:
                    return ''
            }
        }

        const builtFilter = this.state.searchCriteria
            .filter(c => c.property != null && c.value != null && c.value !== '')
            .map((criteria) => {
                let formattedValue = ''

                switch (criteria.property.type) {
                    case PropertyType.Select:
                        formattedValue = `"${criteria.value.value}"`
                        break
                    case PropertyType.Text:
                        formattedValue = `"${criteria.value}"`
                        break
                    case PropertyType.Number:
                        formattedValue = criteria.value
                        break
                    case PropertyType.Date:
                        formattedValue = `"${localLongDate(criteria.value)}"`
                        break
                }

                return `${criteria.property.searchKey}: ${operator(criteria.operation)} ${formattedValue}`
            })
            .join(' and ')

        this.props.onSearch(builtFilter)
        this.props.toggle()
    }

    private readonly addSearchCriteria = () => {
        this.setState({ touched: true, searchCriteria: [...this.state.searchCriteria, { ...emptySearchCriteria }] })
    }

    private readonly removeSearchCriteria = (index: number) => {
        const newCriteria = [...this.state.searchCriteria]
        newCriteria.splice(index, 1)
        this.setState({ touched: true, searchCriteria: newCriteria })
    }

    private readonly handleChangeProperty = (index: number, value: string) => {
        const newCriteria = { ...this.state.searchCriteria[index] }
        if (newCriteria.property == null || value == null || newCriteria.property.name !== value) {
            newCriteria.operation = null
            newCriteria.value = ''
        }
        newCriteria.property = value == null ? null : { ...this.props.availableProps.find(p => p.name === value) }
        const allCriteria = [...this.state.searchCriteria]
        allCriteria[index] = newCriteria
        this.setState({ touched: true, searchCriteria: allCriteria })
    }

    private readonly handleChangeOperation = (index: number, value: SearchOperation) => {
        const newCriteria = { ...this.state.searchCriteria[index] }
        newCriteria.operation = value
        const allCriteria = [...this.state.searchCriteria]
        allCriteria[index] = newCriteria
        this.setState({ touched: true, searchCriteria: allCriteria })
    }

    private readonly handleChangeValue = (index: number, value: string) => {
        const newCriteria = { ...this.state.searchCriteria[index] }
        newCriteria.value = value
        const allCriteria = [...this.state.searchCriteria]
        allCriteria[index] = newCriteria
        this.setState({ touched: true, searchCriteria: allCriteria })
    }

    private readonly renderSearchCriteria = () => {
        return this.state.searchCriteria.map((criteria, idx) => (
            <SearchCriteriaRow
                key={idx}
                index={idx}
                criteria={criteria}
                availableProps={this.props.availableProps}
                onRemove={this.removeSearchCriteria}
                onChangeOperation={this.handleChangeOperation}
                onChangeProperty={this.handleChangeProperty}
                onChangeValue={this.handleChangeValue}
                onSubmit={this.buildSearch}
            />)
        )
    }

    public render() {
        const { isOpen, toggle, onClosed } = this.props
        return (
            <Modal size="lg" isOpen={isOpen} toggle={toggle} onClosed={onClosed} unmountOnClose={false}>
                <ModalHeader toggle={toggle}><FA icon="search" /> Search Assistant</ModalHeader>
                <ModalBody>
                    {this.renderSearchCriteria()}
                    <Button size="sm" color="link" onClick={this.addSearchCriteria}><FA icon="plus" /> Add Search Criteria</Button>
                </ModalBody>
                <ModalFooter><Button color="primary" onClick={this.buildSearch}>Search</Button></ModalFooter>
            </Modal>
        )
    }
}
