import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Container } from 'reactstrap'

import { loadProjectCostsOverview } from '@src/actions/project'
import ActionBar from '@src/components/common/ActionBar'
import CheckboxRadio from '@src/components/common/CheckboxRadio'
import EmptyResourceArea from '@src/components/common/EmptyResourceArea'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import PhaseModal from '@src/components/costs/settings/PhaseModal'
import GenericContentTable from '@src/components/table/GenericContentTable'
import { PhaseDelete } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { formatCurrency } from '@src/logic/utils/Currency'
import { Phase } from '@src/types/costs'
import { RootState } from '@src/types/models'

interface IConnectedState {
    projectId: string
    phases: Phase[]
}

export default function PhaseSettingSection() {
    const dispatch = useDispatch()
    const { projectId, phases } = useSelector<RootState, IConnectedState>(s => ({ projectId: s.projects.active.id, phases: s.projects.activeCostsOverview.phases }))
    const [{ phase, isCreating }, setPhaseAndCreating] = React.useState<{ phase: Phase, isCreating: boolean }>({ phase: null, isCreating: false })

    function editPhase(phase: Phase) {
        setPhaseAndCreating({ phase, isCreating: false })
    }

    async function deletePhase(phase: Phase) {
        try {
            await PhaseDelete(projectId, phase.code)
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.response.status === 400) {
                    NotificationService.error('Cannot delete phase. It is locked or being used in budget or commitments.')
                }
            }
        }
        dispatch(loadProjectCostsOverview())
    }

    function markCreating() {
        setPhaseAndCreating({ isCreating: true, phase: null })
    }

    function clearCreateAndEdit() {
        setPhaseAndCreating({ isCreating: false, phase: null })
    }

    function onSubmitSuccess() {
        dispatch(loadProjectCostsOverview())
        clearCreateAndEdit()
    }

    const phasesExistBody = (
        <GenericContentTable
            data={phases}
            headers={[
                {
                    name: 'Phase',
                    accessor: 'code'
                },
                {
                    name: 'Name',
                    accessor: 'name'
                },
                {
                    name: 'Description',
                    accessor: 'description'
                },
                {
                    name: 'Approved Value',
                    overrideRenderer: phase => formatCurrency(phase.approvedValue)
                },
                {
                    name: 'Locked',
                    overrideRenderer: phase => <CheckboxRadio disabled checked={phase.locked} />
                },
                {
                    name: 'Actions',
                    headerWrapperClass: 'text-right',
                    noSmallHeader: true,
                    overrideRenderer: (phase, rowIdx) => (
                        <div className="justify-content-end text-right selectable-content__actions">
                            <TooltipLinkAction id={`edit-phase-${rowIdx}`} tooltip="Edit" data={phase} onClick={editPhase}><FA icon="pencil" /></TooltipLinkAction>
                            <TooltipLinkAction id={`delete-phase-${rowIdx}`} tooltip="Remove" data={phase} onClick={deletePhase}><FA icon="trash" /></TooltipLinkAction>
                        </div>
                    )
                }
            ]}
        />
    )

    const noPhasesBody = (
        <Card body className="mt-3">
            <EmptyResourceArea
                icon="stream"
                onClick={markCreating}
                buttonText="Create phase"
                messageText="Divide you project costs into distinct phases that you want to assign specific budget, commitments, and payments to."
            />
        </Card>
    )

    return (
        <>
            {phases.length > 0 &&
                <ActionBar className="mb-3">
                    <Button onClick={markCreating}><FA icon="plus-circle" /> Create Phase</Button>
                </ActionBar>
            }
            <Container fluid>
                {phases.length > 0 ? phasesExistBody : noPhasesBody}
                <PhaseModal
                    isOpen={phase != null || isCreating}
                    toggle={clearCreateAndEdit}
                    projectId={projectId}
                    existingPhases={phases}
                    phaseToEdit={phase}
                    onCreateOrEdit={onSubmitSuccess}
                />
            </Container>
        </>
    )
}
