import { localDateTime } from '@src/logic/utils/Date'
import { BoolDefinition, DateDefinition, IMetadataDefinition, NumericDefinition, SelectDefinition, TextDefinition } from '@src/types/metadata'

export const ATTRIBUTE_REQUIRED = 'Required'
export const ATTRIBUTE_MAX_VALUE = 'Maximum value'
export const ATTRIBUTE_MIN_VALUE = 'Minimum value'
export const ATTRIBUTE_MAX_LENGTH = 'Maximum length'
export const ATTRIBUTE_MIN_LENGTH = 'Minimum length'
export const ATTRIBUTE_SUGGESTED_MAX_VALUE = 'Suggested maximum value'
export const ATTRIBUTE_SUGGESTED_MIN_VALUE = 'Suggested minimum value'
export const ATTRIBUTE_SUGGESTED_MAX_LENGTH = 'Suggested maximum length'
export const ATTRIBUTE_SUGGESTED_MIN_LENGTH = 'Suggested minimum length'
export const ATTRIBUTE_ENFORCED_VALUES = 'Need to select from provided values'
export const ATTRIBUTE_CREATE_VALUES_PERMITTED = 'Allowed to create new values'
export const ATTRIBUTE_CAN_SELECT_MULTIPLE = 'Multiple values permitted'

export function boolAttributes(defintion: BoolDefinition): string[] {
    const requirements: string[] = []

    if (defintion.isRequired) {
        requirements.push(ATTRIBUTE_REQUIRED)
    }

    return requirements
}

export function numericAttributes(defintion: NumericDefinition): string[] {
    const requirements: string[] = []

    if (defintion.isRequired) {
        requirements.push(ATTRIBUTE_REQUIRED)
    }

    if (defintion.options.maxValue !== null) {
        if (defintion.options.enforceRange) {
            requirements.push(`${ATTRIBUTE_MAX_VALUE} ${defintion.options.maxValue}`)
        } else {
            requirements.push(`${ATTRIBUTE_SUGGESTED_MAX_VALUE} ${defintion.options.maxValue}`)
        }
    }

    if (defintion.options.minValue !== null) {
        if (defintion.options.enforceRange) {
            requirements.push(`${ATTRIBUTE_MIN_VALUE} ${defintion.options.minValue}`)
        } else {
            requirements.push(`${ATTRIBUTE_SUGGESTED_MIN_VALUE} ${defintion.options.minValue}`)
        }
    }

    return requirements
}

export function textAttributes(defintion: TextDefinition): string[] {
    const requirements: string[] = []

    if (defintion.isRequired) {
        requirements.push(ATTRIBUTE_REQUIRED)
    }

    if (defintion.options.maxLength !== null) {
        requirements.push(`${ATTRIBUTE_MAX_LENGTH} ${defintion.options.maxLength}`)
    }

    if (defintion.options.minLength !== null) {
        requirements.push(`${ATTRIBUTE_MIN_LENGTH} ${defintion.options.minLength}`)
    }

    return requirements
}

export function dateAttributes(defintion: DateDefinition): string[] {
    const requirements: string[] = []

    if (defintion.isRequired) {
        requirements.push(ATTRIBUTE_REQUIRED)
    }

    if (defintion.options.maxValue !== null) {
        if (defintion.options.enforceRange) {
            requirements.push(`${ATTRIBUTE_MAX_VALUE} before ${localDateTime(defintion.options.maxValue)}`)
        } else {
            requirements.push(`${ATTRIBUTE_SUGGESTED_MAX_VALUE} before ${localDateTime(defintion.options.maxValue)}`)
        }
    }

    if (defintion.options.minValue != null) {
        if (defintion.options.enforceRange) {
            requirements.push(`${ATTRIBUTE_MIN_VALUE} after ${localDateTime(defintion.options.minValue)}`)
        } else {
            requirements.push(`${ATTRIBUTE_SUGGESTED_MIN_VALUE} after ${localDateTime(defintion.options.minValue)}`)
        }
    }

    return requirements
}

export function selectAttributes(defintion: SelectDefinition): string[] {
    const requirements: string[] = []

    if (defintion.isRequired) {
        requirements.push(ATTRIBUTE_REQUIRED)
    }

    if (defintion.options.enforceValues) {
        requirements.push(ATTRIBUTE_ENFORCED_VALUES)
    } else {
        requirements.push(ATTRIBUTE_CREATE_VALUES_PERMITTED)
    }

    if (defintion.options.isMultiselect) {
        requirements.push(ATTRIBUTE_CAN_SELECT_MULTIPLE)
    }

    return requirements
}

export function metaAttributes(definition: IMetadataDefinition): string[] {
    switch (definition.type) {
        case 'bool':
            return boolAttributes(definition as BoolDefinition)
        case 'numeric':
            return numericAttributes(definition as NumericDefinition)
        case 'text':
            return textAttributes(definition as TextDefinition)
        case 'date':
            return dateAttributes(definition as DateDefinition)
        case 'select':
            return selectAttributes(definition as SelectDefinition)
        default:
            return []
    }
}
