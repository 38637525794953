import React from 'react'
import { RouteComponentProps } from 'react-router'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import NewCommunicationModal from '@src/components/communication/NewCommunicationModal'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import ModalToggle from '@src/components/modal/ModalToggle'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { CommunicationDelete, CommunicationsList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import { valueOrMutedFallback } from '@src/logic/utils/ValueHelper'
import { Communication, CommunicationBrief } from '@src/types/communication'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
}

interface IState {
    showConfirmDelete: boolean
    communicationToDelete: CommunicationBrief
}

export default class CommunicationsSection extends React.PureComponent<IProps & RouteComponentProps, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<CommunicationBrief, never>>

    constructor(props) {
        super(props)

        this.searchSectionRef = React.createRef()

        this.state = {
            showConfirmDelete: false,
            communicationToDelete: null
        }
    }

    private readonly onSearch = async (filter: string, sort: string, page: number, perPage: number, abortSignal: AbortSignal) => {
        const response = await CommunicationsList(this.props.project.id, filter, sort, page, perPage, { abortSignal })
        return ({
            items: response.data,
            totalItems: +response.headers[Headers.PaginationTotalCount]
        })
    }

    private readonly handleCommunicationCreated = (communication: Communication) => {
        NotificationService.info('Communication created')
        this.props.history.push(Routes.projectCommunication(this.props.project.id, communication.id))
    }

    private readonly deleteCommunication = async () => {
        await CommunicationDelete(this.state.communicationToDelete.id)
        NotificationService.info('Communication deleted')
        this.clearConfirmDelete()
        this.searchSectionRef.current.doSearch()
    }

    private readonly setCommunicationToDelete = (communication: CommunicationBrief) => {
        this.setState({ communicationToDelete: communication, showConfirmDelete: true })
    }

    private readonly clearConfirmDelete = () => {
        this.setState({ showConfirmDelete: false })
    }

    private readonly clearCommunicationToDelete = () => {
        this.setState({ communicationToDelete: null })
    }

    public render() {
        const { project } = this.props
        const { communicationToDelete, showConfirmDelete } = this.state

        const noItemsFoundMessage = (
            <>
                <div className="my-3"><FA size="3x" icon="file-times" /></div>
                <p className="lead">No communications found with the current search criteria...</p>
                <p>{"Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters."}</p>
            </>
        )

        return (
            <SearchSection<CommunicationBrief, never>
                ref={this.searchSectionRef}
                headers={[
                    {
                        name: '#',
                        accessor: 'communicationNo',
                        sortKey: 'comm_no',
                        sortable: true,
                        noSmallHeader: true
                    },
                    {
                        name: 'Template',
                        sortKey: 'template',
                        sortable: true,
                        overrideRenderer: c => c.templateReference?.name
                    },
                    {
                        name: 'Name',
                        sortKey: 'name',
                        sortable: true,
                        noSmallHeader: true,
                        overrideRenderer: communication => (
                            <div className="selectable-content__title">
                                <Link to={Routes.projectCommunication(project.id, communication.id)}>{valueOrMutedFallback(communication.name, 'untitled')}</Link>
                            </div>
                        )
                    },
                    {
                        name: 'Description',
                        accessor: 'description',
                        sortKey: 'description',
                        sortable: true,
                        overrideRenderer: c => c.description
                    },
                    {
                        name: 'Created By',
                        sortKey: 'created_by',
                        sortable: true,
                        overrideRenderer: c => c.createdBy.name
                    },
                    {
                        name: 'Last Update',
                        sortable: true,
                        sortKey: 'updated',
                        overrideRenderer: c => localShortDate(c.updatedDate)
                    },
                    {
                        name: 'Actions',
                        noSmallHeader: true,
                        overrideRenderer: c => (
                            <div className="justify-content-end text-right selectable-content__actions">
                                <TooltipLink id={`edit-${c.id}`} tooltip="Edit" to={Routes.projectCommunicationEdit(project.id, c.id)} className="selectable-content__icon order-lg-1"><FA icon="pencil" /></TooltipLink>
                                <TooltipLinkAction id={`delete-${c.id}`} tooltip="Delete" data={c} onClick={this.setCommunicationToDelete}><FA icon="trash" /></TooltipLinkAction>
                            </div>
                        ),
                        headerWrapperClass: 'text-right'
                    }
                ]}
                onSearch={this.onSearch}
                searchAssistantProperties={[
                    {
                        name: 'Communication Number',
                        searchKey: 'comm_no',
                        type: PropertyType.Number
                    },
                    {
                        name: 'Name',
                        searchKey: 'name',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Template',
                        searchKey: 'template',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Description',
                        searchKey: 'description',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Created By',
                        searchKey: 'created_by',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Updated',
                        searchKey: 'updated',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Body',
                        searchKey: 'body',
                        type: PropertyType.Text
                    }
                ]}
                extraSearchBarElements={[{
                    element: onSearch => (
                        <ModalToggle
                            modal={NewCommunicationModal}
                            modalProps={{ projectId: this.props.project.id, onCommunicationCreated: this.handleCommunicationCreated }}
                            wrapperProps={{ disabled: !isAuthorised(this.props.project.myAccess, ProjectOperations.CreateCommunication) }}
                        >
                            <FA icon="plus" className="mr-1" />New Communication
                        </ModalToggle>
                    ),
                    position: 'before'
                }]}
                noItemsFoundMessage={noItemsFoundMessage}
            >
                <ConfirmationModal
                    danger
                    header={'Delete Communication'}
                    message={<span>Are you sure you want to delete <strong>{communicationToDelete && (isNullOrEmpty(communicationToDelete.name) ? 'Untitled' : communicationToDelete.name)}</strong></span>}
                    confirmAction="Yes, delete"
                    isOpen={showConfirmDelete}
                    toggle={this.clearConfirmDelete}
                    onConfirm={this.deleteCommunication}
                    onClosed={this.clearCommunicationToDelete}
                />
            </SearchSection>
        )
    }
}
