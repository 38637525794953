import React from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  composeValidators,
  required,
  isNumber,
} from "@src/logic/forms/validation";
import ValidatedInput from "@src/components/common/ValidatedInput";
import { IModalProps } from "@src/hooks/useModal";
import NotificationService from "@src/logic/notification/NotificationService";
import { CreateNewCopany } from "@src/logic/http/Api";
import { Address } from "@src/types/principal";
import { useCompanyStore } from "@src/store/admin";
import shallow from "zustand/shallow";

interface INewCompanyValues {
  company: string;
  abn: string;
  address: Address;
  phone: string;
  website: string;
}

export default function AddCompanyForm(modalProps: IModalProps) {
  const [refreshCompanies] = useCompanyStore(
    (s) => [s.refreshCompanies],
    shallow
  );
  async function handleSubmit(values: INewCompanyValues): Promise<any> {
    try {
      // submit company details
      await CreateNewCopany({
        companyName: values.company,
        abn: values.abn,
        address: values.address,
        phone: values.phone,
        website: values.website,
      }).then(async () => {
        await refreshCompanies("");
      });
      NotificationService.success("Company created successfully.");
    } catch {
      NotificationService.error(
        "An error occured while creating a company. Please refresh and try again."
      );
    } finally {
      modalProps.toggle();
    }
  }

  return (
    <Modal {...modalProps}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          company: null,
          abn: null,
          address: {},
          phone: "",
          website: "",
        }}
      >
        {(formProps) => (
          <>
            <ModalHeader>Add New Company</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={8} className="mb-3">
                  <Label for="company">Company *</Label>
                  <Field
                    id="company"
                    name="company"
                    component={ValidatedInput}
                    validate={required}
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Label for="abn">Company ABN</Label>
                  <Field
                    id="abn"
                    name="abn"
                    component={ValidatedInput}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Label for="address">Company Address</Label>
                  <Field
                    id="address.description"
                    name="address.description"
                    component={ValidatedInput}
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Label for="email">Company Phone</Label>
                  <Field
                    id="phone"
                    name="phone"
                    component={ValidatedInput}
                    validate={composeValidators(isNumber)}
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Label for="website">Website</Label>
                  <Field
                    id="website"
                    name="website"
                    component={ValidatedInput}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                style={{ borderColor: "lightgray" }}
                onClick={modalProps.toggle}
                className="w-25"
              >
                Cancel
              </Button>
              <Button
                disabled={formProps.submitting}
                color="primary"
                className="w-25"
                onClick={formProps.handleSubmit}
              >
                Add
              </Button>
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
}
