import React from 'react'
import { AsyncState, useAsyncAbortable } from 'react-async-hook'
import { useResizeDetector } from 'react-resize-detector'
import { RouteComponentProps, useHistory, useLocation } from 'react-router'
import { Card, Col, Container } from 'reactstrap'

import cx from 'classnames'

import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import SearchBar from '@src/components/common/SearchBar'
import EmailInfiniteLoader from '@src/components/email/EmailInfiniteLoader'
import EmailPreview from '@src/components/email/EmailPreview'
import { ISearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import { useBreakpoints } from '@src/hooks/useBreakpoints'
import { EmailGetById, EmailsList } from '@src/logic/http/Api'
import { parseLinkHeader } from '@src/logic/utils/Pagination'
import { tryBuildURL } from '@src/logic/utils/URL'
import { EmailBrief } from '@src/types/email'
import { Project } from '@src/types/project'

interface IProps extends RouteComponentProps {
    project: Project
}

const searchAssistantProperties: ISearchProperty[] = [
    {
        name: 'Subject',
        searchKey: 'subject',
        type: PropertyType.Text
    },
    {
        name: 'Received',
        searchKey: 'received',
        type: PropertyType.Date
    },
    {
        name: 'From',
        searchKey: 'from',
        type: PropertyType.Text
    },
    {
        name: 'From Email',
        searchKey: 'from_email',
        type: PropertyType.Text
    },
    {
        name: 'To',
        searchKey: 'to',
        type: PropertyType.Text
    },
    {
        name: 'To Email',
        searchKey: 'to_email',
        type: PropertyType.Text
    },
    {
        name: 'Cc',
        searchKey: 'cc',
        type: PropertyType.Text
    },
    {
        name: 'Cc Email',
        searchKey: 'cc_email',
        type: PropertyType.Text
    },
    {
        name: 'Bcc',
        searchKey: 'bcc',
        type: PropertyType.Text
    },
    {
        name: 'Bcc Email',
        searchKey: 'bcc_email',
        type: PropertyType.Text
    },
    {
        name: 'Body',
        searchKey: 'body',
        type: PropertyType.Text
    }
]

interface IEmailsAsyncResult {
    items: EmailBrief[]
    nextCursor: string
}

async function loadEmailAsync(abortSignal: AbortSignal, id: string) {
    if (id == null) return null
    const response = await EmailGetById(id, { abortSignal })
    return response.data
}

const EmailsSection: React.FC<IProps> = ({ project }) => {
    const { ref, height } = useResizeDetector({ handleWidth: false })
    const breakPoints = useBreakpoints()
    const location = useLocation()
    const history = useHistory()
    const searchParams = new URLSearchParams(location.search)
    const [{ filter, triggerSearch }, setFilterAndTrigger] = React.useState({ filter: searchParams.get('filter') ?? '', triggerSearch: false })
    const selectedEmailId = searchParams.get('active')
    const selectedEmailAsync = useAsyncAbortable(loadEmailAsync, [selectedEmailId])
    const emailsAsync = useAsyncAbortable(loadEmails, [searchParams.get('filter'), null], { setLoading: state => ({ ...state, loading: true }), setResult: setLoadedEmails })

    React.useEffect(
        () => {
            if (triggerSearch) {
                doSearch()
                setFilterAndTrigger({ filter, triggerSearch: false })
            }
        },
        [triggerSearch]
    )

    async function loadEmails(abortSignal, filter: string, cursor: string): Promise<{ items: EmailBrief[], nextCursor: string }> {
        const response = await EmailsList(project.id, filter, cursor, 50, { abortSignal })
        const nextRel = tryBuildURL(parseLinkHeader(response.headers.link)?.next?.url ?? '')?.searchParams
        return ({
            items: response.data,
            nextCursor: nextRel?.has('cursor') ? nextRel.get('cursor') : null
        })
    }

    function setLoadedEmails(result: IEmailsAsyncResult, asyncState: AsyncState<IEmailsAsyncResult>): AsyncState<IEmailsAsyncResult> {
        if (asyncState.result == null || (emailsAsync.currentParams[1] == null && asyncState.result.nextCursor == null)) return { error: undefined, loading: false, result, status: 'success' }
        return {
            error: undefined,
            loading: false,
            result: { items: asyncState.result.items.concat(result.items), nextCursor: result.nextCursor },
            status: 'success'
        }
    }

    function loadNextBatch() {
        if (emailsAsync.loading) return Promise.resolve()
        return emailsAsync.execute(emailsAsync.currentParams[0], emailsAsync.result.nextCursor)
    }

    function handleCloseEmail() {
        const query = new URLSearchParams(location.search)
        query.delete('active')
        history.push({
            pathname: history.location.pathname,
            search: query.toString()
        })
    }

    function handleOpenEmail(email: EmailBrief) {
        const query = new URLSearchParams(location.search)
        query.set('active', email.id)
        history.push({
            pathname: history.location.pathname,
            search: query.toString()
        })
    }

    function doSearch() {
        emailsAsync.reset()
        const query = new URLSearchParams(location.search)
        query.set('filter', filter)
        const builtQuery = query.toString()
        if (builtQuery !== history.location.search.substring(1)) {
            history.push({
                pathname: history.location.pathname,
                search: builtQuery
            })
        }
    }

    function handleFilterChange(newFilter: string, triggerSearch?: boolean) {
        setFilterAndTrigger({ filter: newFilter, triggerSearch })
    }

    return (
        <Container fluid className="d-flex flex-column px-0 flex-fill overflow-hidden">
           <ActionBar className={cx({ 'd-none': selectedEmailId && breakPoints.currentBreakpoint === 'xs' })}>
                <ActionBar.Search>
                    <SearchBar
                        searchAssistant={searchAssistantProperties != null && searchAssistantProperties.length > 0}
                        availableProperties={searchAssistantProperties}
                        onChange={handleFilterChange}
                        value={filter}
                        onSubmit={doSearch}
                        placeholder={'Search...'}
                        submitting={emailsAsync.loading}
                    />
                </ActionBar.Search>
            </ActionBar>
            <div ref={ref} className="row no-gutters flex-fill overflow-hidden align-content-stretch">
                <Col md={3} className={cx('bg-white h-100', { 'col-0': selectedEmailId, 'col-xs-12': !selectedEmailId })}>
                    <EmailInfiniteLoader
                        filter={emailsAsync.currentParams?.[0]}
                        sort={''}
                        height={height}
                        emails={emailsAsync.result?.items ?? []}
                        loadMore={loadNextBatch}
                        loading={emailsAsync.loading}
                        moreToLoad={emailsAsync.result?.nextCursor != null}
                        onSelect={handleOpenEmail}
                        selectedEmailId={selectedEmailId}
                    />
                </Col>
                <Col className={cx('border-left d-flex h-100', { 'col-xs-0': !selectedEmailId, 'col-xs-12': selectedEmailId })} md={9}>
                    {selectedEmailAsync?.result
                        ? <EmailPreview email={selectedEmailAsync.result} onExit={handleCloseEmail} />
                        : selectedEmailAsync.loading
                        ? (
                            <Card body className="h-100 rounded-0 d-flex align-items-center justify-content-center text-muted">
                                <div className="lead text-center">Loading...<FA icon="spinner-third" className="ml-2" spin /></div>
                            </Card>
                        )
                        : (
                            <Card body className="h-100 rounded-0 d-flex align-items-center justify-content-center text-muted">
                                <div className="mb-3"><FA icon="inbox" size="4x" /></div>
                                <div className="lead text-center">Capture emails in this project by including the project email<br/><a href={`mailto:${project.email}`}>{project.email}</a></div>
                            </Card>
                        )
                    }
                </Col>
            </div>
        </Container>
    )
}

export default EmailsSection
