import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import 'public/styles/app.scss'

// Required for Froala icons
import { dom as fontawesomeDom } from '@fortawesome/fontawesome-svg-core'

import * as Sentry from '@sentry/browser'
import dayjs from 'dayjs'
import 'dayjs/locale/en-au'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import log from 'loglevel'

import { buildIconLibrary } from '@src/components/common/FontAwesomeIcon'
import AppFacade from '@src/containers/AppFacade'
import { buildFroalaIconLibrary } from '@src/logic/froala/fontawesome'
import { configureStore } from '@src/store'

Sentry.init({ dsn: process.env.SENTRY_DSN, release: process.env.RELEASE_TAG })

buildIconLibrary()
buildFroalaIconLibrary()
fontawesomeDom.watch()

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.locale('en-au')

log.setDefaultLevel(process.env.LOGGER_LEVEL as log.LogLevelDesc || 'error')

const store = configureStore()
const rootElement = document.getElementById('root')

ReactDOM.render(<Provider store={store}><AppFacade /></Provider>, rootElement)
