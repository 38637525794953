import React from 'react'

import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import { UserPropertyDefinition } from '@src/types/metadata'
import { IRegisterColumn } from '@src/types/register'

export default function UserPropertyField({ row, col }: IRegisterTableField<UserPropertyDefinition & IRegisterColumn>) {
    const value = row.cells[col.id] as string | null
    return (
        <span>{value}</span>
    )
}

export function UserPropertyFieldFooter() {
    return <span />
}
