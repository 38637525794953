import React from 'react'
import { Field, FieldRenderProps, useField } from 'react-final-form'

import { ActionMeta } from 'react-select'
import { FormatOptionLabelMeta } from 'react-select/base'
import { Badge } from 'reactstrap'

import { IOption, buildOptions } from '@src/components/common/Select'
import { finalFormMetadataValidator } from '@src/components/metadata/MetadataInputs'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import GenericSelectDropdown from '@src/components/register/tablefields/GenericSelectDropdown'
import useTouched from '@src/hooks/useTouched'
import { SelectDefinition } from '@src/types/metadata'

function getOptionLabel(option: IOption<string>) {
    return option.label
}

function getOptionValue(option: IOption<string>) {
    return option.value
}

function optionsToStrings(options: IOption<string>[]) {
    return options.map(x => x.value)
}

function formatSelectOption(option: IOption<string>, meta: FormatOptionLabelMeta<IOption<string>>) {
    if (meta.context === 'value') return <Badge color="tertiary" className="mr-1">{option.label}</Badge>

    return option.label
}

function InnerSelectDropdown({ input, meta, ...props }: IRegisterTableField<SelectDefinition> & FieldRenderProps<string[], HTMLElement, IOption<string>[]>) {
    const options = React.useMemo(() => {
        return buildOptions([...new Set([...input.value.map(x => x.value), ...props.col.data.metadataDefinition.options.values] ?? [])])
    }, [props.col.data.metadataDefinition.options.values, input.value])

    function handleChange(value: IOption<string>[], meta: ActionMeta<IOption<string>>) {
        if (meta.action === 'select-option' || meta.action === 'create-option') {
            if (value.length > 1 && !props.col.data.metadataDefinition.options.isMultiselect) input.onChange([meta.option])
            else input.onChange(value ?? [])
        } else {
            input.onChange(value)
        }
    }

    return (
        <GenericSelectDropdown
            {...props}
            value={input.value}
            onChange={handleChange}
            options={options}
            hideSelectedOptions={false}
            selectType={props.col.data.metadataDefinition.options.enforceValues ? undefined : 'creatable'}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            formatOptionLabel={formatSelectOption}
        />
    )
}

export default function SelectField(props: IRegisterTableField<SelectDefinition>) {
    const initialValue = props.row.cells[props.col.id] as string[] ?? []
    const isTouched = useTouched(props.isFocused, initialValue)

    const options = React.useMemo(() => {
        return [...new Set(buildOptions([...props.col.data.metadataDefinition.options.values] ?? []))]
    }, [props.col.data.metadataDefinition.options.values])

    return isTouched
        ? (
            <Field name={`cells.r-${props.row.id}.c-${props.col.id}`} initialValue={initialValue} format={buildOptions} parse={optionsToStrings} validateFields={[]}>
                {(field) => <InnerSelectDropdown {...props} {...field} />}
            </Field>
        )
        : (
            <GenericSelectDropdown
                {...props}
                value={buildOptions(props.row.cells[props.col.id] as any)}
                options={options}
                hideSelectedOptions={false}
                selectType={props.col.data.metadataDefinition.options.enforceValues ? undefined : 'creatable'}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                formatOptionLabel={formatSelectOption}
            />
        )
}

export function SelectFieldFooter(props: IRegisterTableField<SelectDefinition>) {
    const fieldData = React.useMemo(() => ({ definition: props.col.data }), [props.col.data])
    const { input } = useField<string[], HTMLElement, IOption<string>[]>(`cells.c-${props.col.id}`, { format: buildOptions, parse: optionsToStrings, validate: finalFormMetadataValidator, data: fieldData })
    const options = React.useMemo(() => {
        return [...new Set(buildOptions([...props.col.data.metadataDefinition.options.values] ?? []).concat(input.value))]
    }, [props.col.data.metadataDefinition.options.values, input.value])

    function handleChange(value: IOption<string>[], meta: ActionMeta<IOption<string>>) {
        if (meta.action === 'select-option' || meta.action === 'create-option') {
            if (value.length > 1 && !props.col.data.metadataDefinition.options.isMultiselect) input.onChange([meta.option])
            else input.onChange(value ?? [])
        } else {
            input.onChange(value)
        }
    }

    return (
        <GenericSelectDropdown
            {...props}
            isFocused={true}
            value={input.value}
            onChange={handleChange}
            onBlur={input.onBlur}
            options={options}
            selectType={props.col.data.metadataDefinition.options.enforceValues ? undefined : 'creatable'}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            formatOptionLabel={formatSelectOption}
        />
    )
}
