import React from 'react'
import { Field } from 'react-final-form'

import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import GenericSelectDropdown from '@src/components/register/tablefields/GenericSelectDropdown'
import { TransmittalLinksDefinition } from '@src/types/metadata'
import { TransmittalLink } from '@src/types/transmittal'

function getTransmittalLabel(transmittalLink: TransmittalLink) {
    return transmittalLink.subject
}

function getTransmittalValue(transmittalLink: TransmittalLink) {
    return transmittalLink.id
}

export default function TransmittalLinkField(props: IRegisterTableField<TransmittalLinksDefinition>) {
    const value = props.row.cells[props.col.id] as TransmittalLink[] | null
    return props.isFocused
        ? (
            <Field name={`cells.r-${props.row.id}.c-${props.col.id}`} initialValue={value} validateFields={[]}>
                {({ input }) => <GenericSelectDropdown
                    {...props}
                    onChange={input.onChange}
                    getOptionLabel={getTransmittalLabel}
                    getOptionValue={getTransmittalValue}
                    options={[]}
                />}
            </Field>
        )
        : null
}

export function TransmittalLinkFieldFooter(props: IRegisterTableField<TransmittalLinksDefinition>) {
    return (
        <Field name={`cells.c-${props.col.id}`}>
            {({ input }) =>
                <GenericSelectDropdown
                    {...props}
                    value={input.value}
                    onChange={input.onChange}
                    options={[]}
                    getOptionLabel={getTransmittalLabel}
                    getOptionValue={getTransmittalValue}
                />
            }
        </Field>
    )
}
