import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'

import { FieldValidator } from 'final-form'

import ValidatedSelect from '@src/components/common/ValidatedSelect'
import * as Operations from '@src/logic/auth/operations'
import { ProjectAccessGet } from '@src/logic/http/Api'
import { AclEntry } from '@src/types/access'
import { RootState } from '@src/types/models'

interface IProps {
    id?: string
    name: string
    projectId: string
    validate: FieldValidator<AclEntry>
}

export default function DocumentApproverField({ id, name, projectId, validate }: IProps) {
    const sessionUserId = useSelector<RootState, string>(s => s.session.user.id)
    const approversAsync = useAsyncAbortable(async (abortSignal, pid, userId) => {
        try {
            const response = await ProjectAccessGet(pid, true, { abortSignal })
            const approvers = response.data.acl.filter(x => x.type === 'user' && x.authorised.includes(Operations.ApproveDocument))
            const index = approvers.findIndex(x => x.id === userId)
            return index < 0 ? approvers : [approvers[index], ...approvers.slice(0, index), ...approvers.slice(index + 1)]
        } catch {
            return []
        }
    }, [projectId, sessionUserId])

    function getApproverLabel(approver: AclEntry) {
        return approver.id === sessionUserId ? 'Self Approve' : `${approver.name}`
    }

    function getApproverValue(approver: AclEntry) {
        return approver.id
    }

    function formatApproverLabel(approver: AclEntry) {
        return approver.id === sessionUserId ? 'Self Approve' : <span>{approver.name} <small className="text-muted">({approver.email})</small></span>
    }

    return (
        <Field
            id={id}
            name={name}
            component={ValidatedSelect}
            validate={validate}
            isLoading={approversAsync.loading}
            options={approversAsync.result ?? []}
            getOptionLabel={getApproverLabel}
            getOptionValue={getApproverValue}
            formatOptionLabel={formatApproverLabel}
        />
    )
}
