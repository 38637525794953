import React from 'react'
import ReactDatePicker from 'react-datepicker'
import ReactDOM from 'react-dom'
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

export interface IDatePickerProps {
    id?: string
    selected: Date
    onChange: (value: Date) => void
    onBlur?: (event) => void
    format?: string
    startDate?: Date
    endDate?: Date
    invalid?: boolean
    disabled?: boolean
    isClearable?: boolean
    placeholder?: string
    size?: 'sm' | 'lg'
}

const CalendarContainer = ({ children }) => children
    ? (
        ReactDOM.createPortal(
            React.cloneElement(children, {
                className: 'react-datepicker-popper'
            }),
            document.body)
    )
    : null

export default class DatePicker extends React.PureComponent<IDatePickerProps> {
    private readonly handleClear = () => {
        this.props.onChange('' as any)
    }

    private readonly getFormat = () => this.props.format ?? 'dd/MM/yyyy'

    public render() {
        const selectedDate = this.props.selected ? new Date(this.props.selected) : null
        const startDate = this.props.startDate ? new Date(this.props.startDate) : null
        const endDate = this.props.endDate ? new Date(this.props.endDate) : null

        return (
            <ReactDatePicker
                id={this.props.id}
                todayButton="Today"
                popperContainer={CalendarContainer}
                disabled={this.props.disabled}
                dateFormat={this.getFormat()}
                startDate={startDate}
                endDate={endDate}
                customInput={<DatePickerInput bsSize={this.props.size} invalid={this.props.invalid} onClear={this.handleClear} placeholder={this.props.placeholder} disabled={this.props.disabled} />}
                selected={selectedDate}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        )
    }
}

interface IDatePickerInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    bsSize?: 'sm' | 'lg'
    onClear: () => void
    invalid?: boolean
}

// tslint:disable-next-line:max-classes-per-file
class DatePickerInput extends React.PureComponent<IDatePickerInputProps> {
    public render() {
        const { bsSize, ref, type, onClear, invalid, ...inputProps } = this.props
        return (
            <InputGroup size={bsSize}>
                <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="calendar" /></InputGroupText></InputGroupAddon>
                <Input innerRef={ref as any} type="text" invalid={invalid} {...inputProps} />
                {onClear && <InputGroupAddon addonType="append" style={{ zIndex: 0 }}><Button color="primary" onClick={onClear} disabled={inputProps.disabled}><FA icon="times" /></Button></InputGroupAddon>}
            </InputGroup>
        )
    }
}
