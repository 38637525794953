import React from 'react'
import { FormFeedback } from 'reactstrap'

import cx from 'classnames'

import Select, { IProps as SelectProps } from '@src/components/common/Select'
import { FieldRenderProps } from '@src/types/react-final-form'

export type IProps<TValue, TIsMulti extends boolean> = SelectProps<TValue, TIsMulti> & FieldRenderProps<TIsMulti extends true ? TValue[] : TValue, HTMLElement>

export default class ValidatedSelect<TValue, TIsMulti extends boolean = boolean> extends React.PureComponent<IProps<TValue, TIsMulti>> {
    public render() {
        const { input, meta: { touched, error }, ...selectProps } = this.props

        const showError = touched && !!error

        return (
            <>
                <Select<TValue>
                    {...selectProps}
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    aria-invalid={showError}
                />
                <FormFeedback className={cx({ 'd-block': showError })}>{showError && error}</FormFeedback>
            </>
        )
    }
}
