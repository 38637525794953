import { useDispatch } from 'react-redux'

import { loadProjectCostsOverview } from '@src/actions/project'
import useAppState from '@src/hooks/useAppState'
import { CostsOverview } from '@src/types/costs'

export default function useProjectCosts(): [CostsOverview, () => void] {
    const dispatch = useDispatch()
    const costsOverview = useAppState(s => s.projects.activeCostsOverview)

    async function reload() {
        dispatch(loadProjectCostsOverview())
    }

    return [
        costsOverview,
        reload
    ]
}
