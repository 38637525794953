import { AnyAction, CombinedState, PreloadedState, Store, applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from '@src/reducers'
import { widgetPersistAndSync } from '@src/reducers/widget'
import { RootState } from '@src/types/models'

export let appStore: Store<RootState> = null

export function configureStore(initialState?: PreloadedState<CombinedState<RootState>>): Store<RootState, AnyAction> {
    const middlewares = [thunkMiddleware]

    // NOTE: Redux logger must be the last middleware in the pipeline (https://github.com/evgenyrodionov/redux-logger/issues/20)
    if (process.env.NODE_ENV === 'development') {
        const { logger } = require('redux-logger')
        middlewares.push(logger)
    }

    appStore = createStore(rootReducer, initialState, applyMiddleware(...middlewares))

    widgetPersistAndSync(appStore)

    if (module.hot) {
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers')
            appStore.replaceReducer(nextReducer)
        })
    }

    return appStore
}
