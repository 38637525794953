import React from 'react'
import { Field } from 'react-final-form'

import { finalFormMetadataValidator } from '@src/components/metadata/MetadataInputs'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import useTouched from '@src/hooks/useTouched'
import { TextDefinition } from '@src/types/metadata'

export default function TextField({ row, col, isFocused }: IRegisterTableField<TextDefinition>) {
    const value = row.cells[col.id] as string | null
    const isTouched = useTouched(isFocused, [value])
    return isTouched
    ? (
        <Field name={`cells.r-${row.id}.c-${col.id}`} initialValue={value} validateFields={[]}>
            {props => isFocused ? <input {...props.input} className="register__input-cell" /> : <span className="register__inactive-text-num-span">{props.input.value}</span>}
        </Field>
    )
    : <span className="register__inactive-text-num-span">{value}</span>
}

export function TextFieldFooter({ col }: IRegisterTableField<TextDefinition>) {
    const data = React.useMemo(() => ({ definition: col.data }), [col.data])
    return (
        <Field<string> name={`cells.c-${col.id}`} validate={finalFormMetadataValidator} data={data}>
            {props => <input {...props.input} className="register__input-cell" />}
        </Field>
    )
}
