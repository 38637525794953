import React from 'react'

import ContentTable from '@src/components/table/ContentTable'

interface IBaseTableHeader {
    name: string
    sortable?: boolean
    sortKey?: string
    noSmallHeader?: boolean
    headerWrapperClass?: string
    cellWrapperClass?: string
}

interface IBasePropertyHeader<T> extends IBaseTableHeader {
    accessor: keyof T
    defaultFallback?: React.ReactChild
}

interface ICustomPropertyHeader<T> extends IBaseTableHeader {
    overrideRenderer: (rowData: T, rowIndex: number) => React.ReactChild
}

export type ITableHeader<T> = IBasePropertyHeader<T> | ICustomPropertyHeader<T>

function isCustomPropertyHeader<T>(header: ITableHeader<T>): header is ICustomPropertyHeader<T> {
    return (header as ICustomPropertyHeader<T>).overrideRenderer != null
}

interface IProps<T, K extends keyof T> {
    headers: ITableHeader<T>[]
    data: T[]
    miniTitle?: string
    currentSort?: string
    onSort?: (key: string) => void
    onSelect?: (...rows: T[]) => void
    getRowClass?: (row: T) => string
    selectBy?: {
        key: K
        selected: T[K][]
    }
}

export default class GenericContentTable<T, K extends keyof T> extends React.PureComponent<IProps<T, K>> {
    public static defaultProps: Partial<IProps<any, any>> = {
        miniTitle: 'Results'
    }

    private readonly allSelected = () => {
        return this.props.selectBy && this.props.data?.length > 0 ? this.props.data.every(r => this.props.selectBy.selected.includes(r[this.props.selectBy.key])) : false
    }

    private readonly isSelected = (row: T) => {
        return this.props.selectBy ? this.props.selectBy.selected.includes(row[this.props.selectBy.key]) : false
    }

    private readonly handleSelectAll = () => {
        return this.props.onSelect(...this.props.data)
    }

    private readonly renderHeader = () => {
        return (
            <ContentTable.Header key="table-header" title={this.props.miniTitle} onSelectAll={this.props.onSelect ? this.handleSelectAll : undefined} allRowsSelected={this.allSelected()}>
                {this.props.headers.map((header, idx) => (
                    <ContentTable.HeaderCell key={idx} className={header.headerWrapperClass} onSort={header.sortable && this.props.onSort} sortField={header.sortable ? (header.sortKey) : null} currentSort={this.props.currentSort}>
                        {header.name}
                    </ContentTable.HeaderCell>
                ))}
            </ContentTable.Header>
        )
    }

    private readonly renderValue = (header: ITableHeader<T>, data: T, rowIndex: number) => {
        if (isCustomPropertyHeader(header)) {
            return header.overrideRenderer(data, rowIndex)
        }

        return data[header.accessor] != null ? data[header.accessor] : header.defaultFallback
    }

    private readonly renderRows = () => {
        const { data, headers, onSelect, getRowClass } = this.props
        return data.map((rowData, idx) => (
            <ContentTable.Row key={idx} onSelect={onSelect} isSelected={this.isSelected} rowData={rowData} className={getRowClass?.(rowData)}>
                {headers.map((header, cellIdx) => (
                    <ContentTable.Cell
                        key={cellIdx}
                        className={header.cellWrapperClass}
                    >
                        <strong className="d-lg-none">{header.noSmallHeader ? null : (header.name + ':')} </strong>
                        {this.renderValue(header, rowData, idx)}
                    </ContentTable.Cell>
                ))}
            </ContentTable.Row>
        ))
    }

    public render() {
        return (
            <ContentTable>
                {this.renderHeader()}
                {this.renderRows()}
            </ContentTable>
        )
    }
}
