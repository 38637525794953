import React from 'react'
import { Field } from 'react-final-form'

import { finalFormMetadataValidator } from '@src/components/metadata/MetadataInputs'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import useTouched from '@src/hooks/useTouched'
import { localShortDate, yyyyMMdd } from '@src/logic/utils/Date'
import { DateDefinition } from '@src/types/metadata'

export default function DateField({ row, col, isFocused }: IRegisterTableField<DateDefinition>) {
    const value = row.cells[col.id] as string
    const isTouched = useTouched(isFocused, [value])

    return isTouched
    ? (
        <Field name={`cells.r-${row.id}.c-${col.id}`} initialValue={yyyyMMdd(new Date(value))}>
            {(props) => isFocused ? <input {...props.input} type="date" className="register__input-cell" /> : <span className="register__inactive-date-span">{localShortDate(new Date(props.input.value))}</span>}
        </Field>
    )
    : <span className="register__inactive-date-span">{localShortDate(new Date(value))}</span>
}

export function DateFieldFooter({ col }: IRegisterTableField<DateDefinition>) {
    const data = React.useMemo(() => ({ definition: col.data }), [col.data])
    return (
        <Field<string> name={`cells.c-${col.id}`} validate={finalFormMetadataValidator} data={data}>
            {props => <input {...props.input} type="date" className="register__input-cell" />}
        </Field>
    )
}
