import React, { useEffect } from 'react'
import { useAsync } from 'react-async-hook'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'

import cx from 'classnames'

import infopointLogo from 'public/img/infopoint_white.svg'
import infopointLogoSmall from 'public/img/infopoint_white_small.svg'

import { toggleSandbox } from '@src/actions/sandbox'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import HeaderSearch from '@src/components/header/HeaderSearch'
import ProjectMenu from '@src/components/header/ProjectMenu'
import UserMenu from '@src/components/header/UserMenu'
import NotificationsCard from '@src/components/notifications/NotificationsCard'
import FeatureGate from '@src/containers/FeatureGate'
import { FeatureFlag, Features, GlobalSearchFlagValue } from '@src/logic/features/features'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
// import { HelpScoutServiceInstance } from '@src/logic/support/HelpScoutService'
import { RootState } from '@src/types/models'
import { Notification } from '@src/types/notification'
import { ProjectsState } from '@src/types/project'
import AdminHeader from './AdminHeader'

interface IProps {
    sidebarCollapsed: boolean
    toggleSidebar: (open?: boolean) => void
}

function AppHeader({ sidebarCollapsed, toggleSidebar }: IProps) {
    const projects = useSelector<RootState, ProjectsState>(s => s.projects)
    const features = useSelector<RootState, Features>(state => state.session.features)
    const dispatch = useDispatch()
    const notificationsAsync = useAsync(NotificationService.listNotifications, [])
    const isAdmin = window.location.pathname.includes('admin');

    useEffect(
        () => NotificationService.onReceiveNotification(handleReceiveNotification),
        []
    )

    function handleReceiveNotification(notification: Notification) {
        if (notificationsAsync.loading) {
            notificationsAsync.execute()
        } else {
            notificationsAsync.merge({
                result: {
                    unread: notificationsAsync.result.unread + 1,
                    notifications: [notification, ...notificationsAsync.result.notifications]
                }
            })
        }
    }

    function handleToggleSidebar() {
        toggleSidebar(!sidebarCollapsed)
    }

    function showHelpScout() {
        // HelpScoutServiceInstance.toggle()
        console.log('nothing to see here, move along...');
    }

    function openSandbox() {
        dispatch(toggleSandbox(true))
    }

    async function markAllNotificationsAsRead() {
        await NotificationService.markAllNotificationsAsRead()
        if (notificationsAsync.loading) {
            await notificationsAsync.execute()
        } else {
            notificationsAsync.merge({
                result: {
                    unread: 0,
                    notifications: notificationsAsync.result.notifications.map(n => ({ ...n, read: true }))
                }
            })
        }
    }

    async function markNotificationAsRead(notificationId: string) {
        await NotificationService.markNotificationAsRead(notificationId)
        if (notificationsAsync.loading) {
            await notificationsAsync.execute()
        } else {
            notificationsAsync.merge({
                result: {
                    unread: notificationsAsync.result.unread - 1,
                    notifications: notificationsAsync.result.notifications.map(n => n.id === notificationId ? ({ ...n, read: true }) : n)
                }
            })
        }
    }
    
    return isAdmin ? <AdminHeader /> : <header className="header">
            <ProjectMenu active={projects.active} projects={projects.favourites || []} />
            <Link to={Routes.DASHBOARD} className="header__logo">
                <img className="d-none d-md-inline-block" src={infopointLogo} alt="InfoPoint Logo" />
                <img className="d-md-none" src={infopointLogoSmall} alt="InfoPoint Logo" />
            </Link>
            <Button className="d-none d-md-inline-block" color="success" onClick={openSandbox}><FA icon="cloud-upload" className="mr-2" />Upload documents</Button>
            <FeatureGate features={features} flag={FeatureFlag.GlobalSearch} value={GlobalSearchFlagValue.Default}>
                {() => <HeaderSearch />}
            </FeatureGate>
            <UncontrolledButtonDropdown>
                <DropdownToggle className="header__notifications mr-0" color="link">
                    <FA icon="bell" />
                    {notificationsAsync.result?.unread > 0 && <Badge pill color="warning">{notificationsAsync.result.unread}</Badge>}
                </DropdownToggle>
                <DropdownMenu right className="shadow">
                    <NotificationsCard
                        totalUnread={notificationsAsync.result?.unread}
                        notifications={notificationsAsync.result?.notifications}
                        loading={notificationsAsync.loading}
                        markAllAsRead={markAllNotificationsAsRead}
                        markAsRead={markNotificationAsRead}
                    />
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <Button onClick={showHelpScout} className="header__notifications mr-0" color="link"><FA icon="question-circle" /></Button>
            <UserMenu />
            <a
                className={cx('header__trigger', { 'header__trigger--active': !sidebarCollapsed })}
                onClick={handleToggleSidebar}
                role="button"
            >
                <span className="header__burger" />
            </a> 
        </header>;
}

export default React.memo(AppHeader)
