/* eslint-disable import/order */
import React from 'react'
import FroalaEditor, { EditorProps } from 'react-froala-wysiwyg'

import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/js/plugins/entities.min.js'

import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/htmlmixed/htmlmixed.js'

import '@src/logic/froala/codemirror/liquid'
import '@src/logic/froala/codemirror/liquid.css'

export default class FroalaEditorInput extends React.PureComponent<EditorProps> {
    public static readonly defaults = {
        key: process.env.FROALA_LICENSE_KEY,
        iconsTemplate: 'font_awesome_5l',
        heightMin: 400,
        fileUpload: false,
        imageUpload: false,
        videoUpload: false,
        quickInsertButtons: ['table', 'ul', 'ol', 'hr'],
        codeMirror: CodeMirror,
        codeMirrorOptions: {
            indentWithTabs: true,
            lineNumbers: true,
            lineWrapping: true,
            mode: 'htmlmixedliquid',
            tabMode: 'indent',
            tabSize: 2
        },
        toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'print', 'spellChecker', 'help', 'html', '|', 'undo', 'redo'],
        toolbarButtonsMD: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'print', 'spellChecker', 'help', 'html', '|', 'undo', 'redo'],
        toolbarButtonsSM: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'print', 'spellChecker', 'help', 'html', '|', 'undo', 'redo']
    }

    public render() {
        const { config, ...otherProps } = this.props
        return (
            <FroalaEditor
                tag="textarea"
                config={{
                    ...FroalaEditorInput.defaults,
                    ...config
                }}
                {...otherProps}
            />
        )
    }
}
