import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, useField, useForm } from 'react-final-form'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { OnChange } from 'react-final-form-listeners'
import { Card, CardTitle, Col, Container, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import LoadingCard from '@src/components/common/LoadingCard'
import { buildOptions } from '@src/components/common/Select'
import ToggleButton from '@src/components/common/ToggleButton'
import { FieldPrefix } from '@src/components/forms/FieldPrefix'
import { IWizardPageProps } from '@src/components/forms/WizardPage'
import { IDocumentSandboxDocument, IDocumentSandboxWizardForm } from '@src/components/sandbox/DocumentSandbox'
import { DocumentUpload } from '@src/components/sandbox/DocumentUpload'
import useBoolean from '@src/hooks/useBoolean'
import { DocumentsList } from '@src/logic/http/Api'
import { contentTypeIcon } from '@src/logic/utils/FileFormats'
import { Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'

const MetadataStage: React.FC<IWizardPageProps<IDocumentSandboxWizardForm>> = () => {
    const showOptionalMetadata = useBoolean(true)
    const form = useForm<IDocumentSandboxWizardForm>()
    const metadataDefinitionsField = useField<IMetadataDefinition[]>('metadataDefinitions')
    const metadataDefinitions = metadataDefinitionsField.input.value && !showOptionalMetadata.value ? metadataDefinitionsField.input.value.filter(x => x.isRequired) : metadataDefinitionsField.input.value

    useAsyncAbortable(async (abortSignal) => {
        const response = await DocumentsList(form.getState().values.project.id, undefined, undefined, undefined, 1, 0, { abortSignal })
        form.change('metadataDefinitions', response.data.metadataDefinitions)
    }, [])

    function getInitialDocumentName(existingRevision: Revision | null, uploadName: string) {
        if (existingRevision) return existingRevision.name
        const fileNameParts = uploadName.split('.')
        return fileNameParts.length > 1 ? fileNameParts.slice(0, -1).join('.') : fileNameParts[0]
    }

    function buildGlobalChange(field: string): (value, oldValue) => void {
        const pattern = new RegExp(`documents\\[\\d+\\].uploads\\[\\d+\\].data.${field}`)
        return (value) => {
            form.batch(() => {
                form.getRegisteredFields().filter(x => pattern.test(x)).forEach(toChange => {
                    form.change(toChange as any, value)
                })
            })
        }
    }

    function renderUploads(arrayProps: FieldArrayRenderProps<IDocumentSandboxDocument, any>) {
        const uploads = arrayProps.fields.map((documentField, idx) => (
            <Field<Revision | null> name={`${documentField}.existingRevision`} key={documentField}>
                {({ input: { value: existingRevision } }) => {
                    return <FieldArray name={`${documentField}.uploads`} subscription={{}} key={documentField}>
                        {({ fields }) =>
                            fields.length > 0
                                ? <Card body className="mb-3">
                                    <h4 className="border-bottom mb-3 pb-2">{existingRevision?.name ?? 'New Document'}</h4>
                                    {fields.map((uploadField, uidx: number) => (
                                        <FieldPrefix prefix={`${uploadField}.data`} key={uploadField}>
                                            <DocumentUpload
                                                title={
                                                    <span>
                                                        <Field name={`${uploadField}.contentType`} subscription={{ value: true }}>
                                                            {({ input: { value: contentType } }) => <FA className="mr-1" icon={contentTypeIcon(contentType)} />}
                                                        </Field>
                                                        <Field name={`${uploadField}.filename`} subscription={{ value: true }}>
                                                            {({ input: { value: filename } }) => existingRevision?.name ?? filename}
                                                        </Field>
                                                        <em className="text-muted">&nbsp;(Rev. {uidx + 1})</em>
                                                    </span>
                                                }
                                                metadataDefinitions={metadataDefinitions}
                                                initialValues={existingRevision
                                                    ? {
                                                        author: existingRevision.author,
                                                        description: existingRevision.description,
                                                        metadata: existingRevision.metadata,
                                                        name: existingRevision.name,
                                                        revDate: existingRevision.revDate,
                                                        revNumber: existingRevision.revNumber,
                                                        tags: React.useMemo(() => buildOptions(existingRevision.tags), [existingRevision.tags])
                                                    }
                                                    : {
                                                        name: getInitialDocumentName(existingRevision, form.getState().values.documents[idx].uploads[uidx].filename)
                                                    }}
                                            />
                                        </FieldPrefix>
                                    ))}
                                </Card>
                                : undefined}
                    </FieldArray>
                }}
            </Field>
        ))

        const globalCard = (
            <FieldPrefix prefix="global">
                <Card body className="mb-3">
                    <div className="border-bottom mb-3">
                        <CardTitle>Edit Global Metadata</CardTitle>
                    </div>
                    <DocumentUpload noValidate metadataDefinitions={metadataDefinitions} />
                </Card>
                <OnChange name="global.name">
                    {buildGlobalChange('name')}
                </OnChange>
                <OnChange name="global.version">
                    {buildGlobalChange('version')}
                </OnChange>
                <OnChange name="global.date">
                    {buildGlobalChange('date')}
                </OnChange>
                <OnChange name="global.author">
                    {buildGlobalChange('author')}
                </OnChange>
                <OnChange name="global.tags">
                    {buildGlobalChange('tags')}
                </OnChange>
                <OnChange name="global.description">
                    {buildGlobalChange('description')}
                </OnChange>
                {metadataDefinitions.map(md => (
                    <OnChange key={md.key} name={`global.metadata.${md.key}`}>
                        {buildGlobalChange(`metadata.${md.key}`)}
                    </OnChange>
                ))}
            </FieldPrefix>
        )

        return form.getState().values.documents.flatMap(x => x.uploads).filter(x => x != null).length > 1 ? [globalCard, ...uploads] : uploads
    }

    return (
        <Container fluid className="mh-100 d-flex flex-column">
            <h4 className="mb-3">Set the metadata for your files</h4>
            <p>Use the forms below to set the metadata for your uploaded files. Have fields that are the same? Set these in the global row and the data will be copied across all of your documents.</p>
            <ToggleButton on={showOptionalMetadata.value} onToggle={showOptionalMetadata.toggle} label="Show optional metadata fields" />
            <Row className="mb-3 min-h-0 flex-nowrap">
                <Col className="overflow-auto">
                    {metadataDefinitions
                        ? <FieldArray<IDocumentSandboxDocument> name="documents" render={renderUploads} subscription={{}} />
                        : <LoadingCard />}
                </Col>
            </Row>
        </Container>
    )
}

export default MetadataStage
