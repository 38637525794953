import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import DocumentEditor from '@src/components/communication/DocumentEditor'
import { BuildCommunicationContext } from '@src/logic/autotexts/DropkiqContext'
import { TemplatePatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Template } from '@src/types/communication'

interface IProps {
    template: Template
    onUpdated?: () => void
}

export interface ITemplateLayoutEditFormData {
    layout: string
}

export default function TemplateLayoutEditForm({ template, onUpdated }: IProps) {
    async function handleUpdateLayout(values: ITemplateLayoutEditFormData) {
        try {
            await TemplatePatch(template.id, [
                { op: 'replace', path: '/layout', value: values.layout }
            ])
            NotificationService.info('Updated template layout')
        } catch {
            NotificationService.error('Unable to update template layout')
        }

        onUpdated?.()
    }

    return (
        <Form onSubmit={handleUpdateLayout} initialValues={{ layout: template.layout }}>
            {({ handleSubmit }) =>
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <span><FA icon="file-code" /> Layout</span>
                        <Button color="primary" className="ml-auto" onClick={handleSubmit}>Save</Button>
                    </CardHeader>
                    <CardBody>
                        <Field<string> name="layout">
                            {({ input }) => <DocumentEditor
                                value={input.value}
                                preview=""
                                context={BuildCommunicationContext(template.metadataDefinitions)}
                                isEditing={true}
                                onChange={input.onChange}
                                onRefreshPreview={() => null}
                                onToggleEditing={() => null}
                                renderOverlayMessage={() => null}
                            />}
                        </Field>
                    </CardBody>
                </Card>
            }
        </Form>
    )
}
