import type { TableColumnProperties } from 'exceljs'

import { RegisterGetById } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { projectDocument } from '@src/logic/routing/routes'
import { localDateTime, localShortDate } from '@src/logic/utils/Date'
import { saveBuffer } from '@src/logic/utils/File'
import { DocumentColumnDefinition, DocumentLink, Revision } from '@src/types/document'
import { EmailLink } from '@src/types/email'
import { AutoNumberDefinition, BoolDefinition, CompanyEntityProps, CompanyLinksDefinition, CompanyPropertyDefinition, DateDefinition, DocumentEntityProps, DocumentLinksDefinition, DocumentPropertyDefinition, EmailEntityProps, EmailLinksDefinition, EmailPropertyDefinition, MetadataTypes, NumericDefinition, SelectDefinition, TextDefinition, TransmittalEntityProps, TransmittalLinksDefinition, TransmittalPropertyDefinition, UserEntityProps, UserLinksDefinition, UserPropertyDefinition } from '@src/types/metadata'
import { PrincipalBrief } from '@src/types/principal'
import { RegisterColumnDefinition, RegisterOverview, RegisterRow } from '@src/types/register'
import { TransmittalLink } from '@src/types/transmittal'

const revisionPropertyColumns: TableColumnProperties[] = [
    { name: 'Name' },
    { name: 'Description' },
    { name: 'Author' },
    { name: 'Revision #' },
    { name: 'Revision Date' },
    { name: 'File Name' },
    { name: 'Uploaded Date' },
    { name: 'Tags' }
]

function loadExcelJs() {
    return import('exceljs')
}

export async function exportDocuments(revisions: Revision[], metadataDefinitions: DocumentColumnDefinition[], fileName: string) {
    // make excel workbook and a worksheet named 'export'
    const { Workbook } = await loadExcelJs()
    const workbook = new Workbook()
    const table = workbook.addWorksheet('Export').addTable({
        ref: 'A1',
        name: 'export',
        // set column headers to revision properties and metadata
        columns: [
            ...revisionPropertyColumns,
            ...metadataDefinitions.map<TableColumnProperties>(d => ({
                name: d.name
            }))
        ],
        rows: []
    })
    // iterate through every selected revision
    for (const currentRevision of revisions) {
        // get metadata values and format it
        const metadataValues = metadataDefinitions.map(x => renderCell(currentRevision.metadata[x.key], x, undefined))

        // add all values into a row and format revision properties
        table.addRow([{ text: currentRevision.name, hyperlink: `${location.origin}${projectDocument(currentRevision.projectId, currentRevision.documentId, currentRevision.id)}` },
        currentRevision.description, currentRevision.author, currentRevision.revNumber, localShortDate(currentRevision.revDate), currentRevision.fileName, localShortDate(currentRevision.uploadDate), currentRevision.tags.join(', '),
            ...metadataValues])
    }
    table.commit()
    const buffer = await workbook.xlsx.writeBuffer()
    saveBuffer(buffer as any, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
}

export async function exportRegister(register: RegisterOverview) {
    const rows = await fetchRegisterRows(register.id)
    const { Workbook } = await loadExcelJs()
    const workbook = new Workbook()
    const table = workbook.addWorksheet('Export').addTable({
        ref: 'A1',
        name: 'export',
        columns: register.columnDefinitions.map<TableColumnProperties>(d => ({
            name: d.name
        })),
        rows: []
    })

    for (const row of await formatRegisterData(register, rows)) {
        table.addRow(row)
    }

    table.commit()

    const buffer = await workbook.xlsx.writeBuffer()
    saveBuffer(buffer as any, `${register.name}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
}

export async function fetchRegisterRows(registerId: string) {
    const rows: RegisterRow[] = []
    let offsetPage = 1
    const initialRegister = (await RegisterGetById(registerId, undefined, undefined, offsetPage, 200)).data
    rows.push(...initialRegister.data.rows)
    while (initialRegister.data.totalCount > offsetPage * 200) {
        offsetPage++
        rows.push(...(await RegisterGetById(registerId, undefined, undefined, offsetPage, 200)).data.data.rows)
    }

    return rows
}

export async function formatRegisterData(register: RegisterOverview, rows: RegisterRow[]) {
    return rows.map(row =>
        register.columnDefinitions.map(def =>
            renderCell(row.cells?.[def.key], def, register.projectId)
        )
    )
}

export function renderCell(value: any, definition: RegisterColumnDefinition | DocumentColumnDefinition, projectId: string) {
    switch (definition.type) {
        case MetadataTypes.AutoNumber:
            return renderAutoNumber(value, definition)
        case MetadataTypes.Bool:
            return renderBoolean(value, definition)
        case MetadataTypes.Text:
            return renderText(value, definition)
        case MetadataTypes.Numeric:
            return renderNumeric(value, definition)
        case MetadataTypes.Date:
            return renderDate(value, definition)
        case MetadataTypes.Select:
            return renderSelect(value, definition)
        case MetadataTypes.DocumentLinks:
            return renderDocumentLinks(value, definition, projectId)
        case MetadataTypes.DocumentProperty:
            return renderDocumentProperty(value, definition)
        case MetadataTypes.TransmittalLinks:
            return renderTransmittalLinks(value, definition, projectId)
        case MetadataTypes.TransmittalProperty:
            return renderTransmittalProperty(value, definition)
        case MetadataTypes.CompanyLinks:
            return renderCompanyLinks(value, definition)
        case MetadataTypes.CompanyProperty:
            return renderCompanyProperty(value, definition)
        case MetadataTypes.UserLinks:
            return renderUserLinks(value, definition)
        case MetadataTypes.UserProperty:
            return renderUserProperty(value, definition)
        case MetadataTypes.EmailLinks:
            return renderEmailLinks(value, definition, projectId)
        case MetadataTypes.EmailProperty:
            return renderEmailProperty(value, definition)
    }
}

export function renderAutoNumber(value: number, definition: AutoNumberDefinition) {
    return value
}

export function renderBoolean(value: boolean, defintion: BoolDefinition) {
    return value?.toString()
}

export function renderText(value: string, defintion: TextDefinition) {
    return value
}

export function renderNumeric(value: number, defintion: NumericDefinition) {
    return value
}

export function renderDate(value: Date, defintion: DateDefinition) {
    return localShortDate(value)
}

export function renderSelect(value: string | string[], definition: SelectDefinition) {
    if (value == null) { return null }

    if (definition.options.isMultiselect && value instanceof Array) {
        return value && value.length > 0 ? value.join(', ') : ''
    }

    return value instanceof Array ? value[0] : value
}

export function renderDocumentLinks(value: DocumentLink[], definition: DocumentLinksDefinition, projectId: string) {
    if (value == null || value.length === 0) { return null }
    return value.map(x => `${location.origin}${Routes.projectDocument(projectId, x.documentId, x.revisionId)}`).join(', ')
}

export function renderDocumentProperty(value, definition: DocumentPropertyDefinition) {
    switch (definition.options.property) {
        case DocumentEntityProps.Author:
        case DocumentEntityProps.Description:
        case DocumentEntityProps.Name:
        case DocumentEntityProps.RevisionNo:
            return value
        case DocumentEntityProps.CreatedDate:
        case DocumentEntityProps.RevisionDate:
            return localShortDate(value)
    }
}

export function renderTransmittalLinks(value: TransmittalLink[], definition: TransmittalLinksDefinition, projectId: string) {
    if (value == null || value.length === 0) { return null }
    return value.map(x => `${location.origin}${Routes.projectTransmittal(projectId, x.id)}`).join(', ')
}

export function renderTransmittalProperty(value, definition: TransmittalPropertyDefinition) {
    switch (definition.options.property) {
        case TransmittalEntityProps.CreatedDate:
        case TransmittalEntityProps.ResponseDate:
            return value == null ? null : localShortDate(value)
        case TransmittalEntityProps.ReferenceNo:
        case TransmittalEntityProps.ResponseType:
        case TransmittalEntityProps.Status:
        case TransmittalEntityProps.Subject:
            return value
    }
}

export function renderCompanyLinks(value: PrincipalBrief[], definition: CompanyLinksDefinition) {
    if (value == null || value.length === 0) { return null }
    return value.map(x => `${x.name}`).join(', ')
}

export function renderCompanyProperty(value, definition: CompanyPropertyDefinition) {
    switch (definition.options.property) {
        case CompanyEntityProps.Name:
        case CompanyEntityProps.AbbreviatedName:
            return value
        case CompanyEntityProps.Disciplines:
            return value == null ? null : (value as string[]).join(', ')
    }
}

export function renderUserLinks(value: PrincipalBrief[], definition: UserLinksDefinition) {
    if (value == null || value.length === 0) { return null }
    return value.map(x => `${x.name}`).join(', ')
}

export function renderUserProperty(value, definition: UserPropertyDefinition) {
    switch (definition.options.property) {
        case UserEntityProps.Email:
        case UserEntityProps.Mobile:
        case UserEntityProps.Name:
        case UserEntityProps.Role:
            return value
    }
}

export function renderEmailLinks(value: EmailLink[], definition: EmailLinksDefinition, projectId: string) {
    if (value == null || value.length === 0) { return null }
    return value.map(x => `${location.origin}${Routes.projectEmail(projectId, x.id)}`).join(', ')
}

export function renderEmailProperty(value, definition: EmailPropertyDefinition) {
    switch (definition.options.property) {
        case EmailEntityProps.Bcc:
        case EmailEntityProps.Cc:
        case EmailEntityProps.From:
        case EmailEntityProps.Subject:
        case EmailEntityProps.To:
            return value
        case EmailEntityProps.Date:
            return localDateTime(value)
    }
}
