import React from 'react'
import { Field } from 'react-final-form'

import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import GenericSelectDropdown from '@src/components/register/tablefields/GenericSelectDropdown'
import useProjectWidget from '@src/hooks/useWidget'
import { UserLinksDefinition } from '@src/types/metadata'
import { UserLink } from '@src/types/principal'

function getUserLabel(userLink: UserLink) {
    return userLink.name
}

function getUserValue(userLink: UserLink) {
    return userLink.id
}

export default function UserField(props: IRegisterTableField<UserLinksDefinition>) {
    const widget = useProjectWidget()
    return <GenericSelectDropdown {...props} getOptionLabel={getUserLabel} getOptionValue={getUserValue} options={[]} />
}

export function UserLinkFieldFooter(props: IRegisterTableField<UserLinksDefinition>) {
    const widget = useProjectWidget()

    return (
        <Field<UserLink[]> name={`cells.c-${props.col.id}`} validateFields={[]}>
            {({ input }) =>
                <GenericSelectDropdown
                    {...props}
                    onChange={input.onChange}
                    options={widget.users.map<UserLink>(u => ({ name: `${u.lastName}, ${u.firstName}`, id: u.id }))}
                    getOptionLabel={getUserLabel}
                    getOptionValue={getUserValue}
                />
            }
        </Field>
    )
}
