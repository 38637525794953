import React from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { Form as BootstrapForm, Button, Col, Container, FormGroup, Label, Row } from 'reactstrap'

import ButtonLink from '@src/components/common/ButtonLink'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { required } from '@src/logic/forms/validation'
import { ProjectCreate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'

interface INewProjectFormData {
    name: string
    code: string
    description: string
}

export default function NewProjectPage() {
    const history = useHistory()

    async function createProjectAndRedirect(values: INewProjectFormData) {
        try {
            // Create the project
            const response = await ProjectCreate({
                name: values.name,
                code: values.code,
                description: values.description
            })

            // Go to new project page
            history.push(Routes.projectDocuments(response.data.id))
        } catch {
            NotificationService.error('There was an error creating the project')
        }
    }

    return (
        <>
            <Container>
                <Row className="mt-5 mb-3 justify-content-center">
                    <Col lg={8} xs={12} className="justify-content-center">
                        <Form<INewProjectFormData> onSubmit={createProjectAndRedirect}>
                            {({ handleSubmit }) =>
                                <BootstrapForm>
                                    <h1 className="mb-4">Create a new project</h1>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Field id="name" name="name" bsSize="lg" component={ValidatedInput} validate={required} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="code">Code (optional)</Label>
                                        <Field id="code" name="code" component={ValidatedInput} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Description (optional)</Label>
                                        <Field name="description" component={ValidatedInput} type="textarea" />
                                    </FormGroup>
                                    <div className="d-flex">
                                        <Button onClick={handleSubmit}>Create Project</Button>
                                        <ButtonLink className="ml-auto" color="link" to={Routes.COMPANY}>or go back</ButtonLink>
                                    </div>
                                </BootstrapForm>
                            }
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
