import React from 'react'
import { Form } from 'react-final-form'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import arrayMutators from 'final-form-arrays'

import BudgetItemFormBody, { IBudgetItemFormData } from '@src/components/costs/budget/BudgetItemFormBody'
import { IModalProps } from '@src/hooks/useModal'
import { BudgetItemUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { groupBy } from '@src/logic/utils/Collection'
import { Api } from '@src/types/api'
import { BudgetAdjustment, BudgetItem, BudgetReportColumn, CostsOverview, StatusColumnValueMap } from '@src/types/costs'

interface IProps extends IModalProps {
    projectId: string
    costsOverview: CostsOverview
    budgetItemToEdit?: BudgetItem | BudgetAdjustment
    onItemUpdated: () => void
}

export default function BudgetItemEditModal({ projectId, costsOverview, budgetItemToEdit, onItemUpdated, ...modalProps }: IProps) {
    const initialValues = React.useMemo<Partial<IBudgetItemFormData>>(() => {
        if (budgetItemToEdit == null) return null
        const groupedStatusValues = groupBy(budgetItemToEdit.values, 'status')
        return {
            name: budgetItemToEdit.name,
            date: budgetItemToEdit.date,
            costCode: budgetItemToEdit.costCode,
            phase: costsOverview.phases.find(x => x.code === budgetItemToEdit.phase),
            status: (budgetItemToEdit.isAdjustment ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions).find(x => x.code === budgetItemToEdit.status),
            description: budgetItemToEdit.description,
            statusColumnValues: Object.keys(groupedStatusValues).map(status => ({
                status,
                adjustment: groupedStatusValues[status].find(x => x.column === BudgetReportColumn.Adjustment)?.value.value ?? 0,
                original: groupedStatusValues[status].find(x => x.column === BudgetReportColumn.Original)?.value.value ?? 0,
                unreported: groupedStatusValues[status].find(x => x.column === BudgetReportColumn.Unreported)?.value.value ?? 0
            })),
            ...(budgetItemToEdit.isAdjustment
                ? {
                    clientApprovalDocuments: budgetItemToEdit.clientApprovalDocuments,
                    clientApprovalNumber: budgetItemToEdit.clientApprovalNumber
                }
                : {}
                )
            }
        }, [budgetItemToEdit, costsOverview])
    const isAdjustment = budgetItemToEdit != null ? budgetItemToEdit.isAdjustment : costsOverview.budget.locked

    async function updateBudgetItem(values: IBudgetItemFormData) {
        const statuses = isAdjustment ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions
        const update: Api.Request.BudgetItemUpdate | Api.Request.BudgetAdjustmentUpdate = {
            costCode: values.costCode.code,
            name: values.name,
            description: values.description,
            status: values.status.code,
            date: values.date,
            phase: values.phase?.code,
            values: statuses.map(sd => sd.columns.map<StatusColumnValueMap<BudgetReportColumn>>(col => ({
                column: col,
                status: sd.code,
                value: {
                    quantity: 0,
                    unit: '',
                    rate: 0,
                    notes: '',
                    value: values.statusColumnValues.find(x => x.status === sd.code)[col]
                }
            }))).reduce((a, b) => a.concat(b), []),
            tags: [],
            ...(budgetItemToEdit.isAdjustment
                ? {
                    clientApprovalNumber: values.clientApprovalNumber,
                    clientApprovalDocuments: values.clientApprovalDocuments
                }
                : {})
        }

        await BudgetItemUpdate(projectId, budgetItemToEdit.id, update)
        NotificationService.info('Updated item')
        onItemUpdated()
        modalProps.toggle()
    }

    return (
        <Modal size="lg" {...modalProps}>
            <Form onSubmit={updateBudgetItem} mutators={{ ...arrayMutators }} initialValues={initialValues}>
                {({ handleSubmit }) =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>{`Edit - ${budgetItemToEdit.name}`}</ModalHeader>
                        <ModalBody>
                            <BudgetItemFormBody
                                costsOverview={costsOverview}
                                isAdjustment={isAdjustment}
                                projectId={projectId}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit}>Save</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
