import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Tooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import Banner from "@src/components/banner/Banner";
import BannerPanel from "@src/components/banner/BannerPanel";
import FA from "@src/components/common/FontAwesomeIcon";
import AddCompanyForm from "./AddCompanyForm";
import UpdateCompanyForm from "./UpdateCompanyForm";
import useModal from "@src/hooks/useModal";
import { CompanyPublic } from "@src/types/principal";
import { debounce } from "lodash";
import { useCompanyStore, useCompanyUsersStore } from "@src/store/admin";
import shallow from "zustand/shallow";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";

const CompaniesPage: React.FC = () => {
  const gridRef: any = useRef();
  const newCompanyModal = useModal(false);
  const updateCompanyModal = useModal(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyPublic>();
  const [loadCompanyUsers, resetCompanyUsers] = useCompanyUsersStore(
    (s) => [s.load, s.reset],
    shallow
  );

  const [companies, loadCompanies, refreshCompanies, loadDefaultCompany] =
    useCompanyStore(
      (s) => [
        s.companies,
        s.loadCompanies,
        s.refreshCompanies,
        s.loadDefaultCompany,
      ],
      shallow
    );

  const triggerSearch = debounce((query: string) => {
    refreshCompanies(`name: ${query ?? ""}`);
  }, 999);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const searchCompanies = (e: React.ChangeEvent<HTMLInputElement>) => {
    triggerSearch(e.currentTarget.value);
  };

  const doubleClick = (event) => {
    const company = event.data;
    setSelectedCompany(company);
    resetCompanyUsers();
    company && loadCompanyUsers(company.id);
    updateCompanyModal.actions.show();
  };

  useEffect(() => {
    loadCompanies("");
    loadDefaultCompany();
  }, []);

  const DropdownCellRenderer = ({ value, data }) => {
    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle className="mr-0 pt-0" color="link">
          <FA icon="ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu right className="shadow">
          <DropdownItem
            onClick={() => {
              const company = data;
              setSelectedCompany(company);
              resetCompanyUsers();
              company && loadCompanyUsers(company.id);
              updateCompanyModal.actions.show();
            }}
          >
            Edit
          </DropdownItem>
          {/* <DropdownItem>Delete</DropdownItem> */}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const objectIdToTimestamp = (objectId: string) => {
    objectId = objectId.substring(objectId.indexOf("|") + 1);
    const timestampHex = objectId.substring(0, 8);
    const timestamp = parseInt(timestampHex, 16);

    return dayjs(new Date(timestamp * 1000)).format("DD/MM/YYYY - hh:mm a");
  };

  const [colDefs, setColDefs] = useState([
    {
      cellRendererFramework: ({ value, data }) => (
        <>{objectIdToTimestamp(data.id)}</>
      ),
      headerName: "Date Created",
      field: "id",
      filter: false,
      flex: 1,
      sortable: true,
      sort: "desc" as any,
    },
    { field: "name", filter: true, flex: 1, sortable: true },
    { field: "phone", filter: true, flex: 1, sortable: true },
    { field: "abn", filter: true, flex: 1, sortable: true },
    { field: "website", filter: true, flex: 1, sortable: true },
    {
      headerName: "Action",
      field: "id",
      filter: false,
      flex: 0,
      cellRendererFramework: DropdownCellRenderer,
      cellStyle: {
        overflow: "visible",
      },
      width: 100,
    },
  ]);

  const clearFilters = useCallback(() => {
    //@ts-ignore
    gridRef.current.api.setFilterModel(null);
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Banner title="Companies" noBreadcrumbs>
            <BannerPanel>
              <Button
                className="d-none d-md-inline-block"
                outline
                color="primary"
                onClick={newCompanyModal.actions.show}
              >
                Add New Company
              </Button>
            </BannerPanel>
            <BannerPanel column>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FA icon="search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="search"
                  className="no-border-left"
                  placeholder="Search"
                  onChange={searchCompanies}
                />
              </InputGroup>
            </BannerPanel>
            <BannerPanel>
              <Button
                id="clear-filter-btn"
                color="primary"
                outline
                style={{ marginLeft: 5, borderColor: "lightgray" }}
                onClick={clearFilters}
              >
                <FA icon="filter" />
              </Button>
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target={"clear-filter-btn"}
                toggle={toggle}
              >
                Clear Filters
              </Tooltip>
            </BannerPanel>
          </Banner>
        </Col>
      </Row>
      <AddCompanyForm {...newCompanyModal.modalProps} />
      <UpdateCompanyForm
        modalProps={updateCompanyModal.modalProps}
        company={selectedCompany}
      />
      <Row>
        <Col xs="12">
          <div className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              suppressRowTransform={true}
              suppressCellFocus={true}
              rowData={companies}
              columnDefs={colDefs}
              pagination={true}
              paginationPageSize={15}
              onRowDoubleClicked={doubleClick}
              domLayout="autoHeight"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CompaniesPage;
