import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'

import EmptyResourceArea from '@src/components/common/EmptyResourceArea'
import FA from '@src/components/common/FontAwesomeIcon'
import LoadingMessage from '@src/components/common/LoadingMessage'
import MetadataDefinitionList from '@src/components/metadata/definitions/MetadataDefinitionList'
import NewDefinitionModal, { INewDefinitionModalFormData } from '@src/components/metadata/definitions/NewDefinitionModal'
import useModal from '@src/hooks/useModal'
import { ProjectMetadataAdd, ProjectMetadataRetire, ProjectMetadataUpdate, RevisionsList } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { BaseMetadataTypes, IUpdateMetadataDefinition, MetadataTypes } from '@src/types/metadata'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    company: Company
}

const validMetadataTypes: readonly MetadataTypes[] = [MetadataTypes.Bool, MetadataTypes.Numeric, MetadataTypes.Text, MetadataTypes.Date, MetadataTypes.Select]

export default function MetadataSettingsSection({ project, company }: IProps) {
    const modal = useModal(false)
    const definitionsAsync = useAsyncAbortable(
        async (abortSignal, projectId) => {
            const revisionsResponse = await RevisionsList(projectId, undefined, undefined, undefined, 0, { abortSignal })
            return revisionsResponse.data.metadataDefinitions
        },
        [project.id],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    async function addMetadataDefinition(values: INewDefinitionModalFormData): Promise<void> {
        await ProjectMetadataAdd(project.id, {
            name: values.name,
            key: values.key,
            description: '',
            tags: [],
            isRequired: false,
            options: {},
            type: values.type as BaseMetadataTypes
        })

        definitionsAsync.execute(project.id)
    }

    async function deleteMetadataDefinition(key: string): Promise<void> {
        try {
            await ProjectMetadataRetire(project.id, key)
            NotificationService.info('Deleted metadata')
        } catch {
            NotificationService.error('Unable to delete metadata')
        }
        definitionsAsync.execute(project.id)
    }

    async function updateMetadataDefinition(key: string, update: IUpdateMetadataDefinition): Promise<void> {
        try {
            await ProjectMetadataUpdate(project.id, key, update)
            NotificationService.info('Updated metadata')
        } catch {
            NotificationService.error('Unable to update metadata')
        }
        definitionsAsync.execute(project.id)
    }

    const metadataDisabled = project.owner?.id !== company.id

    return (
        <div>
            <Card className="mb-3">
                <CardHeader className="d-flex align-items-center">
                    <FA icon="list-ul" />
                    <span className="ml-2">Metadata</span>
                    <Button id="add-project-metadata" className="ml-auto" disabled={metadataDisabled} onClick={modal.actions.show}>Add Metadata</Button>
                </CardHeader>
                {definitionsAsync.result?.length
                    ? <MetadataDefinitionList
                        disabled={metadataDisabled}
                        definitions={definitionsAsync.result}
                        onUpdateDefinition={updateMetadataDefinition}
                        onDeleteDefinition={deleteMetadataDefinition}
                    />
                    : <CardBody>
                        {definitionsAsync.loading
                        ? <LoadingMessage />
                        : <EmptyResourceArea
                            icon="list"
                            buttonText="Add metadata"
                            messageText="Metadata lets you record and search extra information against documents. You can customise rules so that only correct information recorded."
                            onClick={modal.actions.show}
                        />}
                    </CardBody>}
            </Card>
            <NewDefinitionModal
                {...modal.modalProps}
                validTypes={validMetadataTypes}
                onSubmit={addMetadataDefinition}
                existingDefinitions={definitionsAsync.result ?? []}
            />
        </div>
    )
}
