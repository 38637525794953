import React from 'react'
import { Button, Card } from 'reactstrap'

import numeral from 'numeral'

import FA from '@src/components/common/FontAwesomeIcon'
import { auth } from '@src/logic/auth/AuthService'
import { EmailDownloadAttachmentsLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { fileTypeIcon } from '@src/logic/utils/FileFormats'
import { EmailAttachment as Attachment } from '@src/types/email'

interface IProps {
    emailId: string
    attachment: Attachment
    index: number
    hideDownload: boolean
}

const EmailAttachment: React.FC<IProps> = React.memo(({ attachment, emailId, index, hideDownload }) => {
    function downloadAttachment() {
        downloadURL(EmailDownloadAttachmentsLink(auth.getSessionToken(), emailId, index))
    }

    return (
        <Card body className="d-flex flex-row align-items-center py-2 px-3 mb-1">
            <div className="mr-3"><FA size="2x" icon={fileTypeIcon(attachment.fileName)} /></div>
            <div className="flex-fill text-truncate">
                <div className="text-truncate">{attachment.fileName}</div>
                <small className="text-muted">{numeral(attachment.size).format('0.0 b')}</small>
            </div>
            {!hideDownload && <div className="ml-2 flex-shrink-0 border-left"><Button color="link" onClick={downloadAttachment} aria-label="Download attachment"><FA icon="download" /></Button></div>}
        </Card>
    )
})

export default EmailAttachment
