import React, { useEffect } from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import Pagination from '@src/components/common/Pagination'
import { IOption } from '@src/components/common/Select'
import RevisionTable from '@src/components/document/RevisionTable'
import WidgetRevisionsInput from '@src/components/widget/inputs/WidgetRevisionsInput'
import { IModalProps } from '@src/hooks/useModal'
import { usePagination } from '@src/hooks/usePagination'
import { CommitmentUpdate, RevisionsList } from '@src/logic/http/Api'
import { FilterBuilder } from '@src/logic/iql/FilterBuilder'
import { Api } from '@src/types/api'
import { Commitment } from '@src/types/costs'
import { Revision } from '@src/types/document'

interface IProps extends IModalProps {
    projectId: string
    commitment: Commitment
    reloadCommitment: () => Promise<void>
    category: string
}

const DocumentLinksModal: React.FC<IProps> = ({ category, children, commitment, projectId, reloadCommitment, ...modalProps }) => {
    const revisionIds = React.useMemo(() => category ? commitment.documentLinks[category].map(x => x.revisionId) : [], [commitment, category])

    const paging = usePagination({ initialPage: 1, initialPerPage: 20, initialTotalItems: revisionIds.length })

    useEffect(() => paging.setTotalItems(revisionIds.length), [revisionIds])

    const revisionsAsync = useAsyncAbortable(
        (abortSignal, ids, _category?) => ids.length === 0 ? Promise.resolve<Api.Response.RevisionListSingleProject>({ metadataDefinitions: [], revisions: [] }) : RevisionsList(projectId, FilterBuilder.for<Revision>().eq('id', ids).build(), undefined, 1, 10, { abortSignal }).then(x => x.data),
        [revisionIds, category],
        {
            setLoading: s => ({ ...s, loading: true })
        }
    )

    async function handleAddDocumentLink(revision: Revision) {
        try {
            await CommitmentUpdate(projectId, commitment.id, {
                description: commitment.description,
                documentLinks: {
                    ...commitment.documentLinks,
                    [category]: [
                        ...commitment.documentLinks[category],
                        {
                            documentId: revision.documentId,
                            revisionId: revision.id,
                            name: revision.name
                        }
                    ]
                },
                name: commitment.name,
                notes: commitment.notes,
                otherParty: commitment.otherParty?.id ?? commitment.otherParty?.name,
                otherPartyReference: commitment.otherPartyReference,
                tags: commitment.tags,
                date: commitment.date,
                variationAdditions: commitment.variationAdditions,
                variationDeductions: commitment.variationDeductions
            })
        } catch (e) {
            //
        }

        await reloadCommitment()
    }

    async function handleRemoveDocumentLink(revision: Revision) {
        try {
            await CommitmentUpdate(projectId, commitment.id, {
                description: commitment.description,
                documentLinks: {
                    ...commitment.documentLinks,
                    [category]: [
                        ...commitment.documentLinks[category].filter(x => x.revisionId !== revision.id)
                    ]
                },
                name: commitment.name,
                notes: commitment.notes,
                otherParty: commitment.otherParty?.id ?? commitment.otherParty?.name,
                otherPartyReference: commitment.otherPartyReference,
                tags: commitment.tags,
                date: commitment.date,
                variationAdditions: commitment.variationAdditions,
                variationDeductions: commitment.variationDeductions
            })
        } catch (e) {
            //
        }

        await reloadCommitment()
    }

    function filterWidgetRevisions(option: IOption<string>) {
        return !revisionIds.includes(option.value)
    }

    return (
        <Modal size="xl" {...modalProps}>
            <ModalHeader toggle={modalProps.toggle}>{category}</ModalHeader>
            <ModalBody>
                {revisionsAsync.result && (
                    <div>
                        <Row className="mb-3">
                            <Col lg={4}>
                                <WidgetRevisionsInput
                                    isMulti={false}
                                    onChange={handleAddDocumentLink}
                                    placeholder="Add document from widget..."
                                    filterOption={filterWidgetRevisions}
                                />
                            </Col>
                            <Col className="ml-auto flex-grow-0 flex-shrink-1">
                                <Pagination
                                    currentPage={paging.page}
                                    onSelectPage={paging.setPage}
                                    perPage={paging.perPage}
                                    totalCount={revisionIds.length}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RevisionTable
                                    revisions={revisionsAsync.result.revisions}
                                    metadataDefinitions={revisionsAsync.result.metadataDefinitions}
                                    deleteAction={{
                                        tooltip: 'Remove link',
                                        icon: 'times',
                                        visible: true,
                                        disabled: false,
                                        overrideAction: handleRemoveDocumentLink
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={modalProps.toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DocumentLinksModal
