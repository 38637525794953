import React from 'react'
import { useAsync } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Form as BootstrapForm, Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalProps } from '@src/hooks/useModal'
import { ProjectOperations } from '@src/logic/auth/operations'
import { required } from '@src/logic/forms/validation'
import { ProjectAccessGet } from '@src/logic/http/Api'
import { RootState } from '@src/types/models'
import { PrincipalBrief, UserInternal } from '@src/types/principal'

interface IProps extends IModalProps {
    projectId: string
    onSubmit: <T>(values: IApprovalFormData) => Promise<T> | Promise<void>
}

export interface IApprovalFormData {
    approver: UserInternal
    comments: string
}

async function loadApprovers(projectId: string): Promise<PrincipalBrief[]> {
    const response = await ProjectAccessGet(projectId, true)
    return response.data.acl
        .filter(x => x.type === 'user' && x.authorised.includes(ProjectOperations.ApproveTransmittal))
        .map<PrincipalBrief>(x => ({ id: x.id, name: x.name }))
}

function TransmittalApprovalModal({ isOpen, toggle, onClosed, onSubmit, projectId }: IProps) {
    const currentUserId = useSelector<RootState, string>(s => s.session.user.id)

    const approversAsync = useAsync(
        async () => {
            // eslint-disable-next-line @typescript-eslint/return-await
            if (!isOpen) return []
            const approvers = await loadApprovers(projectId)
            const currentUserApproverIdx = approvers.findIndex(a => a.id === currentUserId)
            if (currentUserApproverIdx > -1) {
                const currentUser = approvers.splice(currentUserApproverIdx, 1)[0]
                return [currentUser, ...approvers]
            }
            return approvers
        },
        [isOpen],
        { setLoading: s => ({ ...s, loading: true }), executeOnMount: false }
    )

    function formatApprovalLabel(option: PrincipalBrief) {
        if (option && option.id === currentUserId) return <strong>Self Approve</strong>

        return option.name
    }

    function getApproverLabel(approver: PrincipalBrief) {
        return approver.id === currentUserId ? 'Self Approve' : approver.name
    }

    function getApproverValue(approver: PrincipalBrief) {
        return approver.id
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            <Form<IApprovalFormData>
                onSubmit={onSubmit}
            >
            {form =>
                <>
                    <ModalHeader toggle={toggle}>Request Approval</ModalHeader>
                    <ModalBody>
                        <BootstrapForm>
                            <FormGroup>
                                <Label for='transmittalApproval-approver'>Approver</Label>
                                <Field
                                    id='transmittalApproval-approver'
                                    name="approver"
                                    component={ValidatedSelect}
                                    validate={required}
                                    isLoading={approversAsync.loading}
                                    defaultValue={approversAsync.result?.length > 0 && approversAsync.result[0]}
                                    options={approversAsync.result}
                                    formatOptionLabel={formatApprovalLabel}
                                    getOptionLabel={getApproverLabel}
                                    getOptionValue={getApproverValue}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for='transmittalApproval-comments'>Comments</Label>
                                <Field id='transmittalApproval-comments' name="comments" component={ValidatedInput} type="textarea" />
                            </FormGroup>
                        </BootstrapForm>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggle}>Cancel</Button>
                        <Field<PrincipalBrief> name="approver" subscription={{ value: true }}>
                            {({ input: { value: selectedApprover } }) => <Button onClick={form.handleSubmit}>{selectedApprover?.id === currentUserId ? 'Approve & Send' : 'Request Approval'}</Button>}
                        </Field>
                    </ModalFooter>
                </>
            }
            </Form>
        </Modal>
    )
}

export default React.memo(TransmittalApprovalModal)
