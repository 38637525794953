import { CommitmentLink } from '@src/types/costs'
import { CompanyLink } from '@src/types/principal'

interface Schema {
    [model: string]: SchemaModel
}

type ValueColumnType = 'ColumnTypes::String'
    | 'ColumnTypes::Numeric'
    | 'ColumnTypes::Boolean'
    | 'ColumnTypes::DateTime'
    | 'ColumnTypes::Text'
    | 'ColumnTypes::YAML'

type ReferenceColumnType = 'ColumnTypes::HasOne' | 'ColumnTypes::HasMany'

interface SchemaModel<T = any> {
    methods: {
        [K in keyof T]: ValueModelMethod | ReferenceModelMethod
    }
}

export interface ValueModelMethod {
    type: ValueColumnType
    foreign_table_name: null
    hint: string
}

export interface ReferenceModelMethod {
    type: ReferenceColumnType
    foreign_table_name: string
    hint?: string
}

const CommitmentLinkAutotextModel: SchemaModel<CommitmentLink> = {
    methods: {
        id: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Commitment\'s id'
        },
        type: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Commitment\'s type'
        },
        name: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Commitment\'s name'
        }
    }
}

const CompanyLinkAutotextModel: SchemaModel<CompanyLink> = {
    methods: {
        id: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Company\'s id'
        },
        name: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Company\'s name'
        }
    }
}

const DocumentLinkAutotextModel: SchemaModel<any> = {
    methods: {
        RevisionId: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Id of the revision'
        },
        DocumentId: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Id of the document'
        },
        Name: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Name of the document'
        }
    }
}

const PaymentLinkAutotextModel: SchemaModel<any> = {
    methods: {
        Commitment: {
            type: 'ColumnTypes::HasOne',
            foreign_table_name: 'CommitmentLinks',
            hint: 'Commitment related to this payment'
        },
        CertificateNumber: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'The payment\'s certificate number'
        },
        Certified: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'The certified amount (exc. GST)'
        },
        CertifiedIncGst: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'The certified amount (inc. GST)'
        },
        OtherPartyClaimRef: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Other party\'s claim reference number'
        },
        OtherPartyInvoiceRef: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Other party\'s invoice reference number'
        },
        Notes: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Notes recorded against payment'
        },
        ClaimDate: {
            type: 'ColumnTypes::DateTime',
            foreign_table_name: null,
            hint: 'Claim date of the payment'
        },
        InvoiceDate: {
            type: 'ColumnTypes::DateTime',
            foreign_table_name: null,
            hint: 'Invoice date of the payment'
        },
        Value: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'Value of the payment'
        },
        Items: {
            type: 'ColumnTypes::HasMany',
            foreign_table_name: 'PaymentClaimItems',
            hint: 'Payment line items (commitment items)'
        },
        ItemsByType: {
            type: 'ColumnTypes::YAML',
            foreign_table_name: null,
            hint: 'Items grouped by commitment type code'
        }
    }
}

export const PaymentClaimItemsModel: SchemaModel<any> = {
    methods: {
        OtherPartyRef: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Other party reference for this line item'
        },
        ItemId: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'InfoPoint ID for this line item'
        },
        ItemNumber: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'Line item number'
        },
        ItemName: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Line item name'
        },
        ItemValue: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'Line item value'
        },
        Determined: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'Determined complete value for this payment'
        },
        PreviouslyCertified: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'Previously certified amount for line item'
        },
        Certified: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'Certified amount this payment for line item'
        },
        Claimed: {
            type: 'ColumnTypes::Numeric',
            foreign_table_name: null,
            hint: 'Claimed amount this payment for line item'
        },
        Reason: {
            type: 'ColumnTypes::String',
            foreign_table_name: null,
            hint: 'Reason for variance for this line item'
        }
    }
}

export const MetadataSchema: Schema = {
    CommitmentLinks: CommitmentLinkAutotextModel,
    CompanyLinks: CompanyLinkAutotextModel,
    DocumentLinks: DocumentLinkAutotextModel,
    PaymentClaims: PaymentLinkAutotextModel,
    PaymentClaimItems: PaymentClaimItemsModel
}

export const CommunicationSchema: Schema = {
    ...MetadataSchema,
    Communications: {
        methods: {
            Sender: {
                type: 'ColumnTypes::HasOne',
                foreign_table_name: 'Sender',
                hint: 'The user sending the communication'
            },
            Name: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: 'Name of the communication'
            },
            TemplateName: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: 'Name of the template'
            },
            Number: {
                type: 'ColumnTypes::Numeric',
                foreign_table_name: null,
                hint: 'Auto incrementing number of the communication in the project'
            },
            CreatedDate: {
                type: 'ColumnTypes::DateTime',
                foreign_table_name: null,
                hint: 'Date the communication was created'
            },
            To: {
                type: 'ColumnTypes::HasMany',
                foreign_table_name: 'Users',
                hint: 'List of users communication is addressed too'
            },
            Cc: {
                type: 'ColumnTypes::HasMany',
                foreign_table_name: 'Users',
                hint: 'List of users communication is copied too'
            }
        }
    },
    Projects: {
        methods: {
            Code: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Description: {
                type: 'ColumnTypes::Text',
                foreign_table_name: null,
                hint: ''
            },
            Email: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Name: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Tags: {
                type: 'ColumnTypes::YAML',
                foreign_table_name: null,
                hint: ''
            }
        }
    },
    Sender: {
        methods: {
            Company: {
                type: 'ColumnTypes::HasOne',
                foreign_table_name: 'Companies',
                hint: 'The user\'s company'
            },
            FirstName: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            LastName: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            FullName: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Email: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Phone: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Mobile: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Title: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Role: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            SignatureUrl: {
                type: 'ColumnTypes::Text',
                foreign_table_name: null,
                hint: 'URL of the user\'s signature'
            }
        }
    },
    Users: {
        methods: {
            Company: {
                type: 'ColumnTypes::HasOne',
                foreign_table_name: 'Companies',
                hint: 'The user\'s company'
            },
            FirstName: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            LastName: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            FullName: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Email: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Phone: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Mobile: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Title: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            },
            Role: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: ''
            }
        }
    },
    Companies: {
        methods: {
            Owner: {
                type: 'ColumnTypes::HasOne',
                foreign_table_name: 'Companies',
                hint: 'Owner of the project'
            },
            Client: {
                type: 'ColumnTypes::HasOne',
                foreign_table_name: 'Companies',
                hint: 'Client of the project'
            },
            Name: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: 'Name of the company'
            },
            Abn: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: 'Australian Business Number'
            },
            Acn: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: 'Australian Company Number'
            },
            Website: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: 'Website of the company'
            },
            Phone: {
                type: 'ColumnTypes::String',
                foreign_table_name: null,
                hint: 'Phone of the company'
            }
        }
    }
}
