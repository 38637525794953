import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Button, Card, CardBody, CardHeader, FormText } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import useModal from '@src/hooks/useModal'
import { CompanyUsersList, GroupsList, ProjectAccessEntityType, ProjectSettingsDefaultAccessApply, ProjectSettingsDefaultAccessGet, ProjectSettingsDefaultAccessUpdate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { projectSettingsDefaultAccess } from '@src/logic/routing/routes'
import { capitalize } from '@src/logic/utils/Strings'
import { AccessControlList, AclEntry } from '@src/types/access'
import { Api } from '@src/types/api'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    company: Company
}

interface IDefaultAccessRouteParams {
    accessEntityType: string
}

const entityLookup: Record<ProjectAccessEntityType, [string, string]> = {
    [ProjectAccessEntityType.Communication]: ['communication', 'communications'],
    [ProjectAccessEntityType.Document]: ['document', 'documents'],
    [ProjectAccessEntityType.InboundEmail]: ['inbound email', 'inbound emails'],
    [ProjectAccessEntityType.Register]: ['register', 'registers'],
    [ProjectAccessEntityType.Revision]: ['revision', 'revisions'],
    [ProjectAccessEntityType.Transmittal]: ['transmittal', 'transmittals']
}

export default function DefaultAccessSection({ project, company }: IProps) {
    const history = useHistory()
    const match = useRouteMatch<IDefaultAccessRouteParams>()
    const applyDefaultPermissionsModal = useModal()
    const paramTypeAsLower = match.params.accessEntityType.toLowerCase()
    const entityType = Object.keys(ProjectAccessEntityType).find(x => ProjectAccessEntityType[x].toLowerCase() === paramTypeAsLower)

    if (entityType == null) {
        history.replace(projectSettingsDefaultAccess(project.id, ProjectAccessEntityType.Document))
    }

    const projectAccessEntityType = entityType == null ? null : ProjectAccessEntityType[entityType]

    async function applyDefaultPermissions() {
        applyDefaultPermissionsModal.actions.hide()
        try {
            await ProjectSettingsDefaultAccessApply(project.id, entityType as ProjectAccessEntityType)
            NotificationService.info(`Updated all ${pluralType} with default access`)
        } catch (e) {
            if (!isAxiosError(e)) return NotificationService.error('Unable to apply default permissions')
            switch (e.response.status) {
                case 403:
                    NotificationService.error('You must be a project administrator to apply default permissions')
                    break
                default:
                    NotificationService.error('Unable to apply default permissions')
            }
        }
    }

    async function getDefaultEntityAcl(): Promise<AccessControlList> {
        const projectSettings = await ProjectSettingsDefaultAccessGet(project.id)

        switch (projectAccessEntityType) {
            case ProjectAccessEntityType.Document:
                return projectSettings.data.defaultDocumentAcl
            case ProjectAccessEntityType.Revision:
                return projectSettings.data.defaultRevisionAcl
            case ProjectAccessEntityType.Communication:
                return projectSettings.data.defaultCommunicationAcl
            case ProjectAccessEntityType.Transmittal:
                return projectSettings.data.defaultTransmittalAcl
            case ProjectAccessEntityType.InboundEmail:
                return projectSettings.data.defaultInboundEmailAcl
            case ProjectAccessEntityType.Register:
                return projectSettings.data.defaultRegisterAcl
            default:
                return null
        }
    }

    async function loadPrincipals() {
        const principalEntries: AclEntry[] = []
        const usersPromise = CompanyUsersList(undefined, undefined, 1, 200)
        const groupsPromise = GroupsList(undefined, 1, 200)

        const results = await Promise.all([usersPromise, groupsPromise])

        principalEntries.push(...results[0].data.filter(x => x.status === 'Enabled').map<AclEntry>(u => ({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`,
            email: u.email,
            company: company.id,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'user'
        })))

        principalEntries.push(...results[1].data.map<AclEntry>(g => ({
            id: g.id,
            name: g.name,
            email: undefined,
            company: company.id,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'group'
        })))

        principalEntries.push({
            id: company.id,
            company: company.id,
            name: company.name,
            email: undefined,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'company'
        })

        return principalEntries
    }

    async function saveDefaultAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate): Promise<boolean> {
        try {
            await ProjectSettingsDefaultAccessUpdate(project.id, projectAccessEntityType, accessUpdate)
            return true
        } catch {
            return false
        }
    }

    const [singularType, pluralType]: [string, string] = projectAccessEntityType ? entityLookup[projectAccessEntityType] : ['', '']

    return (
        <div>
            {projectAccessEntityType != null &&
                <Card>
                    <CardHeader>
                        <FA icon="lock-alt" />
                        <span> {capitalize(singularType)} Default Access Control</span>
                    </CardHeader>
                    <CardBody>
                        <AccessControlTable
                            getAcl={getDefaultEntityAcl}
                            loadPrincipals={loadPrincipals}
                            commitUpdates={saveDefaultAccessUpdates}
                            disabled={!project.myAccess.isAdministrator}
                        />
                        <hr />
                        <Button className="mb-1" onClick={applyDefaultPermissionsModal.actions.show} isDisabled={!project.myAccess.isAdministrator}>Apply default access</Button>
                        <FormText className="mb-3">
                            <span>Apply the above default permssions to all existing {pluralType}. This will not change any permissions given to other companies.</span>
                            <br />
                            <strong>IMPORTANT: This will replace any custom permissions that may be set for each {singularType}.</strong>
                        </FormText>
                        <ConfirmationModal
                            {...applyDefaultPermissionsModal.modalProps}
                            onConfirm={applyDefaultPermissions}
                            confirmAction="Apply"
                            onReject={applyDefaultPermissionsModal.actions.hide}
                            header="Apply default permissions"
                        >
                            Are you sure you want to apply the default permissions to all existing {pluralType}? This will replace any custom permissions that are set on all individual {pluralType}.
                        </ConfirmationModal>
                    </CardBody>
                </Card>
            }
        </div>
    )
}
