import React from 'react'

import { DragOverEvent, Over, useDndMonitor } from '@dnd-kit/core'

export const SASH_WIDTH = 3

interface IProps {
    height: number
}

export function ColumnDropZoneSash({ height }: IProps) {
    const [overColumn, setOverColumn] = React.useState<Over & { initialX: number }>()
    const handleDragOver = React.useCallback((event: DragOverEvent) => {
        if (event.over && event.over.id === event.active.id) {
            setOverColumn(null)
            return
        }

        setOverColumn({ ...event.over, initialX: event.active.rect.current.initial.left })
    }, [])
    const handleDragEnd = React.useCallback(() => {
        setOverColumn(null)
    }, [])
    useDndMonitor({ onDragOver: handleDragOver, onDragEnd: handleDragEnd, onDragCancel: handleDragEnd })

    const column = overColumn?.rect
    if (column == null) return null

    return (
        <div
            style={{
                background: 'var(--tertiary)',
                left: column.left < overColumn.initialX ? column.left : column.right,
                top: column.top,
                bottom: 0,
                width: SASH_WIDTH,
                height,
                zIndex: 2,
                position: 'fixed'
            }}
        />
    )
}
