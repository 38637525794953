// tslint:disable:jsx-no-lambda
import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import { AxiosResponse } from 'axios'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import CommunicationToolbar from '@src/components/communication/CommunicationToolbar'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { CommunicationOperations } from '@src/logic/auth/operations'
import { CommunicationAccessGet, CommunicationAccessUpdate, CommunicationGet } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import * as Routes from '@src/logic/routing/routes'
import AccessPage from '@src/pages/common/AccessPage'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import CommunicationEditPage from '@src/pages/communication/CommunicationEditPage'
import { Api } from '@src/types/api'
import { Communication } from '@src/types/communication'
import { ClientErrorCode } from '@src/types/http'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

const CommunicationPages: React.FC = () => {
    const match = useRouteMatch<Routes.IProjectCommunicationParams>()
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const company = useSelector<RootState, Company>(s => s.session.company)

    const communicationAsync = useAsyncAbortable<AxiosResponse<Communication>, [string]>(
        (abortSignal, communicationId) => CommunicationGet(communicationId, { abortSignal }),
        [match.params.communicationId],
        { setLoading: (state) => match.params.communicationId === state.result?.data?.id ? { ...state, loading: true } : { ...state, result: null, loading: true } }
    )

    function setOrReloadCommunication(communication?: Communication) {
        if (communication == null || communication.id !== match.params.communicationId) {
            communicationAsync.execute(match.params.communicationId)
        }

        communicationAsync.set({ error: undefined, loading: false, result: { ...communicationAsync.result, data: communication }, status: 'success' })
    }

    const bannerTitle = React.useMemo(
        () => {
            if (communicationAsync.error) {
                if (!isAxiosError(communicationAsync.error)) return ''
                switch (communicationAsync.error.response.status) {
                    case ClientErrorCode.Forbidden:
                        return 'Permission Issue'
                    case ClientErrorCode.NotFound:
                        return 'Communication Not Found'
                    default:
                        return ''
                }
            }

            return communicationAsync.result?.data?.name ?? <span>Loading Communication<FA className="ml-2" icon="spinner-third" spin /></span>
        },
        [match.params.communicationId, communicationAsync.error, communicationAsync.result]
    )

    const clientErrorCode: ClientErrorCode = communicationAsync.error && isAxiosError(communicationAsync.error) ? communicationAsync.error.response.status : undefined

    async function getCommunicationAcl(communication: Communication) {
        return (await CommunicationAccessGet(communication.id)).data
    }

    async function saveCommunicationAccessUpdates(communication: Communication, accessUpdate: Api.Request.AccessControlListUpdate) {
        await CommunicationAccessUpdate(communication.id, accessUpdate)
        await communicationAsync.execute(communication.id)
    }

    const communication: Communication | undefined = communicationAsync.result?.data?.id === match.params.communicationId ? communicationAsync.result?.data : undefined

    return (
        <CrumbRoute
            path=""
            linkPath={communication ? Routes.projectCommunication(project.id, communication.id) : match.url}
            title={communication?.name ?? ''}
        >
            <div>
                <ProjectBanner
                    project={project}
                    overrideTitle={bannerTitle}
                />
                <ClientErrorPage clientErrorCode={clientErrorCode} resourceName="communication" requiredOperation={CommunicationOperations.Read}>
                    {communication &&
                        <Switch>
                            <Route path={Routes.PROJECT_COMMUNICATION_EDIT} exact
                                render={(routeProps) => (
                                    <CommunicationEditPage
                                        {...routeProps}
                                        project={project}
                                        communication={communication}
                                        reloadCommunication={setOrReloadCommunication}
                                    />
                                )}
                            />
                            <CrumbRoute
                                path={Routes.PROJECT_COMMUNICATION_ACCESS}
                                title="Access"
                            >
                                <CommunicationToolbar project={project} communication={communication} />
                                <AccessPage
                                    name="Communication"
                                    entity={communication}
                                    company={company}
                                    getEntityAcl={getCommunicationAcl}
                                    saveAccessUpdate={saveCommunicationAccessUpdates}
                                />
                            </CrumbRoute>
                            <Redirect to={Routes.projectCommunicationEdit(project.id, communication.id)} />
                        </Switch>}
                </ClientErrorPage>
            </div>
        </CrumbRoute>
    )
}

export default CommunicationPages
