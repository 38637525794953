import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import RevisionEditForm, { IRevisionEditFormData } from '@src/components/document/RevisionEditForm'
import { RevisionUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Api } from '@src/types/api'
import { Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'

interface IProps {
    revision: Revision
    metadataDefinitions: IMetadataDefinition[]
    reloadDocument: () => void
}

export default class DocumentEditPage extends React.Component<IProps> {
    public readonly commitRevisionUpdates = async (values: IRevisionEditFormData) => {
        const { revision, metadataDefinitions, reloadDocument } = this.props
        const update: Api.Request.RevisionUpdate = {
            author: values.author,
            description: values.description,
            metadata: metadataDefinitions.reduce(
                (map, d) => {
                    map[d.key] = values.metadata[d.key]
                    return map
                },
                {}),
            name: values.name,
            published: revision.published,
            archived: revision.archived,
            revisionDate: values.revDate,
            revisionNumber: values.revNumber,
            tags: values.tags ? values.tags.map(x => x.value) : []
        }
        try {
            await RevisionUpdate(revision.id, update)
        } catch {
            NotificationService.error('An error occurred while trying to update the revision. Changes were not saved.')
        }

        NotificationService.info('Revision updated sucessfully')
        reloadDocument()
    }

    public render() {
        return (
            <Card>
                <CardHeader><FA icon="pencil" /> Edit Revision</CardHeader>
                <CardBody>
                    <RevisionEditForm
                        revision={this.props.revision}
                        definitions={this.props.metadataDefinitions}
                        onSaveChanges={this.commitRevisionUpdates}
                    />
                </CardBody>
            </Card>
        )
    }
}
