import NotificationService from '@src/logic/notification/NotificationService'

const notifications = {
    DEFAULT_AUTO_CLOSE: NotificationService.DEFAULT_AUTO_CLOSE,
    info: NotificationService.info,
    success: NotificationService.success,
    error: NotificationService.error,
    update: NotificationService.updateToast,
    updateThenClose: NotificationService.updateThenCloseToast,
    pending: NotificationService.pendingActivityToast,
    remove: NotificationService.removeToast
}

export default function useNotifications() {
    return notifications
}
