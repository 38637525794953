import React from 'react'
import { Button } from 'reactstrap'

import log from 'loglevel'

import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import InsuranceModalForm, { IInsuranceFormValues } from '@src/components/costs/commitments/InsuranceModalForm'
import CostValue from '@src/components/costs/common/CostValue'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import useBoolean from '@src/hooks/useBoolean'
import useLocalSearch from '@src/hooks/useLocalSearch'
import { auth } from '@src/logic/auth/AuthService'
import { CommitmentInsuranceCreate, CommitmentInsuranceDelete, CommitmentInsuranceDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import NotificationService from '@src/logic/notification/NotificationService'
import { localShortDate } from '@src/logic/utils/Date'
import { Commitment, Insurance } from '@src/types/costs'

interface IProps {
    projectId: string
    commitment: Commitment
    reloadCommitment: () => void
    search: string
}

const InsuranceSearchProperties: IMappedSearchProperty<Insurance>[] = [
    {
        name: 'Policy Number',
        path: item => item.policyNumber,
        searchKey: 'policy_number',
        type: PropertyType.Text
    },
    {
        name: 'Type',
        path: item => item.type,
        searchKey: 'type',
        type: PropertyType.Text
    },
    {
        name: 'Insurer',
        path: item => item.insurer,
        searchKey: 'insurer',
        type: PropertyType.Text
    },
    {
        name: 'Description',
        path: item => item.description,
        searchKey: 'description',
        type: PropertyType.Text
    },
    {
        name: 'Annual Occurrence Value',
        path: item => item.annualOccurrenceValue,
        searchKey: 'occurrence_value',
        type: PropertyType.Number
    },
    {
        name: 'Annual Aggregate Value',
        path: item => item.annualAggregateValue,
        searchKey: 'aggregate_value',
        type: PropertyType.Number
    },
    {
        name: 'Expiry Date',
        path: item => item.expiryDate,
        searchKey: 'expires',
        type: PropertyType.Date
    }
]

const InsurancesSection: React.FunctionComponent<IProps> = ({ projectId, commitment, reloadCommitment, search }) => {
    const creating = useBoolean(false)
    const editing = useBoolean(false)
    const deleting = useBoolean(false)
    const [selectedItem, setSelected] = React.useState<Insurance>()
    const insurancesSearchSectionRef = React.useRef<SearchSectionType<Insurance, 'id'>>()
    const handleSearch = useLocalSearch(commitment.insurances, InsuranceSearchProperties)

    React.useEffect(
        () => {
            if (insurancesSearchSectionRef.current) {
                insurancesSearchSectionRef.current.doSearch()
            }
        },
        [commitment.insurances, insurancesSearchSectionRef.current]
    )

    function setInsuranceToEdit(item: Insurance) {
        editing.setTrue()
        setSelected(item)
    }

    function setInsuranceToDelete(item: Insurance) {
        deleting.setTrue()
        setSelected(item)
    }

    function clearFlags() {
        editing.setFalse()
        deleting.setFalse()
        creating.setFalse()
    }

    function clearSelectedItemAndFlags() {
        clearFlags()
        setSelected(undefined)
    }

    async function loadCommitmentAndReloadSearch() {
        await reloadCommitment()
        if (insurancesSearchSectionRef.current) insurancesSearchSectionRef.current.doSearch()
    }

    async function createOrUpdateInsurance(values: IInsuranceFormValues) {
        if (selectedItem === undefined) {
            // Create
            try {
                await CommitmentInsuranceCreate(projectId, commitment.id, {
                    type: values.type.value,
                    insurer: values.insurer,
                    policyNumber: values.policyNumber,
                    annualAggregateValue: values.annualAggregateValue,
                    annualOccurrenceValue: values.annualOccurrenceValue,
                    description: values.description,
                    expiryDate: values.expiryDate,
                    fileUuid: values.upload.id
                })
                NotificationService.info('Created insurance')
                reloadCommitment()
                clearFlags()
            } catch (e) {
                NotificationService.error('Unable to create insurance')
                log.error('Failed to create new insurance', e)
            }
        } else {
            // Update
        }
    }

    async function deleteCommitmentItem() {
        const item = { ...selectedItem }
        clearSelectedItemAndFlags()
        try {
            await CommitmentInsuranceDelete(projectId, commitment.id, item.id)
        } catch {
            NotificationService.error(<span>Failed to remove insurance</span>)
        }

        await loadCommitmentAndReloadSearch()
    }

    function downloadInsurance(insurance: Insurance) {
        downloadURL(CommitmentInsuranceDownloadLink(projectId, commitment.id, insurance.id, auth.getSessionToken()))
    }

    return (
        <SearchSection<Insurance, 'id'>
            ref={insurancesSearchSectionRef}
            defaultPerPage={10}
            onSearch={handleSearch}
            searchAssistantProperties={InsuranceSearchProperties}
            extraSearchBarElements={[
                {
                    element: _ => <Button onClick={creating.setTrue}><FA icon="plus" /> New Insurance</Button>,
                    position: 'before'
                }
            ]}
            noItemsFoundMessage={(
                <div>
                    <div className="my-3"><FA size="3x" icon="file-certificate" /></div>
                    <p className="lead">There are no insurances</p>
                    <p className="lead"><Button onClick={creating.setTrue}>Create insurance</Button></p>
                </div>
            )}
            headers={[
                {
                    name: 'Policy Number',
                    accessor: 'policyNumber',
                    sortable: true
                },
                {
                    name: 'Type',
                    accessor: 'type',
                    sortable: true
                },
                {
                    name: 'Insurer',
                    accessor: 'insurer',
                    sortable: true
                },
                {
                    name: 'Description',
                    accessor: 'description',
                    sortable: true
                },
                {
                    name: 'Annual Occurrence Value',
                    overrideRenderer: item => <CostValue value={item.annualOccurrenceValue} />,
                    sortKey: 'occurrence_value',
                    sortable: true
                },
                {
                    name: 'Annual Aggregate Value',
                    overrideRenderer: item => <CostValue value={item.annualAggregateValue} />,
                    sortKey: 'occurrence_value',
                    sortable: true
                },
                {
                    name: 'Expiry Date',
                    overrideRenderer: item => localShortDate(item.expiryDate),
                    sortKey: 'expires',
                    sortable: true
                },
                {
                    name: 'Actions',
                    overrideRenderer: insurance => (
                        <div className="text-right">
                            {/* <TooltipLinkAction id={`edit-insurance-${insurance.id}`} tooltip="Edit" data={insurance} className="order-lg-1" onClick={setInsuranceToEdit}><FA icon="pencil" /></TooltipLinkAction> */}
                            <TooltipLinkAction id={`download-insurance-${insurance.id}`} tooltip="Download" data={insurance} className="" onClick={downloadInsurance}><FA icon="download" /></TooltipLinkAction>
                            <TooltipLinkAction id={`delete-insurance-${insurance.id}`} tooltip="Remove" data={insurance} className="order-lg-1" onClick={setInsuranceToDelete}><FA icon="trash" /></TooltipLinkAction>
                        </div>
                    ),
                    noSmallHeader: true,
                    headerWrapperClass: 'text-right'
                }
            ]}
        >
            <InsuranceModalForm
                isOpen={creating.value || editing.value}
                toggle={clearFlags}
                onClosed={clearSelectedItemAndFlags}
                insurance={selectedItem}
                onSubmit={createOrUpdateInsurance}
            />
            <ConfirmationModal
                danger
                isOpen={deleting.value}
                toggle={deleting.setFalse}
                header="Remove insurance"
                message={<span>Are you sure you want to remove the insurance policy <strong>{selectedItem?.policyNumber}</strong>?</span>}
                onClosed={clearSelectedItemAndFlags}
                onConfirm={deleteCommitmentItem}
            />
        </SearchSection>
    )
}

export default InsurancesSection
