import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, ResponseType } from 'axios'

import { auth } from '@src/logic/auth/AuthService'
import { FeatureUnavailable } from '@src/logic/http/headers'
import { isAbortError } from '@src/logic/utils/Abort'
import { HttpMethod } from '@src/types/api'

function createAuthRefreshInterceptor(ax: AxiosInstance) {
    const id = ax.interceptors.response.use(res => res, async (error: AxiosError) => {
        if (!error.response || (error.response.status !== 401)) {
            throw error
        }

        if (error.response.headers[FeatureUnavailable] != null) {
            throw error
        }

        // Remove the interceptor to prevent a loop
        // in case token refresh also causes the 401
        axios.interceptors.response.eject(id)

        const renewTokenTask = auth.renewSession()

        // Create interceptor that will bind all the others requests
        // until refreshTokenCall is resolved
        const requestQueueInterceptorId = axios.interceptors
            .request
            .use(async (request) => {
                await renewTokenTask
                request.headers.Authorization = `Bearer ${auth.getSessionToken()}`
                return request
            })

        // When response code is 401 (Unauthorized), try to refresh the token.
        try {
            await renewTokenTask
            axios.interceptors.request.eject(requestQueueInterceptorId)
            error.response.config.headers.Authorization = `Bearer ${auth.getSessionToken()}`
            return await axios(error.response.config)
        } catch {
            auth.clearSession()
            axios.interceptors.request.eject(requestQueueInterceptorId)
            return error
        } finally {
            createAuthRefreshInterceptor(ax)
        }
    })
    return ax
}

const requestBuilderAxios = axios.create()
createAuthRefreshInterceptor(requestBuilderAxios)

export class RequestBuilder {
    private config: AxiosRequestConfig

    constructor(method: HttpMethod, url: string) {
        this.config = {
            method,
            url,
            headers: {},
            params: {}
        }
    }

    public responseType(type: ResponseType): RequestBuilder {
        this.config.responseType = type
        return this
    }

    public query(param: string, val?: string): RequestBuilder {
        this.config.params[param] = val
        return this
    }

    public contentType(type: string): RequestBuilder {
        this.config.headers['Content-Type'] = type
        return this
    }

    public authenticated(overrideToken?: string) {
        this.config.headers.Authorization = `Bearer ${overrideToken ?? auth.getSessionToken()}`
        return this
    }

    public withPagination(page: number, perPage: number): RequestBuilder {
        this.config.params.page = page.toString()
        this.config.params.perPage = perPage.toString()
        return this
    }

    public body(body: string): RequestBuilder {
        this.config.data = body
        return this
    }

    public json(body: object | object[]): RequestBuilder {
        this.config.data = body
        return this
    }

    public blob(blob: Blob): RequestBuilder {
        this.config.data = blob
        this.config.headers['Content-Type'] = blob.type
        return this
    }

    public abort(abortSignal?: AbortSignal): RequestBuilder {
        this.config.signal = abortSignal
        return this
    }

    public buildBlob(): AxiosPromise<Blob> {
        this.config.responseType = 'blob'
        return this.build<Blob>()
    }

    public build<T>(): AxiosPromise<T> {
        return requestBuilderAxios.request<T>(this.config)
            .catch((thrown) => {
                if (!isAbortError(thrown)) {
                    throw thrown
                }

                return undefined
            })
    }
}

export function authenticatedRequest<T = any>(method: HttpMethod, url: string, body?: object | object[], overrideAuthToken?: string, abortSignal?: AbortSignal): AxiosPromise<T> {
    const promise = new RequestBuilder(method, url)
        .authenticated(overrideAuthToken)
        .json(body)
        .abort(abortSignal)
        .build<T>()

    return promise
}
