import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { IModalProps } from "@src/hooks/useModal";
import NotificationService from "@src/logic/notification/NotificationService";
import { ApproveUser, MoveContactToCompany } from "@src/logic/http/Api";
import { UserBasic } from "@src/types/principal";
import ValidatedSelect from "@src/components/common/ValidatedSelect";
import shallow from "zustand/shallow";
import {
  useCompanyStore,
  useContactsStore,
  useNewRegistrationsStore,
} from "@src/store/admin";

interface IMoveContactValues {
  company: { label: string; value: string };
  userId: string;
}

export default function ConfirmContactForm({
  modalProps,
  user,
}: {
  modalProps: IModalProps;
  user: UserBasic;
}) {
  const [refreshNewRegistrations] = useNewRegistrationsStore(
    (s) => [s.refresh],
    shallow
  );
  const [searchableCompanies, loadSearchableCompanies] = useCompanyStore(
    (s) => [s.searchableCompanies, s.loadSearchableCompanies],
    shallow
  );
  async function handleSubmit(values: IMoveContactValues): Promise<any> {
    try {
      await MoveContactToCompany({
        companyId: values.company.value,
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      }).then(async (result) => {
        if (result.status == 200) await ApproveUser(user.id);
        await refreshNewRegistrations("");
      });
      NotificationService.success(
        `Contact was successfully confirmed and moved to ${values.company.label}.`
      );
    } catch {
      NotificationService.error(
        "An error occured while confirming a company. Please refresh and try again."
      );
    } finally {
      modalProps.toggle();
    }
  }

  useEffect(() => {
    loadSearchableCompanies("", 10000);
  }, []);

  return (
    <Modal {...modalProps}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          company: user?.company
            ? {
                label: user.company.name,
                value: user.company.id,
              }
            : {},
          userId: user?.id,
        }}
      >
        {(formProps) => (
          <>
            <ModalHeader>Confirm Contact</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12} className="mb-3">
                  <FormGroup>
                    <h5>
                      Contact name : {user?.firstName} {user?.lastName}
                    </h5>
                  </FormGroup>
                </Col>
                <Col xs={12} className="mb-3">
                  <FormGroup>
                    <Label>Select Company</Label>
                    <Field
                      name="company"
                      component={ValidatedSelect}
                      isClearable
                      options={
                        searchableCompanies &&
                        searchableCompanies.map((c) => ({
                          label: c.name,
                          value: c.id,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                style={{ borderColor: "lightgray" }}
                onClick={modalProps.toggle}
                className="w-25"
              >
                Cancel
              </Button>
              <Button
                disabled={formProps.submitting}
                color="primary"
                className="w-25"
                onClick={formProps.handleSubmit}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
}
