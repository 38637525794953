import React from 'react'
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import * as Routes from '@src/logic/routing/routes'
import { Revision } from '@src/types/document'

interface IProps {
    revision: Revision
}

export default function TransmittalLinks({ revision }: IProps) {
    return (
        <>
            <a id={`revision-${revision.id}-tlinks`} href="#" className={cx('mr-0', 'text-center', 'selectable-content__icon', { 'selectable-content__icon--red': revision.links.transmittals.length > 0 })}><FA icon="inbox" /></a>
            {revision.links.transmittals.length > 0 && <UncontrolledPopover target={`revision-${revision.id}-tlinks`} trigger="hover">
                <PopoverHeader>Linked in Transmittals</PopoverHeader>
                <PopoverBody>
                    <ul className="pl-3">
                        {revision.links.transmittals.map(t => <li key={t.id}><Link to={Routes.projectTransmittal(revision.projectId, t.id)}>{t.subject}</Link></li>)}
                    </ul>
                </PopoverBody>
            </UncontrolledPopover>}
        </>
    )
}
