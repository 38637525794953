import React from 'react'
import { useSelector } from 'react-redux'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { exportRegister } from '@src/logic/exports/excelExports'
import { FeatureFlag, Features, RegisterFlagValue, hasFeature } from '@src/logic/features/features'
import { ProjectsGet, RegisterDownloadAsPdf } from '@src/logic/http/Api'
import { downloadBlob } from '@src/logic/http/Download'
import NotificationService from '@src/logic/notification/NotificationService'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { RegisterOverview } from '@src/types/register'

interface IProps {
    register: RegisterOverview
    currentRoutePath: string
}

export default function RegisterToolbar({ register }: IProps) {
    const features = useSelector<RootState, Features>(s => s.session.features)
    const betaRegisters = React.useMemo(() => hasFeature(features, FeatureFlag.Registers, RegisterFlagValue.Beta), [features])

    async function handleExportRegister() {
        const toastId = NotificationService.pendingActivityToast('Exporting register')
        await exportRegister(register)
        NotificationService.updateThenCloseToast(toastId, 'Register exported')
    }

    async function handleExportPdfRegister() {
        const notificationId = NotificationService.pendingActivityToast('Generating register report')
        const project = await ProjectsGet(register.projectId)
        const fileNameData = `${project.data.code} ${project.data.name} ${register.name} Report`
        const fileName = `${fileNameData} - ${new Date().toLocaleDateString().replace(/\//g, '-')}.pdf`

        try {
            const blob = (await RegisterDownloadAsPdf(register.id)).data
            downloadBlob(blob, fileName, 'application/pdf')
            NotificationService.updateThenCloseToast(notificationId, 'Cost report generated')
        } catch {
            NotificationService.updateThenCloseToast(notificationId, 'Failed to generate cost report', { type: 'error' })
        }
    }

    return (
        <section className="banner">
            <Toolbar>
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTER)} to={Routes.projectRegister(register.projectId, register.id)} name="Search" nameBrief="Search" iconBrief="search" />
                {!betaRegisters && <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTER_COLUMNS)} to={Routes.projectRegisterColumns(register.projectId, register.id)} name="Columns" nameBrief="Cols" iconBrief="table" />}
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTER_ACCESS)} to={Routes.projectRegisterAccess(register.projectId, register.id)} name="Access" nameBrief="Access" iconBrief="lock" />
                <div className="ml-auto d-none d-md-flex">
                    <ToolbarLink tool name="Export" icon="download" iconBrief="download" onClick={handleExportRegister} />
                    <ToolbarLink tool name="Report" icon="file-chart-line" iconBrief="file-chart-line" onClick={handleExportPdfRegister} />
                </div>
            </Toolbar>
        </section>
    )
}
