import React from "react";
import Select, { IOption } from "@src/components/common/Select";
import { useCompanyGroupsStore } from "@src/store/admin";
import { CompanyGroup } from "@src/types/principal";
import shallow from "zustand/shallow";

interface IGroupSelectProps {
  value: CompanyGroup[];
  onChange: (value: CompanyGroup[]) => void;
  isMulti: true;
  placeholder?: string;
  filterOption?: (revision: IOption<string>, input: string) => boolean;
}

const GroupSelect: React.FC<IGroupSelectProps> = ({
  value,
  isMulti,
  onChange,
  ...commonProps
}) => {
  const [groups, loadGroups] = useCompanyGroupsStore(
    (s) => [s.groups, s.loadGroups, s.refreshGroups],
    shallow
  );
  function groupLabel(group: CompanyGroup) {
    return group.groupName;
  }

  function groupValue(group: CompanyGroup) {
    return group.groupId;
  }

  return (
    <Select
      isMulti={isMulti}
      options={groups}
      value={value}
      onChange={onChange}
      getOptionLabel={groupLabel}
      getOptionValue={groupValue}
      {...commonProps}
    />
  );
};

export default GroupSelect;
