import React from 'react'
import { Field } from 'react-final-form'

import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import GenericSelectDropdown from '@src/components/register/tablefields/GenericSelectDropdown'
import { EmailLink } from '@src/types/email'
import { EmailLinksDefinition } from '@src/types/metadata'

function getEmailLabel(emailLink: EmailLink) {
    return emailLink.subject
}

function getEmailValue(emailLink: EmailLink) {
    return emailLink.id
}

export default function EmailLinkField(props: IRegisterTableField<EmailLinksDefinition>) {
    const value = props.row.cells[props.col.id] as EmailLink[] | null
    return props.isFocused
        ? (
            <Field name={`cells.r-${props.row.id}.c-${props.col.id}`} validateFields={[]}>
                {({ input }) =>
                    <GenericSelectDropdown
                        {...props}
                        onChange={input.onChange}
                        value={input.value}
                        getOptionLabel={getEmailLabel}
                        getOptionValue={getEmailValue}
                        options={[]}
                    />
                }
            </Field>
        )
        : (
            <span className="register__select-span">{value.length > 0
                ? <a href={'comproute'}>{value.map(n => n.subject)}</a>
                : <span className="register__placeholder"></span>}</span>
        )
}

export function EmailLinkFieldFooter(props: IRegisterTableField<EmailLinksDefinition>) {
    return (
        <Field name={`cells.c-${props.col.id}`}>
            {({ input }) =>
                <GenericSelectDropdown
                    {...props}
                    onChange={input.onChange}
                    options={[]}
                    getOptionLabel={getEmailLabel}
                    getOptionValue={getEmailValue}
                />
            }
        </Field>
    )
}
