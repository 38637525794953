import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field } from 'react-final-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import CostValue from '@src/components/costs/common/CostValue'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import GenericSelectDropdown from '@src/components/register/tablefields/GenericSelectDropdown'
import GenericContentTable, { ITableHeader } from '@src/components/table/GenericContentTable'
import useModal from '@src/hooks/useModal'
import useProject from '@src/hooks/useProject'
import useTouched from '@src/hooks/useTouched'
import useProjectWidget from '@src/hooks/useWidget'
import { paymentLabel, paymentValue } from '@src/logic/forms/SelectHelpers'
import { PaymentClaimsList } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { mutedNotSet, valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { PaymentClaimBrief, PaymentClaimLink } from '@src/types/costs'
import { PaymentClaimLinksDefinition } from '@src/types/metadata'

const NO_OPTIONS_MESSAGE = () => 'Add payments to your widget'

const paymentClaimTableHeaders = (projectId: string): ITableHeader<PaymentClaimBrief>[] => ([
    {
        name: 'Claim #',
        sortKey: 'claim_number',
        sortable: true,
        overrideRenderer: payment => <Link to={Routes.projectCostsPaymentDetail(projectId, payment.commitment.type, payment.commitment.id, payment.id)}>{payment.certificateNumber}</Link>
    },
    {
        name: 'Supplier',
        overrideRenderer: payment => valueOrMutedNotSet(payment.commitment.otherParty?.name)
    },
    {
        name: 'Claim Date',
        sortKey: 'claim_date',
        sortable: true,
        overrideRenderer: payment => payment.claimDate ? localShortDate(payment.claimDate) : mutedNotSet
    },
    {
        name: 'Invoice Date',
        sortKey: 'invoice_date',
        sortable: true,
        overrideRenderer: payment => payment.invoiceDate ? localShortDate(payment.invoiceDate) : mutedNotSet
    },
    {
        name: 'Claim Ref.',
        overrideRenderer: payment => valueOrMutedNotSet(payment.claimReference)
    },
    {
        name: 'Invoice Ref.',
        overrideRenderer: payment => valueOrMutedNotSet(payment.invoiceReference)
    },
    {
        name: 'Claimed',
        overrideRenderer: commitment => <CostValue value={commitment.currentClaimed} />
    },
    {
        name: 'Certified',
        overrideRenderer: commitment => <CostValue value={commitment.currentCertified} />
    },
    {
        name: 'Paid',
        overrideRenderer: commitment => <CostValue value={commitment.currentPaid} />
    }
])

export default function PaymentLinkField(props: IRegisterTableField<PaymentClaimLinksDefinition>) {
    const value = props.row.cells[props.col.id] as PaymentClaimLink[] | null
    const [project] = useProject()
    const widget = useProjectWidget()
    const displayPaymentLinkModal = useModal(false, { destroyOnClose: true })
    const isTouched = useTouched(props.isFocused, [value])
    const tableHeaders = React.useMemo(() => paymentClaimTableHeaders(project.id), [project.id])
    const options = React.useMemo(() => (value ?? []).concat(widget.paymentClaims.map<PaymentClaimLink>(x => ({ id: x.id, commitmentId: x.commitment.id, certificateNumber: x.certificateNumber }))), [widget.paymentClaims, value])

    const paymentsAsync = useAsyncAbortable(async (abortSignal) => {
        const response = await PaymentClaimsList(project.id, `id: ${value.map(x => x.id).join(',')}`, undefined, undefined, undefined, { abortSignal })
        return response.data.paymentClaims
    }, [], {
        executeOnMount: false,
        executeOnUpdate: false,
        initialState: s => ({ error: undefined, loading: false, result: [], status: 'not-requested' }),
        setLoading: s => ({ ...s, loading: true, status: 'loading' })
    })

    React.useEffect(() => {
        if (displayPaymentLinkModal.modalProps.isOpen) paymentsAsync.execute()
    }, [displayPaymentLinkModal.modalProps.isOpen])

    function handleOpenTable(e: React.MouseEvent) {
        e.stopPropagation()
        displayPaymentLinkModal.actions.show()
    }

    return (
        <>
            {isTouched
                ? (
                    <Field name={`cells.r-${props.row.id}.c-${props.col.id}`} initialValue={value} validateFields={[]}>
                        {({ input }) =>
                            <>
                                {props.isFocused
                                    ? <>
                                        <GenericSelectDropdown
                                            {...props}
                                            value={input.value}
                                            onChange={input.onChange}
                                            getOptionLabel={paymentLabel}
                                            getOptionValue={paymentValue}
                                            options={options}
                                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                                        />
                                    </>
                                    : (
                                        <span className="register__select-span d-inline-flex overflow-hidden align-items-center">
                                            {input.value?.length
                                                ? <>
                                                    <span className="text-truncate">{input.value[0].certificateNumber}</span>
                                                    <span className="register__link-button-span">
                                                        <Button color="link" className="register__external-link-button" onClick={handleOpenTable}>
                                                            <FA icon="external-link-alt" />
                                                        </Button>
                                                    </span>
                                                </>
                                                : <span className="register__placeholder" />
                                            }
                                        </span>
                                    )}
                            </>
                        }
                    </Field>
                )
                : (
                    <span className="register__select-span d-inline-flex overflow-hidden align-items-center">
                        {value?.length
                            ? <>
                                <span className="text-truncate flex-grow-1">{value[0].certificateNumber}</span>
                                <span className="register__link-button-span">
                                    <Button color="link" className="register__external-link-button" onClick={handleOpenTable}>
                                        <FA icon="external-link-alt" />
                                    </Button>
                                </span>
                            </>
                            : <span className="register__placeholder" />
                        }
                    </span>
                )
            }
            <Modal {...displayPaymentLinkModal.modalProps} size="xl">
                <ModalHeader toggle={displayPaymentLinkModal.modalProps.toggle}>Linked Payments</ModalHeader>
                <ModalBody>
                    <GenericContentTable
                        data={paymentsAsync.result ?? []}
                        headers={tableHeaders}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}

export function PaymentLinkFieldFooter(props: IRegisterTableField<PaymentClaimLinksDefinition>) {
    const widget = useProjectWidget()
    const options = React.useMemo(() => widget.paymentClaims.map<PaymentClaimLink>(pc => ({ id: pc.id, commitmentId: pc.commitment.id, certificateNumber: pc.certificateNumber })), [widget.paymentClaims])

    return (
        <Field name={`cells.c-${props.col.id}`}>
            {({ input }) =>
                <GenericSelectDropdown
                    {...props}
                    isFocused={true}
                    onChange={input.onChange}
                    value={input.value}
                    getOptionLabel={paymentLabel}
                    getOptionValue={paymentValue}
                    options={options}
                />
            }
        </Field>
    )
}
