import React from 'react'
import { Button, ButtonProps } from 'reactstrap'

import { IModalProps } from '@src/hooks/useModal'

interface IProps<P, WP = ButtonProps> {
    modal: React.ComponentType<P & IModalProps>
    modalProps: P
    wrapper?: React.ElementType<WP>
    wrapperProps?: WP
}

interface IState {
    open: boolean
}

export default class ModalToggle<P extends object = object, WP = {}> extends React.PureComponent<IProps<P, WP>, IState> {
    constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

    public handleClick = () => {
        this.setState({ open: !this.state.open })
    }

    public render() {
        const { modal, children, modalProps, wrapperProps } = this.props
        const Modal = modal

        const Wrapper: React.ElementType = this.props.wrapper || Button

        return (
            <Wrapper onClick={this.handleClick} {...wrapperProps}>
                {children}
                <Modal isOpen={this.state.open} toggle={this.handleClick} {...modalProps} />
            </Wrapper>
        )
    }
}
