import React from 'react'
import { Form } from 'react-final-form'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import arrayMutators from 'final-form-arrays'

import CommitmentItemFormBody, { ICommitmentItemFormData } from '@src/components/costs/commitments/CommitmentItemFormBody'
import { IModalProps } from '@src/hooks/useModal'
import { CommitmentItemCreate, CommitmentItemUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { groupBy } from '@src/logic/utils/Collection'
import { Api } from '@src/types/api'
import { Commitment, CommitmentDefinition, CommitmentItem, CommitmentReportColumn, CostsOverview, StatusColumnValueMap } from '@src/types/costs'

interface IProps extends IModalProps {
    projectId: string
    costsOverview: CostsOverview
    commitmentDefinition: CommitmentDefinition
    commitment: Commitment
    commitmentItemToEdit?: CommitmentItem
    onItemCreatedOrUpdated?: () => void
}

export default function CommitmentItemModal({ projectId, costsOverview, commitmentDefinition, commitment, commitmentItemToEdit, onItemCreatedOrUpdated, ...modalProps }: IProps) {
    const initialValues = React.useMemo<Partial<ICommitmentItemFormData>>(() => {
        if (commitmentItemToEdit == null) {
            return {
                statusColumnValues: commitmentDefinition.statusDefinitions.map(sd => ({
                    status: sd.code,
                    columns: sd.columns.reduce(
                        (prev, curr) => (
                            {
                                ...prev,
                                [curr]: {
                                    value: 0,
                                    unit: '',
                                    quantity: 0,
                                    rate: 0,
                                    notes: ''
                                }
                            }),
                        {}
                    )
                }))
            }
        }

        const groupedStatusColumnValues = groupBy(commitmentItemToEdit.values, 'status')
        return {
            costCode: commitmentItemToEdit.costCode,
            otherPartyRef: commitmentItemToEdit.otherPartyReference,
            description: commitmentItemToEdit.description,
            name: commitmentItemToEdit.name,
            phase: costsOverview.phases.find(p => p.code === commitmentItemToEdit.phase),
            status: commitmentDefinition.statusDefinitions.find(s => s.code === commitmentItemToEdit.status),
            statusColumnValues: Object.keys(groupedStatusColumnValues).map(status => ({
                status,
                columns: groupedStatusColumnValues[status].reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.column]: {
                            ...curr.value
                        }
                    }),
                    {}
                )
            }))
        }
    }, [commitmentItemToEdit, commitmentDefinition])

    async function createOrUpdateItem(values: ICommitmentItemFormData) {
        const statuses = commitmentDefinition.statusDefinitions

        if (commitmentItemToEdit) {
            const update: Api.Request.CommitmentItemUpdate = {
                costCode: values.costCode.code,
                name: values.name,
                description: values.description,
                status: values.status.code,
                phase: values.phase?.code,
                otherPartyReference: values.otherPartyRef,
                values: statuses.map((sd) => {
                    const status = values.statusColumnValues.find(x => x.status === sd.code)
                    return sd.columns.map<StatusColumnValueMap<CommitmentReportColumn>>(col => ({
                        column: col,
                        status: sd.code,
                        value: { ...status.columns[col] }
                    }))
                }).reduce((a, b) => a.concat(b), []),
                tags: []
            }

            await CommitmentItemUpdate(projectId, commitment.id, commitmentItemToEdit.id, update)
            NotificationService.info('Updated item')
        } else {
            const newCommitmentItem: Api.Request.CommitmentItemNew = {
                name: values.name,
                costCode: values.costCode.code,
                otherPartyReference: values.otherPartyRef,
                description: values.description,
                phase: values.phase ? values.phase.code : null,
                status: values.status.code,
                tags: [],
                values: statuses.map((sd) => {
                    const scvValue = values.statusColumnValues.find(x => x.status === sd.code)
                    return sd.columns.map<StatusColumnValueMap<CommitmentReportColumn>>(col => ({
                        status: sd.code,
                        column: col,
                        value: { ...scvValue.columns[col] }
                    }))
                }).reduce((a, b) => a.concat(b), [])
            }
            await CommitmentItemCreate(projectId, commitment.id, newCommitmentItem)
            NotificationService.info('Created item')
        }

        onItemCreatedOrUpdated?.()
        modalProps.toggle()
    }

    return (
        <Form onSubmit={createOrUpdateItem} initialValues={initialValues} mutators={{ ...arrayMutators }}>
            {({ handleSubmit }) =>
                <Modal size="xl" {...modalProps}>
                    <ModalHeader toggle={modalProps.toggle}>{commitmentItemToEdit ? `Edit - ${commitmentItemToEdit.name}` : 'New item'}</ModalHeader>
                    <ModalBody>
                        <CommitmentItemFormBody
                            projectId={projectId}
                            costsOverview={costsOverview}
                            commitmentDefinition={commitmentDefinition}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleSubmit}>{commitmentItemToEdit != null ? 'Save' : 'Create'}</Button>
                    </ModalFooter>
                </Modal>
            }
        </Form>
    )
}
