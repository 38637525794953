import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useSelector } from 'react-redux'
import { Col, Container, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Table } from 'reactstrap'

import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { budgetStatusLabel, budgetStatusValue, costCodeLabel, costCodeValue, isPhaseLocked, phaseLabel, phaseValue, revisionLabel, revisionValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CostCodesList } from '@src/logic/http/Api'
import { groupBy } from '@src/logic/utils/Collection'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { BudgetReportColumn, BudgetStatusDefinition, CostCodeBrief, CostsOverview, Phase } from '@src/types/costs'
import { DocumentLink } from '@src/types/document'
import { RootState } from '@src/types/models'

interface IProps {
    costsOverview: CostsOverview
    projectId: string
    isAdjustment: boolean
}

export interface IBudgetItemFormData {
    name: string
    description: string
    costCode: CostCodeBrief
    phase?: Phase
    status: BudgetStatusDefinition
    date: Date
    statusColumnValues: {
        status: string
        unreported: number
        original: number
        adjustment: number
    }[]
    clientApprovalNumber: string
    clientApprovalDocuments: DocumentLink[]
}

export default function BudgetItemFormBody({ costsOverview, projectId, isAdjustment }: IProps) {
    const statuses = isAdjustment ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions
    const availableApprovalDocuments = useSelector<RootState, DocumentLink[]>(s => getActiveProjectWidgetState(s).revisions.map<DocumentLink>(r => ({
        documentId: r.documentId,
        name: r.name,
        revisionId: r.id
    })))
    const costCodesAsync = useAsyncAbortable(async (abortSignal, query) => {
        const response = await CostCodesList(projectId, 1, 200, { abortSignal })
        const grouped = groupBy(response.data, 'group')
        return Object.keys(grouped).reduce((prev, curr) => [...prev, { label: curr, options: grouped[curr] }], [])
    }, [''])

    return (
        <Container>
            <FormGroup row>
                <Col>
                    <Label>Name</Label>
                    <Field name="name" component={ValidatedInput} placeholder="Name" validate={required} />
                </Col>
            </FormGroup>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Cost Code</Label>
                        <Field name="costCode" component={ValidatedSelect} selectType="async" placeholder="Cost Code" validate={required} defaultOptions loadOptions={costCodesAsync.execute} getOptionValue={costCodeLabel} getOptionLabel={costCodeValue} cacheOptions />
                    </FormGroup>
                    <FormGroup>
                        <Label>Phase</Label>
                        <Field name="phase" component={ValidatedSelect} placeholder="Phase" options={costsOverview.phases} getOptionLabel={phaseLabel} getOptionValue={phaseValue} isClearable isOptionDisabled={isPhaseLocked} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Current Status</Label>
                        <Field name="status" component={ValidatedSelect} placeholder="Status" options={isAdjustment ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions} validate={required} getOptionLabel={budgetStatusLabel} getOptionValue={budgetStatusValue} isClearable />
                    </FormGroup>
                    <FormGroup>
                        <Label>Date</Label>
                        <Field name="date" component={ValidatedDatePicker} validate={isAdjustment ? required : null} />
                    </FormGroup>
                </Col>
            </Row>
            {isAdjustment &&
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Client Approval Number</Label>
                            <Field name="clientApprovalNumber" component={ValidatedInput} validate={required} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Client Approval Documents</Label>
                            <Field name="clientApprovalDocuments" component={ValidatedSelect} validate={required} options={availableApprovalDocuments} getOptionLabel={revisionLabel} getOptionValue={revisionValue} isMulti />
                        </FormGroup>
                    </Col>
                </Row>
            }
            <FormGroup row>
                <Col>
                    <Label>Description</Label>
                    <Field name="description" component={ValidatedInput} type="textarea" />
                </Col>
            </FormGroup>
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Unreported</th>
                                <th>Original</th>
                                <th>Adjustment</th>
                            </tr>
                        </thead>
                        <Field<BudgetStatusDefinition> name="status" subscription={{ value: true }}>
                            {({ input: { value: status } }) =>
                                <FieldArray name="statusColumnValues">
                                    {({ fields }) =>
                                        <tbody>
                                            {fields.map((f, idx) =>
                                                <tr key={f} style={{ background: status.code === statuses[idx].code ? '#ecf4f5' : 'initial' }}>
                                                    <td>{statuses[idx].code}</td>
                                                    <td>{statuses[idx].columns.includes(BudgetReportColumn.Unreported) ? <ValueInput name={`${f}.unreported`} /> : <span>N/A</span>}</td>
                                                    <td>{statuses[idx].columns.includes(BudgetReportColumn.Original) ? <ValueInput name={`${f}.original`} /> : <span>N/A</span>}</td>
                                                    <td>{statuses[idx].columns.includes(BudgetReportColumn.Adjustment) ? <ValueInput name={`${f}.adjustment`} /> : <span>N/A</span>}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    }
                                </FieldArray>
                            }
                        </Field>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

function ValueInput({ name }: { name: string }) {
    return (
        <InputGroup size="sm">
            <InputGroupAddon addonType="prepend" size="sm"><InputGroupText>$</InputGroupText></InputGroupAddon>
            <Field name={name} component={ValidatedCurrencyInput} bsSize="sm" />
        </InputGroup>
    )
}
