import React from 'react'

import { IconName, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import {
    faArchive,
    faAt,
    faBadgeDollar,
    faBan,
    faBell,
    faBoxUsd,
    faBracketsCurly,
    faBuilding,
    faCalendar,
    faChartLine,
    faChartPie,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleNotch,
    faClipboard,
    faClone,
    faCloudDownload,
    faCloudUpload,
    faCog,
    faCogs,
    faColumns,
    faCopy,
    faCreditCard,
    faDollarSign,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEnvelopeOpen,
    faEnvelopeOpenDollar,
    faEnvelopeOpenText,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileArchive,
    faFileAudio,
    faFileCertificate,
    faFileChartLine,
    faFileCheck,
    faFileCode,
    faFileExcel,
    faFileExport,
    faFileImage,
    faFileInvoiceDollar,
    faFilePdf,
    faFilePlus,
    faFilePowerpoint,
    faFileSignature,
    faFileTimes,
    faFileVideo,
    faFileWord,
    faFilter,
    faFont,
    faFrown,
    faGlobe,
    faHome,
    faIdCard,
    faInbox,
    faInfoCircle,
    faKey,
    faLayerPlus,
    faLink,
    faList,
    faListOl,
    faListUl,
    faLock,
    faLockAlt,
    faLockOpen,
    faLockOpenAlt,
    faLongArrowDown,
    faLongArrowUp,
    faMailBulk,
    faMapMarkerQuestion,
    faMinusCircle,
    faMinusSquare,
    faMobile,
    faMoneyCheckAlt,
    faPaperclip,
    faPause,
    faPencil,
    faPercentage,
    faPhone,
    faPlay,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faProjectDiagram,
    faPumpkin,
    faQuestionCircle,
    faSave,
    faSearch,
    faShareSquare,
    faSignOut,
    faSignature,
    faSortAmountDown,
    faSortAmountUp,
    faSortNumericDown,
    faSpinnerThird,
    faStar,
    faStarHalfAlt,
    faStream,
    faSuperscript,
    faSync,
    faTable,
    faTachometer,
    faTimes,
    faTrash,
    faUndo,
    faUpload,
    faUser,
    faUserCheck,
    faUserCog,
    faUserPlus,
    faUserSlash,
    faUsers,
    faUsersCog,
    faUsersCrown,
    faSlidersH,
    faSlidersV
} from '@systems-software/fontawesome-pro-light-svg-icons'

export interface IProps extends FontAwesomeIconProps, Omit<React.DetailedHTMLProps<React.SVGAttributes<SVGElement>, SVGElement>, 'mask' | 'transform' | keyof React.DOMAttributes<SVGElement>> {
    icon: IPFontawesomeIcon
}

export default class FA extends React.PureComponent<IProps> {
    public render() {
        const { icon, ...otherProps } = this.props

        return (
            <FontAwesomeIcon icon={['fal', icon]} {...otherProps} />
        )
    }
}

export function buildIconLibrary() {
    library.add(faArchive,
                faAt,
                faBadgeDollar,
                faBan,
                faBell,
                faBoxUsd,
                faBracketsCurly,
                faBuilding,
                faCalendar,
                faChartLine,
                faChartPie,
                faCheck,
                faCheckCircle,
                faChevronDown,
                faChevronLeft,
                faChevronRight,
                faChevronUp,
                faCircleNotch,
                faClipboard,
                faClone,
                faCloudDownload,
                faCloudUpload,
                faCog,
                faCogs,
                faColumns,
                faCopy,
                faCreditCard,
                faDollarSign,
                faDownload,
                faEdit,
                faEllipsisH,
                faEllipsisV,
                faEnvelope,
                faEnvelopeOpen,
                faEnvelopeOpenDollar,
                faEnvelopeOpenText,
                faExclamationCircle,
                faExclamationTriangle,
                faExternalLinkAlt,
                faEye,
                faEyeSlash,
                faFile,
                faFileAlt,
                faFileArchive,
                faFileAudio,
                faFileCertificate,
                faFileChartLine,
                faFileCheck,
                faFileCode,
                faFileExcel,
                faFileExport,
                faFileImage,
                faFileInvoiceDollar,
                faFilePdf,
                faFilePlus,
                faFilePowerpoint,
                faFileSignature,
                faFileTimes,
                faFileVideo,
                faFileWord,
                faFilter,
                faFont,
                faFrown,
                faGlobe,
                faHome,
                faIdCard,
                faInbox,
                faInfoCircle,
                faKey,
                faLayerPlus,
                faLink,
                faList,
                faListOl,
                faListUl,
                faLock,
                faLockAlt,
                faLockOpen,
                faLockOpenAlt,
                faLongArrowDown,
                faLongArrowUp,
                faMailBulk,
                faMapMarkerQuestion,
                faMinusCircle,
                faMinusSquare,
                faMobile,
                faMoneyCheckAlt,
                faPaperclip,
                faPause,
                faPencil,
                faPercentage,
                faPhone,
                faPlay,
                faPlus,
                faPlusCircle,
                faPlusSquare,
                faProjectDiagram,
                faPumpkin,
                faQuestionCircle,
                faSave,
                faSearch,
                faShareSquare,
                faSignOut,
                faSignature,
                faSortAmountDown,
                faSortAmountUp,
                faSortNumericDown,
                faSpinnerThird,
                faStar,
                faStarHalfAlt,
                faStream,
                faSuperscript,
                faSync,
                faTable,
                faTachometer,
                faTimes,
                faTrash,
                faUndo,
                faUpload,
                faUser,
                faUserCheck,
                faUserCog,
                faUserPlus,
                faUserSlash,
                faUsers,
                faUsersCog,
                faUsersCrown,
                faSlidersH,
                faSlidersV)
}

export type IPFontawesomeIcon = Extends<IconName,
    'archive' |
    'at' |
    'badge-dollar' |
    'ban' |
    'bell' |
    'box-usd' |
    'brackets-curly' |
    'building' |
    'calendar' |
    'chart-line' |
    'chart-pie' |
    'check' |
    'check-circle' |
    'chevron-down' |
    'chevron-left' |
    'chevron-right' |
    'chevron-up' |
    'circle-notch' |
    'clipboard' |
    'clone' |
    'cloud-download' |
    'cloud-upload' |
    'cog' |
    'cogs' |
    'columns' |
    'copy' |
    'credit-card' |
    'dollar-sign' |
    'download' |
    'edit' |
    'ellipsis-h' |
    'ellipsis-v' |
    'envelope' |
    'envelope-open' |
    'envelope-open-dollar' |
    'envelope-open-text' |
    'exclamation-circle' |
    'exclamation-triangle' |
    'external-link-alt' |
    'eye' |
    'eye-slash' |
    'file' |
    'file-alt' |
    'file-archive' |
    'file-audio' |
    'file-certificate' |
    'file-chart-line' |
    'file-check' |
    'file-code' |
    'file-excel' |
    'file-export' |
    'file-image' |
    'file-invoice-dollar' |
    'file-pdf' |
    'file-plus' |
    'file-powerpoint' |
    'file-signature' |
    'file-times' |
    'file-video' |
    'file-word' |
    'filter' |
    'font' |
    'frown' |
    'globe' |
    'home' |
    'id-card' |
    'inbox' |
    'info-circle' |
    'key' |
    'layer-plus' |
    'link' |
    'list' |
    'list-ol' |
    'list-ul' |
    'lock' |
    'lock-alt' |
    'lock-open' |
    'lock-open-alt' |
    'long-arrow-down' |
    'long-arrow-up' |
    'mail-bulk' |
    'map-marker-question' |
    'minus-circle' |
    'minus-square' |
    'mobile' |
    'money-check-alt' |
    'paperclip' |
    'pause' |
    'pencil' |
    'percentage' |
    'phone' |
    'play' |
    'plus' |
    'plus-circle' |
    'plus-square' |
    'project-diagram' |
    'pumpkin' |
    'question-circle' |
    'save' |
    'search' |
    'share-square' |
    'sign-out' |
    'signature' |
    'sort-amount-down' |
    'sort-amount-up' |
    'sort-numeric-down' |
    'spinner-third' |
    'star' |
    'star-half-alt' |
    'stream' |
    'superscript' |
    'sync' |
    'table' |
    'tachometer' |
    'times' |
    'trash' |
    'undo' |
    'upload' |
    'user' |
    'user-check' |
    'user-cog' |
    'user-plus' |
    'user-slash' |
    'users' |
    'users-cog' |
    'users-crown' |
    'sliders-h' | 
    'sliders-v'
>
