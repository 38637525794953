import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'

import BigNumber from 'bignumber.js'
import { compare } from 'fast-json-patch'

import FA from '@src/components/common/FontAwesomeIcon'
import { IOption, buildOptions } from '@src/components/common/Select'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { FieldPrefix } from '@src/components/forms/FieldPrefix'
import { required } from '@src/logic/forms/validation'
import { CommitmentDefinitionPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Api } from '@src/types/api'
import { CommitmentDefinition } from '@src/types/costs'

interface IProps {
    typeInUse: boolean
    projectId: string
    commitmentDefinition: CommitmentDefinition
    onUpdated?: () => void
}

export interface IEditCommitmentDefinitionFormData {
    name: string
    commitmentNumberDefinition: {
        prefix: string
        significantPlaces: number
    }
    paymentNumberDefinition: {
        prefix: string
        significantPlaces: number
    }
    defaultVariationAdditionsPercentage: number
    defaultVariationDeductionsPercentage: number
    defaultDocumentLinkCategories: IOption<string>[]
    defaultPaymentDocumentLinkCategories: IOption<string>[]
    hasInsurances: boolean
}

function normalizeCode(value?: string) {
    return value?.toUpperCase()
}

export default function EditCommitmentDefinitionForm({ commitmentDefinition, projectId, typeInUse }: IProps) {
    const initialValues = React.useMemo(() => ({
        name: commitmentDefinition.name,
        defaultVariationAdditionsPercentage: new BigNumber(commitmentDefinition.defaultVariationAdditions || 0).times(100).toNumber(),
        defaultVariationDeductionsPercentage: new BigNumber(commitmentDefinition.defaultVariationDeductions || 0).times(100).toNumber(),
        defaultDocumentLinkCategories: buildOptions(commitmentDefinition.defaultDocumentLinkCategories),
        defaultPaymentDocumentLinkCategories: buildOptions(commitmentDefinition.defaultPaymentDocumentLinkCategories),
        commitmentNumberDefinition: {
            prefix: commitmentDefinition.commitmentNumberDefinition.prefix,
            significantPlaces: commitmentDefinition.commitmentNumberDefinition.significantPlaces
        },
        paymentNumberDefinition: {
            prefix: commitmentDefinition.certificateNumberDefinition.prefix,
            significantPlaces: commitmentDefinition.certificateNumberDefinition.significantPlaces
        },
        hasInsurances: commitmentDefinition.hasInsurances
    }), [commitmentDefinition])

    async function handleSaveSettings(values: IEditCommitmentDefinitionFormData) {
        const cd = commitmentDefinition
        const original: Api.Request.CommitmentDefinitionUpdate = {
            name: cd.name,
            defaultVariationAdditions: cd.defaultVariationAdditions,
            defaultVariationDeductions: cd.defaultVariationDeductions,
            defaultDocumentLinkCategories: cd.defaultDocumentLinkCategories,
            defaultPaymentDocumentLinkCategories: cd.defaultPaymentDocumentLinkCategories,
            commitmentNumberDefinition: {
                prefix: cd.commitmentNumberDefinition.prefix,
                significantPlaces: cd.commitmentNumberDefinition.significantPlaces
            },
            certificateNumberDefinition: {
                prefix: cd.certificateNumberDefinition.prefix,
                significantPlaces: cd.certificateNumberDefinition.significantPlaces
            },
            statusDefinitions: cd.statusDefinitions,
            hasInsurances: cd.hasInsurances
        }
        const update: Api.Request.CommitmentDefinitionUpdate = {
            name: values.name,
            defaultVariationAdditions: new BigNumber(values.defaultVariationAdditionsPercentage).dividedBy(100).toNumber(),
            defaultVariationDeductions: new BigNumber(values.defaultVariationDeductionsPercentage).dividedBy(100).toNumber(),
            defaultDocumentLinkCategories: values.defaultDocumentLinkCategories?.map(x => x.value) ?? [],
            defaultPaymentDocumentLinkCategories: values.defaultPaymentDocumentLinkCategories?.map(x => x.value) ?? [],
            commitmentNumberDefinition: values.commitmentNumberDefinition,
            certificateNumberDefinition: values.paymentNumberDefinition,
            statusDefinitions: cd.statusDefinitions,
            hasInsurances: cd.hasInsurances
        }
        const patch = compare(original, update)

        try {
            await CommitmentDefinitionPatch(projectId, commitmentDefinition.code, patch)
            NotificationService.info('Saved settings')
        } catch {
            NotificationService.error('There was an error while saving settings')
        }
    }

    return (
        <Form onSubmit={handleSaveSettings} initialValues={initialValues}>
            {({ handleSubmit }) =>
                <Card>
                    <CardHeader className="d-flex">
                        <div className="flex-grow-1 flex-shrink-0"><FA icon="cog" /> Commitment Settings</div>
                        <Button color="primary" onClick={handleSubmit}>Save</Button>
                    </CardHeader>
                    <CardBody>
                        {typeInUse && <FormText className="mb-2"><span className="text-warning"><FA icon="exclamation-triangle" /> This commitment type is in use and cannot be modified.</span></FormText>}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Field name="name" component={ValidatedInput} validate={required} disabled={typeInUse} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <h5 className="pb-2 border-bottom">Item Numbering</h5>
                        <Row>
                            <FieldPrefix prefix="commitmentNumberDefinition">
                                <Col>
                                    <FormGroup>
                                        <Label>Item Prefix</Label>
                                        <Field name="prefix" component={ValidatedInput} validate={required} parse={normalizeCode} disabled={typeInUse} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Item Significant Places</Label>
                                        <Field name="significantPlaces" component={ValidatedInput} validate={required} type="number" min={2} max={10} disabled={typeInUse} />
                                    </FormGroup>
                                </Col>
                            </FieldPrefix>
                        </Row>
                        <h5 className="pb-2 border-bottom">Payment Numbering</h5>
                        <Row>
                            <FieldPrefix prefix="paymentNumberDefinition">
                                <Col>
                                    <FormGroup>
                                        <Label>Payment Claim Prefix</Label>
                                        <Field name="prefix" component={ValidatedInput} validate={required} parse={normalizeCode} disabled={typeInUse} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Payment Claim Significant Places</Label>
                                        <Field name="significantPlaces" component={ValidatedInput} validate={required} type="number" min={2} max={10} disabled={typeInUse} />
                                    </FormGroup>
                                </Col>
                            </FieldPrefix>
                        </Row>
                        <h5 className="pb-2 border-bottom">Other settings</h5>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Field name="hasInsurances" component={ValidatedCheckboxRadio} disabled={typeInUse} label="Has Insurances" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Additions</Label>
                                    <InputGroup>
                                        <Field name="defaultVariationAdditionsPercentage" component={ValidatedInput} type="number" disabled={typeInUse} />
                                        <InputGroupAddon addonType="append">%</InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Deductions</Label>
                                    <InputGroup>
                                        <Field name="defaultVariationDeductionsPercentage" component={ValidatedInput} type="number" disabled={typeInUse} />
                                        <InputGroupAddon addonType="append">%</InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Document Link Categories</Label>
                                    <Field name="defaultDocumentLinkCategories" component={ValidatedSelect} isMulti selectType="creatable" placeholder="Create category..." isDisabled={typeInUse} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Payment Document Link Categories</Label>
                                    <Field name="defaultPaymentDocumentLinkCategories" component={ValidatedSelect} isMulti selectType="creatable" placeholder="Create category..." isDisabled={typeInUse} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
        </Form>
    )
}
