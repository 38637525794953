import React from 'react'
import { Field } from 'react-final-form'

import CheckboxRadio from '@src/components/common/CheckboxRadio'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import useTouched from '@src/hooks/useTouched'
import { BoolDefinition } from '@src/types/metadata'

export default function BooleanField({ row, col, isFocused }: IRegisterTableField<BoolDefinition>) {
    const value = row.cells[col.id] as boolean
    const isTouched = useTouched(isFocused, [value])
    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            {isTouched ? <Field name={`cells.r-${row.id}.c-${col.id}`} labelClass="m-0" initialValue={value} component={ValidatedCheckboxRadio} validateFields={[]} /> : <CheckboxRadio labelClass="m-0" checked={value} />}
        </div>
    )
}

export function BooleanFieldFooter({ col }: IRegisterTableField<BoolDefinition>) {
    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <Field name={`cells.c-${col.id}`} labelClass="m-0" initialValue={false} component={ValidatedCheckboxRadio} />
        </div>
    )
}
