import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { loadProjectCostsOverview } from '@src/actions/project'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CostCodeForm, { ICostCodeFormData } from '@src/components/costs/settings/CostCodeForm'
import NoSearchResults from '@src/components/search/NoSearchResults'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import useBoolean from '@src/hooks/useBoolean'
import useLocalSearch from '@src/hooks/useLocalSearch'
import useUpdateEffect from '@src/hooks/useUpdateEffect'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { CostCodeCreate, CostCodeDelete, CostCodeUpdate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { CostCodeBrief } from '@src/types/costs'
import { RootState } from '@src/types/models'

const costCodeSearchProperties: IMappedSearchProperty<CostCodeBrief>[] = [
    {
        name: 'Code',
        searchKey: 'code',
        type: PropertyType.Text,
        path: c => c.code
    },
    {
        name: 'Group',
        searchKey: 'group',
        type: PropertyType.Text,
        path: c => c.group
    },
    {
        name: 'Name',
        searchKey: 'name',
        type: PropertyType.Text,
        path: c => c.name
    },
    {
        name: 'Client Code',
        searchKey: 'client_code',
        type: PropertyType.Text,
        path: c => c.clientCode
    },
    {
        name: 'Client Group',
        searchKey: 'client_group',
        type: PropertyType.Text,
        path: c => c.clientGroup
    },
    {
        name: 'Client Name',
        searchKey: 'client_name',
        type: PropertyType.Text,
        path: c => c.clientName
    }
]

const CostCodeSettingsSection: React.FC = () => {
    const isCreating = useBoolean(false)
    const [costCodeToEdit, setCostCodeToEdit] = React.useState<CostCodeBrief | undefined>()
    const projectId = useSelector<RootState, string>(s => s.projects.active.id)
    const costCodes = useSelector<RootState, CostCodeBrief[]>(s => s.projects.activeCostsOverview.costCodes)
    const dispatch = useDispatch()

    const doSearch = useLocalSearch(costCodes, costCodeSearchProperties)

    const searchSectionRef = useRef<SearchSectionType<CostCodeBrief, 'code'>>()

    useUpdateEffect(
        () => {
            searchSectionRef.current?.doSearch()
        },
    [costCodes])

    function editCostCode(costCode: CostCodeBrief) {
        setCostCodeToEdit(costCode)
        isCreating.setFalse()
    }

    async function deleteCostCode(costCode: CostCodeBrief) {
        await CostCodeDelete(projectId, costCode.code)
        dispatch(loadProjectCostsOverview())
    }

    function markCreating() {
        isCreating.setTrue()
        setCostCodeToEdit(undefined)
    }

    function clearCreateAndEdit() {
        isCreating.setFalse()
        setCostCodeToEdit(undefined)
    }

    async function handleSubmit(values: ICostCodeFormData) {
        try {
            if (costCodeToEdit) {
                await CostCodeUpdate(projectId, costCodeToEdit.code, {
                    group: values.group ? values.group.value : undefined,
                    name: values.name,
                    clientCostCode: {
                        group: values.clientGroup,
                        code: values.clientCode,
                        name: values.clientName
                    }
                })

                NotificationService.info('Cost code updated')
            } else {
                await CostCodeCreate(projectId, {
                    group: values.group ? values.group.value : undefined,
                    code: values.code,
                    name: values.name,
                    clientGroup: values.clientGroup,
                    clientCode: values.clientCode,
                    clientName: values.clientName
                })

                NotificationService.info('Cost code created')
            }
        } catch (err) {
            if (isAxiosError(err) && err.response && err.response.status === 400) {
                return buildFormErrorsFromModelState(values, err.response.data)
            }

            NotificationService.error('There was an issue updating the cost code.')
        }

        dispatch(loadProjectCostsOverview())
        clearCreateAndEdit()
    }

    const noCodesBody = (
        <div className="text-center mt-3">
            <div className="my-3"><FA size="3x" icon="list-ol" /></div>
            <p className="lead"><Button onClick={markCreating}>Create Cost Code</Button></p>
            <p>There are no cost codes for this project.</p>
        </div>
    )

    return (
        <SearchSection<CostCodeBrief, 'code'>
            onSearch={doSearch}
            ref={searchSectionRef}
            noItemsFoundMessage={costCodes.length ? <NoSearchResults objectName="cost codes" /> : noCodesBody}
            extraSearchBarElements={[
                {
                    position: 'before',
                    element: _ => <Button onClick={markCreating}><FA icon="plus-circle" /> Create Cost Code</Button>
                }
            ]}
            searchAssistantProperties={costCodeSearchProperties}
            defaultPerPage={50}
            itemIdKey="code"
            headers={[
                {
                    name: 'Group',
                    accessor: 'group',
                    sortable: true,
                    sortKey: 'group'
                },
                {
                    name: 'Code',
                    accessor: 'code',
                    sortable: true,
                    sortKey: 'code'
                },
                {
                    name: 'Name',
                    sortKey: 'name',
                    sortable: true,
                    accessor: 'name'
                },
                {
                    name: 'Client Group',
                    overrideRenderer: cc => cc.clientGroup
                },
                {
                    name: 'Client Code',
                    overrideRenderer: cc => cc.clientCode
                },
                {
                    name: 'Client Name',
                    overrideRenderer: cc => cc.clientName
                },
                {
                    name: 'Actions',
                    headerWrapperClass: 'text-right',
                    noSmallHeader: true,
                    overrideRenderer: (costCode, rowIdx) => (
                        <div className="justify-content-end text-right selectable-content__actions">
                            <TooltipLinkAction id={`edit-costcode-${rowIdx}`} tooltip="Edit" data={costCode} onClick={editCostCode}><FA icon="pencil" /></TooltipLinkAction>
                            <TooltipLinkAction id={`remove-costcode-${rowIdx}`} tooltip="Remove" data={costCode} onClick={deleteCostCode}><FA icon="trash" /></TooltipLinkAction>
                        </div>
                    )
                }
            ]}
        >
            <CostCodeForm
                isOpen={!!costCodeToEdit || isCreating.value}
                toggle={clearCreateAndEdit}
                projectId={projectId}
                existingCode={costCodeToEdit}
                onSubmit={handleSubmit}
            />
        </SearchSection>
    )
}

export default CostCodeSettingsSection
