import { AbstractParseTreeVisitor } from 'antlr4ts/tree'
import compromise from 'compromise'
import compromiseDates from 'compromise-dates'
import compromiseNumbers from 'compromise-numbers'
import { flatMap } from 'lodash'

import { AnyEqContext, EqContext, ExprANDContext, ExprORContext, FilterContext, GtContext, LtContext, NullContext, StringContext, TextContext, ValueContext } from '@src/components/search/IQL/IQLFilterParser'
import type { IQLFilterVisitor } from '@src/components/search/IQL/IQLFilterVisitor'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import { regexEscape } from '@src/logic/utils/Strings'

const nlpEx = compromise.extend(compromiseDates).extend(compromiseNumbers)

type Predicate<T> = (item: T) => boolean

export enum FilterOperation {
    Text,
    Eq,
    Gt,
    Lt
}

interface IPropertyResolverMap<T> {
    [property: string]: (op: FilterOperation, value: string) => Predicate<T>
}

export function getParserForType(propertyType: PropertyType) {
    switch (propertyType) {
        case PropertyType.Text:
        case PropertyType.Select:
            return s => s
        case PropertyType.Number:
            return s => Number(s)
        case PropertyType.Date:
            return s => buildDateRange(s)
        case PropertyType.Boolean:
            return (s) => {
                if (s == null) return null

                if (typeof s !== 'string') {
                    return null
                }

                if (/true/i.test(s) || /yes/i.test(s)) return true
                if (/false/i.test(s) || /no/i.test(s)) return false

                return null
            }
        default:
            return s => s
    }
}

function buildDateRange(date: string): [Date?, Date?] {
    try {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        const results = nlpEx(date).dates({ timezone: tz } as any).json()

        if (results?.length === 0) return [undefined, undefined]

        return [results[0].start ? new Date(results[0].start) : undefined, results[0].end ? new Date(results[0].end) : undefined]
    } catch {
        return [undefined, undefined]
    }
}

function withinDateRange(value: Date, range: [Date?, Date?]) {
    const from = range[0]
    const to = range[1]

    if (from === undefined) return false

    if (to === undefined) {
        return value.getTime() === from.getTime()
    }

    const reverseOrder = from > to

    return (
        reverseOrder ? value > to : value >= from
    ) && (
        reverseOrder ? value < from : value <= to
    )
}

export function simplePropertyResolver<T, R>(valuePath: (item: T) => R, valueParser: (value: string) => R): ((op: FilterOperation, value: string) => Predicate<T>) {
    return (op, value) => {
        const parsedValue = valueParser(value)
        switch (op) {
            case FilterOperation.Text:
                return (x) => {
                    const val = valuePath(x) as unknown as string
                    if (val == null) return false
                    return val.search(RegExp(`\\b${regexEscape(parsedValue as unknown as string)}\\b`, 'i')) > -1
                }
            case FilterOperation.Eq:
                return (x) => {
                    try {
                        const val = valuePath(x)
                        if (parsedValue == null) return val == null
                        if (val instanceof Date) return withinDateRange(val, parsedValue as unknown as [Date?, Date?])
                        if (typeof val === 'number') return val === parsedValue
                        if (typeof val === 'string') return val.search(RegExp(regexEscape(parsedValue as unknown as string), 'i')) > -1
                        return val === parsedValue
                    } catch {
                        return false
                    }
                }
            case FilterOperation.Gt:
                return (x) => {
                    try {
                        const val = valuePath(x)
                        if (val == null) return false
                        if (val instanceof Date) return val > (parsedValue as any).end
                        return val as any > value
                    } catch {
                        return false
                    }
                }
            case FilterOperation.Lt:
                return (x) => {
                    try {
                        const val = valuePath(x)
                        if (val == null) return false
                        if (val instanceof Date) return val < (parsedValue as any).start
                        return val as any < parsedValue
                    } catch {
                        return false
                    }
                }
        }
    }
}

export default class InMemoryFilterVisitor<T extends object> extends AbstractParseTreeVisitor<Predicate<T>> implements IQLFilterVisitor<Predicate<T>> {
    private readonly propertyResolverMap: IPropertyResolverMap<T>
    private readonly textProperties: string[] = []
    private readonly words: string[] = []

    constructor(searchProperties: IMappedSearchProperty<T>[]) {
        super()
        this.textProperties = searchProperties.filter(x => x.type === PropertyType.Text || x.type === PropertyType.Select).map(x => x.searchKey)
        this.propertyResolverMap = searchProperties.reduce(
            (prev, curr) => (
                {
                    ...prev,
                    [curr.searchKey]: simplePropertyResolver(curr.path, getParserForType(curr.type))
                }),
            {})
    }

    protected defaultResult(): Predicate<T> {
        return this.fail
    }

    public visitFilter = (ctx: FilterContext): Predicate<T> => {
        const filterPredicate = this.visit(ctx.expr())
        if (this.textProperties.length === 0 || this.words.length === 0) return filterPredicate

        const textFilters = flatMap(this.words, w => this.textProperties.map(p => this.propertyResolverMap[p](FilterOperation.Text, w)))
        return (item: T) => filterPredicate(item) && textFilters.some(f => f(item))
    }

    public visitText = (ctx: TextContext): Predicate<T> => {
        const ctxWords = ctx.children.map(c => c.text)
        ctxWords.forEach((word) => {
            const trimmed = word
            if (!this.words.includes(trimmed)) this.words.push(trimmed)
        })
        return this.pass
    }

    public visitExprAND = (ctx: ExprANDContext): Predicate<T> => {
        return (item: T) => this.visit(ctx.expr(0))(item) && this.visit(ctx.expr(1))(item)
    }

    public visitExprOR = (ctx: ExprORContext): Predicate<T> => {
        return (item: T) => this.visit(ctx.expr(0))(item) || this.visit(ctx.expr(1))(item)
    }

    public visitExprNOT = (ctx: ExprANDContext): Predicate<T> => {
        return (item: T) => !this.visit(ctx.expr(0))(item)
    }

    public visitEq = (ctx: EqContext): Predicate<T> => {
        return this.buildFilter(FilterOperation.Eq, ctx.property_path().text, ctx.value())
    }

    public visitGt = (ctx: GtContext): Predicate<T> => {
        return this.buildFilter(FilterOperation.Gt, ctx.property_path().text, ctx.value())
    }

    public visitLt = (ctx: LtContext): Predicate<T> => {
        return this.buildFilter(FilterOperation.Lt, ctx.property_path().text, ctx.value())
    }

    public visitAnyEq = (ctx: AnyEqContext): Predicate<T> => {
        return ctx.value().reduce<Predicate<T>>((prev: Predicate<T>, valueCtx) => (item: T) => prev(item) || this.buildFilter(FilterOperation.Eq, ctx.property_path().text, valueCtx)(item), _ => false)
    }

    private readonly buildFilter = (operation: FilterOperation, propertyPath: string, valueCtx: ValueContext): Predicate<T> => {
        if (this.propertyResolverMap[propertyPath.toLowerCase()] == null) return this.pass

        let value: string

        if (valueCtx instanceof NullContext) {
            value = null
        } else if (valueCtx instanceof StringContext) {
            value = this.extractStringValue(valueCtx.text)
        } else {
            value = valueCtx.text
        }

        return this.propertyResolverMap[propertyPath.toLowerCase()](operation, value)
    }

    private extractStringValue(toExtract: string) {
        // First remove surrounding quotes
        const firstChar = toExtract[0]
        // If it doesn't start with a quote, return early
        if (firstChar !== '\'' && firstChar !== '"') return toExtract
        let extractedString = toExtract.substring(1, toExtract.length - 1)
        // Replace any escaped internal single quotes
        extractedString = extractedString.replace(`\\${firstChar}`, `${firstChar}`)
        return extractedString
    }

    private fail(item: T) {
        return false
    }

    private pass(item: T) {
        return true
    }
}
