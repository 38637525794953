import React from 'react'
import { Card } from 'reactstrap'

import LoadingMessage from '@src/components/common/LoadingMessage'

export default class extends React.PureComponent {
    public render() {
        return (
            <Card body>
                <LoadingMessage />
            </Card>
        )
    }
}
