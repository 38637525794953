import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { DropResult } from 'react-beautiful-dnd'
import { RouteComponentProps } from 'react-router'
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap'

import { Operation as PatchOperation } from 'fast-json-patch'

import CheckboxRadio from '@src/components/common/CheckboxRadio'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CommitmentStatusModal from '@src/components/costs/settings/CommitmentStatusModal'
import EditCommitmentDefinitionForm from '@src/components/costs/settings/EditCommitmentDefinitionForm'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import GenericTable from '@src/components/table/GenericTable'
import useBoolean from '@src/hooks/useBoolean'
import useProject from '@src/hooks/useProject'
import useProjectCosts from '@src/hooks/useProjectCosts'
import useUpdateEffect from '@src/hooks/useUpdateEffect'
import { CommitmentDefinitionPatch, CommitmentsListByType } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { reorder } from '@src/logic/utils/Collection'
import { CommitmentReportColumnName, CommitmentStatusDefinition } from '@src/types/costs'

interface IProps extends RouteComponentProps<{ key: string }> { }

interface IEditingState {
    selected: CommitmentStatusDefinition | null
    action: 'creating' | 'editing' | 'deleting' | null
}

type Actions = {
    type: 'edit'
    status: CommitmentStatusDefinition
} | {
    type: 'delete'
    status: CommitmentStatusDefinition
} | {
    type: 'create'
} | {
    type: 'clear'
}

function editingStateReducer(state: IEditingState, action: Actions): IEditingState {
    switch (action.type) {
        case 'clear':
            return { selected: null, action: null }
        case 'create':
            return { selected: null, action: 'creating' }
        case 'edit':
            return { selected: action.status, action: 'editing' }
    }
}

const initialEditingState: IEditingState = { action: null, selected: null }

export default function CommitmentDefinitionSettingsSection({ match: { params: { key: definitionKey } } }: IProps) {
    const [project] = useProject()
    const [costsOverview, reloadCostsOverview] = useProjectCosts()
    const existingDefinitions = React.useMemo(() => costsOverview.commitmentDefinitions.filter(cd => cd.code !== definitionKey), [definitionKey, costsOverview])
    const commitmentDefinition = React.useMemo(() => costsOverview.commitmentDefinitions.find(cd => cd.code === definitionKey), [definitionKey, costsOverview])
    const [editingState, dispatchEditingState] = React.useReducer(editingStateReducer, initialEditingState)
    const { value: statusesLocked, toggle: toggleLockStatuses } = useBoolean(true)
    const [updatedStatuses, setUpdatedStatuses] = React.useState<CommitmentStatusDefinition[] | null>()

    const hasCommitmentsAsync = useAsyncAbortable(async (abortSignal) => {
        const response = await CommitmentsListByType(project.id, commitmentDefinition.code, undefined, undefined, 1, 1, { abortSignal })
        return response.data.commitments.length > 0
    }, [])

    useUpdateEffect(() => setUpdatedStatuses(null), [costsOverview])

    function setEditing(status: CommitmentStatusDefinition) {
        dispatchEditingState({ type: 'edit', status: status })
    }

    function setCreating() {
        dispatchEditingState({ type: 'create' })
    }

    function setDeleting(status: CommitmentStatusDefinition) {
        dispatchEditingState({ type: 'delete', status })
    }

    function clearEditingState() {
        dispatchEditingState({ type: 'clear' })
    }

    function getStatusDefinitionId(status: CommitmentStatusDefinition) {
        return status.code
    }

    async function handleStatusReorder(status: CommitmentStatusDefinition, result: DropResult) {
        if (result.destination == null || result.source.index === result.destination.index) return

        const patch: PatchOperation = {
            op: 'move',
            from: `/statusDefinitions/${result.source.index}`,
            path: `/statusDefinitions/${result.destination.index}`
        }

        setUpdatedStatuses(reorder(commitmentDefinition.statusDefinitions, result.source.index, result.destination.index))

        try {
            await CommitmentDefinitionPatch(project.id, commitmentDefinition.code, [patch])
            reloadCostsOverview()
        } catch (e) {
            NotificationService.error('An error occurred when attempting to reorder the status')
        }
    }

    async function deleteStatus() {
        const status = editingState.selected
        const index = commitmentDefinition.statusDefinitions.findIndex(s => s.code === status.code)

        try {
            await CommitmentDefinitionPatch(project.id, commitmentDefinition.code, [{
                op: 'test', path: `/statusDefinitions/${index}/code`, value: status.code
            }, {
                op: 'remove', path: `/statusDefinitions/${index}`
            }])
            NotificationService.info(`Removed status '${status.code}'`)
        } catch {
            NotificationService.error(`There was an error while trying to delete status '${status.code}'`)
        } finally {
            reloadCostsOverview()
            clearEditingState()
        }
    }

    return (
        <Container fluid>
            <Row className="my-3">
                <Col xl={6} className="mb-3">
                    {hasCommitmentsAsync.result != null && <EditCommitmentDefinitionForm
                        projectId={project.id}
                        typeInUse={hasCommitmentsAsync.result}
                        commitmentDefinition={commitmentDefinition}
                        onUpdated={reloadCostsOverview}
                    />}
                </Col>
                <Col xl={6}>
                    <Card>
                        <CardHeader className="d-flex">
                            <div className="flex-grow-1 flex-shrink-0">Statuses</div>
                            <Button className="mr-2" color="dark" onClick={toggleLockStatuses}><FA icon={statusesLocked ? 'lock' : 'lock-open'} /></Button>
                            <Button color="primary" onClick={setCreating}><FA icon="plus" /> Add Status</Button>
                        </CardHeader>
                        <GenericTable<CommitmentStatusDefinition, never>
                            className="mb-0"
                            data={updatedStatuses || commitmentDefinition.statusDefinitions}
                            getRowId={getStatusDefinitionId}
                            onReorder={(!statusesLocked && updatedStatuses == null) ? handleStatusReorder : null}
                            headers={[
                                {
                                    accessor: 'code',
                                    name: 'Status'
                                },
                                {
                                    name: 'Columns',
                                    overrideRenderer: defintion => defintion.columns.map(c => CommitmentReportColumnName[c]).join(', ')
                                },
                                {
                                    name: 'Allow Claim',
                                    overrideRenderer: definition =>
                                        <div className="d-flex justify-content-center">
                                            <CheckboxRadio disabled checked={definition.allowClaim} />
                                        </div>
                                },
                                {
                                    name: 'Unlocked Budget',
                                    overrideRenderer: definition =>
                                        <div className="d-flex justify-content-center">
                                            <CheckboxRadio disabled checked={definition.allowInUnlockedBudget} />
                                        </div>
                                },
                                {
                                    name: 'Require Other Party',
                                    overrideRenderer: definition =>
                                        <div className="d-flex justify-content-center">
                                            <CheckboxRadio disabled checked={definition.requireOtherParty} />
                                        </div>
                                },
                                {
                                    name: 'Actions',
                                    overrideRenderer: (definition, rowIdx) => (
                                        <div className="justify-content-end text-right selectable-content__actions">
                                            <TooltipLinkAction id={`edit-commitdef-${rowIdx}`} tooltip="Edit" data={definition} onClick={setEditing}><FA icon="pencil" /></TooltipLinkAction>
                                            <TooltipLinkAction id={`remove-commitdef-${rowIdx}`} tooltip="Remove" data={definition} onClick={setDeleting}><FA icon="trash" /></TooltipLinkAction>
                                        </div>
                                    )
                                }
                            ]}
                        />
                    </Card>
                </Col>
            </Row>
            <CommitmentStatusModal
                isOpen={editingState.action === 'creating' || editingState.action === 'editing'}
                statusToEdit={editingState.selected}
                toggle={clearEditingState}
                projectId={project.id}
                commitmentDefinition={commitmentDefinition}
                existingCommitmentDefinitions={existingDefinitions}
            />
            <ConfirmationModal
                isOpen={editingState.action === 'deleting'}
                toggle={clearEditingState}
                message={<span>Are you sure you want to remove the status <strong>{editingState.selected?.code}</strong>? This action <strong>cannot</strong> be undone.</span>}
                header="Delete Status"
                confirmAction="Yes, Delete Status"
                onConfirm={deleteStatus}
                danger
            />
        </Container>
    )
}
