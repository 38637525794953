import { ReferenceModelMethod, ValueModelMethod } from '@src/logic/autotexts/DropkiqSchema'
import { IMetadataDefinition, MetadataTypes, PaymentClaimLinksDefinition } from '@src/types/metadata'

export interface DropkiqContext {
    [item: string]: ValueModelMethod | ReferenceModelMethod
}

export function BuildMetadataContext(metadataDefinitions: IMetadataDefinition[]): DropkiqContext {
    const autotextsContext: DropkiqContext = {}

    for (const definition of metadataDefinitions) {
        switch (definition.type) {
            case MetadataTypes.Bool:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::Boolean',
                    foreign_table_name: null,
                    hint: definition.description
                }
                break
            case MetadataTypes.Numeric:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::Numeric',
                    foreign_table_name: null,
                    hint: definition.description
                }
                break
            case MetadataTypes.Text:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::Text',
                    foreign_table_name: null,
                    hint: definition.description
                }
                break
            case MetadataTypes.Date:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::DateTime',
                    foreign_table_name: null,
                    hint: definition.description
                }
                break
            case MetadataTypes.Select:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::YAML',
                    foreign_table_name: null,
                    hint: definition.description
                }
                break
            case MetadataTypes.CommitmentLinks:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::HasMany',
                    foreign_table_name: 'CommitmentLinks',
                    hint: definition.description
                }
                break
            case MetadataTypes.CompanyLinks:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::HasMany',
                    foreign_table_name: 'CompanyLinks',
                    hint: definition.description
                }
                break
            case MetadataTypes.CompanyProperty:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::HasMany',
                    foreign_table_name: 'CompanyProperties',
                    hint: definition.description
                }
                break
            case MetadataTypes.DocumentLinks:
                autotextsContext[definition.key] = {
                    type: 'ColumnTypes::HasMany',
                    foreign_table_name: 'DocumentLinks',
                    hint: definition.description
                }
                break
            case MetadataTypes.PaymentClaimLinks:
                autotextsContext[definition.key] = {
                    type: (definition as PaymentClaimLinksDefinition).options.limit === 1 ? 'ColumnTypes::HasOne' : 'ColumnTypes::HasMany',
                    foreign_table_name: 'PaymentClaims',
                    hint: definition.description
                }
                break
            default:
                break
        }
    }

    return autotextsContext
}

export function BuildCommunicationContext(autotexts: IMetadataDefinition[]): DropkiqContext {
    const context: DropkiqContext = {
        ...BuildMetadataContext(autotexts),
        Communication: {
            type: 'ColumnTypes::HasOne',
            foreign_table_name: 'Communications',
            hint: 'The current communication'
        },
        Project: {
            type: 'ColumnTypes::HasOne',
            foreign_table_name: 'Projects',
            hint: 'The current project'
        }
    }

    return context
}
