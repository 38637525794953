import React from 'react'

import CostValue from '@src/components/costs/common/CostValue'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import { localShortDate } from '@src/logic/utils/Date'
import { PaymentEntityProps, PaymentPropertyDefinition } from '@src/types/metadata'

export default function PaymentPropertyField({ row, col }: IRegisterTableField<PaymentPropertyDefinition>) {
    const rawValue = row.cells[col.id] as string | Date | number | null
    const formattedValue = React.useMemo(() => {
        switch (col.data.metadataDefinition.options.property) {
            case PaymentEntityProps.CertificateNumber:
            case PaymentEntityProps.ClaimReference:
            case PaymentEntityProps.InvoiceReference:
            case PaymentEntityProps.Notes:
            case PaymentEntityProps.Status:
                return <span className="register__inactive-text-num-span">{rawValue}</span>
            case PaymentEntityProps.ClaimDate:
            case PaymentEntityProps.InvoiceDate:
                return <span className="register__inactive-date-span">{localShortDate(new Date(rawValue))}</span>
            case PaymentEntityProps.TotalCertified:
            case PaymentEntityProps.TotalCertifiedIncGst:
            case PaymentEntityProps.TotalClaimed:
            case PaymentEntityProps.TotalPaid:
                return rawValue != null && typeof rawValue === 'number' ? <span className="register__inactive-text-num-span"><CostValue value={rawValue} /></span> : null
            default:
                return rawValue
        }
    },
    [rawValue])

    return (
        <>{formattedValue}</>
    )
}
