import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { Button, Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { groupBy, throttle } from 'lodash'

import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import useConstant from '@src/hooks/useConstant'
import { IModalProps } from '@src/hooks/useModal'
import { commitmentLabel, commitmentValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CommitmentsList, PaymentClaimCreate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { Api } from '@src/types/api'
import { CommitmentReference, CostsOverview, PaymentClaim } from '@src/types/costs'

async function getCommitments(abortSignal: AbortSignal, query: string, projectId: string, costsOverview: CostsOverview) {
    const commitments = (await CommitmentsList(projectId, `parent_id: null AND ${query.split(' ').map(x => `name: "${x.replace('"', '\\"')}"`).join(' ')}`, undefined, 1, 50, { abortSignal })).data.commitments

    const grouped = groupBy<CommitmentReference>(commitments, c => c.type)
    return Object.keys(grouped).map(g => ({
        label: `${g} - ${costsOverview.commitmentDefinitions.find(cd => cd.code === g).name}`,
        options: grouped[g]
    }))
}

interface IProps extends IModalProps {
    projectId: string
    costsOverview: CostsOverview
    commitmentId?: string
    onCreated?: (payment: PaymentClaim) => void
}

export interface IPaymentClaimFormData {
    commitment: CommitmentReference
    value: number
    notes: string
    invoiceDate: Date
    invoiceReference: string
    claimDate: Date
    claimReference: string
}

export default function NewPaymentForm({ costsOverview, onCreated, projectId, commitmentId, ...modalProps }: IProps) {
    const throttleCommitmentsList = useConstant(() => throttle(getCommitments, 300))
    const commitmentsAsync = useAsyncAbortable((ct, query) => throttleCommitmentsList(ct, query, projectId, costsOverview), [''])

    async function handleSubmit(values: IPaymentClaimFormData) {
        const newPaymentClaim: Api.Request.PaymentClaimNew = {
            claimDate: values.claimDate,
            claimReference: values.claimReference,
            value: values.value,
            invoiceDate: values.invoiceDate,
            invoiceReference: values.invoiceReference,
            notes: values.notes
        }
        try {
            const result = await PaymentClaimCreate(projectId, commitmentId ?? values.commitment.id, newPaymentClaim)
            onCreated?.(result.data)
            modalProps.toggle()
        } catch (e) {
            if (isAxiosError(e) && e.response?.status === 400) {
                    return NotificationService.error(e.response.data.message)
            }
            NotificationService.error('Cannot create payment claim')
        }
    }

    return (
        <Modal {...modalProps}>
            <Form onSubmit={handleSubmit}>
                {formProps =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>New Payment Claim</ModalHeader>
                        <ModalBody>
                            {!commitmentId && <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="newPaymentForm-commitment">Commitment</Label>
                                        <Field
                                            id="newPaymentForm-commitment"
                                            name="commitment"
                                            validate={required}
                                            component={ValidatedSelect}
                                            defaultOptions
                                            selectType="async"
                                            loadOptions={commitmentsAsync.execute}
                                            getOptionLabel={commitmentLabel}
                                            getOptionValue={commitmentValue}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>}
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="newPaymentForm-invoiceDate">Invoice Date</Label>
                                        <Field id="newPaymentForm-invoiceDate" name="invoiceDate" component={ValidatedDatePicker} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newPaymentForm-invoiceReference">Invoice Reference</Label>
                                        <Field id="newPaymentForm-invoiceReference" name="invoiceReference" component={ValidatedInput} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="newPaymentForm-claimDate">Claim Date</Label>
                                        <Field id="newPaymentForm-claimDate" name="claimDate" component={ValidatedDatePicker} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newPaymentForm-claimReference">Claim Reference</Label>
                                        <Field id="newPaymentForm-claimReference" name="claimReference" component={ValidatedInput} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="newPaymentForm-value">Value</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><InputGroupText>$</InputGroupText></InputGroupAddon>
                                            <Field id="newPaymentForm-value" name="value" component={ValidatedCurrencyInput} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newPaymentForm-notes">Notes</Label>
                                        <Field id="newPaymentForm-notes" name="notes" type="textarea" component={ValidatedInput} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={formProps.handleSubmit}>Save</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
