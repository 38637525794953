import { UserAccess } from '@src/types/access'
import { Approval, DocumentGroupLink, DocumentLink } from '@src/types/document'
import { PrincipalBrief } from '@src/types/principal'
import { TrackingEntry } from '@src/types/tracking'

export interface TransmittalAddress {
    id: string
    email: string
    name?: string
}

export interface TransmittalLink {
    id: string
    subject: string
}

export enum TransmittalStatus {
    Draft = 'draft',
    Published = 'published',
    Sent = 'sent',
    Rejected = 'rejected'
}

export interface TransmittalBrief {
    id: string
    projectId: string
    createdBy: PrincipalBrief
    createdDate: Date
    updatedBy: PrincipalBrief
    updatedDate: Date
    tags: string[]
    status: TransmittalStatus
    reference: string
    approval: Approval
    subject: string
    to: TransmittalAddress[]
    cc: TransmittalAddress[]
    bcc?: TransmittalAddress[]
    revisions: DocumentLink[]
    documentGroups: DocumentGroupLink[]
    myAccess: UserAccess
}

export interface TransmittalSettings {
    attachDocumentsToEmail: boolean
}

export interface Transmittal {
    id: string
    projectId: string
    createdBy: PrincipalBrief
    createdDate: Date
    updatedBy: PrincipalBrief
    updatedDate: Date
    tags: string[]
    subject: string
    reason: string
    responseDate: Date
    responseType: string
    status: TransmittalStatus
    reference: string
    approval: Approval
    to: TransmittalAddress[]
    cc: TransmittalAddress[]
    bcc?: TransmittalAddress[]
    revisions: DocumentLink[]
    documentGroups: DocumentGroupLink[]
    settings: TransmittalSettings
    body: string
    tracking: TrackingEntry[]
    myAccess: UserAccess
}

export interface TransmittalNew {
    subject: string
    tags: string[]
    body: string
    reason: string
    responseDate: Date
    responseType: string
    requestedApprover: string
    approvalRequestComments: string
    to: string[]
    cc: string[]
    bcc: string[]
    linkedRevisions: string[]
    linkedDocumentGroups: string[]
    settings?: TransmittalSettings
}

export interface TransmittalUpdate {
    subject: string
    tags: string[]
    body: string
    reason: string
    responseDate: Date
    responseType: string
    requestedApprover: string
    approvalRequestComments: string
    to: string[]
    cc: string[]
    bcc: string[]
    linkedRevisions: string[]
    linkedDocumentGroups: string[]
    settings?: TransmittalSettings
}
