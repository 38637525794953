import React from 'react'
import { Field, Form } from 'react-final-form'
import { Form as BootstrapForm, Button, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { FormApi } from 'final-form'

import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedDropzoneSandboxInput from '@src/components/common/ValidatedDropzoneSandboxInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalProps } from '@src/hooks/useModal'
import { composeValidators, required, uploadComplete } from '@src/logic/forms/validation'
import { Insurance } from '@src/types/costs'
import { Sandbox } from '@src/types/sandbox'

export interface IInsuranceFormValues {
    policyNumber: string
    type: IOption<string>
    insurer: string
    expiryDate: Date
    annualOccurrenceValue: number
    annualAggregateValue: number
    description: string
    upload: Sandbox.Upload
}

interface IProps extends IModalProps {
    insurance: Insurance
    onSubmit: (values: IInsuranceFormValues, form: FormApi<IInsuranceFormValues>) => Promise<void>
}

const DefaultInsuranceTypes: IOption<string>[] = [
    {
        value: 'Professional Indemnity',
        label: 'Professional Indemnity'
    },
    {
        value: 'Public Liability',
        label: 'Public Liability'
    },
    {
        value: 'Workers Compensation',
        label: 'Workers Compensation'
    }
]

export default function InsuranceModalForm({ insurance, onSubmit, ...modalProps }: IProps) {
    return (
        <Modal {...modalProps}>
            <Form<IInsuranceFormValues>
                initialValues={insurance && {
                    annualAggregateValue: insurance.annualAggregateValue,
                    annualOccurrenceValue: insurance.annualOccurrenceValue,
                    description: insurance.description,
                    expiryDate: insurance.expiryDate,
                    insurer: insurance.insurer,
                    policyNumber: insurance.policyNumber,
                    type: {
                        label: insurance.type,
                        value: insurance.type
                    },
                    upload: undefined
                }}
                subscription={{ submitting: true, pristine: true }}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, submitting }) => {
                    return (
                        <>
                            <ModalHeader toggle={modalProps.toggle}>{insurance ? 'Edit Insurance' : 'New Insurance'}</ModalHeader>
                            <ModalBody>
                                <BootstrapForm onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label>Policy Number</Label>
                                        <Field name="policyNumber" component={ValidatedInput} validate={required} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Type</Label>
                                        <Field name="type" component={ValidatedSelect} validate={required} selectType="creatable" options={DefaultInsuranceTypes} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Insurer</Label>
                                        <Field<string> name="insurer" component={ValidatedInput} validate={required} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Expiry Date</Label>
                                        <Field name="expiryDate" component={ValidatedDatePicker} validate={required} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Annual Occurrence Value</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="dollar-sign" /></InputGroupText></InputGroupAddon>
                                            <Field name="annualOccurrenceValue" component={ValidatedCurrencyInput} validate={required} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Annual Aggregate Value</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="dollar-sign" /></InputGroupText></InputGroupAddon>
                                            <Field name="annualAggregateValue" component={ValidatedCurrencyInput} validate={required} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Description</Label>
                                        <Field name="description" component={ValidatedInput} type="textarea" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>File</Label>
                                        <Field name="upload" component={ValidatedDropzoneSandboxInput} validate={composeValidators(required, uploadComplete)} />
                                    </FormGroup>
                                </BootstrapForm>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={handleSubmit} disabled={submitting}>{submitting && <FA icon="spinner-third" spin />} {insurance != null ? 'Save' : 'Create'}</Button>
                            </ModalFooter>
                        </>
                    )
                }}
            </Form>
        </Modal>
    )
}
