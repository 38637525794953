import React from 'react'
import { ButtonGroup, Col, Container, Row, UncontrolledTooltip } from 'reactstrap'

import { BigNumber } from 'bignumber.js'

import ActionBar from '@src/components/common/ActionBar'
import DropdownCheckOptions, { IDropdownCheckOption } from '@src/components/common/DropdownCheckOptions'
import FA from '@src/components/common/FontAwesomeIcon'
import CostValue from '@src/components/costs/common/CostValue'
import CostSnapshotTable from '@src/components/costs/dashboard/CostSnapshotTable'
import { CostSnapshotCurrentGet } from '@src/logic/http/Api'
import { CostReportSnapshot, CostReportSnapshotRow, CostsOverview } from '@src/types/costs'

interface IProps {
    projectId: string
    costsOverview: CostsOverview
}

interface IState {
    currentSnapshot: CostReportSnapshot
    activeSnapshot?: CostReportSnapshot
    aggregatedData: {
        currentBudget: BigNumber
        forecastFinalCosts: BigNumber
        forecastCostVariance: BigNumber
    }
    hiddenPhases: string[]
}

const ALL_PHASES_KEY = '__ALL_PHASES__'
const UNASSIGNED_PHASES_KEY = '__UNASSIGNED__'

export default class DashboardSection extends React.PureComponent<IProps, IState> {
    private getSnapshotAbortController: AbortController

    constructor(props) {
        super(props)

        this.state = {
            currentSnapshot: null,
            activeSnapshot: undefined,
            aggregatedData: null,
            hiddenPhases: []
        }
    }

    public componentDidMount() {
        this.loadCostSnapshot()
    }

    public componentWillUnmount() {
        this.getSnapshotAbortController?.abort()
    }

    private readonly loadCostSnapshot = async () => {
        this.getSnapshotAbortController?.abort()

        this.getSnapshotAbortController = new AbortController()
        try {
            const response = await CostSnapshotCurrentGet(this.props.projectId, { abortSignal: this.getSnapshotAbortController.signal })
            const agg = response.data.rows.reduce(
                (prev, curr) => ({
                    currentBudget: prev.currentBudget.plus(curr.currentBudget),
                    forecastFinalCosts: prev.forecastFinalCosts.plus(curr.forecastFinalCosts),
                    forecastCostVariance: prev.forecastCostVariance.plus(curr.forecastCostVariance)
                }),
                {
                    currentBudget: new BigNumber(0),
                    forecastFinalCosts: new BigNumber(0),
                    forecastCostVariance: new BigNumber(0)
                })
            this.setState({ currentSnapshot: response.data, aggregatedData: agg, ...(this.state.activeSnapshot == null ? { activeSnapshot: response.data } : { activeSnapshot: this.state.activeSnapshot }) })
        } catch (error) {
            //
        } finally {
            this.getSnapshotAbortController = null
        }
    }

    private readonly handleCheckPhase = (e, option: IDropdownCheckOption) => {
        if (option.key === ALL_PHASES_KEY) {
            if (this.state.hiddenPhases.length > 0) {
                this.setState({ hiddenPhases: [] })
            } else {
                this.setState({ hiddenPhases: [...this.props.costsOverview.phases.map(p => p.code), UNASSIGNED_PHASES_KEY] })
            }
        } else if (this.state.hiddenPhases.includes(option.key)) {
            this.setState({ hiddenPhases: this.state.hiddenPhases.filter(p => p !== option.key) })
        } else {
            this.setState({ hiddenPhases: [...this.state.hiddenPhases, option.key] })
        }
    }

    public render() {
        const { costsOverview } = this.props
        const { currentSnapshot, aggregatedData: agg, hiddenPhases } = this.state

        const codeGroup = costsOverview.costCodes.reduce((prev, curr) => ({ ...prev, [curr.code]: curr.group }), {})
        const groupRows: { [group: string]: CostReportSnapshotRow[] } = costsOverview.costCodes.reduce((prev, curr) => ({ ...prev, [curr.group]: [] }), {})

        if (currentSnapshot) {
            for (const row of currentSnapshot.rows.filter(r => (r.phase == null && !hiddenPhases.includes(UNASSIGNED_PHASES_KEY)) || (r.phase != null && !hiddenPhases.includes(r.phase)))) {
                groupRows[codeGroup[row.costCode]].push(row)
            }
        }

        return (
            <>
                <ActionBar className="mb-3 pt-0 py-3 d-block">
                    <Row>
                        <Col xs={6} md="auto">
                            <h5>Current Budget</h5>
                            {currentSnapshot != null ? <CostValue value={agg.currentBudget.toNumber()} /> : <FA icon="spinner-third" spin />}
                        </Col>
                        <Col xs={6} md="auto">
                            <h5>Forecast Final Cost</h5>
                            {currentSnapshot != null ? <CostValue value={agg.forecastFinalCosts.toNumber()} /> : <FA icon="spinner-third" spin />}
                        </Col>
                        <Col xs={6} md="auto">
                            <h5>Variance</h5>
                            {currentSnapshot != null ? <CostValue value={agg.forecastCostVariance.toNumber()} errorNonZero /> : <FA icon="spinner-third" spin />}
                        </Col>
                        <Col className="ml-auto flex-shrink-1 flex-grow-0">
                            <ButtonGroup>
                                <DropdownCheckOptions
                                    id="dashboard-phases-options"
                                    caret
                                    right
                                    title={<FA icon="stream" />}
                                    options={this.props.costsOverview.phases.map(p => ({
                                        section: 'Phases',
                                        label: p.name,
                                        key: p.code,
                                        checked: !this.state.hiddenPhases.includes(p.code)
                                    })).concat(
                                        {
                                            section: 'Global',
                                            label: 'All Phases',
                                            key: ALL_PHASES_KEY,
                                            checked: this.state.hiddenPhases.length === 0
                                        },
                                        {
                                            section: 'Global',
                                            label: 'Unassigned',
                                            key: UNASSIGNED_PHASES_KEY,
                                            checked: !this.state.hiddenPhases.includes(UNASSIGNED_PHASES_KEY)
                                        })}
                                    onCheck={this.handleCheckPhase}
                                    sectionHeaders={['Global', 'Phases']}
                                />
                                <UncontrolledTooltip target="dashboard-phases-options">Filter phases</UncontrolledTooltip>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </ActionBar>
                <Container fluid>
                    {this.state.currentSnapshot &&
                        <>
                            <Row className="mb-4">
                                <Col>
                                    <CostSnapshotTable
                                        costModule={costsOverview}
                                        groupRows={groupRows}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </>
        )
    }
}
