import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { useSelector } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap'

import { AxiosResponse } from 'axios'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import ClampLines from '@src/components/common/ClampLines'
import FA from '@src/components/common/FontAwesomeIcon'
import { EditCommitmentModal } from '@src/components/costs/commitments/CommitmentFormModal'
import CommitmentItemSection from '@src/components/costs/commitments/CommitmentItemSection'
import CommitmentPaymentsSection from '@src/components/costs/commitments/CommitmentPaymentsSection'
import CommitmentToolbar from '@src/components/costs/commitments/CommitmentToolbar'
import InsurancesSection from '@src/components/costs/commitments/InsurancesSection'
import SubcommitmentSection from '@src/components/costs/commitments/SubcommitmentSection'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import useBoolean from '@src/hooks/useBoolean'
import useUpdateEffect from '@src/hooks/useUpdateEffect'
import { CommitmentGet } from '@src/logic/http/Api'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { Commitment, CommitmentDefinitionRole, CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

const CommitmentDetailPage: React.FC<RouteComponentProps<Routes.IProjectCostsCommitmentTypeDetailParams>> = ({ match, ...routeProps }) => {
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const editingCommitment = useBoolean(false)
    const costsOverview = useSelector<RootState, CostsOverview>(s => s.projects.activeCostsOverview)
    const asyncCommitment = useAsyncAbortable<AxiosResponse<Commitment>, any[]>(
        abortSignal => CommitmentGet(project.id, match.params.commitmentId, { abortSignal }), [match.params.commitmentId],
        {
            setLoading: state => ({ ...state, loading: true })
        }
    )

    useUpdateEffect(() => asyncCommitment.set(undefined), [match.params.commitmentId])

    const commitment = asyncCommitment.result?.data
    const commitmentType = match.params.type
    const commitmentDefinition = costsOverview.commitmentDefinitions.find(c => c.code === commitmentType)
    const showPayments = commitment && commitment.parent == null
    const showSubCommitments = commitment && commitment.parent == null && !(costsOverview.commitmentDefinitions.find(cd => cd.code === commitment.type).role === CommitmentDefinitionRole.Child)
    const commitmentDetailRoute = Routes.projectCostsCommitmentTypeDetail(project.id, match.params.type, match.params.commitmentId)

    const bannerTitle = commitment
    ? <ClampLines lines={2} text={`${commitment.commitmentNo} ${commitment.name}`} />
    : <>Loading {commitmentType} <FA icon="spinner-third" spin /></>

    return (
        <>
            <ProjectBanner project={project} overrideTitle={bannerTitle} />
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL)} to={commitmentDetailRoute} name="Items" />
                    {commitmentDefinition.hasInsurances && <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_INSURANCES)} to={Routes.projectCostsCommitmentTypeDetailInsurances(project.id, commitmentType, match.params.commitmentId)} name="Insurances" />}
                    {showSubCommitments && <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_SUBCOMMIT, true)} to={Routes.projectCostsCommitmentTypeDetailSubcommit(project.id, match.params.type, match.params.commitmentId)} name="Sub Commitments" />}
                    {showPayments && <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_PAYMENTS, true)} to={Routes.projectCostsCommitmentTypeDetailPayments(project.id, match.params.type, match.params.commitmentId)} name="Payments" />}
                    <div className="ml-auto d-none d-md-flex align-items-center">
                        <ButtonGroup>
                            <Button id="document-toolbar-download" color="primary" onClick={editingCommitment.setTrue}>
                                <FA icon="pencil" />
                                <UncontrolledTooltip target="document-toolbar-download">Edit</UncontrolledTooltip>
                            </Button>
                        </ButtonGroup>
                        {commitment && <EditCommitmentModal
                            isOpen={editingCommitment.value}
                            project={project}
                            costsOverview={costsOverview}
                            toggle={editingCommitment.setFalse}
                            commitment={commitment}
                            onCommitmentUpdated={asyncCommitment.execute}
                        />}
                    </div>
                </Toolbar>
            </section>
            <CrumbRoute {...routeProps} title={commitment ? `${commitment.commitmentNo} ${commitment.name}` : ''} linkPath={commitmentDetailRoute}>
                {commitment &&
                    <>
                        <CommitmentToolbar costsOverview={costsOverview} commitment={commitment} projectId={project.id} />
                        <Switch>
                            {commitmentDefinition.hasInsurances && <CrumbRoute title="Insurances" path={Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_INSURANCES} render={routeProps => <InsurancesSection projectId={project.id} commitment={commitment} reloadCommitment={asyncCommitment.execute} search={routeProps.location.search} />} />}
                            {showSubCommitments && <CrumbRoute title="Sub Commitments" path={Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_SUBCOMMIT} render={routeProps => <SubcommitmentSection project={project} commitment={commitment} costsOverview={costsOverview} search={routeProps.location.search} />} />}
                            {showPayments && <CrumbRoute title="Payments" path={Routes.PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_PAYMENTS} render={routeProps => <CommitmentPaymentsSection {...routeProps} projectId={project.id} commitment={commitment} costsOverview={costsOverview} reloadCommitment={asyncCommitment.execute} search={routeProps.location.search} />} />}
                            <Route render={routeProps => <CommitmentItemSection project={project} commitment={commitment} costsOverview={costsOverview} reloadCommitment={asyncCommitment.execute} search={routeProps.location.search} />} />
                        </Switch>
                    </>
                }
            </CrumbRoute>
        </>
    )
}

export default CommitmentDetailPage
