import produce, { Immutable, castImmutable } from 'immer'
import create, { Mutate, StoreApi, UseBoundStore } from 'zustand'
import createContext from 'zustand/context'
import { subscribeWithSelector } from 'zustand/middleware'

import { ISpreadsheetColumn } from '@src/components/spreadsheet/Spreadsheet'

const DEFAULT_COLUMN_WIDTH = 150

export interface ISpreadsheetContext<TCol, TRow> {
    focus: {
        row?: TRow
        column?: ISpreadsheetColumn<TCol, TRow>
    } | null
    columns: {
        widths: {
            [columnId: string]: number
        }
        resize: {
            target: string
        }
        dragging: {
            target: string | null
            over: string | null
        }
    }
    clearFocus: () => void
    focusCell: (column: ISpreadsheetColumn<TCol, TRow>, row: TRow) => void
    isCellFocused: (column: ISpreadsheetColumn<TCol, TRow>, row: TRow, getRowId: (row?: TRow) => string) => boolean
    getColumnWidth: (column: ISpreadsheetColumn<TCol, TRow>) => number
    setColumnWidth: (column: ISpreadsheetColumn<TCol, TRow>, width: number) => void
    setResizing: (value: string) => void
}

type ReadonlyContext<TCol, TRow> = Immutable<ISpreadsheetContext<TCol, TRow>>

export const { Provider, useStore: useSpreadsheetStore, useStoreApi: useSpreadsheetStoreApi } =
    createContext<
        ISpreadsheetContext<unknown, unknown>,
        UseBoundStore<ISpreadsheetContext<unknown, unknown>,
        Mutate<StoreApi<ISpreadsheetContext<unknown, unknown>>, [['zustand/subscribeWithSelector', never]]>>
    >()

export function createStore<TCol, TRow>() {
    return create<ReadonlyContext<TCol, TRow>>(subscribeWithSelector<ReadonlyContext<TCol, TRow>>((set, get) => ({
        focus: null,
        columnWidths: {},
        resizing: false,
        columns: {
            widths: {},
            resize: {
                target: null
            },
            dragging: {
                target: null,
                over: null
            }
        },
        clearFocus: () => set({ focus: null }),
        focusCell: (column, row) => set({ focus: { column: castImmutable(column), row: castImmutable(row) } }),
        isCellFocused: (column, row, getRowId) => {
            const focus = get().focus
            return focus?.column.id === column.id && getRowId(focus?.row as TRow) === getRowId(row)
        },
        getColumnWidth: (column) => get().columns.widths[column.id] ?? DEFAULT_COLUMN_WIDTH,
        setColumnWidth: (column, width) => {
            set(produce<ReadonlyContext<TCol, TRow>>(state => {
                state.columns.widths[column.id] = width
            }))
        },
        setResizing: (value) => set(produce<ReadonlyContext<TCol, TRow>>(x => {
            x.columns.resize.target = value
        }))
    })))
}
