import React from 'react'
import { connect } from 'react-redux'
import { Col, Jumbotron, Row } from 'reactstrap'

import Banner from '@src/components/banner/Banner'
import ButtonLink from '@src/components/common/ButtonLink'
import ActionNeededCard from '@src/components/dashboard/ActionNeededCard'
import RecentlyViewedDocumentsCard from '@src/components/dashboard/RecentlyViewedDocumentsCard'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IConnectedState {
    projects: Project[]
    loadingFavouriteProjects: boolean
}

class DashboardPage extends React.PureComponent<IConnectedState> {
    public render() {
        return (
            <>
                <Banner title="Dashboard" wrapperClassName="mb-3" />
                <div className="container-fluid">
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Jumbotron style={{ background: 'white' }}>
                                <h1 className="display-3">Welcome to InfoPoint!</h1>
                                <p className="lead">This is the new, fast, and collaborative version of InfoPoint. We are continually adding new features and enhancements to make it even better.</p>
                                <hr className="my-2" />
                                <p>{'Browse users, projects, and more on your company\'s dashboard'}</p>
                                <p className="lead">
                                    <ButtonLink to={Routes.COMPANY} color="primary">My Company</ButtonLink>
                                </p>
                            </Jumbotron>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg={6}>
                            <RecentlyViewedDocumentsCard />
                        </Col>
                        <Col lg={6}>
                            <ActionNeededCard />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        ...ownProps,
        projects: state.projects.favourites || [],
        loadingFavouriteProjects: state.projects.favourites == null
    }
}

export default connect(mapStateToProps)(DashboardPage)
