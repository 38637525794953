import React from 'react'
import { useHistory, useLocation } from 'react-router'

import cx from 'classnames'
import { createLocation } from 'history'

export interface IProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    onClick?: (event: React.MouseEvent) => void
    target?: string
    replace?: boolean
    to: string | Location
    tag?: React.ElementType
    disabled?: boolean
    innerRef?: ((node: React.ReactNode) => void) | string | React.RefObject<HTMLElement>
}

const isModifiedEvent = (event: React.MouseEvent) =>
    !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

function Link({ children, className, disabled, innerRef, onClick, replace, tag, target, to, ...props }: IProps) {
    const history = useHistory()
    const location = useLocation()

    function handleClick(event: React.MouseEvent) {
        if (disabled) return

        onClick?.(event)

        if (
            !event.defaultPrevented && // onClick prevented default
            event.button === 0 && // ignore everything but left clicks
            !target && // let browser handle "target=_blank" etc.
            !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
            event.preventDefault()

            if (replace) {
                history.replace(to)
            } else {
                history.push(to)
            }
        }
    }

    const href = disabled ? null : history.createHref(createLocation(to, null, null, location))
    const Tag = tag || 'a'

    return (
        <Tag {...props} className={cx(className, { pointer: Tag !== 'a' })} onClick={handleClick} {...(Tag === 'a' && { href })} ref={innerRef}>
            {children}
        </Tag>
    )
}

Link.defaultProps = {
    role: 'link'
}

export default Link
