import { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'

export interface IMetadataDefinition<TOptions = {}> {
    key: string
    type: MetadataTypes
    name: string
    description: string
    tags: string[]
    isRequired: boolean
    options: TOptions
}

export interface IEntityPropertyDefinition<TOptions = {}, TProp = unknown> extends IMetadataDefinition<IEntityPropertyOptions<TProp> & TOptions> {
    parentKey: string
}

export interface IMetadataForm {
    definitions: IMetadataDefinition[]
}

export enum MetadataTypes {
    Bool = 'bool',
    Text = 'text',
    Numeric = 'numeric',
    Date = 'date',
    Select = 'select',
    CompanyLinks = 'companyLinks',
    CompanyProperty = 'companyProperty',
    DocumentLinks = 'documentLinks',
    DocumentProperty = 'documentProperty',
    TransmittalLinks = 'transmittalLinks',
    TransmittalProperty = 'transmittalProperty',
    UserLinks = 'userLinks',
    UserProperty = 'userProperty',
    EmailLinks = 'emailLinks',
    EmailProperty = 'emailProperty',
    AutoNumber = 'autoNumber',
    CommitmentLinks = 'commitmentLinks',
    CommitmentProperty = 'commitmentProperty',
    PaymentClaimLinks = 'paymentClaimLinks',
    PaymentProperty = 'paymentProperty'
}

export type BaseMetadataTypes = MetadataTypes.Bool | MetadataTypes.Date | MetadataTypes.DocumentLinks | MetadataTypes.Numeric | MetadataTypes.Select | MetadataTypes.Text | MetadataTypes.TransmittalLinks | MetadataTypes.EmailLinks | MetadataTypes.CompanyLinks | MetadataTypes.UserLinks | MetadataTypes.AutoNumber | MetadataTypes.CommitmentLinks | MetadataTypes.PaymentClaimLinks

export type RelatedMetadataTypes = MetadataTypes.CompanyProperty | MetadataTypes.DocumentProperty | MetadataTypes.TransmittalProperty | MetadataTypes.UserProperty | MetadataTypes.EmailProperty

export const MetadataTypeDisplayMap: {
    [key in MetadataTypes]: {
        icon: IPFontawesomeIcon
        label: string
        tooltip: string
    }
} = {
    [MetadataTypes.Bool]: { icon: 'check-circle', label: 'Check', tooltip: 'Yes/no option' },
    [MetadataTypes.Text]: { icon: 'font', label: 'Text', tooltip: 'Text information' },
    [MetadataTypes.Numeric]: { icon: 'superscript', label: 'Number', tooltip: 'Numeric information' },
    [MetadataTypes.Date]: { icon: 'calendar', label: 'Date', tooltip: 'Date/time information' },
    [MetadataTypes.Select]: { icon: 'list', label: 'Option', tooltip: 'Single/multi option select' },
    [MetadataTypes.CompanyLinks]: { icon: 'building', label: 'Company Link', tooltip: 'Link to a company' },
    [MetadataTypes.CompanyProperty]: { icon: 'building', label: 'Company Property', tooltip: 'Linked company information' },
    [MetadataTypes.DocumentLinks]: { icon: 'file-alt', label: 'Document Link', tooltip: 'Link to a document' },
    [MetadataTypes.DocumentProperty]: { icon: 'file-alt', label: 'Document Property', tooltip: 'Linked document information' },
    [MetadataTypes.TransmittalLinks]: { icon: 'envelope', label: 'Transmittal Link', tooltip: 'Link to a transmittal' },
    [MetadataTypes.TransmittalProperty]: { icon: 'envelope', label: 'Transmittal Property', tooltip: 'Linked transmittal information' },
    [MetadataTypes.UserLinks]: { icon: 'user', label: 'User Link', tooltip: 'Link to a user' },
    [MetadataTypes.UserProperty]: { icon: 'user', label: 'User Property', tooltip: 'Linked user information' },
    [MetadataTypes.EmailLinks]: { icon: 'at', label: 'Email Link', tooltip: 'Link to an email' },
    [MetadataTypes.EmailProperty]: { icon: 'at', label: 'Email Property', tooltip: 'Linked email information' },
    [MetadataTypes.AutoNumber]: { icon: 'sort-numeric-down', label: 'Auto Number', tooltip: 'Auto incrementing number' },
    [MetadataTypes.CommitmentLinks]: { icon: 'badge-dollar', label: 'Commitment Link', tooltip: 'Link to a commitment' },
    [MetadataTypes.CommitmentProperty]: { icon: 'badge-dollar', label: 'Commitment Link', tooltip: 'Linked commitment information' },
    [MetadataTypes.PaymentClaimLinks]: { icon: 'envelope-open-dollar', label: 'Payment Link', tooltip: 'Link to a payment' },
    [MetadataTypes.PaymentProperty]: { icon: 'envelope-open-dollar', label: 'Payment Link', tooltip: 'Linked payment information' }
}

export const MetadataPropertyToLinkType = {
    [MetadataTypes.CompanyProperty]: MetadataTypes.CompanyLinks,
    [MetadataTypes.DocumentProperty]: MetadataTypes.DocumentLinks,
    [MetadataTypes.TransmittalProperty]: MetadataTypes.TransmittalLinks,
    [MetadataTypes.UserProperty]: MetadataTypes.UserLinks,
    [MetadataTypes.EmailProperty]: MetadataTypes.EmailLinks,
    [MetadataTypes.CommitmentProperty]: MetadataTypes.CommitmentLinks,
    [MetadataTypes.PaymentProperty]: MetadataTypes.PaymentClaimLinks
}

export interface BoolDefinition extends IMetadataDefinition<{}> {
    type: MetadataTypes.Bool
}

export interface INumericOptions {
    enforceRange: boolean
    format?: string
    minValue?: number
    maxValue?: number
}

export interface NumericDefinition extends IMetadataDefinition<INumericOptions> {
    type: MetadataTypes.Numeric
}

export interface ITextOptions {
    minLength?: number
    maxLength?: number
}

export interface TextDefinition extends IMetadataDefinition<ITextOptions> {
    type: MetadataTypes.Text
}

export interface IDateOptions {
    enforceRange: boolean
    format?: string
    minValue?: Date
    maxValue?: Date
}

export interface DateDefinition extends IMetadataDefinition<IDateOptions> {
    type: MetadataTypes.Date
}

export interface ISelectOptions {
    values: string[]
    enforceValues: boolean
    isMultiselect: boolean
}

export interface SelectDefinition extends IMetadataDefinition<ISelectOptions> {
    type: MetadataTypes.Select
}

export interface IDocumentLinkOptions {
    limit?: number
}

export interface DocumentLinksDefinition extends IMetadataDefinition<IDocumentLinkOptions> {
    type: MetadataTypes.DocumentLinks
}

export interface ICompanyLinkOptions {
    limit?: number
}

export interface CompanyLinksDefinition extends IMetadataDefinition<ICompanyLinkOptions> {
    type: MetadataTypes.CompanyLinks
}

export interface IUserLinkOptions {
    limit?: number
}

export interface UserLinksDefinition extends IMetadataDefinition<IUserLinkOptions> {
    type: MetadataTypes.UserLinks
}

export interface ITransmittalLinkOptions {
    limit?: number
}

export interface TransmittalLinksDefinition extends IMetadataDefinition<ITransmittalLinkOptions> {
    type: MetadataTypes.TransmittalLinks
}

export interface IEmailLinkOptions {
    limit?: number
}

export interface EmailLinksDefinition extends IMetadataDefinition<IEmailLinkOptions> {
    type: MetadataTypes.EmailLinks
}

export enum DocumentEntityProps {
    Name = 'name',
    Description = 'description',
    RevisionNo = 'revisionNo',
    RevisionDate = 'revisionDate',
    Author = 'author',
    CreatedDate = 'createdDate',
    CreatedBy = 'createdBy',
    Published = 'published',
    MetaData = 'metadata'
}

export interface IEntityPropertyOptions<TProp> {
    property: TProp
}

export interface IDocumentPropertyOptions {
    parentMetadataKey?: string
}

export interface DocumentPropertyDefinition extends IEntityPropertyDefinition<IDocumentPropertyOptions, DocumentEntityProps> {
    type: MetadataTypes.DocumentProperty
}

export enum CompanyEntityProps {
    Name = 'name',
    AbbreviatedName = 'abbreviatedName',
    Disciplines = 'disciplines'
}

export interface CompanyPropertyDefinition extends IEntityPropertyDefinition<{}, CompanyEntityProps> {
    type: MetadataTypes.CompanyProperty
}

export enum UserEntityProps {
    Name = 'name',
    Email = 'email',
    Mobile = 'mobile',
    Role = 'role'
}

export interface UserPropertyDefinition extends IEntityPropertyDefinition<{}, UserEntityProps> {
    type: MetadataTypes.UserProperty
}

export enum TransmittalEntityProps {
    ReferenceNo = 'referenceNo',
    Subject = 'subject',
    CreatedDate = 'createdDate',
    ResponseDate = 'responseDate',
    ResponseType = 'responseType',
    Status = 'status'
}

export interface TransmittalPropertyDefinition extends IEntityPropertyDefinition<{}, TransmittalEntityProps> {
    type: MetadataTypes.TransmittalProperty
}

export enum EmailEntityProps {
    From = 'from',
    To = 'to',
    Cc = 'cc',
    Bcc = 'bcc',
    Subject = 'subject',
    Date = 'date'
}

export interface EmailPropertyDefinition extends IEntityPropertyDefinition<{}, EmailEntityProps> {
    type: MetadataTypes.EmailProperty
}

export interface IAutoNumberOptions {
    displayPrefix: boolean
    minValue: number
    maxValue: number
}

export interface AutoNumberDefinition extends IMetadataDefinition<IAutoNumberOptions> {
    type: MetadataTypes.AutoNumber
    current: number
}

export interface ICommitmentLinksOptions {
    limit?: number
}

export interface CommitmentLinksDefinition extends IMetadataDefinition<ICommitmentLinksOptions> {
    type: MetadataTypes.CommitmentLinks
}

export enum CommitmentEntityProps {
    CommitmentNo = 'commitmentNo',
    Certified = 'certified',
    Claimed = 'claimed',
    Date = 'date',
    Description = 'description',
    Name = 'name',
    Notes = 'notes',
    OtherParty = 'otherParty',
    OtherPartyReference = 'otherPartyReference',
    Paid = 'paid',
    Type = 'type',
    Value = 'value'
}

export interface CommitmentPropertyDefinition extends IEntityPropertyDefinition<{}, CommitmentEntityProps> {
    type: MetadataTypes.CommitmentProperty
}

export interface IPaymentClaimLinksOptions {
    limit?: number
}

export interface PaymentClaimLinksDefinition extends IMetadataDefinition<IPaymentClaimLinksOptions> {
    type: MetadataTypes.PaymentClaimLinks
}

export enum PaymentEntityProps {
    CertificateNumber = 'certificateNumber',
    ClaimDate = 'claimDate',
    ClaimReference = 'claimReference',
    InvoiceDate = 'invoiceDate',
    InvoiceReference = 'invoiceReference',
    Notes = 'notes',
    Status = 'status',
    TotalCertified = 'totalCertified',
    TotalCertifiedIncGst = 'totalCertifiedIncGst',
    TotalClaimed = 'totalClaimed',
    TotalPaid = 'totalPaid',
}

export interface PaymentPropertyDefinition extends IEntityPropertyDefinition<{}, PaymentEntityProps> {
    type: MetadataTypes.PaymentProperty
}

export interface INewMetadataDefinition extends IMetadataDefinition {
    name: string
    key: string
    type: BaseMetadataTypes
}

export interface INewRelatedMetadataDefinition {
    name: string
    key: string
    type: RelatedMetadataTypes
    parentKey: string
}

export interface IUpdateMetadataDefinition<TOptions = {}> {
    name: string
    type: MetadataTypes
    key: string
    description: string
    isRequired: boolean
    options: TOptions
}
