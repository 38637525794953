import React from 'react'
import { useHistory } from 'react-router'
import { ListGroupItem } from 'reactstrap'

import { UserAvatar } from '@src/components/common/Avatar'
import { fromNow } from '@src/logic/utils/Date'
import type { Notification } from '@src/types/notification'

interface IProps extends React.HTMLAttributes<HTMLElement> {
    notification: Notification
    markAsRead: (notificationId: string) => void
}

const NotificationListItem: React.FC<IProps> = ({ notification, style, markAsRead, ...props }) => {
    const history = useHistory()

    const notificationStyle: React.CSSProperties = notification.read
    ? style
    : {
        boxShadow: '3px 0 0 var(--tertiary) inset',
        ...style
    }

    function handleClick() {
        markAsRead(notification.id)
        const url = new URL(notification.actionLink)
        history.push(url.pathname + url.search)
    }

    return (
        <ListGroupItem className="pointer border border-left-0 border-right-0 border-quaternary-tint" onClick={handleClick} style={notificationStyle} action {...props} color={notification.read ? null : 'quaternary'}>
            <div className="d-flex align-items-start mw-100">
                <UserAvatar imageUrl={notification.author.profilePictureLink} className="mr-3 border border-default flex-shrink-0" />
                <div className="flex-grow-1">
                    <div className="d-flex"><strong>{notification.title}</strong><small className="ml-auto text-muted">{fromNow(notification.createdAt)}</small></div>
                    <div style={{ fontSize: '0.8em' }} dangerouslySetInnerHTML={{ __html: notification.description }}></div>
                </div>
            </div>
        </ListGroupItem>
    )
}

export default NotificationListItem
