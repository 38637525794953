import React from 'react'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'
import { Transmittal, TransmittalStatus } from '@src/types/transmittal'

interface IProps {
    transmittal?: Transmittal
    children?: React.ReactNode
}

const TransmittalToolbar: React.FC<IProps> = ({ transmittal, children }) => {
    const onNewRoute = pathMatchesCurrentRoute(Routes.PROJECT_TRANSMITTAL_NEW)
    const onEditRoute = pathMatchesCurrentRoute(Routes.PROJECT_TRANSMITTAL_EDIT)

    return (
        <section className="banner">
            <Toolbar>
                {transmittal && transmittal?.status !== TransmittalStatus.Draft &&
                    <ToolbarLink
                        active={pathMatchesCurrentRoute(Routes.PROJECT_TRANSMITTAL_VIEW)}
                        to={Routes.projectTransmittalView(transmittal.projectId, transmittal.id)}
                        name="View"
                        nameBrief="View"
                        iconBrief="eye"
                        data-tour={TourTags.TransmittalToolbarView}
                    />
                }
                {(transmittal?.status !== TransmittalStatus.Sent && transmittal?.status !== TransmittalStatus.Rejected) &&
                    <ToolbarLink
                        active={onEditRoute || onNewRoute}
                        to={Routes.projectTransmittalEdit(transmittal?.projectId, transmittal?.id)}
                        name="Edit"
                        nameBrief="Edit"
                        iconBrief="pencil"
                    />
                }
                {transmittal &&
                <>
                <ToolbarLink
                    active={pathMatchesCurrentRoute(Routes.PROJECT_TRANSMITTAL_ACCESS)}
                    to={Routes.projectTransmittalAccess(transmittal.projectId, transmittal.id)}
                    name="Access"
                    nameBrief="Access"
                    iconBrief="lock"
                    data-tour={TourTags.TransmittalToolbarAccess}
                />
                </>
                }
                <div className="ml-auto d-none d-flex align-items-center">
                    {children}
                </div>
            </Toolbar>
        </section>
    )
}

export default React.memo(TransmittalToolbar)
