import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { required } from '@src/logic/forms/validation'
import { TemplatePatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Template } from '@src/types/communication'

interface ITemplateEditFormData {
    name: string
    description: string
}

interface IProps {
    template: Template
    onUpdated?: () => void
}

export default function TemplateEditForm({ template, onUpdated }: IProps) {
    const initialValues = React.useMemo(() => ({
        name: template.name,
        description: template.description
    }), [template])

    async function handleUpdateInfo(values: ITemplateEditFormData) {
        try {
            await TemplatePatch(this.props.template.id, [
                { op: 'replace', path: '/name', value: values.name },
                { op: 'replace', path: '/description', value: values.description }
            ])
            NotificationService.info('Updated template info')
        } catch {
            NotificationService.error('Unable to update template info')
        }

        onUpdated?.()
    }

    return (
        <Form onSubmit={handleUpdateInfo} initialValues={initialValues}>
            {({ handleSubmit }) =>
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <span><FA icon="info-circle" /> Template Info</span>
                        <Button className="ml-auto" onClick={handleSubmit}>Save</Button>
                    </CardHeader>
                    <CardBody>
                        <FormGroup>
                            <Label>Name</Label>
                            <Field name="name" component={ValidatedInput} validate={required} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Field name="description" component={ValidatedInput} validate={required} type="textarea" />
                        </FormGroup>
                    </CardBody>
                </Card>
            }
        </Form>
    )
}
