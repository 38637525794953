import React from 'react'
import { connect } from 'react-redux'
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { Dispatch } from 'redux'

import cx from 'classnames'

import { setDocumentToRevise, toggleSandbox } from '@src/actions/sandbox'
import * as WidgetActions from '@src/actions/widget'
import FA from '@src/components/common/FontAwesomeIcon'
import BulkPermissionUpdateModal from '@src/components/widget/BulkPermissionUpdateModal'
import WidgetButton from '@src/components/widget/WidgetButton'
import { auth } from '@src/logic/auth/AuthService'
import { exportDocuments } from '@src/logic/exports/excelExports'
import { DocumentsList, RevisionsDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import NotificationService from '@src/logic/notification/NotificationService'
import history from '@src/logic/routing/history'
import * as Routes from '@src/logic/routing/routes'
import { localShortDate } from '@src/logic/utils/Date'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'
import { IProjectWidgetState } from '@src/types/widget'

interface IConnectedState {
    company: Company
    project: Project
    projectWidget: IProjectWidgetState
    totalSelectedItems: number
}

interface IConnectedDispatch {
    clearRevisions: (projectId: string) => void
    clearWidget: (projectId: string) => void
    bulkRevise: (project: Project, revisions: Revision[]) => void
}

interface IState {
    selectedMenuOpen: boolean
    bulkPermissionsOpen: boolean
}

class Widget extends React.Component<IConnectedState & IConnectedDispatch, IState> {
    constructor(props) {
        super(props)

        this.state = {
            selectedMenuOpen: false,
            bulkPermissionsOpen: false
        }
    }

    private readonly toggleSelectedMenu = () => {
        this.setState({ selectedMenuOpen: !this.state.selectedMenuOpen })
    }

    private readonly downloadRevisions = () => {
        downloadURL(RevisionsDownloadLink(auth.getSessionToken(), this.props.projectWidget.revisions[0].projectId, ...this.props.projectWidget.revisions.map(r => r.id)))
    }

    private readonly newTransmittal = () => {
        history.push(Routes.projectTransmittalNew(this.props.project.id))
    }

    private readonly clearRevisions = () => {
        this.props.clearRevisions(this.props.project.id)
    }

    private readonly clearWidget = () => {
        this.props.clearWidget(this.props.project.id)
    }

    private readonly toggleBulkPermissions = () => {
        this.setState({ bulkPermissionsOpen: !this.state.bulkPermissionsOpen })
    }

    private readonly bulkRevise = () => {
        const revisions = Object.values(this.props.projectWidget.revisions.reduce<{ [documentId: string]: Revision }>((agg, curr) => {
            agg[curr.documentId] = curr
            return agg
        }, {}))
        this.props.bulkRevise(this.props.project, revisions)
    }

    private readonly exportDocuments = async () => {
        const toastId = NotificationService.pendingActivityToast('Exporting documents')

        // use API to get list of documents, metadata definitions and export it
        const documentsList = await DocumentsList(this.props.project.id, undefined, undefined, undefined, 1, 0)
        exportDocuments(this.props.projectWidget.revisions, documentsList.data.metadataDefinitions, `${this.props.project.code} Metadata Export - ${(localShortDate(new Date())).replace(/\//g, '-')}.xlsx`)
        NotificationService.updateThenCloseToast(toastId, 'Documents exported')
    }

    public render() {
        const { totalSelectedItems, projectWidget } = this.props
        const { bulkPermissionsOpen, selectedMenuOpen } = this.state

        if (this.props.project == null) return null

        const { revisions, users } = projectWidget
        const displayWidgetClass = totalSelectedItems > 0 ? 'd-flex' : 'd-none'
        return (
            <footer className={cx('file-select', 'file-select__doc--selected', displayWidgetClass)}>
                <div className="file-select__docs mr-2">
                    <Dropdown direction="up" className="file-select__drop file-select__dropdown" toggle={this.toggleSelectedMenu} isOpen={selectedMenuOpen}>
                        <DropdownToggle tag="a" className="file-select__doc" role="button">
                            <Badge pill color="warning">
                                {this.props.totalSelectedItems}
                            </Badge>
                            <span className="d-none d-sm-inline-block">
                                <span className="d-none d-lg-inline-block">&nbsp;items</span>
                                &nbsp;selected
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={this.clearRevisions}>
                                <span className="text-danger float-right">
                                    <FA icon="times" />
                                </span>
                                Clear selected revisions
                            </DropdownItem>
                            <DropdownItem onClick={this.clearWidget}>
                                <span className="text-danger float-right">
                                    <FA icon="times" />
                                </span>
                                Clear widget
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="file-select__docs file-select__scroll mr-auto">
                    {/* <WidgetDropup fullName="New communication" shortName="Comms" icon="copy">
                        <DropdownItem tag="a" href="#">
                            <span className="text-danger float-right">
                                <FA icon="times" />
                            </span>
                            Another action
                        </DropdownItem>
                        <DropdownItem tag="a" href="#">
                            <span className="text-success float-right">
                                <FA icon="check" />
                            </span>
                            Action
                        </DropdownItem>
                        <DropdownItem tag="a" href="#">
                            <span className="text-success float-right">
                                <FA icon="check" />
                            </span>
                            Something else here
                        </DropdownItem>
                    </WidgetDropup> */}
                    {/* <WidgetDropup fullName="Add to draft" shortName="Draft" icon="envelope-open">
                        <DropdownItem tag="a" href="#">
                            <span className="text-danger float-right">
                                <FA icon="times" />
                            </span>
                            Another action
                        </DropdownItem>
                        <DropdownItem tag="a" href="#">
                            <span className="text-success float-right">
                                <FA icon="check" />
                            </span>
                            Action
                        </DropdownItem>
                        <DropdownItem tag="a" href="#">
                            <span className="text-success float-right">
                                <FA icon="check" />
                            </span>
                            Something else here
                        </DropdownItem>
                    </WidgetDropup> */}
                    {(revisions.length > 0 || users.length > 0) && <WidgetButton fullName="Add to Draft" shortName="New draft" icon="envelope-open" onClick={this.newTransmittal} />}
                    {/* {revisions.length > 0 && <WidgetButton fullName="Add to document group" shortName="Doc group" icon="copy" />} */}
                    {revisions.length > 0 && <WidgetButton fullName="Download Revisions" shortName="Download" icon="cloud-download" onClick={this.downloadRevisions} />}
                    {revisions.length > 0 && <WidgetButton fullName="Bulk Revise" shortName="Revise" icon="edit" onClick={this.bulkRevise} />}
                    {revisions.length > 0 && revisions.length < 51 && <WidgetButton fullName="Bulk Revision Access" shortName="Bulk Access" icon="lock" onClick={this.toggleBulkPermissions} />}
                    {revisions.length > 0 && <WidgetButton fullName="Export Documents" shortName="Export" icon="download" onClick={this.exportDocuments}/>}
                </div>
                {/* <div className="file-select__instructions d-none d-xl-inline-block">
                    <strong><em>shift+click</em></strong> to select,
                    <strong><em> esc</em></strong> to deselect all
                </div> */}
                <BulkPermissionUpdateModal isOpen={bulkPermissionsOpen} toggle={this.toggleBulkPermissions} />
            </footer>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    const activeProjectWidgetState = getActiveProjectWidgetState(state)
    return {
        company: state.session.company,
        project: state.projects.active ? state.projects.active : null,
        projectWidget: activeProjectWidgetState,
        totalSelectedItems: Object.keys(activeProjectWidgetState).reduce((prev, curr) => prev + activeProjectWidgetState[curr].length, 0)
    }
}

function mapDispatchToProps(dispatch: Dispatch): IConnectedDispatch {
    return {
        clearRevisions: (projectId: string) => dispatch(WidgetActions.clearRevisions(projectId)),
        clearWidget: (projectId: string) => dispatch(WidgetActions.clearWidget(projectId)),
        bulkRevise: (project: Project, revisions: Revision[]) => {
            dispatch(setDocumentToRevise({ project, revisions }))
            dispatch(toggleSandbox(true))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget)
