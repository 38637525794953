import React from 'react'
import { connect } from 'react-redux'
import { Alert, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row, Table } from 'reactstrap'

import numeral from 'numeral'

import ClipboardButton from '@src/components/common/ClipboardButton'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import Tags from '@src/components/common/Tags'
import TrackingList from '@src/components/common/TrackingList'
import DocumentPreview from '@src/components/document/DocumentPreview'
import RevisionApprovalModal from '@src/components/document/RevisionApprovalModal'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import ModalToggle from '@src/components/modal/ModalToggle'
import TransmittalStatusIndicator from '@src/components/transmittal/TransmittalStatusIndicator'
import FeatureGate from '@src/containers/FeatureGate'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { CostFlagValue, FeatureFlag, Features } from '@src/logic/features/features'
import { TransmittalsList } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { localDateTime } from '@src/logic/utils/Date'
import { capitalize } from '@src/logic/utils/Strings'
import { mutedNotSet, mutedValue, valueOrMutedFallback, valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { CommitmentLink } from '@src/types/costs'
import { Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Session } from '@src/types/session'
import { TransmittalBrief } from '@src/types/transmittal'

interface IProps {
    revision: Revision
    metadataDefinitions: IMetadataDefinition[]
    reloadDocument: () => void
}

interface IConnectedState {
    project: Project
    user: Session.User
    features: Features
}

interface IState {
    linkedTransmittals: TransmittalBrief[]
}

class DocumentPreviewPage extends React.PureComponent<IProps & IConnectedState, IState> {
    private getTransmittalsAbortController: AbortController

    constructor(props) {
        super(props)

        this.state = {
            linkedTransmittals: null
        }
    }

    public componentDidMount() {
        this.loadLinkedTransmittals()
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.revision.id !== this.props.revision.id) {
            this.props.reloadDocument()
        }
    }

    private readonly loadLinkedTransmittals = async () => {
        this.getTransmittalsAbortController?.abort()

        this.getTransmittalsAbortController = new AbortController()
        try {
            const response = await TransmittalsList(this.props.revision.projectId, `revision_ids: "${this.props.revision.id}"`, undefined, 1, 200)
            this.setState({ linkedTransmittals: response.data })
        } catch (error) {
            //
        } finally {
            this.getTransmittalsAbortController = null
        }
    }

    private readonly renderMetadata = () => {
        return this.props.metadataDefinitions
            ? this.props.metadataDefinitions.map(md => (
                    <ListGroupItem key={md.key} className="py-1">
                        {md.name}: {renderMetadata(this.props.revision.metadata[md.key], md, this.props.revision.projectId)}
                    </ListGroupItem>
                )
            )
            : <ListGroupItem className="text-center">No metadata</ListGroupItem>
    }

    public render() {
        const { revision, project, features } = this.props
        const { linkedTransmittals } = this.state

        const linkToRevision = window.location.origin + Routes.projectDocument(revision.projectId, revision.documentId, revision.id)
        const linkToLatestRevision = window.location.origin + Routes.projectDocument(revision.projectId, revision.documentId)

        return (
            <>
                {revision.approval.status === 'pending' && <Row>
                    <Col>
                        <Alert color="info" className="mb-3">
                            <h4><FA icon="exclamation-circle" /> Revision Requires Approval</h4>
                            <p>This revision has been uploaded but requires approval before being committed. Only users with document approval permissions on the project will be able to see this revision until it has been formally approved.</p>
                            {isAuthorised(project.myAccess, ProjectOperations.ApproveDocument) && <ModalToggle modal={RevisionApprovalModal} modalProps={{ revision, onApproveOrReject: this.props.reloadDocument }}>Review</ModalToggle>}
                        </Alert>
                    </Col>
                </Row>}
                {revision.archived && <Row>
                    <Col>
                        <Alert color="warning" className="mb-3">
                            <h4><FA icon="archive" /> Archived Revision</h4>
                            <p>This revision has been archived and its access may only be intended for historical purposes.</p>
                        </Alert>
                    </Col>
                </Row>}
                <Row>
                    <Col lg={8} className="mb-3">
                        <Card>
                            <CardHeader><FA icon="eye" /> Preview</CardHeader>
                            <CardBody className="p-0">
                                <DocumentPreview revisionId={revision.id} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="mb-3">
                            <CardHeader><FA icon="info-circle" /> Basic Info</CardHeader>
                            <ListGroup className="small" flush>
                                <ListGroupItem className="py-1"><strong>Name: </strong>{revision.name}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Revision #: </strong>{valueOrMutedNotSet(revision.revNumber)}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Revision Date: </strong>{revision.revDate ? localDateTime(revision.revDate) : mutedNotSet}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Description: </strong>{valueOrMutedNotSet(revision.description)}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Uploaded: </strong>{localDateTime(revision.uploadDate)}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Uploaded By: </strong>{revision.uploadedBy.name}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Author: </strong>{valueOrMutedNotSet(revision.author)}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Tags: </strong>{revision.tags.length ? <Tags tags={revision.tags} /> : mutedValue('(none)')}</ListGroupItem>
                                <ListGroupItem className="py-1 text-truncate"><strong>Approved By: </strong>{valueOrMutedFallback(revision.approval?.approvedBy?.name, 'N/A')}</ListGroupItem>
                                <ListGroupItem className="py-1 text-truncate"><strong>Approval Comments: </strong>{valueOrMutedFallback(revision.approval?.comments, 'N/A')}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>File name: </strong>{revision.fileName}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>File size: </strong>{numeral(revision.fileSize).format('0.0 b')}</ListGroupItem>
                                <ListGroupItem className="py-1 text-truncate"><strong>Link to this revision: </strong><ClipboardButton id="copy-revision-link" explicitCopy={linkToRevision} /><Link to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)}>{linkToRevision}</Link></ListGroupItem>
                                <ListGroupItem className="py-1 text-truncate"><strong>Link to latest revision: </strong><ClipboardButton id="copy-latest-revision-link" explicitCopy={linkToLatestRevision} /><Link to={Routes.projectDocument(revision.projectId, revision.documentId)}>{linkToLatestRevision}</Link></ListGroupItem>

                            </ListGroup>
                        </Card>
                        <Card className="mb-3">
                            <CardHeader><FA icon="list" /> Metadata</CardHeader>
                            <ListGroup className="small" flush>
                                {this.renderMetadata()}
                            </ListGroup>
                        </Card>
                        <Card className="mb-3">
                            <CardHeader>
                                <FA icon="envelope" className="mr-2" />Transmittals&nbsp;
                                {linkedTransmittals != null && <span className="text-muted">({linkedTransmittals.length})</span>}
                            </CardHeader>
                            <LinkedTransmittals transmittals={linkedTransmittals} />
                        </Card>
                        <FeatureGate features={features} flag={FeatureFlag.Costs} value={CostFlagValue.Default}>
                            {() => (
                                <>
                                    <Card className="mb-3">
                                        <CardHeader>
                                            <FA icon="envelope" className="mr-2" />Commitments&nbsp;
                                            {revision.links.commitments != null && <span className="text-muted">({revision.links.commitments.length})</span>}
                                        </CardHeader>
                                        <LinkedCommitments projectId={project.id} commitments={revision.links.commitments} />
                                    </Card>
                                    <TrackingList header="Revision Activity" trackingEntries={revision.tracking} />
                                </>
                            )}
                        </FeatureGate>
                    </Col>
                </Row>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps): IProps & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active,
        user: state.session.user,
        features: state.session.features
    }
}

export default connect(mapStateToProps)(DocumentPreviewPage)

const LinkedTransmittals = ({ transmittals }: { transmittals: TransmittalBrief[] }) => {
    return transmittals?.length > 0
        ? (
            <Table>
                <thead>
                    <tr>
                        <th>Subject</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {transmittals.map((t, idx) => (
                        <tr key={idx}>
                            <td>
                                <div>
                                <TransmittalStatusIndicator transmittal={t} />
                                <Link to={Routes.projectTransmittal(t.projectId, t.id)}>{t.subject}</Link>
                                </div>
                            </td>
                            <td>{capitalize(t.status)}</td>
                            <td>{localDateTime(t.updatedDate)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
        : <ListGroup className="small" flush><ListGroupItem className="text-center">Revision has not been sent in any transmittals</ListGroupItem></ListGroup>
}

const LinkedCommitments = ({ projectId, commitments }: { projectId: string, commitments: CommitmentLink[] }) => {
    return commitments?.length > 0
        ? (
            <Table>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {commitments.map((c, idx) => (
                        <tr key={idx}>
                            <td><Link to={Routes.projectCostsCommitmentType(projectId, c.type)}>{c.type}</Link></td>
                            <td><Link to={Routes.projectCostsCommitmentTypeDetail(projectId, c.type, c.id)}>{c.name}</Link></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
        : <ListGroup className="small" flush><ListGroupItem className="text-center">Revision has not been linked in project costs</ListGroupItem></ListGroup>
}
