import React from 'react'

import cx from 'classnames'

import Breadcrumb from '@src/components/navigation/Breadcrumb'

interface IProps {
    title: React.ReactChild
    wrapperClassName?: string;
    noBreadcrumbs?: boolean;
}

export default class Banner extends React.PureComponent<IProps> {

    public render() {
        return (
            <section className={cx('banner', this.props.wrapperClassName)}>
                <div className="banner__inner">
                    <div className="banner__panel banner__panel--lead">
                        {!this.props.noBreadcrumbs && <Breadcrumb/>}
                        <h1>{this.props.title}</h1>
                    </div>
                    {this.props.children}
                </div>
            </section>
        )
    }
}
