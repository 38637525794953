import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Button, Col, Container, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { IOption } from '@src/components/common/Select'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { FieldPrefix, PrefixedField } from '@src/components/forms/FieldPrefix'
import DocumentApproverField from '@src/components/forms/fields/DocumentApproverField'
import { ValidatedGenericInput } from '@src/components/metadata/MetadataInputs'
import MetadataTooltip from '@src/components/metadata/MetadataTooltip'
import { buildValidator } from '@src/components/metadata/validator'
import { IModalProps } from '@src/hooks/useModal'
import { paymentLabel, paymentValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CommunicationPreviewPdfBlob, DocumentCreate, DocumentsList, SandboxSignUpload } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Manager as StorageManager } from '@src/logic/storage/gcs/GCSUploadManager'
import { AclEntry } from '@src/types/access'
import { Communication } from '@src/types/communication'
import { CostsOverview } from '@src/types/costs'
import { DocumentSearchMode } from '@src/types/document'
import { MetadataTypes, PaymentClaimLinksDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IProps {
    communication: Communication
}

interface IFormaliseCommunicationValues {
    name: string
    version: string
    date: Date
    tags: IOption<string>[]
    author: string
    description: string
    metadata: {
        [key: string]: any
    }
    approver: AclEntry
    approvalRequestComments: string
}

export function UploadCommunicationModal({ communication, ...modalProps }: IProps & IModalProps) {
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const costsOverview = useSelector<RootState, CostsOverview>(s => s.projects.activeCostsOverview)
    const metadataDefinitionsAsync = useAsyncAbortable(async (abortSignal) => {
        const response = await DocumentsList(project.id, DocumentSearchMode.LatestNonArchived, undefined, undefined, 1, 1, { abortSignal })
        return response.data.metadataDefinitions
    }, [])

    const paymentDefinition = communication.metadataDefinitions.find(x => x.type === MetadataTypes.PaymentClaimLinks) as PaymentClaimLinksDefinition
    const paymentCategories = paymentDefinition
        ? communication.metadata[paymentDefinition.key]
        : []

    async function handleUpload(values: IFormaliseCommunicationValues, form) {
        const formaliseNotification = NotificationService.pendingActivityToast('Formalising communication')
        modalProps.toggle()
        // Download the communication
        const blob = (await CommunicationPreviewPdfBlob(communication.id)).data
        // Create the document
        try {
            const fileName = `${communication.name.trim()}.pdf`
            const file = new File([await blob.arrayBuffer()], fileName, { type: 'application/pdf' })
            const signedUrlResponse = (await SandboxSignUpload({ contentType: file.type, fileName: encodeURI(fileName), length: file.size })).data
            // eslint-disable-next-line @typescript-eslint/no-misused-promises,no-async-promise-executor
            await new Promise<void>(async (resolve, reject) => {
                try {
                    const upload = await StorageManager.newSignedUpload(signedUrlResponse.name, signedUrlResponse.url, file, () => {}, (uploadId) => {
                        DocumentCreate(project.id, {
                            approvalRequestComments: '',
                            requestedApprover: values.approver.id,
                            overrideDocumentPermissions: undefined,
                            overrideRevisionPermissions: undefined,
                            revisions: [{
                                author: values.author,
                                date: values.date,
                                description: values.description,
                                fileUuid: uploadId,
                                metadata: values.metadata,
                                name: values.name,
                                tags: [], // values.tags.map(x => x.value),
                                version: values.version
                            }]
                        }).then(() => resolve())
                    })
                    StorageManager.startUpload(upload.id)
                } catch (e) {
                    reject(e)
                }
            })
        } catch {}

        NotificationService.updateThenCloseToast(formaliseNotification, 'Communication formalised')
        // Cleanup if failed to upload

        // Link to commitments, payments
    }

    function renderMetadataInputs() {
        return metadataDefinitionsAsync.result.map((md, idx) => (
            <Col tag={FormGroup} xs={12} md={6} lg={3} key={md.key} className="mb-3">
                <Label>{md.name} <MetadataTooltip id={`meta-tooltip-${md.key}`} definition={md} placement="right" /></Label>
                <PrefixedField name={md.key} component={ValidatedGenericInput} definition={md} validate={buildValidator(md)} size="sm" />
            </Col>
        ))
    }

    return <Form<IFormaliseCommunicationValues> onSubmit={handleUpload}
        initialValues={{
            name: communication.name,
            date: new Date(),
            author: communication.createdBy.name

        }}
    >
        {({ handleSubmit }) => (
            <Modal {...modalProps} size="lg" unmountOnClose={false}>
                <ModalHeader toggle={modalProps.toggle}>Formalise communication</ModalHeader>
                <ModalBody>
                    {metadataDefinitionsAsync.result
                        ? (
                            <Container fluid>
                                <h5 className="border-bottom pb-2">Metadata</h5>
                                <Row>
                                    <Col tag={FormGroup} xs={12} md={6} lg={3} className="mb-3">
                                        <Label>Document Name</Label>
                                        <Field name="name" component={ValidatedInput} validate={required} bsSize="sm" />
                                    </Col>
                                    <Col tag={FormGroup} xs={12} md={6} lg={3} className="mb-3">
                                        <Label className="text-nowrap">Revision #</Label>
                                        <Field name="version" component={ValidatedInput} bsSize="sm" />
                                    </Col>
                                    <Col tag={FormGroup} xs={12} md={6} lg={3} className="mb-3">
                                        <Label>Revision Date</Label>
                                        <Field name="date" component={ValidatedDatePicker} size="sm" />
                                    </Col>
                                    <Col tag={FormGroup} xs={12} md={6} lg={3} className="mb-3">
                                        <Label>Author</Label>
                                        <Field name="author" component={ValidatedInput} bsSize="sm" initialValue={communication.createdBy.name} />
                                    </Col>
                                    <Col tag={FormGroup} xs={12} md={6} lg={3} className="mb-3">
                                        <Label>Tags</Label>
                                        <Field name="tags" isMulti selectType="creatable" component={ValidatedSelect} size="sm" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col tag={FormGroup} xs={12} md={8} lg={12} className="mb-3">
                                        <Label>Description</Label>
                                        <Field name="description" type="textarea" component={ValidatedInput} bsSize="sm" initialValue={communication.description} />
                                    </Col>
                                </Row>
                                <Row>
                                    <FieldPrefix prefix="metadata">
                                        {renderMetadataInputs()}
                                    </FieldPrefix>
                                </Row>
                                <h5 className="border-bottom pb-2">Approval</h5>
                                <Row>
                                    <Col tag={FormGroup} lg={6}>
                                        <Label>Approver</Label>
                                        <DocumentApproverField name="approver" projectId={project.id} validate={required} />
                                    </Col>
                                    <Col tag={FormGroup} lg={12}>
                                        <Label>Comments</Label>
                                        <Field name="approvalRequestComments" type="textarea" component={ValidatedInput} />
                                    </Col>
                                </Row>
                                <h5 className="border-bottom pb-2">Options</h5>
                                <Row>
                                    {costsOverview && paymentDefinition && <Col tag={FormGroup} lg={6}>
                                        <Label>Link to payment claim</Label>
                                        <Field name="linkPaymentCategories" component={ValidatedSelect} options={paymentCategories} getOptionValue={paymentValue} getOptionLabel={paymentLabel} />
                                    </Col>}
                                </Row>
                            </Container>
                        )
                        : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Formalise</Button>
                    <Button color="default" onClick={modalProps.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )}
    </Form>
}
