import React from 'react'

import useUpdateEffect from '@src/hooks/useUpdateEffect'

export default function useTouched(flag: boolean, dependencies: unknown[]) {
    const [state, setState] = React.useState(flag)
    useUpdateEffect(() => {
        setState(state || flag)
    }, [flag])

    useUpdateEffect(() => {
        setState(flag)
    }, dependencies)

    return state
}
