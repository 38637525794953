import React from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Form as BootstrapForm, Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { FORM_ERROR } from 'final-form'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedInput from '@src/components/common/ValidatedInput'
import useBoolean from '@src/hooks/useBoolean'
import { auth } from '@src/logic/auth/AuthService'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { composeValidators, matchField, required, validPassword } from '@src/logic/forms/validation'
import { AccountPasswordUpdate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { RootState } from '@src/types/models'

export interface IChangePasswordFormData {
    oldPassword: string
    newPassword: string
    confirmNewPassword: string
}

function validateChangePasswordForm(values: IChangePasswordFormData) {
    if (values.confirmNewPassword !== values.newPassword) {
        return {
            confirmNewPassword: 'Passwords do not match'
        }
    }

    return undefined
}

const newPasswordValidator = composeValidators(required, validPassword)
const confirmPasswordValidator = matchField<IChangePasswordFormData>('newPassword', 'Passwords do not match')

const ChangePasswordForm: React.FC = () => {
    const showResetPasswordDialog = useBoolean(false)
    const userEmail = useSelector<RootState, string>(s => s.session.user.email)

    function resetPassword() {
        showResetPasswordDialog.setFalse()
        return auth.resetPassword(userEmail)
    }

    async function updatePassword(values: IChangePasswordFormData) {
        const changePasswordRequest = AccountPasswordUpdate(values.oldPassword, values.newPassword)
        try {
            await changePasswordRequest
            NotificationService.info('Password updated')
        } catch (error) {
            if (isAxiosError(error) && error.response?.status === 400) {
                return buildFormErrorsFromModelState(values, error.response.data)
            }

            NotificationService.error('Error while setting password')
            return {
                [FORM_ERROR]: ''
            }
        }
    }

    return (
        <Form
            onSubmit={updatePassword}
            validate={validateChangePasswordForm}
        >
            {({ handleSubmit, submitting }) =>
                <BootstrapForm>
                    <FormGroup>
                        <Label for="oldPassword">Old password</Label>
                        <Field id="oldPassword" name="oldPassword" component={ValidatedInput} type="password" validate={required} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="newPassword">New password</Label>
                        <Field id="newPassword" name="newPassword" component={ValidatedInput} type="password" validate={newPasswordValidator} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirmNewPassword">Confirm new password</Label>
                        <Field id="confirmNewPassword" name="confirmNewPassword" component={ValidatedInput} type="password" validate={confirmPasswordValidator} />
                    </FormGroup>
                    <div>
                        <Button onClick={handleSubmit} disabled={submitting}><FA className={submitting ? '' : 'd-none'} icon="spinner-third" spin /><span className={submitting ? 'ml-2' : ''}>Update password</span></Button>
                        <Button color="link" onClick={showResetPasswordDialog.setTrue}>I forgot my password</Button>
                    </div>
                    <Modal isOpen={showResetPasswordDialog.value} toggle={showResetPasswordDialog.setFalse}>
                        <ModalHeader toggle={showResetPasswordDialog.setFalse}>Reset Password</ModalHeader>
                        <ModalBody>
                            A password reset link will be sent to <strong>{userEmail}</strong>. Are you sure you want to reset your password?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={resetPassword}>Reset password</Button>
                            <Button color="default" onClick={showResetPasswordDialog.setFalse}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </BootstrapForm>
            }
        </Form>
    )
}

export default React.memo(ChangePasswordForm)
