import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import ValidatedInput from '@src/components/common/ValidatedInput'
import { IModalProps } from '@src/hooks/useModal'
import { required } from '@src/logic/forms/validation'
import { TemplateCreate } from '@src/logic/http/Api'
import { Template } from '@src/types/communication'

interface IProps extends IModalProps {
    projectId?: string
    onTemplateCreated?: (template: Template) => void
}

interface INewTemplateFormData {
    key: string
    name: string
}

export default function NewTemplateModal({ projectId, onTemplateCreated, ...modalProps }: IProps) {
    async function handleCreateTemplate(values: INewTemplateFormData) {
        const templateResponse = await TemplateCreate({
            key: values.key,
            projectId: projectId,
            name: values.name,
            description: '',
            layout: '',
            metadataDefinitions: [],
            tags: []
        })

        onTemplateCreated?.(templateResponse.data)
    }

    return (
        <Modal {...modalProps}>
            <Form<INewTemplateFormData> onSubmit={handleCreateTemplate}>
                {({ handleSubmit, submitting }) =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>New Template</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Field id="name" name="name" component={ValidatedInput} validate={required} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="key">Code</Label>
                                <Field id="key" name="key" component={ValidatedInput} validate={required} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit} disabled={submitting}>Create</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
