import React from 'react'
import { Card, CardHeader, ListGroup, ListGroupItem, Table } from 'reactstrap'

import { localDateTime } from '@src/logic/utils/Date'
import { TrackingEntry } from '@src/types/tracking'

interface IProps {
    header: React.ReactChild
    trackingEntries: TrackingEntry[]
}

export default class TrackingList extends React.PureComponent<IProps> {
    public render() {
        const { trackingEntries } = this.props

        const body = trackingEntries.length > 0
        ? (
            <Table>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Activity</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {trackingEntries.map((e, idx) => (
                        <tr key={idx}>
                            <td>{e.principal.name}</td>
                            <td>{e.activity}</td>
                            <td>{localDateTime(e.date)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
        : (
                <ListGroup flush>
                    <ListGroupItem className="text-center">No activity</ListGroupItem>
                </ListGroup>
            )

        return (
            <Card>
                <CardHeader>{this.props.header} <span className="text-muted">({this.props.trackingEntries.length})</span></CardHeader>
                {body}
            </Card>
        )
    }
}
