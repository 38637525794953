import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import RevisionTable from '@src/components/document/RevisionTable'
import { IModalProps } from '@src/hooks/useModal'
import { Revision } from '@src/types/document'

export interface IProps extends IModalProps {
    revisions: Revision[]
    colName: string
}

export default function DocumentLinkModal({ revisions, colName, ...modalProps }: IProps) {
    return (
        <Modal {...modalProps} size="xl" unmountOnClose>
            <ModalHeader toggle={modalProps.toggle}>View - {colName}</ModalHeader>
            <ModalBody>
            <RevisionTable revisions={revisions} metadataDefinitions={[]}></RevisionTable>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}
