import React from 'react'
import ReactSelect, { Props as SelectProps, StylesConfig } from 'react-select'
import { GroupBase } from 'react-select'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import AsyncCreatable from 'react-select/async-creatable'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'

import cx from 'classnames'

import variables from 'public/styles/custom/global/_variables.scss'

type SelectSize = 'sm' | 'md' | 'lg'

export type InputActionChange = 'set-value' | 'input-change' | 'input-blur' | 'menu-close'

export interface IOption<TValue> {
    __isNew__?: boolean
    label: string
    value: TValue
    [key: string]: any
}

export type IGroupedOptions<TValue> = GroupBase<TValue>[]

const sizeStyles: {
    [key in SelectSize]: {
        [component: string]: React.CSSProperties
    }
} = {
    sm: {
        control: {
            fontSize: variables.inputFontSizeSm,
            height: variables.inputHeightSm,
            minHeight: 'unset',
            lineHeight: variables.inputLineHeightSm,
            borderRadius: variables.inputBorderRadiusSm
        },
        option: {
            fontSize: variables.inputFontSizeSm
        },
        placeholder: {
            fontSize: variables.fontSizeSm
        },
        dropdownIndicator: {
            padding: '0px 8px'
        },
        clearIndicator: {
            padding: '0px 8px'
        },
        valueContainer: {
            padding: '0px 8px'
        }
    },
    md: {
        control: {
            borderRadius: variables.inputBorderRadius
        },
        option: {
            fontSize: variables.fontBaseSize
        },
        placeholder: {
            fontSize: variables.fontSizeBase
        },
        dropdownIndicator: {},
        clearIndicator: {},
        valueContainer: {}
    },
    lg: {
        control: {
            fontSize: variables.inputFontSizeLg,
            height: variables.inputHeightLg,
            lineHeight: variables.inputLineHeightLg,
            borderRadius: variables.inputBorderRadiusLg
        },
        option: {
            fontSize: variables.inputFontSizeLg
        },
        placeholder: {
            fontSize: variables.fontSizeLg
        },
        dropdownIndicator: {},
        clearIndicator: {},
        valueContainer: {}
    }
}

type SelectType = 'async' | 'creatable' | 'async-creatable'

interface IDefaultProps<TValue, TIsMulti extends boolean> extends SelectProps<TValue, TIsMulti, GroupBase<TValue>> {
    selectType?: undefined
}

interface IAsyncProps<TValue, TIsMulti extends boolean> extends AsyncProps<TValue, TIsMulti, GroupBase<TValue>> {
    selectType?: 'async'
}

interface ICreatableProps<TValue, TIsMulti extends boolean> extends CreatableProps<TValue, TIsMulti, GroupBase<TValue>> {
    selectType?: 'creatable'
}

interface IAsyncCreatableProps<TValue, TIsMulti extends boolean> extends AsyncProps<TValue, TIsMulti, GroupBase<TValue>>, CreatableProps<TValue, TIsMulti, GroupBase<TValue>> {
    selectType?: 'async-creatable'
}

type IUnionProps<TValue, TIsMulti extends boolean> = IDefaultProps<TValue, TIsMulti> | IAsyncProps<TValue, TIsMulti> | ICreatableProps<TValue, TIsMulti> | IAsyncCreatableProps<TValue, TIsMulti>

export type IProps<TValue, TIsMulti extends boolean = boolean> = IUnionProps<TValue, TIsMulti> & {
    size?: SelectSize
    wrapperClass?: string
}

function defaultSelectStyles<TValue, TIsMulti extends boolean>(selectSize: SelectSize): StylesConfig<TValue, TIsMulti> {
    return {
        control: (base, state) => ({
            ...base,
            background: state.isDisabled ? variables.inputDisabledBg : (state.isFocused ? variables.inputFocusBg : variables.inputBg),
            borderColor: state.isFocused ? variables.inputFocusBorderColor : variables.inputBorderColor,
            boxShadow: state.isFocused ? variables.inputFocusBoxShadow : null,
            borderWidth: variables.inputBorderWidth,
            fontWeight: 400,
            ...(sizeStyles[selectSize].control)
        }),
        input: (base, state) => ({
            ...base
        }),
        option: (base, state) => ({
            ...base,
            fontWeight: 400,
            ...(sizeStyles[selectSize].option),
            ...(state.isSelected && { backgroundColor: variables.inputFocusBorderColor })
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            ...(sizeStyles[selectSize].dropdownIndicator)
        }),
        valueContainer: (base, state) => ({
            ...base,
            ...(sizeStyles[selectSize].valueContainer)
        }),
        clearIndicator: (base, state) => ({
            ...base,
            ...(sizeStyles[selectSize].clearIndicator)
        }),
        placeholder: (base, state) => ({
            ...base,
            color: variables.inputPlaceholderColor,
            ...(sizeStyles[selectSize].placeholder)
        }),
        loadingIndicator: (base, state) => ({
            ...base
        })
    }
}

export function buildOptions(values: string[]): IOption<string>[] {
    return values?.map(x => ({ label: x, value: x })) ?? []
}

function getSelectTag(type?: SelectType): CreatableSelect | AsyncSelect | AsyncCreatable | ReactSelect {
    switch (type) {
        case 'creatable':
            return CreatableSelect
        case 'async':
            return AsyncSelect
        case 'async-creatable':
            return AsyncCreatable
        default:
            return ReactSelect
    }
}

function swallowEvent(e: React.MouseEvent) {
    e.stopPropagation()
}

function defaultFilter(option: IOption<any>, rawInput: string) {
    const words = rawInput.split(' ')
    return words.reduce(
        (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
        true
    )
}

export default function Select<TValue, TIsMulti extends boolean = boolean>({ size, selectType, id, styles, wrapperClass, ...selectProps }: IProps<TValue, TIsMulti>) {
    const selectStyles: StylesConfig<TValue, TIsMulti> = React.useMemo(() => styles ?? defaultSelectStyles(size ?? 'md'), [styles, size])
    // const filter = filterOption
    const SelectTag = getSelectTag(selectType)

    return (
        <div role="presentation" onClick={swallowEvent} className={cx('react-select', wrapperClass)}>
            <SelectTag
                {...selectProps}
                classNamePrefix="react-select"
                inputId={id}
                menuPlacement="auto"
                styles={selectStyles}
                className={cx({ 'react-select-invalid': selectProps['aria-invalid'] })}
            />
        </div>
    )
}
