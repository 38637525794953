import React from 'react'
import { Col, ColProps } from 'reactstrap'

import cx from 'classnames'

function Cell(props: ColProps) {
    const { children, className, ...rest } = props
    return (
        <Col className={cx(className, 'selectable-content__cell')} {...rest}>
            {children}
        </Col>
    )
}

export default React.memo(Cell)
