import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFeedback, Input, InputProps } from 'reactstrap'

import { v4 } from 'uuid'

export type IProps<TValue> = RemoveIndex<InputProps> & React.InputHTMLAttributes<HTMLInputElement> & FieldRenderProps<TValue>

export default class ValidatedInput<TValue extends string | number | readonly string[]> extends React.PureComponent<IProps<TValue>> {
    private readonly id: string

    constructor(props: IProps<TValue>) {
        super(props)
        this.id = props.id ?? v4()
    }

    public render() {
        const { input, meta: { touched, error, ...otherMeta }, type, ...htmlInputProps } = this.props
        const submitError = otherMeta.submitError
        const showError = touched && (!!error || (!!submitError && !otherMeta.dirtySinceLastSubmit))
        const inputType = type as InputProps['type']

        const ariaErrorMessage = htmlInputProps['aria-errormessage'] ?? `${this.id}-errorMessage`

        return (
            <>
                <Input {...htmlInputProps} aria-errormessage={ariaErrorMessage} {...input} {...(inputType && { type: inputType })} invalid={showError} {...showError ? { 'aria-invalid': true } : {}} />
                <FormFeedback id={ariaErrorMessage}>
                    {showError && (error || submitError)}
                </FormFeedback>
            </>
        )
    }
}
