import React from 'react'
import { Field, Form } from 'react-final-form'
import { Form as BootstrapForm, Button, FormGroup, FormText, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { IModalProps } from '@src/hooks/useModal'
import { composeValidators, nonNegative, required } from '@src/logic/forms/validation'
import { PhaseCreate, PhaseUpdate } from '@src/logic/http/Api'
import { Phase } from '@src/types/costs'

export interface IPhaseFormData {
    code: string
    name: string
    description: string
    approvedValue: number
    locked: boolean
}

interface IProps extends IModalProps {
    projectId: string
    existingPhases: Phase[]
    phaseToEdit?: Phase
    onCreateOrEdit?: (phase: Phase) => void
}

export default function PhaseModal({ projectId, phaseToEdit, existingPhases, onCreateOrEdit, ...modalProps }: IProps) {
    const codeValidator = React.useMemo(() => composeValidators<string, IPhaseFormData>(required, uniquePhase), [existingPhases, phaseToEdit])
    async function doSubmit(values: IPhaseFormData) {
        if (phaseToEdit != null) {
            await PhaseUpdate(projectId, phaseToEdit.code, {
                name: values.name,
                description: values.description,
                approvedValue: values.approvedValue,
                locked: values.locked
            })
            onCreateOrEdit?.({
                name: values.name,
                description: values.description,
                approvedValue: values.approvedValue,
                locked: values.locked,
                code: phaseToEdit.code
            })
        } else {
            const response = await PhaseCreate(projectId, {
                ...values,
                approvedValue: values.approvedValue
            })
            onCreateOrEdit?.(response.data)
        }

        modalProps.toggle()
    }

    function normalizeCode(value: string) {
        return value ? value.toUpperCase() : value
    }

    function uniquePhase(value: string) {
        if (existingPhases.length < 1) return

        if (phaseToEdit != null) return

        return existingPhases.find(p => p.code === value) == null ? undefined : 'Must be unique'
    }

    return (
        <Modal {...modalProps}>
            <Form<IPhaseFormData>
                onSubmit={doSubmit}
                initialValues={{
                    approvedValue: phaseToEdit?.approvedValue,
                    code: phaseToEdit?.code,
                    description: phaseToEdit?.description,
                    locked: phaseToEdit?.locked,
                    name: phaseToEdit?.name
                }}
            >
                {({ handleSubmit, values }) =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>{phaseToEdit ? 'Edit Phase' : 'New Phase'}</ModalHeader>
                        <ModalBody>
                            <BootstrapForm>
                                <FormGroup>
                                    <Label for='phase-code'>Code</Label>
                                    <Field id='phase-code' name="code" disabled={phaseToEdit != null} component={ValidatedInput} validate={codeValidator} parse={normalizeCode} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='phase-name'>Name</Label>
                                    <Field id='phase-name' name="name" component={ValidatedInput} validate={required} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='phase-description'>Description</Label>
                                    <Field id='phase-description' name="description" component={ValidatedInput} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='phase-approvedValue'>Approved Value</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Field id='phase-approvedValue' name="approvedValue" component={ValidatedCurrencyInput} disabled={values.locked} validate={nonNegative} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Field name="locked" component={ValidatedCheckboxRadio} label="Locked" />
                                    <FormText>When a phase is locked, budget items and commitments can no longer be assigned to it.</FormText>
                                </FormGroup>
                            </BootstrapForm>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit}>{phaseToEdit ? 'Save' : 'Create'}</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
