export enum FeatureFlag {
    DocumentModule = 'document-module',
    Communications = 'communications',
    Costs = 'cost-module',
    Registers = 'registers',
    GlobalSearch = 'global-search'
}

export enum DocumentFlagValue {
    Default = 'default'
}
export enum CommunicationFlagValue {
    Default = 'default'
}
export enum CostFlagValue {
    Default = 'default'
}
export enum RegisterFlagValue {
    Default = 'default',
    Beta = 'beta'
}

export enum GlobalSearchFlagValue {
    Default = 'default'
}

export interface Features {
    [FeatureFlag.DocumentModule]: DocumentFlagValue
    [FeatureFlag.Communications]: CommunicationFlagValue
    [FeatureFlag.Costs]: CostFlagValue
    [FeatureFlag.Registers]: RegisterFlagValue
    [FeatureFlag.GlobalSearch]: GlobalSearchFlagValue
}

export function hasFeature<TFlag extends FeatureFlag>(features: Features, flag: TFlag, value: Features[TFlag] | Features[TFlag][]): boolean {
    return value instanceof Array ? value.some(v => features[flag] === v) : features[flag] === value
}
