import React from 'react'
import { Form } from 'react-final-form'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import arrayMutators from 'final-form-arrays'

import BudgetItemFormBody, { IBudgetItemFormData } from '@src/components/costs/budget/BudgetItemFormBody'
import { IModalProps } from '@src/hooks/useModal'
import { BudgetItemCreate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { BudgetReportColumn, CostsOverview, StatusColumnValueMap } from '@src/types/costs'

interface IProps extends IModalProps {
    costsOverview: CostsOverview
    projectId: string
    onItemAdded: () => void
}

export function BudgetItemAddModal({ costsOverview, projectId, onItemAdded, ...modalProps }: IProps) {
    const initialValues = React.useMemo<Partial<IBudgetItemFormData>>(() => ({
        date: new Date(),
        status: (costsOverview.budget.locked ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions)[0],
        statusColumnValues: (costsOverview.budget.locked ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions).map(sd => ({
            status: sd.code,
            unreported: 0,
            original: 0,
            adjustment: 0
        }))
    }), [costsOverview])

    async function createItemOrAdjustment(values: IBudgetItemFormData) {
        const {
            locked: budgetLocked,
            itemStatusDefinitions: itemStatuses,
            adjustmentStatusDefinitions: adjustmentStatuses
        } = costsOverview.budget
        try {
            await BudgetItemCreate(projectId, {
                isAdjustment: budgetLocked,
                name: values.name,
                costCode: values.costCode.code,
                description: '',
                phaseId: values.phase ? values.phase.code : null,
                status: values.status.code,
                date: new Date(),
                tags: [],
                values: (budgetLocked ? adjustmentStatuses : itemStatuses).map(sd => sd.columns.map<StatusColumnValueMap<BudgetReportColumn>>((col, colIdx) => ({
                    column: col,
                    status: sd.code,
                    value: {
                        quantity: 0,
                        unit: '',
                        rate: 0,
                        notes: '',
                        value: values.statusColumnValues.find(x => x.status === sd.code)[col]
                    }
                }))).reduce((a, b) => a.concat(b), []),
                clientApprovalNumber: values.clientApprovalNumber,
                clientApprovalDocuments: values.clientApprovalDocuments
            })
        } catch (e) {
            NotificationService.error('Failed to create budget item')
            throw e
        }

        NotificationService.info('Created Budget Item')
        onItemAdded?.()
        modalProps.toggle()
    }

    return (
        <Modal size="lg" {...modalProps}>
            <Form onSubmit={createItemOrAdjustment} mutators={{ ...arrayMutators }} initialValues={initialValues}>
                {({ handleSubmit }) => (
                    <>
                        <ModalHeader toggle={modalProps.toggle}>{`New ${(costsOverview.budget.locked ? 'adjustment' : 'item')}`}</ModalHeader>
                        <ModalBody>
                            <BudgetItemFormBody
                                costsOverview={costsOverview}
                                isAdjustment={costsOverview.budget.locked}
                                projectId={projectId}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleSubmit}>Create</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}
