import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import ValidatedInput from '@src/components/common/ValidatedInput'
import { IModalProps } from '@src/hooks/useModal'
import { required } from '@src/logic/forms/validation'
import { CompanyUserInvite } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'

interface IInviteUserFormValues {
    email: string
    firstName: string
    lastName: string
    mobile: string
    phone: string
    role: string
    groupIds: string[]
}

export default function InviteUserForm(modalProps: IModalProps) {
    async function handleSubmit(values: IInviteUserFormValues): Promise<any> {
        try {
            await CompanyUserInvite({
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                mobile: values.mobile,
                phone: values.phone,
                role: values.role,
                groupIds: []
            })
            NotificationService.info('Invited user')
        } catch {
            NotificationService.error('An error occured while inviting the user. Refresh and try again.')
        } finally {
            modalProps.toggle()
        }
    }

    return (
        <Modal {...modalProps}>
            <Form onSubmit={handleSubmit}>
                {formProps =>
                    <>
                        <ModalHeader>Invite User</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs={12} className="mb-3">
                                    <Label for="email">Email</Label>
                                    <Field id="email" name="email" component={ValidatedInput} validate={required} />
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Label for="firstName">First Name</Label>
                                    <Field id="firstName" name="firstName" component={ValidatedInput} />
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Label for="lastName">Last Name</Label>
                                    <Field id="lastName" name="lastName" component={ValidatedInput} />
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Label for="mobile">Mobile</Label>
                                    <Field id="mobile" name="mobile" component={ValidatedInput} />
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Label for="phone">Phone</Label>
                                    <Field id="phone" name="phone" component={ValidatedInput} />
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Label for="role">Role</Label>
                                    <Field id="role" name="roles" component={ValidatedInput} />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={formProps.submitting} color="primary" className="mr-2" onClick={formProps.handleSubmit}>Invite</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
