import React from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { Button, Col, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, Row } from 'reactstrap'

import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { FieldPrefix, PrefixedField } from '@src/components/forms/FieldPrefix'
import { projectLabel, projectValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CostsCreateFromProject, DocumentsList, ProjectAccessEntityType, ProjectAccessGet, ProjectAccessUpdate, ProjectCreate, ProjectMetadataAdd, ProjectSettingsDefaultAccessGet, ProjectSettingsDefaultAccessUpdate, ProjectsList, RegisterColumnAdd, RegisterCreate, RegistersList } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { project } from '@src/logic/routing/routes'
import { AclEntry } from '@src/types/access'
import { Api } from '@src/types/api'
import { BaseMetadataTypes, IEntityPropertyDefinition, INewMetadataDefinition } from '@src/types/metadata'
import { CollaboratorSettings, Project } from '@src/types/project'

interface ICopyProjectFormData {
    name: string
    code: string
    toCopy: Project
    copySettings: {
        permissions: boolean
        metadata: boolean
        registers: boolean
        costs: boolean
    }
}

async function copyMetadata(fromId: string, toId: string) {
    const metadataDefinitions = (await DocumentsList(fromId, undefined, undefined, undefined, undefined, undefined, undefined)).data.metadataDefinitions
    for (const definition of metadataDefinitions) {
        await ProjectMetadataAdd(toId, {
            key: definition.key,
            name: definition.name,
            type: definition.type as BaseMetadataTypes,
            description: definition.description,
            isRequired: definition.isRequired,
            tags: definition.tags,
            options: (definition.options != null ? definition.options : {})
        })
    }
}

function getDefaultAclForEntityType(collaboratorSettings: CollaboratorSettings, entityType: ProjectAccessEntityType): AclEntry[] {
    switch (entityType) {
        case ProjectAccessEntityType.Communication:
            return collaboratorSettings.defaultCommunicationAcl.acl
        case ProjectAccessEntityType.Document:
            return collaboratorSettings.defaultDocumentAcl.acl
        case ProjectAccessEntityType.InboundEmail:
            return collaboratorSettings.defaultInboundEmailAcl.acl
        case ProjectAccessEntityType.Register:
            return collaboratorSettings.defaultRegisterAcl.acl
        case ProjectAccessEntityType.Revision:
            return collaboratorSettings.defaultRevisionAcl.acl
        case ProjectAccessEntityType.Transmittal:
            return collaboratorSettings.defaultTransmittalAcl.acl
        default:
            return []
    }
}

async function copyProjectPermissions(fromId: string, to: Project) {
    const toCopyAccess = (await ProjectAccessGet(fromId)).data.acl
    await ProjectAccessUpdate(
        to.id,
        toCopyAccess.reduce<Api.Request.AccessControlListUpdate>(
            (agg, entry) => ({
                grant: { ...agg.grant, [entry.id]: entry.grants },
                revoke: agg.revoke,
                deny: { ...agg.deny, [entry.id]: entry.denials },
                undeny: agg.undeny,
                addAdmin: entry.isAdministrator ? [...agg.addAdmin, entry.id] : agg.addAdmin,
                removeAdmin: agg.removeAdmin
            }),
            {
                grant: {},
                revoke: {},
                deny: {},
                undeny: {},
                addAdmin: [],
                removeAdmin: []
            }
        )
    )
    const projectSettings = await ProjectSettingsDefaultAccessGet(fromId)
    // tslint:disable-next-line: forin
    for (const entityType in ProjectAccessEntityType) {
        await ProjectSettingsDefaultAccessUpdate(
            to.id,
            ProjectAccessEntityType[entityType],
            getDefaultAclForEntityType(projectSettings.data, ProjectAccessEntityType[entityType]).reduce<Api.Request.AccessControlListUpdate>(
                (agg, entry) => ({
                    grant: { ...agg.grant, [entry.id]: entry.grants },
                    revoke: agg.revoke,
                    deny: { ...agg.deny, [entry.id]: entry.denials },
                    undeny: agg.undeny,
                    addAdmin: entry.isAdministrator ? [...agg.addAdmin, entry.id] : agg.addAdmin,
                    removeAdmin: agg.removeAdmin
                }),
                {
                    grant: {},
                    revoke: {},
                    deny: {},
                    undeny: {},
                    addAdmin: [],
                    removeAdmin: []
                }
            )
        )
    }
}

async function copyProjectCosts(fromId: string, toId: string) {
    await CostsCreateFromProject(toId, fromId, { clearBudget: true, overwriteExisting: true })
}

async function copyProjectRegisters(fromId: string, toId: string) {
    const registersResponse = await RegistersList(fromId)

    for (const register of registersResponse.data) {
        const newRegister = (await RegisterCreate(toId, {
            name: register.name,
            description: register.description,
            tags: register.tags
        })).data

        for (const { metadataDefinition: metaDef, styles } of register.columns) {
            await RegisterColumnAdd(newRegister.id, {
                metadataDefinition: {
                    description: metaDef.description,
                    isRequired: metaDef.isRequired,
                    key: metaDef.key,
                    name: metaDef.name,
                    options: metaDef.options,
                    tags: metaDef.tags,
                    type: (metaDef.type as INewMetadataDefinition['type']),
                    ...((metaDef as IEntityPropertyDefinition<any, any>).parentKey ? { parentKey: (metaDef as IEntityPropertyDefinition<any, any>).parentKey } : {})
                },
                styles
            })
        }
    }
}

const CopyProjectModal: React.FC<ModalProps> = ({ ...modalProps }) => {
    const history = useHistory()

    async function createProject(values: ICopyProjectFormData): Promise<void> {
        const toCopy = values.toCopy
        let createdProject: Project

        const creatingNotification = NotificationService.pendingActivityToast('Copying project...')

        try {
            createdProject = (await ProjectCreate({
                category: null,
                code: values.code,
                description: '',
                name: values.name,
                finishDate: null,
                startDate: null,
                status: null,
                tags: [],
                value: null
            })).data
        } catch (err) {
            if (isAxiosError(err) && err.response.status === 403) {
                NotificationService.updateThenCloseToast(creatingNotification, 'Failed to copy project. You do not have permission to create projects.', { type: 'error' })
            } else {
                NotificationService.updateThenCloseToast(creatingNotification, 'Failed to copy project.', { type: 'error' })
            }
        }

        const metadataPromise = values.copySettings.metadata ? copyMetadata(toCopy.id, createdProject.id).catch(() => NotificationService.error('Failed to copy metadata.')) : Promise.resolve()
        const costsPromise = values.copySettings.costs
? copyProjectCosts(toCopy.id, createdProject.id).catch((err) => {
            if (isAxiosError(err) && err.response.status === 404) return
            NotificationService.error('Failed to copy project costs.')
        })
: Promise.resolve()
        const registerPromise = values.copySettings.registers
? copyProjectRegisters(toCopy.id, createdProject.id)
            .catch(() => NotificationService.error('Failed to copy registers.'))
: Promise.resolve()

        await Promise.all([metadataPromise, costsPromise, registerPromise])

        if (values.copySettings.permissions) {
            await copyProjectPermissions(toCopy.id, createdProject).catch(() => NotificationService.error('Failed to copy permissions.'))
        }

        NotificationService.removeToast(creatingNotification)

        history.push(project(createdProject.id))
    }

    async function filterProjects(input: string): Promise<Project[]> {
        const filter = input ? `name: "${input}" code: "${input}"` : undefined
        const response = await ProjectsList(filter, undefined, 1, 10)
        return response.data
    }

    return (
        <Modal {...modalProps}>
            <Form<ICopyProjectFormData>
                onSubmit={createProject}
                initialValues={{
                    name: '',
                    code: '',
                    toCopy: undefined,
                    copySettings: {
                        permissions: true,
                        metadata: true,
                        registers: true,
                        costs: true
                    }
                }}
                subscription={{}}
            >
                {({ handleSubmit }) => (
                    <>
                        <ModalHeader toggle={modalProps.toggle as React.MouseEventHandler}>Copy Existing Project</ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Col>
                                    <Label>Project Name</Label>
                                    <Field name="name" component={ValidatedInput} validate={required} />
                                    <FormText />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Label>Code</Label>
                                    <Field name="code" component={ValidatedInput} validate={required} />
                                    <FormText />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Label>Project to Copy</Label>
                                    <Field
                                        name="toCopy"
                                        selectType="async"
                                        cacheOptions
                                        defaultOptions
                                        component={ValidatedSelect}
                                        validate={required}
                                        loadOptions={filterProjects}
                                        getOptionLabel={projectLabel}
                                        getOptionValue={projectValue}
                                    />
                                    <FormText />
                                </Col>
                            </FormGroup>
                            <hr />
                            <Row>
                                <Col>
                                    <FieldPrefix prefix="copySettings">
                                        <PrefixedField name="permissions" component={ValidatedCheckboxRadio} label="Permissions" />
                                        <PrefixedField name="metadata" component={ValidatedCheckboxRadio} label="Metadata" />
                                        <PrefixedField name="registers" component={ValidatedCheckboxRadio} label="Registers" />
                                        <PrefixedField name="costs" component={ValidatedCheckboxRadio} label="Costs Configuration" />
                                    </FieldPrefix>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" onClick={modalProps.toggle as React.MouseEventHandler}>Cancel</Button>
                            <Button color="primary" onClick={handleSubmit}>Create</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}

export default CopyProjectModal
