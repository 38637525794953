import React, { useEffect } from "react";
import { useAsync } from "react-async-hook";
import { useDispatch } from "react-redux";
import infopointLogo from "public/img/infopoint_white.svg";
import infopointLogoSmall from "public/img/infopoint_white_small.svg";
import Link from "@src/components/common/Link";
import NotificationService from "@src/logic/notification/NotificationService";
import * as Routes from "@src/logic/routing/routes";
import { Notification } from "@src/types/notification";
import UserMenu from "./UserMenu";
import {
  useCompanyStore,
  useContactsStore,
  useNewRegistrationsStore,
} from "@src/store/admin";
import shallow from "zustand/shallow";

interface IProps {}

function AdminHeader({}: IProps) {
  const dispatch = useDispatch();
  const notificationsAsync = useAsync(
    NotificationService.listNotifications,
    []
  );
  const [resetCompanies, loadCompanies] = useCompanyStore(
    (s) => [s.resetCompanies, s.loadCompanies],
    shallow
  );
  const [resetContacts, loadContacts] = useContactsStore(
    (s) => [s.reset, s.loadContacts],
    shallow
  );
  const [resetNewRegistrations, loadNewRegistrations] =
    useNewRegistrationsStore((s) => [s.reset, s.load], shallow);
  useEffect(
    () => NotificationService.onReceiveNotification(handleReceiveNotification),
    []
  );

  function handleReceiveNotification(notification: Notification) {
    if (notificationsAsync.loading) {
      notificationsAsync.execute();
    } else {
      notificationsAsync.merge({
        result: {
          unread: notificationsAsync.result.unread + 1,
          notifications: [
            notification,
            ...notificationsAsync.result.notifications,
          ],
        },
      });
    }
  }

  return (
    <header className="header">
      <div className="header__logo">
        <Link to={Routes.DASHBOARD}>
          <img
            className="d-none d-md-inline-block"
            src={infopointLogo}
            alt="InfoPoint Logo"
          />
          <img
            className="d-md-none"
            src={infopointLogoSmall}
            alt="InfoPoint Logo"
          />
        </Link>
        <Link
          to={Routes.ADMIN_COMPANIES}
          style={{ marginLeft: 25, verticalAlign: "middle" }}
          onClick={() => {
            resetCompanies();
            loadCompanies("");
          }}
        >
          <span className="d-inline" style={{ fontSize: 18, color: "white" }}>
            Companies
          </span>
        </Link>
        <Link
          to={Routes.ADMIN_CONTACTS}
          style={{ marginLeft: 25, verticalAlign: "middle" }}
          onClick={() => {
            resetContacts();
            loadContacts("");
          }}
        >
          <span className="d-inline" style={{ fontSize: 18, color: "white" }}>
            Contacts
          </span>
        </Link>
        <Link
          to={Routes.ADMIN_NEW_REGISTRATIONS}
          style={{ marginLeft: 25, verticalAlign: "middle" }}
          onClick={() => {
            resetNewRegistrations();
            loadNewRegistrations("");
          }}
        >
          <span className="d-inline" style={{ fontSize: 18, color: "white" }}>
            Legacy Registrations
          </span>
        </Link>
      </div>
      <UserMenu />
    </header>
  );
}

export default React.memo(AdminHeader);
