import React from "react";
import { Card, Col, Container, Row } from "reactstrap";

import LoginForm from "@src/components/auth/LoginForm";
import ResetPasswordForm from "@src/components/auth/ResetPasswordForm";

import infopointLogo from "public/img/infopoint_colour.svg";

interface IState {
  showPasswordReset: boolean;
}

export default class LoginPage extends React.PureComponent<{}, IState> {
  constructor(props) {
    super(props);

    this.state = {
      showPasswordReset: false,
    };
  }

  private handleForgotPassword = () => {
    this.setState({ showPasswordReset: true });
  };

  private handleBackToLogin = () => {
    this.setState({ showPasswordReset: false });
  };

  public render() {
    const { showPasswordReset } = this.state;
    return (
      <div
        className="flex-grow-1 d-flex"
        style={{
          background:
            "linear-gradient(135deg, rgba(2,21,79,1) 0%, rgba(37,64,146,1) 100%)",
        }}
      >
        <Container className="my-auto">
          <Row>
            <Col sm={9} md={7} lg={5} className="mx-auto">
              <Card
                body
                className="my-7"
                style={{ boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)" }}
              >
                <Col
                  xs={12}
                  className="justify-content-center align-items-center text-center"
                >
                  <div>
                    <img
                      className="mt-2 mb-2 mx-auto"
                      width="200"
                      src={infopointLogo}
                      alt="InfoPoint Logo"
                    />
                    <h1 className="mt-3 mx-auto font-weight-bold">Welcome</h1>
                    <h4>Login to InfoPoint to continue</h4>
                  </div>
                </Col>

                {!showPasswordReset && (
                  <LoginForm onForgotPassword={this.handleForgotPassword} />
                )}
                {showPasswordReset && (
                  <ResetPasswordForm onCancel={this.handleBackToLogin} />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
