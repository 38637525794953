import React from 'react'
import { Button, Card, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import LinkAction from '@src/components/common/LinkAction'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CommitmentItemModal from '@src/components/costs/commitments/CommitmentItemModal'
import DocumentLinksModal from '@src/components/costs/commitments/DocumentLinksModal'
import CostValue from '@src/components/costs/common/CostValue'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import useBoolean from '@src/hooks/useBoolean'
import useLocalSearch from '@src/hooks/useLocalSearch'
import useModal from '@src/hooks/useModal'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import { CommitmentItemDelete } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { localLongDate } from '@src/logic/utils/Date'
import { capitalize, toPercent } from '@src/logic/utils/Strings'
import { mutedNotSet, valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { Commitment, CommitmentDefinition, CommitmentItem, CostsOverview } from '@src/types/costs'
import { Project } from '@src/types/project'

function buildCommitmentItemSearchProperties(costsOverview: CostsOverview, commitmentType: CommitmentDefinition): IMappedSearchProperty<CommitmentItem>[] {
    return [
        {
            name: 'Item Number',
            path: item => item.itemNo,
            searchKey: 'item_no',
            type: PropertyType.Number
        },
        {
            name: 'Name',
            path: item => item.name,
            searchKey: 'name',
            type: PropertyType.Text
        },
        {
            name: 'Other Party Ref',
            path: item => item.otherPartyReference,
            searchKey: 'other_party_ref',
            type: PropertyType.Text
        },
        {
            name: 'Cost Code',
            path: item => item.costCode.code,
            searchKey: 'cost_code',
            type: PropertyType.Text
        },
        {
            name: 'Phase',
            path: item => item.phase,
            searchKey: 'phase',
            selectOptions: costsOverview.phases.map(x => ({ value: x.code, label: x.name })),
            type: PropertyType.Select
        },
        {
            name: 'Status',
            path: item => item.status,
            searchKey: 'status',
            selectOptions: commitmentType.statusDefinitions.map(s => ({ value: s.code, label: s.code })),
            type: PropertyType.Select
        },
        {
            name: 'Value',
            path: item => item.currentValue,
            searchKey: 'value',
            type: PropertyType.Number
        },
        {
            name: 'Certified',
            path: item => item.currentCertified,
            searchKey: 'certified',
            type: PropertyType.Number
        },
        {
            name: 'Paid',
            path: item => item.currentPaid,
            searchKey: 'paid',
            type: PropertyType.Number
        }
    ]
}

interface IProps {
    project: Project
    commitment: Commitment
    costsOverview: CostsOverview
    reloadCommitment: () => Promise<any>
    search: string
}

const CommitmentItemSection: React.FunctionComponent<IProps> = ({ project, commitment, costsOverview, reloadCommitment, search }) => {
    const creating = useBoolean(false)
    const editing = useBoolean(false)
    const deleting = useBoolean(false)
    const [selectedItem, setSelected] = React.useState<CommitmentItem>()
    const commitmentDefinition = costsOverview.commitmentDefinitions.find(x => x.code === commitment.type)
    const itemSearchSectionRef = React.useRef<SearchSectionType<CommitmentItem, 'id'>>()
    const searchProperties = React.useMemo(() => buildCommitmentItemSearchProperties(costsOverview, commitmentDefinition), [costsOverview, commitmentDefinition])
    const handleSearch = useLocalSearch(commitment.commitmentItems, searchProperties)

    const [linksCategory, setLinksCategory] = React.useState<string>()
    const documentLinksModal = useModal(false, { onClose: () => setLinksCategory(undefined) })

    function setCommitmentItemToEdit(item: CommitmentItem) {
        editing.setTrue()
        setSelected(item)
    }

    function setCommitmentItemToDelete(item: CommitmentItem) {
        deleting.setTrue()
        setSelected(item)
    }

    function clearFlags() {
        editing.setFalse()
        deleting.setFalse()
        creating.setFalse()
    }

    function clearSelectedItemAndFlags() {
        clearFlags()
        setSelected(undefined)
    }

    async function loadCommitmentAndReloadSearch() {
        await reloadCommitment()
        if (itemSearchSectionRef.current) itemSearchSectionRef.current.doSearch()
    }

    async function deleteCommitmentItem() {
        const item = { ...selectedItem }
        clearSelectedItemAndFlags()
        try {
            await CommitmentItemDelete(project.id, commitment.id, item.id)
        } catch {
            NotificationService.error(<span>Failed to remove {item.name}</span>)
        }

        await loadCommitmentAndReloadSearch()
    }

    function showDocumentLinks(type: string) {
        setLinksCategory(type)
        documentLinksModal.actions.show()
    }

    return (
        <Container fluid className="mt-3">
            <Row>
                <Col lg={3} md={12} className="mb-3 order-lg-12">
                    <Card>
                        <CardHeader><FA icon="info-circle" className="mr-2" />{capitalize(commitmentDefinition.name)} Info</CardHeader>
                        <ListGroup className="small mb-2" flush>
                            <ListGroupItem className="py-1">
                                <strong>Supplier: </strong>
                                {valueOrMutedNotSet(commitment.otherParty?.name)}
                            </ListGroupItem>
                            <ListGroupItem className="py-1">
                                <strong>Supplier Reference: </strong>
                                {valueOrMutedNotSet(commitment.otherPartyReference)}
                            </ListGroupItem>
                            <ListGroupItem className="py-1">
                                <strong>Date: </strong>
                                {commitment.date ? localLongDate(commitment.date) : mutedNotSet}
                            </ListGroupItem>
                            <ListGroupItem className="py-1">
                                <strong>Description: </strong>
                                {valueOrMutedNotSet(commitment.description)}
                            </ListGroupItem>
                            {commitment.parent &&
                            <ListGroupItem className="py-1">
                                <strong>{costsOverview.commitmentDefinitions.find(cd => commitment.parent.type).name}: </strong><Link to={Routes.projectCostsCommitmentTypeDetail(project.id, commitment.parent.type, commitment.parent.id)}>{commitment.parent.name}</Link>
                            </ListGroupItem>}
                            <ListGroupItem className="py-1">
                                <strong>Additions: </strong>
                                {toPercent(commitment.variationAdditions)}
                            </ListGroupItem>
                            <ListGroupItem className="py-1">
                                <strong>Deductions: </strong>
                                {toPercent(commitment.variationDeductions)}
                            </ListGroupItem>
                            {Object.entries(commitment.documentLinks).map(([title, links]) =>
                                <ListGroupItem key={title} className="py-1">
                                    <strong>{title}: </strong>
                                    <LinkAction data={title} onClick={showDocumentLinks}>{links.length} {links.length === 1 ? 'document' : 'documents'}</LinkAction>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                        <DocumentLinksModal
                            {...documentLinksModal.modalProps}
                            projectId={project.id}
                            commitment={commitment}
                            category={linksCategory}
                            reloadCommitment={reloadCommitment}
                        />
                    </Card>
                </Col>
                <Col lg={9} md={12}>
                    <SearchSection<CommitmentItem, 'id'>
                        noContainerForTable
                        ref={itemSearchSectionRef}
                        defaultPerPage={50}
                        onSearch={handleSearch}
                        searchAssistantProperties={searchProperties}
                        extraSearchBarElements={[
                            {
                                element: _ => <Button color="secondary" onClick={creating.setTrue}><FA icon="plus" /> New Item</Button>,
                                position: 'before'
                            }
                        ]}
                        noItemsFoundMessage={(
                            <div>
                                <div className="my-3"><FA size="3x" icon="box-usd" /></div>
                                <p className="lead">There are no line items.</p>
                                <p className="lead"><Button onClick={creating.setTrue}>Create Line Item</Button></p>
                            </div>
                        )}
                        headers={[
                            {
                                name: 'Item #',
                                accessor: 'itemNo',
                                sortKey: 'item_no',
                                sortable: true
                            },
                            {
                                name: 'Name',
                                accessor: 'name',
                                sortKey: 'name',
                                sortable: true
                            },
                            {
                                name: 'Other Party Ref',
                                overrideRenderer: item => valueOrMutedNotSet(item.otherPartyReference),
                                sortKey: 'other_party_ref',
                                sortable: true
                            },
                            {
                                name: 'Cost Code',
                                overrideRenderer: item => item.costCode.code,
                                sortKey: 'cost_code',
                                sortable: true
                            },
                            {
                                name: 'Phase',
                                accessor: 'phase',
                                sortKey: 'phase',
                                sortable: true
                            },
                            {
                                name: 'Status',
                                accessor: 'status',
                                sortKey: 'status',
                                sortable: true
                            },
                            {
                                name: 'Value',
                                overrideRenderer: item => <CostValue value={item.currentValue} />,
                                sortKey: 'value',
                                sortable: true
                            },
                            {
                                name: 'Certified',
                                overrideRenderer: item => <CostValue value={item.currentCertified} />,
                                sortKey: 'certified',
                                sortable: true
                            },
                            {
                                name: 'Paid',
                                overrideRenderer: item => <CostValue value={item.currentPaid} />,
                                sortKey: 'paid',
                                sortable: true
                            },
                            {
                                name: 'Remaining',
                                overrideRenderer: item => <CostValue value={item.currentValue - item.currentCertified} />
                            },
                            {
                                name: 'Actions',
                                headerWrapperClass: 'text-right',
                                overrideRenderer: item => (
                                    <div className="text-right">
                                        <TooltipLinkAction id={`edit-item-${item.id}`} tooltip="Edit" data={item} className="order-lg-1" onClick={setCommitmentItemToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                        <TooltipLinkAction id={`delete-item-${item.id}`} tooltip="Remove" data={item} className="order-lg-1" onClick={setCommitmentItemToDelete} disabled={!isAuthorised(costsOverview.myAccess, Operations.Delete)}><FA icon="trash" /></TooltipLinkAction>
                                    </div>
                                )
                            }
                        ]}

                    >
                        <CommitmentItemModal
                            isOpen={creating.value || editing.value}
                            toggle={clearFlags}
                            projectId={project.id}
                            commitmentDefinition={commitmentDefinition}
                            commitment={commitment}
                            costsOverview={costsOverview}
                            commitmentItemToEdit={selectedItem}
                            onClosed={clearSelectedItemAndFlags}
                            onItemCreatedOrUpdated={loadCommitmentAndReloadSearch}
                        />
                        <ConfirmationModal
                            danger
                            isOpen={deleting.value}
                            toggle={clearFlags}
                            header={'Remove item'}
                            message={<span>Are you sure you want to remove <strong>{selectedItem?.name}</strong>?</span>}
                            confirmAction="Remove"
                            rejectAction="Cancel"
                            onReject={clearSelectedItemAndFlags}
                            onConfirm={deleteCommitmentItem}
                        />
                    </SearchSection>
                </Col>
            </Row>
        </Container>
    )
}

export default CommitmentItemSection
