import React from 'react'

import { debounce } from 'lodash'

interface IProps {
    lines: number
    text: string
}

interface IState {
    clamped: boolean
    showMoreLess: boolean
}

/**
 * Clamps text to a given number of lines. This uses a webkit css flag, and isn't guaranteed to work in
 * all browsers. At last check, all modern browsers except Firefox support this https://caniuse.com/#search=line-clamp.
 * Since a JavaScript implementation is relatively heavy, and this feature is being worked on in firefox,
 * it was decided to use this for now, being mindful of the impact of firefox users.
 *
 * @export
 * @class ClampLines
 * @extends {React.PureComponent<IProps, IState>}
 */
export default class ClampLines extends React.PureComponent<IProps, IState> {
    private timeout: number = null

    public static defaultProps = {
        lines: 3
    }

    private readonly ref: React.RefObject<HTMLDivElement>
    private readonly debouncedResize: () => void

    constructor(props) {
        super(props)

        this.ref = React.createRef()
        this.debouncedResize = debounce(this.onResize, 200)
        this.state = {
            clamped: true,
            showMoreLess: false
        }
    }

    public componentDidMount() {
        if (this.state.clamped) {
            this.setState({ showMoreLess: this.ref.current.offsetHeight < this.ref.current.scrollHeight })
        }

        window.addEventListener('resize', this.debouncedResize)
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedResize)
    }

    private readonly onResize = () => {
        if (!this.state.clamped && this.state.showMoreLess) {
            this.setState({ clamped: true, showMoreLess: this.ref.current.offsetHeight < this.ref.current.scrollHeight })
        }
    }

    private readonly startUnclampTimer = () => {
        this.timeout = window.setTimeout(this.unclamp, 500)
    }

    private readonly clearUnclampTimeout = () => {
        window.clearTimeout(this.timeout)
        this.clamp()
    }

    private readonly clamp = () => {
        this.setState({ clamped: true })
    }

    private readonly unclamp = () => {
        this.setState({ clamped: false })
    }

    public render() {
        return (
            <div ref={this.ref} style={this.state.clamped ? { overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: this.props.lines, WebkitBoxOrient: 'vertical' } : {}} onMouseOver={this.startUnclampTimer} onMouseLeave={this.clearUnclampTimeout}>
                {this.props.text}
            </div>
        )
    }
}
