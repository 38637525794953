import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import useBoolean from '@src/hooks/useBoolean'

interface IProps<TRow> {
    index: number
    row: TRow
    onDeleteRow?: (row: TRow) => void
}

export default function SpreadsheetIndexDropdown<TRow>({ index, row, onDeleteRow }: IProps<TRow>) {
    const dropdown = useBoolean(false)

    function handleDelete() {
        onDeleteRow(row)
    }

    return (
        <Dropdown isOpen={dropdown.value} toggle={dropdown.toggle} className="text-right flex-grow-1 mr-1">
            <DropdownToggle tag="div" className="ml-auto mr-1 pointer" role="button">
                {index}
            </DropdownToggle>
            <DropdownMenu container="body" className="shadow">
                <DropdownItem header>Actions</DropdownItem>
                {onDeleteRow && <DropdownItem className="text-danger" onClick={handleDelete}>Delete row</DropdownItem>}
            </DropdownMenu>
        </Dropdown>
    )
}
