import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import DropdownCheckOptions, { IDropdownCheckOption } from '@src/components/common/DropdownCheckOptions'
import FA from '@src/components/common/FontAwesomeIcon'

export const FileTypes = {
    pdf: {
        name: 'Pdf',
        ext: ['pdf']
    },
    word: {
        name: 'Word',
        ext: ['doc', 'docx']
    },
    excel: {
        name: 'Excel',
        ext: ['xlsx']
    },
    powerpoint: {
        name: 'PowerPoint',
        ext: ['ppt', 'pptx']
    },
    jpg: {
        name: 'JPEG',
        ext: ['jpeg', 'jpg']
    },
    dwg: {
        name: 'DWG',
        ext: ['dwg']
    }
}

interface IProps {
    selectedFileTypes: string[]
    onCheck: (fileType: string) => void
}

export default function FileTypesDropdown({ selectedFileTypes, onCheck }: IProps) {
    function handleCheck(e: React.SyntheticEvent, options: IDropdownCheckOption) {
        onCheck(options.key)
    }

    return (
        <>
            <DropdownCheckOptions
                caret
                isSearchable
                title={<FA icon="file-alt" />}
                toggleProps={{ color: 'default', outline: true, id: 'document-search-filetype' }}
                onCheck={handleCheck}
                options={
                    [...Object.keys(FileTypes).map<IDropdownCheckOption>(f => ({
                        key: f,
                        label: FileTypes[f].name,
                        checked: selectedFileTypes.includes(f)
                    }))]
                }
            />
            <UncontrolledTooltip target="document-search-filetype" delay={{ show: 1000, hide: 0 }}>File type</UncontrolledTooltip>
        </>
    )
}
