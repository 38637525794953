import React from 'react'
import { useField } from 'react-final-form'
import { UncontrolledTooltip } from 'reactstrap'

import BooleanField, { BooleanFieldFooter } from '@src/components/register/tablefields/BooleanField'
import CommitmentLinkField, { CommitmentLinkFieldFooter } from '@src/components/register/tablefields/CommitmentLinkField'
import CommitmentPropertyField from '@src/components/register/tablefields/CommitmentPropertyField'
import CompanyLinkField, { CompanyLinkFieldFooter } from '@src/components/register/tablefields/CompanyLinkField'
import CompanyPropertyField from '@src/components/register/tablefields/CompanyPropertyField'
import DateField, { DateFieldFooter } from '@src/components/register/tablefields/DateField'
import DocumentLinkField, { DocumentLinkFieldFooter } from '@src/components/register/tablefields/DocumentLinkField'
import DocumentPropertyField from '@src/components/register/tablefields/DocumentPropertyField'
import EmailLinkField, { EmailLinkFieldFooter } from '@src/components/register/tablefields/EmailLinkField'
import EmailPropertyField from '@src/components/register/tablefields/EmailPropertyField'
import EmptyField from '@src/components/register/tablefields/EmptyField'
import NumberField, { NumberFieldFooter } from '@src/components/register/tablefields/NumberField'
import PaymentLinkField, { PaymentLinkFieldFooter } from '@src/components/register/tablefields/PaymentLinkField'
import PaymentPropertyField from '@src/components/register/tablefields/PaymentPropertyField'
import SelectField, { SelectFieldFooter } from '@src/components/register/tablefields/SelectField'
import TextField, { TextFieldFooter } from '@src/components/register/tablefields/TextField'
import TransmittalLinkField, { TransmittalLinkFieldFooter } from '@src/components/register/tablefields/TransmittalLinkField'
import TransmittalPropertyField from '@src/components/register/tablefields/TransmittalPropertyField'
import UserLinkField, { UserLinkFieldFooter } from '@src/components/register/tablefields/UserLinkField'
import UserPropertyField from '@src/components/register/tablefields/UserPropertyField'
import { ICellRenderProps } from '@src/components/spreadsheet/SpreadsheetCell'
import { IFooterRenderProps } from '@src/components/spreadsheet/SpreadsheetFooter'
import { MetadataTypes } from '@src/types/metadata'
import { RegisterColumn, RegisterRow } from '@src/types/register'

export function determineRendererForType(column: RegisterColumn): React.ElementType<ICellRenderProps<RegisterColumn, RegisterRow>> {
    switch (column.metadataDefinition.type) {
        case MetadataTypes.Bool:
            return BooleanField
        case MetadataTypes.Text:
            return TextField
        case MetadataTypes.Numeric:
            return NumberField
        case MetadataTypes.Select:
            return SelectField
        case MetadataTypes.Date:
            return DateField
        case MetadataTypes.UserLinks:
            return UserLinkField
        case MetadataTypes.EmailLinks:
            return EmailLinkField
        case MetadataTypes.CompanyLinks:
            return CompanyLinkField
        case MetadataTypes.DocumentLinks:
            return DocumentLinkField
        case MetadataTypes.CommitmentLinks:
            return CommitmentLinkField
        case MetadataTypes.TransmittalLinks:
            return TransmittalLinkField
        case MetadataTypes.PaymentClaimLinks:
            return PaymentLinkField
        case MetadataTypes.UserProperty:
            return UserPropertyField
        case MetadataTypes.EmailProperty:
            return EmailPropertyField
        case MetadataTypes.CompanyProperty:
            return CompanyPropertyField
        case MetadataTypes.DocumentProperty:
            return DocumentPropertyField
        case MetadataTypes.TransmittalProperty:
            return TransmittalPropertyField
        case MetadataTypes.CommitmentProperty:
            return CommitmentPropertyField
        case MetadataTypes.PaymentProperty:
            return PaymentPropertyField
        // alarm
        // formula
        default:
            return () => <span>n/a</span>
    }
}

export function determineRendererForFooterType(column: RegisterColumn): React.ElementType<IFooterRenderProps<RegisterColumn>> {
    switch (column.metadataDefinition.type) {
        case MetadataTypes.Bool:
            return WrappedFooter(BooleanFieldFooter)
        case MetadataTypes.Text:
            return WrappedFooter(TextFieldFooter)
        case MetadataTypes.Numeric:
            return WrappedFooter(NumberFieldFooter)
        case MetadataTypes.Select:
            return WrappedFooter(SelectFieldFooter)
        case MetadataTypes.Date:
            return WrappedFooter(DateFieldFooter)
        case MetadataTypes.UserLinks:
            return WrappedFooter(UserLinkFieldFooter)
        case MetadataTypes.EmailLinks:
            return WrappedFooter(EmailLinkFieldFooter)
        case MetadataTypes.CompanyLinks:
            return WrappedFooter(CompanyLinkFieldFooter)
        case MetadataTypes.DocumentLinks:
            return WrappedFooter(DocumentLinkFieldFooter)
        case MetadataTypes.CommitmentLinks:
            return WrappedFooter(CommitmentLinkFieldFooter)
        case MetadataTypes.PaymentClaimLinks:
            return WrappedFooter(PaymentLinkFieldFooter)
        case MetadataTypes.TransmittalLinks:
            return WrappedFooter(TransmittalLinkFieldFooter)
        // case MetadataTypes.PaymentClaimLinks:
        //     return <span> paymentlinkdata </span>
        case MetadataTypes.UserProperty:
        case MetadataTypes.EmailProperty:
        case MetadataTypes.CompanyProperty:
        case MetadataTypes.DocumentProperty:
        case MetadataTypes.TransmittalProperty:
        case MetadataTypes.CommitmentProperty:
        case MetadataTypes.PaymentProperty:
            return WrappedFooter(EmptyField)
        // alarm
        // formula
        default:
            return () => <span>n/a</span>
    }
}

function WrappedFooter(Component: React.ElementType<IFooterRenderProps<RegisterColumn>>) {
    return (props: IFooterRenderProps<RegisterColumn>) => {
        const { meta: { valid: isValid, touched, error } } = useField(`cells.c-${props.col.data.key}`, { subscription: { valid: true, touched: true, error: true } })
        const showError = touched && !isValid
        const styles = showError
            ? {
                transition: 'box-shadow 0.1s ease 0s',
                boxShadow: '0px 0px 1px 1px var(--danger) inset',
                margin: '-1px 0px 0px 0px'
                // border: '1px solid var(--danger)'
            }
            : undefined

        return (
            <>
                <div id={props.col.id} className="d-flex flex-grow-1" style={styles}>
                    <Component {...props} />
                </div >
                {showError && error && <UncontrolledTooltip target={props.col.id}>{error}</UncontrolledTooltip>}
            </>
        )
    }
}
