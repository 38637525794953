import { isNullOrEmpty } from '@src/logic/utils/Strings'

export const pageToSkip = (currentPage: number, perPage: number) => {
    const normalizedCurrent = currentPage < 1 ? 1 : currentPage
    const normalizedPerPage = perPage < 0 ? 0 : perPage
    return (normalizedCurrent - 1) * normalizedPerPage
}

export function parseLinkHeader(linkHeader: string): { [rel: string]: { [param: string]: any, rel: string, url: string } } {
    function hasRel(x: undefined | { rel?: string }): boolean {
        return !isNullOrEmpty(x?.rel)
    }

    function intoRels(acc: { [rel: string]: { [key: string]: any, rel: string, url: string } }, x: { rel: string, url: string }) {
        function splitRel(rel: string) {
            acc[rel] = { ...x, rel }
        }
        x.rel.split(/\s+/).forEach(splitRel)
        return acc
    }

    function createObjects(acc: { [key: string]: string }, p: string) {
        // rel="next" => 1: rel 2: next
        const match = p.match(/\s*(.+)\s*=\s*"?([^"]+)"?/)
        if (match) acc[match[1]] = match[2]
        return acc
    }

    function parseLink(link: string) {
        try {
            const match = link.match(/<?([^>]*)>(.*)/)
            const linkUrl = match[1]
            const parts = match[2].split(';')

            parts.shift()

            const info = parts
                .reduce(createObjects, { url: linkUrl })

            return info
        } catch (e) {
            return null
        }
    }

    return linkHeader.split(/,\s*</)
        .map(parseLink)
        .filter(hasRel)
        .reduce(intoRels, {})
}
