import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, Col, Input, Row, UncontrolledTooltip } from 'reactstrap'
import { Dispatch, bindActionCreators } from 'redux'

import { loadProjectCostsOverview } from '@src/actions/project'
import * as WidgetActions from '@src/actions/widget'
import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import LinksPopover from '@src/components/common/LinksPopover'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import { BudgetItemAddModal } from '@src/components/costs/budget/BudgetItemAddModal'
import BudgetItemEditModal from '@src/components/costs/budget/BudgetItemEditModal'
import BudgetItemToolbarForm from '@src/components/costs/budget/BudgetItemToolbarForm'
import CostValue from '@src/components/costs/common/CostValue'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { ISearchResult, SearchSectionType } from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import { BudgetItemDelete, BudgetItemsList, BudgetPatch } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { pushURLWithParamUpdates } from '@src/logic/search/SearchStateHelpers'
import { localLongDate, localShortDate } from '@src/logic/utils/Date'
import { toBoolean } from '@src/logic/utils/Strings'
import { mutedNotSet, valueOrMutedFallback } from '@src/logic/utils/ValueHelper'
import { getProjectState } from '@src/reducers/widget'
import { BudgetItem, CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'

interface IState {
    isAdjustment?: boolean
    totalBudgetSum: number
    isEditing: boolean
    isAddingAdjustment: boolean
    budgetItemToEdit?: BudgetItem
    budgetItemToDelete?: BudgetItem
}

interface IConnectedState {
    projectId: string
    costsOverview: CostsOverview
    widgetBudgetItemIds: string[]
}

interface IConnectedDispatch {
    reloadCostsOverview: () => void
    widgetActions: typeof WidgetActions
}

class BudgetSection extends React.PureComponent<RouteComponentProps & IConnectedState & IConnectedDispatch, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<BudgetItem, 'id'>>

    constructor(props: RouteComponentProps & IConnectedState & IConnectedDispatch) {
        super(props)

        this.searchSectionRef = React.createRef<SearchSectionType<BudgetItem, 'id'>>()

        const urlParams = new URLSearchParams(this.props.location.search)
        const isAdjustment = toBoolean(urlParams.get('isAdjustment'))

        this.state = {
            isAdjustment,
            totalBudgetSum: null,
            isEditing: false,
            isAddingAdjustment: false,
            budgetItemToEdit: null,
            budgetItemToDelete: null
        }
    }

    private readonly loadBudgetItems = async (filter: string, sort: string, page: number, perPage: number, abortSignal: AbortSignal): Promise<ISearchResult<BudgetItem>> => {
        const budgetFilter = this.getBudgetTypeFilter()
        const filterWithType = budgetFilter != null ? `${budgetFilter} AND ${filter}` : filter

        const response = await BudgetItemsList(this.props.projectId, filterWithType, sort, page, perPage, { abortSignal })
        this.setState({ totalBudgetSum: response.data.aggregate.totalSum })
        return {
            items: response.data.items,
            totalItems: Number(response.headers[Headers.PaginationTotalCount])
        }
    }

    private readonly getBudgetTypeFilter = (): string => {
        switch (this.state.isAdjustment) {
            case false:
                return 'is_adjustment: false'
            case true:
                return 'is_adjustment: true'
            default:
                return null
        }
    }

    private readonly setBudgetType = (e: React.ChangeEvent<HTMLInputElement>) => {
        let isAdjustment: boolean = null
        switch (e.currentTarget.value) {
            case 'Items':
                isAdjustment = false
                break
            case 'Adjustments':
                isAdjustment = true
                break
        }
        this.setState({ isAdjustment }, () => {
            pushURLWithParamUpdates(this.props.history, this.props.location, isAdjustment != null ? { isAdjustment: isAdjustment.toString() } : {}, isAdjustment == null ? ['isAdjustment'] : [])
            this.searchSectionRef.current.doSearch()
        })
    }

    private readonly getAdjustmentSelectValue = () => {
        switch (this.state.isAdjustment) {
            case false:
                return 'Items'
            case true:
                return 'Adjustments'
            default:
                return 'All'
        }
    }

    private readonly setBudgetItemToDelete = (item: BudgetItem) => {
        this.setState({ budgetItemToDelete: item })
    }

    private readonly clearBudgetItemToDelete = () => {
        this.setState({ budgetItemToDelete: null })
    }

    private readonly deleteBudgetItem = async () => {
        const { budgetItemToDelete: item } = this.state
        this.clearBudgetItemToDelete()

        try {
            await BudgetItemDelete(this.props.projectId, item.id)
        } catch {
            NotificationService.error(<span>Failed to remove budget {item.isAdjustment ? 'item' : 'adjustment'}</span>)
        }

        await this.searchSectionRef.current.doSearch()
    }

    private readonly setBudgetItemToEdit = (budgetItemToEdit: BudgetItem) => {
        this.setState({ budgetItemToEdit, isEditing: true })
    }

    private readonly clearItemToEdit = () => {
        this.setState({ budgetItemToEdit: null })
    }

    private readonly clearEditItems = () => {
        this.setState({ isEditing: false })
    }

    private readonly setAddingAdjustment = () => {
        this.setState({ isAddingAdjustment: true })
    }

    private readonly toggleAddingAdjustment = () => {
        this.setState({ isAddingAdjustment: !this.state.isAddingAdjustment })
    }

    private readonly handleBudgetItemsSelected = (...budgetItems: BudgetItem[]) => {
        const selectedIds = [...this.props.widgetBudgetItemIds]
        this.props.widgetActions.addBudgetItems({ projectId: this.props.projectId, entities: budgetItems.filter(b => !selectedIds.includes(b.id)) })
        this.props.widgetActions.removeBudgetItems({ projectId: this.props.projectId, entityIds: budgetItems.filter(b => selectedIds.includes(b.id)).map(b => b.id) })
    }

    private readonly getLockIssues = (): string[] => {
        const { costsOverview, costsOverview: { budget } } = this.props
        const { totalBudgetSum } = this.state
        const issues = []

        if (!isAuthorised(costsOverview.myAccess, Operations.WriteBudget)) issues.push('Need permission')

        if (!budget.locked && (totalBudgetSum != null ? totalBudgetSum !== budget.approvedBudget : true)) issues.push('Total budget value does not equal the approved budget')

        return issues
    }

    private readonly toggleBudgetLock = async () => {
        try {
            await BudgetPatch(this.props.projectId, [{ op: 'replace', path: '/locked', value: !this.props.costsOverview.budget.locked }])
        } catch {
            NotificationService.error(`Unable to ${this.props.costsOverview.budget.locked ? 'unlock' : 'lock'} budget`)
            return
        }

        this.props.reloadCostsOverview()
    }

    public render() {
        const { projectId, costsOverview } = this.props
        const { budgetItemToDelete, budgetItemToEdit, isEditing, isAdjustment, isAddingAdjustment, totalBudgetSum } = this.state
        const noBudgetItemsFoundMessage = (
            <>
                <div className="my-3"><FA size="3x" icon="money-check-alt" /></div>
                <p className="lead">No budget items found with the current search criteria...</p>
                <p>{"Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters."}</p>
            </>
        )

        let adjustmentStatuses = []
        switch (isAdjustment) {
            case false:
                adjustmentStatuses = costsOverview.budget.adjustmentStatusDefinitions.map(s => s.code)
                break
            case true:
                adjustmentStatuses = costsOverview.budget.itemStatusDefinitions.map(s => s.code)
                break
            default:
                adjustmentStatuses = [costsOverview.budget.itemStatusDefinitions.map(s => s.code), ...costsOverview.budget.adjustmentStatusDefinitions.map(s => s.code)]
        }

        const lockIssues = this.getLockIssues()

        return (
            <>
                <ActionBar className="pb-0 pt-0 d-block">
                    <Row>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Budget Value</h5>
                            {totalBudgetSum != null ? <CostValue value={totalBudgetSum} /> : <FA icon="spinner-third" />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>{costsOverview.budget.budgetApprovalDate ? 'Approved' : 'Working'} Budget</h5>
                            <CostValue value={costsOverview.budget.approvedBudget} />
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Approval Date</h5>
                            <span>{costsOverview.budget.budgetApprovalDate ? localShortDate(costsOverview.budget.budgetApprovalDate) : 'Not approved'}</span>
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Status</h5>
                            <span>{costsOverview.budget.locked ? 'Locked' : 'Unlocked'}</span>
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Client Budget Advice</h5>
                            {costsOverview.budget.clientBudgetAdvice.length
                                ? <LinksPopover title="Documents" links={costsOverview.budget.clientBudgetAdvice.map(dl => ({ name: dl.name, route: Routes.projectDocument(projectId, dl.documentId, dl.revisionId) }))} />
                                : 'N/A'
                            }
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Consultant Budget Advice</h5>
                            {costsOverview.budget.consultantBudgetAdvice.length
                                ? <LinksPopover title="Documents" links={costsOverview.budget.consultantBudgetAdvice.map(dl => ({ name: dl.name, route: Routes.projectDocument(projectId, dl.documentId, dl.revisionId) }))} />
                                : 'N/A'
                            }
                        </Col>
                        <Col className="pt-3 ml-auto" xs={6} md="auto">
                            {lockIssues.length > 0 && <UncontrolledTooltip placement="top" target="budget-section-lock-budget">{lockIssues[0]}</UncontrolledTooltip>}
                            <div className="d-block" id="budget-section-lock-budget">
                                <Button onClick={this.toggleAddingAdjustment} className="mr-2">
                                    <FA icon="plus" className="mr-1" />Add Adjustment
                                </Button>
                                <Button color={costsOverview.budget.locked ? 'warning' : 'secondary'} style={{ pointerEvents: lockIssues.length > 0 ? 'none' : 'initial' }} disabled={lockIssues.length > 0} onClick={this.toggleBudgetLock}>
                                    <FA icon={costsOverview.budget.locked ? 'lock-open-alt' : 'lock-alt'} />
                                    <span>&nbsp;{costsOverview.budget.locked ? 'Unlock' : 'Lock'} Budget</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ActionBar>
                <SearchSection<BudgetItem, 'id'>
                    ref={this.searchSectionRef}
                    itemIdKey="id"
                    headers={[
                        {
                            name: 'Item',
                            accessor: 'itemNo',
                            sortKey: 'item_number',
                            sortable: true
                        },
                        {
                            name: '',
                            overrideRenderer: item => item.isAdjustment ? <span><UncontrolledTooltip placement="top" target={`budget-adjustment-${item.id}`}>Adjustment</UncontrolledTooltip><FA id={`budget-adjustment-${item.id}`} icon="money-check-alt" /></span> : null
                        },
                        {
                            name: 'Name',
                            accessor: 'name',
                            sortKey: 'name',
                            sortable: true
                        },
                        {
                            name: 'Cost Code',
                            sortKey: 'cost_code',
                            sortable: true,
                            overrideRenderer: item => item.costCode.code
                        },
                        {
                            name: 'Cost Code Name',
                            overrideRenderer: item => item.costCode.name
                        },
                        {
                            name: 'Cost Code Group',
                            overrideRenderer: item => item.costCode.group
                        },
                        {
                            name: 'Phase',
                            sortKey: 'phase_code',
                            sortable: true,
                            overrideRenderer: item => valueOrMutedFallback(item.phase, '(none)')
                        },
                        {
                            name: 'Status',
                            accessor: 'status',
                            sortKey: 'status',
                            sortable: true
                        },
                        {
                            name: 'Date',
                            sortKey: 'date',
                            sortable: true,
                            overrideRenderer: (item) => {
                                if (item.date == null) return mutedNotSet
                                return localLongDate(item.date)
                            }
                        },
                        {
                            name: 'Value',
                            sortKey: 'value',
                            sortable: true,
                            overrideRenderer: item => <CostValue value={item.currentValue} />
                        },
                        {
                            name: 'Actions',
                            headerWrapperClass: 'text-right',
                            overrideRenderer: item => (
                                <div className="text-right">
                                    <TooltipLinkAction id={`edit-${item.id}`} tooltip="Edit" data={item} className="order-lg-1" onClick={this.setBudgetItemToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                    <TooltipLinkAction id={`delete-${item.id}`} tooltip="Remove" data={item} className="order-lg-1" onClick={this.setBudgetItemToDelete} disabled={!isAuthorised(costsOverview.myAccess, Operations.DeleteBudget) || (!item.isAdjustment && costsOverview.budget.locked)}><FA icon="trash" /></TooltipLinkAction>
                                </div>
                            )
                        }
                    ]}
                    searchAssistantProperties={[
                        {
                            name: 'Item',
                            searchKey: 'item_number',
                            type: PropertyType.Text
                        },
                        {
                            name: 'Name',
                            searchKey: 'name',
                            type: PropertyType.Text
                        },
                        {
                            name: 'Cost Code',
                            searchKey: 'cost_code',
                            type: PropertyType.Text
                        },
                        {
                            name: 'Phase Code',
                            searchKey: 'phase_code',
                            type: PropertyType.Select,
                            selectOptions: costsOverview.phases.map(p => p.code)
                        },
                        {
                            name: 'Status',
                            searchKey: 'status',
                            type: PropertyType.Select,
                            selectOptions: adjustmentStatuses
                        },
                        {
                            name: 'Description',
                            searchKey: 'name',
                            type: PropertyType.Text
                        },
                        {
                            name: 'Date',
                            searchKey: 'date',
                            type: PropertyType.Date
                        },
                        {
                            name: 'Value',
                            searchKey: 'value',
                            type: PropertyType.Number
                        }
                    ]}
                    onSearch={this.loadBudgetItems}
                    noItemsFoundMessage={noBudgetItemsFoundMessage}
                    extraSearchBarElements={[
                        {
                            element: onSearch => (
                                <Input type="select" onChange={this.setBudgetType} value={this.getAdjustmentSelectValue()}>
                                    <option value={'All'}>All</option>
                                    <option value={'Items'}>Items</option>
                                    <option value={'Adjustments'}>Adjustments</option>
                                </Input>
                            ),
                            position: 'before'
                        }
                    ]}
                >
                    {!costsOverview.budget.locked && <BudgetItemToolbarForm costsOverview={costsOverview} projectId={projectId} onItemCreated={this.searchSectionRef.current?.doSearch} />}
                    <ConfirmationModal
                        danger
                        isOpen={budgetItemToDelete != null}
                        toggle={this.clearBudgetItemToDelete}
                        header={`Remove budget ${(budgetItemToDelete?.isAdjustment ? 'adjustment' : 'item')}`}
                        message={<span>Are you sure you want to remove <strong>{budgetItemToDelete?.name}</strong>?</span>}
                        confirmAction="Remove"
                        rejectAction="Cancel"
                        onReject={this.clearBudgetItemToDelete}
                        onConfirm={this.deleteBudgetItem}
                    />
                    <BudgetItemEditModal
                        isOpen={isEditing}
                        budgetItemToEdit={budgetItemToEdit}
                        costsOverview={costsOverview}
                        projectId={projectId}
                        toggle={this.clearEditItems}
                        onItemUpdated={this.searchSectionRef.current?.doSearch}
                        onClosed={this.clearItemToEdit}
                    />
                    <BudgetItemAddModal costsOverview={costsOverview} projectId={projectId} onItemAdded={this.searchSectionRef.current?.doSearch} isOpen={isAddingAdjustment} toggle={this.toggleAddingAdjustment} />
                </SearchSection>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps): RouteComponentProps & IConnectedState {
    return {
        ...ownProps,
        projectId: state.projects.active.id,
        costsOverview: state.projects.activeCostsOverview,
        widgetBudgetItemIds: getProjectState(state.widget, state.projects.active.id).budgetItems.map(b => b.id)
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: RouteComponentProps): IConnectedDispatch {
    return {
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview()),
        widgetActions: bindActionCreators(WidgetActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetSection)
