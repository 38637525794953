import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button, Card, Col, Container, Row } from 'reactstrap'

import * as Sentry from '@sentry/browser'
import log from 'loglevel'

import FA from '@src/components/common/FontAwesomeIcon'
// import { HelpScoutServiceInstance } from '@src/logic/support/HelpScoutService'

interface IState {
    hasError: boolean
}

class ErrorBoundary extends React.Component<RouteComponentProps, IState> {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    public componentDidUpdate(prevProps: RouteComponentProps, prevState: IState) {
        if ((this.state.hasError && prevState.hasError) && prevProps.location !== this.props.location) {
            this.setState({ hasError: false })
        }
    }

    public componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true })
        Sentry.captureException(error)
        // You can also log the error to an error reporting service
        log.error(error, info)
    }

    private readonly showHelpScout = () => {
        // HelpScoutServiceInstance.open()
        console.log('nothing to see here, move along...');
    }

    public render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Container>
                    <Card body className="my-4">
                        <Row className="m-3">
                            <Col xs="auto">
                                <FA icon="frown" size="8x" />
                            </Col>
                            <Col>
                                <h1 className="display-2">I don't feel so good...</h1>
                                <p className="lead">Something went wrong. Please refresh and try again. If the problem persists, please <a href="mailto:support@infopoint.com.au">contact support</a>.</p>
                                <p>
                                    {/* <Button color="primary" onClick={this.showHelpScout}>Contact Support</Button> */}
                                </p>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            )
        }
        return this.props.children
    }
}

export default withRouter(ErrorBoundary)
