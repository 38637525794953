import { AnyAction, Dispatch } from 'redux'
import { createAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import * as Actions from '@src/actions/Actions'
import { CostsGet, ProjectUsersList, ProjectsGet, ProjectsListFavourites } from '@src/logic/http/Api'
import { CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { UserBasic } from '@src/types/principal'
import { Project } from '@src/types/project'

export const activeProject = createAction<Project>(Actions.PROJECT_SET_ACTIVE)
export const activeProjectContact = createAction<UserBasic>(Actions.PROJECT_SET_ACTIVE_CONTACT)
export const activeProjectCostsOverview = createAction<CostsOverview>(Actions.PROJECT_SET_ACTIVE_COSTSOVERVIEW)
export const setFavourites = createAction<Project[]>(Actions.PROJECT_SET_FAVOURITES)

export const loadFavourites = (): ThunkAction<Promise<void>, RootState, null, AnyAction> => {
    return async (dispatch: Dispatch) => {
        const response = await ProjectsListFavourites()

        dispatch(setFavourites(response.data))
    }
}

export const reloadActiveProject = (): ThunkAction<Promise<void>, RootState, null, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const project = getState().projects.active

        if (project == null) return

        const response = await ProjectsGet(project.id)
        dispatch(activeProject(response.data))

        if (response.data.settings.primaryContact) {
            const activeProjectContactResponse = await ProjectUsersList(project.id, `id: "${response.data.settings.primaryContact.id}"`, undefined, 1, 1)
            dispatch(activeProjectContact(activeProjectContactResponse.data.length > 0 ? activeProjectContactResponse.data[0] : null))
        }
    }
}

export const loadProjectCostsOverview = (): ThunkAction<Promise<void>, RootState, null, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const project = getState().projects.active

        if (project == null) return

        try {
            const response = await CostsGet(project.id)
            dispatch(activeProjectCostsOverview(response.data))
        } catch (error) {
            if (error?.response?.status !== 404) {
                throw error
            }
        }
    }
}
