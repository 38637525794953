import React from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { Button, Col, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { MetadataDefinitionSpreadsheet } from '@src/components/register/EditColumnModal'
import { IModalProps } from '@src/hooks/useModal'
import { required } from '@src/logic/forms/validation'
import { IMetadataDefinition, MetadataPropertyToLinkType, MetadataTypes } from '@src/types/metadata'
import { RegisterColumn } from '@src/types/register'

interface IProps extends IModalProps {
    existingColumns: readonly RegisterColumn[]
}

export default function AdvancedAddColumnModal({ existingColumns, ...modalProps }: IProps) {
    const typeField = useField<MetadataTypes>('type')
    const formAddColumn = useForm()

    function handleSubmit() {
        formAddColumn.submit()
        modalProps.toggle()
    }

    const columnDefinition: IMetadataDefinition = React.useMemo<IMetadataDefinition<unknown>>(() => typeField.input.value
        ? ({
            key: '',
            type: typeField.input.value,
            name: '',
            description: '',
            tags: [],
            isRequired: false,
            options: null
        })
        : null,
    [typeField.input.value])

    const linkOptions = React.useMemo(() =>
        (typeField.input.value && Object.keys(MetadataPropertyToLinkType).includes(typeField.input.value))
            ? existingColumns.filter(d => d.metadataDefinition.type === MetadataPropertyToLinkType[typeField.input.value]).map(d => ({ label: d.metadataDefinition.name, value: d.key }))
            : null
    , [typeField.input.value])

    return (
        <Modal {...modalProps} size="xl">
            <ModalHeader>Add column to register</ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="border-right" lg={3}>
                        <MetadataTypeList />
                    </Col>
                    <Col>
                        {linkOptions &&
                            <FormGroup>
                                <Label for="register-add-column-parent">Parent</Label>
                                <Field id="register-add-column-parent" name="parentKey" component={ValidatedSelect} validate={required} options={linkOptions} />
                            </FormGroup>
                        }
                        {columnDefinition &&
                            <>
                                <FormGroup>
                                    <Label for="register-add-column-name">Name</Label>
                                    <Field id="register-add-column-name" name="name" aria-label="Name" component={ValidatedInput} validate={required} />
                                    <FormText>Name of the field.</FormText>
                                </FormGroup>
                                <MetadataDefinitionSpreadsheet definition={columnDefinition} />
                            </>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={modalProps.toggle}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit}>Create</Button>
            </ModalFooter>
        </Modal>
    )
}

function MetadataTypeList() {
    return (
        <FormGroup>
            <Label>Column Type</Label>
            <Field<MetadataTypes> name="type" validate={required} subscription={{ value: true }} initialValue={MetadataTypes.Text}>
                {({ input }) =>
                    <Nav vertical pills>
                        <NavItem className="text-dark m-2" disabled><FA className="mr-1" icon="list" />Basic Data</NavItem>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.Text}>Text</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.Numeric}>Number</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.Bool}>Check</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.Date}>Date</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.Select}>Option</MetadataTypeOption>
                        <NavItem className="text-dark m-2" disabled><FA className="mr-1" icon="file-alt" />Documents</NavItem>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.DocumentLinks}>Document Links</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.DocumentProperty}>Document Property</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.TransmittalLinks}>Transmittal Links</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.TransmittalProperty}>Transmittal Property</MetadataTypeOption>
                        <NavItem className="text-dark m-2" disabled><FA className="mr-1" icon="badge-dollar" />Costs</NavItem>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.CommitmentLinks}>Commitment Links</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.CommitmentProperty}>Commitment Property</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.PaymentClaimLinks}>Payment Links</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.PaymentProperty}>Payment Property</MetadataTypeOption>
                        <NavItem className="text-dark m-2" disabled><FA className="mr-1" icon="users" /><span>Users &amp; Companies</span></NavItem>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.UserLinks}>User Links</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.UserProperty}>User Property</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.CompanyLinks}>Company Links</MetadataTypeOption>
                        <MetadataTypeOption selected={input.value} onClick={input.onChange} type={MetadataTypes.CompanyProperty}>Company Property</MetadataTypeOption>
                    </Nav>
                }
            </Field>
        </FormGroup>
    )
}

function MetadataTypeOption(props: { children: React.ReactNode, type: MetadataTypes, onClick: (type: MetadataTypes) => void, selected: MetadataTypes }) {
    function handleClick() {
        props.onClick(props.type)
    }

    return (
        <NavItem>
            <NavLink role="button" className="text-sm" tag={Link} onClick={handleClick} active={props.selected === props.type}>{props.children}</NavLink>
        </NavItem>
    )
}
