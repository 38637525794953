import React from 'react'
import { GroupBase, MultiValue, SelectComponentsConfig, StylesConfig, components } from 'react-select'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import variables from 'public/styles/custom/global/_variables.scss'

import Select, { IProps as SelectProps } from '@src/components/common/Select'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import useBoolean from '@src/hooks/useBoolean'
import useConstant from '@src/hooks/useConstant'
import { RegisterColumnDefinition } from '@src/types/register'

export type IGenericSelectDropdownProps<T> = SelectProps<T, true> & {
    itemRef?: React.RefObject<HTMLInputElement>
}

function valueSuffix(selectedLength: number, index: number) {
    const isLastSelected = index === selectedLength - 1
    return isLastSelected ? '' : ','
}

const styles: StylesConfig = {
    control: (base, state) => ({
        ...base,
        background: state.isDisabled ? variables.inputDisabledBg : (state.isFocused ? variables.inputFocusBg : variables.inputBg),
        borderColor: state.isFocused ? variables.inputFocusBorderColor : variables.inputBorderColor,
        fontWeight: 400,
        maxHeight: 25,
        borderRadius: variables.inputBorderRadius,
        minWidth: 240,
        margin: 8,
        boxShadow: state.isFocused ? variables.inputFocusBoxShadow : null,
        borderWidth: variables.inputBorderWidth
    }),
    input: (base, state) => ({
        ...base
    }),
    option: (base, state) => ({
        ...base,
        fontWeight: 400,
        fontSize: variables.fontBaseSize
    }),
    dropdownIndicator: (base, state) => ({
        ...base
    }),
    valueContainer: (base, state) => ({
        ...base,
        textOverflow: 'ellipsis',
        maxWidth: '99%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: 'black',
        paddingLeft: 15,
        borderRadius: 0
    }),
    clearIndicator: (base, state) => ({
        ...base,
        size: 5
    }),
    placeholder: (base, state) => ({
        ...base,
        color: variables.inputPlaceholderColor,
        fontSize: variables.fontSizeBase
    }),
    loadingIndicator: (base, state) => ({
        ...base
    }),
    menu: (base, state) => ({
        ...base,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTop: 0,
        position: 'relative',
        boxShadow: 'none'
    })
}

export default function GenericSelectDropdown<TDefinition extends RegisterColumnDefinition, TValue>({ col, row, spreadsheetPortalTarget, isFocused, options, value: overrideValue, getOptionLabel, getOptionValue, formatOptionLabel, ...selectProps }: IRegisterTableField<TDefinition> & IGenericSelectDropdownProps<TValue>) {
    const inputRef = React.useRef<HTMLInputElement>()
    const { value: dropdownOpen, toggle } = useBoolean(false)
    const selectComponents: SelectComponentsConfig<TValue, true, GroupBase<TValue>> = useConstant(() => ({
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        // Placeholder: props => <span className="text-muted">{props.children}</span>,
        MultiValueContainer: () => null,
        Input: (props) => <components.Input {...props} innerRef={inputRef as any} />
        // Control: props => <div {...props.innerProps} {...props.getStyles('control', props) as any} className={cx(
        //     {
        //       control: true,
        //       'control--is-disabled': props.isDisabled,
        //       'control--is-focused': props.isFocused,
        //       'control--menu-is-open': props.menuIsOpen
        //     })
        // }>{props.children}</div>
    }))
    const value = React.useMemo(() => overrideValue === undefined ? row?.cells[col.id] as MultiValue<TValue> ?? [] : overrideValue as MultiValue<TValue>, [row, col, overrideValue])

    React.useEffect(() => {
        if (dropdownOpen) inputRef.current?.focus()
    }, [dropdownOpen])

    const displayValue = value?.map?.((o, idx) => <span key={getOptionValue(o)}>{formatOptionLabel?.(o, { context: 'value', inputValue: inputRef.current?.value, selectValue: value }) ?? getOptionLabel(o)}</span>)

    return isFocused
        ? (
            <Dropdown className="d-flex flex-grow-1 h-100 w-100 align-items-center" isOpen={dropdownOpen} toggle={toggle} onClick={e => e.preventDefault()}>
                <DropdownToggle tag="span" role="button" className="register__select-span text-truncate">
                    {displayValue ?? ''}
                </DropdownToggle>
                <DropdownMenu container={spreadsheetPortalTarget} modifiers={{ preventOverflow: { boundariesElement: 'window', enabled: true } }}>
                    <div style={{
                        backgroundColor: 'white',
                        borderRadius: 4,
                        boxShadow: '0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)',
                        marginTop: 8,
                        zIndex: 3
                    }}>
                        <Select
                            {...selectProps}
                            options={options}
                            isClearable={false}
                            value={value}
                            components={selectComponents}
                            hideSelectedOptions={false}
                            placeholder='Search...'
                            isMulti
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                            formatOptionLabel={formatOptionLabel}
                            styles={styles}
                            menuIsOpen={dropdownOpen}
                            isSearchable={true}
                        />
                     </div>
                 </DropdownMenu>
             </Dropdown>
        )
        : (
            <span className="register__select-span">{displayValue}</span>
        )
}
