import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'

import { reloadActiveProject } from '@src/actions/project'
import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { userBasicLabel, userBasicLabelFormat, userBasicValue } from '@src/logic/forms/SelectHelpers'
import { isOnOrAfter } from '@src/logic/forms/validation'
import { ProjectSettingsUpdate, ProjectUsersList, ProjectsList } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import { RootState } from '@src/types/models'
import { UserBasic } from '@src/types/principal'
import { Project } from '@src/types/project'

export interface ICollaboratorSettingsFormData {
    code: string
    category: IOption<string>
    startDate: Date
    finishDate: Date
    value: number
    status: IOption<string>
    primaryContact: UserBasic
    description: string
}

const CollaboratorSettingsSection: React.FC<RouteComponentProps> = ({ history }) => {
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const dispatch = useDispatch()
    const authorised = React.useMemo(() => isAuthorised(project.myAccess, ProjectOperations.Update), [project])

    const projectContactAsync = useAsyncAbortable((abortSignal, primaryContactId) => loadPrimaryContact(primaryContactId, abortSignal), [project.settings.primaryContact ? project.settings.primaryContact.id : undefined])
    const statusOptionsAsync = useAsyncAbortable((abortSignal, input: string) => loadStatusOptions(input, abortSignal), [undefined], { executeOnMount: false, executeOnUpdate: false })
    const categoryOptionsAsync = useAsyncAbortable((abortSignal, input: string) => loadCategoryOptions(input, abortSignal), [undefined], { executeOnMount: false, executeOnUpdate: false })
    const projectContactsAsync = useAsyncAbortable((abortSignal, input: string) => loadProjectUsers(input, abortSignal), [undefined], { executeOnMount: false, executeOnUpdate: false })

    const initialValues = React.useMemo(() => ({
        category: project.settings.category ? { label: project.settings.category, value: project.settings.category } : null,
        code: project.settings.code,
        description: project.settings.description,
        status: project.settings.status ? { label: project.settings.status, value: project.settings.status } : null,
        finishDate: new Date(project.settings.finishDate),
        startDate: new Date(project.settings.startDate),
        primaryContact: projectContactAsync.result,
        value: project.settings.projectValue
    }), [project])

    async function loadStatusOptions(inputValue: string, abortSignal: AbortSignal): Promise<IOption<string>[]> {
        const response = await ProjectsList(isNullOrEmpty(inputValue) ? '' : `status: "${inputValue.replace('"', '\\"')}"`, undefined, undefined, undefined, { abortSignal })
        return [...new Set(response.data.filter(x => !isNullOrEmpty(x.settings.status)).map(x => x.settings.status))].map(status => ({ label: status, value: status }))
    }

    async function loadCategoryOptions(inputValue: string, abortSignal: AbortSignal): Promise<IOption<string>[]> {
        const response = await ProjectsList(isNullOrEmpty(inputValue) ? '' : `category: "${inputValue.replace('"', '\\"')}"`, undefined, undefined, undefined, { abortSignal })
        return [...new Set(response.data.filter(x => !isNullOrEmpty(x.settings.category)).map(x => x.settings.category))].map(category => ({ label: category, value: category }))
    }

    async function loadProjectUsers(inputValue: string, abortSignal: AbortSignal): Promise<UserBasic[]> {
        const val = inputValue.replace('"', '\\"').replace(/\s/, ',')
        const response = await ProjectUsersList(project.id, isNullOrEmpty(inputValue) ? '' : `first_name: ${val} last_name: ${val}`, undefined, undefined, undefined, { abortSignal })
        return response.data
    }

    async function loadPrimaryContact(contactId: string | undefined, abortSignal: AbortSignal): Promise<UserBasic | undefined> {
        if (contactId === undefined) return undefined
        const result = await ProjectUsersList(project.id, `id: "${contactId}"`, undefined, 1, 1, { abortSignal })

        return result.data.length > 0 ? result.data[0] : undefined
    }

    async function handleSaveSettings(values: ICollaboratorSettingsFormData) {
        try {
            await ProjectSettingsUpdate(project.id, {
                category: values.category != null ? values.category.value : null,
                code: values.code,
                description: values.description,
                finishDate: values.finishDate,
                primaryContact: values.primaryContact != null ? values.primaryContact.id : null,
                projectValue: values.value,
                startDate: values.startDate,
                status: values.status != null ? values.status.value : null
            })
        } catch (err) {
            if (!isAxiosError(err)) return false

            if (!err.response) {
                NotificationService.error('There was an error connecting to InfoPoint. Please try again.')
                return true
            }

            switch (err.response.status) {
                case 400:
                    return buildFormErrorsFromModelState(values, err.response.data)
                case 403:
                    NotificationService.error('You do not have permission to make changes.')
                    return
                case 404:
                    NotificationService.error('Could not find project.')
                    history.push(Routes.DASHBOARD)
                    return
                default:
                    NotificationService.error('There was an error while saving.')
                    return
            }
        }

        dispatch(reloadActiveProject())
    }

    function startDateAfterFinish(value: Date, allValues: ICollaboratorSettingsFormData) {
        return isOnOrAfter(value, allValues.startDate)
    }

    return (
        <Form<ICollaboratorSettingsFormData>
            onSubmit={handleSaveSettings}
            initialValues={initialValues}
        >
            {({ handleSubmit }) => (
                <Card className="mb-3">
                    <CardHeader className="d-flex align-items-center">
                        <span><FA icon="cogs" /> Collaborator Settings</span>
                        {authorised && <Button className="ml-auto" color="primary" onClick={handleSubmit}>Save</Button>}
                    </CardHeader>
                    <CardBody>
                        <FormText>The following settings are specific to your company. Changing these will not affect similar settings set by other collaborators.</FormText>
                        {!authorised && <FormText color="warning"><FA icon="exclamation-triangle" /> You need <strong>update</strong> permission on the project in order to change these settings.</FormText>}
                        <div className="mb-3" />
                        <FormGroup row>
                            <Col md={6}>
                                <Label for="collaboratorSettings-code">Project Code</Label>
                                <Field id="collaboratorSettings-code" name="code" disabled={!authorised} component={ValidatedInput} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md={6}>
                                <Label for="collaboratorSettings-category">Project Category</Label>
                                <Field id="collaboratorSettings-category" name="category" isDisabled={!authorised} component={ValidatedSelect} selectType="async-creatable" isClearable defaultOptions loadOptions={categoryOptionsAsync.execute} />
                            </Col>
                        </FormGroup>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="collaboratorSettings-startDate">Start Date</Label>
                                    <Field id="collaboratorSettings-startDate" name="startDate" disabled={!authorised} component={ValidatedDatePicker} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="collaboratorSettings-finishDate">Finish Date</Label>
                                    <Field id="collaboratorSettings-finishDate" name="finishDate" disabled={!authorised} component={ValidatedDatePicker} validate={startDateAfterFinish} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col md={6}>
                                <Label for="collaboratorSettings-value">Value</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend"><InputGroupText>$</InputGroupText></InputGroupAddon>
                                    <Field id="collaboratorSettings-value" name="value" disabled={!authorised} component={ValidatedCurrencyInput} />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md={6}>
                                <Label for="collaboratorSettings-status">Status</Label>
                                <Field id="collaboratorSettings-status" name="status" isDisabled={!authorised} component={ValidatedSelect} selectType="async-creatable" loadOptions={statusOptionsAsync.execute} isClearable defaultOptions />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md={6}>
                                <Label for="collaboratorSettings-primaryContact">Primary Contact</Label>
                                <Field id="collaboratorSettings-primaryContact" name="primaryContact" isDisabled={!authorised} component={ValidatedSelect} selectType="async" loadOptions={projectContactsAsync.execute} isClearable defaultOptions getOptionValue={userBasicValue} getOptionLabel={userBasicLabel} formatOptionLabel={userBasicLabelFormat} />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label for="collaboratorSettings-description">Description</Label>
                            <Field id="collaboratorSettings-description" name="description" disabled={!authorised} component={ValidatedInput} type="textarea" />
                        </FormGroup>
                    </CardBody>
                </Card>
            )}
        </Form>
    )
}

export default CollaboratorSettingsSection
