import create from "zustand";
import {
  GetAllUsers,
  GetCompanies,
  GetCompanyGroups,
  GetCompanyUsers,
  GetAdminCompany,
} from "@src/logic/http/Api";
import {
  CompanyGroup,
  CompanyPublic,
  UserInternal,
} from "@src/types/principal";

interface ICompanyUsersStore {
  count: number;
  companyUsers: UserInternal[] | null;
  loadError: Error | null;
  load: (companyId: string, abortSignal?: AbortSignal) => Promise<void>;
  refresh: (companyId: string, abortSignal?: AbortSignal) => Promise<void>;
  update: (update: Partial<UserInternal[]>) => void;
  incrementPage: () => void;
  reset: () => void;
}

export const useCompanyUsersStore = create<ICompanyUsersStore>((set, get) => ({
  count: 500,
  companyUsers: null,
  loadError: null,
  load: async (companyId, abortSignal) => {
    set({ companyUsers: null, loadError: null });
    try {
      const count = get().count;
      const response = await GetCompanyUsers(
        companyId,
        undefined,
        "first_name",
        undefined,
        count,
        { abortSignal }
      );

      set({ companyUsers: response.data, loadError: null });
    } catch (error) {
      set({ companyUsers: null, loadError: error as Error });
    }
  },
  refresh: async (companyId, abortSignal) => {
    if (companyId == null) return;
    try {
      const count = get().count;
      const response = await GetCompanyUsers(
        companyId,
        undefined,
        "first_name",
        undefined,
        count,
        { abortSignal }
      );
      set({ companyUsers: response.data, loadError: null });
    } catch (error) {
      set({ companyUsers: null, loadError: error as Error });
    }
  },
  update: (update: Partial<UserInternal[]>) => {
    const companyUsers = get().companyUsers;
    set({ companyUsers: { ...companyUsers, ...update } });
  },
  incrementPage: () => set((state) => ({ count: state.count + 500 })),
  reset: () => {
    set({ count: 500, companyUsers: null });
  },
}));

interface ICompanyStore {
  perPage: number;
  companies: CompanyPublic[] | null;
  searchableCompanies: CompanyPublic[] | null;
  adminCompany: CompanyPublic;
  loadError: Error | null;
  loadSearchableCompanies: (
    query: string,
    page?: number,
    abortSignal?: AbortSignal
  ) => Promise<void>;
  loadCompanies: (
    query: string,
    page?: number,
    abortSignal?: AbortSignal
  ) => Promise<void>;
  refreshCompanies: (
    query: string,
    page?: number,
    abortSignal?: AbortSignal
  ) => Promise<void>;
  updateCompanies: (update: Partial<CompanyPublic[]>) => void;
  resetCompanies: () => void;
  loadDefaultCompany: (abortSignal?: AbortSignal) => Promise<void>;
  refreshDefaultCompany: (abortSignal?: AbortSignal) => Promise<void>;
  resetDefaultCompany: () => void;
}

export const useCompanyStore = create<ICompanyStore>((set, get) => ({
  perPage: 10000,
  companies: null,
  searchableCompanies: null,
  adminCompany: null,
  loadError: null,
  loadSearchableCompanies: async (query, page, abortSignal) => {
    set({ searchableCompanies: null, loadError: null });
    try {
      const count = page ?? get().perPage;
      const response = await GetCompanies(query, "name", undefined, count, {
        abortSignal,
      });
      set({ searchableCompanies: response.data, loadError: null });
    } catch (error) {
      set({ searchableCompanies: null, loadError: error as Error });
    }
  },
  loadCompanies: async (query, page, abortSignal) => {
    set({ companies: null, loadError: null });
    try {
      const count = page ?? get().perPage;
      const response = await GetCompanies(query, "name", undefined, count, {
        abortSignal,
      });
      set({ companies: response.data, loadError: null });
    } catch (error) {
      set({ companies: null, loadError: error as Error });
    }
  },
  refreshCompanies: async (query, page, abortSignal) => {
    try {
      const count = page ?? get().perPage;
      const response = await GetCompanies(query, "name", undefined, count, {
        abortSignal,
      });
      set({ companies: response.data, loadError: null });
    } catch (error) {
      set({ companies: null, loadError: error as Error });
    }
  },
  updateCompanies: (update: Partial<CompanyPublic[]>) => {
    const companies = get().companies;
    set({ companies: { ...companies, ...update } });
  },
  resetCompanies: () => {
    set({ perPage: 10000, companies: null });
  },
  loadDefaultCompany: async (abortSignal) => {
    set({ adminCompany: null, loadError: null });
    try {
      const responseAdmin = await GetAdminCompany({ abortSignal });
      set({ adminCompany: responseAdmin.data, loadError: null });
    } catch (error) {
      set({ adminCompany: null, loadError: error as Error });
    }
  },
  refreshDefaultCompany: async (abortSignal) => {
    try {
      const responseAdmin = await GetAdminCompany({ abortSignal });
      set({ adminCompany: responseAdmin.data, loadError: null });
    } catch (error) {
      set({ adminCompany: null, loadError: error as Error });
    }
  },
  resetDefaultCompany: () => {
    set({ perPage: 10, companies: null });
  },
}));

interface INewRegistrationsStore {
  count: number;
  newRegistrations: UserInternal[] | null;
  loadError: Error | null;
  load: (query: string, abortSignal?: AbortSignal) => Promise<void>;
  refresh: (query: string, abortSignal?: AbortSignal) => Promise<void>;
  update: (update: Partial<UserInternal[]>) => void;
  reset: () => void;
}

export const useNewRegistrationsStore = create<INewRegistrationsStore>(
  (set, get) => ({
    count: 10000,
    newRegistrations: null,
    loadError: null,
    load: async (query, abortSignal) => {
      set({ newRegistrations: null, loadError: null });
      try {
        const count = get().count;
        const response = await GetAllUsers(
          `status: Activated, $first_name: ${query ?? ""}`,
          "first_name",
          undefined,
          count,
          { abortSignal }
        );

        set({ newRegistrations: response.data, loadError: null });
      } catch (error) {
        set({ newRegistrations: null, loadError: error as Error });
      }
    },
    refresh: async (query, abortSignal) => {
      try {
        const count = get().count;
        const response = await GetAllUsers(
          `status: Activated, $first_name: ${query ?? ""}`,
          "first_name",
          undefined,
          count,
          { abortSignal }
        );
        set({ newRegistrations: response.data, loadError: null });
      } catch (error) {
        set({ newRegistrations: null, loadError: error as Error });
      }
    },
    update: (update: Partial<UserInternal[]>) => {
      const companyUsers = get().newRegistrations;
      set({ newRegistrations: { ...companyUsers, ...update } });
    },
    reset: () => {
      set({ count: 10000, newRegistrations: null });
    },
  })
);

interface IContactsStore {
  perPage: number;
  contacts: UserInternal[] | null;
  contact: UserInternal;
  loadError: Error | null;
  loadContacts: (query: string, abortSignal?: AbortSignal) => Promise<void>;
  refreshContacts: (query: string, abortSignal?: AbortSignal) => Promise<void>;
  updateContacts: (update: Partial<UserInternal[]>) => void;
  reset: () => void;
}

export const useContactsStore = create<IContactsStore>((set, get) => ({
  perPage: 10000,
  contacts: null,
  contact: null,
  loadError: null,
  loadContacts: async (query, abortSignal) => {
    set({ contacts: null, loadError: null });
    try {
      const count = get().perPage;
      const response = await GetAllUsers(
        query,
        "first_name",
        undefined,
        count,
        {
          abortSignal,
        }
      );
      set({ contacts: response.data, loadError: null });
    } catch (error) {
      set({ contacts: null, loadError: error as Error });
    }
  },
  refreshContacts: async (query, abortSignal) => {
    try {
      const count = get().perPage;
      const response = await GetAllUsers(
        query,
        "first_name",
        undefined,
        count,
        {
          abortSignal,
        }
      );
      set({ contacts: response.data, loadError: null });
    } catch (error) {
      set({ contacts: null, loadError: error as Error });
    }
  },
  updateContacts: (update: Partial<UserInternal[]>) => {
    const companies = get().contacts;
    set({ contacts: { ...companies, ...update } });
  },

  reset: () => {
    set({ perPage: 10000, contacts: null });
  },
}));

interface ICompanyGroupsStore {
  groups: CompanyGroup[] | null;
  loadError: Error | null;
  loadGroups: (companyId: string, abortSignal?: AbortSignal) => Promise<void>;
  refreshGroups: (
    companyId: string,
    abortSignal?: AbortSignal
  ) => Promise<void>;
  updateGroups: (update: Partial<CompanyGroup[]>) => void;
  reset: () => void;
}

export const useCompanyGroupsStore = create<ICompanyGroupsStore>(
  (set, get) => ({
    groups: null,
    loadError: null,
    loadGroups: async (companyId, abortSignal) => {
      set({ groups: null, loadError: null });
      try {
        const response = await GetCompanyGroups(companyId, {
          abortSignal,
        });
        set({ groups: response.data, loadError: null });
      } catch (error) {
        set({ groups: null, loadError: error as Error });
      }
    },
    refreshGroups: async (companyId, abortSignal) => {
      try {
        const response = await GetCompanyGroups(companyId, {
          abortSignal,
        });
        set({ groups: response.data, loadError: null });
      } catch (error) {
        set({ groups: null, loadError: error as Error });
      }
    },
    updateGroups: (update: Partial<CompanyGroup[]>) => {
      const companies = get().groups;
      set({ groups: { ...companies, ...update } });
    },
    reset: () => {
      set({ groups: null });
    },
  })
);

//export default useCompanyUsersRegister
