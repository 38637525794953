import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { compare } from 'fast-json-patch'

import Link from '@src/components/common/Link'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { IModalProps } from '@src/hooks/useModal'
import { PaymentClaimPatch } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { CostsOverview, PaymentClaimBrief } from '@src/types/costs'

interface IProps extends IModalProps {
    projectId: string
    paymentClaim: PaymentClaimBrief
    costsOverview: CostsOverview
    onUpdated?: () => void
}

interface IEditPaymentFormData {
    claimDate: Date
    claimReference: string
    invoiceDate: Date
    invoiceReference: string
    value: number
    notes: string
}

export default function EditPaymentForm({ costsOverview, onUpdated, paymentClaim, projectId, ...modalProps }: IProps) {
    const commitmentTypeName = costsOverview.commitmentDefinitions.find(x => x.code === paymentClaim.commitment.type).name

    async function handleSubmit(values: IEditPaymentFormData) {
        const update = compare(paymentClaim, {
            ...paymentClaim,
            claimDate: values.claimDate,
            claimReference: values.claimReference,
            invoiceDate: values.invoiceDate,
            invoiceReference: values.invoiceReference,
            value: values.value,
            notes: values.notes
        })

        await PaymentClaimPatch(projectId, paymentClaim.commitment.id, paymentClaim.id, update)
        onUpdated?.()
        modalProps.toggle()
    }

    return (
        <Modal {...modalProps}>
            <Form
                onSubmit={handleSubmit}
                initialValues={{
                    claimDate: paymentClaim.claimDate,
                    claimReference: paymentClaim.claimReference,
                    invoiceDate: paymentClaim.invoiceDate,
                    invoiceReference: paymentClaim.invoiceReference,
                    notes: paymentClaim.notes,
                    value: paymentClaim.value
                }}>
                {formProps =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>Edit - Payment Claim</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>{commitmentTypeName}</Label>
                                        <Link className="d-block" to={Routes.projectCostsCommitmentTypeDetail(projectId, paymentClaim.commitment.type, paymentClaim.id)}>{paymentClaim.commitment.name}</Link>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Invoice Date</Label>
                                        <Field name="invoiceDate" component={ValidatedDatePicker} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Invoice Reference</Label>
                                        <Field name="invoiceReference" component={ValidatedInput} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Claim Date</Label>
                                        <Field name="claimDate" component={ValidatedDatePicker} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Claim Reference</Label>
                                        <Field name="claimReference" component={ValidatedInput} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Value</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><InputGroupText>$</InputGroupText></InputGroupAddon>
                                            <Field name="value" component={ValidatedCurrencyInput} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Notes</Label>
                                        <Field name="notes" type="textarea" component={ValidatedInput} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={formProps.handleSubmit}>Save</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}
