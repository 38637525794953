import React from 'react'
import { Badge } from 'reactstrap'

import { safeHtmlId } from '@src/logic/utils/Strings'

interface IProps {
    tags: string[]
}

export default class Tags extends React.PureComponent<IProps> {
    public render() {
        return (
            <span>
                {this.props.tags.map((t, idx) => <Badge className="mr-1" key={`${safeHtmlId(t)}-${idx}`} color="primary">{t}</Badge>)}
            </span>
        )
    }
}
