import React from 'react'
import { Field } from 'react-final-form'

import { finalFormMetadataValidator } from '@src/components/metadata/MetadataInputs'
import { IRegisterTableField } from '@src/components/register/tablefields/FieldTypes'
import useTouched from '@src/hooks/useTouched'
import { NumericDefinition } from '@src/types/metadata'

function parse(value: string) {
    return parseInt(value)
}

function format(value: number) {
    return value?.toString() ?? ''
}

export default function NumberField({ row, col, isFocused }: IRegisterTableField<NumericDefinition>) {
    const value = row.cells[col.id] as number | null
    const isTouched = useTouched(isFocused, [value])

    return isTouched
    ? (
        <Field name={`cells.r-${row.id}.c-${col.id}`} initialValue={value} parse={parse} format={format} validateFields={[]}>
            {props => isFocused ? <input {...props.input} type="number" className="register__input-cell" /> : <span className="register__inactive-text-num-span">{props.input.value}</span>}
        </Field>
    )
    : <span className="register__inactive-text-num-span">{value}</span>
}

export function NumberFieldFooter({ col }: IRegisterTableField<NumericDefinition>) {
    const data = React.useMemo(() => ({ definition: col.data }), [col.data])
    return (
        <Field<number, React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement>, HTMLInputElement, string>
            name={`cells.c-${col.id}`} parse={parse} format={format} validate={finalFormMetadataValidator} data={data}>
            {({ input }) =>
                <input {...input} type="number" className="register__input-cell" />
            }
        </Field>
    )
}
