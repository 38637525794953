import React from 'react'
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import * as Routes from '@src/logic/routing/routes'
import { groupBy } from '@src/logic/utils/Collection'
import { CostsOverview } from '@src/types/costs'
import { Revision } from '@src/types/document'

interface IProps {
    revision: Revision
    costsOverview: CostsOverview
}

export default function CommitmentLinks({ revision, costsOverview }: IProps) {
    const linksByType = groupBy(revision.links.commitments, 'type')
    return (
        <>
            <a role="button" href="#" id={`revision-${revision.id}-clinks`} className={cx('ml-2 text-center', 'selectable-content__icon', { 'selectable-content__icon--green': revision.links.commitments.length > 0 })}><FA icon="dollar-sign" /></a>
            {revision.links.commitments.length > 0 && <UncontrolledPopover target={`revision-${revision.id}-clinks`} trigger="hover">
                <PopoverHeader>Linked in Costs</PopoverHeader>
                <PopoverBody>
                    <dl className="mb-0" style={{ width: 300 }}>
                        {Object.keys(linksByType).map(
                            type =>
                                <React.Fragment key={type}>
                                    <dt>{(costsOverview?.commitmentDefinitions.find(x => x.code === type)?.name) ?? type}</dt>
                                    <dd>
                                        <ul>
                                            {linksByType[type].map(l => <li key={l.id} className="text-nowrap"><Link to={Routes.projectCostsCommitmentTypeDetail(revision.projectId, type, l.id)}>{l.name}</Link></li>)}
                                        </ul>
                                    </dd>
                                </React.Fragment>
                        )}
                    </dl>
                </PopoverBody>
            </UncontrolledPopover>}
        </>
    )
                        }
