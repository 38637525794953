import React from 'react'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'
import useUpdateEffect from '@src/hooks/useUpdateEffect'
import { localDateTime } from '@src/logic/utils/Date'
import { EmailBrief } from '@src/types/email'

const EmailListWrapper = React.forwardRef((props, ref: React.RefObject<HTMLDivElement>) => (
    <div className="list-group list-group-flush" ref={ref} {...props} />
))

interface IProps {
    emails: EmailBrief[]
    selectedEmailId: string
    loading: boolean
    moreToLoad: boolean
    height?: number
    filter: string
    sort: string
    loadMore: () => Promise<any>
    onSelect: (email: EmailBrief) => void
}

export interface IInfiniteLoaderRef {
    resetloadMoreItemsCache: () => void
}

const EmailInfiniteLoader: React.FC<IProps> = ({ emails, selectedEmailId, loading, moreToLoad, height, filter, sort, loadMore, onSelect }) => {
    const infiniteLoaderRef = React.useRef<InfiniteLoader>()
    useUpdateEffect(
        () => {
            infiniteLoaderRef.current?.resetloadMoreItemsCache()
        },
    [filter, sort])

    function loadMoreItems() {
        if (loading) return Promise.resolve()
        return loadMore()
    }

    function isItemLoaded(index: number) {
        return !moreToLoad || index < emails.length
    }

    function EmailListItem(props: { data: EmailBrief[], index: number, style: React.CSSProperties }) {
        const email: EmailBrief = props.index < props.data.length ? props.data[props.index] : undefined
        const active = selectedEmailId === props.data[props.index]?.id

        function handleClick() {
            onSelect(props.data[props.index])
        }

        return email != null
        ? (
            <ListGroupItem tag="button" className="border-top-0 border-bottom" action active={active} style={props.style} onClick={handleClick}>
                <div className="d-flex w-100 align-items-center">
                    <ListGroupItemHeading className={cx('text-truncate', { 'text-white': active })}>{email.from.name ?? email.from.email}</ListGroupItemHeading>
                    <small className="ml-auto flex-shrink-0">{localDateTime(email.received)}</small>
                </div>
                <ListGroupItemText className="mb-1 d-flex align-items-center">
                    <span className="text-truncate">{email.subject}</span>
                    {email.attachments.some(x => x.contentId == null) && <FA icon="paperclip" size="sm" className="ml-auto" />}
                </ListGroupItemText>
                <div><pre className={cx('text-truncate mb-0', { 'text-light': active, 'text-muted': !active })} style={{ fontFamily: 'inherit' }}>{email.bodyPreview}</pre></div>
            </ListGroupItem>
        )
        : (
            <ListGroupItem className="border-top-0" style={props.style}>
                <div className="lead text-center text-muted mt-2">
                    {loading ? <span>Loading...<FA icon="spinner-third" spin className="ml-2" /></span> : <span>End of captured emails</span>}
                </div>
            </ListGroupItem>
        )
    }

    return (
        <InfiniteLoader
            ref={infiniteLoaderRef}
            isItemLoaded={isItemLoaded}
            itemCount={emails.length + 1}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={50}
        >
            {({ onItemsRendered, ref }) => (
                <FixedSizeList
                    itemCount={emails.length + 1}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    itemData={emails}
                    width="100%"
                    height={height ?? 100}
                    itemSize={100}
                    innerElementType={EmailListWrapper}
                >
                    {EmailListItem}
                </FixedSizeList>
            )}
        </InfiniteLoader>
    )
}

export default EmailInfiniteLoader
