import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { Slide, ToastContainer } from "react-toastify";
import "ag-grid-community/dist/styles/ag-grid.scss";
import "ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine.scss";

import * as Sentry from "@sentry/browser";

import * as ProjectActions from "@src/actions/project";
import Breakpoints from "@src/components/breakpoint/Breakpoints";
import AppHeader from "@src/components/header/AppHeader";
import AppSidebar from "@src/components/navigation/AppSidebar";
import CrumbRoute from "@src/components/navigation/CrumbRoute";
import { FeatureCrumbRoute } from "@src/components/navigation/FeatureRoute";
import { NotificationContainer } from "@src/components/notifications/NotificationContainer";
import DocumentSandbox from "@src/components/sandbox/DocumentSandbox";
import Widget from "@src/components/widget/Widget";
import ErrorBoundary from "@src/containers/ErrorBoundary";
import useBoolean from "@src/hooks/useBoolean";
import useConstant from "@src/hooks/useConstant";
import {
  FeatureFlag,
  GlobalSearchFlagValue,
} from "@src/logic/features/features";
import NotificationService from "@src/logic/notification/NotificationService";
import * as Routes from "@src/logic/routing/routes";
import { HelpHeroServiceInstance } from "@src/logic/support/HelpHeroService";
// import { HelpScoutServiceInstance } from "@src/logic/support/HelpScoutService";
import CompanyPages from "@src/pages/CompanyPages";
import DashboardPage from "@src/pages/DashboardPage";
import AdminPage from "@src/pages/AdminPage";
import GlobalSearchPage from "@src/pages/globalsearch/GlobalSearchPage";
import NewProjectPage from "@src/pages/project/NewProjectPage";
import ProjectPages from "@src/pages/project/ProjectPages";
import SettingsPages from "@src/pages/settings/SettingsPages";
import { RootState } from "@src/types/models";
import { Company } from "@src/types/principal";
import { Session } from "@src/types/session";
import CompaniesPage from "@src/pages/admin/Companies/CompaniesPage";
import ContactsPage from "@src/pages/admin/Contacts/ContactsPage";
import NewRegistrationsPage from "@src/pages/admin/NewRegistrations/NewRegistrationsPage";

interface IConnectedState {
  account: Session.AuthAccount;
  user: Session.User;
  company: Company;
}

const App: React.FC = () => {
  const dispatch = useDispatch();
  const sidebarCollapsed = useBoolean(true);
  const session = useSelector<RootState, IConnectedState>((s) => ({
    account: s.session.account,
    user: s.session.user,
    company: s.session.company,
  }));
  const isAdmin = window.location.pathname.includes("admin");

  useConstant(() => NotificationService.initializeNotificationConnection());

  React.useEffect(() => {
    dispatch(ProjectActions.loadFavourites());
    // HelpScoutServiceInstance.identify(session.user, session.company);
    HelpHeroServiceInstance.identify(session.user);
    Sentry.setUser({
      id: session.user.id,
      email: session.user.email,
      username: session.user.fullName,
    });

    return NotificationService.closeNotificationConnection;
  }, []);

  const toggleSidebar = React.useCallback((open?: boolean) => {
    if (open == null) return sidebarCollapsed.toggle();

    return open ? sidebarCollapsed.setTrue() : sidebarCollapsed.setFalse();
  }, []);

  const companyCrumbTitle = session.company?.name ?? "";
  return (
    <Breakpoints debounceResize>
      <AppHeader
        sidebarCollapsed={sidebarCollapsed.value}
        toggleSidebar={toggleSidebar}
      />
      <div className="wrapper">
        {!isAdmin && (
          <AppSidebar
            collapsed={sidebarCollapsed.value}
            toggle={toggleSidebar}
          />
        )}
        <main className="content">
          <ToastContainer
            enableMultiContainer
            containerId="toasts"
            transition={Slide}
            limit={3}
            toastClassName="rounded toast show p-0 min-h-0 border-0 p-2"
            closeButton={false}
            bodyClassName="toast-header bg-transparent border-0 text-light"
            newestOnTop
            hideProgressBar
            position="top-center"
            className="mt-4"
          />
          <NotificationContainer
            position="bottom-right"
            enableMultiContainer
            containerId="notifications"
          />
          <div className="content__inner">
            <ErrorBoundary>
              <Switch>
                <FeatureCrumbRoute
                  flag={FeatureFlag.GlobalSearch}
                  value={GlobalSearchFlagValue.Default}
                  title="Search"
                  path={Routes.GLOBAL_SEARCH}
                  component={GlobalSearchPage}
                />
                <CrumbRoute
                  title={companyCrumbTitle}
                  path={Routes.COMPANY}
                  component={CompanyPages}
                />
                <CrumbRoute
                  title="New Project"
                  path={Routes.PROJECT_CREATE}
                  component={NewProjectPage}
                />
                <Route path={Routes.PROJECT} component={ProjectPages} />
                <CrumbRoute
                  title="Settings"
                  path={Routes.SETTINGS}
                  component={SettingsPages}
                />
                <CrumbRoute
                  exact
                  title="Dashboard"
                  path={Routes.DASHBOARD}
                  component={DashboardPage}
                />
                <Route
                  exact
                  path={Routes.ADMIN_CONSOLE_DASHBOARD}
                  component={CompaniesPage}
                />
                <Route
                  path={Routes.ADMIN_COMPANIES}
                  component={CompaniesPage}
                />
                <Route path={Routes.ADMIN_CONTACTS} component={ContactsPage} />
                <Route
                  path={Routes.ADMIN_NEW_REGISTRATIONS}
                  component={NewRegistrationsPage}
                />
                <Redirect from="*" to={Routes.DASHBOARD} />
              </Switch>
            </ErrorBoundary>
          </div>
          <Widget />
          <DocumentSandbox />
        </main>
      </div>
    </Breakpoints>
  );
};

export default App;
