import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFeedback, Input, InputProps } from 'reactstrap'

import BigNumber from 'bignumber.js'

import { formatCurrency, removeAllNonPeriodDigit } from '@src/logic/utils/Currency'

type IProps = Omit<RemoveIndex<InputProps>, 'onChange' | 'onFocus' | 'onBlur' | 'className' | 'invalid' | 'type' | 'value' | 'innerRef'> & FieldRenderProps<number>

interface IState {
    value: string
}

export default class ValidatedCurrencyInput extends React.PureComponent<IProps, IState> {
    private readonly inputRef: React.RefObject<HTMLInputElement>

    constructor(props: IProps) {
        super(props)

        this.inputRef = React.createRef()

        this.state = {
            value: this.formattedFormValue()
        }
    }

    public componentDidUpdate(prevProps: IProps) {
        if (!this.props.meta.active && this.props.input.value !== prevProps.input.value) {
            this.setState({ value: this.formattedFormValue() })
        }
    }

    private readonly formattedFormValue = () => {
        const value = new BigNumber(this.props.input.value)
        return formatCurrency(value.isNaN() ? new BigNumber(0) : value, false)
    }

    private readonly handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value != null ? new BigNumber(removeAllNonPeriodDigit(e.currentTarget.value)).decimalPlaces(2).toNumber() : 0
        this.setState({ value: formatCurrency(!isNaN(value) ? value : 0, false) }, () => this.props.input.onBlur())
    }

    private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value != null ? new BigNumber(removeAllNonPeriodDigit(e.currentTarget.value)).decimalPlaces(2).toNumber() : 0
        this.setState({ value: e.currentTarget.value }, () => this.props.input.onChange(!isNaN(value) ? value : 0))
    }

    public render() {
        const { input, meta: { touched, error, active }, ...htmlProps } = this.props
        const showError = touched && !!error

        return (
            <>
                <Input
                    {...htmlProps}
                    className="text-right"
                    innerRef={this.inputRef}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={input.onFocus}
                    invalid={showError}
                    value={active ? this.state.value : this.formattedFormValue()}
                />
                <FormFeedback>{showError && error}</FormFeedback>
            </>
        )
    }
}
