import React from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory, useLocation } from 'react-router'

import { FormApi } from 'final-form'

import SimpleSearch from '@src/components/search/SimpleSearch'
import * as Routes from '@src/logic/routing/routes'

interface FormValues {
    search: string
}

const HeaderSearch: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const querySearch = React.useMemo(() => new URLSearchParams().get('filter') ?? '', [location.search])

    function handleSearch(values: FormValues, form: FormApi<FormValues>) {
        history.push(Routes.globalSearchUser(values.search))
    }

    return (
        <Form<FormValues>
            initialValues={{
                search: querySearch
            }}
            onSubmit={handleSearch}
        >
            {(formProps) => (
                <Field<string> name="search">
                    {(fieldProps) =>
                    <SimpleSearch
                        className="header__search flex-grow-1 flex-md-grow-0"
                        value={fieldProps.input.value}
                        onChange={fieldProps.input.onChange}
                        onSearch={formProps.handleSubmit}
                    />}
                </Field>
            )}
        </Form>
    )
}

export default HeaderSearch
