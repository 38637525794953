import React from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import { Field, FieldRenderProps, Form } from 'react-final-form'
import { InputActionMeta } from 'react-select'
import { Button, FormGroup, Label, ListGroup, ListGroupItem, ListGroupItemText, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import Action from '@src/components/common/Action'
import { UserAvatar } from '@src/components/common/Avatar'
import FA from '@src/components/common/FontAwesomeIcon'
import Select from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedTextArea from '@src/components/common/ValidatedTextArea'
import { IModalProps } from '@src/hooks/useModal'
import { userBasicLabel, userBasicLabelFormat, userBasicValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CompanyUsersList, GroupCreate, GroupUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { mutedValue } from '@src/logic/utils/ValueHelper'
import { Group, UserInternal } from '@src/types/principal'

interface IProps extends IModalProps {
    group?: Group
    onSave?: () => void
}

interface IGroupFormData {
    name: string
    description: string
    members: UserInternal[]
}

export default function GroupModal ({ group, onSave, ...modalProps }: IProps) {
    const initialValues = React.useMemo(() => group != null
            ? {
                name: group.name,
                description: group.notes,
                members: group.members
            }
            : {
                name: '',
                description: '',
                members: []
            },
        [group])

    async function handleSave(values: IGroupFormData) {
        if (group) {
            await GroupUpdate(group.id, {
                name: values.name,
                notes: values.description,
                memberIds: values.members ? values.members.map(x => x.id) : []
            })
            NotificationService.info('Group updated')
        } else {
            await GroupCreate({
                name: values.name,
                notes: values.description,
                memberIds: values.members ? values.members.map(x => x.id) : []
            })
            NotificationService.info('Group created')
        }

        onSave?.()
        modalProps.toggle()
    }

    return (
        <Modal {...modalProps}>
            <Form<IGroupFormData> onSubmit={handleSave} initialValues={initialValues}>
                {({ handleSubmit }) =>
                    <>
                        <ModalHeader toggle={modalProps.toggle}>{group ? group.name : 'New group'}</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="groupmodal-name">Name</Label>
                                <Field id="groupmodal-name" name="name" component={ValidatedInput} validate={required}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="groupmodal-description">Description</Label>
                                <Field id="groupmodal-description" name="description" component={ValidatedTextArea} />
                            </FormGroup>
                            <Field<UserInternal[]> name="members" component={GroupMembers}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={handleSubmit}>Save</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}

function GroupMembers ({ input: { onChange, value: members } }: FieldRenderProps<UserInternal[]>) {
    const usersAsync = useAsyncAbortable(async (abortSignal, filter) => {
            const queryValue = filter.split(/\s/).map(p => `"${p.replace('"', '\\"')}"`).join(',')
            return (await CompanyUsersList(`first_name: ${queryValue} OR last_name: ${queryValue}`, 'first_name', 1, 100, { abortSignal })).data
        },
        [''],
        {
            executeOnUpdate: false,
            setLoading: s => ({
                ...s,
                loading: true
            }),
            initialState: () => ({
                status: 'not-requested',
                loading: false,
                error: undefined,
                result: []
            })
        })

    function addMember(user: UserInternal) {
        return onChange([...members, user])
    }

    function removeMember(index: number) {
        return onChange([...members.slice(0, index), ...members.slice(index + 1)])
    }

    function filterUsers(option: { label, value, data: UserInternal }, input) {
        return !members.find(x => x.id === option.data.id)
    }

    function onInputChange(value: string, meta: InputActionMeta) {
        if (meta.action === 'input-change') {
            usersAsync.execute(value)
        }
    }

    return (
        <FormGroup>
            <Label for="groupmodal-addmember">Members</Label>
            <Select<UserInternal>
                value={null}
                id="groupmodal-addmember"
                isLoading={usersAsync.loading}
                options={usersAsync.result}
                onInputChange={onInputChange}
                onChange={addMember}
                getOptionLabel={userBasicLabel}
                getOptionValue={userBasicValue}
                formatOptionLabel={userBasicLabelFormat}
                filterOption={filterUsers}
            />
            <ListGroup className="mt-3">
                {members.map((user, idx) =>
                    <ListGroupItem key={user.id}>
                        <div className="d-flex align-items-center">
                            <UserAvatar size="xs" imageUrl={user.profilePictureLink} firstName={user.firstName}
                                        lastName={user.lastName}/>
                            <ListGroupItemText
                                className="mb-0 ml-2">{user.firstName} {user.lastName} {mutedValue(user.email)}</ListGroupItemText>
                            <Action data={idx} onClick={removeMember} className="ml-auto">
                                <FA className="text-muted" icon="times"/>
                            </Action>
                        </div>
                    </ListGroupItem>
                )}
            </ListGroup>
        </FormGroup>
    )
}
